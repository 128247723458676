.aspireiq_theme {

  .nonForm_group {

    .btn_danger {
      
    }
  }

  .paymentTable_form_control {

    a {
      color: $aiq_darkblue;

      &:hover, &:focus {
        color: $aiq_darkblue_hover;
      }
    }
  }

  .managers_section {

    .permissions_select_btn {
      color: $aiq_darkblue;
      border: 1px solid $aiq_darkblue;

      &:hover {
        color: $aiq_darkblue_hover;
        border: 1px solid $aiq_darkblue_hover;
        background: url("/images/settings/down_arrow_icon.svg") calc(100% - 14px) center no-repeat;
      }
    }

    .permissions_select_container {

      .dropdown_list {
        top: 30px;

        &:before, &:after {
          left: 111px;
        }
      }

      &:hover {

        .permissions_dropdown {
          left: -44px !important;
          top: 31px !important;
          width: unset;
        }
      }

      .permissionDropdown_btn {
        color: $aiq_darkblue;

        &:hover {
          color: $aiq_darkblue_hover;
        }
      }
    }

    .permissions_select_btn {
      color: $aiq_black;
      background-color: $aiq_lightblue_bg;
      border: none;

      &:hover, &:focus {
        color: $aiq_black;
        background-color: $aiq_lightblue_bg_hover;
        border: none;
      }

      &:active {
        background-color: $aiq_lightblue_bg;
        border: none;
      }
    }
  }

  .main_section {

    .main_title {
      font-size: 1.75rem;
      font-weight: 400;
    }
  }
}
