.modal {
  &.loading-view {
    background-color: rgba(255, 255, 255, 0.7);
    display: block;
  }
}

.relative-loading-section {
    position: relative;
    width: 100%;
    height: 50px;
    text-align: center;
    margin-top: 3em;
    margin-bottom: 4em;
}

.small-relative-loading-section {
    text-align: center;

    .loading-label {
        margin-top: 2px;
        margin-bottom: 2px;
        color: #6b6b6b;
        font-weight: 300;
        font-size: 16px;
        display: inline-block;
        vertical-align: top;
    }

    .spinner-image {
        width: 20px;
        height: 20px;
        margin: 0 8px;
        display: inline-block;
    }
}

.loading-section-container {
    position: absolute;
    width: 100%;
    height: 500px;
    display: none;
}

.main-loading-section {
    -webkit-transition: opacity 1s ease-in;
    -moz-transition: opacity 1s ease-in;
    -ms-transition: opacity 1s ease-in;
    -o-transition: opacity 1s ease-in;
    transition: opacity 1s ease-in;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 250px;
    z-index: 2;
    height: 95px;
}

.main-loading-section-inline {
    position: relative;
    text-align: center;
    margin: auto;
    width: 250px;
    height: 95px;
}

.spinner-image {
  width: 40px;
  height: 40px;
  margin: 16px auto;
  display: block;
}

.loading-label {
  margin-top: 8px;
  color: #6b6b6b;
  font-weight: 300;
  text-align: center;
  font-size: 20px;
}
