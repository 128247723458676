.content_dashboard {
  padding: 50px 16px 0;
  max-width: 1400px;
  margin: 0 auto;

  @include media($break1390) {
    padding: 50px 1rem 0;
  }

  .leftCol, .rightCol {
    float: left;
  }

  .leftCol {
    margin-right: 2rem;
    width: 260px;
    padding-top: .5rem;
    position: relative;

    @include media($break790) {
      float: none;
      width: 100%;
      margin-right: 0;

      .filters_container {
        display: none;
      }
    }

    .leftCol_fixed_wrapper {
      position: fixed;
      width: 260px;
      z-index: 5;

      @include media($break790) {
        position: relative;
        width: 100%;
      }
    }

    .labelIcon {
      vertical-align: 2px;
    }

    .totalContent_container {
      margin-bottom: .85rem;
    }

    .totalContent_title {
      float: left;
      width: calc(100% - 71px);
      font-size: 1.3rem;

      @include media($break790) {
        font-size: 2rem;
        width: calc(100% - 42px);
      }
    }

    .libraryActions_container {
      float: left;

      .tooltip {
        top: 43px;
      }
    }

    .shareLibrary_btn {
      width: 34px;
    }

    .addContent_btn, .shareLibrary_btn {
      background: none;
      padding: 5px;
      border-radius: 6px;

      @include media($break790) {
        margin-top: 5px;
      }

      &:hover {
        background-color: #F6F6F6;
      }
    }

    .addContent_icon {
      width: 20px;
      height: 20px;

      @include media($break790) {
        width: 30px;
      }
    }


    .icon_share {
      width: 20px;
      height: 20px;

      @include media($break790) {
        width: 20px;
      }
    }

    .dropdown_btn, .dropdown_btn2 {
      width: 100%;
      max-width: 100%;
      margin-bottom: 0;
      height: 40px;
    }

    .dropdown_btn2 {
      background-position: 93% center;
    }

    .loading_progressBar_container {
      margin-bottom: 1rem;
    }

    .loading_progressBar {
      margin-bottom: .5rem;
    }

    .loading_progressTxt {
      font-size: .85rem;
      font-weight: 300;
    }

    .campaignDropdown_container, .networkDropdown_container {

      .dropdown_btn {
        background-position: 93% center;
        padding: .5rem 2.1rem .5rem .95rem;
      }
    }

    .campaignDropdown_list, .networkDropdown_list {
      display: block;
      width: 100%;

      &:before, &:after {
        right: 8px;
        left: auto;
      }
    }

    .scroll_wrap {
      display: block;
      width: 265px;
      top: 35px;

      &:before, &:after {
        left: 232px;
      }

      .actual_list {
        max-height: 300px;
        overflow-y: auto;
      }
    }

    .networkDropdown_list {

      li {
        display: block;

        a {
          display: block;
          padding: .4rem .5rem .3rem;
        }
      }

      .tooltip_container {
        margin-left: .25rem;
      }

      .source_icon {
        width: 17px;
        vertical-align: -3px;
        margin-right: .25rem;
      }
    }

    .autocomplete {
      display: block !important;
    }

    .standard_label {
      font-weight: 600;
      font-size: .85rem;
    }

    .standard_filter_section {
      margin-bottom: 1rem;
    }

    .tags_section {
      .tag-item {
        span {
          text-transform: capitalize;
        }
      }
    }

    .networkDropdown_container {
      text-transform: capitalize;

      .dropdown_btn {
        text-transform: capitalize;

        .source_icon {
          vertical-align: -4px;
          margin-right: .4rem;
          width: 20px;
        }
      }
    }

    .img_icon {
      width: 20px;
    }

    .img_icon_black {}

    .img_icon_white {
      display: none;
    }

    .video_icon {
      margin: 3px 0;
    }

    .video_icon_black {}

    .video_icon_white {
      display: none;
    }

    .source_icon {
      display: inline-block;
    }

    .active_filter_item {

      .video_icon_black, .img_icon_black {
        display: none;
      }

      .video_icon_white, .img_icon_white {
        display: block;
      }
    }

    .gDrive_sync_btn {
      border-radius: 100px;
      border: none;
      font-size: .8rem;
      padding: .5rem;

      a {
        text-decoration: none;
      }

      .icon_sync_gdrive {
        width: 20px;
        margin-right: .25rem;
        vertical-align: -6px;
      }
    }

    .gDrive_synced_btn {
      border: 1px solid $lightgray_border;

      .icon_sync_gdrive {
        width: 24px;
        margin-right: .3rem;
        vertical-align: -3px;
      }

      .icon_chevron {
        width: 8px;
        margin-left: .3rem;
        vertical-align: -1px;
      }
    }

    .gDrive_sync_btn, .gDrive_synced_btn {
      display: block;
      width: 100%;
      margin-bottom: 1rem;
      padding: .5rem;
    }

    .mobileFilter_controls_container {
      display: none;

      @include media($break790) {
        display: block;
      }

      .mobileFilters_dropdown_list {
        width: 275px;
        padding: 1rem;
        top: 43px;
        right: -8px;

        @include media($break540) {
          right: -3px;
        }

        @include media($break475) {
          width: 100%;
        }

        &:after, &:before {
          left: 237px;

          @include media($break475) {
            right: 13px;
            left: auto;
          }
        }

        .removeTag_btn {
          display: inline-block;
          width: auto;
        }
      }

      .mobile_sortDropdown_list {}\

      .gDrive_synced_btn {}

      .filters_container {
        display: block;
      }

      .mobileFilters_okay_btn {
        display: block;
        width: 100%;
      }

      .filterDropdown_container, .sortDropdown_container {
        float: left;
      }

      .filterDropdown_container {
        width: 49%;
        margin-right: 1rem;

        @include media($break565) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 1rem;
        }
      }

      .sortDropdown_container {
        @include media($break565) {
          width: 100% !important;
          margin-right: 0;
          margin-bottom: 1rem;
        }
      }

      .mobile_sortDropdown_list {

        @include media($break475) {
          width: 100%;

          &:before, &:after {
            right: 13px;
            left: auto;
          }
        }
      }

      .dropdown_btn {
        padding: .45rem 1.75rem .5rem .75rem;
        background-size: 17px;
      }

      .btn_txt {
        font-size: 1rem;
      }

      .sortDropdown_container {
        width: 48%;
      }
    }

    .host {

      &:active, &:focus {
        outline: none;
      }
    }
  }

  .rightCol {
    width: calc(100% - 292px);
    position: relative;

    @include media($break790) {
      float: none;
      width: 100%;
    }

    .contentList_toolbar {
      padding: 6px 0 0 14px;
      height: 51px;
      position: fixed;
      width: 100%;
      z-index: 5;
      background-color: rgba(255,255,255,.95);

      @include media($break790) {
        position: relative;
        padding: 6px 0 0 0;
      }

      .selectActions_container {
        float: left;
      }

      .selectAll_container {
        float: left;
        margin-top: 10px;

        .control-indicator {
          top: 1px;
        }

        .selectAll_txt_container {}

        .selectedTxt_container {
          font-weight: 600;
        }

        .selectedAmount {
          color: white;
          background-color: $revfluence-blue;
          border-radius: 100px;
          padding: 1px 9px;
          margin-right: 1px;
          font-size: .75rem;
          vertical-align: 1px;
        }
      }

      .checkbox_control {}

      .disabled_contentActions_list {
        opacity: .3;
      }

      .content_actions_list {
        float: left;
        padding: 0 1rem;

        @include media($break400) {
          display: none;
        }
      }

      .tooltip {
        top: 46px;
      }

      .permissions_action_item {
        display: inline-block;
      }

      .sortDropdown_container {
        float: left;
        margin-top: 4px;
      }

      .sort_btn_txt {
        margin-right: 2px;
      }

      .sort_btn {
        background: none;
        padding: 6px 8px;
        border-radius: 6px;

        @include media($break790) {
          display: none;
        }

        &:hover {
          background-color: #F6F6F6;
        }
      }

      .sortDropdown_indicator_icon {
        width: 11px;
      }

      .sort_dropdown_list {
        display: block;
        right: -9px;
      }

      .icon_sort_checkmark {
        width: 13px;
      }

      .content_action_item {
        display: inline-block;
      }

      .content_action_btn {
        background: none;
        padding: 8px;
        width: 40px;
        height: 40px;
        border-radius: 6px;

        &:hover {
          background-color: #F6F6F6;
        }
      }

      .content_action_icon {
        width: 100%;
      }
    }

    .noContent_placeholder {
      background-color: $ultraLight_gray;
      text-align: center;
      padding: 1rem;
      color: gray;
      border-radius: 6px;
      margin-top: 4rem;

      .noContent_title {
        font-weight: 300;
        font-size: 1rem;
        color: $dark-text;
      }

      .addContent_btn {
        margin-top: .5rem;
      }
    }

    .groupTitle_container {
      border-bottom: 1px solid $lightgray-border;
      margin-bottom: 1rem;
    }

    .group_title {
      padding: .25rem 1rem;
      background-color: white;
      display: block;
      margin: -15px auto;
      width: 89px;

      @include media($break790) {
        margin-top: 0;
      }
    }

    .contentList_wrapper {
      padding-top: 4rem;
    }

    .contentList {

      @include media($break400) {
        padding-bottom: 4rem;
      }

      .imgThumbnail {
        max-width: 100%;
        width: 100%;
        border-radius: 6px;
        border: 1px solid $lightgray-border;
      }

      .control-indicator {}

      .permissions_indicator {
        background: url('/images/style_guide/gradientBottom_bg.png') center -1px no-repeat;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 7px 10px;
        display: none;
        background-size: 100%;
      }

      .permissions_icon {
        width: 12px;
        margin-right: 2px;
        vertical-align: -2px;
      }

      .permissions_txt {
        color: white;
        font-weight: 400;
        font-size: .8rem;
        text-shadow: 1px 1px rgba(0,0,0,.3);
      }

      .contentList_item {
        float: left;
        width: 23.5%;
        margin-right: 2%;
        padding: 10px;
        border-radius: 6px;
        opacity: 0;

        &.angular-grid-item {
          opacity: 1.0;
        }

        @include media($break565) {
          width: 50%;
          margin-right: 0;
          padding: 8px;
        }

        &:hover {
          background-color: #F6F6F6;

          @include media($break565) {
            background-color: none;
          }

          .permissions_indicator {
            display: block;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }

      /* Use this class dynamically for content with unset permissions */
      .unset_permissions {
        display: block !important;
      }

      .imgContainer {
        position: relative;
        margin-bottom: .8rem;
      }

      .content_top_shadow {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        opacity: .3;
      }

      .searchContent_btn {
        right: 5.55rem;
      }

      .likeContent_btn {
        right: 3.25rem;
      }

      .checkbox_control {
        z-index: 2;
        padding: 0;
      }

      .control-indicator {
        z-index: 2;
        top: 12px;
        left: 13px;
        border: 1px solid lightgray;
      }

      .mediaType_icon_container {
        position: absolute;
        right: 1rem;
        top: .8rem;
        background-color: white;
        padding: 4px;
        border-radius: 3px;
        width: 25px;
        height: 25px;
        z-index: 3;
      }

      .icon_play {
        width: 11px;
      }

      .icon_view {
        width: 22px;
      }

      .video_icon {
        margin-top: 3px;
        width: 100%;
      }

      .img_icon {
        width: 100%;
      }

      .contentDetails_container {
        margin-bottom: .8rem;
      }

      .avatar_container {
        width: 40px;
        position: relative;
        float: left;
        margin-right: 9px;
        margin-top: 2px;
      }

      .avatarAccount_link {}

      .creator_avatar_img {
        max-width: 100%;
        border-radius: 100px;
        width: 32px;

        &:hover {
          opacity: .85;
        }
      }

      .icon_raw_img {
        width: 18px;
        display: block;
        margin: 4px auto 0;
      }

      .network_icon_container {
        background-color: white;
        width: 23px;
        height: 23px;
        border-radius: 100px;
        position: absolute;
        bottom: -7px;
        right: 0;
        border: 1px solid $lightgray-border;
      }

      .network_icon {}

      .youtube_icon {
        width: 12px;
        display: block;
        margin: 6px auto 0;
      }

      .instagram_icon {
        width: 14px;
        display: block;
        margin: 4px auto 0;
      }

      .contentDetails_info_container {
        float: left;
        width: calc(100% - 54px);
      }

      .contentDetails_txt {
        float: left;
        width: calc(100% - 118px);

        .admin_link {
			display: block;
			margin-top: 1em;
			font-size: 1.25em;
			text-decoration: none;
			color: $revfluence-blue;
			padding-bottom: 1rem;

			&:hover {
				color: $revfluence-blue-hover;
			}
        }
      }

      .creatorName_txt, .dateCreated_txt {
        font-size: .85rem;
      }

      .creatorName_txt {
        font-weight: 600;
        display: block;
      }

      .accountName_link {
        color: $dark-text;
        text-decoration: none;

        &:hover {
          color: $revfluence-blue;
        }
      }

      .dateCreated_txt {
        display: block;
      }

      .contentActions_container {
        float: right;
        padding-top: 2px;
      }

      .content_actionLink {
        background: none;
        padding: 8px;
      }

      .visitManage_btn {}

      .visistContent_btn {}

      .visitManage_icon {
        width: 20px;
      }

      .visitContent_icon {
        width: 16px;
      }

      .contentTags_container {}

      .contentTags_list {}

      .remove_tag_btn {
        display: none;
        background: url('/images/style_guide/tag_gradient_bg.png') center no-repeat;
        position: absolute;
        right: 0;
        top: 0;
        padding: 5px 4px 4px 8px;
      }

      .remove_tag_icon {
        width: 10px;
      }

      .contentTags_item {
        display: inline-block;
        margin: 0 3px 5px 0;
        background: white;
        padding: 5px 6px;
        font-size: .75rem;
        border: 1px solid $lightgray-border;
        border-radius: 4px;
        font-weight: 300;
        position: relative;
        text-transform: capitalize;

        &:last-child {
          margin-right: 0;
        }

        &:hover {

          .remove_tag_btn {
            display: block;
          }
        }

        .icon_addTag {
          width: 12px;
        }
      }

      .addTag_item {
        vertical-align: -2px;
        margin-right: 0;

        .icon_addTag_link {
          padding: 0;
          border: none;
          background: none;
          cursor: pointer;
        }
      }

      .overflowTags_item {
        display: inline-block;
        margin: 0 3px 5px 0;
        position: relative;
      }

      .firstContent_item {
        color: $lightgray_txt;
        border: none;
        background: none;
        font-style: italic;
      }

      .overflowTags_dropdown {
        padding: .5rem;
        top: -46px;
        right: -10px;

        .editTag_btn {

          &:hover {
            color: $dark-text;
            background-color: $ultraLight_gray;
          }
        }
      }

      .moreTags_item {

        &:hover {

          .overflowTags_dropdown {
            display: block !important;
          }
        }
      }

      .overflowTags_btn {
        border-radius: 4px;
        border: 1px solid $lightgray-border;
        font-weight: 300;
        font-size: .75rem;
        padding: 5px 7px;
        vertical-align: 3px;
        height: 24px;
        display: inline-block;
        background: white;
      }

      .automaticTag {
        background: $lightblue;

        .remove_tag_btn {
          background: url('/images/style_guide/tag_gradient_bg_auto.png') center no-repeat;
        }
      }
    }
  }

  .mobile_contentActions_bar {
    display: none;
    border-top: 1px solid $lightgray-border;
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;

    @include media($break400) {
      display: block;
    }

    .mobile_contentActions_list {}

    .mobile_contentActions_item {
      float: left;
      width: 25%;
    }

    .mobile_contentActions_btn {
      background: none;
      border-radius: 0;
      padding: 17px 37px;
    }

    .permissions_icon {
      width: 19px;
    }

    .download_icon {
      width: 27px;
      margin-top: 4px;
    }

    .tag_icon {
      width: 23px;
    }

    .remove_icon {}

    .content_action_icon {}
  }

  .loadingSpinner_container {
    padding-top: 4rem;
    padding-bottom: 2rem;

    .loading_icon {
      display: block;
      margin: 0 auto;
      width: 75px;
    }
  }

  .dropdown_list {
    top: 39px;
    left: 0;

    &:before, &:after {
      display: none;
    }
  }

  .hint_icon {
    width: 20px;
    height: 20px;
  }
}
