.aspireiq_theme {

  .simpleTabs_list {

    .simpleTabs_link {

      &:hover {
        color: $aiq_darkblue;
      }
    }

    .active_simpleTab_link {
      color: $aiq_darkblue;

      &:hover, &:focus {
        color: $aiq_darkblue;
      }
    }
  }

  .tabs_container {

    .nav_tabs>li>a {

      &:hover {
        background-color: white;
      }
    }
  }

  .tab_nav {

    .nav_links_list {

      .active_link {
        border-bottom: 1px solid $aiq_darkblue;
      }
    }
  }
}
