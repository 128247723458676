.aspireiq_theme {

  .rightCol {

    .contentList_toolbar {

      .selectAll_container {

        .selectedAmount {
          background-color: $aiq_darkblue;
        }
      }
    }
  }

  .leftCol {

    .img_icon, .video_icon {
      width: 20px;
    }

    .toggle_filter_button {
      height: 38px;
    }
  }
}
