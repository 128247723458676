.add_viewsSnap_modal {

  .close_modal_btn {}

  .add_snapViews_graphic {
    width: 70px;
    display: block;
    margin: 12px auto 0;
  }

  .add_snapViews_title {
    text-align: center;
    padding: .25rem 0 1.25rem;

    @include media($break650) {
      font-size: 1.8rem;
    }

    @include media($break375) {
      font-size: 1.45rem;
      padding: .25rem 0 .75rem;
    }
  }

  .addViews_main_container {
    padding: 0 2rem 2rem;
  }

  /* Left col - Drag & Drop Images */
  .addSnap_views_dragDrop {
    float: left;
    width: 200px;
    margin-right: .5rem;

    @include media($break560) {
      float: none;
      margin: 0 auto;
      padding-bottom: 1.5rem;
    }

    .drag_drop_container {

      .dropped_slot_item {
        height: 100%;
      }

      .dropped_img_wrapper {
        height: 100%;
      }
    }
  }

  .addSnap_viewsLabel {
    font-weight: 600;
    display: block;
    padding-bottom: .5rem;
  }

  .contentImg_form {}

  .active_dragdrop_indicator {}

  .active_dragdrop_icon {}

  .drop_slot_list {}

  .drop_slot_container {}

  /* Right col */
  .addSnap_views_txt_container {
    float: left;
    width: calc(100% - 208px);

    @include media($break560) {
      width: 100%;
      float: none;
    }

    p {
      margin-bottom: .5rem;
    }

    .addSnap_views_instructionsContainer {

      @include media($break560) {
        float: left;
        width: calc(100% - 125px);
      }
    }

    .addSnap_views_instructions {}

    .screenshotGuide_txt {
      padding-bottom: .5rem;
      display: block;
    }

    .snap_views_screenshot {
      width: 125px;
      padding-bottom: 1.45rem;
    }

    .addSnap_views_actionsContainer {
      padding-bottom: 0;

      @include media($break560) {
        display: none;
      }
    }

    .addSnap_views_mobileActions {
      display: none;

      @include media($break560) {
        display: block;
      }

      @include media($break380) {
        margin-top: .5rem;
      }
    }

    .finishAdding_views_btn {
      margin-right: .25rem;
    }

    .skipAdding_views_btn {}

    .screenshotGuide_txt {}

    .addSnap_views_mobileActions {

      .uploadScreenshot_btn {
        display: block;
        width: 100%;
        margin-bottom: 1rem;
      }

      .addSnap_viewsUpload_icon {
        vertical-align: -4px;
        margin-right: .5rem;
      }

      .addSnap_views_skip {
        display: block;
        width: 100%;
      }
    }

    .confirmViews_instructions {
      font-weight: 600;
      margin-bottom: .6rem;
    }

    .add_snapViews_form {

      .standard_label {
        font-weight: 600;
      }

      .snapViews_input {
        max-width: 85px;
        margin-bottom: .6rem;
      }

      .submit_snapViews_btn {
        background: $revfluence-blue url('/images/style_guide/icon_checkmark_circle_white.svg') 15px center no-repeat;
        padding: 6px 20px 6px 43px;
      }
    }

    .uploaded_screenshotGuide {
      padding-top: .75rem;
      display: block;
    }

  }
}
