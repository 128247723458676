.creator_rejectReason_modal {

  .modal-dialog {
    padding: 1rem 2rem 2rem;
    overflow: initial;
  }

  .main_header {}

  .main_title {
    max-width: 445px;
    margin: 0 auto;
    font-size: 1.88rem;

    @include media($break620) {
      font-size: 1.7rem;
      line-height: 2.2rem;
    }
  }

  .main_title, .main_descrip {
    text-align: center;
  }

  .main_descrip {
    padding-bottom: 1.25rem;
    font-size: 1rem;
  }

  .actions_list {}

  .list_spacer {
    margin-bottom: 1rem;
  }

  .action_item {
    margin-bottom: .5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .rectangle_btn {
    width: 100%;
    display: block;
    white-space: normal;
    word-wrap: break-word;
    font-weight: 300;
  }
}
