.managerRegistration_form_container {
  padding-bottom: 1rem;

  .managerRegistration_title {
    text-align: center;
    padding-bottom: 1rem;

    @include media($break450) {
      font-size: 2.5em !important;
    }
  }

  .managerRegistration_form {
    max-width: 260px;
    margin: 0 auto;
  }

  .standard_fieldset {
    padding: .25rem 0;
  }

  .nameFieldset {}

  .emailFieldset {}

  .phoneFieldset {}

  .passwordFieldset {}

  .standard_label {}

  .nameInput {}

  .emailInput {}

  .phoneInput {}

  .passwordInput {}

  .form_control {}

  .submitBtn {
    display: block;
    margin: 1.5rem auto 0;
    width: 125px;
  }
}
