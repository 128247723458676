@import "../common";

.direct_offer .aspirex_embedded {
  .choose_campaign {
    border: none;
    margin: 0;
  }

  .compose_terms {
    max-width: 700px;
    margin: 0 auto;
    border: none;

    .main_header {
      border: none;
      padding: 0;

      .main_title {
        @include aspirex_title;
      }

      .main_descrip {
        @include aspirex_subtitle;
      }
    }

    .offer_container {
      border: none;
      margin: 0;
    }
  }
}