.aspireiq_theme {
  .h1, .h2, .h3, .h4, .h5, .h6 .open_sans .lato, input, select, textarea {
    color: $aiq_black;
  }

  ::selection {
    background: $aiq_darkblue; /* WebKit/Blink Browsers */
    color: white;
  }

  ::-moz-selection {
    background: $aiq_darkblue; /* Gecko Browsers */
    color: white;
  }

  .btn_link {
    color: $aiq_darkblue;

    &:hover {
      color: $aiq_lightblue;
    }
  }

  .generic_creator_list_container {

    .main_title {
      font-weight: 600;
      font-size: 2rem;
    }
  }

  .checkList {

    .checkList_item {
      background: url('/images/themes/aspireiq/icon_checkmark_blue.svg') center left no-repeat;
      background-size: 12px;
    }
  }

  .warningTxt {
    color: $aiq_dangerRed;
  }
}
