@keyframes first_modal {

  0% {
    opacity: 0;
    transform: translate(0, 0);
  }

  20% {
    opacity: 100%;
    transform: translate(0, 0);
  }

  75% {
    opacity: 100%;
    transform: translate(0, 0);
  }

  90% {
    opacity: 100%;
    transform: translate(0, -150px);
  }

  100% {
    opacity: 100%;
    transform: translate(0, -150px);
  }
}

@keyframes second_modal {

  0% {
    opacity: 0;
    transform: translate(0, -160px);
  }

  50% {
    opacity: 0;
    transform: translate(0, -160px);
  }

  90% {
    opacity: 0;
    transform: translate(0, -160px);
  }

  100% {
    opacity: 100%;
    transform: translate(0, -160px);
  }
}

@keyframes move_second_modal {

  0% {
    transform: translate(0,-200px);
  }

  100% {
    transform: translate(0,-200px);
  }
}

.termsAgreed_modal {

  /* Congrats Modal */
  .congrats_modal {
    position: relative;
    margin: 200px auto 2rem;
    animation-name: first_modal;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-delay: 0;
    animation-direction: alternate;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-play-state: running;

    @include media($break610) {
      margin: 0 auto 1rem;
    }

    .close_modal {
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
    }

    .icon_dismiss_modal {
      width: 16px;
    }

    .content_wrapper {
      padding: 0 0 1.75rem;
      text-align: center;
    }

    .img_wrapper {
      width: 220px;
      margin: 0 auto;
    }

    .handshake_gif {
      max-width: 100%;
    }

    .txt_wrapper {
      margin-top: -21px;
    }

    .congrats_title {
      font-size: 2.2rem;
    }

    .congrats_descrip {
      font-size: 1.13rem;
    }
  }

  .getTxt_alerts_modal {
    animation-name: second_modal;
    animation-duration: 2.5s;
    animation-timing-function: ease-out;
    animation-delay: 0;
    animation-direction: alternate;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-play-state: running;
  }

  .modal-dialog {
    top: 0%;
  }
}

.phoneCollect_modal {
  padding: 3rem 0;
  height: 100%;

  @include media($break610) {
    padding: 2rem 0;
  }

  .modal-dialog {
    left: 0%;
  }

  .congrats_modal, .getTxt_alerts_modal {
    position: relative;
    @include media($break610) {
      width: 95%;
    }
  }

  .getTxt_alerts_modal_container {
    margin: auto;
  }

  /* Text Alert Modals */
  .getTxt_alerts_modal {
    background-color: $helpful_yellow;
    margin: 0 auto;
    padding: 13px 0 20px;
    transform: none;

    .modal-dialog {
      top: 0%;
    }

    .close_modal {
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
    }

    .getAlerts_title {
      font-size: 1.85rem;
    }

    .icon_dismiss_modal {
      width: 16px;
    }

    .content_wrapper {}

    fieldset {
      max-width: 285px;
      padding-bottom: .7rem;
    }

    .img_wrapper, .txt_wrapper {
      float: left;
    }

    .img_wrapper {
      width: 125px;
      padding-top: 1.25rem;

      @include media($break600) {
        width: 100px;
      }

      @include media($break375) {
        width: 80px;
      }
    }

    .txtAlerts_gif {
      width: 50px;
      margin: 0 auto;
      display: block;
    }

    .txt_wrapper {
      width: calc(100% - 125px);
      padding-right: 1rem;

      @include media($break600) {
        width: calc(100% - 100px);
      }

      @include media($break375) {
        width: calc(100% - 80px);
      }
    }

    .getAlerts_title {}

    .getAlerts_form {}

    .standard_label {
      font-weight: 600;
      font-size: 1.13rem;
      padding-bottom: .5rem;
    }

    .phoneNumber_input, .save_btn {
      float: left;
    }

    .phoneNumber_input {
      font-size: 1.13rem;
      width: calc(100% - 94px);
      border-radius: 6px 0 0 6px;

      @include media($break375) {
        width:100%;
        margin-bottom: .5rem;
        border-radius: 6px;
      }
    }

    .save_btn {
      background: $revfluence-blue url('/images/style_guide/icon_checkmark_circle_white.svg') 14px center no-repeat;
      width: 94px;
      border-radius: 0 6px 6px 0;
      padding: 9px 11px 9px 35px;
      font-size: 1rem;

      &:hover {
        background: $revfluence_blue_hover url('/images/style_guide/icon_checkmark_circle_white.svg') 14px center no-repeat;
      }

      @include media($break375) {
        border-radius: 6px;
      }
    }

    .txtAlerts_disclaimer {
      font-size: .96rem;
    }

    .standard_link {
      font-weight: 500;
    }

    /* Stage 1 - Enter phone number */
    .enter_phoneNumber_wrapper {}

    /* Stage 2 - Enter phone number */
    .loading_wrapper {
      position: relative;
      height: 110px;

      .icon_loading {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 70px;
        margin-left: -63px;
        margin-top: -22px;
      }
    }

    /* Stage 3 - Verify phone number */
    .verify_phoneNumber_wrapper {

      .verify_label {
        font-size: 1rem;
      }

      .resend_code_link {
        font-size: 1rem;
      }

      .getAlerts_title {
        font-size: 1.65rem;
      }

      fieldset {
        max-width: 200px;
      }

      .phoneNumber_input {
        width: calc(100% - 94px);
      }

      .verify_btn {
        background: $revfluence-blue url('/images/style_guide/icon_checkmark_circle_white.svg') 10px center no-repeat;

        &:hover {
          background: $revfluence_blue_hover url('/images/style_guide/icon_checkmark_circle_white.svg') 10px center no-repeat;
        }
      }
    }

    /* Stage 4 - Successfully signed up for email alerts */
    .successfully_added_wrapper {

      .successfully_added_descrip {
        padding-bottom: .6rem;
        font-size: 1rem;
      }

      .ok_btn {}
    }

  }
}
