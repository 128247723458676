.creator_profile {

  .bio_container {
    margin-bottom: 1.5rem;

    .section_title {
      margin-bottom: .4rem;

      @include media($break875) {
        display: none;
      }

      .edit_btn {
        margin-left: .7rem;
        display: inline-block;
      }

    }

    .smallScreen_edit_btn {
      display: none;
      margin-top: .5rem;
      padding: 0;

      @include media($break875) {
        display: block;
      }
    }

    .bio_txt {}
  }
}
