.branded_creatorLog_in {
  background: none;
  padding: 0;

  .contentRow {
    width: 100%;
    overflow: hidden;
  }

  .contentRow_list {
    width: 10000%;
  }

  .contentRow_item {
    float: left;
    max-height: 247px;
    width: auto;
  }

  .brandContent_img {
    max-width: 100%;
    display: block;
    max-height: 247px;
  }

  .brandLogo {
    border: 4px solid white;
    border-radius: 3px;
    margin: -3rem auto 0;
    width: 93px;
    background: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.23);
    position: relative;
    z-index: 2;

    @include media($break325) {
      width: 80px;
    }
  }

  .brand_logo {
    max-width: 100%;
  }

  .main_header {}

  .mainTitle {
    font-size: 2.85rem;
    font-weight: 300;
    text-align: center;
    padding: 2rem 0 .75rem;

    @include media($break800) {
      font-size: 2.35rem;
    }

    @include media($break575) {
      font-size: 2rem;
    }
  }

  .mainDescrip {
    max-width: 630px;
    margin: 0 auto;
    padding: 1rem 0;
    text-align: center;
  }

  .disclaimer_list {
    background-color: $aiq_lightblue_bg;
    border-radius: 4px;
    padding: 1rem 5rem;
    font-weight: 600;
    max-width: 650px;
    margin: .75rem auto .15rem;
    line-height: 1.5rem;

    @include media($break600) {
      padding: 1rem 1.25rem;
    }
  }

  .bullet {
    width: 15px;
    margin-right: .5rem;
    vertical-align: -1px;
  }

  .disclaimer_item {

    @include media($break500) {
      padding-bottom: .5rem;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .signupIntro {
    text-align: center;
    padding: 1.5rem 0 2rem;
  }

  .forms_wrap {
    max-width: 850px;
    margin: 0 auto;

    fieldset {
      padding-bottom: .6rem;
    }

    .btn_primary {
      display: block;
      width: 100%;
      border: none;
      margin-top: .6rem;
    }
  }

  .loginCol {
    float: left;
    width: 50%;
    padding: 0 2rem;

    @include media($break650) {
      width: 100%;
      padding: 0 1rem;
    }
  }

  .loginCol_title {
    text-align: center;
    font-weight: 600;
    padding-bottom: 1rem;
  }

  .network_logins_list {}

  .network_logins_item {
    margin-bottom: .75rem;
    a {
      text-decoration: none;
    }

    .btn_primary {
      text-decoration: none;
    }
  }

  .signup_btn {
    border-radius: 4px;
  }

  .btn {

    @include media($break1260) {
      padding: 10px 23px;
      font-size: .9rem;
    }
  }

  .icon_network {
    width: 17px;
    margin-right: .2rem;
  }

  .icon_ig {
    vertical-align: -1px;
  }

  .icon_yt {
    vertical-align: 1px;
  }

  .formCol {
    border-left: 1px solid $lightgray-border;

    @include media($break650) {
      border-left: none;
      border-top: 1px solid $lightgray-border;
      padding-top: 1.5rem;
      margin-top: 1.5rem;
    }
  }

  .loginCol_title {}

  fieldset {}

  .standard_label {}

  .form_control {}

  .btn_primary {}

  .alreadyAcct {
    text-align: center;
    padding: 2.4rem 0 3rem;
  }

  .mainDescrip, .disclaimer_list, .signupIntro, .loginCol_title, .alreadyAcct  {
    font-size: 1.14rem;
    line-height: 1.9rem;

    @include media($break800) {
      font-size: 1rem;
      line-height: 1.5rem;
      margin: 0 1rem;
    }
  }
}
