/* Use this to display the 5 star reviews correctly */
.star_reviews_list {
  margin-right: .5rem;
  margin-top: .1rem;
  display: block;

  .star_review_item {
    margin-right: .25rem;
    float: left;
    position: relative;

    &:last-child {
      margin-right: 0;
    }
  }

  .review_icon {
    width: 19px;
    height: 18px;
  }
}

/* Star Reviews List Example HTML
<ul class="star_reviews_list clearfix">
  <li class="star_review_item"><img src="/images/new_manage/earned_star_icon.svg" alt class="review_icon"></li>
  <li class="star_review_item"><img src="/images/new_manage/earned_star_icon.svg" alt class="review_icon"></li>
  <li class="star_review_item"><img src="/images/new_manage/earned_star_icon.svg" alt class="review_icon"></li>
  <li class="star_review_item"><img src="/images/new_manage/earned_star_icon.svg" alt class="review_icon"></li>
  <li class="star_review_item"><img src="/images/new_manage/unearned_star_icon.svg" alt class="review_icon"></li>
</ul>
*/
