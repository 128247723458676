.new_manage {

  .intro_creator {

    .introCreator_header {
      padding-bottom: 1.25rem;

        .mobile {
          display: none;

          @include media($break800) {
            display: inline-block;
          }
        }

        .desktop {
          @include media($break800) {
            display: none;
          }
        }
    }

    .visualWrap {
      flex: .5;
      text-align: center;
    }

    .txtWrap {
      flex: 2;
    }

    .actionsWrap {
      flex: .5;
      text-align: center;
    }

    .icon_watchTut {
      width: 40px;
    }

    .introCreator_title {
      font-size: 1.4rem;
      padding-bottom: .25rem;
    }

    .introCreator_desc {}

    .nextSteps_list {
      padding-bottom: 1.35rem;

      .nextSteps_item {}

      .visualWrap {}

      .icon_watchTut {}

      .txtWrap {}

      .nextSteps_title {
        display: block;
        font-weight: 700;
      }

      .nextSteps_desc {
        display: block;
      }

      .img_campaign {
        width: 40px;
        border-radius: 3px;
      }
    }

    .contactUs_help {
      padding: 1rem 1rem 1.5rem;

      .contactUs_title {
        padding-bottom: 1rem;
        text-align: center;
        font-size: 1.3rem;
      }

      .row {
        display: flex;
        align-items: center;
        background: url('/images/manage/border_hint.svg') center repeat-y;  

        @include media($break1360) {
          background: none;
        }

        @include media($break630) {
          display: block;
        }
      }

      .contactAIQ {
        flex: 2;
        text-align: center;

        @include media($break630) {
          padding-bottom: 2rem;
        }
      }

      .divider {
        flex: 1;
        text-align: center;

        @include media($break630) {
          padding-bottom: 1.3rem;
        }
      }

      .contactBrand {
        flex: 2;
        text-align: center;

        &.mobile {
          display: none;

          @include media($break800) {
            display: block;
          }
        }

        &.desktop {
          @include media($break800) {
            display: none;
          }
        }

        .btn_replyBrand {
          &.mobile {
            display: none;

            @include media($break800) {
              display: inline-block;
            }
          }

          &.desktop {
            @include media($break800) {
              display: none;
            }
          }
        }
      }

      .wrapTxt {
        padding-bottom: .45rem;
      }

      .colTitle {
        font-weight: 700;
        display: block;
        padding-bottom: .25rem;
      }

      .recommend {
        font-weight: 700;
        color: $aiq_pink;
        font-style: italic;
        display: block;
        padding-bottom: .25rem;
      }

      .desc {
        margin-bottom: 0;
      }

      .icon_intercom {
        width: 50px;
        display: block;
        margin: 0 auto 1.25rem;
      }

      .img_reply {
        max-width: 214px;
        margin: 0 auto 1.25rem;
      }

      .icon_intercom, .img_reply {
        display: block;

        @include media($break800) {
          display: none;
        }
      }

      .img_mobileSup, .img_mobileBrand {
        display: none;
        max-width: 270px;
        margin: 0 auto 1.25rem;

        @include media($break800) {
          display: block;
        }
      }

      .btn_contactAIQ {}

      .txtOr {
        background-color: $aiq_nude;
        padding: .5rem;
        display: block;
        color: #C6B9B0;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: .8rem;
        font-weight: 800;
      }

      .btn_msgBrand {}
    }
  }
}
