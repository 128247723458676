.aspireiq_theme {

  .addItem_icon, .icon_help_center, .icon_completedTask, .icon_activeTool_alert, .hint_icon, .icon_addItem, .add_qtyicon, .subtract_qty_icon, .edit_icon, .icon_download_pdf {
    display: none;
  }

  .main_actions_container {

    .offer_icon, .like_icon, .skip_icon {
      display: none;
    }
  }

  .tos_header {

    .rev_logo {
      display: none;
    }
  }

  .aiq_theme_icons {
    display: inline-block !important;
  }

  .tos_header {

    .aiq_theme_icons {
      display: block !important;
      width: 384px;
      padding: 3rem 0;
    }
  }
}
