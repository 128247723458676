.finish_setup {
	background: #1a1a1a url('../images/creator_signup/background-image.jpg') center center no-repeat fixed;
	padding-top: 5em;
	background-size: cover;
	@include media($break730) {
		padding-top: 3em;
	}

	.revfluence-logo {
		a {
			display: block;
			width: 199px;
			height: 43px;
			background: url('../images/creator_signup/revfluence-logo.png') center no-repeat;
			margin: 0 auto;
			text-indent: -9999px;
		}
	}

	.main {
		margin-top: 12em;
		padding-bottom: 6em;
		overflow: hidden;
		@include media($break730) {
			margin-top: 3em;
		}

		.intro_container {
			margin-bottom: 1em;
			margin-bottom: 5em;
			padding: 0 2em;
		}

		.intro_title {
			@include title50;
			color: white;
			font-weight: 300;
			text-align: center;
			margin-bottom: .25em;
			@include media($break980) {
				@include title50;
			}
			@include media($break730) {
				@include title40;
			}
			@include media($break530) {
				@include title25;
				font-weight: 500;
			}
		}

		.intro_descrip {
			@include fontsize16;
			color: white;
			text-align: center;
		}

		.error_container {
	    @include errors;
	    width: 950px;
	    @include media($break975) {
	    	width: 95%;
	    }

	    .error_descrip {
	    	color: white;
	    	@include fontsize16;
	    	text-align: center;
	    }

	    a {
	    	color: white;
	    	text-decoration: underline;

	    	&:hover {
	    		text-decoration: none;
	    	}
	    }
	  }

		.emailSignup_form {
			width: 450px;
			margin: 0 auto;
			@include media($break485) {
				width: 95%;
			}
		}

		fieldset {
			margin-bottom: 2em;
		}

		label {
			@include standard-label;
			color: white;
		}

		.username_input {
			@include fontsize18;
			border: 1px solid #7f7f7f;
			background-color: rgba(0,0,0,.5);
			display: block;
			@include standard-radius;
			color: white;
			width: 100%;
			padding: .85em;

			&:focus {
				border: 1px solid $revfluence-blue;
				background-color: rgba(0,0,0,.7);
				outline: none;
			}
		}

		.email_input {
			@include fontsize18;
			border: 1px solid #7f7f7f;
			background-color: rgba(0,0,0,.5);
			display: block;
			@include standard-radius;
			color: white;
			width: 100%;
			padding: .85em;

			&:focus {
				border: 1px solid $revfluence-blue;
				background-color: rgba(0,0,0,.7);
				outline: none;
			}
		}

		.password_input {
			@include fontsize18;
			border: 1px solid #7f7f7f;
			background-color: rgba(0,0,0,.5);
			display: block;
			@include standard-radius;
			color: white;
			width: 100%;
			padding: .85em;

			&:focus {
				border: 1px solid $revfluence-blue;
				background-color: rgba(0,0,0,.7);
				outline: none;
			}
		}

		.emailSignup_button {
			display: block;
			width: 100%;
			margin-bottom: 2em;
			padding: 1.13em;
		}
	}
}