.ads_reports {
  font-weight: 400;
  height: 100%;

  .reports_mainHeader {
    padding: 4.25rem 0 2rem;
    background-color: white;
    margin-bottom: 1rem;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid $lightgray-border;
    box-shadow: 0 1px 2px rgba(0,0,0,.1);

    @include media($break1440) {
      padding: 3.75rem 0 1.25rem;
    }

    @include media($break1300) {
      padding: 4.25rem 1.5rem 2rem;
    }

    .header_inner_container {
      margin: 0 auto;
      max-width: 1300px;
    }

    .reports_mainTitle {
      padding-bottom: .5rem;
      font-size: 2.5rem;

      @include media($break1440) {
        padding-bottom: .25rem;
        font-size: 2rem;
      }

      @include media($break950) {
        font-size: 2rem;
      }

      @include media($break500) {
        font-size: 1.5rem;
      }
    }

    .reports_mainDescrip {
      font-weight: 300;
      font-size: 1.1rem;

      @include media($break1440) {
        font-size: 1rem;
      }
    }
  }

  .reports_toolbar, .standard_table2_wrapper {}

  .standard_table2_wrapper {
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    box-shadow: 1px 1px 1px rgba(0,0,0,.09);
    max-width: 1300px;
    margin: 0 auto;
    height: calc(100% - 290px);

    @include media($break1440) {
      height: calc(100% - 260px);
    }

    td {
      font-size: .85rem;
      padding: 5px 8px;
    }
  }

  .inner_container {
    height: 100%;
  }

  .tableRow_loading_wrapper {
    padding: 1rem 0;

    .tableRow_loading_icon {
      display: block;
      width: 70px;
      margin: 0 auto;
    }
  }

  .icon_content {
    width: 16px;
    vertical-align: -2px;
  }

  .icon_calendar {
    width: 16px;
    margin-right: .22rem;
    vertical-align: -2px;
  }

  .icon_filter {
    width: 15px;
    vertical-align: -2px;
  }

  .reports_toolbar {

    .btn_txt {
      font-weight: 600;
      font-size: .8rem;
    }

    .inner_container {}

    .btn, a, label {
      font-weight: 400;
    }

      .dropdown_btn2 {

        .truncate {
          width: calc(100% - 2px);
        }
      }

      .dropdown_li {

        .truncate {
          width: calc(100% - 21px);
        }
      }

      .editMode_header {
        padding-bottom: .25rem;

        .editMode_header_txt {
          margin-right: .25rem;
          font-weight: 600;
        }

        .rename_report_btn {
          font-size: 1rem;
          padding: 0;
          vertical-align: 0;
          margin-left: .25rem;
        }
      }

      .dropdown_btn2 {
        margin-bottom: 0;
        background-position: 92% center;
        padding: .5rem 2.15rem .45rem .75rem;
      }

    .filterDescrip_wrapper {

      .filterDescrip_txt {
        color: #6C6C6C;
        font-style: italic;
        font-size: .9rem;
        padding-bottom: 1rem;
        padding-top: .5rem;
      }
    }

    .rightGrp {
      float: right;
      width: 81px;
      margin-top: 0;

      @include media($break645) {
        width: 45px;
      }

      .share_tooltip {

        .shareFBAnalyze_btn {
          border: none;
          padding: .7rem;
          margin-bottom: 0;
          text-align: center;

          @include media($break1260) {
            width: auto;
          }
        }

        .icon_share {
          margin-top: -3px;
        }

        .tooltip {
          z-index: 9062;
          width: 152px !important;
          left: -98px !important;

          &:before, &:after {
            left: 109px !important;
          }
        }
      }
    }

    .colSettings_dropdown {
      margin-right: .25rem;

      @include media($break1260) {
        width: auto !important;
      }

      .dropdown_btn2 {
        border: none;
        padding: .556rem;
        margin-bottom: 0;

        @include media($break1260) {
          width: auto;
        }
      }

      .dropdownLeft_icon {
        margin-right: 0;
      }

      .tooltip {
        z-index: 9062;
        width: 152px !important;
        left: -98px !important;

        &:before, &:after {
          left: 109px !important;
        }
      }

      .icon_settings {
        width: 20px;

        @include media($break1260) {
          width: 14px !important;
        }
      }

      .dropdown_container {

        @include media($break1260) {
          width: auto !important;
        }
      }

      .dropdown_list {
        width: 330px;
        &:before, &:after {
          left: 291px;
        }
      }
    }

    .enteredMode {}

    .adSets_container, .adSet_controls {
      float: left;
      margin-right: .75rem;

      &:last-child {
        margin-right: 0;
      }
    }

    .medWidth {
      width: 191px;
    }

    .medLar_width {
      width: 250px;
    }

    .larWidth {
      width: 370px;
    }

    .adAccounts_container {
      margin-right: .5rem;
      float: left;

      .dropdown_list {
        width: 225px;
        left: 0;
        top: 38px;

        &:before, &:after {
          left: 190px;
        }
      }

      .icon_user {
        vertical-align: 0;
        margin-right: .2rem;
        width: 13px;
      }

      .dropdown_txt {
        width: calc(100% - 20px);
        display: inline-block;
      }

      .truncate {
        display: inline-block;
        vertical-align: -5px;
      }
    }

    .adAccount_edit_btn {
      float: left;
      margin-right: .5rem;
      border: none;

      &:active {
        outline: none;
      }

      .icon_edit {}
    }

    .adSets_container {
      width: 380px;

      .dropdown_list {
        width: 380px;
        top: 38px;

        &:before, &:after {
          right: 8px;
          left: initial;
        }
      }

      .actualList {
        max-height: 200px;
        overflow-y: auto;
      }

      .dropdown_li_leftIcon {
        width: 17px;
        margin-right: .25rem;
        vertical-align: -3px;
      }

      .newReport_container {
        border-top: 1px solid $lightgray_border;
        cursor: pointer;
        font-weight: 300;

        &:hover {
          background-color: $revfluence-blue;

          div {
            color: white;
          }
        }

        div {
          padding: .75em 1em .5em;
          color: $dark-text;
          text-decoration: none;
          display: block;
          font-size: .85rem;
        }

        .icon_addItem {
          vertical-align: -6px;
          margin-right: .2rem;
        }
      }
    }

    .icon_adSet {
      width: 17px;
      vertical-align: -2px;
    }

    .adSet_controls {

      .tooltip {
        z-index: 9060;
      }
    }

    .edit_tooltip, .addNew_tooltip, .download_tooltip, .colSettings_tooltip, .share_tooltip {

      .tooltip {
        width: 85px;
        text-align: center;
        left: -23px;
        bottom: 40px;

        &:before, &:after {
          left: 32px;
          bottom: -7px;
        }
      }

      .btn_icon {
        padding: .53rem;
        background-color: white;
      }
      }
    }

    .edit_tooltip {
      margin-right: .25rem;
    }

    .addNew_tooltip {

      .tooltip {
        width: 104px;
        left: -34px;

        &:before, &:after {
          left: 42px;
        }
      }
    }

    .icon_edit {}

    .icon_add {}

    .editMode {

      .backBtn_container, .adSet_name_input, .adAccount_dropdown, .segment_dropdown, .filters_dropdown, .dates_dropdown {
        float: left;
        margin-right: .5rem;

        &:last-child {
          margin-right: 0;
        }
      }

      .icon_user {
        display: none;
      }

      .backBtn_container {
        margin-right: .4rem;
      }

      .back_btn {
        padding: .47rem;
      }

      .back_btn {

        &:hover {
          background-color: $ultraLight_gray;
        }

        &:active, &:focus {
          background-color: $lightgray_bg;
        }
      }

      .adSet_name_input {
        background: url('/images/style_guide/icon_nameTag_gray.svg') .5rem center no-repeat;
        padding: .53em .7em .53em 1.9em;
      }

      .adAccount_dropdown {

        .dropdown_list {
          width: 204px;
          top: 39px;

          &:before, &:after {
            left: 171px;
          }
        }

        .icon_user {
          margin-right: .15rem;
        }
      }

      .segment_dropdown {

        .icon_content {
          margin-right: .2rem;
        }

        .dropdown_accord {
          width: 330px;
          top: 39px;
          right: -8px;

          &:before, &:after {
            left: 293px;
          }
        }

        .icon_user {
          width: 14px;
        }
      }

      .filters_dropdown {

        .icon_filter {
          margin-right: .2rem;
        }

        .dropdown_list {
          width: 350px;
          left: 0;
          top: 39px;

          &:before, &:after {
            left: 163px;
          }
        }

        .truncate {
          width: calc(100% - 0);
        }

        .actual_dropdown_list {
          padding-bottom: .3rem;
        }
      }

      .dates_dropdown {

        .dropdown_list {
          top: 39px;
          width: 200px;
          right: -4px;

          &:before, &:after {
            left: 168px;
          }
        }

        .icon_calendar {}
      }

      .save_report_btn {
        border-radius: 6px;
        padding: 8px 13px;
        margin-right: .3rem;
      }

      .cancelEdit_btn {
        background: none;
        border: none;
        padding: 8px 13px;
        margin-right: .3rem;
        border-radius: 6px;

        &:hover {
          background: $ultraLight_gray;
        }
      }

      .deleteReport_btn {
        color: $error;
        border-radius: 6px;
        background: none;
        padding: 8px 13px;
        cursor: auto;

        &:hover {
          background-color: $ultraLight_gray;
        }
      }
    }

    .actions_container, .download_btn {
      float: left;
    }

    .actions_container {
      margin-right: .5rem;

      .dropdown_list {
        width: 200px;
        top: 38px;

        &:before, &:after {
          left: 166px;
        }
      }

      .dropdown_li_leftIcon {
        width: 15px;
        margin-right: .25rem;
      }
    }

    @include media($break800) {

      .btn {
        margin: .25rem !important;
      }
    }

    @include media($break1260) {

      .btn_txt, .btn {
        font-size: .8rem;
      }

      .dropdownLeft_icon {
        width: 9px !important;
        vertical-align: -1px !important;
      }

      .icon_doubleArrow {
        width: 7px;
        top: 8px;

        @include media($break1260) {
          right: 11px !important;
          top: 7px !important;
          width: 8px !important;
        }
      }

      .editMode_back_btn {
        padding: .25rem .2rem .25rem .25rem !important;
      }

      .icon_dropdown {
        width: 10px;
        top: 12px;

        @include media($break1260) {
          right: 8px !important;
          top: 12px !important;
          width: 11px !important;
        }
      }

      .save_report_btn, .cancelEdit_btn, .deleteReport_btn {
        padding: 4px 9px !important;
      }

      .filterDescrip_txt {
        font-size: .75rem !important;
      }

      .dropdown_list {
        top: 28px !important;

        a {
          font-size: .8rem;
        }
      }

      .icon_back {
        width: 16px !important;
      }

      .adAccounts_container {

        .dropdown_list {

          &:before, &:after {
            left: 122px !important;
            right: initial !important;
          }
        }
      }

      .icon_edit, .icon_tweak {
        width: 15px;
      }

      .btn_icon {
        padding: .38rem;
      }

      .adSets_container {

        .dropdown_list {
          left: 0;

          &:before, &:after {
            left: 121px;
            right: initial;
          }
        }
      }

      .accordSet {

        .btn {
          padding: .25rem 1.6rem .25rem 1.4rem !important;
        }

        .icon_arrow {
          top: .5rem !important;
        }
      }
    }

    @include media($break1225) {

      th {
        font-size: .65rem !important;
      }

      td {
        font-size: 14px !important;
      }
    }
  }

  .editMode_toolbar {
    background-color: #FAFAFA;

    .editMode_header {
      display: block;
    }
  }

  .editMode_back_btn {
    float: left;
    margin-right: .35rem;
    padding: 10px 5px;

    .icon_back {
      width: 20px;
    }
  }

  .graphContainer {
    padding-bottom: 1rem;

    .icon_graph {
      vertical-align: -2px;
      margin-right: .5rem;
    }

    .graph_colWrapper {
      padding-top: 1.25rem;
      padding-bottom: 1.75rem;
    }

    .line_graph {
      float: left;
      margin-right: 2.5rem;
      width: calc(100% - 250px);
    }

    .placeholderGraph {
      max-width: 100%;
    }

    .graphOptions {
      float: left;
      width: 200px;

      .standard_label {
        font-weight: 600;
        margin-bottom: 6px;
      }

      .metrics_container {}

      .yaxis_a_options {}

      .yaxis_a_options, .yaxis_b_options {

        .dropdown_list {
          top: 38px;
          width: 169px;

          &:before, &:after {
            left: 134px;
          }
        }

        .dropdown_container {
          display: inline-block;
          width: calc(100% - 32px);
          margin-right: .5rem;

        }
      }

      .icon_yaxis {
        vertical-align: 2px;
      }

      .compareContainer {

        .dropdown_list {
          width: 202px;
          top: 39px;

          &:before, &:after {
            left: 169px;
          }
        }
      }
    }
  }

  .reports_table {
    font-weight: 400;
    border-radius: 8px;

    .contentTooltip {
      left: -30px;
      bottom: 48px;

      &:before, &:after {
        left: 34px;
      }
    }

    .icon_sortDirection {
      display: none;
      width: 16px;
      padding-left: .25rem;

      &.icon_sortDown {
        display: inline-block;
      }

      &.icon_sortUp {
        display: inline-block;
      }
    }

    .commentCell {
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    thead {
      background-color: white;
      border-radius: 8px 8px 0 0;

      .thead_tooltip {
        display: inline-block;
      }

      tr {
        box-shadow: 0 1px 2px rgba(0,0,0,.15);
      }

      .hint_icon {
        width: 22px;
      }

      .tooltip_txt {
        font-weight: 400;
      }

      th {
        font-size: .76rem;
        font-weight: 600;

        &:hover {
          background-color: $light_action_blue;
        }
      }
    }

    tbody {
      background-color: #EAECF0;

      td {
        .multi_line_text {
          white-space: pre;
          font-size: small;
        }
      }

      tr {

        &:nth-child(even) {
          background-color: $light_action_blue;
        }

        &:hover {
          background-color: #E7EFF9;
        }
      }
    }
  }

  .reportsTable_has_loading {
    height: calc(100% - 230px);
  }

  .noResults_found_wrapper {
    padding: 1rem;
    border-radius: 6px;
    text-align: center;
    margin: 1rem 0;
    font-weight: 400;
    font-size: .9rem;

    .noResults_title {
      font-weight: 600;
    }
  }

  .settings_btn, .shareFBAnalyze_btn {
    width: 37px;
    height: 37px;
  }
