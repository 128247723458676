.creator_profile {

  .top_campaigns {

    @include media($break875) {
      padding: 1rem;
      border-top: 1px solid $lightgray-border;
    }

    .top_campaigns_list {}

    .top_campaigns_item {
      border-bottom: 1px solid $lightgray-border;
      padding-bottom: 1.5rem;
      margin-bottom: 1.5rem;

      &:last-child {
        border-bottom: none;
      }
    }

    .brand_avatar_container {
      width: 40px;
      float: left;
      margin-right: 1.5rem;
      padding-top: .65rem;

      @include media($break875) {
        display: none;
      }
    }

    .mobile_brand_avatar {
      display: none;

      @include media($break875) {
        display: inline;
        margin-right: 1rem;
        border-radius: 6px;
        width: 40px;
      }
    }

    .brand_avatar {
      max-width: 100%;
      border-radius: 6px;
    }

    .campaign_details_container {
      float: left;
      width: calc(100% - 64px);

      @include media($break875) {
        width: 100%;
      }
    }

    .name_txt_container {
      padding-bottom: .7rem;

      @include media($break875) {
        max-width: calc(100% - 97px);
        display: inline-block;
      }
    }

    .brand_name {
      max-width: calc(100% - 40px);
      display: inline-block;

      @include media($break875) {
        max-width: 100%;
      }
    }

    .campaign_info {
      width: 100%;

      @include media($break875) {
        padding-top: .5rem;
      }
    }

    .content_container {
      float: left;
      width: 200px;
      margin-right: 1.65rem;

      @include media($break475) {
        float: none;
        width: 100%;
        margin-right: 0;
        padding-bottom: .75rem;
      }
    }

    .view_content_link {
      display: block;
    }

    .video_thumbnail {
      display: block;
      max-width: 100%;
      transition: all .15s ease-in-out;

      &:hover {
        opacity: .87;
      }
    }

    .txt_container {
      float: left;
      width: calc(100% - 227px);

      @include media($break475) {
        width: 100%;
        float: none;
      }
    }

    .campaign_title {
      font-weight: 600;
      margin-bottom: .7rem;
    }

    .stats_list {
      margin-bottom: .7rem;
    }

    .stats_item {
      display: inline-block;
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }

    .campaign_descrip {

      p {}
    }

    .edit_btn {
      margin-left: .6rem;
      vertical-align: 5px;

      @include media($break875) {
        display: none;
      }
    }

    .edit_icon {}

    .mobile_edit_btn {
      display: none;
      padding: 0;
      margin-left: 0;

      @include media($break875) {
        display: inline-block;
        margin-left: .5rem;
      }
    }
  }
}
