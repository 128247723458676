.style_guide {
	background-color: #fbfbfb;

	.single_tab {
		margin-left: 1em;
	}

	.double_tab {
		margin-left: 2em;
	}

	.triple_tab {
		margin-left: 3em;
	}

	.quad_tab {
		margin-left: 4em;
	}

	.quint_tab {
		margin-left: 5em;
	}

	.branding_bar {
		background-color: white;
		background-color: rgba(255,255,255,.8);
		box-shadow: 1px 1.732px 10px 0px rgba(1, 1, 1, 0.04);
		position: relative;
		z-index: 1;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;

		.inner_container {
			max-width: 1200px;
			margin: 0 auto;
			overflow: hidden;
		}

		.revfluence_logo {
			width: 27px;
			background: url('/images/themes/aspireiq/logo_a_mark.png') center no-repeat;
			background-size: 100%;
			display: block;
			text-indent: -9999px;
			padding: 18px 0;
			float: left;
		}

		.admin_link {
			float: right;
			margin-top: 18px;
			color: gray;
			font-size: 1.3em;
			font-weight: 300;

			&:hover {
				color: $revfluence-blue;
			}
		}
	}

	.main_header {
		padding: 32px 0 48px;
		background: white;
		margin-bottom: 23px;
		border-top: 1px solid $lightgray-border;
		border-bottom: 1px solid $lightgray-border;
		margin-top: 45px;

		.main_title {
			margin-bottom: .25em;
		}

		.main_descrip {
			font-size: 2em;
		}

		.inner_container {
			max-width: 1200px;
			overflow: hidden;
			margin: 0 auto;
		}
	}

	.main_container {
		overflow: hidden;
		max-width: 1200px;
		margin: 0 auto 75px;

		.inner_section {
			margin-bottom: 25px;
		}

		.inner_section_heading {
			margin-bottom: 12px;
		}

		.styleguide_main_nav {
			float: left;
			width: 14%;
			margin-top: 20px;
			position: relative;

			.nav_list {
				position: fixed;
			}

			.nav_item {}

			.nav_link {
				display: block;
				color: gray;
				padding-left: 10px;
				border-left: 1px solid #fbfbfb;
				font-size: 1.25em;

				&:hover {
					border-left: 1px solid $revfluence-blue;
					color: $revfluence-blue;
				}
			}

			.active_nav_link {
				font-weight: 600;
				color: $revfluence-blue;

				&:hover {}
			}

			.subnav_list {
				margin-left: 14px;
				margin-bottom: 1em;
			}

			.subnav_item {}

			.subnav_link {
				display: block;
				font-size: 1.12em;
				color: gray;
				padding-left: 10px;
				border-left: 1px solid #fbfbfb;

				&:hover {
					color: $revfluence-blue;
					border-left: 1px solid $revfluence-blue;
				}
			}

			.nav_link, .subnav_link {
				font-weight: 300;
				line-height: 1.85em;
			}
		}

		.main_content_col {
			float: left;
			width: 83%;
			margin-right: 3%;

			.primary_section_header {
				border-bottom: 1px solid $lightgray_border;
				padding-bottom: 13px;
				margin-bottom: 20px;
			}

			.secondary_section_header {
				margin-bottom: .25em;
			}

			.section_container {
				background-color: white;
				margin-bottom: 23px;
				padding: 28px 40px;
				border: 1px solid $lightgray-border;
				border-radius: 6px;
			}

			.subsection_container {
				margin-bottom: 25px;
			}

			.intro_container {
				margin-bottom: 2.25em;

				p {
					margin-bottom: 1em;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			.example_container {
				padding: 20px;
				border: 1px solid $lightgray-border;
				border-radius: 8px 8px 0 0;
			}

			.highlight_container {
				background-color: #fbfbfb;
				padding: 9px 14px;
				border-radius: 0 0 8px 8px;
				margin-top: -1px;
				border-right: 1px solid #f9f2f4;
				border-bottom: 1px solid #f9f2f4;
				border-left: 1px solid #f9f2f4;
				border-top: 1px solid $lightgray-border;

				code {
					font-size: 1.2em !important;
					background-color: #fbfbfb;
					line-height: 1.7em;
				}

				.nt {
					color: #2f6f9f;
				}

				.na {
					color: #4f9fcf;
				}

				.s {
					color: #d44950;
				}

				.c {
					color: #999;
				}
			}

			.callout_container {
				border: 1px solid $lightgray-border;
				border-left-width: 5px;
				border-radius: 8px;
				padding: 12px 14px 20px 17px;
				margin: 20px 0;

				h4 {
					margin-bottom: .4em;
				}
			}

			.callout_warning {
				border-left-color: #aa6708;
			}

			.markup_line {
				display: block;
				margin-bottom: .5em;
				line-height: 1.4em;

				&:last-child {
					margin-bottom: 0;
				}
			}

			.markup_group {
				margin-bottom: 2em;

				&:last-child {
					margin-bottom: 0;
				}
			}

			.example_title {
				text-transform: uppercase;
				letter-spacing: .25em;
				font-size: 1.2em;
				font-weight: 400;
				margin-bottom: 1.4em;
				color: #c1c1c1;
			}

			.well {
				min-height: 20px;
				padding: 19px;
				margin-bottom: 20px;
				background-color: #f5f5f5;
				border: 1px solid #e3e3e3;
				border-radius: 4px;
				-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.03);
				box-shadow: inset 0 1px 1px rgba(0,0,0,.03);
				max-width: 400px;
			}

			.center_block {
				display: block;
				margin-right: auto;
				margin-left: auto;
			}
		}

		.sub_subsection_container, .sub_sub_subsection_container {
			margin-bottom: 2.25em;

			.intro_container {
				margin-bottom: 1.5em;
			}
		}

		.headings_container {

			.heading_wrapper {
				padding: 10px 0;
				border-bottom: 1px solid $lightgray-border;

				&:last-child {
					border-bottom: none;
				}
			}
		}

		.body_copy_container {

			.example_container {

				p {
					margin-bottom: 1em;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			.highlight_container {
				padding: 16px 14px;

				code {
					background: none !important;
				}
			}
		}

		.button_tags_container, .button_sizes_container, .button_options_container {

			.button_tags {}

			.button_options_container {}

			.btn {
				margin-right: 5px;

				&:last-child {
					margin-right: 0;
				}
			}
		}

		.block_buttons_container {

			.btn_primary {
				margin-bottom: 1em;
			}
		}

		.disabled_buttons_container {

			.btn {
				display: inline-block;
				margin-right: .5em;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}

	.radio_container {
		margin-bottom: 15px;
	}

	.tabs_container {

		.section_heading {
			margin-bottom: 1rem;
		}
	}
}

.no_border{
  border:none !important;
}

.clearfix {

	&:after {
		clear: both;
		content: "";
		display: table;
	}
}

[rf-click-to-download] {
	cursor: pointer;
}
