.creator_profile {

  .social_account_preview {
    border: 1px solid $lightgray-border;
    border-radius: 8px;
    margin-bottom: 1rem;

    @include media($break875) {
      display: none !important;
    }

    .social_account_link {
      display: block;
      color: $dark-text;
      padding: 1rem 1.5rem 1.5rem;
      /* background: url('/images/creator_profile/dark_disclosure_icon.svg') 97% 50% no-repeat; */
      background-size: 11px;

      .account_name_container {
        width: 40%;
        float: left;
      }

      .network_icon {
        display: inline-block;
        margin-right: .5rem;
      }

      .demo_mode {
        width: 28px;
      }

      .instagram_icon {
        vertical-align: -2px;
      }

      .youtube_icon {}

      .twitter_icon {
        width: 23px;
      }

      .facebook_icon {}

      .vine_icon {}

      .snapchat_icon {}

      .blog_icon {}

      .pinterest_icon {}

      .googleplus_icon {}

      .account_name {
        display: inline-block;
        width: calc(100% - 34px);
        vertical-align: -10px;
      }

      .stats_container {
        float: right;
        width: 60%;
      }

      .stats_list {}

      .stats_item {
        text-align: center;
        float: left;
        width: 22%;
        margin-right: 4%;

        &:last-child {
          margin-right: 0;
        }
      }

      .subscribers {}

      .avg_views {}

      .total_views {}

      .total_videos {}

      .stat_number {
        display: block;
      }

      .stat_label {
        display: block;
      }

      .snapchat_score {
        .stat_label {
          display: inline-block;
        }
      }
    }


    .featured_content_container {
      padding: 0 1.5rem 1.25rem;

      .content_list {}

      .content_item {
        position: relative;
        width: 31.3%;
        float: left;
        margin-right: 3%;
        transition: all .15s ease-in-out;
        margin-bottom: 0;

        .content_image_container {
          margin-bottom: 0;
        }

        &:hover {
          opacity: .87;
        }

        &:last-child {
          margin-right: 0;
        }

        .edit_icon {
          top: 5px;
          right: 5px;
          position: absolute;
          z-index: 1;
          display: none;

          @include media($break875) {
            display: block;
          }
        }

        &:hover {

          .edit_icon {
            display: block;
          }
        }
      }

      .content_link {
        color: $dark-text;
        text-decoration: none;
      }

      .video_thumbnail {
        max-width: 100%;
        margin-bottom: .4rem;
      }

      .video_title {}
    }

    .txt_container {
      margin-bottom: 0;
    }
  }

  .snapchat_socialAccount_preview {

    .account_name_container {
      width: calc(100% - 192px) !important;
    }

    .account_name {
      width: auto !important;
      max-width: calc(100% - 163px) !important;
    }

    .stats_list {}

    .stats_container {
      width: 192px !important;
    }

    .stats_item {
      width: 50% !important;
      margin-right: 0 !important;
      float: right !important
    }

    .addSnap_btn {
      vertical-align: 2px;
      margin-left: .5rem;
    }
  }

  .blog_socialAccount_preview {

    .account_name_container {
      width: calc(100% - 192px) !important;
    }

    .account_name {
      width: auto !important;
      max-width: calc(100% - 163px) !important;
    }

    .stats_list {}

    .stats_container {
      width: 99px !important;
    }

    .stats_item {
      width: 100% !important;
      margin-right: 0 !important;
      float: right !important
    }

    .addSnap_btn {
      vertical-align: 2px;
      margin-left: .5rem;
    }
  }

  .add_account_container {
    margin-bottom: 3rem;
    text-align: center;
    margin-top: 1.85rem;

    @include media($break875) {
      display: none;
    }

    .add_social_account_btn {
      display: block;
      width: 205px;
      margin: 0 auto;
    }

    .add_icon {
      vertical-align: -4px;
      margin-right: .25rem;
    }
  }
}
