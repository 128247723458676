/* Apply this class on body only when looking at main_view or relationship_view */
.relationship_body {
	overflow-y: hidden;
}

.new_manage {

	/* .scrollbox {

		&:before, &:after {
			content: "";
			position: relative;
			z-index: -1;
			display: block;
			height: 30px;
			margin: 0 0 -30px;
			background: -webkit-linear-gradient(top,#FFF,#FFF 30%,rgba(255,255,255,0));
			background: -moz-linear-gradient(top,#FFF,#FFF 30%,rgba(255,255,255,0));
			background: linear-gradient(to bottom,#FFF,#FFF 30%,rgba(255,255,255,0));
		}

		&:after {
			margin: -30px 0 0;
			background: -webkit-linear-gradient(top,rgba(255,255,255,0),#FFF 70%,#FFF);
			background: -moz-linear-gradient(top,rgba(255,255,255,0),#FFF 70%,#FFF);
			background: linear-gradient(to bottom,rgba(255,255,255,0),#FFF 70%,#FFF);
		}

		&:before {

		}
	} */

	.animated {
		animation-duration: .2s;
	}

	.relationship_container {
		height: 100%;
	}

	.deletedAcct_wrap {
		font-weight: 600;
		position: absolute;
		z-index: 3;
		width: 100%;
		height: 105%;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		padding: 23px 1rem 0;
		background: white;

		@include media($break1045) {
			padding: .5rem 1rem 0;
		}

		.deletedHeader_wrap {
			padding-bottom: 1rem;
		}

		.icon_deleted {
			width: 22px;
			margin-right: .25rem;
			vertical-align: -12px;
		}

		.deletedHeader_txt {
			font-weight: 600;
			vertical-align: -6px;

			@include media($break1045) {
				font-size: .7rem;
			}
		}

		.deletedDescrip {
			font-size: .8rem;
			font-weight: 600;

			@include media($break1045) {
				font-size: .7rem;
			}
		}
	}

	.deletedCompose_wrap {

		@include media($break800) {
			display: none;
		}
	}

	.deletedAcct_sm_wrap {
		font-weight: 600;
		position: relative;
		z-index: 1;
		width: 100%;
		height: auto;
		top: auto;
		right: auto;
		bottom: auto;
		left: auto;
		padding: 1rem;
		background: #EAEAEA;
		margin-bottom: 1rem;
		border-radius: 6px;

		.deletedHeader_wrap {
			padding-bottom: .5rem;
		}

		@include media($break800) {
			display: block;
		}
	}

	.relationship_main_content {
		height: calc(100% - 124px);

		@include media($break1000) {
			height: calc(100% - 94px);
		}

		@include media($break630) {
			height: calc(100% - 103px);
		}
	}

	.hasSubNav {

		.relationship_main_content {
			height: calc(100% - 117px);

			@include media($break1000) {
				height: calc(100% - 87px);
			}
		}

		.messages_container {
			height: calc(100% - 143px);
		}
	}

	.relationship_main {
		width: calc(100% - 238px);
		float: left;
		height: 100%;

		.creator_preview_overlay {
			opacity: .65;
		}

		@include media($break1000) {
			width: 100%;
			float: none;
		}

		.filters_form {
			width: 217px;
			padding-left: .7rem;
			top: 35px;
			text-align: left;

			&:before, &:after {
				left: 156px;
			}

			.radio_inline {
				margin: .35rem 0;
			}
		}

		/* Collab again */
		.collabAgain_container {
			border: 1px solid $lightgray-border;
			margin-bottom: 1.25rem;
			padding: .6rem 1rem 1rem;
			border-radius: 6px;
			background-color: white;

			@include media($break630) {
				margin-bottom: .75rem;
			}

			.collabAgain_title {
				font-size: 1.6rem;
				line-height: 2rem;
				padding-bottom: .4rem;

				@include media($break375) {
					font-size: 1.5rem;
				}
			}

			.collabAgain_descrip {
				padding-bottom: .5rem;
			}

			.collabAgain_actions_list {}

			.collabAgain_actions_item {
				display: inline-block;
				margin-right: .25rem;
			}

			.btn_primary {}

			.btn_default {}
		}

		/* Collab again 2, the green one, for BRANDS */
		.collabAgain_container2 {
			margin-bottom: 1.25rem;
			padding: .6rem 1rem 1rem;

			@include media($break630) {
				margin-bottom: .75rem;
			}

			.collabAgain_title2 {
				font-size: 1.45rem;
				padding-bottom: .5rem;
				line-height: 1.25em;

				@include media($break630) {
					font-size: 20px;
					font-style: normal;
					font-stretch: normal;
					line-height: normal;
					letter-spacing: 0.3px;
				}
			}

			.disclosure_icon {
				vertical-align: -2px;
				width: 15px;
				margin-left: .25rem;
			}
		}

		/* Collab again, the green one, for CREATORS */
		.creators_collabAgain_container {
			margin-bottom: 1.25rem;
			padding: .6rem 1rem 1rem;

			@include media($break630) {
				margin-bottom: .75rem;
			}

			.creators_collabAgain_descrip {
				padding-bottom: .5rem;
			}

			.available_campaigns_list {}

			.available_campaigns_item {
				margin-bottom: .5rem;

				&:last-child {
					margin-bottom: 0;
				}
			}

			.available_campaigns_btn {
				background: #F7FFFB url('/images/style_guide/icon_chevronRight_black.svg') 96% center no-repeat;
				border: 1px solid #B3EBCC;
				padding: .75rem;
				border-radius: 6px;
				background-size: 11px;
				width: 100%;
				text-align: left;
			}

			.campaign_img, .campaign_title {
				float: left;
			}

			.campaign_img {
				border-radius: 100px;
				width: 40px;
				margin-right: .6rem;
			}

			.campaign_title {
				font-weight: 600;
				width: calc(100% - 95px);
				margin-top: 7px;
			}
		}

		.account {

			.account_inner_container {
				height: 100% !important;

				@include media($break945) {
					padding: 0 0 50px !important;
				}

				@include media($break630) {
					background-color: white;
				}
			}

			.inner_container {
				padding: 0 0 24px !important;
				width: 100%;

				@include media($break945) {
					padding: 0 !important;
				}
			}

			.account_header {
				margin: 0 auto 15px !important;

				@include media($break630) {
					padding-top: 22px;
				}
			}

			.account_intro_container {
				overflow: hidden;
				float: left;
				margin-top: 2px;

				@include media($break945) {
					float: none;
					margin-top: 0;
					margin-bottom: 2em;
				}

				@include media($break630) {
					padding: 0 20px;
				}

				a {
					display: block;
				}
			}

			.account_stats_container {
				margin-bottom: 0;
				width: 345px !important;

				@include media($break945) {
					width: 100% !important;
					float: none;
				}
			}

			.account_nav {

				@include media($break630) {
					padding: 0 20px;
				}
			}

			.content_container {
				padding: 0 20px 0 0;

				@include media($break630) {
					padding: 0 20px;
				}

				.account {

					.timestamp {
						display: none !important;
					}
				}
			}

			.account_aside {
				float: left;
				width: 280px;
			}

			.sort_button {
				padding: 0 !important;
				background: none;

				&:active, &:focus {
					box-shadow: none;
				}
			}

			.dropdown_list {

				@include media($break500) {
					right: -75px;
				}

				&:before, &:after {

					@include media($break500) {
						left: 35px;
					}
				}
			}

			.text_container {
				width: auto !important;
			}

			.filters_container {
				padding: 0 !important;

				@include media($break500) {
					text-align: left !important;
				}
			}

			.small_network_type_indicator {

				@include media($break930) {
					display: none !important;
				}
			}

			.network_icon {
				vertical-align: 0 !important;

				@include media($break930) {
					display: inline-block !important;
				}
			}

			.gender_container, .age_container {
				margin-bottom: 1em;
			}

			.audience_demographics_title {
				margin-bottom: .75em;
			}

			.stat_number {
				line-height: .85em;

				@include media($break945) {
					font-size: 2.25em;
				}
			}

			.account_name_container {
				margin-bottom: 0;
			}

			.account_username_text {
				font-size: 2.45em;
				margin-right: 9px;

				@include media($break930) {
					margin-right: .35em !important;
				}
			}

			.account_stats_list {

				@include media($break840) {
					padding-left: 0 !important;
				}

				@include media($break500) {
					padding: 0 20px !important;
				}
			}

			.account_stats_item {

				@include media($break945) {
					text-align: left !important;
					padding: 9px 25px 10px 0 !important;
				}
			}

			.account_nav_link {
				padding: 0 0 10px !important;

				@include media($break630) {
					font-size: 1rem !important;

				}
			}

			.account_nav_active {

				@include media($break630) {
					font-weight: 600 !important;
				}
			}

			.demographics_section {

				.gender_container, .ages_container, .country_container {
					margin-bottom: 2rem;
				}

				.section_title {

					@include media($break875) {
						display: block;
					}
				}
			}
		}

		.msgFilters_mobile {
			display: none;
			position: absolute;
			right: 0;
			top: -13px;

			@include media($break630) {
				display: block;
				top: 3px;
			}

			.msgFilters_btn {
				background: none;
				font-weight: 300;
				padding: 15px .75rem;

				@include media($break630) {
					padding: 8px .75rem;
				}

				&:focus, &:active {
					box-shadow: none;
				}
			}

			.filters_form {
				padding: 1em 1rem;
				right: 8px;
				top: 47px;
				font-size: .58rem;

				@include media($break630) {
					text-align: left;
					top: 42px;
				}

				&:before, &:after {
					left: 182px;
					display: none;
				}
			}
		}

		.desktop_timeline {

			.messages_header {
				border: 1px solid $lightgray-border;
				padding: .5rem 1rem;
				border-radius: 6px 6px 0 0;
				box-shadow: 0px 1px 1px rgba(0,0,0,.06);
				position: relative;
				z-index: 1;

				@include media($break630) {
					display: none;
				}

				.title_container {
					text-align: center;
				}

				.messages_title {
					display: inline;
					font-weight: 600;
				}

				.messages_number {
					color: #A0A0A0;
				}
			}

			@include media($break630) {
				display: none !important;
			}

			.timeline_container {
				float: left;
				width: 47.52%;
				height: 100%;
				position: relative;
				padding-top: 0;
				margin-top: 0;

				@include media($break630) {
					display: none !important;
				}
			}

			.timeline_list {
				padding: 0;
				box-shadow: 0 3px 2px rgba(0, 0, 0, 0.02) inset;
				height: auto;
			}
		}

		.msgFilters_desktop {
			position: absolute;
			right: 2px;
			top: 0;
			width: 45px;
			/* display: none !important; */

			@include media($break1000) {
				display: block;
			}

			@include media($break500) {
				top: -2px;
			}

			@include media($break400) {
				top: 0;
				width: 49px;
			}

			.filters_form {
				padding: 1em 1rem;
				right: 0;
				top: 37px;

				@include media($break630) {
					text-align: left;
					right: 11px;
					top: 49px;
				}

				@include media($break400) {
					right: 3px;
				}

				&:before, &:after {
					left: 182px;
				}
			}

			.msgFilters_btn {
				padding: 10px 10px;
				border: none;
				width: 44px;
				height: 32px;
				margin-top: 3px;

				&:active, &:focus {
					box-shadow: none;
				}
			}

			.dropdown_indicator_icon {
				width: 16px;
			}

			.radio {
				font-size: .8rem;
				margin: .5rem 0;
			}
		}

		.messages_header {

			@include media($break630) {
				display: none;
			}
		}

		.relationship_main_header {
			position: relative;
			height: 99px;
			margin-bottom: 20px;
			padding: 18px 0 10px;
			border-bottom: 1px solid $lightgray-border;

			@include media($break1000) {
				background-color: white;
				margin-bottom: 13px;
				padding: 0;
				height: auto;
			}

			.remove_button {
				vertical-align: 0 !important;
			}

			.large_backButton {
				width: 35px;
				float: left;
				height: 25px;
				border: none;
				text-indent: -9999px;
				cursor: pointer;
				padding: 18px 18px 18px 0;

				.back_icon {
					display: block;
					width: 22px;
				}

				@include media($break1000) {
					display: none !important;
				}
			}

			.icon_talentAgency {
				width: 25px;
			}

			.talentAgency_tooltip_container {
				margin-right: .7rem;

				.tooltip_btn {
					cursor: initial;
				}
			}

			.talentAgency_tooltip {
				width: 115px;
				left: -46px;
				bottom: 38px;
				font-weight: 300;
				z-index: 9999;

				&:before, &:after {
					left: 47px;
					border-color: $dark-text transparent;
					bottom: -10px;
				}

				.tooltip_txt {
					font-weight: 400;
				}
			}
		}

		.projects_header {
			position: relative;
			border-bottom: 1px solid $aiq_border;
			height: 82px;

			@include media($break1000) {
				height: auto;
			}

			@include media($break375) {
				height: 45px;
			}

			.msgFilters_desktop {
				right: 0;

				.dropdown_indicator_icon {
					width: 15px;
				}

				.msgFilters_btn {
					padding: 1.5em 1em;

					@include media($break400) {
						padding: 1.5em 1em;
					}
				}

				.filter_txt {
					margin-right: 5px;
					font-weight: 300;
				}
			}
		}

		.mobile_timeline_header {

			.messages_icon {}

			.avatar_container {
				margin-right: 0;
			}

			.relationship_avatar {
				border-radius: 0;
				width: 32px;
				margin: 9px auto 0;
				height: 32px;
			}
		}

		.leftActions_container {
			display: none;
			position: absolute;
			top: 12px;
			left: 0;

			@include media($break1000) {
				display: block;
			}

			@include media($break630) {
				top: 3px;
				left: 4px;
			}

			@include media($break375) {
				top: 1px;
				left: 2px;
			}

			.backBtn {
				width: 35px;
				height: 25px;
				border: none;
				text-indent: -9999px;
				cursor: pointer;
				padding: 0;
				animation-duration: .5s;
				float: left;

				@include media($break1000) {
					margin: 0;
					padding: .75rem;
					height: 48px;
					width: 49px;
					display: block !important;
				}

				@include media($break630) {
					display: none !important;
				}
			}

			.toggle_filters_button {
				display: none;
				float: left;
				position: relative;

				@include media($break1000) {
					display: block;
				}

				@include media($break630) {
					padding: 10px;
				}

				@include media($break375) {
					padding: 12px;
				}
			}
		}

		.toggle_timeline_button {
			display: none;
			position: absolute;
			right: 0;
			top: .6rem;
			border: none;
			padding: 15px;
			background: none;

			@include media($break800) {
				display: block;
			}

			@include media($break630) {
				right: 4px;
				top: 3px;
				padding: 8px;
			}

			@include media($break375) {
				padding: 11px;
				top: 2px;
				right: 2px;
			}

			.toggleMessages_icon {
				width: 30px;

				@include media($break375) {
					width: 22px;
				}
			}

			.messages_alert_icon {
				position: absolute;
				top: 10px;
				right: 4px;
				width: 23px;
				height: 23px;
			}

			&.active {
				display: none;
			}
		}

		.toggle_filters_button {
			position: absolute;
			border: none;
			cursor: pointer;
			background: none;
			padding: 15px;

			&:active, &:focus {
				box-shadow: none;
			}

			.toggle_filters_icon {
				width: 25px;

				@include media($break375) {
					width: 20px;
				}
			}

			.filters_alert_icon {
				position: absolute;
				top: 0;
				left: initial;
				right: 0;
				width: 23px;
				height: 23px;
			}
		}

		.large_infoContainer {

			.avatar_container {
				margin-right: 15px;
				position: relative;
			}

			.icon_paymentIndicator {
				position: absolute;
				right: -7px;
				top: -5px;
				z-index: 2;
			}

			a {
				text-decoration: none;
				color: $dark-text;
			}

			.add_label_input {
				border: none;
		    font-weight: 300;
		    border-radius: 100px;
		    padding: 4px;
		    display: inline-block;
			}

			@include media($break1000) {
				display: none;
			}
		}

		.small_infoContainer {
			display: none;

			.addLabel_container {
				padding: 1.4em 1.4em 1em;
				border-top: 1px solid $lightgray-border;
			}

			@include media($break1000) {
				display: block;
			}
		}

		.info_container {
			width: 92%;
			float: left;
			padding-top: .2rem;

			@include media($break1000) {
				width: 100%;
				padding: 0 3rem 0 7rem;
				margin: 0 auto;
				text-align: center;
				float: none;
			}

			@include media($break630) {
			  padding: 0 3rem;
			}
		}

		.avatar_container {
			width: 50px;
			height: 50px;
			float: left;
			margin-right: 7px;
			position: relative;

			@include media($break1000) {
				float: none;
				display: inline-block;
				vertical-align: -14px;
			}

			@include media($break630) {
				width: 34px;
				height: 34px;
				vertical-align: -8px;
			}

			@include media($break375) {
				margin-right: 5px;
				width: 26px;
				height: 26px;
			}
		}

		.no_link_avatar_container {
			display: block;
			position: relative;
		}

		.featuredCreator_badge, .liked_badge {
			position: absolute;
			top: -9px;
			left: -12px;
			width: 30px;
			height: 30px;
			z-index: 2;
		}

		.featured_text {
			display: block;
			text-align: center;
			color: $dark-text;
			font-size: .9em;
			font-weight: 800;
			margin-top: .3em;
		}

		.relation_info_container {
			float: left;
			width: calc(100% - 68px);

			@include media($break1000) {
			  float: none;
			  display: inline-block;
			}

			@include media($break630) {
				width: auto;
			}

			.add_label_input {
				font-weight: 300;
				border-radius: 100px;
				padding: 4px;
				display: inline-block;
				font-size: 1.4em;

				@include media($break1000) {
					display: block;
					border-radius: inherit;
					padding: inherit;
					font-size: inherit;
				}

				&:focus {
					outline: none;

					@include media($break1000) {
						outline: inherit;
					}
				}
			}
		}

		.relationship_avatar {
			display: block;
			max-width: 100%;
			border-radius: 100px;
			height: 100%;

			&:hover {
				opacity: .7;
			}

			@include media($break630) {
				margin: 0 auto;
			}
		}

		.name_container {

			.remove_button {
				vertical-align: 4px;
			}
		}

		.ambassador_icon_container {
			display: inline-block;
			vertical-align: top;
		}

		.relation_info_container {
			margin-left: 2px;
			.ambassador_icon {
				height: 28px;
			}
		}

		.accountsWith_history_container {
			.ambassador_icon {
				height: 1.3rem;
			}
		}

		.user_name {
			line-height: 1em;
			display: inline-block;
			max-width: calc(100% - 317px);
			vertical-align: -14px;
			font-size: 2rem;

			@include media($break1000) {
				display: inline-block;
				margin-bottom: .5rem;
				font-size: 2.75em;
				max-width: 250px;
				padding-bottom: 3px;
				margin-right: 7px;
				vertical-align: -11px;
			}

			@include media($break630) {
        /* headline 4 */
        font-size: 24px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.3px;
				margin-right: 0.05rem;
				margin-bottom: 0;
				padding-bottom: 0;
				vertical-align: -2px;
			}

			@include media($break450) {
				max-width: 195px;
			}

			@include media($break375) {
				font-size: 20px;
				font-weight: bold;
				font-style: normal;
				font-stretch: normal;
				line-height: normal;
				letter-spacing: 0.3px;
				max-width: 160px;
			}

			span, .nameLink {
				padding-bottom: .35rem;
			}

			.nameLink {
				color: $aiq_black;
				max-width: 470px;

				@include media($break1000) {
					max-width: 123px;
				}

        &:hover {
          color: $aiq_darkblue;
        }
      }
		}

		.moreOptions_indicator_icon {
			width: 15px;
			margin-left: .25rem;
			vertical-align: 7px;

			@include media($break375) {
				width: 12px;
			}
		}

		.labels_list {
			display: inline-block;
			vertical-align: 6px;
		}

		.likedIndicator {
			display: inline-block;
			margin-right: 1em;
		}

		.add_label {
			display: inline-block;
		}

		.user_tag {
			margin-right: 8px;

			&:last-child {
				margin-right: 0;
			}
		}

		.network_container {
			display: inline-block;
			vertical-align: 0;
			width: 344px;
			margin-left: 10px;
		}

		.account_link {
			display: inline-block;
			margin-right: 3px;

			&:last-child {
				margin-right: 0;
			}

			a {
				border: 1px solid $lightgray-border;
				border-radius: 6px;
				padding: .5rem;

				&:hover {
					background-color: $lightgray_bg;
				}

				&:active, &:focus {
					background-color: $pressedBtn_gray;
				}
			}

			.accountName_icon {
				width: 14px;
				height: 14px;
				margin-right: 3px;
				vertical-align: -3px;
			}

			.accountName_txt {
				display: inline-block;
				vertical-align: -5px;
				max-width: 50px;
				font-size: .85rem;
			}
		}

		/* More Accounts Container - This goes in relationship header and is used only when there are more than 3 social accounts */

		.accounts_container {
			display: inline-block;
		}

		.more_accounts_container {
			display: inline-block;
			position: relative;

			.more_accounts_btn {
				border: 1px solid $lightgray-border;
				background-color: white;
				padding: 6px 11px;
				height: 32px;
				border-radius: 6px;
				vertical-align: 2px;
				margin-left: -1px;

				&:hover {
					background-color: #FAFAFA;
				}
			}

			.more_accounts_dropdown {
				display: inline-block;
				top: 35px;
				width: 200px;
				left: -11px;

				&:before, &:after {
					left: 19px;
				}

				.moreAccount_link {
					padding: .5em 1em;

					&:hover {
						background-color: $revfluence-blue;
						color: white;
					}
				}

				> li {
					margin-bottom: 0;
				}

				.accountName_txt {
					display: inline-block;
					vertical-align: -6px;
					width: calc(100% - 24px);
				}

				.accountNetwork_icon {
					margin-right: 3px;
					vertical-align: -3px;
				}

				.accountName_icon {
					width: 15px;
					margin-right: .2rem;
				}
			}
		}

		.more_options_container {

			.more_options_btn {
				font-size: 1em;
				border: none;
				padding: .35rem .3rem .25rem .5rem;

				&:active, &:focus {
					box-shadow: none;
				}
			}

			.more_options_dropdown {
				display: none;
				right: 0;
				top: 24px;

				@include media($break1000) {
					top: 70px;
					left: 50%;
					width: 100%;
					max-width: 375px;
					margin-left: -187px;
				}

				@include media($break630) {
					top: 50px;
				}

				@include media($break375) {
					max-width: 294px;
					margin-left: -147px;
					top: 44px;
				}

				&:before, &:after {
					display: none;
				}

				> li {
					margin-bottom: 0;
				}

				/* GROUPS SECTION FOR MOBILE */
				.groupsSection_mobile {
					padding: 0 1rem;
					border-top: 1px solid $lightgray-border;

					.addGrp_btn {
						margin: .25rem 0;
						display: block;
						width: 100%;
						text-align: left;
					}

					.addedGrp_list {
						max-height: 150px;
						overflow-y: auto;
						padding-top: 0.5rem;
						min-height: 46px;
						padding-bottom: 1rem;

						.removeFromGrp_btn {
					    display: block;
							padding: 0;
							width: 20px;
					  }
					}
				}

				.labels_list {
					padding: 0 1.4em;

					@include media($break1000) {
						padding: 0 1.4em .5rem;
					}
				}

				.moreOptions_accountLink, .moreOptions_btn {
					padding: .5em 1em;
					color: $dark-text;
					border-radius: 0;

					&:hover {
						background-color: $ultraLight_gray;
						color: $dark-text !important;
					}
				}

				.moreOptions_accountLink {
					padding: .5rem 1rem;

					.network_icon {
						width: 17px;
						margin-right: .5rem;
					}

					.network_txt {
						color: $dark-text;
					}
				}

				.moreOptions_btn {
					display: block;
					background: none;
					font-weight: 300;
					width: 100%;
					text-align: left;

					.filter_txt {
						margin-right: 5px;
					}

					@include media($break630) {
						padding: .75rem 1rem;
					}

					&:hover {
						color: $revfluence-blue;
					}
				}
			}

			&:hover {

				.more_options_dropdown {
					display: block;
				}
			}

			.moreOptions_networkIcon, .archive_icon, .unarchive_icon, .featureOff_icon, .markUnread_icon, .subscribe_icon, .unsubscribe_icon {
				width: 15px;
				margin-right: .5rem;
			}

			.archive_icon {
				vertical-align: -2px;
			}

			.more_options_link {}

			.more_options_text {
				display: inline-block;
				font-size: .85rem;
			}

			.dropdown_indicator_icon {
				background: url('/images/new_manage/open_filters_icon.svg') left center no-repeat;
				width: 13px;
				height: 6px;
				display: inline-block;
				margin-left: 2px;
				vertical-align: 2px;
			}
		}

		.small_moreOptions_container {
			display: none;

			@include media($break1000) {
				display: block;
			}

			.more_options_btn {
				background: none;
				padding: .75rem .3rem .5rem .5rem;

				@include media($break630) {
					padding: .5rem .3rem;
				}

				&:hover, &:focus, &:active {
					background: none;
				}
			}

			.name_container {
				display: inline-block;
				vertical-align: -3px;
			}
		}

		.large_moreOptions_container {
			width: 65px;
			position: absolute;
			bottom: 9px;
			right: 0;

			@include media($break1000) {
				display: none;
			}

			.more_options_dropdown {
				top: 30px;
				width: 182px;
			}

			.more_options_btn {
				padding: .5rem;
			}
		}

		.more_options_container_small {
			display: none;
			background-color: white;
			border-bottom: 1px solid #fcfcfc;
			text-align: center;
			margin: -13px 0 0 0;

			@include media($break630) {
				display: block;
			}

			.more_options_button {
				color: $dark-text;
				display: block;
				width: 100%;
				text-align: center;
				padding: 5px 0 8px;
				box-shadow: 0 2px 4px rgba(0,0,0,.06);
				position: relative;
				z-index: 2;

				&:active, &:focus {
					box-shadow: 0 2px 4px rgba(0,0,0,.06);
				}

				@include media($break630) {
					border-radius: 0;
					background: none;
				}
			}

			.small_labels_container {
				padding-bottom: 15px;
			}

			.labels_list {
				display: block;
				vertical-align: 0;
				padding: 0 1.4em 1em;
			}

			.label_item {
				margin: 0 5px 5px 0;
			}

			.remove_button {
				vertical-align: 0;
			}

			.button_text {
				display: inline-block;
				margin-right: 3px;
			}

			.more_options_icon {
				display: inline-block;
				width: 10px;
				height: 9px;
			}

			.add_label_input {
				display: block;
				margin-top: .5em;
				font-size: 1.3em;
			}

			.closed_icon {
				display: none;
			}

			.open_icon {}

			.more_options_content {
				border-top: 1px solid $lightgray-border;
				padding-top: 15px;
				text-align: left;
				padding-bottom: 20px;
				padding: 15px;
				position: absolute;
				width: 100%;
				background-color: white;
				z-index: 7001;
				box-shadow: 0 3px 8px rgba(0,0,0,.1);
			}

			.more_options_small_container {
				width: 100%;
				height: 0px;
			}
		}

		.main_container {
			margin-top: 22px;
		}

		.add_new_icon_light {
			display: none;
		}

		.collabs_container {
			float: left;
			margin-right: 1%;
			width: 51.48%;
			height: 100%;

			@include media($break800) {
				width: auto;
				margin: 0;
				float: none;
			}

			.new_collab_btn {
				width: 205px;
				margin: 0 auto 2rem;
				display: block;

				.add_new_icon {
					display: inline-block;
					vertical-align: -4px;
				}
			}

			.stronger_new_collab_btn {
				color: white;
				background-color: $revfluence-blue;
				border: none;

				&:hover {
					background-color: $revfluence-blue-hover;
				}

				.add_new_icon_light {
					display: inline;
					margin-right: .25rem;
					vertical-align: -4px;
				}

				.add_new_icon {
					display: none;
				}
			}
		}

		.no_timeline {
			width: 100%;

			.social_accounts_item {
				width: 47%;
			}
		}

		.new_collab_button {
			border: none;
			cursor: pointer;
			float: right;
			background: none;
			line-height: 1em;
			margin-top: 14px;
			width: 86px;
			padding: 0;
			text-align: right;
		}

		.collabs_list {

			@include media($break630) {
				padding: .5rem;
			}
		}

		.collabs_list, .addto_profile_wrapper {
			padding-right: 2px;
			padding-bottom: 20px;
			height: 100%;
			overflow-y: auto;
			position: relative;
			-webkit-overflow-scrolling: touch;

			@include media($break630) {
				background: none;
				box-shadow: none;
				padding-top: 10px;
			}
		}

		/* Completed Collab Item */
		.completedCollab_item {
			margin-bottom: 1.5rem;
			padding: .7rem 1rem 1.4rem;
			border-radius: 6px;
			border: 1px solid $lightgray-border;
			background: white;

			@include media($break630) {
				margin-bottom: .75rem;
			}

			.completedCollab_header {
				border-bottom: 1px solid $lightgray-border;
				padding-bottom: .8rem;

				.completedCollab_img_container {
					width: 100px;
					margin-right: .25rem;
					float: left;

					@include media($break375) {
						width: 70px;
					}
				}

				.completedCollab_img {
					max-width: 100%;
				}

				.completedCollab_details {
					float: left;
					width: calc(100% - 104px;);
					padding-top: .45rem;

					@include media($break375) {
						width: calc(100% - 74px);
						padding-top: 0;
					}
				}

				.completedCollab_title {
					font-size: 1.6rem;

					@include media($break375) {
						font-size: 1.5rem;
					}
				}

				.completedCollab_descrip {
					padding-bottom: .15rem;
				}

				.notFinished_disclaimer {}
			}

			.reviewForm {
				padding: .75rem 0 0;

				.your_rating_review {
					padding-bottom: .25rem;
					display: block;
					font-weight: 600;
				}

				fieldset {
					padding-bottom: 1rem;
				}

				.txt_container {
					font-size: 1rem;
				}

				.review_input {
					display: inline;
				}

				.additional_comments_label {
					padding-bottom: .4rem;
					display: block;
				}
			}
		}

		.addto_profile_wrapper {

			@include media($break630) {
				background: white;
				padding: 1rem;
				border: 1px solid $lightgray-border;
				margin-top: 1rem;
				border-radius: 6px;
			}
		}

		.elevateMessage {
			margin-bottom: 1rem;

			.title {
				margin-bottom: 0.5rem;
			}
		}

		.collab_item {
			margin-bottom: 25px;
			position: relative;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.ax_collab_item {
			border: 1px solid #ECECEC;
			background: white url(/images/new_manage/right_content_nav_icon.svg) 96% center no-repeat;
			cursor: pointer;
			padding: .75rem;
			margin-bottom: .75rem;
			border-radius: 5px;
		}

		.collab_item_container {
			border: 1px solid $lightgray-border;
			border-radius: 6px;


			/*** COLLAB OPTIONS DROPDOWN ***/
			.collabOptions_dropdown_container {
				overflow: visible;

				.icon_settings, .btn_txt {
					display: inline-block;
				}

				.icon_settings {
					margin-right: .07rem;
					width: 18px;
					vertical-align: -2px;
				}

				.btn_txt {
					vertical-align: 1px;
				}

				.dropdown_list {
          left: -6px;
					top: 21px;
					width: 160px;

          &:before, &:after {
            left: 16px;
            display: none;
          }
        }

				.dropdown_btn {
					width: 115px;
					overflow: visible;
          padding: .5rem 2rem .4rem .8rem;
          margin-bottom: 0;
					background-position: 88% center;
				}
			}

			/** NEW CAMPAIGN COLLAB FEATURE ALERT **/
			.newFeature_alert {
				padding: 0 25px 18px;

				.newFeature_alert_btn {
					background-color: $light_action_blue;
					border-radius: 4px;
					display: block;
					width: 100%;
					padding: 13px;
					text-align: left;
					position: relative;

					&:hover {
						background-color: $light_action_blue_hover;
					}

					&:active {
						background-color: $light_action_blue;
					}
				}

				.arrow_icon {
					position: absolute;
					right: 1.25rem;
					top: 50%;
					margin-top: -7px;
					width: 11px;
				}

				.newFeature_icon, .txt_container {
					float: left;
				}

				.newFeature_icon {
					margin-right: 1rem;
					width: 32px;
					padding-top: 4px;
				}

				.txt_container {
					width: calc(100% - 65px);
				}

				.newFeature_title, .newFeature_descrip {
					display: block;
				}

				.newFeature_title {
					font-weight: 600;
				}

				.newFeature_descrip {
					font-weight: 300;
				}
			}

			.collab_complete_container {
				text-align: center;
				padding: 1.25rem 1.5rem 1.5rem;
				border: 1px solid $aiq_border;
				border-radius: 6px;

				.complete_title {
					margin-bottom: .5rem;
				}

				.complete_descrip {
					margin-bottom: 1rem;
					font-size: 1.65em;
				}

				.buttons_container {
					margin-bottom: .25rem;
				}

				.write_review_btn, .completed_btn {
					display: inline-block;
				}

				.not_finished_btn {
					display: inline-block;
					text-align: center;
					margin-left: .5rem;

					@include media($break350) {
						margin-left: 0;
						margin-top: 1rem;
					}
				}

				.disclosure_icon {
					width: 9px;
					vertical-align: -3px;
					margin-left: .25rem;
				}

				.hint_msg {

					p {
						margin-bottom: 0;
					}
				}
			}
		}

		.collab_item_header {
			margin-bottom: .25rem;
			padding: 15px 45px 0 25px;
			position: relative;
			width: calc(100% - 46px);

			@include media($break500) {
				padding: 15px 0 0 15px;
			}

			a {
				color: inherit;
				text-decoration: none;
			}
		}

		.completion_status_container {
			padding: .5rem 0;

			.completion_status_indicator {
				margin-right: .75rem;
				float: left;
			}

			.completion_status_icon {
				margin-right: .15rem;
				width: 16px;
				vertical-align: -3px;
			}

			.completion_status_txt {
				font-weight: 600;
				font-size: 1.3em;
			}

			.mark_incomplete_btn {
				float: left;
				padding: 0;
				font-size: 1.3em;
				border: none;
			}
		}

		.addto_profile_header {
			margin-bottom: .5rem;

			@include media($break630) {
				padding-top: 0;
			}

			.addto_profile_title {}

			.addto_profile_descrip {}

			.addto_profile_actions {
				padding: .25rem 0;
				margin-top: .5rem;
			}

			.returnBtn {
				margin-right: .5rem;

				@include media($break445) {
					display: block;
				}
			}

			.arrow_icon {
				margin-right: .25rem;
			}

			.btn_txt {}

			.viewProfile_btn {

				@include media($break445) {
					display: block;
					margin-top: 1rem;
				}
			}
		}

		.profilePreview {
			border: 1px solid $lightgray-border;
			border-radius: 6px;
			margin-top: 1.25rem;

			.profilePreview_header {
				text-align: center;
				padding: .5rem;
				border-bottom: 1px solid $lightgray-border;
			}

			.profilePreview_title {
				display: block;
			}

			.profilePreview_body {
				padding: 1rem 1.25rem;
			}

			.contentDescrip {
				padding-bottom: 1rem;
			}

			.past_collabs {

				@include media($break630) {
					padding: 0;
				}
			}

			.past_collab_item {
				padding-bottom: 0;
				margin-bottom: 0;
			}
		}

		.addto_profile_container {
			border: 1px solid $lightgray-border;
			padding: 1rem;
			margin-top: 1rem;
			border-radius: 6px;
			background-color: white;

			.content_gallery_container {
				float: left;
				width: 100px;
				margin-right: 1rem;

				@include media($break365) {
					display: none;
				}
			}

			.content_img {
				max-width: 100%;
				display: block;
				border-radius: 6px;
			}

			.nav_container {
				position: relative;
				margin-top: .5rem;
			}

			.nav_left_btn, .nav_right_btn {
				background: none;
				padding: 0;
				position: absolute;
				top: 0;
			}

			.nav_left_btn {
				left: 0;
			}

			.nav_left_btn_icon {}

			.amount_indicator {
				text-align: center;
				display: block;
				font-size: 1.25em;
				padding-top: .4em;
				font-style: italic;
			}

			.nav_right_btn {
				right: 0;
			}

			.nav_right_btn_icon {}

			.addto_profile_info_container {
				width: calc(100% - 116px);
				float: left;

				@include media($break365) {
					width: 100%;
				}
			}

			.campaign_summary {

				.campaign_summary_list {}

				.campaign_summary_item {
					padding-bottom: .25rem;

					&:last-child {
						padding-bottom: 0;
					}
				}

				.review_star_list {
					display: inline-block;
					vertical-align: -4px;
				}

				.review_star_item {
					display: inline;

					&:last-child {
						margin-right: 0;
					}
				}

				.star_icon {}

				.like_icon {
					vertical-align: -2px;
					margin-right: .3rem;
					width: 17px;
				}
			}

			.addto_suggestion_container {}

			.addto_profile_title {
				margin-bottom: .5rem;
			}

			.addto_profile_descrip {
				margin-bottom: .85rem;
			}

			.addto_profile_title {
				font-weight: 600;
			}

			.addto_profile_descrip {}

			.addto_profile_btn {}

			.add_icon {
				vertical-align: -4px;
				margin-right: .25rem;
			}

			.collab_summary_container {

				.error_text {
					padding-bottom: .5rem;
				}

				.collab_summary_title {
					font-weight: 600;
					margin-bottom: .25rem;
					display: block;
				}

				.collab_summary_descrip {
					margin-bottom: .5rem;
				}

				.collab_summary_input {
					margin-bottom: .75rem;
				}

				.feature_collab_fieldset {}

				.checkbox_control {
					display: inline-block;
					padding: 0 5px 0 30px;
				}

				.hint_btn {
					display: inline-block;
					vertical-align: -4px;

					@include media($break365) {
						margin-bottom: 1rem;
					}
				}

				.hint_icon {
					width: auto;
					width: auto;
					background: none;
				}

				.text_container {
					width: auto;
					float: none;
				}

				.save_btn {
					margin-right: .5rem;
				}
			}

		}

		.completed_indicator {
			padding: .3rem .8rem;
			border: 1px solid $lightgray-border;
			border-radius: 100px;
			font-size: 1.25em;
			margin-bottom: .9rem;
			display: inline-block;
			background-color: white;
			margin-left: 25px;

			.checkmark_icon {
				margin-right: .2rem;
				vertical-align: -3px;
			}
		}

		.collab_basic_info {

			@include media($break630) {
				background-color: white;
				border-radius: 4px 4px 0 0;
				border-bottom: 1px solid $lightgray-border;
			}
		}

		.mark_completed_btn {
			font-size: 1rem;
			margin: .75rem 0 1.2rem 0;

			.checkmark_icon {
				margin-right: .2rem;
				vertical-align: -3px;
			}
		}

		.reopen_btn {
			margin-top: .95rem;
		}

		.project_network_icon {
			width: 18px;
			float: left;
			margin-right: 10px;
			margin-top: 6px;

			@include media($break630) {
				margin-top: 2px;
			}
		}

		.youtube_icon {
			margin-top: 11px;

			@include media($break425) {
				margin-top: 7px;
			}
		}

		.project_title {
			font-size: 1.45rem;
			padding-bottom: .5rem;
			line-height: 1.25em;

			@include media($break630) {
				font-size: 20px;
				font-weight: bold;
				font-style: normal;
				font-stretch: normal;
				line-height: normal;
				letter-spacing: 0.3px;
			}

			&:hover {
        color: $aiq_darkblue;
      }
		}

		.collab_summary_info {
			padding: 0 25px;
			position: relative;

			@include media($break500) {
				padding: 0 15px;
			}

			/*** COLLAB PAYMENT VALUE(FOR CREATORS) ***/
			.paymentValue_container {
				padding: 0 0 .7rem;

				.icon_paymentCircle {
					margin-right: .35rem;
				}

				.paymentValue_txt {
					vertical-align: 4px;
					font-weight: 600;
				}
			}

			.help_center_link {
				position: absolute;
				bottom: 32px;
				right: 20px;

				@include media($break475) {
					position: relative;
					bottom: auto;
					right: auto;
					margin-top: 1rem;
					display: block;
				}
			}

			.see_proposal_link {
				display: inline-block;
				margin-top: 6px;
				margin-right: 10px;
			}

			.seeProposal_link {
				padding: 0 5px 18px 0;
				display: inline-block;
			}

			.collabContent_item {
				padding-bottom: .75rem;

				&:last-child {
					margin-bottom: 0;
				}

				.contentVisual_container {
					position: relative;
					display: inline-block;
					margin-right: .14rem;
					vertical-align: -3px;
				}

				.text_wrap {
					display: inline-block;
					vertical-align: top;
					width: calc(100% - 30px);
				}

				.text_item {

				}

				strong {
					font-weight: 700;
				}
			}

			.networkIcon_container {
				background-color: white;
				border: 1px solid $lightgray-border;
				border-radius: 100px;
				position: absolute;
				bottom: -3px;
				right: -2px;
				width: 23px;
				height: 23px;
				/* Reason for :none; Probably getting rid of this to make for a flush icon list in active collab */
				display: none;
			}

			.icon_network {
				width: 13px;
				display: block;
				margin: 4px auto 0;
			}

			.icon_youtube {
				margin: 5px auto 0;
			}

			.youtube_icon {
				margin: 6px auto 0;
			}

			.completed_txt {
				color: #B4B4B4;
				text-decoration: line-through;
				margin-left: 6px;
			}

			.content_thumbnail {
				width: 21px;
				border-radius: 2px;
			}
		}

		.collab_expanded_content {
			background-color: #fafafa;
			padding: 0 .75rem 0 0;
			box-shadow: inset 0 1px 7px rgba(0,0,0,.04);

			@include media($break630) {
				box-shadow: none;
				background-color: #FAFAFA;
				padding: 0 13px 0 0;
				border-top: 1px solid $lightgray-border;
			}
		}


		/* COLLAB TERMS TOOLS LIST */
		.tools_list {
			position: relative;
			padding: .75rem 0;

			.toolsTimeline_line {
				display: block;
				border-left: 3px solid #DEDEDE;
				position: absolute;
				width: 3px;
				top: 0;
				left: 17px;
				height: calc(100% - 1px);
				z-index: 1;
			}

			.toolsTimeline_fill {
				position: absolute;
				border-left: 3px solid $accent_gold;
				height: 545px;
				width: 3px;
				z-index: 2;
				left: -3px;
			}
		}

		.tools_item {
			border-radius: 4px;
			padding: 11px .5rem;
			margin: 0 0 .5rem 2.8rem;
			cursor: pointer;
			box-shadow: 1px 1px 3px rgba(0,0,0,.05);
			transition: all .15s ease-in-out;
			background: white url('/images/new_manage/right_content_nav_icon.svg') 97% center no-repeat;
			position: relative;
			border: 1px solid #E2E2E2;
			z-index: 3;

			@include media($break630) {
				box-shadow: none;
				border-bottom: 1px solid $lightgray-border;
				margin-bottom: .5rem;
				padding: .54rem .75rem .75rem;
				background: white url('/images/new_manage/right_content_nav_icon.svg') 97% center no-repeat;

				&:last-child {
					border-bottom: 1px solid $lightgray-border;
				}
			}

			&:last-child {
				margin-bottom: 0;
			}

			&:after {
				content: '';
				position: absolute;
				border-style: solid;
				border-width: 8px 11px 8px 0;
				border-color: transparent #FFFFFF;
				display: block;
				width: 0;
				z-index: 1;
				left: -11px;
				top: 29px;
			}

			&:before {
				content: '';
				position: absolute;
				border-style: solid;
				border-width: 8px 11px 8px 0;
				border-color: transparent #E2E2E2;
				display: block;
				width: 0;
				z-index: 0;
				left: -12px;
				top: 29px;
			}
		}

		.toolStatus_icon {
			position: absolute;
			left: -39px;
			top: 26px;
		}

		.skippedStatus_icon, .incompleteStatus_icon {
			left: -37px;
		}

		.tool_icon_container {
			width: 42px;
			height: 42px;
			float: left;
			margin: 2px 10px 0 0;
		}

		.tools_icon {
			display: inline-block;
			width: 42px;
			height: 42px;
		}

		.inactive_collabTerms_icon {
			background: url('/images/new_manage/inactive_collabTerms_icon.svg') center no-repeat;
			background-size: 42px;
		}

		.inactive_payment_icon {
			background: url('/images/new_manage/inactive_payment_icon.svg') center no-repeat;
			background-size: 42px;
		}

		.inactive_conversionTracker_icon {
			background: url('/images/new_manage/inactive_conversionTracker_icon.svg') center no-repeat;
			background-size: 42px;
		}

		.inactive_contentTracker_icon {
			background: url('/images/new_manage/inactive_contentTracker_icon.svg') center no-repeat;
			background-size: 42px;
		}

		.inactive_collabReview_icon {
			background: url('/images/new_manage/inactive_collabReview_icon.svg') center no-repeat;
			background-size: 42px;
		}

		.inactive_contentApproval_icon {
			background: url('/images/new_manage/inactive_contentApproval_icon.svg') center no-repeat;
			background-size: 42px;
		}

		.inactive_productShipment_icon {
			background: url('/images/new_manage/inactive_productShipment_icon.svg') center no-repeat;
			background-size: 42px;
		 }

		.inactive_contractManager_icon {
			background: url('/images/new_manage/inactive_signContract_icon.svg') center no-repeat;
			background-size: 42px;
		 }

		 .inactive_advertiserAccess_icon {
			background: url('/images/icons/outline/account_access.svg') center no-repeat;
			background-size: 31px;
		 }

		.tool_text_container {
			float: left;
			width: calc(100% - 73px);
		}

		.toolStatus_txt {
			font-style: italic;
			color: #838383;
		}

		.tool_status {}

		.payment_tool {}

		.conversion_tracker_tool {}

		.tool_alert_indicator {}

		.active_collabTerms_icon {
			background: url('/images/new_manage/active_collabTerms_icon.svg') center no-repeat;
		}

		.active_payment_icon {
			background: url('/images/new_manage/active_payment_icon.svg') center no-repeat;
		}

		.active_conversionTracker_icon {
			background: url('/images/new_manage/active_conversionTracker_icon.svg') center no-repeat;
		}

		.active_contentTracker_icon {
			background: url('/images/new_manage/active_contentTracker_icon.svg') center no-repeat;
		}

		.active_collabReview_icon {
			background: url('/images/new_manage/active_collabReview_icon.svg') center no-repeat;
		}

		.active_contractManager_icon {
			background: url('/images/new_manage/active_signContract_icon.svg') center no-repeat;
		}

		.active_contentApproval_icon {
			  background: url('/images/new_manage/active_contentApproval_icon.svg') center no-repeat;
			}

		.active_productShipment_icon {
			background: url('/images/new_manage/active_productShipment_icon.svg') center no-repeat;
		}

		.active_signContract_icon {
			background: url('/images/new_manage/active_signContract_icon.svg') center no-repeat;
			background-size: 93%;
		}

		.active_advertiser_access {
			background: url('/images/icons/multicolor/account_access.svg') center no-repeat;
			background-size: 93%;
		}

		.active_collabTerms_icon, .active_payment_icon, .active_conversionTracker_icon, .active_contentTracker_icon, .active_collabReview_icon, .active_contentApproval_icon, .active_productShipment_icon, .active_signContract_icon {
			background-size: 42px;
			margin-right: .25rem;
		}

		.timeline_view_container {
			height: 100%;
		}

		.timeline_container {
			width: 100%;
			float: none;
			margin-top: 0;
			height: 100%;
		}

		.active_tool {
			border: 1px solid $aiq_nude_border;
			background: $aiq_nude;
			height: auto;
			padding: .8rem .75rem 1rem;

			&:after {
				border-color: transparent $aiq_nude;
			}

			&:before {
				border-color: transparent $aiq_nude;
			}

			.tools_icon {
				float: left;
				margin-right: .5rem;
				width: 42px;
			}

			.activeTool_details_container {
				float: left;
				width: calc(100% - 50px);
			}

			.activeTool_header_container {
				padding-bottom: .25rem;
			}

			.activeTool_header_container {}

			.activeTool_header_txt {
				font-weight: 600;
				font-size: 1.1rem;
			}

			.activeTool_body_container {}

			.activeTool_descrip {
				padding-bottom: .2rem;
			}

			.activeTool_actions_container {
				margin-top: .5rem;
			}

			.activeTool_actions_list {}

			.activeTool_actions_item {}

			.disclosure_icon {
				width: 9px;
				vertical-align: -1px;
				margin-left: .25rem;
			}

			.activeTool_actions_item, .btn_primary, .btn_default {
				display: inline-block;

				@include media($break1215) {
					display: block;
					width: 100%;
					margin-right: 0;
				}
			}

			.btn_primary {
				margin-right: .25rem;

				@include media($break1215) {
					margin-bottom: .5rem;
				}
			}
		}

		.completed_collab_item {

			.collab_basic_info {
				padding: 1rem;
			}

			.collab_item_header {
				padding: 0;
			}

			.completed_indicator {
				margin-left: 0;
			}

			.collab_summary_info, .mark_completed_btn_group {
				padding: 0;
			}
		}

		.closed_collab_item {

			.collab_basic_info {
				border-radius: 6px;
			}

			.collab_expanded_content {
				display: none;
			}
		}

		.complete_check {
			display: inline-block;
			width: 15px;
			height: 15px;
			position: relative;
			vertical-align: -2px;
			background-image: url("/images/style_guide/icon_checkmark_circle.svg");
			background-repeat: no-repeat;
			background-size: 15px;
			margin-right: .25em;
		}

		.timeline_list {
			box-shadow: none;
			padding: 15px 15px 0;

			@include media($break630) {
				background-color: white;
				border-radius: 6px 6px 0 0;
				border: none;
				padding: 10px 10px 5px;
				border-radius: 0;
				margin-top: 1px;
			}
		}

		.loadMore_btn {
			margin-bottom: .6rem;
			display: block;
			width: 100%;
		}

		.messages_container {
			height: calc(100% - 140px);
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			padding: .5rem;
			border-left: 1px solid $lightgray-border;
			border-right: 1px solid $lightgray-border;
			transition: all .25s;

			&.focused {
				height: calc(100% - 355px);
			}

			/* DON'T CHANGE THIS BREAKPOINT WITHOUT NOTIFYING ENGINEERING */
			@include media($break1000) {
				height: calc(100% - 140px);

				&.focused {
					height: calc(100% - 349px);
				}
			}

			/* This is where it switches from a textarea to a button to show textarea */
			@include media($break630) {
				height: calc(100% - 52px);
				border-right: none;
				border-left: none;
				padding: 0;

				&.focused {
					height: calc(100% - 90px);
				}
			}

			@include media($break500) {
				height: calc(100% - 52px);
				border-right: none;
				border-left: none;
				padding: 0;
			}

			&.has_attachment {
				height: calc(100% - 168px);

				/* This is were composer changes from textarea to button to show textarea */
				@include media($break630) {
					height: calc(100% - 150px);
				}

				@include media($break500) {
					height: calc(100% - 140px);
				}

				@include media($break400) {
					height: calc(100% - 122px);
				}
			}
		}

		.timelineList_hasAttachments {
			height: calc(100% - 143px);
		}

		.timeline_item {
			margin-bottom: .7rem;
			position: relative;

			/* READ RECEIPTS */
			.read_receipt {

				.readLabel {
					font-weight: 300;
					padding-left: 0.9em;
					font-size: .8rem;
					padding-top: 1px;
					display: block;
					color: #A3A3A3;
				}

				.timestamp_txt {}
			}
		}

		/* PENDING AUTO MESSAGE */
		.pending_autoMsg_container {
			border-top: 1px solid $lightgray-border;
			position: relative;
			height: 25px;
			margin-top: 24px;

			.pending_autoMsg_btn {
				width: 100%;
				border-radius: 0;
				background: none;
				padding: .25rem .5rem;
				position: absolute;
				top: -17px;
				left: 0;
			}

			.pending_autoMsg_txt {
				font-size: .85rem;
				font-weight: 600;
			}

			.pendingTxt_wrap {
				background-color: white;
				padding: 3px 10px;

				&:hover {
					background-color: $ultraLight_gray;
				}
			}

			.scroll_indicator_icon {
				width: 15px;
			}
		}

		/* HAS READ RECEIPT, add when receipt is made */
		.hasRead_receipt {}

		.new_timeline_item {

			.message_container {
				border: 1px solid $revfluence-blue;
			}
		}

		.system_message {
			background-color: white;
			border-radius: 4px;
			padding: 10px;
			position: relative;
			border: 1px solid $lightgray-border;

			.read_receipt {
				display: none;
			}

			.message_container {
				border: none;
				padding: 0 4px;
				background-color: white;

				&.white {
					background-color: white;
				}
			}

			a {
				text-decoration: none;
				color: $revfluence-blue;
				cursor: pointer;
			}

			.bold_text_container {
				font-weight: 600;
			}

			.text_container {
				a {
					font-weight: 600;
				}
			}

			.changedBy_txt {}

			.message_text {
				margin-bottom: 1em;
				&:last-child {
					margin-bottom: 0em;
				}
			}

			.username {
				font-weight: 500;
				margin-right: 0.25rem;
			}
		}

		.celebMsg {
			text-align: center;
			border: none;

			.tooltip_img_wrap {}

			.badge_img {
				width: 60px;
				margin-bottom: .5rem;

				@include media($break750) {
					width: 50px;
				}
			}

			.celebMsg_txtWrap {
				max-width: 850px;
				margin: 0 auto;
			}

			.celebMsg_title, .celebMsg_quote, .timestamp {
				display: block;
				font-size: 1.0rem;
				line-height: 1.0rem;
			}

			.celebMsg_title {
				font-size: 1rem;
				font-weight: 600;
				margin-bottom: .5rem;
			}

			.celebMsg_quote {
				margin-bottom: .5rem;
				font-style: italic;
			}
		}

		.creator_celebMsg {

			.tooltip_container {
				margin-left: .25rem;
				vertical-align: .25rem;
			}
		}

		.system_message_icon {
			width: 23px;
			height: 23px;
			margin: 4px 6px 0 0;
			display: inline-block;
			vertical-align: top;

			&.avatar {
				border-radius: 10px;
			}
		}

		.systemMsg_content {
			display: inline-block;
			width: calc(100% - 50px);
		}

		.systemMsg_payment_icon {
			width: 10px;
			margin: 3px 13px 0 6px;
		}

		.timeline_collabReview_icon {
			background: url('/images/new_manage/timeline_collabReview_icon.svg') center no-repeat;
		}

		.timeline_collabTerms_icon {
			background: url('/images/style_guide/icon_terms_black.svg') center no-repeat;
		}

		.timeline_contentTracker_icon {
			background: url('/images/new_manage/timeline_contentTracker_icon.svg') center no-repeat;
		}

		.timeline_conversionTracker_icon {
			background: url('/images/new_manage/timeline_conversionTracker_icon.svg') center no-repeat;
		}

		.timeline_payment_icon {
			background: url('/images/new_manage/timeline_payment_icon.svg') center no-repeat;
		}

		.agreement_text_container {
			display: block;
		}

		/* Text container for system messages and user messages */
		.text_container {
			width: calc(100% - 35px);
			float: left;
			position: relative;
			margin-bottom: .25rem;

			&:last-child {
				margin-bottom: 0em;
			}
		}

		.raw_text {
			white-space: pre-line;
		}

		.system_message_text {
			display: block;
			margin-bottom: 0.4rem;

			.btn_link {
				font-weight: 600;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}

		.content_preview_container {
			width: 95px;
			padding: 7px 0 5px;
		}

		.content_preview_link {
			display: block;
			text-decoration: none;
		}

		.content_preview_thumbnail {
			max-width: 100%;
			display: block;
			border-radius: 3px;

			&:hover {
				opacity: .9;
				box-shadow: 1px 1px 10px rgba(0,0,0,0.15);

				&:active, &:focus {
					box-shadow: none;
					opacity: 1;
				}
			}
		}

		.system_message_metadata {
			font-size: .9em;
			color: #A3A3A3;
			display: block;
			font-weight: 300;
			padding-top: 4px;
			float: left;
			width: 100%;
		}

		.campaign_name {
		}

		.timestamp {
		    padding-top: 0;
		}

		.autoMsg_indicator {
		}

		.message_tail {
			position: absolute;
			top: 6px;
			width: 21px;
			height: 14px;
			display: block;
		}

		.left_message_tail {
			left: -14px;
		}

		.right_message_tail {
			right: -14px;
		}

		.timeline_contentTracker_icon {}

		.timeline_payment_icon {}

		.timeline_contentTracker_icon {}

		.timeline_collabReview_icon {}

		.user_message {
			position: relative;

			.message_container {
				background-color: #F4F9FF;
			}

			.message_text {
				white-space: pre-wrap;
			}
		}

		.pending_autoMsg {
			position: relative;
			border: none;

			.message_container {
				background-color: #F9F9F9;
				border: none;
			}

			.pendingMsg_header {
				font-weight: 600;
			}

			.pendingMsg_icon {
				width: 18px;
				vertical-align: -3px;
				margin-right: 1px;
			}

			.pendingMsg_preview_txt {
				font-size: .85rem;
				line-height: 1.15rem;
				color: #787878;
				padding: .35rem 0;
				display: block;
			}

			strong {
				font-weight: 600;
			}

			.pendingMsg_link, .dontSend_link {
				font-weight: 600;
				font-size: .85rem;

				&:hover {
					color: $revfluence-blue-hover;
				}

				@include media($break415) {
					display: block;
					padding: .5rem 0;
				}
			}

			.pendingMsg_link {
				margin-right: .5rem;
				dispaly: inline;
			}

			.dontSend_link {}
		}

		.note_message {
			background-color: $helpful_yellow !important;
			border: 1px solid $helpful_yellow_border !important;
			position: relative;
		}

		.user_avatar {
			width: 30px;
			height: 30px;
		}

		.message_container {
			float: left;
			width: calc(100% - 45px);
			border: 1px solid $lightgray_border;
			border-radius: 4px;
			padding: 10px;
			position: relative;
			transition: all .18s ease-in-out;
		}

		.message_text_container {
			width: calc(100% - 20px);

			.username {
				font-weight: 700;
				margin-right: 0.25rem;
			}

			a {
				color: $aiq_darkblue;
				text-decoration: none;

				&:hover {
					color: $aiq_darkblue_hover;
				}
			}
		}

		.message_text {
			margin-bottom: 1px;

			a {
				color: $aiq_darkblue;
				text-decoration: none;

				&:hover {
					color: $aiq_darkblue_hover;
				}
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		.general_message {

			.user_avatar {
				margin-right: 15px;
				float: left;
				border-radius: 100px;
			}
		}

		.personal_message {

			.user_avatar {
				float: right;
				margin-left: 15px;
				border-radius: 100px;
			}

			a {
				text-decoration: none;
				color: $aiq_darkblue;

        &:hover, &:focus {
          color: $aiq_darkblue_hover;
        }
			}
		}

		.message_link {
			text-decoration: none;
			color: $aiq_darkblue;

			&:hover, &:focus {
				color: $aiq_darkblue_hover;
			}
		}

		.view_proposal_link {
			display: block;
			margin-top: 10px;
		}

		.mobile_msgActions_container {
			display: none;
			background-color: white;
			border-top: 1px solid $lightgray-border;

			@include media($break630) {
				display: block;
			}

			.reply_btn, .note_btn {
				float: left;
				background: none;
				padding: 1rem;

				&:focus, &:active {
					box-shadow: none;
				}
			}

			.reply_btn {
				width: 100%;
				border-right: 1px solid $lightgray-border;
				border-radius: 0;
			}

			.reply_icon {
				padding-right: .2rem;
			}

			.mobileAction_btn_txt {
				font-size: 1.1rem;
				font-weight: 600;
			}

			.note_btn {
				width: 65px;
				border-right: 1px solid $lightgray-border;
				border-radius: 0;
				padding: .6rem 1rem .5rem;
			}

			.note_icon {
				height: 24px;
			}

			.note_icon_txt {
				text-align: center;
				display: block;
				font-weight: 600;
				font-size: .8rem;
			}
		}

		.userMsg_unbold {
			font-weight: 300;
			display: inline-block;
			padding-bottom: 5px;
		}

		/* Both: Compose Message Container */
		.composeMsg_container {
			padding: 0 0 3px;
			width: 100%;
			background-color: white;
			border-top: 1px solid #ECECEC;
			position: relative;
			transition: all .25s;
			height: 154px;

			&.focused {
				height: 300px;
			}

			@include media($break1000) {
				bottom: 0;
			}

			@include media($break630) {
				padding: 0;
				border-radius: 0;
				top: initial;
				z-index: 999999;
				height: 100%;
				background-color: white;
				position: fixed;
				bottom: -2405px;
				left: 0;
				width: 100%;
				overflow: hidden;

				&.focused {
					height: 100%;
				}
			}

			/* Add this class to .toggle_composeMsg_paneBtn when user closes compose message pane */
			.closeIt_indication {

				.toggle_composeMsg_icon {
					transform: rotate(180deg);
				}
			}

			/* Add this class to .toggle_composeMsg_paneBtn when user open compose message pane */
			.openIt_indication {

				.toggle_composeMsg_icon {
					transform: rotate(180deg);
				}
			}

			.composeMsg_tabsList {

				@include media($break630) {
					display: none;
				}

				.replyItem {
					display: inline-block;

					@include media($break630) {
						width: 50%;
						border-right: 1px solid $lightgray-border;
						float: left;
						display: block;

						&:last-child {
							border-right: none;
						}
					}
				}

				.composeMsg_tabBtn {
					color: #989898;
					background: none;
					border-radius: 0;
					font-weight: 300;
					padding: .5rem .55rem;
					font-size: .9rem;
					border: none;

					@include media($break630) {
						background: #FAFAFA;
						display: block;
						width: 100%;
						padding: .75rem .55rem;
						font-size: 1.45em;
					}

					&:hover {
						color: $dark-text;
					}
				}

				.active_composeMsg_tabBtn {
					font-weight: 600;
					color: $revfluence-blue;

					@include media($break630) {
						background: white;
						font-weight: 600;
					}

					&:hover {
						color: $revfluence-blue-hover;
					}
				}
			}

			.toggle_composeMsg_paneBtn {
				position: absolute;
				left: 50%;
				width: 40px;
				height: 40px;
				margin-left: -25px;
				top: -25px;
				border-radius: 100px;
				box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.05);
				background: white;
				display: none;
				padding: 0;
				border: 1px solid $lightgray-border;

				@include media($break630) {
					display: block;
				}

				.toggle_composeMsg_icon {
					display: block;
					margin: 5px auto;
				}

				.closeIt_indication {}

				.openIt_indication {
					transform: rotate(180deg);
				}
			}
		}

		.open_composeMsg_container {

			@include media($break630) {
				top: -1px;
				bottom: 5px;
				left: 0;
			}
		}

		/* Add this to composeMsg_container when there are attachments */
		/* ISSUE: the height is variable, how can i set height cacl properly? */
		.has_attachments {}

		/* Add this to composeMsg_container when there are attachments */
		/* ISSUE: the height is variable, how can i set height cacl properly? */
		.has_error {}

		/* Mobile: Error in composer */
		.error_container {
			display: block;
			padding: 0 .5rem .75rem;

			@include media($break630) {
				padding-bottom: 1rem;
			}

			.error_txt {
				color: $error_red;
				font-weight: 600;
			}
		}


		/* Mobile: Attachments List  */
		.smallAttachments_list {
			padding: 0 .5rem;
			display: none;

			@include media($break630) {
				display: block;
			}

			.attachment_item {
				margin-bottom: .5rem;
				position: relative;
				padding: .5rem 2rem .5rem .75rem;
				border: 1px solid $lightgray-border;
				border-radius: 100px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			a {
				text-decoration: none;
			}

			.filename {
				color: black;
				text-decoration: none;
			}

			.remove_btn {
				position: absolute;
				right: .5rem;
				top: .3rem;
				padding: .45rem;
				background: none;
			}

			.remove_icon {
				display: block;
			}


		}

		/* Both: composerInput */
		.composeInputs_container {
			height: calc(100% - 55px);

			form {
				height: 100%;

				@include media($break630) {
					padding: .5rem;
				}
			}
		}

		.message_input {
			border-radius: 100px;
			width: calc(99% - 79px);
			float: left;
			margin-right: 6px;
			display: inline-block;
			border-radius: 4px;
			font-size: 1.39em;
			height: 100%;

			@include media($break630) {
				float: none;
				width: 100%;
				margin-right: 0;
				display: block;
				height: 100%;
				border-radius: 0;
				box-shadow: none;
				border: none !important;
				padding: .75rem .5rem;
				resize: none;
				font-size: 1rem;
			}

			&.error_input {
				border: none;
				&:focus {
					border: 1px solid $revfluence-blue !important;
					box-shadow: none !important;
				}
			}
		}

		.send_message_button {
			display: block;
			background-color: $revfluence-blue;
			border-radius: 6px 6px 0 0;
			padding: 8px 20px;
			color: white;
			border: 1px solid $revfluence-blue;
			width: 100%;
			height: 41px;
			font-weight: 400;
			font-size: 1.3em;

			&:active, &:focus {
				box-shadow: none;
			}

			&:hover {
				background-color: $revfluence-blue-hover;
			}
		}

		.timelineActions_container {
			float: left;

			@include media($break630) {
				display: none;
			}
		}

		.addAttachment_btn, .addAttachment_btn_mobile {
			background: url('/images/style_guide/icon_attach.svg') center no-repeat;
			height: 40px;
			padding: 6px 13px;
			border: 1px solid $lightgray-border;
			border-radius: 0 0 6px 6px;
			display: block;
			width: 100%;
			background-size: 18px;

			&:active, &:focus {
				box-shadow: none;
			}
		}

		.addAttachment_btn {
			cursor: initial;

			&:hover {
				background: $lightgray_bg url('/images/style_guide/icon_attach.svg') center no-repeat;
				background-size: 18px;
			}
		}

		.addAttachment_btn_mobile {
			background: url('/images/style_guide/icon_attach.svg') center no-repeat;
			height: 55px;
			float: left;
			padding: 1.25rem 1rem;
			border: none;
			border-radius: 0 0 6px 6px;
			display: block;
			width: 61px;
			background-size: 18px;

			&:active, &:focus {
				box-shadow: none;
			}
		}

		.noteForm {
			display: none;
			position: relative;

			.message_input {
				width: calc(99% - 80px);

				@include media($break630) {
					width: 100%;
					background-color: $helpful_yellow;
				}
			}

			.send_message_button {
				border-radius: 6px;
				border: 1px solid #CAEEDB;
				border: none;
				font-size: .825rem;
				line-height: 1.4rem;

				@include media($break630) {
					position: absolute;
					bottom: 0;
					right: .75rem;
					width: 100px;
					border-radius: 100px;
					height: auto;
					padding: 8px 20px;
					font-size: .9rem;
				}
			}

			.mentionedDropUp {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;

				.dropdown_list {
					left: 0;
					top: -221px;

					/* Use left: property to change position of tail */
					&:after {
						content: '';
						position: absolute;
						border-style: solid;
						border-width: 10px 10px 0;
						border-color: #FFFFFF transparent;
						display: block;
						width: 0;
						z-index: 1;
						bottom: -10px;
			      right: 8px;
						top: initial;
					}

					/* Use left: property to change position of tail */
					&:before {
						content: '';
						position: absolute;
						border-style: solid;
						border-width: 10px 10px 0;
						border-color: #E2E2E2 transparent;
						display: block;
						width: 0;
						z-index: 0;
						top: initial;
						bottom: -11px;
					 	right: 8px;
					}
				}

				.mentionedDropUp_list {
					overflow-y: auto;
					max-height: 213px;
				}
			}
		}

		.addedAttachments_list {
			padding: .5rem 0 0;
		}

		.addedAttachments_inner_container {
			/* width: 10000px;
			margin-top: 7px;
			height: 50px;
			overflow-y: scroll; */
		}

		.addedAttachment_item {
			float: left;
			margin-right: 5px;
			background-color: #C0C0C0;
			border-radius: 100px;
			padding: 3px 8px;

			@include media($break630) {
				margin: 10px 1rem 26px;
			}

			&:last-child {
				margin-right: 0;
			}

			&:hover {
				background-color: #cacaca;
			}
		}

		.addedAttachment_text {
			display: inline-block;
			color: white;
			font-size: 1.15em;
			vertical-align: -2px;
		}

		.removeAttachment_btn {
			display: inline-block;
			width: 17px;
			height: 17px;
			background: url('/images/new_manage/remove_attachment_icon.svg') center no-repeat;
			padding: 0;

			&:active, &:focus {
				box-shadow: none;
			}
		}

		.new_proposal_item {
			border-radius: 6px;
			margin-bottom: 25px;

			@include media($break1000) {
				background-color: white;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		.dismissed_indicator {
			color: #d9534f;
			font-weight: 800;
		}

		.new_message_container {
			padding: 20px 30px 30px;
			border-bottom: 1px solid $lightgray-border;
			position: relative;

			@include media($break1000) {
				border-bottom: none;
				padding: 20px;
			}

			@include media($break550) {
				padding: 10px 20px;
			}

			@include media($break400) {
				padding: 2px 10px 10px;
			}

			.help_center_link {
				position: absolute;
				bottom: 36px;
				right: 30px;
			}

			.new_proposal_actions_container {
				padding-top: 1rem;

                .tooltip_topLeft {
                    left: 5px;
                    top: 56px;
                }

				.collaborate_btn {

					@include media($break440) {
						margin-top: 1rem;
					}
				}
			}

			.applicationClosed_container {
				margin-top: .75rem;
				width: calc(100% - 40px);
			}

			.address_copy {
				white-space: pre-line;
			}

			.collabOptions_dropdown_container {
				display: inline;
				margin-right: 0.5rem;

				.dropdown_btn {
					border: none;
					margin-bottom: 0rem;
					vertical-align: -2px;
				}
			}
		}

		.new_proposal_title {
			line-height: 1.2em;
			display: inline-block;
			margin: 6px 10px 0 0;
			margin-bottom: 6px;

			@include media($break560) {
				font-size: 2.7em;
				margin: 10px 10px 0 0;
			}

			@include media($break550) {
				font-size: 1.85em;
				margin-bottom: 7px;
			}
		}

		.metadata_item {
			display: inline-block;
			margin-right: 5px;

			&:last-child {
				margin-right: 0;
			}
		}

		.metadata_item {}

		.proposal_underReview_notice {
			display: block;
			margin-top: .25rem;
			font-weight: 600;
		}

		.adminPage_link {
			font-size: 1rem;
			display: inline-block;
			padding-top: 1rem;
		}

		.expiration_indicator {
			font-weight: 600;
		}

		.dismiss_btn {
			display: inline-block;
			margin-right: 8px;

			@include media($break315) {
				display: block;
				width: 100%;
				margin-bottom: 15px;
				margin-right: 0;
			}
		}

		.collaborate_btn {
			display: inline-block;

			@include media($break315) {
				display: block;
				width: 100%;
			}
		}

		.social_accounts_container {
			background: #FAFAFA;
			padding: 15px;
			overflow: hidden;

			@include media($break1000) {
				background: none;
			}

			@include media($break550) {
				padding: 20px 23px 0;
			}

			@include media($break400) {
				padding: 20px 10px 9px;
			}
		}

		.noAccounts_needed {
			margin-bottom: 1rem;

			.icon_alert, .noAccounts_txt {
				float: left;
			}

			.icon_alert {
				width: 25px;
				margin-right: .25rem;
				padding-top: 1rem;
				margin: 0 .7rem 0 0;
			}

			p {
				margin-bottom: 0;
			}

			.noAccounts_txt {
				width: calc(100% - 50px);
			}

			.noAccounts_title {
				font-weight: 600;
			}
		}

		.social_accounts_list {
			overflow: hidden;
		}

		.social_accounts_item {

			&:last-child {
				margin-bottom: 0px;
			}
		}

		.accordionMain_content {

			@include media($break400) {
				padding: .5rem;
			}
		}

		.account_summary_container {
			cursor: pointer;
		}

		.social_account_link {
			color: $dark-text;
			text-decoration: none;
		}

		.network_item {}

		.accountNetwork_icon {
			width: 18px;
			height: 18px;
			margin-right: 10px;
			vertical-align: -3px;
		}

		.account_name_container {
			margin-bottom: 1.35em;
			cursor: pointer;
		}

		.account_link {
			text-decoration: none;
		}

		.account_username {
			font-weight: 600;
			color: $black;
		}

		.account_stats_container {
			margin-bottom: 1em;
		}

		.stats_item {
			display: inline-block;
			width: 100px;
			margin-right: 25px;

			@include media($break355) {
				display: block;
				width: 100%;
				margin-right: 0;
				margin-bottom: 15px;
			}
		}

		.stat_number {
			display: block;
			font-size: 2.5em;
			margin-bottom: 4px;
			font-weight: 300;
		}

		.stat_label {
			display: block;
		}

		.proposal_content_preview_container {
			margin-top: 20px;
		}

		.groups_container {

			.assignOwner_container {
				float: left;
				margin-top: 2px;

				.dropdown_btn {
					border: none;
					padding: 4px 6px 6px 0;
					background: none;
					margin-bottom: 0;
					cursor: pointer;
					margin-right: .35rem;
					max-width: initial;

					&:hover {
						color: $revfluence-blue;
					}
				}

				.icon_user {
					width: 17px;
					vertical-align: 5px;
				}

				.btn_txt {
					display: inline-block;
					font-weight: 500;
					font-size: .85rem;
					max-width: 162px;
				}

				.dropdown_list {
					width: 215px;
					top: 36px;
					right: auto;
					left: -12px;

					&:before, &:after {
						left: 4px;
					}

					.icon_user {
						margin-right: .3rem;
						vertical-align: 0;
						width: 12px;
					}
				}

				.relationshipDropdown_list {
					max-height: 300px;
					overflow-y: auto;
					scrollbar-face-color: #AFAFAF;
					scrollbar-track-color: none;

					@include media($break630) {
					  background-color: #F8F8F8;
					  padding: 0;
					}

					::-webkit-scrollbar {
					  width:  6px;
					}

					::-webkit-scrollbar-thumb {
					  background: #D8D8D8;
					  border-radius: 100px;
					}

					::-webkit-scrollbar-track {
					  background: none;
					}
				}

				.dropdown_list_list {
					max-height: 300px;
					overflow-y: auto;
				}
			}

			.addGrp_container {
				position: relative;
				display: inline-block;
				float: left;

				&:hover {

					.addGrp_dropdown {
						display: block;
					}
				}

				.addItem_icon {
					vertical-align: -5px;
				}

				.createGrp_btn_txt {
					font-size: .9rem;
				}

				/* ADD GROUP BUTTON */
				.addGrp_btn {
					float: left;
					margin-right: .75rem;
					padding: 6px 6px 13px 0;

					&:hover {

						.addGrp_btn_txt {
							color: $revfluence-blue;
						}
					}

					.addGrp_icon {
						width: 24px;
					}

					.addGrp_btn_txt {
						font-size: .85rem;
						font-weight: 500;
					}
				}

				/* ADD TO GROUP DROPDOWN */
				.addGrp_dropdown {
					left: -15px;
					top: 37px;
					width: 235px;

					&:after, &:before {
						left: 16px;
					}

					.addGrp_dropdown_list {
						max-height: 300px;
						overflow-y: auto;
						border-bottom: 1px solid $lightgray-border;
					}

					.createGrp_li {}

					.createGrp_btn {
						background: none;
						padding: .5rem;
						width: 100%;
						text-align: left;
						display: block;

						&:hover {
							background-color: $revfluence-blue;
							border-radius: 0;

							.createGrp_btn_txt {
								color: white;
							}
						}
					}
				}
			}

			.grp_icon {
				margin-right: 1px;
			}

			.addedGrp_name {}

			.remove_icon {}

			.addedGrp_list {
				float: left;
				width: calc(100% - 330px);
				overflow: hidden;
				height: 31px;
				padding-top: 0.5rem;

				.addedGrp {}

				li {
					display: inline-block;
					margin-right: .25rem;

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}

		.content_preview_list {}

		.proposal_content_preview_item {
			float: left;
			margin-right: 14px;

			&:last-child {
				margin-right: 0;
			}
		}

		.content_preview_item {
			display: block;
			float: left;
			margin: 0 1.6% 7px 0;
			position: relative;
			width: 100px;
			max-width: 31%;

			&.youtube_content {
				width: 220px;
			}

			@include media($break400) {
				width: 98px;
			}

			&:last-child {
				margin-right: 0;
			}

			.edit_icon {
				top: 4px;
				right: 5px;
				width: 25px;
				position: absolute;
				z-index: 4;
			}

			.imgContent_container {

				.imgContent {
					width: 100px;
				}
			}

			.videoContent_container {

				.videoThumb {
					width: 220px;
				}
			}
		}

		.campaign_details_summary {
			background-color: white;
			border-radius: 6px;
			border: 1px solid $lightgray-border;
			padding: 24px 25px 24px;
			background: white url('/images/new_manage/close_filters_icon.svg') center no-repeat;
			background-position: 95% 25px;
			cursor: pointer;

			@include media($break355) {
				background-position: 93% 18px;
			}

			.campaign_details_title {
				margin-bottom: 1rem;
			}

			.summary_section_title {
				font-weight: 800;
			}

			.desired_influencer {
				margin-bottom: 1rem;
			}

			.rewards_for_influencers {

				.summary_section_title {
					margin-bottom: .5rem;
				}
			}
		}

		/* Mobile: composerHeader */
		.composerHeader {
			display: none;
			position: relative;

			@include media($break630) {
				display: block;
			}

			.small_leftActions_container {
				float: left;
				width: 120px;
				position: relative;
				z-index: 2;
			}

			.hideComposer_btn {
				background: none;
				padding: 1.25rem 1rem;
				float: left;

				.hide_icon {
					width: 20px;
					height: 13px;
				}
			}

			.composerHeader_title {
				width: 100%;
				padding: 1rem 6.75rem 0;
				text-align: center;
				font-weight: 600;
				position: absolute;
				top: .9rem;
				font-size: 1rem;
				left: 0;
				right: 0;
				z-index: 1;
				top: 0;
			}

			.rightActions_container {
				float: right;
				width: 100px;
				text-align: right;
				position: relative;
				z-index: 2;
			}

			.send_message_button, .saveNote_btn {

				@include media($break630) {
					font-size: .95rem;
					color: $revfluence-blue;
					font-weight: 600;
					padding: 1rem;
					background: none;
					border:none;
					height: auto;
					width: auto;
					display: inline-block;

					&:active, &:focus, &:hover {
						background: none;
					}
				}
			}

			.saveNote_btn {
				display: none;
			}

			.uploadAttachment_form {
				float: left;

				.addAttachment_btn, .addAttachment_btn_mobile {

					@include media($break630) {
						height: 54px;
						width: 45px;
						padding: 1.25rem;
						background: url('/images/style_guide/icon_attach.svg') center no-repeat;
						position: relative;
						border: none;
						background-size: 22px;
						text-indent: -9999px;
					}
				}
			}
		}

		/* Mobile: Reply outside wrapper */
		.replyMsg_container {

			.noteForm {
				display: none;
			}

			.saveNote_btn {
				display: none;
			}

			.noteHeader {
				display: none;
			}

			.noteHeader_title {
				display: none;
			}
		}

		/* Mobile: Note outside wrapper */
		.noteMsg_container {

			@include media($break630) {
				background-color: $hint_yellow;
			}

			.uploadAttachment_form {
				display: none;
			}



			.saveNote_btn {

				@include media($break630) {
					display: inline-block;
				}
			}

			.noteForm {

				@include media($break630) {
					display: block;
				}
			}

			.message_input {
				background-color: $aspireiq_desert_sand_tone1;
			}

			.noteHeader {

				@include media($break630) {
					display: block;
				}
			}

			.composerHeader {
				background-color: $hint_yellow;
			}

			.uploadAttachment_form {
				display: none;
			}

			.replyHeader_title {
				display: none;
			}
		}
	}
}

.hasCampaign_navbar {}

.inbox_view.hasSubNav {

	.messages_container {
		height: calc(100% - 143px);

		@include media($break630) {
			height: calc(100% - 69px);
		}

		@include media($break400) {
			height: calc(100% - 68px);
		}

		@include media($break375) {
			height: calc(100% - 62px);
		}
	}
}

.migrationBanner {
	height: 70px;
	background-color: $aiq_nude;
	padding: 10px;
	margin-bottom: 10px;
	border-radius: 5px;

	.migrateButton {
		position: absolute;
		right: 25px;
		top: 16px;
	}

	.explanationText {
		display: block;
		width: 85%;
	}
}

.archiveBlocker {
	width: 100%;
	height: 100%;

	.title {
		text-align: center;
		padding-bottom: 1rem;
	}

	.archiveModalDialog {
		padding: 2rem;
	}

	.archiveActions {
		padding-top: 2rem;
		text-align: center;
	}
}
