.topNav {
	width: 100% !important;

	.mobile {

		.revfluence_logo {
			display: block;
			width: 160px;
			height: 35px;
			text-indent: -9999px;
			position: absolute;
			top: 1.3em;
			left: 1.5em;
			background: url('/images/themes/aspireiq/aiq_logo.png') center no-repeat;
			background-size: 100%;
			outline: none;
		}

		a {
			outline: none;
		}

		.navBarDropDownMobile {
			right: 1em !important;
			padding: 0 !important;
		}
	}

	.desktop {

		.revfluence_logo {
			background: none;
		}
	}
}

.onboarding {
	.main_nav {
		display: none;
	}
}

.main_nav {
	background-color: white;
	background-color: hsla(255, 0%, 100%, 0.98);
	z-index: 9054;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 50px;
	border-bottom: 1px solid $lightgray-border;

	.creator_profile_logo {
		display: none;
		width: 150px;
		position: absolute;
		margin-left: -75px;
		left: 50%;
		top: .5rem;
	}

	.logo_link {
		float: left;
		outline: none;
		display: block;
		padding: 7px;
		// position: absolute;
		transition: all .25s ease-in-out;

		&:hover {
			opacity: .7;
		}

		@include media($break500) {
			padding: 14px;
		}

		.revfluence_logo {
			display: block;
			width: 160px;

			@include media($break500) {
				display: none;
			}
		}

		.revfluence_logomark {
			display: none;
			width: 35px;

			@include media($break500) {
				display: block;
			}

		}
	}

	.aiq_theme_icons {
		display: none;
	}

	a {
		text-decoration: none;
	}

	.mainNav_inner {
		margin: 0 auto;
	}

  .nolink {
    display: none;
  }

	.nav_links_container {
		float: right;

		@include media($break700) {
			width: 102px;
		}
	}

	.large_nav_links_no_profile {
		margin: 1.2em;
	}

	.large_nav_links {
		padding: 0;
		list-style: none;
		float: left;

		@include media($break865) {
			display: none;
		}

		.active {

			&:hover {
				color: white;
			}

			&.core_link {
				color: $revfluence-blue;

				&:hover {
					color: $revfluence-blue
				}
			}
			.messages_number {
				display: none;
			}
		}

		.core_link {
			padding: 12px 10px 16px;
		}

		.help_link, .menu_link {
			border-left: 1px solid $lightgray-border;

			.icon_dropdown_indicator {
				margin-left: 5px;
				width: 10px;
			}
		}

		.menu_link {
			padding: 17px 15px;
			display: inline-block;

			.menu_icon {
				display: block;
			}
		}

		.menu_li {
			&:hover {
				.menu_dropdown {
					display: block;
				}
			}
		}

		.main_nav_li {
			float: left;
			padding: 0;
			border: 0;
			outline: 0;
			font-weight: inherit;
			font-style: inherit;
			position: relative;

			.messages_text {
				display: inline-block;
				margin-right: .25em;
			}

			.messages_number {
				background-color: $revfluence-blue;
				color: white;
				display: inline-block;
				padding: 0 10px;
				text-align: center;
				font-size: .8em;
				border-radius: 100px;
				font-weight: 300;
				line-height: 1.65em;
				margin-left: 1px;
			}

			.campaign_indicator {
				font-size: 1.1em;
				@include standard-radius;
				padding: .01em .5em;
			}
		}

		.start_subscription_item {
			border-left: 1px solid $lightgray-border;
			padding: 11px 0 11px 10px;
		}

		.campaigns_nav_li {
			position: relative;

			.icon_mainNav_alert {
				display: inline-block;
				vertical-align: -5px;
				margin-left: 1px;
			}
		}

		.core_link {
			color: $dark-text;
			text-decoration: none;
			display: block;

			&:hover {
				color: $revfluence-blue;
			}
		}

		.learn_more_item {
			position: relative;

			.learn_more_txt {
				float: left;
				color: $dark-text;
			}

			&:hover {

				.learn_more_dropdown {
					display: block;
				}

				.learn_more_txt {
					color: $revfluence-blue;
				}
			}

			.learn_more_icon {
				background: url('/images/nav_main/learn_more_icon.svg') center no-repeat;
				width: 23px;
				height: 23px;
				display: inline-block;
				vertical-align: -6px;
			}

			.icon_learn_more {
				width: 26px;
				transition: all .15s ease-in-out;
			}

			.learn_more_txt {
				display: inline-block;
			}

			.learn_more_dropdown {
				position: absolute;
				display: none;
				top: 49px;
				background-color: white;
				right: -2px;
				z-index: $zindexFlashNotification;
				box-shadow: 1px 1.732px 10px 0px rgba(1, 1, 1, 0.08);
				padding: 0px;
				border-radius: 10px;
				border: #E2E2E2 solid 1px;
				width: 150px;
				padding: .5em 0;

				&:after {
					content: '';
					position: absolute;
					border-style: solid;
					border-width: 0 11px 12px;
					border-color: #FFFFFF transparent;
					display: block;
					width: 0;
					z-index: 1;
					top: -12px;
					left: 112px;
				}

				&:before {
					content: '';
					position: absolute;
					border-style: solid;
					border-width: 0 11px 12px;
					border-color: #E2E2E2 transparent;
					display: block;
					width: 0;
					z-index: 0;
					top: -13px;
					left: 112px;
				}
			}

			.learn_more_dropdown_item {
				margin-right: 0;
				display: block;
			}

			.learn_more_link {
				display: block;
				padding: 12px 11px 11px 11px;

				&:hover {

					.icon_learn_more {
						transform: scale(1.15, 1.15);
					}
				}
			}
		}

		.help_li {
			position: relative;

			.help_link {
				display: inline-block;
				color: $dark-text;
				padding: 12px 15px 16px;

				&:hover {
					color: $revfluence-blue;
				}
			}

			.active {
				color: $revfluence-blue;

				&:hover {
					color: $revfluence-blue-hover;
				}
			}

			.help_dropdown_list {
				display: none;
				position: absolute;
				top: 49px;
				right: 2px;
				width: 150px;

				.help_dropdown_item {
					text-align: left;
					display: block;
					margin-right: 0;

					&:last-child {
						margin-bottom: 0;
					}

					.help_dropdown_link {
						padding: .25em 1em;
						display: block;
						color: $dark-text;
						font-size: 1.5em;
						border: none;
						line-height: 1.7em;
						font-weight: 300;
						text-decoration: none;

						&:hover {
							color: white;
							background-color: $revfluence-blue;
						}
					}

					.active {
						color: white;
					}

					.hint_icon {
						vertical-align: -7px;
						margin-left: 2px;
						width: 24px;
					}
				}
			}

			&:hover {

				.help_dropdown_list {
					display: block;
				}
			}
		}

		.account_li {
			position: relative;
			margin-left: 1em;
			padding: 0;
			margin-right: 0;
			margin-top: 0;

			a {
				padding: 0;
			}

			.accountDropdown_list {
				display: none;
				background-color: white;
				position: absolute;
				top: 41px;
				right: -5px;
				z-index: $zindexFlashNotification;
				box-shadow: 1px 1.732px 10px 0px rgba(1, 1, 1, 0.08);
				border-radius: 10px;
				border: #E2E2E2 solid 1px;
				width: 150px;
				padding: .5em 0;

				&:after {
					content: '';
					position: absolute;
					border-style: solid;
					border-width: 0 11px 12px;
					border-color: #FFFFFF transparent;
					display: block;
					width: 0;
					z-index: 1;
					top: -12px;
					left: 112px;
				}

				&:before {
					content: '';
					position: absolute;
					border-style: solid;
					border-width: 0 11px 12px;
					border-color: #E2E2E2 transparent;
					display: block;
					width: 0;
					z-index: 0;
					top: -13px;
					left: 112px;
				}

				.accountDropdown_li {
					margin-right: 0;
					margin-bottom: 0;
					display: block;
				}

				li {
					margin-bottom: 1em;
					text-align: left;

					&:last-child {
						margin-bottom: 0;
					}

					a {
						padding: .25em 1em;
						display: block;
						color: $dark-text;
						font-size: 1.5em;
						border: none;
						line-height: 1.7em;
						font-weight: 300;
						text-decoration: none;

						&:hover, &:active, &:focus {
							color: white;
							background-color: $revfluence-blue;
						}
					}
				}
			}

			&:hover {

				.accountDropdown_list {
					display: block;
				}
			}
		}

		.account_link {
			display: block;
			border: none;

			img {
				border-radius: 20px;
				width: 40px;
				height: 40px	;
			}
		}
	}

	.menu_li {

		.menu_dropdown {
			display: none;
			right: -1px;
			width: 210px;
			top: 49px;

			&:before, &:after {
				left: 172px;
			}
		}
	}

	.small_nav_links {
		display: none;
		float: right;

		@include media($break865) {
			display: block;
		}

		.icon_mainNav_alert {
			display: inline-block;
			vertical-align: -5px;

			@include media($break865) {
				vertical-align: -4px;
			}
		}

		.my_account_item {
			border-top: 1px solid #E2E2E2;
			margin-top: 7px;
		}

		.menu_link {
			cursor: pointer;
		}

		.menu_dropdown {
			margin: 1em 0;
			width: 150px;
			right: 8px;
			padding: .5em 0;
			display: none;
			background-color: white;
			position: absolute;
			top: 39px;
			z-index: $zindexFlashNotification;
			box-shadow: 1px 1.732px 10px 0px rgba(1, 1, 1, 0.08);
			border-radius: 10px;
			border: #E2E2E2 solid 1px;

			&:after {
				content: '';
				position: absolute;
				border-style: solid;
				border-width: 0 11px 12px;
				border-color: #FFFFFF transparent;
				display: block;
				width: 0;
				z-index: 1;
				top: -12px;
				left: 112px;
			}

			&:before {
				content: '';
				position: absolute;
				border-style: solid;
				border-width: 0 11px 12px;
				border-color: #E2E2E2 transparent;
				display: block;
				width: 0;
				z-index: 0;
				top: -13px;
				left: 112px;
			}

			li {
				margin-right: 0;
				margin-bottom: 0;
				display: block;
			}

			.smallLinks_helpCenter_link {

				.hint_icon {
					vertical-align: -5px;
				}
			}

			.how_it_works_item {
				padding-top: .5em;
				border-top: 1px solid #E2E2E2;
				margin-top: .5em;
			}

			.case_studies_item {}

			.select_plan_item {
				padding: .5em 0;
				border-top: 1px solid #E2E2E2;
				margin: .5em 0;
			}

			.divider_top_item {
				padding-top: .5em;
				border-top: 1px solid #E2E2E2;
				margin-top: .5em;
			}

			.divider_bottom_item {
				padding-bottom: .5em;
				border-bottom: 1px solid #E2E2E2;
				margin-bottom: .5em;
			}

			a {
				padding: .25em 1em;
				display: block;
				font-size: 1.5em;
				border: none;
				line-height: 1.7em;
				font-weight: 300;
				text-decoration: none;
				color: $dark-text;

				&:hover, &:active, &:focus {
					background-color: $revfluence-blue;
					color: white;
				}

				&.active {
					font-weight: 600;
					color: white;
					background-color: $revfluence-blue;

					&:hover, &:active, &:focus {
						color: white;
						background-color: $revfluence-blue;
						font-weight: 600;
					}
				}
			}

			.messages_text {
				display: inline-block;
				margin-right: .25em;
			}

			.messages_number {
				background-color: $revfluence-blue;
				color: white;
				@include fontsize8;
				display: inline-block;
				border-radius: 40px;
				padding: .01em .5em;
			}

			.select_plan_button {
				color: white;
				font-size: .85rem;
				font-weight: 400;
				margin: .3rem .5rem 0;
			}
		}

		.menu_li {
			position: relative;
			z-index: 8000;

			&.active {

				.menu_dropdown {
					display: block;
				}
			}

			.menu_link {
				padding: 17px 15px;
				display: inline-block;
				border-left: 1px solid $lightgray-border;

				.menu_icon {
					display: block;
				}
			}
		}
	}
}

.onboarding_tinder {

	.revfluence_logo {
		float: none;
		display: none;

		a {
			margin: 0 auto;
		}

		&.nolink {
			display: block;
		}
	}

	.large_nav_links, .small_nav_links {
		display: none;
	}
}
