/* Major breakpoints: 750 */

.pricing_landing {

	a {
		text-decoration: none;
	}

	.welcome_nav {

		.primaryEntry_links {
			vertical-align: 21px;
		}
	}

	.main_header {
		text-align: center;
		padding: 7rem 0 4.25rem;

		@include media($break400) {
			padding: 5rem 2rem 3rem;
		}

		.main_title {
			margin-bottom: .12em;
			font-size: 4.7em;

			@include media($break540) {
				font-size: 2.25rem;
				font-weight: 400;
				margin-bottom: 1rem;
			}

			@include media($break400) {
				font-size: 3em;
			}
		}

		.main_descrip {
			font-size: 1.35rem;
			font-weight: 400;

			@include media($break400) {
				font-size: 1.85em;
			}
		}
	}

	strong {
		font-weight: 600;
	}

	.pricing_options {
		overflow: hidden;
		max-width: 1400px;
		margin: 0 auto 3em;
		padding: 1.2em 1rem 1.4em;

		@include media($break1450) {
			padding: 1.2em 5rem 1.4em;
		}

		@include media($break750) {
			padding: 1.2em 2rem 1.4em;
		}

		@include media($break425) {
			padding: 1.2em 1rem 1.4em;
		}

		.plan_indicator {
			float: left;
			width: 100%;
			text-align: center;
			padding: .5rem 0 .45rem;
			border-radius: 4px 4px 0 0;
			background: #C1C763;

			.plan_indicator_txt {
				display: inline-block;
				color: white;
				text-transform: uppercase;
				font-weight: 600;
				font-size: .75rem;
				letter-spacing: .2em;
				vertical-align: 5px;
			}
		}

		div {

			&:last-child {

				.plan {
					margin-right: 0;

					@include media($break1080) {
						margin-right: auto;
					}
				}
			}
		}

		.current_plan {

			.current_plan_icon {
				display: inline-block;
				margin-right: .6em;
				vertical-align: 5px;
			}
		}

		.last_plan {
			margin-right:0 !important;

			@include media($break1080) {
				margin: 0 auto 4em !important;
			}
		}

		.plan {
			float: left;
			width: 32%;
			border-radius: 13px;
			transition: all .15s ease-in-out;
			border: 1px solid $lightgray-border;
			margin-right: 2%;

			&:hover {
				// -ms-transform: scale(1.018,1.018); /* IE 9 */
				//-webkit-transform: scale(1.018,1.018); /* Safari */
				//transform: scale(1.018,1.018);
				//box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
			}

			@include media($break1080) {
				text-align: left;
				width: 750px;
				margin: 0 auto 4em;
				float: none;
			}

			@include media($break830) {
				width: 100%;
				margin: 0 0 4em;
			}

			@include media($break425) {
				margin: 0 0 2em;
			}
		}

		.pricing_header {
			padding: 2rem 2.5rem 2.75rem;
			color: $dark-text;
			text-align: center;

			@include media($break1450) {
				height: 299px;
			}

			@include media($break1080) {
				padding: 2.5em 5em 2.5rem;
				height: auto;
			}

			@include media($break750) {
				text-align: center;
				height: auto;
			}
		}

		.pricing_title {
			color: $dark-text;
			font-weight: 500;
			font-size: 3.6em;
			padding-bottom: 2rem;
		}

		.pricing_descrip {
			padding-bottom: 2rem;
			font-size: 1rem;
			font-weight: 500;
			height: 104px;

			@include media($break1080) {
				height: auto;
			}
		}

		.choose_plan_btn {
			font-size: 1.05rem;
			padding: 9px 35px;
		}

		.networks_container {
			margin-bottom: 2em;
		}

		.networks_text {}

		.instagram_icon, .youtube_icon {
			display: inline-block;
			vertical-align: -2.5px;
		}

		.instagram_icon {
			padding: 0 .16em 0 .08em;
		}

		.youtube_icon {
			padding: 0px 0.26em 0px 0.1em;
		}

		.plan_features {
			height: 365px;
			font-size: 1.65em;

			@include media($break1265) {
				height: 390px;
			}

			@include media($break1080) {
				height: auto;
			}
		}

		.feature_item {
			padding: .85rem .9rem .85rem 2.5rem;
			font-weight: 400;
			color: $dark-text;
			font-size: .93rem;
			position: relative;

			&:last-child {
				margin-bottom: 0;
				border-bottom: none;
			}

			&:nth-child(even) {
				background: $ultraLight_gray;
			}

			&:nth-child(odd) {}
		}

		.bespoke_plan {
			display: none;
		}

		.pro_plan {

			.pricing_header {
				background-color: $revfluence-blue;
				border-radius: 13px 13px 0 0;
			}

			.pricing_title, .pricing_descrip {
				color: white;
			}

			.choose_plan_btn {
				background-color: white;
				color: $revfluence-blue;

				&:hover {
					background-color: $ultraLight_gray;
				}
			}

			.feature_item {

				&:nth-child(even) {
					background: #DAEAFF;
				}

				&:nth-child(odd) {
					background-color: #FAFCFF;
				}
			}

			.plan_features {

				@include media($break1265) {
					height: 390px;
				}

				@include media($break1080) {
					height: auto;
				}
			}
		}

		/* Use this class for items that are unique to the plan, it bolds them */
		.unique_item {
			font-weight: 700;
		}

		.actions_container {
			padding: 2em 2em 2.5rem 2em;
			text-align: center;

			@include media($break1080) {
				text-align: left;
				padding: 1em 2em 3em 5em;
			}

			@include media($break400) {
				padding: 1em 3em 3em 3em;
			}

			.choose_plan_btn {
				border-radius: 6px;
				display: block;
				max-width: 200px;
				margin: 0 auto;
				width: 100%;
				padding: 7px 20px;
				font-size: 1rem;
			}
		}
	}

	.icon_checkmark {
		width: 16px;
		margin-right: .5rem;
		position: absolute;
		top: 36%;
		left: 15px;
	}

	.current_plan {

		.choose_plan_btn {
			opacity: .5;
			border-radius: 6px;
			width: 220px;
		}
	}

	.first-loading-view {
		background-color:rgba(255, 255, 255, 1.0);
	}

	.agency_suggest {
		text-align: center;
		font-size: 1.35rem;
		font-weight: 400;
		padding-bottom: 3rem;

		.show_demo_btn {
			cursor: pointer;
		}
	}

	.logo_list {
		text-align: center;
		padding-bottom: 3rem;

		li {
			display: inline-block;
		}

		.brand_logo {
			width: 240px;
		}
	}

	.section_title {
		text-align: center;
		padding: 3.5rem 0 3rem;

		@include media($break630) {
			font-size: 2rem;
		}
	}

	.testimonial_wrap {
		background: url('/images/style_guide/blurred_bg1.jpg') center no-repeat;
		padding-top: 4rem;

		.inner_container {
			max-width: 775px;
			margin: 0 auto;
			padding-bottom: 4.5rem;

			@include media($break770) {
				padding: 0 1rem 4.5rem;
			}
		}

		.testimonial_avatar {
			width: 105px;
			float: left;
			margin-right: 1rem;
			margin-top: 3.15rem;

			@include media($break770) {
				width: 70px;
				margin-top: 0;
			}
		}

		.testimonial_txt_wrap {
			background: url('/images/style_guide/chat_bubble_bg.png') center no-repeat;
			float: left;
			width: calc(100% - 121px);
			padding: 1.25rem 2rem 1.5rem 2.75rem;
			background-size: 100%;

			@include media($break770) {
				font-size: 1rem;
				background: none;
				background: none;
				width: calc(100% - 86px);
				padding: 0;
			}
		}

		.testimonial_txt {
			padding-bottom: 1rem;
			font-size: 1.35rem;

			@include media($break770) {
				font-size: 1rem;
			}
		}

		.testimonial_name {
			font-weight: 600;
			font-size: 1.35rem;

			@include media($break770) {
				font-size: 1rem;
			}
		}

		.casestudy_suggest {
			background-color: white;
			background-color: rgba(255,255,255,.75);
			padding: 1rem 0;
			font-weight: 600;
			text-align: center;
		}

		.casestudy_suggest_txt {
			display: inline-block;
			font-weight: 600;
			margin-right: 1rem;
			font-size: 1.35rem;
			vertical-align: -6px;

			@include media($break770) {
				display: block;
				margin-right: 0;
				margin-bottom: 1rem;
			}
		}

		.casestudy_suggest_btn {
			font-size: 1.05rem;
			padding: 9px 24px;
		}
	}

	.features_wrap {

		.inner_container {
			margin: 0 auto;
			max-width: 1400px;
		}

		.features_title {}

		.features_list {
			font-size: 1.35rem;
			padding: 0 4.95rem;

			@include media($break770) {
				padding: 0 1rem;
			}
		}

		.feature_icon {
			width: 75px;
			margin-bottom: 1.5rem;
		}

		.features_item {
			float: left;
			width: 33.33%;
			text-align: center;
			height: 340px;
			padding: 0 2rem;

			@include media($break1355) {
				height: 373px;
			}

			@include media($break1170) {
				width: 49.33%;
			}

			@include media($break830) {
				height: 405px;
			}

			@include media($break800) {
				height: 445px;
			}

			@include media($break980) {
				width: 49.33%;
			}

			@include media($break770) {
				height: auto;
				width: 100%;
				margin-bottom: 3rem;
			}

			@include media($break660) {
				height: 471px;
			}

			@include media($break560) {
				height: auto;
				float: none;
				width: 100%;
				margin-bottom: 3rem;
			}
		}

		.feature_name {
			font-weight: 600;
			display: block;
			padding-bottom: 1.5rem;
		}
	}

	.faq_wrap {
		background-color: #FAFAFA;

		.faq_title {}

		.faq_list {
			padding-bottom: 3rem;
			max-width: 1000px;
			margin: 0 auto;
			font-size: 1.35rem;

			@include media($break1040) {
				padding: 0 2rem 3rem;
			}
		}

		.faq_item {
			border-bottom: 1px solid #E2E2E2;
			padding-bottom: 2rem;
			margin-bottom: 2rem;

			&:last-child {
				border-bottom: none;
				padding-bottom: 0;
			}
		}

		.faq_question {
			padding-bottom: 1rem;
			font-weight: 600;
		}

		.faq_answer {}
	}

	.request_demo_wrap {
		text-align: center;
		padding: 3rem 0;

		.request_demo_title {
			padding-bottom: 1rem;

			@include media($break350) {
				font-size: 2rem;
			}
		}

		.request_demo_descrip {
			padding-bottom: 1.5rem;
			font-size: 1.35rem;
		}

		strong {
			font-weight: 600;
		}

		.request_demo_form {
			padding-bottom: 1rem;
			max-width: 300px;
			margin: 0 auto;
		}

		.email_input {
			margin-bottom: 1rem;
		}

		.submit_email_btn {
			display: block;
			max-width: 150px;
			margin: 0 auto 2rem;
		}

		.error {}
	}

	.help_container {
		width: 212px;
	}
}

.pricing-modal {

	.modal-dialog {
		width:550px;
		padding: 2rem 2rem 3rem;
	}

	.main_title, .main_descrip {
		text-align: center;
	}

	.main_title {
		margin-bottom: .5rem;
	}

	.main_descrip {
		margin-bottom: 1.25rem;
	}

	.okay_btn {
		width: 100px;
		margin: 0 auto;
		display: block;
	}

	.confirmation_actions{
		text-align:center;
	}

	.confirmation_button{
		width: 100px;
		display:inline;
		margin-right:0.5rem;
	}
}
