.marketing_content_section {
  background-color: #F6F6F6;

  .inner_container {
    max-width: 1270px;
    margin: 0 auto;
  }

  .doubleCol {
    float: left;
    width: 50%;
    padding: 1rem 2rem 2rem;
    position: relative;

    &:first-child {
      border-right: 1px solid #DEDEDE;

      @include media($break825) {
        border-right: none;
        border-bottom: 1px solid #DEDEDE;
      }
    }

    @include media($break825) {
      width: 100%;
      float: none;
    }
  }

  .doubleCol_link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &:hover {

      .btn_primary {
        background-color: $revfluence-blue-hover;
      }
    }
  }

  .img_col, .txt_col {
    float: left;

    @include media($break450) {
      float: none;
    }
  }

  .img_col {
    width: 215px;
    margin-top: -51px;

    @include media($break1050) {
      width: 165px;
      margin-top: 0;
    }

    @include media($break450) {
      width: 100%;
      text-align: center;
      margin-top: 1.75rem;
    }
  }

  .larger_img_col {
    width: 235px;
    margin-top: -33px;

    @include media($break1050) {
      margin-top: 0;
      width: 191px;
    }

    @include media($break450) {
      width: 100%;
      text-align: center;
      margin-bottom: 1rem;
      margin-top: 1.75rem;
    }
  }

  .pricing_ebook {
    max-width: 100%;

    @include media($break450) {
      width: 150px;
      display: block;
      margin: 0 auto;
    }
  }

  .txt_col {
    width: calc(100% - 247px);
    padding-top: 3.5rem;
    margin-right: 2rem;

    @include media($break1050) {
      width: calc(100% - 197px);
    }

    @include media($break450) {
      width: 100%;
      text-align: center;
      padding-top: 1rem;
    }
  }

  .smaller_txt_col {
    width: calc(100% - 267px);
    padding-top: 1.26rem;

    @include media($break1050) {
      width: calc(100% - 223px);
    }

    @include media($break450) {
      width: 100%;
      text-align: center;
      padding-top: 1rem;
    }
  }

  .descrip_txt {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .15rem;
    margin-bottom: .9rem;
    font-size: .9rem;
    display: inline-block;
    background-image: linear-gradient(90deg, #FFE857 0%, #FFCD2C 100%);
    border-radius: 6px;
    color: white;
    padding: .25rem .5rem;
  }

  .book_title_txt {
    margin-bottom: 1.25rem;

    @include media($break1050) {
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.6rem;
    }

    @include media($break450) {
      font-size: 1.45rem;
      line-height: 1.9rem;
    }
  }

  .primary_btn {}

  .download_icon {
    margin-right: .5rem;
    vertical-align: -4px;
  }
}
