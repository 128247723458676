.aspireiq_theme {

  .pricing_options {

    .plan_indicator {
      background: $aspireiq_persian_blue;
    }

    .actions_container {

      .choose_plan_btn {
        font-size: .85rem;
        padding: 10px 20px;
      }
    }
  }

  .supporting_info {

    .contact_us {
      background-color: $aspireiq_desert_sand_tone1;

      &:hover {
          background-color: $aspireiq_desert_sand_tone1_border;
      }

      &:active {
        background-color: $aspireiq_desert_sand_tone1;
      }
    }
  }
}
