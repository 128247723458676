.panel {
  border: 1px solid $lightgray-border;
  border-radius: 6px;
  padding: .5rem;
  background-color: white;
}

@mixin panel {
  border: 1px solid $lightgray-border;
  border-radius: 6px;
  padding: 1rem;
  background-color: white;
}
