.add_social_accounts {
	background-color: white;
	padding-top: 4em;

	.confirm_account_deletion {
		position: absolute;
		width: 250px;
		border: 1px solid $lightgray-border;
		box-shadow: 3px 3px 7px rgba(0,0,0,.5);
		display: none;
		background-color: white;
		z-index: $zindexTutorial;
		padding: 1em;
		@include standard-radius;
		top: 4em;
		right: 0;

		.confirm_delete_message {
			@include fontsize14;
			color: $dark-text;
			margin-bottom: 1em;
		}

		.actions_list {

			li {
			 	display: inline-block;
			 	margin-right: 1em;

			 	&:last-child {
			 		margin-right: 0;
			 	}
			}

			.yes_button {
				background-color: $revfluence-blue;
				@include standard-radius;
				color: white;
				padding: .5em 1em;
				display: block;
				text-decoration: none;
				@include uppercase12;

				&:hover {
					background-color: $revfluence-blue-hover;
				}
			}

			.no_button {
			 	background-color: #afafaf;
			 	@include standard-radius;
			 	color: white;
			 	padding: .5em 1em;
			 	display: block;
			 	text-decoration: none;
			 	@include uppercase12;
			 	&:hover {
			 		background-color: #9c9c9c;
			 	}
			}
		}
	}

	.revfluenceBrand_bar {

		.revfluence_logo {
			background: url('../images/themes/aspireiq/aiq_logo.png') center no-repeat;
			background-size: 100%;
			width: 162px;
			height: 35px;
			text-indent: -9999px;
			display: block;
			margin: 0 auto;
			padding: 3em 0;
		}
	}

	.intro_container {
		padding: 0 0 1em;
        	margin-top: 3em;
		@include media($break1020) {
			padding: 0 0 1em;
		}

		@include media($break430) {
			padding: 0 0 1em;
		}

		.intro_title {
			color: $dark-text;
			@include title30;
			letter-spacing: 0;
			font-weight: 300;
			text-align: center;
			margin-bottom: .25em;
			@include media($break1020) {
        			@include title25;
        		}
			@include media($break585) {
				@include title25;
				font-weight: 500;
			}
			@include media($break430) {
				@include title20;
				font-weight: 500;
			}
		}

		.intro_subtitle {
			color: $dark-text;
			@include fontsize16;
			letter-spacing: 0;
			font-weight: 300;
			text-align: center;
			margin-bottom: .25em;
			@include media($break1020) {
        			@include fontsize14;
        		}
			@include media($break585) {
				@include fontsize14;
				font-weight: 500;
			}
			@include media($break430) {
				@include fontsize14;
				font-weight: 500;
			}
		}

        .skip_text {
			display: block;
			width: 200px;
			margin: 0 auto;
			color: white;
			text-decoration: none;
			@include fontsize15;
			text-align: center;
			font-style: italic;
        }

		.skip_link {
            text-decoration: underline;
            color: white;
		}
	}

	.main {

		.accounts_body {
			width: 950px;
			margin: 0 auto;
			padding: 3em 0 4em;
			@include media($break975) {
				padding: 2em 2em 4em;
				width: 100%;
			}
			@include media($break815) {
				width: 500px;
			}
			@include media($break505) {
				width: 100%;
				padding: 0;
			}
		}

		.error_container {
			display: block;
			width: 100%;
			margin-bottom: 2em;
			@include media($break505) {
				border-radius: 0;
				margin: 0 auto 0;
			}

			.error_descrip {
				color: white;
				@include fontsize16;
				text-align: center;
	           	}

			a {
				color: white;
				text-decoration: underline;

				&:hover {
				text-decoration: none;
				}
			}
		}

      .info_container {
          background-color: $revfluence-blue;
	  }

		.user_accounts_container {
			width: 46%;
			margin-right: 6%;
            display: inline-block;
            vertical-align: top;
			@include media($break815) {
				width: 100%;
                display: block;
				margin-bottom: 2em;
				margin-right: 0;
                padding: 2em 0 3em 0;
			}
		}

		.recently_added_list {
			margin-bottom: 2em;
			background-color: #ffffdb;
			padding: 2em;
			@include media($break505) {
				padding: 1em;
			}

			li {
				background-color: white;
			}
		}

		.addMore_accounts {
			width: 47%;
			display: inline-block;
			@include media($break815) {
				width: 100%;
                display: block;
			}
		}

		.for_mobile {
			display: none;
			.unavailable_buttons {
				display: none;
			}
			.available_title {
				text-align: center;
			}
			.availableButtons_list {
				text-align: center;
			}

			@include media($break505) {
				display: block;
			}
		}

		.for_desktop {
			@include media($break505) {
				display: none;
			}
		}

		.available_title {
			@include fontsize16;
			color: $dark-text;
			margin-bottom: 1em;
			@include media($break505) {
				padding: 0 1em;
			}
		}

		.available_buttons {
			margin-bottom: 2em;
		}

		.availableButtons_list {
			@include media($break505) {
				padding: 0 2em;
			}

			li {
				display: inline-block;
				margin-right: 1em;
			}
		}

		.existingAccounts_title {
			@include fontsize16;
			color: $dark-text;
			margin-bottom: .5em;
			@include standard-bold;
			@include media($break505) {
				padding: 0 1em;
			}
		}

		.correct_account_confirmation {
			@include fontsize14;
			color: $dark-text;
			@include media($break505) {
				margin-top: 1em;
				text-align: center;
			}
		}

		.existingAccounts_list {
			@include media($break505) {
				padding: 0 1em;
			}
		}

		.existingAccounts_list, .recently_added_list {

			.existingAccounts_li, .recentlyAdded_li {
				margin-bottom: 1em;
				border: 1px solid $lightgray-border;
				padding: 1em;
				@include standard-radius;
				position: relative;
				@include media($break505) {
					margin-bottom: .5em;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}

			.none_added {
				border: 1px dashed #858585;
				background-color: $lightgray-bg;
				@include fontsize16;
				color: $dark-text;
			}

			.newly_added {
				background-color: #ffffdb;
			}

			.social_icon {
				width: 21px;
				height: 21px;
				display: inline-block;
				margin-right: 1em;
				vertical-align: -0.44em;
			}

			.social_text {
				color: $dark-text;
				display: inline-block;
				@include fontsize14;
				overflow: hidden;
				text-overflow: clip ellipsis;
				white-space: nowrap;
				width: 80%;
				line-height: inherit;
			}

			.username {
				font-weight: 500;
			}

			.added_via_oauth {
				font-style: italic;
			}

			.close_link {
				display: inline-block;
				width: 12px;
				height: 12px;
				background: url('../images/publisher_user/close-icon.svg') center no-repeat;
				text-indent: -9999px;
				position: absolute;
				top: 1.5em;
				right: 1.5em;
			}
		}

		.social_button {
			width: 63px;
			height: 63px;
			display: inline-block;
			text-indent: -9999px;
		}

		.instagram_button {
			background: url('../images/publisher_user/instagram_round_button.svg') center no-repeat;
		}

		.youtube_button {
			background: url('../images/publisher_user/youtube_round_button.svg') center no-repeat;
		}

		.facebook_button {
			background: url('../images/publisher_user/facebook_round_button.svg') center no-repeat;
		}

		.twitter_button {
			background: url('../images/publisher_user/twitter_round_button.svg') center no-repeat;
		}

		.vine_button {
			background: url('../images/publisher_user/vine_round_button.svg') center no-repeat;
		}

		.unavailable_title {
			@include fontsize16;
			color: $dark-text;
			margin-bottom: 1em;
			@include media($break505) {
				padding: 0 1em;
			}
		}

		.unavailableButtons_list {
			@include media($break505) {
				padding: 0 1em;
			}

			li {
				display: inline-block;
				margin-right: 1em;
			}

			a {
				opacity: .5;
				cursor: default;
			}
		}

		.accounts_footer {
			padding: 3em 0;
			border-top: 1px solid $lightgray-border;

			.continue_btn {
				width: 350px;
				display: block;
				margin: 0 auto 1em;
				text-align: center;
				@include media($break505) {
					width: 95%;
				}
			}

			.btn_disabled {
				opacity: 0.5;
				pointer-events: none;
				cursor: default;
			}

			.future_changes_notice {
				@include fontsize16;
				display: block;
				text-align: center;
				color: $dark-text;
				@include media($break505) {
					padding: 0 1em;
				}
			}
		}
	}
}
