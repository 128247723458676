.howto_addSnapchat_modal {

  .modal-dialog {
    padding: 1.5rem 1.5rem 2rem;
  }

  .howto_addSnap_title {
    padding-bottom: 2rem;
    text-align: center;

    @include media($break465) {
      font-size: 1.8rem;
    }
  }

  .howto_addSnap_list {
    padding-bottom: 1.5rem;
  }

  .howto_addSnap_item {
    float: left;
    margin-right: 3%;
    width: 30%;
    text-align: center;

    &:last-child {
      margin-right: 0;
    }

    @include media($break465) {
      float: none;
      margin-right: 0;
      width: 100%;
      padding-bottom: 2rem;
    }

    strong {
      font-weight: bold;
    }
  }

  .rev_snapchat_visualContainer {
    height: 90px;
    margin-bottom: 1rem;
  }

  .rev_snapchat_visual {}

  .rev_snapchat_codeVisual {
    width: 90px;
  }

  .rev_snapchat_sendVisual {
    width: 127px;
  }

  .rev_snapchat_verifiedVisual {
    width: 73px;
    padding-top: 1rem;
  }

  .howto_addSnap_txt {
    display: block;
    font-weight: 500;
    word-wrap: break-word;
  }

  .howto_addSnap_ok_btn {
    display: block;
    margin: 0 auto;
    width: 150px;

    @include media($break400) {
      width: 100%;
      margin: 0;
    }
  }
}
