.creator_profile {

  .accounts_list_container {
    padding: .75rem 1rem 1rem;

    .total_reach_number {
      padding-bottom: 1rem;
      border-bottom: 1px solid $lightgray-border;
      display: block;
    }

    .social_accounts_list {}

    .social_account_item {
      border-bottom: 1px solid $lightgray-border;
      position: relative;
    }

    .social_account_link {
      position: relative;
    }

    .mobile_addSnap_content_btn {
      padding: 0;
      border: none;
      position: absolute;
      left: 32%;
      top: 1px;

      @include media($break550) {
        left: 23%;
      }
    }

    .mobile_addSnap_content_icon {
      width: 25px;
    }

    .details_toggle_link {
      text-decoration: none;
      display: block;
      padding: .5rem 2.5rem .9rem .78rem;

      @include media($break550) {
        padding: .5rem 2.5rem .9rem 0;
      }
    }

    .disclosure_icon {
      position: absolute;
      top: 50%;
      margin-top: -12px;
      right: 1rem;
      width: 12px;

      @include media($break550) {
        width: 10px;
      }
    }

    .youtube_icon {}

    .instagram_icon {
      vertical-align: -3px;
    }

    .twitter_icon {}

    .network_icon {
      margin-right: .65rem;
      width: 25px;
    }

    .account_name_txt {
      font-size: 1.35rem;
      display: inline-block;
      vertical-align: -5px;
      width: calc(100% - 38px);
    }
  }
}
