/*

Starter HTML:

<div class="tooltip_container">
  <button class="tooltip_btn btn"><img src="/images/icons/multicolor/help.svg" width="24" height="24" alt class="hint_icon"></button>
  <div class="tooltip_topLeft tooltip">
    <span class="tooltip_txt">Lorem ipsum</span>
  </div>
</div>

*/

.tooltip_container {
  position: relative;
  display: inline-block;

  .tooltip_btn {
    border: none;
    padding: 0;
    background: none;
  }

  .tooltip {
    padding: .5rem .5rem .65rem .5rem;
    position: absolute;
    width: 235px;
    background: #FFFFFF;
    border-radius: 6px;
    border: $hint_border_yellow solid 1px;
    box-shadow: 0 1px 3px rgba(0,0,0,.05);
    z-index: 8000;
    background-color: $hint_yellow;
    display: none;
  }

  &:hover {

    .tooltip {
      display: block;
    }
  }

  .hint_icon {}

  .tooltip_txt {
    font-size: .8rem;
  	line-height: 1.5em;
  	font-weight: 300;
    letter-spacing: .01rem;
    color: $dark-text !important;
    text-transform: none !important;
    white-space: normal;
  }

  /* USE FOR CONFIRM DELETE ITEMS */
  .confirmDelete_tooltip {

    .btn_primary, .btn_default {
      display: inline-block;
    }

    .btn_primary {}

    .btn_default {}

    .tooltip_txt {
      display: block;
      padding-bottom: .25rem;
      font-weight: 500;
    }
  }

  /* USE THIS FOR TOP LEFT QUADRANT OF VIEWPORT */
  .tooltip_topLeft {
    left: -9px;
    top: 31px;

    &:after {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 0 10px 10px;
      border-color: $hint_yellow transparent;
      display: block;
      width: 0;
      z-index: 1;
      top: -10px;
      left: 8px;
    }

    &:before {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 0 10px 10px;
      border-color: $hint_border_yellow transparent;
      display: block;
      width: 0;
      z-index: 0;
      top: -11px;
      left: 8px;
    }
  }

  /* USE THIS FOR TOP RIGHT QUADRANT OF VIEWPORT */
  .tooltip_topRight {
    right: -9px;
    top: 31px;

    &:after {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 0 10px 10px;
      border-color: $hint_yellow transparent;
      display: block;
      width: 0;
      z-index: 1;
      top: -10px;
      right: 8px;
    }

    &:before {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 0 10px 10px;
      border-color: $hint_border_yellow transparent;
      display: block;
      width: 0;
      z-index: 0;
      top: -11px;
      right: 8px;
    }
  }

  /* USE THIS FOR TOP CENTER TOOLTIP */
  .tooltip_topCenter {
    right: -9px;
    top: 31px;
    text-align: center;

    &:after {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 0 10px 10px;
      border-color: $hint_yellow transparent;
      display: block;
      width: 0;
      z-index: 1;
      top: -10px;
      right: 8px;
    }

    &:before {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 0 10px 10px;
      border-color: $hint_border_yellow transparent;
      display: block;
      width: 0;
      z-index: 0;
      top: -11px;
      right: 8px;
    }
  }

  /* USE THIS FOR BOTTOM LEFT QUADRANT OF VIEWPORT */
  .tooltip_bottomLeft {
    left: -9px;
    bottom: 31px;

    &:after {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 10px 10px 0;
      border-color: $hint_yellow transparent;
      display: block;
      width: 0;
      z-index: 1;
      bottom: -10px;
      left: 8px;
    }

    &:before {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 10px 10px 0;
      border-color: $hint_border_yellow transparent;
      display: block;
      width: 0;
      z-index: 0;
      bottom: -11px;
      left: 8px;
    }
  }

  /* USE THIS FOR BOTTOM CENTER TOOLTIP */
  .tooltip_bottomCenter {
    left: -9px;
    bottom: 31px;

    &:after {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 10px 10px 0;
      border-color: $hint_yellow transparent;
      display: block;
      width: 0;
      z-index: 1;
      bottom: -10px;
      left: 8px;
    }

    &:before {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 10px 10px 0;
      border-color: $hint_border_yellow transparent;
      display: block;
      width: 0;
      z-index: 0;
      bottom: -11px;
      left: 8px;
    }
  }

  /* USE THIS FOR BOTTOM RIGHT QUADRANT OF VIEWPORT */
  .tooltip_bottomRight {
    right: -9px;
    bottom: 31px;

    &:after {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 10px 10px 0;
      border-color: $hint_yellow transparent;
      display: block;
      width: 0;
      z-index: 1;
      bottom: -10px;
      right: 8px;
    }

    &:before {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 10px 10px 0;
      border-color: $hint_border_yellow transparent;
      display: block;
      width: 0;
      z-index: 0;
      bottom: -11px;
      right: 8px;
    }
  }

  .tooltip_black {
    padding: .5rem .5rem .65rem .5rem;
    position: absolute;
    width: 235px;
    background: rgba(0,0,0,.9);
    background-color: rgba(0,0,0,.9);
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0,0,0,.05);
    z-index: 8000;
    display: none;
    color: white;
    border: none;
    border-width: 10px 10px 0;

    &:before, &:after {
      border-color: rgba(0,0,0,.9) transparent;
    }

    &:before {
      border-color: rgba(0,0,0,.9) transparent !important;
    }

    .tooltip_txt {
      font-size: .8rem;
    	line-height: 1.5em;
    	font-weight: 300;
      letter-spacing: .01rem;
      color: white !important;
      text-transform: none !important;
    }
  }

  .black_tooltip {
    background-color: $dark-text;
  }

  .center_tooltip {
    text-align: center;
  }

  .smallCenter_tooltip {
  	width: 85px;
  	text-align: center;
  	left: -23px;
  	bottom: 40px;

  	&:before, &:after {
  		left: 32px;
  		bottom: -7px;
  	}
  }
}

.tooltip_sm {

  .tooltip {
    width: 130px;
  }

  .tooltip_topCenter {
    left: 50%;
    margin-left: -70px;

    &:after {
      right: 51px;
    }

    &:before {
      right: 51px;
      top: -10px;
    }
  }
}


/* CONTENT TOOLTIP SAMPLE HTML
<div class="contentTooltip_wrapper">
  <ul class="contentTooltip_list">
    <li class="contentTooltip_item">
      <img src="/images/style_guide/sample_avatar.jpg" alt class="img_content">
      <div class="contentTooltip">
        <img src="/images/style_guide/sample_avatar.jpg" alt class="tooltipContent_img">
      </div>
    </li>
  </ul>
</div>

 */
.contentTooltip_wrapper {

  /* EXAMPLE CONTENT POPUP - USE FOR CONTENT BELOW HALF THE VERTICAL VIEWPORT */
  .contentTooltip {
    display: none;
    position: absolute;
    width: 250px;
    height: 250px;
    padding: .5rem;
    background: #FFFFFF;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    z-index: 6000;
    bottom: 52px;
    left: -188px;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.12);

    &:after, &:before {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 15px 15px 0;
      border-color: #FFFFFF transparent;
      display: block;
      width: 0;
      z-index: 1;
      bottom: -15px;
      left: 201px;
    }

    /* LARGE THUMBNAIL */
    .tooltipContent_img {
      max-width: 100%;
      width: 100%;
    }
  }

  .contentTooltip_item {
    display: inline-block;
    vertical-align: -14px;
    margin-right: 5px;
    position: relative;

    &:hover {

      .contentTooltip {
        display: block;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  /* SMALL THUMBNAIL */
  .img_content {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 4px;
  }
}

/* DEPRECATE THIS SHIT
.standard_tooltip, .standardTooltip {
  z-index: 9999;
  text-align: center;
  font-weight: 300;
  color: white;
  padding: .5rem;
  position: absolute;
  width: 250px;
  background: rgba(0,0,0,.85);
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  left: -115px;
  bottom: 41px;

  &:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 12px 11px 0;
    border-color: #000 transparent;
    display: block;
    width: 0;
    z-index: 1;
    bottom: -12px;
    left: 114px;
  }

  .tooltip_txt {
    display: block;
    color: white;
  }
}
*/
