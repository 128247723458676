.edit_agreement {
	background-color: $agreements_purple_subtle;

    .standard-error-message {
        @include standard-error-message;
        display: none;
    }

    .form-error-message {
        @include form-error-message;
        display: none;
    }

	.intro_container {
		margin: 10em 0 5em;
		text-align: center;
		@include media($break900) {
			width: 95%;
			margin: 9em auto 4em;
		}

		.intro_container {
			margin-bottom: 1em;

			.intro_icon {
				display: inline-block;
				margin-right: 1em;
				@include standard-radius;
				width: 40px;
				height: 40px;
				background: url('../images/agreements/agreements_circle_icon.svg') center no-repeat;
				@include media($break325) {
					margin-right: 0;
					margin-bottom: 1em;
					display: block;
					margin: 0 auto 1em;
				}
			}

			.intro_title {
				display: inline-block;
				@include title35;
				color: $dark-text;
				vertical-align: 8px;
				letter-spacing: 0;
				@include media($break575) {
					@include title25;
					font-weight: 600;
				}
			}
		}

		.intro_description {
			@include fontsize15;
			color: $dark-text;
		}
	}

	.main_container {
		@include outer-container;
		margin-bottom: 13em;
	}

	.form_container {
		@include span-columns(9);
		background-color: white;
		@include standard-radius;
		@include shift(1);
		@include media($break900) {
			@include shift(0);
			width: 95%;
			margin: 0 auto;
			float: none;
		}
	}

	.support_container {
		@include span-columns(4);
		@include shift(.5);
		@include media($break900) {
			display: none;
		}
	}

	.section_container {}

	.broadcast_header {
		margin-bottom: 2em;
	}

	.brand_avatar {
		width: 35px;
		height: 35px;
		display: inline-block;
		@include circle-radius;
		vertical-align: -10px;
		margin-right: .75em;
	}

	.broadcast_title {
		display: inline-block;
		@include title20;
		color: $dark-text;
		font-weight: 500;
	}

	.body_title {
		@include fontsize15;
		font-weight: 600;
		color: $dark-text;
	}

	.broadcast_body {
		p {
			@include fontsize15;
			margin-bottom: 1em;
			color: $dark-text;
		}
	}

	.first_section_header_radius {
		@include standard-radius-top;
	}

	.section_header {
		background-color: $gray_header_bg;
		overflow: hidden;
		padding: 1.25em 1.75em;
	}

	.section_title {
		color: $dark-text;
		@include fontsize15;
		display: inline-block;
		font-weight: 700;
	}

	.section_body {
		padding: 2em 2em 0;
		margin-right: 2em;
		@include media($break575) {
			margin-right: 0;
		}
	}

	fieldset {
		border-bottom: 1px solid $lightgray-border;
		margin-bottom: 2em;
		padding-bottom: 2em;
	}

	.row {
		margin-bottom: 2em;
	}

	.no_margin_bottom {
		margin-bottom: 0;
	}

	.no_padding_bottom {
		padding-bottom: 0;
	}

	.no_border_bottom {
		border-bottom: none;
	}

	.requirements_label {
		vertical-align: top;
	}

	.label_container {
		width: 25%;
		display: inline-block;
		text-align: right;
		margin-right: 3%;
		@include media($break575) {
			width: 100%;
			display: block;
			text-align: left;
			margin-right: 0;
			margin-bottom: .75em;
		}

		label {
			@include fontsize14;
			color: $dark-text;
		}
	}

	.input_container {
		width: 71%;
		display: inline-block;
		@include media($break415) {
			width: 100%;
		}
        .help_text {
            @include fontsize12;
            margin-left: 0.5em;
            color: $lightgray-text;
            @include media($break575) {
            	margin-left: 0;
            	display: block;
            	margin-top: .5em;
            }
        }
	}

	#creator_requirements_section, #brand_requirements_section, .added_account_fieldset {
		.input_container {
			@include media($break575) {
				width: 100%;
			}
		}
	}

	input[type="text"] {
		@include standard-input;
	}

	.long_input {
		width: 100%;
	}

	.short_input {
		width: 35%;
		@include media($break575) {
			width: 50%;
		}
		@include media($break415) {
			width: 100%;
		}
	}

	.add_account_button {
		margin-right: .5em;
		display: inline-block;
	}

	.cancel_add_account {
		display: inline-block;
	}

	.added_account_fieldset {

		.label_container {
			vertical-align: top;
            margin-top: 10px;
		}

        .add_another_account {
            @include blue-link;
            @include fontsize15;
        }
	}

	.added_account {
		background-color: $system-lightBlue;
		border: 1px solid $revfluence-blue;
		@include standard-radius;
		padding: 1em 1.25em;
		display: block;
		margin-bottom: 1em;
		position: relative;

		.posts_number {
			background-color: $revfluence-blue;
			color: white;
			@include standard-radius;
			display:inline-block;
			margin-right: .5em;
			@include fontsize13;
			padding: .06em .55em;
		}

		.username {
			color: $dark-text;
			@include fontsize15;
			font-weight: 600;
		}

		.network {
			color: $dark-text;
			@include fontsize15;
			margin-right: 1em;
		}

		.edit_account {
			display: inline-block;
			width: 15px;
			height: 15px;
			background: url('../images/agreements/edit_account_icon.svg') center no-repeat;
			background-size: 100%;
			text-indent: -9999px;
			margin-right: 1em;
			vertical-align: 4px;
			position: absolute;
			right: 3em;
			top: 1.45em;
		}

		.delete_account {
			display: inline-block;
			width: 15px;
			height: 15px;
			background: url('../images/agreements/delete_account_icon.svg') center no-repeat;
			background-size: 100%;
			text-indent: -9999px;
			vertical-align: 4px;
			position: absolute;
			right: 1.3em;
			top: 1.45em;
		}
	}

	.creator_username_label {
		vertical-align: 60px;
	}

	.decrease_button {
		display: inline-block;
		margin-right: 1em;
		background: url(../images/agreements/decrease-button.svg) center no-repeat;
		text-align: -9999px;
		width: 20px;
		height: 20px;
		border: none;
		cursor: pointer;
		outline: none;

		&:focus {
			outline: none;
		}
	}

	.occurrence_number {
		display: inline-block;
		@include fontsize15;
		margin-right: 1em;
		color: $dark-text;
		vertical-align: -.35em;
	}

	.increase_button {
		display: inline-block;
		background: url(../images/agreements/increase-button.svg) center no-repeat;
		text-align: -9999px;
		width: 20px;
		height: 20px;
		border: none;
		cursor: pointer;
		outline: none;

		&:focus {
			outline: none;
		}
	}

	.requirement_container {
		border: 1px solid $lightgray-border;
		@include standard-radius;
		padding: 1em;
		margin-bottom: 1.5em;
	}

	.requirement_header {
		overflow: hidden;
	}

	.requirement_title {
		@include fontsize13;
		padding-bottom: .5em;
		display: inline-block;
	}

	.requirement_actions_list {
		float: right;

		li {
			display: inline-block;
			margin-right: 1em;
            @include blue-link;
            @include fontsize13;

			&:last-child {
				margin-right: 0;
			}
		}

		.edit_requirement {
			display: inline-block;
			width: 15px;
			height: 15px;
			background: url('../images/agreements/edit_account_icon.svg') center no-repeat;
			background-size: 100%;
			text-indent: -9999px;
			vertical-align: 4px;
		}

		.delete_requirement {
			display: inline-block;
			width: 15px;
			height: 15px;
			background: url('../images/agreements/delete_account_icon.svg') center no-repeat;
			background-size: 100%;
			text-indent: -9999px;
			vertical-align: 4px;
		}
	}

	.requirement_textarea {
		@include standard-textarea;
		width: 100%;
		height: 75px;
	}

	.added_requirement_text {

	}

	.add_additional_requirement {
		@include blue-link;
		display: block;
		@include fontsize15;
		margin-bottom: 1em;
	}

	.added_requirement_container {
		border: 1px solid $revfluence-blue;
		@include standard-radius;
		background-color: $system-lightBlue;
		padding: 1em;
		margin-bottom: 1.5em;

		.requirement_title {
			font-weight: 500;
		}
	}

	.added_requirement_text {
		color: $dark-text;
		@include fontsize13;
	}

	.hint {
		@include standard-hint;
		list-style-type: circle;
		list-style-position: inside;
	}

	.propose_button {
		text-align: center;
		width: 100%;
	}

    .buttons {
        text-align: center;
        padding: 0 2em 2em;

        a {
        	text-decoration: none;
        }
    }

    .half_propose_button {
        text-align: center;
        width: 45%;
        display: inline-block;
        margin: 0 1em 1em;
        @include media($break574) {
        	width: 100%;
        	display: block;
        	margin: 0;
        }
    }

    .half_cancel_button {
      text-align: center;
      width: 45%;
      display:inline-block;
      margin: 0 1em 1em;
      text-decoration: none;
      @include media($break574) {
	      	width: 100%;
	      	display: block;
	      	margin: 0 0 1em;
      }
    }

}

