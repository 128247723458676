.analyze_view {

  .outreach_header {
    border-bottom: 1px solid $lightgray-border;
    padding: 2rem 0 2.5rem;
    text-align: center;

    @include media($break435) {
      padding: 1rem 0 1.05rem;
    }


    .outreach_title {

      @include media($break1095) {
        font-size: 3.25em;
      }

      @include media($break435) {
        font-size: 2.5em;
      }
    }

    em {
      font-weight: 700;
    }
  }

  .outreach_component {
    padding: 1.25em 1.5em .25em;

    .predictive_score {
      margin-bottom: .75rem;
    }

    .score_txt {}

    .hint_icon {
      background: none;
    }

    .hint_btn {
      background: none;
      padding: 0;
      border: none;
      vertical-align: -4px;
      margin-left: 3px;
    }

    .activate_ad_btn {}

    .fb_ad_icon {
      width: 28px;
      vertical-align: -1px;
      margin-right: 2px;
    }

    .ad_icon {}

    .btn_txt {}
  }

  .request_demo_notice {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid $lightgray-border;
    box-shadow: 1px 1px 10px rgba(0, 0, 0,.15);
    text-align: center;
    background: white;
    padding: .8rem 0 .9rem;
    z-index: 9000;

    .question_txt {
      display: inline;
      margin-right: 1rem;
      font-weight: bold;
      font-size: 1.8em;
      vertical-align: -3px;

      @include media($break720) {
        display: block;
        vertical-align: 0;
        margin-right: 0;
      }

      @include media($break400) {
        font-size: 1.5em;
      }
    }

    .request_demo_btn {
      display: inline;

      @include media($break720) {
        margin-top: .45rem;
      }
    }
  }
}
