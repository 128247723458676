/* Starter HTML

<section class="campaignQuirks_container">
  <ul class="campaignQuirks_list">
    <li class="campaignQuirks_item">
      <div class="quirkNumber_container">1</div>
      <p class="quirkMsg_txt default_body_copy">This campaign only accepts creators in Miami. I confirm I live in Miami.</p>
      <button type="button" class="acceptQuirk_btn btn_primary btn">Accept</button>
      <div class="quirkAccepted_indicator" style="display:none;">
        <img src="/images/style_guide/icon_checkmark_circle_gold.svg" class="icon_accepted">
        Accepted
      </div>
      <li>
  </ul>
</section>

*/

.campaignQuirks_container {
  padding: 0 1rem 1rem;

  .campaignQuirks_list {
    margin-top: 1rem;
  }

  .campaignQuirks_item {
    padding-left: 2rem;
    position: relative;
    padding-bottom: .6rem;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .campaignQuirks_title {
    padding-bottom: .5rem;
  }

  .quirkNumber_container {
    background-color: lightgray;
    color: white;
    text-align: center;
    font-size: .8rem;
    font-weight: 600;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    position: absolute;
    left: 0;
    padding-top: 4px;
    top: 0;
  }

  .quirkMsg_txt {
    padding-bottom: .4rem;
  }

  .acceptQuirk_btn {}

  .icon_accepted {
    width: 16px;
    vertical-align: -3px;
    margin-right: 1px;
  }

  .quirkAccepted_indicator {
    font-weight: 600;
    font-size: 1.25em;
  }

  .icon_accepted {}
}
