.payment_tool {

	.paymentTool_container {
		padding: 24px 27px 28px;
		border-radius: 6px;
		border: 1px solid $lightgray-border;
		margin-bottom: 2em;
		margin-top: .5rem;

		@include media($break630) {
			@include panel;
		}

		.paymentTool_title {
			font-size: 2em;
			margin-bottom: 1em;
			font-weight: 300;
		}
	}

	.send_payment_container {

		.send_payment_title {
			font-size: 2em;
			margin-bottom: 1em;

			@include media($break630) {
				line-height: 1.4em;
			}
		}

		.paymentBreakdown_container {
			width: 229px;
			overflow: hidden;
			margin-bottom: 1em;

			@include media($break350) {
				width: 100%;
			}
		}

		.paymentRequest_container {
			margin-bottom: 1em;
		}

		.breakdown_row {
			padding: .25em 0;
		}

		.amountAgreed_row {}

		.amountPending_row {}

		.totalSent_row {
			border-bottom: 1px solid $lightgray-border;
			padding: .25em 0 .75em;
		}

		.amountOwed_row {
			padding: .5em 0 .25em;
		}

		.breakdown_label {}

		.amountOwed_label {
			font-weight: 600;
		}

		.breakdown_amount {
			float: right;
		}

		.paymentActions_container {}

        .awaiting_payment_text {
            position: relative;
            top: 3px;
        }
		.customPayment_btn {
			font-size: 1em;
			padding: 0;
			vertical-align: initial;

			&:focus, &:active {
				box-shadow: none;
			}
		}

		.brand_actions {

			.sendPayment_btn {}

			.standard_sendPayment_container {

				.custom_payment {
					margin-left: .8em;

					@include media($break415) {
						display: block;
						margin: 1.25em 0 0 0;
					}
				}
			}

			.customPayment_container {}

			.amount_label {}

			.inputs_container {}

			.amount_input {
				width: 150px;
				display: inline-block;
				margin-right: .5em;
			}

			.send_btn {
				display: inline-block;
				margin-right: .5em;
			}

			.cancel_btn {
				display: inline-block;
			}

			.pendingRequest_container {

				.custom_payment {
					display: block;
					margin: .75em 0 0 0;
				}
			}

		}

		.creator_actions {

			.requestPayment_container {

				.requestPayment_btn {}

				.requested_indicator {}
			}
		}

		.send_btn {}

		.dispute_btn {}

		.dispute_container {}

		.dispute_label {}

		.dispute_descrip {}

		.disputeReason_input {
			margin: 1.0em 0;
		}

		.actions_container {}

		.disputing_container {}

		.disputing_title {}

		.disputing_descrip {}

		.revTip {
			font-size: 1.25em;
			margin-top: 1.25em;
		}
	}

	.enterPaypal_container {

		.main_title {}

		.main_descrip {}

		.paypalAccount_input {
			width: 200px;
			margin-bottom: 1em;
		}

		.save_btn {}
	}

	.paymentHistory_container {

		.paymentHistory_title {}

		.noHistory_disclaimer {}

		table {
			width: 600px;
		}

		thead {}

		tr {
			border-bottom: 1px solid $lightgray-border;

			&:last-child {
				border-bottom: none;
			}
		}

		th {
			font-weight: 600;
			text-align: left;
			padding: 10px;
		}

		td {
			padding: 10px;
		}
	}
}
