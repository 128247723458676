.creator_profile {

  .location_container {
    margin-bottom: 1.7rem;

    .map_container {}

    .map_img {
      display: block;
      float: left;
      width: 90px;
      margin-right: 1rem;
      border-radius: 12px;
      border: 1px solid white;
      box-shadow: 1px 1px 4px rgba(0,0,0,.15);

      @include media($break875) {
        width: 120px;
      }
    }

    .txt_container {
      width: calc(100% - 136px);
      float: left;
      padding-top: 1.55rem;

      @include media($break875) {
        padding-top: 2.75rem;
      }
    }

    .location_txt {
      line-height: 1.5em;
      font-size: 1.5em;
    }

    .edit_btn {
      margin-left: .5rem;
      display: inline-block;
    }

    &:hover {

      .edit_btn {
        display: inline-block;
      }
    }
  }
}

.pac-container{
  z-index:9999 !important;
}
