.server_down {

	.server_down_header {

		.inner_container {
			max-width: 1400px;
			margin: 0 auto;
			overflow: hidden;
			padding: 11px 20px 0;

			@include media($break680) {
				padding: 20px 20px 0;
				margin: 0;
			}
		}

		.logo_container {
			width: 223px;
			height: 50px;
			display: block;
			float: left;

			@include media($break680) {
				width: 171px;
				float: none;
				margin: 0 auto;
				height: 38px;
			}
		}

		.revfluence_logo {
			background: url('/images/themes/aspireiq/aiq_logo.png') center no-repeat;
			text-indent: -9999px;
			background-size: 100%;
			width: 173px;
			height: 37px;

			@include media($break680) {
				background: url('/images/themes/aspireiq/aiq_logo.png') center no-repeat;
				background-size: 100%;
				width: 173px;
				height: 37px;
			}
		}

		.logo_link {
			display: block;
		}

		.header_links_container {
			width: 359px;
			float: right;

			@include media($break680) {
				display: none;
			}
		}

		.header_links_list {
			margin-top: 9px;
		}

		.header_links_item {
			display: inline-block;
			margin-right: 20px;
		}

		.header_link {
			display: inline-block;
			text-decoration: none;
		}

		.twitter_link {
			width: 20px;
			height: 17px;
			background: url('/images/server_down/twitter_icon.svg') center no-repeat;
			text-indent: -9999px;
			background-size: 100%;
		}

		.facebook_link {
			width: 17px;
			height: 17px;
			background: url('/images/server_down/instagram_icon.svg') center no-repeat;
			text-indent: -9999px;
			background-size: 100%;
		}

		.need_help_item {
			vertical-align: 3px;
			font-size: .95em;
			text-align: right;

			&:last-child {
				margin-right: 0;
			}
		}

		.help_label {}

		.help_link {
			color: #989898;
			text-decoration: none;		
		}
	}

	.main_container {
		max-width: 815px;
		margin: 120px auto 135px;
		overflow: hidden;

		@include media($break815) {
			margin: 25px auto 40px;
		}

		.text_container {
			float: left;
			width: 495px;
			margin: 31px 56px 0 0;

			@include media($break815) {
				float: none;
				width: 100%;
				text-align: center;
				padding: 0 5%;
				margin: 0 0 35px 0;
			}
		}

		.server_down_title {
			margin: 0 0 20px 0;
			line-height: 1.3em;

			@include media($break815) {
				font-size: 4em;
			}

			@include media($break450) {
				font-size: 3em;
			}
		}

		.title_line_break {
			display: block;
		}

		.server_down_descrip {}

		strong {
			font-weight: 600;
			display: block;
		}

		.server_down_visual_container {
			width: 260px;
			float: right;

			@include media($break815) {
				width: 280px;
				float: none;
				margin: 0 auto;
			}
		}

		.server_down_visual {
			display: block;
			max-width: 100%;
		}
	}

	.server_down_footers {
		text-align: center;

		.footer_links_container {
			max-width: 1400px;
			margin: 0 auto;
			overflow: hidden;
			padding: 0 0 25px;
		}

		.footer_links_list {

		}

		.footer_list_item {
			display: inline-block;
			margin-right: 45px;
		}

		.copyright_item {
			color: #B5B5B5;
		}

		.footer_link_item {
			color: $dark-text;
			text-decoration: none;
		}

		.footer_link_item {}
	}
}
