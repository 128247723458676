.campaign_filters {
	background: #fbfbfb;

	.main_container {
		background-color: white;
		box-shadow: 2px 2px 13px rgba(0,0,0,.05);
		max-width: 765px;
		margin: 8em auto 0;
	}

	.main_header {
		padding: 3em;

		.main_title {
			font-size: 3em;
			color: $dark-text;
			font-weight: 300;
			text-align: center;
			line-height: 1.5em;
		}
	}

	.campaign_filters_container {
		margin: 0 2em;

		.instagram_container {
			margin-right: 3.46%;

			@include media($break575) {
				margin-right: 0;
				margin-bottom: 2em;
			}
		}

		.network_row_container {
			overflow: hidden;
			margin-bottom: 25px;
		}

		.section_title {
			  display: block;
			  margin-bottom: .5em;
			  font-size: 1.5em;
			  line-height: 1.45em;
			  color: $dark-text;
		}

		.section_container {
			padding: 5% 6%;
		}

		.network_container {
			width: 48.27%;
			float: left;
			border: 1px solid #f2f2f2;

			@include media($break575) {
				width: 100%;
			}

			.network_header {
				overflow: hidden;
				padding: 5% 6%;
				border-bottom: 1px solid #f2f2f2;
			}

			.network_title_container {
				width: calc(100% - 94px);
				float: left;
			}

			.network_title {
				font-size: 2em;
				font-weight: 300;
				color: $dark-text;
				line-height: 1.5em;
			}



			.toggle_container {
				width: 94px;
				float: right;

				.toggle_button {
					-webkit-appearance: none;
					box-shadow: 0.5px 0.866px 5px 0px rgba(1, 1, 1, 0.06);
					border: 1px solid #c3c3c3;
					border-radius: 4px;
					background-color: white;
					cursor: pointer;
					padding: 8px 11px;
					outline: none;
					font-size: 1.1em;
					line-height: 1.5em;
					color: $dark-text;

					&:active {
						background-color: $revfluence-blue;
						color: white;
						border: 1px solid $revfluence-blue;
					}
				}

				.active_button {
					background-color: $revfluence-blue;
					color: white;
					border: 1px solid $revfluence-blue;
				}

				.on_button {}

				.off_button {}
			}
		}

		.youtube_container {}

		.countries_container, .language_container {
			padding: 4% 3.5%;
			margin: 0 auto 35px;
			border: 1px solid #f2f2f2;

			.country_title_container {
				margin-bottom: 1em;
				overflow: hidden;

				.section_title {
					display: inline-block;
					margin-right: .25em;
					margin-bottom: .85em;
					font-size: 1.5em;
					line-height: 1.45em;
					color: $dark-text;
				}

				.remove_countries_container {
					display: inline-block;
					float: right;

					.remove_countries_link {
						@include blue-link;
						display: inline-block;
						@include fontsize12;
					}
				}
			}

			.selected_countries_container {
				margin-bottom: 1em;

				.selected_countries_list {
					@include non_colored_labels;
					display: inline-block;
				}

				.showing_all_indication {
					color: $lightgray-text;
					@include fontsize12;
					display: none;
				}
			}

			.country_input_container {
				position: relative;
			}

			.country_input {
				padding: .75em;
				border: 1px solid #e8e8e8;
				color: $dark-text;
				line-height: 1.45em;
				font-weight: 300;
				background-color: white;
				font-size: 1.5em;
				width: 100%;
				outline: none;
				border-radius: 3px;

				&:focus {
					box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem $revfluence-blue;
				}
			}

			.suggestions_container {
	            		display: none;
				position: absolute;
				top: 47px;
				left: 0;
				border: 1px solid $lightgray-border;
				z-index: $zindexTutorial;
				background-color: white;
				width: 100%;
				height: 175px;
				overflow-y: auto;
				@include standard-radius-bottom;
			}

			.suggestions_list {
				padding: 1em 0;

				li {
					@include fontsize14;
					color: $dark-text;
					padding: .5em 1.5em;
					cursor: pointer;

					&:hover {
						background-color: $revfluence_blue;
						color: white;
					}
				}
			}
		}

		.actions_container {
			padding-bottom: 4em;

			.save_filters_button {
				background-color: $revfluence-blue;
				color: white;
				font-size: 1.5em;
				font-weight: 500;
				line-height: 1.45em;
				text-align: center;
				padding: .5em;
				width: 150px;
				margin: 0 auto;
				cursor: pointer;
				display: block;
				border: none;
				border-radius: 2em;
				outline: none;
				-webkit-appearance: none;

				&:hover {
					background-color: $revfluence-blue-hover;
				}

				&:focus {
					box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem $revfluence-blue;
				}
			}
		}
	}

	.slider_container {
		margin-bottom: 14px;

		.ui-widget-content {
			border: none;
			background: #efefef;
		}

		.ui-slider-handle {
			background: white;
			border: 1px solid #c3c3c3;
			width: 20px;
			height: 20px;
			top: -.6em;
			outline: none;
			border-radius: 4px;
			cursor: move;
			z-index: 1;
		}

		.ui-slider-range {
			background: $revfluence-blue;
		}

		.ui-slider-horizontal {
			height: .5em;
			width: 94%;
			margin-left: 4px;
		}
	}

	.slider_range {}

	.slider_description_container {
	}

	.title {}

	.slider_description {
		font-size: 1.1em;
		color: $dark-text;
		line-height: 1.5em;
	}
}