.creatorList_header {
  padding-bottom: .75rem;

    .creatorList_toolbar {
      float: right;

      .sort_dropdown_btn {
        margin-bottom: 0;
        font-weight: 400;
        padding: 8px 31px 8px 14px !important;
      }

      .creatorList_actions_wrapper, .listView_toggle {
        float: left;
        margin-right: 1.25rem;

        @include media($break665) {
          float: none;
        }
      }

      .sort_dropdown_btn {
        background: #fff url("/images/style_guide/dropdown_indicator_icon.svg") 90% center no-repeat;
        background-size: 18%;

        &:hover {
          background: $ultraLight_gray url("/images/style_guide/dropdown_indicator_icon.svg") 90% center no-repeat;
          background-size: 18%;
        }

        .icon_sort, .btn_txt {
          display: inline-block;
        }

        .btn_txt {
          font-weight: 400;
        }
      }

      .listView_toggle {
        margin-right: 0;
      }

      .creatorList_action_item {
        display: inline-block;
        margin-right: .5rem;
        position: relative;

        @include media($break665) {
          display: block !important;
          margin-bottom: .5rem;
          margin-right: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        .btn {
          padding: 8px 14px;
          border-radius: 4px;
          font-size: .8rem;
        }

        .btn_invite, .btn_remove {
          position: relative;
        }

        .btn_sendOffer {

          .icon_sendoffer {
            width: 17px;
            vertical-align: -3px;
            margin-right: .2rem;
          }
        }

        .btn_export {
          padding: 8px 14px 8px 33px;
          background: $aiq_lightblue_bg url('/images/style_guide/icon_export_dark.svg') 12px center no-repeat;

          &:hover, &:focus {
            background: $aiq_lightblue_bg_hover url('/images/style_guide/icon_export_dark.svg') 12px center no-repeat;
          }

          &:active {
            background: $aiq_lightblue_bg url('/images/style_guide/icon_export_dark.svg') 12px center no-repeat;
          }
        }

        .disabled_btn_blocker {
          display: none;
          position: absolute;
          top: 0;
          height: 100%;
          width: 100%;
          background-color: white;
          background-color: rgba(255,255,255,.5);
          z-index: 2;
        }
      }

      .disabled_action_item {

        .disabled_btn_blocker {
          display: block;
        }
      }

      .sort_dropdown_container {

        .dropdown_list {
          right: -8px;
        }

        .sortActive_icon {
          margin-right: .15rem;
          display: none;
          width: 14px;
        }

        .sortActive_icon_hover {
          display: none;
        }

        .sortActive_item {

          a {
            font-weight: 600;
          }

          .sortActive_icon {
            display: inline-block;
          }
        }
      }

      .copyto_dropdown_container {

        .copyto_dropdown_list {
          top: 39px;
          width: 274px;

          &:before, &:after {
            left: 162px;
          }
        }
      }

      .btn_copy, .btn_remove {
        color: $dark-text;
      }

      .active_icon {
        display: none;
      }

      .active_filter_item {

        .favOptions_icon {
          display: none;
        }

        .active_icon {
          display: block;
        }
      }

      .favAction_icon {
        width: 13px;
        margin-right: .25rem;
        vertical-align: 0;
      }

      .icon_invite, .icon_duplicate {
        width: 18px;
      }

      .icon_invite {
        margin: 0 .35rem 0 0 !important;
      }

      .icon_duplicate {
        vertical-align: -3px;
        width: 17px;
        margin-right: .35rem;
      }

      .icon_sort {
        width: 16px;
        margin-right: .15rem;
      }

      .icon_remove {
        width: 12px !important;
        margin: 0 .35rem 0 0 !important;
        vertical-align: -1px;
      }

      .listView_toggle {

        .toggle_filter_button {
          height: 37px;
        }
      }

      .gridView_icon {}

      .listView_icon {}

      .galleryView_icon {}

      .toggle_filter_button {
        padding: .95em 1.15em .95em;

        .favOptions_icon {
          width: 14px;
        }
      }
    }

    .favSelect_wrapper, .creatorList_toolbar {

      @include media($break965) {
        float: none;
      }
    }
}

.creatorList_header_padding {
  padding-bottom: 2rem;
}
