.public_library_nav {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9000;
	transition: all .15s ease-in-out;
	background-color: rgba(255,255,255,.97);
	padding: .5rem 0;
	border-bottom: 1px solid $lightgray-border;

	.welcome_nav_inner {
		max-width: 1400px;
		margin: 0 auto;

		@include media($break1400) {
			padding: 0 1rem;
		}
	}

  .revfluence_logo {
    width: 160px;
    transition: all .25s ease-in-out;

    &:hover {
      opacity: .7;
    }
  }
}
