.loading_demo_noSelection {
	background-color: #fafafa;
	width: 100%;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 9999;

	.message_container {
		background-color: white;
		box-shadow: 0px 1px 2px 0px rgba(1, 1, 1, 0.09);
		padding: 4em 0;
		@include media($break600) {
			padding: 4em 1.25em;
		}

		.main_title {
			color: $dark-text;
			font-size: 2em;
			line-height: 1.5em;
			margin-bottom: .5em;
			font-weight: 600;
			text-align: center;
		}

		.main_descrip {
			color: $dark-text;
			font-size: 1.6em;
			line-height: 1.5em;
			font-weight: 300;
			text-align: center;
		}
	}

	.loading_indicator_container {
		width: 750px;
		margin: 10em auto 0;
		position: relative;

		@include media($break750) {
			width: 100%;
		}
	}

	.loading_indicator {
		position: absolute;
		left: 50%;
		top: -23px;
		width: 100px;
		height: 100px;
		z-index: 1;
		border-radius: 100px;
		background-color: white;
		border: 2px solid lightgray;
		margin-left: -50px;
	}

	.revfluence_logomark {
		width: 50px;
		height: 50px;
		background: url('/images/loading/revfluence_logomark.svg') center no-repeat;
		margin: 2em auto 0;
		display: block
	}

	.wrapper {
	  width: 100px; /* Set the size of the progress bar */
	  height: 100px;
	  position: absolute; /* Enable clipping */
	  clip: rect(0px, 100px, 100px, 50px); /* Hide half of the progress bar */
	  left: 50%;
	  margin-left: -50px;
	  top: -2px;
	}
	/* Set the sizes of the elements that make up the progress bar */
	.circle {
	  width: 100px;
	  height: 100px;
	  border: 2px solid $revfluence-blue;
	  border-radius: 100px;
	  position: absolute;
	  clip: rect(0px, 50px, 100px, 0px);
	}

	/* Using the data attributes for the animation selectors. */
	/* Base settings for all animated elements */
	div[data-anim~=base] {
	  -webkit-animation-iteration-count: 1;  /* Only run once */
	  -webkit-animation-fill-mode: forwards; /* Hold the last keyframe */
	  -webkit-animation-timing-function:linear; /* Linear animation */
	}

	.wrapper[data-anim~=wrapper] {
	  -webkit-animation-duration: 0.01s; /* Complete keyframes asap */
	  -webkit-animation-delay: 2s; /* Wait half of the animation */
	  -webkit-animation-name: close-wrapper; /* Keyframes name */

	}

	.circle[data-anim~=left] {
	  -webkit-animation-duration: 4s; /* Full animation time */
	  -webkit-animation-name: left-spin;
	}

	.circle[data-anim~=right] {
	  -webkit-animation-duration: 2s; /* Half animation time */
	  -webkit-animation-name: right-spin;
	}
}

/* Rotate the right side of the progress bar from 0 to 180 degrees */
@-webkit-keyframes right-spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(180deg);
  }
}
/* Rotate the left side of the progress bar from 0 to 360 degrees */
@-webkit-keyframes left-spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
/* Set the wrapper clip to auto, effectively removing the clip */
@-webkit-keyframes close-wrapper {
  to {
    clip: rect(auto, auto, auto, auto);
  }
}