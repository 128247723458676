.analyze_view {

  .contentUsage {

    .filterHeader {
      padding: 0 4rem .75rem 2.25rem;
      min-width: 1200px;

      .contentUsage_descrip {
        font-weight: 600;
        float: left;
        width: calc(100% - 234px);
        padding-top: .5rem;
      }

      .sortFilter_wrap {
        float: right;

        .dropdown_btn2 {
          margin-bottom: 0;
        }
      }

      .sort_wrap, .filterChannel_wrap {
        display: inline-block;
      }

      .sort_wrap {
        margin-right: .5rem;
      }
    }
  }
}
