.browse_creators {
	background-color: white;
	height: auto;

	.filters_container {
		overflow-x: visible;
		height: 100%;
	}

	.main_container {
		margin: 150px auto 0;
		position: relative;
		padding: 0 1rem;
		height: 100%;
		overflow: visible;

		@include media($break1400) {
			padding: 0 1em;
		}
	}

	.creator_table {
		margin-top: 1rem;
		overflow: visible;

		td, th {
			width: 150px;
		}

		.contentPreview_cell {
			min-width: 140px;
		}

		.addPrice_btn {
			font-size: .9rem;
			border: none;
			padding: 0;
			margin-bottom: .25rem;
		}

		.inputWrapper {

			.form_control {
				display:inline-block;
				font-size: .85rem;
				width: 90px;
				padding: .4em .7em .4em 1.35em;
				background: url('/images/style_guide/icon_payment_black.svg') 8px center no-repeat;
				background-size: 7px;
			}
		}

		.enteredWrapper {

			.enteredNumber {
				margin-right: .17rem;
			}

			.icon_loading_spinner, .icon_success {
				vertical-align: -4px;
			}
		}
	}

	.searchPaging_section {
		max-width: 850px;
		margin: 0 auto;
		padding: 2rem 0 3rem;
		text-align: center;
		-webkit-transition: all ease 800ms;
		transition: all ease 800ms;
		opacity: 1.0;

		&.ng-enter {
			opacity: 0;
		}

		@include media($break585) {
			width: 322px;
			max-width: auto;
			padding: 2rem 0;
		}

		@include media($break335) {
			width: 270px;
		}

		.current_page_title {
			display: block;
			text-align: center;
			padding-bottom: 1rem;
		}

		.searchPaging_list {}

		.searchPaging_item {
			display: inline-block;
			margin-right: .5rem;
			margin-bottom: 1.0rem;

			&:last-child {
				margin-right: 0;
			}
		}

		.searchPaging_btn {
			border-radius: 6px;
			color: $dark-text;
			padding: .3rem .8rem;
			width: 60px;

			@include media($break335) {
				padding: .3rem .57rem;
			}
		}

		.active_searchPaging_btn {
			background-color: $aiq_darkblue;
			border: 1px solid $aiq_darkblue;
			color: white;
			font-weight: 600;

			&:hover, &:focus, &:active {
				color: white;
				background-color: $aiq_darkblue;
				border: 1px solid $aiq_darkblue;
			}
		}

		.icon_pageLeft, .icon_pageRight {
			width: 11px;
			height: 22px;
		}

		.searchPaging_mobile {

			@include media($break585) {
				display: none;
			}
		}
	}

	.creator_list_container {
		width: calc(100% - 348px);
		float: left;
		position: relative;
		height: 100%;
		overflow-x: visible;

		::-webkit-scrollbar {
			width:  6px;

			&:horizontal {
				height: 6px;
			}
		}

		::-webkit-scrollbar-thumb {
			background: #D8D8D8;
			border-radius: 100px;
		}

		::-webkit-scrollbar-track {
			background: none;
		}

		.creatorList_header {
			padding-bottom: .75rem;
			min-height: 50px;

			@include media($break930) {
				display: none;
			}
		}

		@include media($break865) {
			width: 100%;
			float: none;
			margin-top: 0;
			padding-left: 0;
			border-left: none;
		}

		.search_creators_header {
			position: absolute;
			font-size: 1.25rem;
			top: 3px;
			left: 9px;
			width: calc(100% - 615px);

			@include media($break1200) {
				display: none;
			}

			strong {
				font-weight: 600;
			}
		}

		.creator_list {
			overflow: visible;

			.previewContent_container {

				@include media($break1060) {
					bottom: -2%;
				}

				@include media($break615) {
					bottom: -4%;
				}
			}

			.video_content_container {

				@include media($break1060) {
					bottom: -2%;
				}

				@include media($break615) {
					bottom: -4%;
				}
			}
		}

		.no_results_container,.search_error_container {
			text-align: center;
			margin: 150px 0;
			
			@include media($break850) {
				margin: 200px 0;
			}
			@include media($break515) {
				margin: 55px0;
			}

			.main_title {
				font-size: 3em;
				color: $dark-text;
				font-weight: 300;
				margin-bottom: .5em;
			}

			.main_descrip {
				font-size: 1.5em;
				color: $dark-text;
				font-weight: 300;
				line-height: 1.5em;
			}

			a {
				color: $aspireiq_persian_blue;
				text-decoration: none;
			}
		}

		.creator_item {
			width: 31.99%;
			float: left;
			background-color: white;
			margin-right: 1.34%;
			margin-bottom: 14px;
			border-radius: 6px;
			border: 1px solid $lightgray-border;

			@include media($break1200) {
				width: 31.71%;
				margin-right: 1.62%;
			}
			@include media($break975) {
				width: 48.38%;
			}
			@include media($break850) {
				width: 31.715%;
			}
			@include media($break600) {
				width: 48.38%;
			}
			@include media($break390) {
				width: 100%;
			}

			.networkIcon {
				width: 15px;
			}

			.instagram_icon {}

			.youtube_icon {
				width: 16px;
				vertical-align: 1px;
			}

			.snapchat_icon {}

			.vine_icon {
				width: 14px;
			}

			.twitter_icon {
				width: 17px;
			}

			.facebook_icon {}

			.blog_icon {
				width: 16px;
			}

			.pinterest_icon {
				width: 14px;
				vertical-align: -2px;
			}

			.gplus_icon {
				width: 22px;
			}
		}

		.topControls_container, .previewContent_container {

			@include media($break600) {
				display: none;
			}
		}

		.detail_view_version {
			display: none;
		}

		.hover_creator_content {
			visibility: hidden;;
			position: absolute;
			z-index: 9000;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			bottom: 0;
			z-index: 1000;
			background-color: white;
			opacity: 0;
			transition: all .19s ease-in-out;
			padding: 8px;
			display: block;

			.main_thumbnail_container {
				height: 50%;
				margin-bottom: 2.46%;
			}

			.row {
				height: 50%;
			}

			.main_thumbnail {}

			.small_thumb_container {
				float: left;
				height: 100%;
				width: 48.77%;

				&:first-child {
					margin-right: 2.46%;
				}
			}

			.small_thumb {}

			.main_thumbnail, .small_thumb {
				max-width: 100%;
			}

			.main_thumbnail_container, .small_thumb_container {
				overflow: hidden;
			}
		}

		.creator_avatar_container {
			overflow: hidden;
			border-radius: 6px 6px 0 0;
			position: relative;


			a {

				.creator_avatar {
					opacity: 1;
				}

				&:hover {
					.creator_avatar {
						opacity: .9;
					}
				}
			}
		}

		.yt_avatar_container {
			height: 210px;

			.creator_avatar {
				width: 100%;

				@include media($break720) {
					width: 200%;
				}

				@include media($break600) {
					width: 164%;
				}

				@include media($break600) {
					width: 184%;
				}

				@include media($break320) {
					width: 126%;
				}
			}
		}

		.ig_avatar_container {

			.creator_avatar {
				max-width: 100%;
				width: 100%;
			}

			&:hover {

				.hover_creator_content {
					opacity: 1;
					visibility: visible;
				}
			}
		}

		.blog_avatar_container {
			height: 200px;

			.creator_avatar {
				max-width: 100%;
				width: 100%;
			}
		}

		.creator_avatar {
			/* max-width: 100%;
			width: 100%;  */
			border-radius: 3px 3px 0 0;
		}

		.ig_avatar {
			width: 100%;

			@include media($break1245) {}
		}

		.yt_avatar {

		}

		.creator_info_container {
			padding: 20px 0 0 0;
		}

		.username_link {
			color: $dark-text;
			text-decoration: none;
		}

		.creator_username {
			font-size: 2em;
			font-weight: 300;
			color: $dark-text;
			line-height: 1.5em;
			margin-bottom: .5em;
			text-align: center;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			padding: 0 .5em;
		}

		.details_container {
			text-align: center;
			border-bottom: 1px solid #f2f2f2;
			padding-bottom: 0;
		}

		.engagement_amount, .follower_amount {
			display: block;
		}

		.availNetworks_container {
			padding: 0 1.25rem;
		}

		.availNetworks_list {
			text-align: center;
			overflow: hidden;
			height: 17px;

			.availNetwork_item {
				display: inline-block;
				margin-right: .5rem;

				&:last-child {
					margin-right: 0;
				}
			}
		}

		.ellipses_icon {
			float: left;
			padding-top: .8rem;
		}

		.has_networkOverflow {
			width: 75%;
			margin: 0 auto;

			.availNetworks_list {
				float: left;
				width: calc(100% - 11px);
				overflow: hidden;
				text-align: left;
				height: 17px;
			}

			.ellipses_icon {
				float: left;
			}
		}

		.availNetworks_container {}

		.ellipses_icon {}

		.admin_link {
			display: block;
			margin-top: 1em;
			font-size: 1.25em;
			text-decoration: none;
			color: $revfluence-blue;
			padding-bottom: 1rem;

			&:hover {
				color: $revfluence-blue-hover;
			}
		}

		.engagement_amount {
			font-size: 1.3em;
			color: $dark-text;
			font-weight: 300;
			line-height: 1.5em;
		}

		.follower_amount {
			font-size: 1.3em;
			color: $dark-text;
			font-weight: 300;
			line-height: 1.5em;
			display: block;
			margin-bottom: 1rem;
		}

		.creator_actions_container {
			text-align: center;
			height: 52px;
		}

	  	.invite_in_progress{
		  height:15px;
		}

		.btn_icon {
			display: inline-block;
			vertical-align: -3px;
			margin-right: .2rem;
			width: 19px;
			padding: 0;
		}

		.actions_link {
			display: block;
			padding: 14px;
			text-decoration: none;
			color: $revfluence-blue;
			cursor: pointer;
			float: left;
			width: 50%;
			border-radius: 0;
			transition: all .15s ease-in-out;

			&.invited_link {
				cursor: default;

				&:hover {
					background-color: white;
				}
			}

			&:last-child {
				border-right: none;
			}

			&:hover {
				background-color: $ultra_light_blue;
			}

			&:active, &:focus {
				box-shadow: none;
				background-color: white;
			}

			&.full_width_actions_link {
				width: 100%;
			}
		}

		.like_action_link {
			border-right: 1px solid $lightgray-border;
		}

		.btn_txt {
			font-weight: 300;
		}

		.actionable_btn {
			color: $revfluence-blue;
			cursor: pointer;
		}

		.actions_icon {
			display: inline-block;
			width: 20px;
			height: 20px;
			background: url('/images/browse_creators/like_icon_outline.svg') center no-repeat;
			margin-right: 3px;
			vertical-align: -4px;
		}

		.actions_text {
			display: inline-block;
			font-size: 1.3em;
			font-weight: 300;
			line-height: 1.5em;
		}

		.invited_link {
			.btn_txt {
				color: #9d9d9d;
			}
		}

		.main_loading_section {
			margin: 30px 0;

			.spinner-image {
				margin: 0 auto;
			}
		}
	}

	.inactive_creator_list_container {
		@include media($break850) {
			display: none;
		}
	}
}

.has_demo {

	.start_campaign_banner {
		margin-top: 104px;

		@include media($break850) {
			margin-top: 148px;
		}

		@include media($break355) {
			margin-top: 144px;
		}
	}

	.creator_list_container {

		@include media($break850) {
			margin-top: 13px;
		}

		@include media($break640) {
			margin-top: 7px;
		}
	}

	.main_container {
		margin: 15px auto 0;
	}

	.small_filters_container {
		margin: 190px auto 0;
	}

	.create_account_container {
		position: relative;
		text-align: center;
		float: left;
		width: 100%;
		margin-top: -29em;
		z-index: 10;
		height: 300px;
		display: none;
		background: url('/images/demo_product/create_account_gradient.png') repeat-x;
		background-position: left center;

		.inner_container {
			max-width: 750px;
			padding: 3em 0;
			margin-top: 20em;

            .get_started_button {
                background-color: $revfluence-blue;
                border-radius: 45px;
                width: 160px;
                color: white;
                display: block;
                text-decoration: none;
                text-align: center;
                padding: .75em 0;
                font-size: 1.5em;
                margin-bottom: 2em;
                margin-top: 1.0em;
                margin-left: auto;
                margin-right: auto;
                &:hover {
                    background-color: $revfluence-blue-hover;
                }
            }
		}

		.main_title {
			font-size: 2em;
			color: $dark-text;
			font-weight: 200;
			line-height: 1.5em;
			margin-bottom: .5em;

			strong {
				font-weight: 600;
			}
		}

		.create_account_link {
			color: $revfluence-blue;
			display: block;
			font-size: 2em;
			font-weight: 500;
			line-height: 1.5em;
			text-decoration: none;

			&:hover {
				color: $revfluence-blue-hover;
			}
		}
	}
}
