.product_shipment_tool {

	/* Tools Header */
	.tools_header {

		.tool_name_container {}

		.tool_icon_container {}

		.tool_icon {}

		.tool_name {}
	}

	.editing_choices_container {
		margin-bottom: 1rem;
	}

	.creator_address_container {
		margin-bottom: 1rem;

		.deletedComposeWrap {
			font-weight: 600;
			position: relative;
			z-index: 1;
			width: 100%;
			height: auto;
			top: auto;
			right: auto;
			bottom: auto;
			left: auto;
			padding: 1rem;
			background: #F4F4F4;
			margin-bottom: 0;
			border-radius: 6px;

			.deletedHeader_wrap {
				padding-bottom: .5rem;
			}

				.icon_deleted {
					width: 20px;
				}
		}
	}

	/* Saved Info Title */
	.saved_info_header {

		.standard_label {
			font-weight: 700;
			float: left;
			margin-right: .35rem;
		}

		.edit_btn {
			background: none;
			float: left;
			padding: 0;
			border: none;
			margin: 0;

			&:active, &:focus {
				box-shadow: none;
			}
		}
	}

	/* Brand - Add Product Options */
	.add_options_form {
		margin-bottom: 1.15rem;

		.options_input, .choices_input {
			margin-bottom: .75rem;
		}

		.save_btn {}
	}

	/* AGREED TERMS INTRO */
	.agreedTerms_intro {
		margin-bottom: .5rem;
		width: 97%;
		margin-top: .25rem;

		@include media($break800) {
			width: 100%;
		}

		.agreedTerms_title {
			font-weight: 500;
			padding-bottom: 1px;
		}

		p {
			margin-bottom: 0;
		}

		.icon_checkmark {
			vertical-align: -2px;
			margin-right: 2px;
		}

		.agreedTerms_descrip {}
	}

	/* SAVED PRODUCT OPTIONS */
	.instrucTo_receive_product {
		width: 97%;

		@include media($break800) {
			width: 100%;
		}

		.instrucTo_title {
			display: inline-block;
			vertical-align: 3px;
		}

		.instructions_text {
			white-space: pre-line;
		}

		p {
			padding: .35rem 0;
		}
	}

	/* NO BRAND INSTRUCTIONS AVAILABLE */
	.noInstruc_container {
		width: 97%;
		margin-bottom: .75rem;

		@include media($break800) {
			width: 100%;
		}

		.icon_warning {
			display: inline-block;
		}

		.noInstruc_title {
			display: block;
			font-weight: 700;
			padding-bottom: .25rem;
		}

		.strong {
			font-weight: 700;
		}
	}

	/* Brand - Saved Product Options */
	.saved_product_options {
		width: 97%;
		margin-bottom: .75rem;

		.main_title {
			font-weight: 700;
			margin-bottom: .25rem;
		}

		.no_options_disclaimer {

		}
	}


	/* Creator - Add Product Choices */
	.add_choices {

		.your_choices_fieldset {
			margin-bottom: .5rem;
		}

		.choices_input {}
	}


	/* Creator - Save Product Choices */
	.saved_creator_choices {

		.saved_msg {
			margin-bottom: .85rem;
		}
	}


	/* Creator - Add Address */
	.add_address_form {
		margin-bottom: 1rem;

		.intro_txt {
			font-weight: 600;
			margin-bottom: .5rem;
		}

		.name_row {
			margin-bottom: .5rem;
		}

		.first_name_container {
			margin-right: 2.5%;

			@include media($break400) {
				margin-right: 0;
				margin-bottom: .5rem;
			}
		}

		.first_name_container, .last_name_container {
			float: left;
			width: 48.5%;

			@include media($break400) {
				float: none;
				width: 100%;
			}
		}

		.street {
			margin-bottom: .5rem;
		}

		.street_input {}

		.row {
			margin-bottom: .5rem;
		}

		.city {
			margin-right: 2.5%;
			width: 48.5%;

			@include media($break1200) {
				width: 51.5%;
			}

			@include media($break500) {
				margin-bottom: .5rem;
			}
		}

		.city_input {}

		.state {
			margin-right: 2.5%;
			width: 20%;

			@include media($break1100) {
				width: 21%;
			}

			@include media($break500) {
				margin-bottom: .5rem;
			}
		}

		.state_input {}

		.zip {
			width: 20%;

			@include media($break1200) {
				width: 22%;
			}
		}

		.zip_input {}

		.country_container {
			width: 48.5%;
			margin-bottom: .5rem;

			@include media($break1200) {
				width: 100%;
			}

			@include media($break630) {
				width: 51.5%;
			}

			@include media($break500) {
				width: 100%;
				margin-right: 0;
			}
		}

		.city, .state, .zip {
			float: left;

			@include media($break500) {
				float: none;
			}
		}

		.city, .state, .zip, .country_container {

			@include media($break500) {
				width: 100%;
			}
		}

		.save_address_btn {}
	}

	.saved_address_container {

		.street {}

		.row {}

		.city {}

		.state {}

		.zip {}
	}

	.saved_msg {

		pre {
			word-break: break-word;
			margin: 0;
			white-space: pre-line;
		}

		a {
			color: $revfluence-blue;
			text-decoration: none;

			&:hover {
				color: $revfluence-blue-hover;
			}
		}

		p {}

		br {
			display: block;
			margin: 5px 0;
		}
	}

	.add_tracking_notice {

		.add_tracking_label {
			display: block;
			font-weight: 700;
			margin-bottom: .5rem;
		}

		.tracking_btn {}

		.arrow_icon {
			vertical-align: -1px;
			margin-left: .4rem;
		}
	}

	/* Tracking Info */
	.tracking_info_container {
		margin-bottom: 2rem;

		.standard_label {
			display: block;
			font-weight: 400;
		}

		.add_tracking_info {
			border-bottom: 1px solid $lightgray-border;
			padding-bottom: 1.25rem;
			margin-bottom: 1.25rem;
		}

		.table_container {}

		.table_title {
			font-weight: 700;
			text-align: left;
		}
	}

	/* Saved Info Title */
	.saved_info_header {
		position: relative;
		margin-bottom: .5rem;

		.standard_label {
			font-weight: 700;
			float: left;
			margin-right: .35rem;
		}

		.edit_btn {
			background: none;
			float: left;
			padding: 0;
			border: none;
			margin: 0;

			&:active, &:focus {
				box-shadow: none;
			}
		}

		.restart_button {
			float: left;
			margin-top: -10px;

			@include media($break425) {
				margin: .5rem 0;
				width: 100%;
			}

			.icon {
				width: 24px;
				margin-right: .15rem;
				vertical-align: -8px;
			}
		}	
	}


	/* Brand - Add Tracking Info */
	.add_tracking_info {

		.main_title {
			font-weight: 600;
			display: block;
			margin-bottom: .5rem;
		}

		.serviceProvider_fieldset {
			margin-bottom: .75rem;
		}

		.tracking_code {
			margin-bottom: 1rem;
		}

		.tracking_link_input {
			max-width: 18rem;
			margin-bottom: .5rem;
		}

		.trackingCode_suggest {
			margin-top: .5rem;
		}
	}

	/* TRACKING CODE */

	.add_tracking_code {

		.main_title {}

		.row {
			margin-bottom: .65rem;
		}

		.carrier_fieldset, .code_fieldset {
			float: left;
			width: 49%;
		}

		.carrier_fieldset {
			margin-right: 2%;
		}
	}


	/* Tracking Packages Shipped  */
	.package_status_table {

		caption {
			margin-bottom: .5rem;
			display: block;
			width: 100%;
		}

		.first_col {
			width: 200px;

			@include media($break555) {
				width: auto;
			}
		}

		.second_col {
			width: 125px;

			@include media($break555) {
				width: auto;
			}
		}

		.third_col {}

		.received_color {
			color: #2AB240;
		}

		td, th {
			padding: .5rem;
		}

		thead, tbody, tr {
			width: 100%;
			display: block;
		}

		thead, tr {
			text-align: left;
			border-bottom: 1px solid $lightgray-border;

			&:last-child {
				border-bottom: none;
			}
		}

		.not_received_color {
			color: #E34C4C;
		}

		.received_package_btn {
			font-size: 1em;
		}
	}

	.your_choices_label {
		font-weight: 600;
	}

	/* Creator - First Step */
	.choose_choices_container {
		margin-bottom: 2rem;
		padding-top: 1rem;

		@include media($break630) {
			padding: 0 .5rem;
		}

		.saved_product_options {
			margin-bottom: 1rem;
		}

		.add_choices {
			margin-bottom: 1.15rem;
		}

		.accordion_box {
			margin-bottom: 2rem;
		}

		.h5 {
			padding-bottom: .5rem;
			display: block;
			font-size: 1rem;
		}

		.creator_info_container {
			width: 97%;

			@include media($break845) {
				width: 99%;
			}

			@include media($break400) {
				padding: .8rem 1.25rem;
			}
		}

		.choices_input {
			margin-bottom: .65rem;
		}

		.saved_creator_choices {
			margin-bottom: .75rem;
		}
	}

	/* Brand - First Step */
	.choice_comparison_container {
		margin-bottom: 1.5rem;
		padding-top: 1rem;

		@include media($break630) {
			padding-top: .5rem;
			margin: 0 .5rem 1rem;
		}

		.nav_tabs {
			font-size: 1rem !important;
		}

		.brand_options_container {}

		.saved_creator_choices {
			margin-bottom: 1rem;
			padding-top: 1rem;
		}
	}

	.product_options_container {
		padding-top: .5rem;

		@include media($break630) {
			padding: 0 .5rem;
			margin-bottom: 2.5rem;
		}

		.add_options_form {
			width: 97%;
			margin-bottom: 1.5rem;

			@include media($break845) {
				width: 99%;
			}
		}

		.hint_msg {
			margin-bottom: .65rem;

			.intro_sentence {
				margin-bottom: .5rem;
			}

			.spec_list {
				list-style-type: decimal;
				list-style-position: inside;
			}
		}
	}
}
