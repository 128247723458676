.analyze_view {

  .distribute_blocker {

    .modal-dialog {
      padding: 2rem;
      position: relative;
      max-width: 680px;
    }

    .close-modal-link {
      padding: 0;
      border: none;
      position: absolute;
      top: 1rem;
      right: 1rem;
    }

    .close_icon {
      width: 20px;
    }

    .main_title {
      text-align: center;
      margin-bottom: 1.5rem;
    }

    .main_content_container {
      margin-bottom: 1.5rem;
    }

    .instagram_content {}

    .content_img, .distribute_descrip {
      float: left;

      @include media($break450) {
        float: none;
      }
    }

    .content_img {
      max-width: 175px;
      margin-right: 1rem;

      @include media($break450) {
        margin: 0 auto 1rem;
        display: block;
      }
    }

    .distribute_descrip {
      text-align: center;
      width: calc(100% - 191px);
      font-size: 1.8em;
      padding-top: 2.8rem;

      @include media($break550) {
        text-align: left;
        padding-top: 0;
      }

      @include media($break450) {
        width: 100%;
        text-align: center;
      }
    }

    .actions_container {
      text-align: center;
    }

    .request_demo_btn {

    }
  }
}
