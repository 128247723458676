@mixin aspirex_title {
	font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    margin-top: 24px;
}

@mixin aspirex_subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #8F8D91;
    text-align: center;
    margin: 8px 0;
}
