.aspireiq_theme {

  .agreeCheckbox {
    background: $aiq_lightblue_bg;

    &:active {
      background: $aiq_lightblue_bg_hover;
    }
  }
}
