.aspireiq_theme {

  .best_practices_nav {

    .navigation_link {

      &:hover {
        color: $aiq_darkblue;
      }
    }
  }

  .best_practice_section {

    .text_container {

      a {
        color: $aiq_darkblue;

        &:hover {
          color: $aiq_darkblue_hover;
        }
      }
    }
  }

  .bottom_border_container {
    display: none;
  }
}
