.conversionTracker_tool {

	.tool_main_container {

		@include media($break630) {
			padding: 1rem;
			background-color: white;
		}
	}

	.unshared_data_warning_container {
		margin-top: 1.0rem;
		margin-bottom: 1.0rem;
	}

	.tools_header {
		margin-bottom: 1rem;
	}

	.sales_tracking_message {
		width: 50%;
		min-width: 300px;
		padding-top: 1.0em;
		padding-bottom: 1.0em;

		h2 {
			font-size: 1.6em;
		}

		.integrations_btn {
			margin-top: 1.0em;
		}
	}

	.add_tracking_title {
		font-weight: 600;
		margin-bottom: .4rem;
	}

	.hasWebsite_container {
		margin-bottom: .9rem;
	}

	.hasWebsite_checkbox {
		overflow: hidden;
		padding: 3px;
	}

	.websiteUrl_container {
		margin-bottom: 0;

		.websiteUrl_label {
			margin-right: .4em;
		}

		.websiteUrl_input {
			width: 90%;
			display: inline-block;
		}
	}

	.current_stats_container {
		padding: .5rem 1rem 1rem;
		border: 1px solid $lightgray-border;
		border-radius: 6px;
		margin-bottom: 1.5rem;
		width: 98%;

		.current_stats_wrapper {
			border-bottom: 1px solid $lightgray-border;
			padding-bottom: .75rem;
			margin-bottom: .5rem;
		}

		.current_stats_title {
			font-weight: 600;
			margin-bottom: .35rem;
		}

		.stats_list {}

		.total_sales_item {
			float: left;
			margin-right: 2rem;
			min-width: 125px;
		}

		.sales_stats_item {}

		.stat_number {
			font-size: 1.35rem;
			display: block;
		}

		.sales_stat_number, .stat_label {
			padding: .1rem .25rem;
		}

		.nubmer_of_stats {
			float: left;
		}

		.recentOrders_container {}

		.recentOrders_table {

			td, th {
				padding: .1rem .25rem;
			}
		}

		.recentOrder_header {
			text-align: left;
		}

		.recentOrder_body {}
	}

	.trackers_container {
		margin-bottom: 1.0rem;
		text-align: left;

		th {
			font-weight: 600;
		}

		td {
			max-width: 300px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		td, th {
			padding: .1rem .5rem;
		}
	}

	.conversion_tracker_text {
		font-size: 1.5em;
	}

	.hasCoupon_code_container {

		.hasCoupon_checkbox {
			margin-bottom: 1.25rem;
		}

		.hasCoupon_checkbox_control {
			overflow: hidden;
			margin-bottom: 0;
		}

		.couponCode_label {
			display: inline-block;
			margin-right: .4em;
		}

		.couponCode_input {
			display: inline-block;
			width: 150px;
		}

	}

	.couponExpires_container {
		margin-left: 2em;
		margin-top: 3em;

		.couponExpires_checkbox {
			margin-bottom: 1.5em;
			overflow: hidden;
		}

		.calendar {
			border: 1px solid $lightgray-border;
			border-radius: 6px;
			padding: 1.25em 1.25em .4em 1.25em;
			width: 259px;
			margin-bottom: 2em;
		}

		.calendarHead {
			padding-bottom: 1em;
			border-bottom: 1px solid $lightgray-border;
			overflow: hidden;
			position: relative;
			margin-bottom: .35em;
		}

		.navigateMonth_btn {
			width: 20px;
			height: 20px;
			display: block;
			position: absolute;
			padding: 0;

			&:focus, &:active {
				box-shadow: none;
			}
		}

		.previousMonth_btn {
			background: url('/images/new_manage/prev_month_icon.svg') center no-repeat;
			left: 0;
			top: 1px;
		}

		.nextMonth_btn {
			background: url('/images/new_manage/next_month_icon.svg') center no-repeat;
			right: 0;
			top: 1px;
		}

		.calendar_title {
			margin: 0 auto;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 180px;
			display: block;
			text-align: center;
		}

		.month {}

		.year {}

		.calendarBody {}

		.weekdayNames_list {}

		.weekdayNumbers_list {}

		.weekday_item {
			display: inline-block;
			margin: 0;
			width: 30px;
			height: 30px;
			padding-top: 5px;
			text-align: center;
		}

		.day_btn {
			cursor: pointer;
			border-radius: 4px;
			overflow: hidden;

			&:hover {
				background-color: #E1EEFF;
				color: $revfluence-blue;
			}
		}

		.activeDay_btn {
			background-color: $revfluence-blue;
			color: white;
			border-radius: 4px;

			&:hover {
				color: white;
				background-color: $revfluence-blue;
			}
		}

		.empty_btn {
			cursor: default;

			&:hover {
				background-color: white;
			}
		}

		.codeDescrip_container {}

		.codeDescrip_label {}

		.codeDescrip_input {
			width: 250px;
		}

	}

	.text_container {
		font-size: 1.02em;
	}

	.hasShopify_integration_container {
		margin-bottom: 1.5em;
	}

	.updateButton_container {
		padding-top: 1.5em;
		border-top: 1px solid rgb(236, 236, 236);
	}
}
