.filter_creators {

	.modal-dialog {
		width: 500px;
		@include media($break550) {
			width: 95%;
		}
	}

	.close-modal-link {
		display: none;
	}

	.modal-header {
		padding: 1em;
		position: relative;
		background-color: #f4f4f4;

		.modal-title {
			@include fontsize18;
			color: $dark-text;
			text-align: center;
			@include standard-bold;
            margin-bottom: 0.3em;
		}

        .modal-subtitle {
            @include fontsize16;
            color: $label-red;
            text-align: center;
        }

		.close_link {
			width: 20px;
			height: 20px;
			position: absolute;
			right: 1em;
			top: 1.4em;
			background: url('../images/filter_creators/close_icon.svg') center no-repeat;
			text-indent: -9999px;
		}
	}

	.section_title {
		@include fontsize16;
		color: $dark-text;
		@include standard-bold;
		margin-bottom: 1em;
	}

	.section_container {
		padding: 2em;
		margin-bottom: 1px solid $lightgray-border;
		border-bottom: 1px solid $lightgray-border;
	}

	.network_select_container {

		.actions_container {
			overflow: hidden;
		}

		.actions_list {
			float: left;
		}

		.actions_item {
			float: left;
			margin-right: 10px;
			&:last-child {
				margin-right: 0;
			}
		}

		.youtube_icon {
			background: url('/images/browse_creators/youtube_icon.svg') center no-repeat;
		}

		.instagram_icon {
			background: url('/images/browse_creators/instagram_icon.svg') center no-repeat;
		}

		.action_button {
			-webkit-appearance: none;
			box-shadow: 0.5px 0.866px 5px 0px rgba(1, 1, 1, 0.06);
			border: 1px solid #c3c3c3;
			border-radius: 4px;
			background-color: white;
			cursor: pointer;
			padding: 8px 11px;
			outline: none;
		}

		.active_action_button {
			background-color: $revfluence-blue;
			box-shadow: inset 0px 2px 4px 0px rgba(37, 130, 244, 0.07);
			border: 1px solid $revfluence-blue;

			.youtube_icon {
				background: url('/images/browse_creators/youtube_icon_white.svg') center no-repeat;
			}

			.instagram_icon {
				background: url('/images/browse_creators/instagram_icon_white.svg') center no-repeat;
			}
		}

		.action_icon {
			width: 15px;
			height: 15px;
			display: block;
			text-indent: -9999px;
		}
	}

	.average_views_container {

		.section_title {
			margin-bottom: 3.0em;
		}

		.slider_container {
            padding: 0em 2.5em;
			margin-bottom: 2em;
			@include ui-slider;
		}

		.price_estimates_container {
            padding: 0em 2.5em;

            .title {
                @include fontsize14;
                @include standard-bold;
            }

            .dedicated_estimate {
				margin-bottom: .5em;
			}

			.dedicated_estimate, .mention_estimate {
				@include fontsize14;
				color: $dark-text;
			}
		}
	}

	.subscribers_container {

		.section_title {
			margin-bottom: 2em;
		}

		.slider_container {
			margin-bottom: 2em;
			@include ui-slider;
		}
	}

	.countries_container, .language_container {
		border-bottom: none;

		.country_title_container {
			margin-bottom: 1em;
			overflow: hidden;

			.section_title {
				display: inline-block;
				margin-right: .25em;
				margin-bottom: 0;
			}

			.remove_countries_container {
				display: inline-block;
				float: right;

				.remove_countries_link {
					@include blue-link;
					display: inline-block;
					@include fontsize12;
				}
			}
		}

		.selected_countries_container {
			margin-bottom: 1em;

			.selected_countries_list {
				@include non_colored_labels;
				display: inline-block;
			}

			.showing_all_indication {
				color: $lightgray-text;
				@include fontsize12;
				display: none;
			}
		}

		.country_input_container {
			position: relative;
		}

		.country_input {
			@include standard-input;
			width: 100%;
		}

		.suggestions_container {
            display: none;
			position: absolute;
			top: 47px;
			left: 0;
			border: 1px solid $lightgray-border;
			z-index: $zindexTutorial;
			background-color: white;
			width: 100%;
			height: 175px;
			overflow-y: auto;
			@include standard-radius-bottom;
		}

		.suggestions_list {
			padding: 1em 0;

			li {
				@include fontsize14;
				color: $dark-text;
				padding: .5em 1.5em;
				cursor: pointer;

				&:hover {
					background-color: $revfluence_blue;
					color: white;
				}
			}
		}
	}

	.filter_button {
		@include standard-radius-bottom;
		width: 100%;
		display: block;
		text-align: center;
	}
}
