.demo_navbar {
    background-color: white;
    border-bottom: 1px solid $lightgray-border;
    z-index: $zindexHeader;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    vertical-align: baseline;


    .inner_container {
		max-width: 1200px;
		margin: 0 auto;
		overflow: hidden;
		@include media($break1200) {
			padding: 0 2em;
		}
	}

	.logo_container {
		width: 132px;
		float: left;
		margin-top: 1.1em;
		@include media($break505) {
			float: none;
			margin: 1.1em auto 0;
		}
	}

	.revfluence_logo {
		width: 132px;
		height: 29px;
		display: block;
		background: url('/images/themes/aspireiq/aiq_logo.png') center no-repeat;
		background-size: 100%;
		text-indent: -9999px;
	}

	.nav_links_container {
		width: 314px;
		float: right;
		@include media($break505) {
			float: none;
			width: 100%;
		}
	}

	.nav_links_list {
		@include media($break505) {
			text-align: center;
		}
	}

	.nav_links_item {
		display: inline-block;
		margin-right: 3em;
		padding: 1.5em 0;

		&:last-child {
			margin-right: 0;
		}

		@include media($break325) {
			margin-right: 2em;
		}
	}

	.nav_text_link {
		display: block;
		color: #969696;
		line-height: 1.5em;
		text-decoration: none;
		font-size: 1.5em;
		font-weight: 300;
		&:hover {
			color: #3b92fb;
		}
		@include media($break325) {
			font-size: 1.25em;
		}
	}

	.active {
		font-weight: 600;
		color: $dark-text;
		&:hover {
			color: $dark-text;
		}
	}

	.how_it_works_item {
		border-left: #eaeaea 1px solid;
		padding-left: 3em;
		@include media($break505) {
			border-left: none;
			padding-left: 0;
		}
	}

    .messages_number {
        background-color: $revfluence-blue;
        color: white;
        @include fontsize12;
        display: inline-block;
        @include standard-radius;
        padding: .01em .5em;
    }
}
