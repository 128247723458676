.creator_profile, .new_manage {

  .past_collabs {

    .main-loading-section{
      top:50px;
    }

    @include media($break875) {
      padding: .75rem 1rem;
    }

    .past_collabs_header {
      margin-bottom: 1rem;

      .past_collabs_title {
        float: left;

        @include media($break500) {
          float: none;
        }
      }

      .toggle_edit_btn {
        padding: 0;
        background: none;
        border: none;
        float: left;
        margin-top: 15px;
        margin-left: .5rem;
      }

      /* Edit/Preview Mode Togggle */
      .previewManage_tab {
        float: left;
        margin-left: 1.25rem;
        padding-top: .75rem;
        width: 200px;

        @include media($break500) {
          margin-left: 0;
          float: none;
        }

        .previewManage_btn {
          background: none;
          border: 1px solid $lightgray-border;
          padding: 3px 14px;
          float: left;
          width: 50%;

          &:focus, &:active {
            box-shadow: none;
          }
        }

        .toggle_btn_icon {
          width: 17px;
        }

        .toggle_btn_txt {
          font-size: .85rem;
          font-weight: 300;
          color: $aiq_darkblue;
        }

        .active_previewManage_btn {
          border: 1px solid $aiq_darkblue;
          background-color:  $aiq_darkblue;

          .toggle_btn_txt {
            color: white;
          }
        }

        .manage_btn {
          border-radius: 100px 0 0 100px;
        }

        .edit_icon {
          vertical-align: -3px;
          width: 14px;
        }

        .preview_btn {
          border-radius: 0 100px 100px 0;
        }

        .preview_icon {
          vertical-align: -1px;
          margin-right: 1px;
        }
      }
    }

    .buildResume_hint {
      padding: .6rem .9rem .6rem;
      margin-bottom: .5rem;
      display: inline-block;

      p {
        margin-bottom: 0;
      }
    }

    .past_collabs_list {
      position: relative;
    }

    .past_collab_item {
      border-bottom: 1px solid $lightgray-border;
      padding-bottom: 1.5rem;
      margin-bottom: 1.5rem;

      &:last-child {
        border-bottom: none;
      }
    }

    .collab_header {}

    .name_row {
      margin-bottom: .5rem;
    }

    .logo_container {
      float: left;
      width: 39px;
      margin-right: .65rem;
    }

    .logo_img {
      border-radius: 100px;
      max-width: 100%;
      display: block;
    }

    .brand_name_txt {
      float: left;
      width: calc(100% - 55px);
    }

    .metadata_row {
      margin-bottom: .5rem;
    }

    .contentDescrip_container {
      padding-bottom: .95rem;
    }

    .visibility_indicator {
      float: left;
      margin-right: 1rem;
    }

    .offline_icon {}

    .online_icon {}

    .visibility_icon {
      width: 11px;
      margin-right: .25rem;
    }

    .visibility_txt {
      font-weight: 600;
    }

    .date_txt {
      float: left;
    }

    .edit_short_blurb {
      margin-bottom: 1rem;

      .edit_blurb_label {
        margin-bottom: .5rem;
      }

      .collab_summary_input {
        max-width: 475px;
        margin-bottom: .75rem;
      }

      .set_live {}
    }

    .past_collabs_carousel {
      margin: 1rem 0;
      position: relative;
      border-top:1px solid $lightgray-border;
      border-bottom: 1px solid $lightgray-border;

      .slick-prev, .slick-next {
        width: 35px;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index:10;
        background-color:white;
        border:1px solid $lightgray-border;
      }

      .slick-list{
        left:10px;
      }

      .slick-prev {
        border-radius: 8px 0 0 8px;
        left: 0;
      }

      .slick-next {
        border-radius: 0 8px 8px 0;
        right: -25px;
      }

      .nav_left_icon, .nav_right_icon {
        position: absolute;
        left: 50%;
        margin-left: -6px;
        top: 50%;
        margin-top: -7px;
      }

      .nav_left_icon {}

      .nav_right_icon {}

      .gallery_content_list {
        width: 100%;
        border-top: 1px solid $lightgray-border;
        border-bottom: 1px solid $lightgray-border;
        padding: 0 35px;
        border-radius: 8px;
      }

      .gallery_content_item {
        width: 32.8%;
        margin-right: .2%;
        display: inline-block;
        vertical-align: middle;
        position: relative;

        &:last-child {
          margin-right: 0;
        }

        @include media($break500) {
          width: 32.6%;
        }

        @include media($break390) {
          width: 32.4%;
        }

        @include media($break320) {
          width: 32.2%;
        }
      }

      .duration_amount {
        background: $dark-text;
        border-radius: 4px;
        color: white;
        padding: 2px 5px 1px 4px;
        position: absolute;
        bottom: 8px;
        right: 12px;
        font-size: .75rem;

        @include media($break390) {
          display: none;
        }
      }

      .yt_content_img {}

      .ig_content_img {}

      .content_img {}
    }

    .action_notice {
      font-weight: 600;
      display: inline-block;
      margin-bottom: .5rem;

      .hint_txt {
        display: inline;
      }

      .hint_link {}

      .hint_btn {
        background: none;
        margin-left: .25rem;
      }

      .hint_icon {}
    }

    .short_blurb {
      margin-bottom: 1rem;

      .blurb_txt {
        padding-bottom: .5rem;
      }
    }

    .hint_msg {
      padding: .6rem .9rem .75rem;
    }

    .content_list {

      .content_item {
        width: 33%;
        float: left;
        margin-right: .2%
      }

      .content_link {}

      .ig_content_img {}

      .yt_content_img {}

      .content_img {
        display: block;
        max-width: 100%;
      }
    }
  }
}
