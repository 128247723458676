.talentAgent_auth_success {

  .authRev_logo {
    display: block;
    margin: 8px auto 80px;
    width: 200px;
    padding-top: 6rem;
  }

  .main_container {
    max-width: 740px;
    margin: 0 auto 9rem;

    @include media($break400) {
      padding: 0 1rem;
    }

    .talentAgent_logo_container {
      width: 75px;
      margin: 0 auto 1.25rem;
      position: relative;
    }

    .icon_checkmark {
      position: absolute;
      top: -15px;
      right: -15px;
    }

    .talentAgent_logo {
      max-width: 100%;
      width: 100%;
    }

    .talentAgent_main_title {
      margin-bottom: 1rem;
      text-align: center;

      @include media($break400) {
        font-size: 2.25rem;
      }
    }

    .talentAgent_auth_descrip {
      margin-bottom: 1.75rem;
      text-align: center;
      font-size: 1.15rem;

      strong {
        font-weight: 600;
      }
    }

    .getStarted_btn {
      display: block;
      width: 160px;
      margin: 0 auto;
    }

    .arrow_icon {
      vertical-align: -3px;
      margin-left: .25rem;
    }
  }
}
