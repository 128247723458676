.tiktok_invitation_sent_confirmation_modal {
  max-width: 500px;
  min-height: 500px;

  .modal-dialog {
    padding: 2rem 4rem;
  }

  .tiktok_invitation_sent_confirmation_section {
    text-align: center;
    max-width: 550px;

    .main_title {
      font-size: 3em;
    }

    .explanation_icons {
      margin-bottom: 30px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      .left_icon {
        width: 95px;
        height: 172px;
        background-image: url('/images/style_guide/icon_tiktok_left.svg');
        margin-right: 20px;
      }

      .right_icon {
        width: 95px;
        height: 172px;
        background-image: url('/images/style_guide/icon_tiktok_right.svg');
      }
    }

    .main_descrip {
      line-height: 18px;
      margin-bottom: 30px;

      .first_paragraph {
        margin-bottom: 30px;
      }

      .second_paragraph {
        font-weight: 700;
        margin-bottom: 30px;
      }
    }

    .warning {
      font-size: 1.25em;
    }

    .setup_account_form {
      max-width: 350px;
      margin: 0 auto;
    }

    fieldset {
      margin-bottom: .5rem;
    }

    .finish_btn {
      font-size: 1.5em;
      cursor: pointer;
    }
  }
}
