.aspireiq_theme {

  .rebrandNotice {
    padding: 1rem;
    text-align: center;
    margin: 0 auto 1.5rem;

    @include media($break320) {
      margin: 0 .5rem 1.5rem;
    }

    .rebrand_img {
      padding-bottom: 1rem;
      max-width: 350px;
      width: 100%;
    }

    .rebrandTitle, .rebrandDescrip {
      margin: 0 auto;
      max-width: 525px;
    }

    .rebrandTitle {
      font-size: 1.25rem;
      font-weight: 600;
      padding-bottom: .25rem;
    }
  }

  .creator_rebrandNotice {
    max-width: 920px;
  }

  .org_rebrandNotice {
    max-width: 600px;
    margin: 1.5rem auto 1.5rem;

    @include media($break320) {
      margin: 1.5rem .5rem 1.5rem;
    }
  }

  .loginmodalwindow {
    margin: 0 auto 5em;
    max-width: 580px;
    width: 100%;

    h1 {
      font-size: 1.75rem;
      margin: 0 0 15px 0;
    }

    .w-form-fail {
      background-color: $aiq_dangerRed;
      font-size: .85rem !important;
      padding: 0 !important;
      border-radius: 3px;
      margin-top: 1rem;
      font-weight: 500;

      p {
        color: white;
        line-height: 2rem;
      }
    }

    .w-form-done {
      position:relative;
      text-align: center;
      font-size: .85rem;
      background: $aiq_pink;
      color: white;
      line-height: 2rem;
      height: auto;
      border-radius: 3px;
      margin-top: 1rem;
      font-weight: 500;

      p {
        color: white;
      }
    }

    .signin_descrip {
      margin-top: 1rem;
      text-align: center;
      padding-bottom: 2rem;
      color: $aiq_black;
    }
  }

  .forgotmodal {

    h2 {
      font-size: 1.75rem;
    }

    .loginLink {
      color: $aiq_darkblue;

      &:hover, &:focus {
        color: $aiq_darkblue_hover;
      }
    }
  }

  .brandSignin_logo {
    background: url('/images/themes/aspireiq/aiq_logo.png') center no-repeat;
    background-size: 100%;

  }

  .submitbutton {
    background-color: $aiq_darkblue;
    color: white;

    &:hover, &:focus {
      background-color: $aiq_darkblue_hover;
    }

    &:active {
      background-color: $aiq_darkblue;
    }
  }

  .loginform {

    .loginLink {
      color: $aiq_darkblue;

      &:hover, &:focus {
        color: $aiq_darkblue_hover;
      }
    }
  }

  .loginLink.su {
    color: $aiq_darkblue;

    &:hover, &:focus {
      color: $aiq_darkblue_hover;
    }
  }

  .creator_login_header {

    @include media($break320) {
      margin-bottom: 1.5rem !important;
    }

    .revfluence-logo {

      a {
        background: url('/images/themes/aspireiq/aiq_logo.png') center no-repeat !important;
        background-size: 100% !important;
        width: 159px;
        height: 60px;
      }
    }
  }

  .creator_login_body {
    .creator_marketplace_navigation {
      text-align: center;
      background-color: #FFECA4;
      padding: 15px 10px;
      font-size: 20px;
      margin-bottom: 10px;
      .creator_host {
        color: black;
        font-weight: 500;
        cursor: pointer;
      }
    }

    .intro_title {
      font-size: 1.9rem !important;
    }

    .main {

      .form-error-span {
        background-color: $aiq_dangerRed;
        font-size: .85rem !important;
        padding: 0 .5rem !important;
      }

      .email_login {

        .email_login_button {
          background-color: $aiq_darkblue;
          color: white;

          &:hover, &:focus {
            background-color: $aiq_darkblue_hover;
          }

          &:active {
            background-color: $aiq_darkblue;
          }
        }

        .forgot_password_link {
          color: $aiq_darkblue;

          &:hover, &:focus {
            color: $aiq_darkblue_hover;
          }
        }
      }
    }
  }
}
