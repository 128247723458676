.new_campaign_edit_view {

	.mainSettings_content {
		height: calc(100% - 115px);
		overflow-y: auto;

		@include media($break1024) {
			height: calc(100% - 78px);
		}
	}

	.campaignCreation_steps {
		display: none;
	}

	.main_actions {
    text-align: center;
    padding: 1.5rem 0;
		background-color: rgba(255,255,255,.5);
		border-radius: 0 0 6px 6px;
		border: 1px solid $lightgray-border;
		max-width: 1200px;
		margin: 0 auto;
		position: relative;
		z-index: 1;

		@include media($break1024) {
			padding: 1rem 0;
		}

		@include media($break330) {
			padding: 1rem;
		}

    .save_btn {
			margin: 0 .25rem;

      @include media($break330) {
        display: block;
        width: 100%;
				margin: .5rem 0;
      }
    }

    .save_btn {}

    .continue_btn {}
  }

	.contact_rep_container {
    text-align: center;
    background-color: $hint_yellow;
    border: 1px solid $hint_border_yellow;

    .contact_rep_header {
      margin-bottom: .25rem;
    }

    .success_icon {
      vertical-align: -3px;
      margin-right: .25rem;
    }

    .campaignApproved_txt {
      font-weight: 600;
    }
  }

	.help-block {
		color: #d9534f;
		font-size: 1.5em;
		margin: .618em 0;
		line-height: 2em;
		font-weight: bold;
	}

	.nav_tabs {

		.active {
			font-weight: 600;
		}

		.step_number {}

		li {
			width: 16.251%;
			text-align: center;
			margin-right: .5%;

			&:last-child {
				margin-right: 0;
			}
		}

		a {
			margin-right: 0;
		}
	}

	.fiveNav_tabs {

		li {
			width: 19.5%;
		}
	}

	.sixNav_tabs {

		li {
			width: 16.25%;
		}
	}

	.createOffer_header {
		padding-bottom: 2em;
		text-align: center;
		@include media($break900) {
			margin-top: 7em;
		}

		.inner_container {
			position: relative;
			max-width: 1400px;
			margin: 0 auto;
		}

		.back_btn {
			position: absolute;
			top: 11px;
			background: none;
			padding: 0;
			left: 0;

			&:active, &:focus {
				box-shadow: none;
			}

			.back_icon {
				width: 30px;
				display: block;
			}
		}

		.createOffer_title {
			text-align: center;
			margin: .75em 0 .25em;

			@include media($break900) {
				margin: 1.5em 0 .25em;
			}

			@include media($break550) {
				font-size: 3.5em;
			}

			@include media($break350) {
				font-size: 2.75em;
			}
		}

		.createOffer_subtitle {
		  margin: 0 0 .5em 0;
		  text-align: center;
			font-size: 2em;
		}
	}

	.main_wrapper {
		padding: 2rem;
		border: 1px solid $lightgray-border;
		border-radius: 7px;
	}

	.campaignImg_container {
		float: left;
		width: 23%;
		margin-right: 5%;

		@include media($break705) {
			width: 100%;
			margin-right: 0;
			margin-bottom: 2rem;
		}

		.imgUpload_title {
			display: none;
			margin-bottom: 1rem;
			font-weight: 600;

			@include media($break705) {
				display: block;
			}
		}

		.img_upload_label {
			display: block;
			margin-bottom: .85rem;
			cursor: pointer;

			@include media($break705) {
				width: 300px;
			}

			@include media($break375) {
				width: 100%;
			}
		}

		.broadcastEditSplashImg {
			max-width: 100%;
			display: block;
		}
	}

	.campaignDetails_container {
		float: left;
		width: 61%;

		@include media($break705) {
			width: 100%;
		}

		.basicInfo_section {
			margin-bottom: 1.25rem;
			padding-bottom: .5rem;
			border-bottom: 1px solid $lightgray-border;
		}

		.compensation_section {
			margin-bottom: 2.5em;

			.compensation_title {
				font-weight: 600;
				margin-bottom: .5rem;
				font-size: 1.5em;
			}

			.checkbox_text {
				display: inline-block;
				color: $dark-text;
				font-weight: 300;
			}

			.form_group {
				margin-bottom: .5rem;
			}

	    .example_fixed_price_text {
	      font-size: 1.05em;
	      margin-left: 1.5em;
	      display: inline-block;
	      color: $dark-text;
	      font-weight: 300;
	    }

			.per_network_view_txt {
				font-size: 1.25em !important;
				vertical-align: 3px;
			}

			.fixed_compensation_per_engagement {
				display: block;
				margin-top: .25rem;
				font-size: 1.25em;
			}



			.filter_out_example {

			}

			.payment_instructions {
				margin-top: .75rem;
				margin-bottom: .9rem;
			}

			.max_budget_per_container {
				margin-bottom: 1.5rem;
			}

			.max_budget_per_label {
				margin-bottom: 0;
				font-weight: 300;
			}

	    .example_fixed_price {
	      font-weight: 700;
	    }

	    .help_text {
		    @include fontsize17;
		    font-weight: 300;
		    color: $dark-text;
		    margin-bottom: .5em;
		    position: relative;
		    top: 50%;
		    transform: translateY(-50%);
		    margin-left: 0.3em;
	    }

			.productInstructions {
				display: block;
				width: 100%;
			}

			.commissionDetails_label {
				@include standard_label;
			}

	    .payment_details_input {
	      width: 70px;
	      margin-top: 0.5em;
	      margin-left: 1.5em;
	      height: 40px;
				margin-bottom: .15rem;
	    }

	    .indentation {
	      margin-left: 3em;
	    }

			.max_budget_select {
				display: block;
				width: 150px;
				margin-top: .5rem;
			}

			.viaAffiliate_character_count {
				font-size: 1.25em;
				display: block;
				margin-top: .25rem;
				margin-left: 1.5rem;
			}

			.productInstructions_textarea, .commission_details_textarea, .other_compensation_textarea {
				height: 65px;
				width: 519px;
				margin-top: -.45em;
	      margin-left: 1.5em;
				@include media($break640) {
					width: 100%
				}
			}

			.commission_details_textarea {
				margin-top: .5rem;
			}

			.other_compensation_textarea {
				margin-top: .5rem;
			}

			.other_compensation_character_count {
				margin-left: 1.5rem;
				font-size: 1.25em;
				margin-top: .5rem;
			}

			.commission_instructions {
				width: 100%;
			}

	    .hint-icon {
		    display: inline-block;
		    position: relative;
		    margin-left: .5em;
		    width: 20px;
		    height: 20px;
		    cursor: pointer;
		    vertical-align: -5px;
		    background: url('../images/brand_onboarding/tooltip_hint_icon.svg') center no-repeat;
				background-size: 100%;
	    }

	    .standardTooltip {
	      @include standard_tooltip;
	      width: 180px;
	      bottom: 31px;
	      left: -83px;
	    }
		}

		.networks_section {
			margin-bottom: 2rem;

			input[type="checkbox"] {
				float: left;
				margin-right: .65rem;
			}

			.networks_title {
				font-weight: 600;
				margin-bottom: .8rem;
			}

			label {
				float: left;
				margin-right: 2rem;
				cursor: pointer;

				&:last-child {
					margin-right: 0;
				}
			}

			.text_container {
				float: left;
			}

			.instagram_icon {
				display: block;
				margin-top: .04rem;
			}

			.youtube_icon {
				margin-top: .2rem;
			}

			.twitter_icon {}

			.facebook_icon {}

			.vine_icon {}

			.snapchat_icon {}

			.blog_icon {}

			.pinterest_icon {}

			.googleplus_icon {}
		}
	}

	.campaignform {
		margin-bottom: 1.25rem;

	  .fieldset-1 {
	  	width: 64%;
	  	@include media($break900) {
	  		width: 100%;
	  		padding: 0;
	  	}
	  }

	  .fieldset-2 {
	  	width: 68%;

	  	@include media($break900) {
	  		width: 100%;
	  		padding: 0;
	  	}
  }

    .payment_details_input {
      @include standard-radius;
      @include standard-input;
      background-color: white;

      &:focus {
          background-color: white;
      }
  	}

		.container {
			@include standard-radius;

			fieldset {
				display: inline-block;
				margin-right: 2em;
				@include media($break667) {
					margin-right: 0;
				}
			}

			textarea {
				border: 1px solid $lightgray-border;
				padding-bottom: 2em;
				margin-bottom: .5em;
				background-color: white;
			}

			.exampleText_container {
				display: none;
			}
		}

		.focusContainer_active {
			background-color: #dff2ff;
			padding: 2em 2.5em;
			@include media($break900) {
				padding: 1em 2em 1em;
			}

			textarea {
				border: 1px solid #aacde6;
			}

		.exampleText_container {
			display: inline-block;
			@include fontsize15;
			width: 33%;
			vertical-align: top;
			display: inline-block;
			@include media($break900) {
				width: 100%;
			}
			@include media($break667) {
				padding: 0;
			}

			strong {
				color: $dark-text;
				display: block;
				font-weight: 500;
				font-style: italic;
				@include media($break900) {
					display: inline-block;
					margin-right: 1em;
				}
			}

			ul {
				list-style: initial;
				list-style-position: inside;
				@include media($break900) {
					display: inline-block;
				}
			}

			li {
				color: $dark-text;
				font-style: italic;
				@include media($break900) {
					display: inline-block;
					margin-right: 1em;
				}
			}

			p {
				color: $dark-text;
				margin-left: 0 !important;
			}
			}
		}
	}

	.exampleCollab_container {
		margin-bottom: 1em;

		.exampleCollab_input {
			@include standard-input;
			margin-right: .25em;
			width: 91.5%;
			background-color: white;

			&:focus {
				background-color: white;
			}
		}

		.delete_example_collab {
			display: inline-block;
			width: 18px;
			height: 20px;
			text-indent: -9999px;
			background: url('../images/brand_onboarding/delete_example_collab_icon.svg') center no-repeat;
			vertical-align: .6em;
      cursor: pointer;
		}
	}

	.add_another_example {
		margin-top: .5em;
		display: inline-block;
	}

	.informationRequest_container {
		margin-bottom: 3em;
		overflow: hidden;
		padding-left: 2em;

		.informationRequestHeader {
			@include fontsize17;
			color: $dark-text;
			display: block;
			font-weight: 500;
			margin-bottom: .5em;
		}

		.informationRequestHeader {}

      .broadcastInformation_inputs {
          float: left;
          width: 60%;
          margin-right: 2em;
      }
	}

	.exampleText_container {
		font-size: .85em;
		padding: 1em 2em;
		border: 1px solid $lightgray-border;
		border-radius: 6px;
		margin-top: .75rem;

		.exampleText_title {
			font-weight: 600;
			margin-bottom: .25rem;
			display: block;
		}

		.exampleList {
			list-style-type: disc;
			list-style-position: inside;
		}
	}

	.createCampaign_actions {
		display: none;
	}
}
