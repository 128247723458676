.app_campaign_details {
    h4 {
        color: $dark-text;
        @include fontsize18;
        font-weight: lighter;
        margin-top: 0.5em;
    }

    .standard_campaign_details_container {
        padding: 2em;

        h6 {
            font-size: 1.4em;
            font-weight: 600;
            color: $dark-text;
            padding: 0;
            margin-bottom: 0.2em;
        }

        .profileparagraph {
            color: $dark-text;
        }
    }
}