/* Starter HTML

DESKTOP

<div class="pagination_wrapper">
  <ol class="pagination_list">
    <li class="pagination_item">
      <button class="pagination_btn btn rectangle_btn"><img src="/images/style_guide/icon_chevronLeft_black.svg" alt class="icon_chevronLeft icon_chevron"></button>
    </li>
    <li class="pagination_item">
      <button class="pagination_btn btn rectangle_btn">1</button>
    </li>
    <li class="pagination_item"><img src="/images/style_guide/icon_ellipses_black.svg" class="icon_ellipses"></li>
    <li class="pagination_item">
      <button class="pagination_btn btn rectangle_btn">5</button>
    </li>
    <li class="pagination_item">
      <button class="pagination_btn btn rectangle_btn">6</button>
    </li>
    <li class="pagination_item">
      <button class="pagination_btn btn rectangle_btn">7</button>
    </li>
    <li class="pagination_item">
      <button class="pagination_btn btn rectangle_btn">8</button>
    </li>
    <li class="pagination_item">
      <button class="pagination_btn btn rectangle_btn">9</button>
    </li>
    <li class="pagination_item"><img src="/images/style_guide/icon_ellipses_black.svg" class="icon_ellipses"></li>
    <li class="pagination_item">
      <button class="pagination_btn btn rectangle_btn">10</button>
    </li>
    <li class="pagination_item">
      <button class="pagination_btn btn rectangle_btn"><img src="/images/style_guide/icon_chevronRight_black.svg" alt class="icon_chevronLeft icon_chevron"></button>
    </li>
  </ol>

</div>

MOBILE
<div class="mobilePagination_wrapper">
  <button class="previousPage_btn page_btn"><img src="/images/style_guide/icon_chevronLeft_black.svg" alt class="icon_chevronLeft"></button>
  <span class="currentPage_txt">Page 1</span>
  <button class="previousPage_btn page_btn"><img src="/images/style_guide/icon_chevronRight_black.svg" alt class="icon_chevronLeft"></button>
</div>

*/

.pagination_wrapper {

  .pagination_list {}

  .pagination_item {
    display: inline-block;
    margin-right: .2rem;

    &:last-child {
      margin-right: 0;
    }
  }

  .pagination_btn {
    font-weight: 300;
    font-size: .85rem;
    width: 34px;
    padding: 6px 8px;
  }

  .active_pagination_btn {
    background-color: $revfluence-blue;
    color: white;
    border: 1px solid $revfluence-blue;
  }

  .icon_chevron {
    height: 15px;
    margin: 3px 0 2px;
  }

  .icon_chevronLeft {}

  .icon_chevronRight {}

  .icon_ellipses {
    width: 16px;
    vertical-align: -6px;
  }
}

/*
<ul class="pageIndicator_list">
  <li class="pageIndicator_item"><button type="button" class="btn_pageIndicator btn_icon btn"><img src="/images/style_guide/icon_pageIndicator_active.svg" class="icon_pageIndicator"></button></li>
  <li class="pageIndicator_item"><button type="button" class="btn_pageIndicator btn_icon btn"><img src="/images/style_guide/icon_pageIndicator_inactive.svg" class="icon_pageIndicator"></button></li>
  <li class="pageIndicator_item"><button type="button" class="btn_pageIndicator btn_icon btn"><img src="/images/style_guide/icon_pageIndicator_inactive.svg" class="icon_pageIndicator"></button></li>
</ul>
*/
.pageIndicator_list {
  text-align: center;
  padding: 1rem;

  .pageIndicator_item {
    display: inline-block;
    margin-right: .5rem;
  }

  .btn_pageIndicator {
    padding: .5rem;
  }

  .icon_pageIndicator {}
}
