.import_modal {

  ::-webkit-scrollbar {
    width:  6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #D8D8D8;
    border-radius: 100px;
  }

  ::-webkit-scrollbar-track {
    background: none;
  }

  .modal-dialog {
    padding: 0 2rem;
  }

  .btn_close_modal {
    background: none;
    padding: 10px;
    position: absolute;
    right: 5px;
    top: 5px;

    &:hover {
      background-color: $ultraLight_gray;
    }
  }

  .icon_close {
    width: 14px;
  }

  .main_header {
    text-align: center;
    padding: 1rem 0;
  }

  .noImports_container {
    text-align: center;
    border-radius: 6px;
    padding: .5rem;
    background-color: $lightgray-bg;
  }

  .fetching_spinner_container {
    height: 100px;
  }

  .main_title {

    @include media($break480) {
      font-size: 1.6rem;
    }
  }

  .campaignHeader {
    font-weight: 600;
    display: block;
    text-align: left;
    padding-bottom: .5rem;
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  .autoMsg_list_container {
    border: 1px solid $lightgray-border;
    border-radius: 6px;
    max-height: 225px;
    overflow-y: auto;
    padding: .5rem;
    max-width: 450px;
    margin: 0 auto;
  }

  .autoMsg_list {
    padding-left: .7rem;
  }

  .reactivate_btn {
    margin-right: .5rem;
  }

  .actions_container {
    padding: 1.5rem 0;
    text-align: center;
  }

  .error {
    text-align: center;
    display: block;
  }

}
