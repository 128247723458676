.new_manage {

  .edit_team {
    float: left;
    width: calc(100% - 238px);
    height: 100%;
    position: relative;

    @include media($break845) {
      width: 100%;
      float: none;
      background: white;
    }

    .actionBar {
      width: 100%;
      background-color: white;
      padding: .75rem 0;

      @include media($break845) {
        display: none;
      }

      .selectedIndicator {
        padding-top: .3rem;
        display: block;
        margin-bottom: 1rem;
      }

      .createTeam_btn {}
    }

    .edit_team_header {
      padding: 1rem 3rem;
      position: relative;
      border-bottom: 1px solid $lightgray-border;
      margin-bottom: 1rem;

      @include media($break845) {
        padding: .5rem 3rem .8rem;
        text-align: center;
      }

      strong {
        font-weight: 600;
      }

      .back_btn {
        background: none;
        padding: 0;
        border: none;
        position: absolute;
        left: 0;
        top: 1.8rem;

        @include media($break845) {
          left: .75rem;
          top: 1.4rem;
        }

        @include media($break500) {
          top: 1.2rem;
        }
      }

      .create_newTeam_title {

        @include media($break500) {
          font-size: 2.5em;
        }
      }
    }

    .edit_team_body {
      height: calc(100% - 96px);
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      @include media($break845) {
        overflow-y: initial;
        height: 100%;
      }

      .nameTeam_fieldset {
        margin-bottom: 1.15rem;
        max-width: 400px;

        @include media($break845) {
          margin-bottom: .8rem;
          padding: 0 .75rem;
        }

        .standard_label {}

        .nameTeam_input {}
      }

      .selectCreators_container {
        padding: .75rem 1rem;
        border: 1px solid $lightgray-border;
        border-radius: 6px;
        max-width: 600px;
        height: calc(100% - 215px);
        min-height: 300px;

        @include media($break845) {
          height: 100%;
          padding: 0;
          max-width: 100%;
          border: none;
          overflow-y: inherit;
          border-radius: 0;
        }
      }

      .selectCreators_label {
        margin-bottom: .85rem;
        display: block;
        text-align: center;

        @include media($break845) {
          padding: 0 .75rem;
          text-align: left;
        }
      }

      .creatorList_container {
        height: calc(100% - 40px);

        @include media($break500) {
          height: calc(100% - 24px);
        }
        /* height: 100%; */
      }

      .searchCreators_container {
        padding-bottom: 1rem;

        @include media($break845) {
          padding: 0 .75rem 1rem;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
        }
      }

      .searchCreators_input {
        background: url('/images/style_guide/icon_search.svg') 10px center no-repeat;
        padding-left: 2em;
        background-size: 16px;
        max-width: 100%;
        width: 100%;;

        @include media($break845) {
          width: 100%;
          max-width: 100%;
        }
      }

      .creatorsContainer {
        overflow-y: auto;
        height: calc(100% - 50px);
        -webkit-overflow-scrolling: touch;

        @include media($break845) {
          overflow-y: auto;
          -webkit-overflow-scrolling: touch;
          height: calc(100% - 308px);
        }

        @include media($break500) {
          height: calc(100% - 300px);
        }
      }

      .creatorList {
        height: auto;

        @include media($break845) {
          padding: 0 1rem;
          height: auto;
        }
      }

      .creatorItem {
        padding: .75rem 0 0;
        padding-left: 3px;
      }

      .checkbox_control {
        margin-bottom: 0;
        padding: 0 0 0 30px;
      }

      .editTeam_actionBar {
        box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.05);
        padding: .75rem;
        display: none;

        @include media($break845) {
          display: block;
        }

        .selectedIndicator {
          float: left;
          padding-top: .3rem;
        }

        .editTeam_main_btn {
          float: right;
        }
      }

      .chockbox_container {}

      .creatorInfo_container {
        margin-left: .5rem;
        border-bottom: 1px solid $lightgray-border;
        padding-bottom: .5rem;
      }

      .creatorUsername {
        margin-bottom: 0 !important;
      }

      .ytStats_container {
        padding-top: 0;
      }

      .networkStats_txt {
        font-weight: 300;
      }

      .creatorStats_container {
        padding: 0;
      }

      .creatorAvatar_container {
        float: left;
        margin-right: .75rem;
        width: 40px;
      }

      .creatorAvatar {
        max-width: 100%;
      }

      .creatorTxt_container {
        float: left;
        width: calc(100% - 100px);
      }

      .creatorUsername {
        margin-bottom: .5rem;
        display: block;
      }
    }
  }
}
