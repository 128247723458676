.analyze_view {

  .core_subnav {
    background-color: white;

    .campaignBtn_img {
      float: left;
      margin-right: .4em;
      width: 27px;
      height: 27px;
      border-radius: 100px;
    }

    .core_links_container {

      @include media($break1130) {
        float: left;
      }
    }

    .core_links_list {
      margin-left: 0 !important;
      border-right: 1px solid $lightgray-border;

      @include media($break1130) {
        display: block;
      }

      @include media($break900) {
        display: none;
      }
    }

    .campaignSelection_dropdownBtn {
      width: 200px;

      @include media($break900) {
        width: auto;
      }

      .campaignBtn_title {
        margin-top: 2px;
        width: calc(100% - 33px);

        @include media($break900) {
          display: none;
        }
      }
    }

    .desktopHelp_center_link {

      @include media($break900) {
        display: none;
      }
    }

    .brandSummary_report_link {
      color: $dark-text;
      text-decoration: none;
      display: inline-block;
      float: left;
      padding: 7px 10px 9px 10px;

      &:hover {
        color: $revfluence-blue-hover;
      }

      @include media($break900) {
        display: none;
      }

      .brandSummary_logo {
        width: 27px;
        border-radius: 100px;
        margin-right: 4px;
        vertical-align: -3px;
      }

      &.active_core_link {
        color: $revfluence-blue-hover;
      }
    }

    .icon_report {
      width: 15px;
      vertical-align: -6px;
      margin-right: 2px;
    }

    .brandSummary_name_txt {
      vertical-align: -2px;
    }

    .small_coreLinks_list {
      display: none;

      @include media($break900) {
        display: block;
      }

      .more_item {

        &:hover {

          .more_dropdown_list {
            right: -129px;

            &:before, &:after {
              left: 18px;
            }

            .icon_help_center {
              display: none;
            }
          }
        }
      }
    }
  }
}
