.new_manage {

	.tool_view_container {
		height: 100%;

		.tool_main_container {
			margin: 0 0 50px;
			overflow-y: scroll;
			height: 100%;
			-webkit-overflow-scrolling: touch;
			vertical-align: 5px;

			@include media($break845) {
				margin: 1px 0 0;
				padding: 0.5rem 1.5rem;
			}

			/* Tools Header */
			.tools_header {
				position: relative;
				padding-bottom: 2.5rem;

				@include media($break630) {
					padding: 14px 0 0;
					display: none;
				}

				.toolBack_btn {
					background: none;
					border: none;
					padding: 10px 9px 10px 4px;
					float: left;
					display: none !important
				}

				.icon_toolBack {
					width: 10px;
					height: 17px;
				}

				.icon_tool {
					width: 27px;
					height: 27px;
					float: left;
					margin-right: 7px;
				}

				.productShipment_icon {
					margin-top: 5px;
				}

				.paymentTool_icon {
					margin-top: 5px;
				}

				.termsTool_icon {
					margin-top: 5px;
				}

				/* Help Center Link */
				.help_center_link {
					position: absolute;
					right: 6px;
					top: 5px;
				}

				.salesTracking_icon {
					margin-top: 2px;
				}

				.icon_contentApproval {
					margin-top: 5px;
					width: 25px;
					height: 25px;
					margin-right: 6px;
				}

				.icon_collabReview {
					margin-top: 3px;
				}

				.tool_name {
					float: left;
					width: calc(100% - 100px);

					@include media($break630) {
						display:none;
					}
				}

				.toolHeader_actions {
					position: absolute;
					top: 1px;
					right: 6px;
					z-index: 1000;

					.toolHeader_actions_list {
					}

					.toolHeader_actions_item {
						float: left;

						&:last-child {
							padding-right: 0;
						}
					}

					.toolHeader_btn {
						border-radius: 5px;
						padding: .5rem;
					}

					.emailTerms_btn {
						border-radius: 5px 0 0 5px;
						border-right: none;
					}

					.printTerms_btn {
						border-radius: 5px 5px 5px 5px;
						cursor: pointer;
					}

					.icon_toolHeader_action {}

					.icon_emailTerms {
						width: 24px;
						height: 18px;
					}

					.icon_printTerms {
						width: 20px;
						height: 18px;
					}
				}
			}

			.termsTools_header {

				.tool_name {
					/*width: calc(100% - 148px);*/
					width: calc(100% - 57px);
				}
			}
			/* End tools_header */
		}
		/* End tool_main_container */

		.collabTitle {

			@include media($break630) {
				padding-top: 3px;
			}
		}
	}
}
