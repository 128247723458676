/* CUSTOM DATE RANGE STARTER HTML
<div class="custom_date_range">
	<input type="text" placeholder="Enter a date&hellip;" class="date_input form_control">
	<img src="/images/style_guide/date_range_dash.svg" alt class="date_range_dash">
	<input type="text" placeholder="Enter a date&hellip;" class="date_input form_control">
	<input type="submit" value="Done" class="date_picker_btn btn_primary btn">
</div>
*/
.custom_date_range {

	.date_input {
		max-width: 175px;
		margin-right: 3px;
		display: inline;
		padding: .51em .7em;
	}

	.date_range_dash {
		vertical-align: 5px;
		margin-right: 3px;
	}

	.date_picker_btn {
		border-radius: 6px;
		padding: 7px 10px;
		vertical-align: 0;
	}
}

.character_count {
	font-size: .8rem;
	margin-top: .5rem;
	display: block;
	font-weight: 300;
}

.help_block {
	display: block;
	margin: 7px 0 1px;
	color: #737373;
	font-size: 1.25em;
  font-weight: 300;
}

/* <input type="text" class="form_control"> */
.form_control {
	font-size: .9rem;
	line-height: 1.5em;
	padding: .65rem 1rem;
	border-radius: 100px;
	font-weight: 400;
	outline: none;
	display: block;
	width: 100%;
	background-color: $aiq_lightblue_bg;
	border: none;

	&:hover, &:focus {
		background-color: $aiq_lightblue_bg_hover;
	}

  &.standard-input-error {
		@include standard-input-error;

  	&:focus {
      @include standard-input-error;
    }
  }
}

.new_date_input {
	background-image: url('/images/icons/outline/calendar.svg');
	background-position: 15px 10px;
	background-repeat: no-repeat;
	padding: .65rem 1rem .65rem 2.5rem;
}

textarea {
	border-radius: 6px !important;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important
}

/*
Tags Input Starter HTML

<div class="tagInput_container form_control">
	<ul class="enteredTags_list">
		<li class="enteredTag_item">
			<button class="removeTag_btn"><img src="/images/style_guide/icon_remove_white.svg" alt class="icon_remove"></button>
			<span class="enteredTag_txt">United States</span>
		</li>
	</ul>
	<input type="text" placeholder="Enter a tag&hellip;" class="tag_input">
</div>

*/
.tagInput_container {
	padding: 6px;

	.enteredTags_list {
		display: inline-block;
	}

	.enteredTag_item {
		background-color: $revfluence-blue;
		border-radius: 3px;
		display: inline-block;
		padding: 3px 6px 3px 3px;
		margin: 0 0 3px 0;
		cursor: pointer;

		&:hover {
			background-color: $revfluence-blue-hover;
		}
	}

	.removeTag_btn {
		background: none;
		border: none;
		outline: none;
		padding: 3px 2px 3px 4px;
		cursor: pointer;
	}

	.icon_remove {
		width: 11px;
	}

	.enteredTag_txt {
		color: white;
		font-size: .8rem;
	}

	.tag_input {
		display: inline-block;
		font-size: .85rem;
		font-weight: 300;
		border: none;
		padding: 5px;

		&:focus, &:active {
			outline: none;
		}
	}
}

tags-input {
	padding: 6px;

	.tag-list {
		display: inline-block;

		.selected {
			background-color: $revfluence-blue-hover;
		}
	}

	.tag-item {
		background-color: $revfluence-blue;
		border-radius: 3px;
		display: inline-block;
		padding: 3px 3px 3px 6px;
		margin: 0 3px 3px 0;
		cursor: pointer;

		&:hover {
			background-color: $revfluence-blue-hover;
		}

		span {
			color: white;
			font-size: .8rem;
		}

		.remove-button {
			background: url("/images/style_guide/icon_remove_white.svg") no-repeat center;
			background-size: 11px;
			border: none;
			outline: none;
			padding: 3px 2px 3px 4px;
			cursor: pointer;
			color: transparent;
		}
	}

	.icon_remove {
		width: 11px;
	}

	.input {
		display: inline-block;
		font-size: .85rem;
		font-weight: 300;
		border: none;
		background: none;

		&:focus, &:active {
			outline: none;
		}
	}

	auto-complete {

		.selected {
			background-color: lightgrey;
		}
	}
}

.form_group {
  margin-bottom: 15px;
}

.standard_label {
  font-size: 1.5em;
  line-height: 1.5em;
  font-weight: 300;
  display: inline-block;
  margin-bottom: 5px;
}

input[type=file] {
  display: block;
  font-size: 1.2em;
  font-family: inherit;
  line-height: inherit;
  font-weight: 300;
  margin-top: 1px;
}

.form_control[disabled], fieldset[disabled] .form_control {
  cursor: not-allowed;
}

.form_control[disabled], .form_control[readonly], fieldset[disabled] .form_control {
  background-color: #f4f4f4;
  opacity: 1;
}

.form_horizontal {

	.form_group {
		margin-bottom: 22px;
	}

	.multi_option_col {
		float: left;
		margin-top: 10px;
		padding: 0 15px;
	}

	.control_label {
		margin-bottom: 0;
		text-align: right;
		width: 200px;
		float: left;
		font-size: 1.5em;
		line-height: 1.5em;
		font-weight: 600;
		color: $dark-text;
		padding: 7px 15px 0 15px;
	}

    .vertical_control_label {
      margin-bottom: 0;
      font-size: 1.5em;
      line-height: 1.5em;
      font-weight: 600;
      color: $dark-text;
      margin-bottom: .25em;
      display: block;
    }

	.form_control_container {
		width: 450px;
		float: left;
		padding: 0 15px;
	}
}

.checkbox.disabled label, .radio_container.disabled label, fiedlset[disabled] .checkbox label, fieldset[disabled] .radio label {
  cursor: not-allowed;
}

// Custom Checkbox shit

/*
 * Checkboxes and radios

Starter HTML
<label class="checkbox_control checkbox">
 <input type="checkbox" value="">
   <span class="control-indicator"></span>
   <div class="text_container">
       Check me out
   </div>
</label>

 */

.checkbox_control {
	position: relative;
	display: block;
	color: $dark-text;
	font-size: 1.5em;
	line-height: 1.5em;
	font-weight: 400;
	padding: 1px 15px 0 30px;
	margin-bottom: 15px;
	cursor: pointer;
}

.checkbox_control input {
	position: absolute;
	opacity: 0;
	z-index: -1; /* Put the input behind the label so it doesn't overlay text */
}

.control-indicator {
	position: absolute;
	top: 2px;
	left: 0;
	display: block;
	width:  1.35rem;
	height: 1.35rem;
	line-height: 1rem;
	font-size: 65%;
	margin-right: .5rem;
	color: #eee;
	text-align: center;
	border: 1px solid #e4e4e4;
	background-color: white;
	background-size: 50% 50%;
	background-position: center center;
	background-repeat: no-repeat;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.control-indicator {
	color: #eee;
	border: 1px solid $aiq_border;
	background-color: white;
}

/* Hover state */
/* Uncomment if you need it, but be aware of the sticky iOS states.
.control:hover .control-indicator {
  color: #fff;
  background-color: #ccc;
}
*/

/* Focus */
.checkbox_control input:focus ~ .control-indicator {
  box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem $revfluence-blue;
}

/* Checked state */
.checkbox_control input:checked ~ .control-indicator {
  color: #fff;
  background-color: $revfluence-blue;
	border: none;
}

/* Active */
.checkbox_control input:active ~ .control-indicator {
  color: #fff;
	background-color: #84c6ff;
}

/* Checkbox modifiers */
.checkbox .control-indicator {
  border-radius: .15rem;
}
.checkbox input:checked ~ .control-indicator {
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
	background-size: 12px;
	background-position: center;
	background-repeat: no-repeat;
}

/*
Example HTML for making a radio

<label class="radio radio_inline">
  <input name="max_days_since_last_post" type="radio" value="7">
  <span class="control-indicator"></span>1
</label>
*/

/* Radio modifiers */
.radio .control-indicator {
  border-radius: 50%;
}
.radio input:checked ~ .control-indicator {
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTQsMUMyLjMsMSwxLDIuMywxLDRzMS4zLDMsMywzczMtMS4zLDMtM1M1LjcsMSw0LDF6Ii8+DQo8L3N2Zz4NCg==);
	background-size: 10px;
	background-position: center;
	background-repeat: no-repeat;
}

/* Alternately, use another character */
.control-x input:checked ~ .control-indicator {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iOHB4IiBoZWlnaHQ9IjhweCIgdmlld0JveD0iMCAwIDggOCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgOCA4IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0xLjQsMEwwLDEuNGwwLjcsMC43bDEuOCwxLjhMMC43LDUuN0wwLDYuNGwxLjQsMS40bDAuNy0wLjdsMS44LTEuOGwxLjgsMS44bDAuNywwLjdsMS40LTEuNEw3LjEsNS43DQoJTDUuMywzLjlsMS44LTEuOGwwLjctMC43TDYuNCwwTDUuNywwLjdMMy45LDIuNUwyLjEsMC43QzIuMSwwLjcsMS40LDAsMS40LDB6Ii8+DQo8L3N2Zz4NCg==);
}
.control-dash input:checked ~ .control-indicator {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iOHB4IiBoZWlnaHQ9IjhweCIgdmlld0JveD0iMCAwIDggOCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgOCA4IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0wLDN2Mmg4VjNIMHoiLz4NCjwvc3ZnPg0K)
}

.radio {
  font-size: .85rem;
  line-height: 1.5em;
  font-weight: 400;
  position: relative;
  display: inline-block;
  padding: 0 15px 0 30px;
  cursor: pointer;
}

.radio input {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

/* Focus */
.radio input:focus ~ .control-indicator {
  box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #3494ee;
}

/* Checked state */
.radio input:checked ~ .control-indicator {
  color: #fff;
  background-color: $revfluence-blue;
  border: none;
}

/* Active */
.radio input:active ~ .control-indicator {
  color: #fff;
  background-color: #84c6ff;
}

/* SELECT DROPDOWNS */

/*
Example HTML for making a select dropdown
<div class="select">
	<select>
		<option value="1">One</option>
		<option value="2">Two</option>
		<option value="3">Three</option>
		<option value="4">Four</option>
	</select>
</div>
*/

.select_dropdown {
  position: relative;
  display: block;
  margin-top: 0.5em;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  cursor: pointer;
}

select {
  /* Make sure the select is wider than the container so we can clip the arrow */
  width: 110%;
  max-width: 110%;
  min-width: 110%;
  /* Remove select styling */
  appearance: none;
  -webkit-appearance: none;
  /* Ugly Firefox way of doing it */
  -moz-appearance: window;
  text-indent: 0.01px;
  text-overflow: "";
  /* Magic font size number to prevent iOS text zoom */
  background:none;
  border: none;
  color: $dark-text;
  @include fontsize13;
  font-weight: 300;
  outline: none;
  /* Padding works surpringly well */
  padding: .5em 19% .4em .5em;
  margin:.2em;
  cursor: pointer;
}

/* This hides native dropdown button arrow in IE */
select::-ms-expand {
  display: none;
}

 /* Custom arrow - could be an image, SVG, icon font, etc. */
.select_dropdown:after {
	width: 15px;
	height: 15px;
	content: url(../images/form_elements/dropdown_arrow_icon.svg);
	background:  center no-repeat;
	background-size: 100%;
	font-size: .7em;
	padding:0;
	position: absolute;
	right: 2em;
	top: 2.1em;
	bottom: .3em;
	z-index: 1;
	/* This hack makes the select behind the arrow clickable in some browsers */
    pointer-events:none;
}

/* Hover style - tricky because we're clipping the overflow */
.select_dropdown:hover {
}

/* Focus style */
select:focus {
}

/* This hides focus around selected option in FF */
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

 /* These are just demo button-y styles, style as you like */
.select_button {
  border: 1px solid $lightgray_border;
  @include standard-radius;
  background: white; /* Old browsers */
  cursor: pointer;
}

/* Select */


/*

<div class="select">
	<select aria-label="Select menu example">
		<option selected>One</option>
		<option value="2">Two</option>
		<option value="3">Three</option>
		<option value="4">Four</option>
	</select>
</div>

*/

.select {
  position: relative;
  display: inline-block;
  color: #555;
}
.select select {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: .3rem 1.25rem .3rem .5rem;
  line-height: 1.5;
  color: $dark-text;
  font-weight: 600;
  font-size: 1.6em;
  background-color: white;
  border: 1px solid $lightgray_border;
  border-radius: .25rem;
  cursor: pointer;
  outline: 0;
  font-weight: 300;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
/* Undo the Firefox inner focus ring */
.select select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
/* Dropdown arrow */
.select:after {
  position: absolute;
  top: 50%;
  right: 0;
  display: inline-block;
  content: "";
  width: 0;
  height: 0;
  margin-top: -.15rem;
  pointer-events: none;
  border-top: .35rem solid;
  border-right: .35rem solid transparent;
  border-bottom: .35rem solid transparent;
  border-left: .35rem solid transparent;
}

/* Hover state */
/* Uncomment if you need it, but be aware of the sticky iOS states.
.select select:hover {
  background-color: #ddd;
}
*/

/* Focus */
.select select:focus {
  box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #0074d9;
}

/* Active/open */
.select select:active {
  color: #fff;
  background-color: #0074d9;
}

/* Hide the arrow in IE10 and up */
.select select::-ms-expand {
  display: none;
}

/* Media query to target Firefox only */
@-moz-document url-prefix() {
  /* Firefox hack to hide the arrow */
  .select select {
    text-indent: 0.01px;
    text-overflow: '';
    padding-right: 1rem;
  }

  /* <option> elements inherit styles from <select>, so reset them. */
  .select option {
    background-color: white;
  }
}

/* IE9 hack to hide the arrow */
@media screen and (min-width:0\0) {
  .select select {
    z-index: 1;
    padding: .5rem 1.5rem .5rem 1rem;
  }
  .select:after {
    z-index: 5;
  }
  .select:before {
    position: absolute;
    top: 0;
    right: 1rem;
    bottom: 0;
    z-index: 2;
    content: "";
    display: block;
    width: 1.5rem;
    background-color: #eee;
  }
  .select select:hover,
  .select select:focus,
  .select select:active {
    color: #555;
    background-color: #eee;
  }
}

.success_descrip {
  background-color: DarkSeaGreen;
  color: white;
  padding: 5px 11px;
  border-radius: 3px;
  margin-bottom: 12px;
}

.error {
	background-color: #dd4438;
  color: white;
  padding: 5px 11px;
  border-radius: 6px;
  margin-bottom: 12px;
	font-size: 1.3em;
	line-height: 1.5em;
}

.error_with_icon {
	text-align: center;

	.error_icon {
		vertical-align: -7px;
		margin-right: .15rem;
	}
}

.error_caption {
	display: block;
	font-size: .85rem;
	color: $aiq_dangerRed;
	font-weight: bold;
}

.error_input {
  &:focus {
    border: 1px solid red !important;
  }
  border: 1px solid red;
}

.success_text {
  color: #417505;
  display: block;
  font-weight: 600;
  font-size: 1.4em;
  margin-top: .5em;
}

.error_text {
  color: red;
  display: block;
  font-weight: 600;
  font-size: 1.4em;
  margin-top: .5em;
}

.form_error_text {
  margin-top: 1.0em;
}

.center_form_error_text {
  margin-top: 1.0em;
  text-align: center;
}

.error_descrip {
  background-color: red;
  color: white;
  padding: 5px 11px;
  border-radius: 3px;
  margin-bottom: 12px;
}

.checkbox_inline, .radio_inline {
  position: relative;
  display: inline-block;
  padding-left: 30px;
  margin-bottom: 0;
  vertical-align: middle;
  cursor: pointer;
}

.has_error {

  .vertical_control_label, .checkbox_control {
    color: red;
  }

  .form_control, .select select, .radio_control {
    border: 1px solid red;
  }
}


/*
Starter HTML - for Drag and Drop

<div class="dragDrop_wrapper">

    <div class="drag_drop_container">
        <form class="contentImg_form clearfix">

            <div class="active_dragdrop_indicator">
                <img src="/images/style_guide/active_dragdrop_icon.svg" class="active_dragdrop_icon" >
            </div>
            <ol class="drop_slot_list clearfix">
                <div class="drop_slot_container">
                    <li class="dropped_slot_item">
                        <div>
                            <button class="remove_content_btn btn no_shadow_focus"><img src="/images/style_guide/close_icon.svg" alt class="remove_content_icon"></button>
                        </div>
                        <label for="fileInput">
                            <div class="loadingIndicator_wrapper">
                                <img src="/images/style_guide/icon_loading_notext.gif" alt class="loading_icon">
                            </div>
                            <div class="dropped_img_wrapper">
                                <img src='#' class="drop_slot_img" />
                            </div>
                        </label>
                    </li>
                </div>
                <div class="drop_slot_container">
                    <label for="fileInput">
                        <li class="drop_slot_item"></li>
                    </label>
                </div>
            </ol>
            <div style='display:none'>
                <input id="fileInput" type="file" value="Change Profile" name="images">
            </div>
        </form>
    </div>
</div>

<div class="view_instagram_content">
    <ol class="content_list clearfix">
        <li class="content_list_item">
            <a href title class="download_link">
                <button class="download_btn btn no_shadow_focus"><img src="/images/style_guide/icon_arrow_down.svg" alt class="download_icon"></button>
                <img src="#" alt class="content_img">
            </a>
        </li>
    </ol>
</div>

DROPPED FILE


*/

.dragDrop_wrapper {

  .drag_drop_container {
    background: #F8F8F8;
    padding: 1rem 0 0 1rem;
    cursor: pointer;
    border: dashed 1px #C9C9C9;
    border-radius: 6px;
    display: inline-block;
    position: relative;
    height: 100%;

    .active_dragdrop_indicator {
      display:none;
      z-index: 10;
      border: solid 1px $revfluence-blue;
      background-color: rgba(244, 249, 255, .85);
      border-radius: 6px;
      opacity: 0;
      position: absolute;
      left: -1px;
      top: -1px;
      right: 0;
      bottom: 0;
      width: 101%;
      height: 101%;
      cursor: pointer;
      transition: all .15s ease-in-out;
      pointer-events: none;

      .active_dragdrop_icon {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -65px;
        margin-top: -34px;
        width: 130px;
      }
    }

    .show_dragdrop_indicator.active_dragdrop_indicator {
      opacity: 1;
    }

    .drop_slot_list {
    }

    .loadingIndicator_wrapper {
      position: absolute;
      width: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #F1F1F1;
      border-radius: 6px;

      .loading_icon {
        display: block;
        width: 25px;
        margin: 4rem auto 0;
      }
    }

    .drop_slot_container {
      float: left;
      width: 170px;
      margin-right: .85rem;
      margin-bottom: .85rem;

      &:last-child {
        margin-right: 0;
      }
    }

    .drop_slot_item {
      background: #F1F1F1 url('/images/style_guide/drag_drop_icon.png') center no-repeat;
      display: block;
      height: 154px;
      width: 154px;
      border-radius: 6px;
      background-size: 65%;
      cursor: pointer;

			&:hover, &:focus {
				background: #EBEBEB url('/images/style_guide/drag_drop_icon.png') center no-repeat;
				background-size: 65%;
			}
    }

    .remove_content_btn {}

    .dropped_slot_item {
      display: block;
      height: 154px;
      width: 154px;
      border-radius: 6px;
      background-size: 65%;
      cursor: pointer;
      position: relative;
    }

    .dropped_img_wrapper {
      height: 154px;
      width: 154px;
      border-radius: 6px;
      overflow: hidden;
      cursor:pointer;

      .drop_slot_img {
        width: 100%;
        max-width: 100%;
        border-radius: 6px;

        &:hover {
          opacity: .9;
        }
      }

      .drop_slot_vid {
        max-width: 100%;
        max-height: 100%;
      }
    }

		.dropped_file_wrapper {
			background-color: white;
			text-align: center;
			padding: 2.9rem 1rem 1rem;
			border: 1px dashed lightgray;

			&:hover {
				background-color: #FCFCFC;
			}

			.drop_slot_icon {
				padding-bottom: .4rem;
				width: 29px;
			}

			.droppedFile_txt {
				font-size: .8rem;
			}
		}

    .remove_content_btn {
      position: absolute;
      top: -10px;
      right: -10px;
      border: 1px solid $lightgray-border;
      border-radius: 100px;
      padding: 5px;
      background-color: white;
      z-index: 1;
			transition: all .15s ease-in-out;

			&:hover {
				transform:scale(1.13,1.13);
			}
    }

    .remove_content_icon {
      width: 20px;
      height: 20px;
    }

    .img_upload_label {
    }
  }
}


/* Keywords Input - Example HTML

<section class="keywordsInput_container">
	<div class="fake_input">
		<ul class="keywords_list">
			<li class="keywords_item" style="display:none;">
				<span class="keywords_text"></span>
				<a href="#" title="" class="remove_keyword_link"></a>
			</li>
			<li class="keywords_item has_input">
				<input type="text" placeholder="Enter Instagram usernames&hellip;" class="real_text_input">
			</li>
		</ul>
	</div>
</section>

*/

.keywordsInput_container {
	margin-bottom: 10px;

	.title_container {
		margin-bottom: 10px;
	}

	.section_title {
		font-size: 1.3em;
		color: #201e1e;
		line-height: 1.5em;
	}

	.fake_input {
		border: 1px solid #f2f2f2;
		border-radius: 4px;
		width: 100%;
		background-size: 15px;
		outline: none;
		font-weight: 300;
		background-color: #fbfbfb;
		position: relative;
		padding: 8px 8px 5px 8px;
	}

	.keywords_list {}

	.keywords_item {
		display: inline-block;
		margin-right: 3px;
		margin-bottom: 3px;

		&:last-child {
			margin-right: 0;
		}
	}

	.has_input {
		width: 100%;
	}

	.remove_keyword_link {
		background: $revfluence-blue url('/images/browse_creators/remove_keyword_icon.svg') center no-repeat;
		width: 7px;
		height: 30px;
		display: inline-block;
		padding: 14px;
		border-radius: 0 4px 4px 0;
		background-size: 10px;
		margin-left: -3px;
		border-left: 1px solid #94c2fb;
		&:hover {
			background: $revfluence-blue-hover url('/images/browse_creators/remove_keyword_icon.svg') center no-repeat;
			background-size: 10px;
		}
	}

	.keywords_text {
		color: white;
		font-weight: 500;
		font-size: 1.1em;
		display: inline-block;
		padding: 9px;
		background-color: $revfluence-blue;
		border-radius: 4px 0 0px 4px;
		height: 30px;
		vertical-align: 11px;
	}

	.real_text_input {
		font-size: 1.3em;
		color: #201e1e;
		border: none;
		color: $dark-text;
		outline: none;
		font-weight: 300;
		background: none;
		display: inline-block;
		padding: 5px 6px;
		width: 100%;
		vertical-align: 10px;
		height: 29px;
	}
}

.standardFieldset {
	padding: .3rem 0;
}

.slider_container {

	.ui-widget-content {
		border: none !important;
		background: #efefef !important;
	}

	.ui-slider-handle {
		background: white !important;
		border: 1px solid #c3c3c3 !important;
		width: 20px !important;
		height: 20px !important;
		top: -.6em !important;
		outline: none !important;
		border-radius: 100px !important;
		cursor: move !important;
		z-index: 1 !important;
	}

	.ui-slider-range {
		background: $revfluence-blue !important;
	}

	.ui-slider-horizontal {
		height: .5em !important;
		width: 94% !important;
		margin-left: 4px !important;
	}
}

.ui-datepicker {
	z-index: 5;

	td {
		font-size: 13px;
	}
}
/* Schedule demo form STARTER HTML

*/

.getStarted_form_wrap {

	header {
		padding: 2rem;
	}

	.main_title {
		color: $dark-text;
		font-size: 3.4em;
		line-height: 1.5em;
		font-weight: 300;
		text-align: center;
		padding: 1rem 0 .5rem;

		@include media($break500) {
			font-size: 1.6rem;
			font-weight: 400;
		}
	}

	.main_descrip {
		font-size: 1.7em;
		line-height: 1.5em;
		font-weight: 300;
		text-align: center;
	}

	.standard_label {
		font-weight: 500;
	}

	.get_started_form {
		max-width: 325px;
		margin: 0 auto;
		padding-bottom: 3rem;

		@include media($break340) {
			padding: 0 .5rem 3rem;
		}
	}

	.standard_label {
		font-weight: 500;
	}

	.formA {

		.get_started_button {
			margin-top: .75rem;
		}
	}

	.getStarted_loading_wrap {
		max-width: 300px;
		margin: 0 auto;
		text-align: center;
		padding: .75rem 0 3rem;

		.getStarted_icon_loading {
			width: 35px;
			display: block;
			margin: 0 auto 1rem;
		}

		.getStarted_loadingTxt {
			font-size: .8rem;
			font-weight: 400;
			display: block;
		}
	}

	.standard_fieldset {
		padding-bottom: .75rem;
	}

	.industryField {

		.dropdown_list {
			width: 200px;
			top: 38px;
			right: -5px;

			&:before, &:after {
				left: 167px;
			}
		}
	}

	.sizeField {

		.dropdown_list {
			top: 38px;
			right: -9px;

			&:before, &:after {

			}
		}
	}

	.influField {

		.dropdownField {
			top: 39px;
			right: -10px;
		}
	}

	.get_started_button {
		color: #FFF;
		font-weight: 500;
		border-radius: 2em;
		background-color: $revfluence-blue;
		text-decoration: none;
		padding: 0.5em 1.25em;
		font-size: 1.5em;
		line-height: 1.5em;
		border: none;
		display: block;
		margin: 0 auto;
		cursor: pointer;
		-webkit-appearance: none;

		@include media($break350) {
			width: 100%;
		}

		&:hover {
			background-color: $revfluence-blue-hover;
		}
	}

	.gdprTxt {
		font-size: .65rem;
		color: #898A8F;
		display: block;
		padding-top: 1.5rem;
		font-weight: 400;
		text-align: center;
	}

	.error_text {
		background-color: #ff5959;
		color: white;
		font-weight: 400;
		font-size: .8rem;
		line-height: 1.5em;
		padding: .25rem;
		border-radius: 4px;
		text-align: center;
		margin-bottom: 1rem;
	}

	.influencer_mention_container {
		text-align: center;
		padding: 2rem;
		background-color: #F2F5F8;

		h1 {
			font-size: 1.3em;
			line-height: 1.4em;
			color: $dark-text;
			font-weight: 600;
			margin-bottom: .5em;
		}

		h2 {
			font-size: 1.3em;
			line-height: 1.4em;
			color: $dark-text;
			font-weight: 600;
			margin-bottom: .5em;
		}

		p {
			font-size: 1.3em;
			line-height: 1.4em;
			color: $dark-text;
			font-weight: 300;
		}

		a {
			color: $revfluence-blue;
			text-decoration: none;

			&:hover {
				color: $revfluence-blue-hover;
				text-decoration: underline;
			}
		}
	}
}

/* The Toggle - the box around the slider */
.toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 23px;
}

/* Hide default HTML checkbox */
.toggle input {display:none;}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #E5E5E5;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $revfluence-blue;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.toggleTxt_wrap {
	display: inline-block;
	vertical-align: 7px;
	padding-left: .85rem;
	width: calc(100% - 53px);

	.icon_network {
		width: 16px;
		vertical-align: -3px;
	}

	.icon_ig {}

	.icon_yt {}

	.icon_fb {}

	.icon_tw {}

	.new_toggleTxt {
		font-weight: 600;
		font-size: .8rem;
		padding-left: .4rem;
		width: calc(100% - 19px);
		display: inline-block;
		vertical-align: -4px;
	}
}

.icon_text_input {
	background-image: url('/images/icons/outline/search.svg');
	background-position: 12px center;
	background-repeat: no-repeat;
	background-size: 20px;
	padding-left: 2.25rem;
}

.tag_input {
	background-image: url('/images/icons/outline/tag.svg')
}
