.analyze_view {
  background-color: white;

  .start_campaign_banner {
    margin-top: 99px;
  }

  .posts-spinner {
    height:20px;
  }

  .core_subnav {
    top: 50px;
  }

  .stats-spinner {
    height: 20px;
    margin: 0.4em 0;
    display: block;
  }

  .main_loading_section {
		margin: 30px 0;

		.spinner-image {
			margin: 0 auto;
		}
	}

  .create_campaign_btn {
    margin: 1rem 1.4rem;
  }
}
