.ig_demographics_popup {

  .modal-dialog {
    padding: 3rem;
    max-width: 800px;
    position: relative;

    @include media($break750) {
      padding: 2rem 1rem;
    }
  }

  .closeModal_btn {
    padding: .5rem;
    position: absolute;
    top: 1rem;
    right: 1rem;

    @include media($break750) {
      top: .25rem;
      right: .25rem;
    }
  }

  .insightClose_icon {
    width: 18px;
  }

  .mainTitle {
    padding-bottom: 1.85rem;
    text-align: center;

    @include media($break550) {
      font-size: 1.75rem;
    }
  }

  .subTitle {
    padding-bottom: .5rem;
    font-weight: 600;
    display:block;

    @include media($break550) {
      font-size: .95rem;
    }
  }

  .introDescrip {
    padding-bottom: 1.25rem;

    @include media($break550) {
      font-size: .95rem;
    }
  }

  .demographicActions_list {}

  .demographicActions_item {
    display: inline-block;

    @include media($break630) {
      display: block;
    }

    &:first-child {
      margin-right: .5rem;

      @include media($break630) {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
  }

  .confirmBtn {}

  .hintTxt {
    display: block;
    padding-top: 1.25rem;
  }

  .error {
    display: block;
    text-align: center;
    margin-bottom: 1.5rem;
    padding: .5rem .75rem .75rem;

    a {
      color: white;
      text-decoration: underline;
    }
  }
}


.fbAccess_popup {}

.connectIG_demogrpahics_popup {}

.fb_insights_popup {
  .modal-dialog {
    max-width: 1200px;
  }

  .insightPara {
    padding-bottom: 1.75rem;
    text-align: center;
    max-width: 710px;
    margin: 0 auto;
    font-size: 1.15rem;

    @include media($break550) {
      font-size: .95rem;
    }

    strong {
      font-weight: 600;
    }
  }

  .insightImg {
    display: block;
    border: 8px solid $lightgray-border;
    max-width: 100%;
    margin: 0 auto 2rem;
  }

  .fb_page_settings_img {
    max-width: 800px;

    @include media($break870) {
      max-width: 100%;
    }
  }

  .fb_biz_setup {
    max-width: 500px;

    @include media($break870) {
      max-width: 100%;
    }
  }

  .fb_biz_setup {}
}

.ig_insights_fail_modal {
  .modal-dialog {
    max-width: 600px !important;

    @include media($break375) {
      padding: 1.5rem 1rem 1rem !important;
    }
  }

  .main_title {
    margin-bottom: 0;

    .instagram_icon {
      padding-right: 0.25rem;
    }
  }

  .negative_flashNotice {
    margin-bottom: 1rem;
  }

  .insights_fail_descrip {
    margin-bottom: 1.25rem;
  }

  .actions_wrap {
    .btn_primary, .btn_default {
      display: inline-block;
      vertical-align: top;

      @include media($break450) {
        display: block;
        width: 100%;
      }
    }

    .btn_primary {
      margin-right: 0.5rem;

      @include media($break450) {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }

    .action_icon {
      vertical-align: -8px;
      margin-right: 0.15rem;
    }
  }

  .faq {
    padding-top: 1rem;
  }
}
