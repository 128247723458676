.manager_signup {
	@include outer-container;
	max-width: 110em;
	@include pad(0 5em);
	background: white url('../images/brand_onboarding/signup_bg_light.jpg') center top no-repeat;
	@include media($break600) {
		background: white;
		padding: 0 3em;
	}
	@include media($break350) {
		padding: 0 2em;
	}

	.header_container {
		padding: 2em 0 8em;
		overflow: hidden;
		@include media($break450) {
			padding: 2em 0 3em;
		}

		.revfluence_logo {
			display: block;
			width: 150px;
			height: 34px;
			text-indent: -9999px;
			background: url('/images/themes/aspireiq/aiq_logo.png') center no-repeat;
			background-size: 100%;
			float: left;

			&:focus {
				outline: 1px solid $revfluence-blue;
			}
		}

		.login_suggestion {
			float: right;
			text-align: center;
			color: $dark-text;
			@include fontsize15;

		  a {
		 		padding: 6px 18px;
			    display: inline-block;
			    vertical-align: middle;
			    background: transparent;
			    border: 1px solid $dark-text;
			    white-space: nowrap;
			    @include standard-radius;
			    cursor: pointer;
			    font-size: 14px;
			    color: #343d48;
			    text-decoration: none;
			    font-weight: 500;

		 		&:focus {
		 			outline: none;
		 		}

		 		&:hover {
		 			background: $dark-text;
	    			color: #fff;
		 		}
		 	}
		}

		.header_title_text {
			margin-top: 12em;
			@include media($break450) {
				margin-top: 6em;
			}

			.main_title {
				color: $dark-text;
				font-weight: 700;
				text-align: center;
				margin-bottom: .3em;
				@include title42;
				@include media($break600) {
					@include title25;
				}
			}

			.subtitle {
				@include title20;
				color: $dark-text;
				font-weight: 300;
				text-align: center;
				letter-spacing: .01em;
				@include media($break600) {
					@include fontsize15;
				}
			}
		}
	}

	.body_container {
		overflow: hidden;

		.signup_form {
			width: 435px;
			margin: 0 auto 4em;
			overflow: hidden;
			@include media($break600) {
				width: 100%;
			}

			.error_container {
				@include errors;
				width: 100%;
			}
		}

		.col_container {}

		.col1 {
			margin-bottom: 3em;
		}

		.col2 {
			margin-bottom: 3em;
		}

    /* Use for when there is only categories showing, style categories  under here */
    .col {

    	label {
    		text-align: center;
    		@include title40;
    		margin-bottom: 1em;
    		font-weight: 300;
    	}
    }

		fieldset {
			margin-bottom: 1em;
		}

		label {
			@include standard-label;
			color: $dark-text;
		}

		input[type="text"], input[type="password"], input[type="email"] {
			@include standard-input;
			width: 100%;
			border: 1px solid #afafaf;
		}

		.categories_container {}

		.categories_list {

			.category_button {
				padding: 1em;
				display: inline-block;
				cursor: pointer;
				width: 211px;
				margin-bottom: 1em;
				border: 1px solid #afafaf;
				@include standard-radius;
				@include media($break600) {
					width: 48%;
				}
				@include media($break450) {
					width: 100%;
				}

				&:hover {
					border: 1px solid $revfluence-blue;
					background-color: white;
				}

				.category_icon {
					display: inline-block;
					margin-right: 1em;
					vertical-align: -11px;
					width: 32px;
					height: 32px;
					background: url('../images/sign_up/category_icon_sprite.png');
                    @include hidpi(1.5) {
                        background: url('../images/sign_up/category_icon_sprite@2x.png');
                        background-size: 256px;
                    }
				}

				.womens_fashion_icon {
					background-position: 0 0;
				}

				.mens_fashion_icon {
					background-position: -32px 0;
				}

				.food_icon {
					background-position: -64px 0;
				}

				.fitness_icon {
					background-position: -160px 0;
				}

				.lifestyle_icon {
					background-position: -128px 0;
				}

				.beauty_icon {
					background-position: -96px 0;
				}

				.gaming_icon {
					background-position: -192px 0;
				}

				.kidsfamily_icon {
					background-position: -224px 0;
				}

				.category_text {
					display: inline-block;
					color: $dark-text;
					@include fontsize15;
				}

				&.active, &:focus {
						border: 1px solid $dark-text;
						background-color: $dark-text;
						outline: none;

						.category_text {
							color: white;
						}

						.womens_fashion_icon {
							background-position: 0 -32px;
						}

						.mens_fashion_icon {
							background-position: -32px -32px;
						}

						.food_icon {
							background-position: -64px -32px;
						}

						.fitness_icon {
							background-position: -160px -32px;
						}

						.lifestyle_icon {
							background-position: -128px -32px;
						}

						.beauty_icon {
							background-position: -96px -32px;
						}

						.gaming_icon {
							background-position: -192px -32px;
						}

						.kidsfamily_icon {
							background-position: -224px -32px;
						}
					}
				}
			}

		.margin_right {
			margin-right: 1em;
		}

		.category_icon {
			width: 32px;
			height: 32px;
			display: inline-block;
			margin-right: 1em;
		}

		.submit_button {
			display: block;
			width: 100%;
            		text-align: center;
			margin: 0 auto 2em;
		}
	}
}
