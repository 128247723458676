/* Starter HTML

<div class="standard_table2_wrapper">
  <table class="standard_table2">
    <thead>
      <tr>
        <th class="">Creator</th>
        <th class="">Spend</th>
        <th class="">Impressions</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><a href="#"><img src="/images/style_guide/sample_avatar.jpg" class="img_thumbnail"> Ali Fedotowski</a></td>
        <td>$13,432</td>
        <td>$241,342</td>
        <div class="exampleContent_cell" class="exampleContent_cell">
          <ul class="exampleContent_img_list">
            <li class="exampleContent_img_item">
              <img src="/images/style_guide/sample_ig_content2.jpg" alt class="img_content">
              <div class="exampleContent_popup exampleContent_popup_aboveHalf" style="display:none;">
                <img src="/images/style_guide/sample_ig_content2.jpg" alt class="exampleContent_img">
              </div>
            </li>
          </ul>
        </div>
      </tr>
      <tr>
        <td><a href="#"><img src="/images/style_guide/sample_avatar.jpg" class="img_thumbnail"> <img src="/images/style_guide/sample_avatar.jpg" class="creator_avatar"> Ali Fedotowski</a></td>
        <td>$13,432</td>
        <td>$241,342</td>
      </tr>
      <tr>
        <td><a href="#"><img src="/images/style_guide/sample_avatar.jpg" class="img_thumbnail"> <img src="/images/style_guide/sample_avatar.jpg" class="creator_avatar"> Ali Fedotowski</a></td>
        <td>$13,432</td>
        <td>$241,342</td>
      </tr>
    </tbody>
  </table>
</div>

*/

.standard_table2_wrapper {
  height: calc(100% - 147px);
  overflow-x: auto;

  ::-webkit-scrollbar {
      width:  6px;
      height: 10px;
  }

  ::-webkit-scrollbar-thumb {
      background: #red;
      border-radius: 100px;
  }

  ::-webkit-scrollbar-track {
      background: none;
  }

  .tableRow_loading_wrap {
    padding: .65rem .5rem .65rem 1rem;
    border-radius: 6px;
    background-color: white;
    position: absolute;
    left: 50%;
    bottom: 2rem;
    margin-left: -25px;
    z-index: 100;
    border: 1px solid lightgray;
    box-shadow: 1px 2px 7px rgba(0,0,0,.25);

    .tableRow_loading_icon2 {
      width: 70px;
      display: block;
    }
  }

  .img_content {
    max-width: 60px;
    border-radius: 4px;
  }

  .noData_wrap {
    margin: 0 !important;
  }
}

.standard_table2 {
  font-size: 1.5em;
  line-height: 1.5em;
  font-weight: 300;
  table-layout: fixed;
  margin: 0;
  white-space: nowrap;
  height: 100%;

  ::-webkit-scrollbar {
    width:  6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #D8D8D8;
    border-radius: 100px;
  }

  ::-webkit-scrollbar-track {
    background: none;
  }

  th {
    position: relative;
  }

  .thead_tooltip {
    position: absolute;
    right: 4px;
    top: 8px;
  }

  .nameTooltip_wrap {
    display: inline-block;

    .tooltip_black {
      text-align: center;
      padding: .4rem;
      width: 125px;
      right: auto;
      left: -20px;

      &:before, &:after {
        left: 15px;
        bottom: 29px;
      }

      .tooltip_txt {
        font-size: .8rem;
      }
    }
  }

  .noData_cell {
    background-color: $ultraLight_gray;
    text-align: center;
    color: #929292;
    font-weight: 400;
  }

  .xsm_col, .sm_col, .m_col, .l_col, .xl_col {

    .th_txtWrap {
      display: inline-block;
      max-width: 100%;
      vertical-align: -7px;
    }
  }

  /* NEW REGULAR CLASS SIZES. APPLY BOTH TD AND TH */
  .xsm_col {
    width: 150px;

    .nameTooltip_wrap {
      max-width: calc(100% - 19px);
    }
  }

  .sm_col {
    width: 200px;

    .nameTooltip_wrap {
      max-width: calc(100% - 19px);
    }
  }

  .m_col {
    width: 250px;

    .nameTooltip_wrap {
      max-width: calc(100% - 39px);
    }
  }

  .l_col {
    width: 300px;

    .nameTooltip_wrap {
      max-width: calc(100% - 39px);
    }
  }

  .xl_col {
    width: 350px;

    .nameTooltip_wrap {
      max-width: calc(100% - 18px);
    }
  }

  .cellContextual_menu {
    position: absolute;
    right: -149px;
    top: -29px;

    &:before {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 11px 11px 10px 0;
      border-color: transparent #FFFFFF;
      display: block;
      width: 0;
      z-index: 1;
      left: -11px;
      top: 29px;
    }

    &:after {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 11px 10px 11px 0;
      border-color: transparent $lightgray-border;
      display: block;
      width: 0;
      z-index: 0;
      left: -12px;
      top: 29px;
    }
  }

  .top_cellContextual_menu {
    top: -1px;

    &:before, &:after {
      top: 4px;
    }
  }

  .right_cellContextual_menu {
    left: -157px;
    right: auto;

    &:before {
      right: -11px;
      left: auto;
      border-width: 11px 0 10px 11px;
    }

    &:after {
      right: -12px;
      left: auto;
      border-width: 11px 0 10px 11px;
    }
  }

  .topRight_cellContextual_menu {
    left: -157px;
    right: auto;
    top: -3px;

    &:before {
      right: -11px;
      left: auto;
      border-width: 11px 0 10px 11px;
      top: 3px;
    }

    &:after {
      right: -12px;
      left: auto;
      border-width: 11px 0 10px 11px;
      top: 3px;
    }
  }

  a {
    font-size: .9rem !important;
    font-weight: 400 !important;
  }

  .actual_dropdown_list {
    max-height: 88px;
  }

  .lastMsg_cell {
    position: relative;
    padding: .2rem 0;
    display: flex;

    &:hover {

      .lastMsg_popup_aboveHalf, .lastMsg_popup_belowHalf {
        display: block;
      }
    }

    .lastMsg_popup_aboveHalf {
      display: none;
    }

    .lastMsg_popup_belowHalf {
      top: 28px;
      display: none;

      &:after {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 0 15px 15px;
        border-color: #FFFFFF transparent;
        display: block;
        width: 0;
        z-index: 1;
        top: -15px;
        left: 18px;
      }

      &:before {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 0 15px 15px;
        border-color: #ECECEC transparent;
        display: block;
        width: 0;
        z-index: 0;
        top: -16px;
        left: 18px;
      }
    }

    .lastMsg_popup_aboveHalf, .lastMsg_popup_belowHalf {
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.12);
      z-index: 6000;
      max-height: 250px;
      padding: .5rem;
      position: absolute;
      width: 350px;
      background: #FFFFFF;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      border: 1px solid $lightgray-border;

      .lastMsg_popup_scrollable {
        max-height: 150px;
        overflow-y: auto;
      }

      .lastMsg_popup_link {
      }

      .lastMsg_popup_avatar {
        width: 30px;
        border-radius: 100px;
        padding-right: .25rem;
        vertical-align: -7px;
      }

      .lastMsg_popup_txt {
        white-space: normal;
        font-size: .9rem;
      }
    }
  }

  .exampleContent_cell {

    /* EXAMPLE CONTENT POPUP - USE FOR CONTENT BELOW HALF THE VERTICAL VIEWPORT */
    .exampleContent_popup_belowHalf {
      display: none;
      position: absolute;
      width: 250px;
      height: 250px;
      padding: .5rem;
      background: #FFFFFF;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      z-index: 6000;
      bottom: 52px;
      left: -188px;
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.12);

      .exampleContent_img {
        max-width: 100%;
      }

      &:after {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 15px 15px 0;
        border-color: #FFFFFF transparent;
        display: block;
        width: 0;
        z-index: 1;
        bottom: -15px;
        left: 201px;
      }
    }

    .exampleContent_popup_right_belowHalf {
      display: none;
      position: absolute;
      width: 250px;
      height: 250px;
      padding: .5rem;
      background: #FFFFFF;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      z-index: 6000;
      bottom: 52px;
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.12);

      .exampleContent_img {
        max-width: 100%;
      }

      &:after {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 15px 15px 0;
        border-color: #FFFFFF transparent;
        display: block;
        width: 0;
        z-index: 1;
        bottom: -15px;
        left: 6px;
      }
    }

    /* EXAMPLE CONTENT POPUP FOR CELLS ABOVE THE HALF VERTICAL VIEWPORT */
    .exampleContent_popup_aboveHalf {
      display: none;
      position: absolute;
      width: 250px;
      left: -195px;
      top: 31px;
      padding: .5rem;
      background: #FFFFFF;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      z-index: 6000;
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.12);

      .exampleContent_img {
        max-width: 100%;
      }

      &:after {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 0 15px 15px;
        border-color: #FFFFFF transparent;
        display: block;
        width: 0;
        z-index: 1;
        top: -15px;
        left: 201px;
      }
    }

    .exampleContent_popup_right_aboveHalf {
      display: none;
      position: absolute;
      width: 250px;
      top: 31px;
      padding: .5rem;
      background: #FFFFFF;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      z-index: 6000;
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.12);

      .exampleContent_img {
        max-width: 100%;
      }

      &:after {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 0 15px 15px;
        border-color: #FFFFFF transparent;
        display: block;
        width: 0;
        z-index: 1;
        top: -15px;
        left: 6px;
      }
    }
  }

  .exampleContent_img_item {
    display: inline-block;
    vertical-align: -14px;
    margin-right: 5px;
    position: relative;

    &:hover {
      .exampleContent_popup {
        display: block;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    .img_content {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 4px;
    }
  }
}

.ads_reports {

  thead {

    tr {
      display: block;
      position: relative;
    }
  }

  .creator_avatar {
    border-radius: 100%;
    width: 35px;
  }

  tbody {
    display: block;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    scrollbar-face-color: #AFAFAF;
    scrollbar-track-color: none;

    @include media($break630) {
      background-color: #F8F8F8;
      padding: 0;
    }

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: #D8D8D8;
      border-radius: 100px;
    }

    ::-webkit-scrollbar-track {
      background: none;
    }

    tr {
      transition: all .15s ease-in-out;
      background-color: white;

      &:hover {
        background-color: #F0F7FF;
      }
    }
  }

  th {
    color: $dark-text;
    line-height: 1.4rem;
    font-weight: 400;
    padding-bottom: 1rem;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: .1rem;
    text-align: left;
    font-size: .85rem;

    &:hover {
      color: $revfluence-blue;
      background-color: #F9F9F9;
    }
  }

  .th_txtWrap {
    display: inline-block;
  }

  .active_th {
    color: $revfluence-blue;
    background-color: #F9F9F9;
  }

  td {
    border: none;
  }

  th, td {
    padding: 8px;
  }

  .sort_icon {
    width: 14px;
  }

  .sort_up {
    transform: rotate(180deg);
  }

  .img_thumbnail {
    width: 35px;
    border-radius: 3px;
  }

  tr:nth-child(even) {
    background-color: #F9F9F9;
  }

  a {
    text-decoration: none;
    color: $revfluence-blue;

    &:hover {
      color: $revfluence-blue-hover;
    }
  }

  .lastMsg_cell {
    position: relative;
    padding: .2rem 0;
    display: flex;

    &:hover {

      .lastMsg_popup_aboveHalf, .lastMsg_popup_belowHalf {
        display: block;
      }
    }

    .lastMsg_popup_aboveHalf {
      display: none;
    }

    .lastMsg_popup_belowHalf {
      top: 28px;
      display: none;

      &:after {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 0 15px 15px;
        border-color: #FFFFFF transparent;
        display: block;
        width: 0;
        z-index: 1;
        top: -15px;
        left: 18px;
      }

      &:before {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 0 15px 15px;
        border-color: #ECECEC transparent;
        display: block;
        width: 0;
        z-index: 0;
        top: -16px;
        left: 18px;
      }
    }

    .lastMsg_popup_aboveHalf, .lastMsg_popup_belowHalf {
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.12);
      z-index: 6000;
      max-height: 250px;
      padding: .5rem;
      position: absolute;
      width: 250px;
      background: #FFFFFF;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      border: 1px solid $lightgray-border;

      .lastMsg_popup_scrollable {
        max-height: 150px;
        overflow: auto;
      }

      .lastMsg_popup_link {
      }

      .lastMsg_popup_avatar {
        width: 30px;
        border-radius: 100px;
        padding-right: .25rem;
        vertical-align: -7px;
      }

      .lastMsg_popup_txt {
        white-space: normal;
        font-size: .9rem;
      }
    }
  }

  .exampleContent_cell {

    /* EXAMPLE CONTENT POPUP - USE FOR CONTENT BELOW HALF THE VERTICAL VIEWPORT */
    .exampleContent_popup_belowHalf {
      display: none;
      position: absolute;
      width: 250px;
      height: 250px;
      padding: .5rem;
      background: #FFFFFF;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      z-index: 6000;
      bottom: 52px;
      left: -188px;
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.12);

      .exampleContent_img {
        max-width: 100%;
      }

      &:after {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 15px 15px 0;
        border-color: #FFFFFF transparent;
        display: block;
        width: 0;
        z-index: 1;
        bottom: -15px;
        left: 201px;
      }
    }

    .exampleContent_popup_right_belowHalf {
      display: none;
      position: absolute;
      width: 250px;
      height: 250px;
      padding: .5rem;
      background: #FFFFFF;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      z-index: 6000;
      bottom: 52px;
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.12);

      .exampleContent_img {
        max-width: 100%;
      }

      &:after {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 15px 15px 0;
        border-color: #FFFFFF transparent;
        display: block;
        width: 0;
        z-index: 1;
        bottom: -15px;
        left: 6px;
      }
    }

    /* EXAMPLE CONTENT POPUP FOR CELLS ABOVE THE HALF VERTICAL VIEWPORT */
    .exampleContent_popup_aboveHalf {
      display: none;
      position: absolute;
      width: 250px;
      left: -195px;
      top: 31px;
      padding: .5rem;
      background: #FFFFFF;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      z-index: 6000;
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.12);

      .exampleContent_img {
        max-width: 100%;
      }

      &:after {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 0 15px 15px;
        border-color: #FFFFFF transparent;
        display: block;
        width: 0;
        z-index: 1;
        top: -15px;
        left: 201px;
      }
    }

    .exampleContent_popup_right_aboveHalf {
      display: none;
      position: absolute;
      width: 250px;
      top: 31px;
      padding: .5rem;
      background: #FFFFFF;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      z-index: 6000;
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.12);

      .exampleContent_img {
        max-width: 100%;
      }

      &:after {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 0 15px 15px;
        border-color: #FFFFFF transparent;
        display: block;
        width: 0;
        z-index: 1;
        top: -15px;
        left: 6px;
      }
    }
  }

  .exampleContent_img_item {
    display: inline-block;
    vertical-align: -14px;
    margin-right: 5px;
    position: relative;

    &:hover {
      .exampleContent_popup {
        display: block;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    .img_content {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 4px;
    }
  }
  /* THIS ONE IS DEPRECATED DON'T USE */
  .standard_table {
    font-size: 1.5em;
    line-height: 1.5em;
    font-weight: 300;
    margin: 0;

    th {
      border: none;
      color: #A0A0A0;
      text-align: left;
      font-weight: 300;

      &:hover {
        color: $revfluence-blue;
        background-color: #F5F5F5;
      }
    }

    .thActive {
      color: $revfluence-blue;
    }

    td {
      border: none;
    }

    th, td {
      padding: 8px;
    }

    tr:nth-child(even) {
      background-color: #F9F9F9;
    }

    a {
      text-decoration: none;
      color: $revfluence-blue;

      &:hover {
        color: $revfluence-blue-hover;
      }
    }
  }
}

/* STARTER HTML

*/
.standard_table2_container {
  padding: 0 1rem;

  .standard_table2 {
    font-size: 1.5em;
    line-height: 1.5em;
    font-weight: 300;
    margin: 0;
    white-space: nowrap;
    padding: 0 1rem;

    th {
      border: none;
      color: #A0A0A0;
      text-align: left;
      font-weight: 300;
    }

    a {
      font-weight: 400;
    }

    .interactable {

      a {
        cursor: pointer;
      }
    }

    .icon_invited {
      width: 24px;
      vertical-align: -6px;
    }

    .th_btn {
      text-transform: uppercase;
      color: #787878;
      font-size: .72rem;
      letter-spacing: .04rem;
      cursor: pointer;

      &:hover {
        color: $revfluence-blue;
        background-color: #FAFAFA;
      }
    }

    td {
      border: none;
      font-size: .9rem;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    th, td {
      padding: 8px;
    }

    tr {
      border-bottom: 1px solid $lightgray_border;
      position: relative;
    }

    .selected_tr {
      background-color: #F0F7FF;

      &:hover {
        background-color: #F0F7FF;
      }
    }

    tbody {

      tr {

        &:hover {
          background-color: #FAFAFA;
        }
      }
    }

    a {
      text-decoration: none;
      color: $revfluence-blue;

      &:hover {
        color: $revfluence-blue-hover;
      }
    }

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 100px;
      vertical-align: -14px;
      margin-right: .25rem;
    }

    .txt_name {
      font-weight: 600;
      overflow: hidden;
      display: inline-block;
      max-width: 144px;
      vertical-align: -5px;
    }

    .icon_sort {
      width: 14px;
      display: inline-block;
      vertical-align: -9px;
    }

    .contentPreview_item {
      display: inline-block;
      vertical-align: -14px;
      margin-right: 5px;
      position: relative;

      &:hover {

        .contentPreview_popup {
          display: block;
        }
      }
    }

    .thumbnailLink {}

    .approve_btn, .reject_btn {
      font-size: .9rem;
    }

    .approve_btn {
      margin-right: .25rem;
    }

    .lastMsg_popup_belowHalf {
      box-shadow: 1px 1px 8px rgba(0,0,0,0.12);
      z-index: 6000;
      max-height: 250px;
      padding: .5rem;
      position: absolute;
      width: 250px;
      background: #FFFFFF;
      border-radius: 10px;
      border: 1px solid #ECECEC;
      display: none;
      top: 35px;

      &:before {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 0 15px 15px;
        border-color: #ECECEC transparent;
        display: block;
        width: 0;
        z-index: 0;
        top: -16px;
        left: 18px;
      }

      &:after {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 0 15px 15px;
        border-color: #FFFFFF transparent;
        display: block;
        width: 0;
        z-index: 1;
        top: -15px;
        left: 18px;
      }
    }

    .lastMsg_cell {

      &:hover {

        .lastMsg_popup_belowHalf {
          display: block;
        }
      }
    }

    .img_content {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 4px;

      &:hover {
        opacity: .8;
      }
    }

    .exampleContent_img {
      max-width: 100%;
      width: 100%;
    }

    .contentPreview_popup {
      display: none;
      position: absolute;
      width: 250px;
      left: -195px;
      top: 44px;
      padding: .5rem;
      background: #FFFFFF;
      border-radius: 10px;
      z-index: 6000;
      box-shadow: 1px 1px 8px rgba(0,0,0,0.12);

      &:after {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 0 15px 15px;
        border-color: #FFFFFF transparent;
        display: block;
        width: 0;
        z-index: 1;
        top: -15px;
        left: 201px;
      }

      .img_content {
        width: 100%;
      }
    }

    .lastMsg_popup_scrollable {
      max-height: 150px;
      overflow: auto;
    }

    .acceptReject_wrapper {

      .btn {
        font-size: .8rem;
        padding: 5px 9px;
        border-radius: 4px;
      }

      .approve_btn {}

      .reject_btn {
        color: $dark-text;
      }
    }
  }
}

.friendlyTable {

}
