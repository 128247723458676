.choose_fb_account_popup {

  .modal-dialog {
    margin: 35px auto;
    width: 95%;

    .close_modal_btn {
      top: .5rem;
      right: .5rem;
    }
  }

  .choose_fb_header {
    text-align: center;
    padding: 1.25rem 1rem 1rem;

    .mainTitle_container {
      text-align: center;
    }

    .facebook_icon {
      margin-right: .25rem;
      vertical-align: -2px;
      width: 24px;

      @include media($break550) {
        width: 19px;
      }
    }

    .mainTitle_txt {

      @include media($break550) {
        font-size: 1.25rem;
      }
    }

    .subTitle_txt {

      @include media($break550) {
        font-size: 1rem;
      }
    }
  }

  .fbAccounts_container {

    .fbAccounts_list {
      max-width: 500px;
      margin: 0 auto;
      border: 1px solid $lightgray-border;
      border-radius: 7px;
      height: 200px;
      overflow-y: auto;

      @include media($break550) {
        margin: 0 .5rem;
      }

      > li {
        padding: .5rem 0;
        border-bottom: 1px solid $lightgray-border;
        margin: 0 .65rem;

        &:last-child {
          border-bottom: none;
        }
      }

      .account_avatar {
        border-radius: 100px;
        width: 40px;
        vertical-align: -14px;
        margin-right: .5rem;

        @include media($break550) {
          width: 30px;
          vertical-align: -11px;
        }
      }

      .text_container {

        @include media($break550) {
          font-size: .87rem;
        }
      }

      .fbAccounts_btn {
        margin-bottom: 0;
      }

      .reach_amount {}

      .control-indicator {
        top: 8px;

        @include media($break550) {
          top: 3px;
        }
      }
    }

    .username {
      font-weight: 600;
    }
  }

  .actions_container {
    padding: 1.25rem 0 1.25rem;

    .finish_btn {
      display: block;
      margin: 0 auto;
      width: 190px;
    }
  }
}
