.manage_inbox_body {
	overflow-y: hidden;

	@include media($break1000) {
		overflow-y: initial;
	}
}

.new_manage {

	 .inbox_list_container {
		float: left;
		width: calc(100% - 238px);
		height: 100%;
		padding-top: 8px;

		@include media($break1000) {
			width: 100%;
			float: none;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
		}

		@include media($break630) {
			background-color: white;
		}

		/* Begin Inbox Table table stuff */
		.inbox_list_table {
			height: 100%;
			display: block;
		}

		.inbox_table_header {
			text-align: left;
			display: block;
			padding: .25rem 0 .5rem;

			.h3 {
				display: inline-block;
				margin-right: .5rem;

				@include media($break1180) {
					display: block;
					padding-bottom: .5rem;
				}

				@include media($break1000) {
					display: inline-block;
					width: auto;
				}
			}

			.tooltip {
				z-index: 9999;
				padding: .35rem 1rem .35rem 1rem;
				width: auto;
				left: 50%;
				transform: translateX(-50%);
				bottom: 44px;

				&:before, &:after {
					display: none;
				}

				.tooltip_txt {
					white-space: pre;
				}
			}

			.relationshipCount {
				color: #C7C7C7;
				font-weight: 300;
			}

			.specialActions_container {
				display: inline-block;
				vertical-align: 8px;
				position: relative;
				padding-left: .25rem;

				.specialActions_disabler {
					position: absolute;;
					z-index: 2;
					left: 0;
					top: 0;
					right: 0;
					bottom: 0;
					background-color: rgba(255,255,255,.5);
					width: 101%;
				}

				@include media($break1180) {
					display: block;
				}
			}

			.specialActions_list {
				display: inline-block;
			}

			.specialActions_list {
				margin-right: .4rem;
				border-right: 1px solid $lightgray-border;
				padding-right: .5rem;

				@include media($break925) {
					margin-right: 0;
					padding-right: 0;
					border-right: none;
					margin-bottom: .5rem;
				}
			}

			.inboxActions_list {
				display:inline-block;
				position: relative;
			}

			.inboxActions_item {
				display: inline;
				margin-right: .25rem;

				&:last-child {
					margin-right: 0;
				}
			}

			.inboxAction_btn {
				width: 41px;
				height: 41px;
				border: none;
				background: none;
				color: $aiq_black;
				border-radius: 100px;
				padding: 0;

				&:hover, &:focus {
					border: none;
					background-color: $aiq_lightblue_bg;
				}

				&:active {
					background: none;
				}

				.txt_inboxAction {
					color: $aiq_black;
					font-size: .8rem;
					letter-spacing: .01rem;
				}
			}

			.withLabel {
				width: auto;
				height: auto;
				margin-right: 16px;
				padding: 4px 14px;

				&:last-child {
					margin-right: 0;
				}
			}

			.inboxAction_label {
				font-weight: 600;
				vertical-align: 2px;
				font-size: 12px;
				margin-left: 4px;
			}

			.message_button, .terms_button {

				.icon_msg {
					vertical-align: -5px;
				}

				.inboxAction_label {
					vertical-align: 0;
				}
			}

			.terms_button {

				.icon_sendTerms {
					vertical-align: -9px;
				}
				
				.inboxAction_label {
					vertical-align: -2px;
				}
			}

			.icon_inboxAction {
				width: 21px;
				height: 21px;
			}

			.icon_addGrp {
				width: 28px;
				height: 21px;
				vertical-align: -3px;
			}

			.icon_edit_group {
				width: 12px;
				margin-right: 1px;
				vertical-align: -1px;
			}

			.icon_deleteGroup {
				vertical-align: -1px;
				margin-right: 1px;
			}

			.icon_dismiss {
				width: 16px;
			}

			.icon_download {
				width: 19px;
				height: 19px;
			}

			.icon_save {
				vertical-align: -2px;
			}

			.icon_sendTerms {
				width: 19px;
				height: 23px;
			}

			.icon_markasread {
				width: 21px;
			}

			.icon_archive {
				vertical-align: -3px;
			}

			.icon_msg {
				width: 20px;
				height: 20px;
			}

			.txt_inboxAction {
				color: $dark-text;
				font-size: .8rem;
			}

			.addGrp_item {
				position: relative;

				.addGrp_dropdown {
					position: absolute;
					right: 0;
					top: 24px;
					max-height: 600px;
					overflow-y: scroll;

					&:before {
						left: 197px;
					}

					&:after {
						left: 197px;
					}
				}
			}
		}

		.inbox_table_container {
			height: calc(100% - 61px);
			overflow: auto;

			@include media($break1180) {
				height: calc(100% - 109px);
			}

			@include media($break1000) {
				height: calc(100% - 122px);
			}

			@include media($break580) {
				height: calc(100% - 155px);
			}

			@include media($break400) {
				height: calc(100% - 185px);
			}
		}

		.inbox_table {
			display: block;
			/* table-layout: fixed; */
			white-space: nowrap;
			/* width: 3000px; */

			&:focus {
				outline: none;
			}

			.checkbox_control {
				padding: 0 15px 0 8px;
				display: block;
				position: relative;
				margin-top: -13px;
				top: -4px;
			}

			.control-indicator {
				margin-right: 0;
			}

			.new_activity_indicator {}

			.div_name {
				overflow: hidden;
				position: relative;

				&.overflow_visible {
					overflow: visible;
				}
			}

			.icon_talentAgency {
				width: 22px;
				margin-top: -4px;
			}

			.talentAgency_tooltip {
				width: 103px;
				left: -42px;
				bottom: -49px;
				font-weight: 300;

				&:after {
					content: '';
					position: absolute;
					border-style: solid;
					border-width: 0 13px 13px;
					border-color: #000 transparent;
					display: block;
					width: 0;
					z-index: 1;
					top: -9px;
					left: 40px;
					bottom: 74px;
				}

				&:before {
					display: none;
				}

				.tooltip_txt {
					font-weight: 400;
				}
			}

			.liked_indicator_icon {
				width: 27px;
				position: absolute;
				left: 22px;
				top: 21px;
			}

			.icon_paymentIndicator {
				position: absolute;
				left: 23px;
				top: -4px;
				z-index: 2;
			}

			.txt_name {
				font-weight: 600;
				overflow: hidden;
				display: inline-block;
				max-width: 144px;
				vertical-align: -5px;
			}

			.avatar {
				width: 40px;
				height: 40px;
				border-radius: 100px;
				vertical-align: -14px;
				margin-right: .25rem;
			}

			.lastMsg_cell {}

			thead, tbody {}

			tr {
				border-bottom: 1px solid $lightgray-border;
				position: relative;
			}

			/* Selected Rows */
			.selected_row {
				background-color: #F0F7FF;

				&:hover {
					background-color: #F0F7FF;
				}
			}

			/* Read Rows */
			.read_row {
				background-color: #F7F7F7;

				&:hover {
					background-color: #F7F7F7;
				}

				/* .lastMsg_txt, .txt_name, .stage_btn, .totalEngage_txt, .approxPricing_txt, .timeinStage_txt, .lastUpdated_txt {
					font-weight: 300;
				}*/

				span, button, .lastMsg_popup_txt {
					font-weight: 300;
				}

				span {}

				&.selected_row {
					background-color: #F0F7FF;

					&:hover {
						background-color: #F0F7FF;
					}
				}
			}

			td, th {
				padding: .5rem;
				text-align: left;
				max-width: 205px;
			}

			td {
				font-size: .9rem;
				white-space: nowrap;
				text-overflow: ellipsis;

				.checkbox_control {}
			}

			th {
				text-transform: uppercase;
				color: #787878;
				font-size: .72rem;
				letter-spacing: .04rem;
				cursor: pointer;

				.checkbox_control {}

				&:hover {
					color: $revfluence-blue;
					background-color: $ultraLight_gray;
				}
			}

			tbody {

				span, button, .lastMsg_popup_txt {
					font-weight: 600;
				}

				tr {
					cursor: pointer;

					&:hover {
						background-color: $ultraLight_gray;
					}
				}
			}


			.lastMsg_cell {
				position: relative;

					&:hover {

						.lastMsg_popup_aboveHalf, .lastMsg_popup_belowHalf {
							display: block;
						}
					}

					.lastMsg_popup_aboveHalf {
						display: none;
					}

					.lastMsg_popup_belowHalf {
						top: 41px;
						display: none;

						&:after {
							content: '';
							position: absolute;
							border-style: solid;
							border-width: 0 15px 15px;
							border-color: #FFFFFF transparent;
							display: block;
							width: 0;
							z-index: 1;
							top: -15px;
							left: 18px;
						}

						&:before {
							content: '';
							position: absolute;
							border-style: solid;
							border-width: 0 15px 15px;
							border-color: #ECECEC transparent;
							display: block;
							width: 0;
							z-index: 0;
							top: -16px;
							left: 18px;
						}
					}

					.lastMsg_popup_aboveHalf, .lastMsg_popup_belowHalf {
						box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.12);
						z-index: 6000;
						max-height: 250px;
						padding: .5rem;
						position: absolute;
						width: 250px;
						background: #FFFFFF;
						-webkit-border-radius: 10px;
						-moz-border-radius: 10px;
						border-radius: 10px;
						border: 1px solid $lightgray-border;

						.lastMsg_popup_scrollable {
							max-height: 150px;
							overflow: auto;
						}

						.lastMsg_popup_link {}

						.lastMsg_popup_avatar {
							width: 30px;
							border-radius: 100px;
							padding-right: .25rem;
							vertical-align: -7px;
						}

						.lastMsg_popup_txt {
							font-size: 1rem;
							font-size: .9rem;
							white-space: normal;
							font-weight: 300;
						}
					}
				}

			 .exampleContent_cell {

				 	/* EXAMPLE CONTENT POPUP - USE FOR CONTENT BELOW HALF THE VERTICAL VIEWPORT */
					.exampleContent_popup_belowHalf {
						display: none;
						position: absolute;
						width: 250px;
						height: 250px;
						padding: .5rem;
						background: #FFFFFF;
						-webkit-border-radius: 10px;
						-moz-border-radius: 10px;
						border-radius: 10px;
						z-index: 6000;
						bottom: 52px;
						left: -188px;
						box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.12);

						.exampleContent_img {
							max-width: 100%;
						}

						&:after {
							content: '';
							position: absolute;
							border-style: solid;
							border-width: 15px 15px 0;
							border-color: #FFFFFF transparent;
							display: block;
							width: 0;
							z-index: 1;
							bottom: -15px;
							left: 201px;
						}
					}

					/* EXAMPLE CONTENT POPUP FOR CELLS ABOVE THE HALF VERTICAL VIEWPORT */
					.exampleContent_popup_aboveHalf {
						display: none;
						position: absolute;
						width: 250px;
						left: -195px;
						top: 31px;
						padding: .5rem;
						background: #FFFFFF;
						-webkit-border-radius: 10px;
						-moz-border-radius: 10px;
						border-radius: 10px;
						z-index: 6000;
						box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.12);

						.exampleContent_img {
							max-width: 100%;
						}

						&:after {
							content: '';
							position: absolute;
							border-style: solid;
							border-width: 0 15px 15px;
							border-color: #FFFFFF transparent;
							display: block;
							width: 0;
							z-index: 1;
							top: -15px;
							left: 201px;
						}
					}
			 }

			.select_cell {
			}

			.activity_cell {
				padding: 0;
			}

			.active_header {
				font-weight: 600;
				color: $dark-text;
			}

			.lastMsg_txt {
				overflow: hidden;
				padding: 10px 0;
			}

			.icon_sortDown {
				width: 16px;
				padding-left: .25rem;
			}

			.icon_sortUp {
				transform: rotate(180deg);
				width: 16px;
				padding-left: .25rem;
			}

			.rising {
				width: 16px;
				path: green;
				padding-left: .25rem;
			}

			.falling {
				transform: rotate(180deg);
				width: 16px;
				padding-left: .25rem;
				path: red;
			}

			.icon_sortUp {}

			.icon_sortDirection {
				display: none;
				&.icon_sortDown {
					display: inline-block;
				}
			}

			.network_list {}

			.network_li {
				display: inline;
				margin-right: .25rem;

				&:last-child {
					margin-right: 0;
				}

				a {
					text-decoration: none;
				}
			}

			.icon_network {
				width: 17px;
				height: 17px;
				object-fit: fill;
			}

			.icon_instagram {
				vertical-align: -1px;
			}

			.icon_youtube {}

			.icon_vine {
				width: 14px;
			}

			.icon_twitter {}

			.icon_facebook {
				width: 15px;
			}

			.icon_googleplus {
				width: 21px;
			}

			.icon_snapchat {
				vertical-align: -1px;
				width: 16px;
			}

			.icon_pinterest {
				width: 13px;
			}

			.icon_blog {
				vertical-align: -2px;
			}

			.star_reviews_list {
				display: inline-block;
				vertical-align: -6px;
				width: 120px;
			}

			.exampleContent_img_list {}

			.exampleContent_img_item {
				display: inline-block;
				vertical-align: -14px;
				margin-right: 5px;
				position: relative;

				&:hover {

					.exampleContent_popup {
						display: block;
					}
				}

				&:last-child {
					margin-right: 0;
				}
			}

			.stage_btn {
				font-size: .9rem;
				text-align: left;
				padding: 0;
				border: none;
				vertical-align: 0;
			}

			.img_content {
				width: 40px;
				height: 40px;
				object-fit: cover;
				border-radius: 4px;
			}

			.reviews_list {}

			.reviews_item {
				display: inline;
			}

			.icon_reviewStar {}

		}

		/* Non-table inbox list */
		.inbox_list_header {
			padding-bottom: 13px;
			border-bottom: 1px solid $lightgray-border;
			position: relative;

			@include media($break1000) {
				display: block;
				text-align: center;
				padding: 0 60px 10px;
			}
		}

		/* Help Center Link */
		.help_center_link {}

		.desktop_helpCenter_link {
			position: absolute;
			right: 13px;
			top: 11px;

			@include media($break1000) {
				display: none;
			}
		}

		.mobile_helpcenter_link {
		}

		.groupActions {
			display: none;
			padding-left: .75rem;
			float: left;
			padding-top: .54rem;

			@include media($break1000) {
				display: none !important;
			}

			.sendMass_msg_btn, .editTeam_btn, .removeTeam_btn {
				float: left;
				padding: 6px 15px;
			}

			.sendMass_msg_btn {
				margin-right: .25rem;
				line-height: 1.15rem;

				.sendMass_msg_icon {
					margin-right: .4rem;
					vertical-align: -4px;
					width: 18px;
				}

				.sendMass_msg_txt {
					font-size: .8rem;
				}
			}

			.editTeam_btn {
				margin-right: .25rem;
				line-height: 1.15rem;

				.editTeam_icon {
					margin-right: .4rem;
					vertical-align: -2px;
				}

				.editTeam_btn_txt {
					font-size: .8rem;
				}
			}

			.removeTeam_btn {
				line-height: 1.15rem;

				.removeTeam_icon {
					margin-right: .4rem;
					vertical-align: -1px;
					width: 11px;
				}

				.removeTeam_btn_txt {
					font-size: .8rem;
				}
			}
		}

		.groupOptions {
			display: none !important;
			position: absolute;
			right: 0;
			top: 5px;

			@include media($break1000) {
				display: block !important;
			}

			@include media($break350) {
				top: 0;
			}

			&:hover {

				.dropdown_list {
					display: block;
					right: 5px;
				}
			}

			.groupOptions_btn {
				display: none;
				background: none;
				padding: .75rem;
			}

			.dropdownIndicator_icon {
				display: block;
			}
		}

		.labels_list {
			display: inline-block;
			padding-top: .5rem;

			.label_text {
				font-size: 1.1em;
			}

			.remove_button {
				vertical-align: 0;
				display: none;
			}
		}

		.active_collabs_title {
			margin: 0 auto;

			@include media($break550) {
				font-size: 2.6em;
				line-height: 1.7em;
			}

			@include media($break350) {
				font-size: 2.1em;
			}

			.activeCollection_name {
				max-width: 340px;
				float: left;

				@include media($break1000) {
					float: none;
					margin: 0 auto;
				}

				@include media($break445) {
					max-width: 250px;
				}

				@include media($break375) {
					max-width: 195px;
				}
			}
		}

		.toggle_filters_button {
			display: none;
			left: 8px;
			top: -8px;

			@include media($break1000) {
				display: block;
			}

			@include media($break345) {
				top: -8px;
			}

			&.inbox_table_toggle_filters_button {
				position: relative;

				@include media($break1000) {
					display: inline-block;
					margin-right: 1.5em;
				}
			}

			.filters_alert_icon {
				position: absolute;
				width: 24px;
				height: 24px;
				top: 2px;
				right: 1px;
			}
		}


		/* Old Inbox List **SAVE THIS FOR MOBILE** */
		.inbox_list {
			height: calc(100% - 62px);
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;

			@include media($break1000) {
				height: auto;
				overflow-y: initial;
				display: block;
			}

			.label_item {
				border: 1px solid #EDEDED;
			}
		}

		.inbox_list_item {
			overflow: hidden;
			padding: 9px 10px 16px;
			border-bottom: 1px solid #ededed;
			position: relative;
			display: block;
			text-decoration: none;
			color: $dark-text;
			cursor: pointer;

			&:last-child {
				border-bottom: none;
			}

			@include media($break550) {
				padding: 9px 10px 14px;
			}
		}

		.loadMore_container {
			padding: 1rem;

			.loadMore_btn {
				width: 200px;
				display: block;
				margin: 0 auto;
			}
		}

		.readInbox_listItem {
			background: #f9f9f9;

			.user_name, .alert_text, .networkStats_txt {
				font-weight: 300;
			}

			.last_message {
				font-weight: 300;
				color: #7C7C7C;
			}
		}

		.avatar_container {
			width: 40px;
			height: 40px;
			float: left;
			margin: 4px 19px 0 0;
			position: relative;
		}

		.icon_paymentIndicator {
			position: absolute;
			right: -9px;
			top: -9px;
			z-index: 2;
		}

		.featuredCreator_badge, .liked_badge {
			position: absolute;
			top: -9px;
			left: -5px;
			width: 23px;
			height: 23px;
		}

		.featured_text {
			display: block;
			text-align: center;
			color: $dark-text;
			font-size: .9em;
			font-weight: 800;
			margin-top: .3em;
		}

		.user_avatar {
			max-width: 100%;
			display: block;
			border-radius: 100px;
			height: 100%;
		}

		.info_container {
			float: left;
			width: calc(100% - 80px);
		}

		.info_header {
			overflow: hidden;
			margin-bottom: 4px;
		}

		.user_name {
			max-width: 180px;
			display: inline-block;
			margin-right: 10px;
			line-height: 2em;
			float: none;
		}

		.last_message {
			font-weight: 600;
		}

		.alerts_container {
			margin: 14px 0 0;

			@include media($break1000) {
				margin: 8px 0 0;
			}
		}

		.alert_item {
			background-color: $revfluence-blue;
			text-decoration: none;
			color: white;
			border-radius: 100px;
			padding: 2px 10px 3px 5px;
			margin-right: 7px;
			display: inline-block;

			@include media($break1000) {
				text-decoration: none;
				color: $dark-text;
				background: none;
				margin-bottom: 3px;
			}
		}

		.alert_icon {
			display: inline-block;
			margin-right: 2px;
			background: url('/images/new_manage/alert_icon.svg') center no-repeat;
			width: 18px;
			height: 18px;
			vertical-align: -2px;

			@include media($break1000) {
				background: url('/images/new_manage/needs_attention_icon2.svg') center no-repeat;
			}
		}

		.alert_text {
			display: inline-block;
			font-size: 1.25em;
			vertical-align: 2px;
			margin-right: 3px;
			font-weight: 600;
		}

		.disclosure_icon {
			width: 7px;
			display: none;

			@include media($break1000) {
				display: inline-block;
			}
		}

		.likedIndicator {
			display: inline-block;
		}

		.timestamp {
			position: absolute;
			right: 10px;
			top: 16px;
			font-size: 1.25em;
			color: #B1B1B1;
			font-weight: 300;
		}

		.noMessages_container {
			display: block;
			margin: 3rem auto;
			max-width: 410px;

			.connect_btn {
				font-size: 1.85em;
			}
		}
	}

	.hasSubNav {

		.inbox_table_container {
			height: calc(100% - 62px);

			@include media($break1180) {
				height: calc(100% - 112px);
			}

			@include media($break920) {
				height: calc(100% - 125px);
			}

			@include media($break580) {
				height: calc(100% - 155px);
			}

			@include media($break395) {
				height: calc(100% - 185px);
			}
		}
	}
}
