.vine_signin_popup {

  .modal-dialog {
    padding: 2rem;
    max-width: 500px;

    @include media($break575) {
      padding: 1rem;
    }
  }

  .vineSignin_header {
    position: relative;
    text-align: center;
    margin-bottom: 1rem;

    @include media($break335) {
      padding-top: 1rem;
    }

    .back_button {
      padding: .25rem;
      position: absolute;
      left: 0;
      top: .5rem;
      background: none;

      @include media($break400) {
        left: -10px;
        top: -4px;
      }
    }

    .back_icon {
      width: 27px;

      @include media($break400) {
        width: 22px;
      }
    }

    .vineSignin_title {
      margin-bottom: .25rem;

      @include media($break575) {
        font-size: 1.75rem;
      }
    }

    .vine_icon {
      width: 25px;
      margin-right: .5rem;

      @include media($break575) {
        width: 20px;
      }
    }

    .introDescrip {
      font-size: 1.25rem;
      font-weight: 300;

      @include media($break575) {
        font-size: 1rem;
      }
    }
  }

  .vineSignin_form {
    max-width: 300px;
    margin: 0 auto 1rem;

    .standard_fieldset {
      margin-bottom: .25rem;
    }

    .form_control {}

    .signin_btn {
      margin-top: .5rem;
    }

    .error {
      margin: .5rem 0 0;
    }
  }

  .full_disclosure {
    text-align: center;
  }
}
