.new_manage {

  .create_newTeam_container {

    .create_team_form {
      height: 100%;
    }

    .creatorsContainer {

      @include media($break845) {
        height: calc(100% - 275px) !important;
      }
    }
  }
}
