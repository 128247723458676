.analyze_summary {
  padding-bottom: 4rem;

  .inner_container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 1rem;

    @include media($break1400) {}
  }

  .name_container {
    padding-top: 15px;

    .instagram_icon {
      margin-right: 8px;
      vertical-align: 6px;
    }

    .social_account_name {
      display: inline-block;
    }
  }

  .overallStats_container {
    border-bottom: 1px solid $lightgray-border;
    padding: 1rem 0 1.6rem;

    .standard_tooltip {
  		width: 250px;
  		left: initial;
  		bottom: initial;

  		&:after, &:before {
  			display: none;
  		}
  	}

    @include media($break430) {
      padding: .16rem 0 0;
    }

    .overallStats_list {

      @include media($break420) {
        padding: 1rem 0;
      }
    }

    .overallStats_item {
      float: left;
      margin-right: 1.2%;
      width: 8.92%;
      position: relative;

      &:last-child {
        margin-right: 0;
      }

      @include media($break800) {
        width: 28%;
        margin-right: 5%;
        height: 75px;
        margin-bottom: 2rem;
        text-align: center;

        &:last-child {
          margin-bottom: 0;
        }
      }

      @include media($break335) {
        width: 45%;
        height: 70px;
      }
    }

    .instagramImpressinos_stat {}

    .stat_number {
      margin-bottom: 0;
      float: left;
      width: 100%;

      @include media($break1100) {
        font-size: 1.5rem;
      }

      @include media($break860) {
        font-size: 1.25rem;
      }

      @include media($break835) {
        font-size: 2.5em;
      }

      @include media($break800) {
        float: none;
      }

      @include media($break430) {
        font-size: 2em;
      }

      @include media($break300) {
        font-size: 1.75em;
      }
    }

    .loading_spinner {
      width: 37px;
      height: 37px;
      background-size: 19px;
    }

    .stat_label {
      float: left;
      color: #818181;
      text-transform: uppercase;
      font-size: 1.2em;
      letter-spacing: .08em;
      position: relative;

      @include media($break835) {
        font-size: 1.25em;
      }

      @include media($break800) {
        float: none;
      }
    }

    .hint_btn {
      background: none;
      padding: 3px;
      margin-top: -5px;

      &:focus, &:active {
        box-shadow: none;
      }

      @include media($break700) {
        margin-top: 0;
      }

      @include media($break420) {
        display: none;
      }

      .hint_icon2 {
        width: 17px;
      }
    }
  }

  .contentRow_container {
    border-bottom: 1px solid $lightgray-border;
  }

  .pieChart_container {

    .label_container {
      font-size: 1.4em;
      position: absolute;
      text-align: center;
      width: 70px;
      font-weight: 300;
    }

    .label_title {
      color: #818181;
      display: block;
    }

    .label_amount {
      display: block;
      color: $dark-text;
    }
  }

  .valueCalculator_container, .campaignBreakdown_container {
    float: left;
    width: 50%;

    .cantShow {

      .icon_warning, .cantShow_txt {
        display: block;
      }

      .icon_warning {
        display: block;
        margin: 0 auto 1rem;
        width: 27px;
      }

      .cantShow_txt {
        font-weight: 600;
      }
    }

    .cantShow_inner {
      padding: 10rem 4.5rem;
      text-align: center;

      @include media($break600) {
        padding: 2rem 1.5rem;
        background: #F3F3F3;
      }
    }

    .cantShow_a {
      background: url('/images/analyze/cantshow/cantShow_placeholder_a.png') center no-repeat;
      background-size: 100%;
      margin: 1rem 1rem 1rem 0;

      @include media($break975) {
        margin: 1rem 0 0;
      }
    }

    .cantShow_b {
      background: url('/images/analyze/cantshow/cantShow_placeholder_b.png') center no-repeat;
      background-size: 100%;
      margin: 1rem 0 1rem 1rem;

      @include media($break975) {
        margin: 1rem 0 0;
      }
    }

    @include media($break975) {
      width: 100%;
      float: none;
    }

    @include media($break895) {
      float: none;
      width: 100%;
      border-bottom: 1px solid $lightgray-border;
      padding-bottom: 2.25rem;
      padding-top: .35rem;

      &:last-child {
        border-bottom: none;
      }
    }

    .header_title {

      @include media($break485) {
        text-align: center;
      }

    }
  }

  .valueCalculator_container {

    @include media($break975) {
      border-bottom: 1px solid $lightgray-border;
      padding-bottom: 2rem;
    }

    .header_container {
      padding: 1rem 1rem 1rem 0;
    }

    .mainContent_container {}

    .totalMedia_value_container {
      float: left;
      margin-right: 1rem;

      @include media($break485) {
        float: none;
        margin-right: 0;
        margin: 0;
      }

      .totalCurrency_label {
        font-size: 1.6em;
      }

      .ng-isolate-scope {
        width: 383px;
        display: block;
        position: relative;

        @include media($break1250) {
          width: 335px;
        }

        @include media($break1140) {
          width: 285px;
        }

        @include media($break975) {
          width: 375px;
        }

        @include media($break615) {
          width: 295px;
        }

        @include media($break530) {
          width: 250px;
        }

        @include media($break485) {
          width: 100%;
        }
      }

      .nv-pieLabels {

        text {
          font-size: 25px;
          font-weight: 800;
        }
      }
    }

    .text_container {
      width: 115px;
      position: absolute;
      left: 50%;
      top: 135px;
      margin-left: -57.5px;
      text-align: center;

      @include media($break1250) {
        top: 111px;
      }

      @include media($break1140) {
        top: 97px;
      }

      @include media($break975) {
        top: 121px;
      }

      @include media($break615) {
        top: 97px;
      }

      @include media($break530) {
        top: 79px;
      }

      @include media($break485) {
        top: 36%;
      }
    }

    .totalCurrency_amount {
      font-weight: 800;
      display: block;

      @include media($break1140) {
        font-size: 1.8em;
      }

      @include media($break975) {
        font-size: 2.35em;
      }

      @include media($break615) {
        font-size: 2em;
      }

      @include media($break485) {
        font-size: 3em;
      }

      @include media($break375) {
        font-size: 2em;
      }
    }

    .totalCurrency_label {
      font-weight: 300;
      display: block;

      @include media($break1140) {
        font-size: 1.8em;
      }

      @include media($break975) {
        font-size: 2.25em;
      }

      @include media($break615) {
        font-size: 1.75em;
      }

      @include media($break485) {
        font-size: 2em;
      }

      @include media($break375) {
        font-size: 1.7em;
      }
    }

    .sampleGraph_image {
      width: 228px;
      position: absolute;
      left: 50%;
      margin-left: -114px;
      top: 9px;
    }

    .likesComments_label {
      left: 0;
      top: 37%;
    }

    .videoViews_label {
      right: 40px;
    }

    .numberOf_clicks {
      bottom: 0;
      right: 42px;
    }

    .campaignRoi_container {
      float: left;
      width: 220px;
      border: 1px solid $lightgray-border;
      border-radius: 6px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.09);
      text-align: center;
      padding: 1rem 1rem 1.4rem;
      margin-top: 7.5rem;

      @include media($break1375) {
        width: 170px;
        margin-top: 6rem;
      }

      @include media($break1250) {
        margin-top: 6rem;
      }

      @include media($break1045) {
        margin-top: 4.5rem;
        width: 135px;
      }

      @include media($break975) {
        width: 300px;
        margin-top: 10.5em;
      }

      @include media($break975) {
        width: 220px;
      }

      @include media($break663) {
        width: 165px;
      }

      @include media($break615) {
        margin-top: 6.5em;
      }

      @include media($break485) {
        width: 100%;
        margin-top: 0;
      }
    }

    .percentage_amount {
      display: block;
      font-weight: 800;
      font-size: 2em;

      @include media($break1300) {
        font-size: 1.75em;
      }

      @include media($break1140) {
        font-size: 1.5em;
      }

      @include media($break975) {
        font-size: 2.75em;
      }

      @include media($break745) {
        font-size: 2em;
      }
    }

    .campaignRoi_label {
      display: block;

      @include media($break1300) {
        font-size: 1.5em;
      }

      @include media($break1140) {
        font-size: 1.35em;
      }

      @include media($break975) {
        font-size: 2em;
      }

      @include media($break745) {
        font-size: 1.75em;
      }
    }

  }

  .campaignBreakdown_container {
    border-left: 1px solid $lightgray-border;

    .showcase_container {
      height: 100%;

      @include media($break485) {
        margin-top: 2rem;
      }

      .audience_report_icon {
        vertical-align: -5px;
        margin-right: .25rem;
      }

      .data_showcase_button {
        width: 235px;
        margin: 0 auto 1.5rem;
        display: block;

        @include media($break485) {
          width: 100%;
          float: none;
        }
      }
    }

    @include media($break975) {
      border-left: none;
    }

    .nv-pieLabels {

      text {
        font-size: 30px;
        font-weight: 800;
      }
    }

    .nv-noData {
      font-size: 30px;
      font-weight: 300;
    }

    .header_container {
      padding: 1rem 1rem 1rem 1.5rem;

      @include media($break975) {
        padding: 1rem 1rem 1rem 0;
      }
    }

    .mainContent_container {}

    .gender_container, .age_container {
      width: 48.69%;
      float: left;

      @include media($break485) {
        margin-right: 0;
        width: 100%;
        float: none;
      }
    }

    .age_container_hidden {
      display:none;
    }

    .insightStats_container {
      float: left;
      width: 195px;
      border: 1px solid #f4f4f4;
      border-radius: 6px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.09);
      text-align: center;
      padding: 1rem 1rem 1.4rem;

      @include media($break485) {
        float: none;
        width: 100%;
      }
    }

    .insightRate_container, .age_container {}

    .insightRate_container {
      border-bottom: 1px solid $lightgray-border;
      padding-bottom: 1.1rem;
      margin-bottom: .8rem;
    }

    .insightRate_number {
      display: block;
      font-weight: 800;
      font-size: 2.25em;
    }

    .insightRate_label {
      color: #818181;
      text-transform: uppercase;
      font-size: 1.2em;
      letter-spacing: .08em;
      display: block;
      font-weight: 300;
      line-height: 1.5em;
      margin-bottom: .7rem;
    }

    .insightRate_label {
      font-size: 1.3em;
      font-weight: 300;
    }

    .vs_txt {
      font-size: 2em;
      font-weight: 300;
      margin-bottom: 1rem;
      display: block;
    }

    .insight_comparison {
      font-size: 1.25em;
      font-weight: 300;
    }

    .likeComment_ratio_container {}

    .breakdownGraph_title_container {
      margin-bottom: .5rem;
    }

    .breakdownGraph_title_container

    .breakdownGraph_title {
      margin-left:1rem;

      @include media($break975) {
        margin-left: 0;
      }

      @include media($break485) {
        text-align: center;
      }
    }

    .breakdownGraph_container {
      position: relative;
      height: 283px;
    }

    .sampleGraph_image {
      width: 228px;
      position: absolute;
      left: 50%;
      margin-left: -114px;
      top: 0;
    }

    .female_label {
      top: 12px;
      left: 16px;
    }

    .male_label {
      bottom: 11px;
      left: 50%;
    }

    .ageGroup_a {
      left: 43px;
      top: -23px;
    }

    .ageGroup_b {
        left: -9px;
        top: 38%;
    }

    .ageGroup_c {
      bottom: 8px;
      left: 85px;
    }

    .ageGroup_d {
      right: -5px;
      top: 33%;
    }
  }

  .gender_container {
    margin-right: 2.62%;

    @include media($break485) {
      border-bottom: 1px solid $lightgray-border;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
    }

    .breakdownGraph_title {
      margin-left: 1.65rem !important;

      @include media($break975) {
        margin-left: 0 !important;
      }
    }
  }

  .selectedContent_container {

    .main_header {
      padding: 2.5rem 0 2rem;
      text-align: center;
    }

    .main_title {}

    .main_content {}

    .selectedContent_list {

      @include media($break975) {
        margin: 0 auto;
        width: 830px;
      }

      @include media($break845) {
        width: 100%;
      }
    }

    .selectedContent_item {
      float: left;
      width: 23.18%;
      margin-right: 2.22%;

      &:last-child {
        margin-right: 0;
      }

      &:hover {

        .content_img {
          opacity: .95;
        }
      }

      @include media($break975) {
        margin-bottom: 1rem;
        width: 45%;
        margin-right: 5%;
        height: 565px;

        &:last-child {
          margin-right: 0;
        }
      }

      @include media($break875) {

      }

      @include media($break525) {
        width: 100%;
        margin-right: 0;
        height: auto;
        margin-bottom: 2rem;
      }
    }

    .image_container {}

    .content_img {
      max-width: 100%;
    }

    .instagram_img {}

    .statsOverview_container {
      padding: .5rem .5rem;
    }

    .account_avatar {
      border: 3px solid white;
      border-radius: 100px;
      float: left;
      margin-right: 1rem;
      width: 60px;
      margin-top: -30px;
      position: relative;
    }

    .stats_list {
      width: calc(100% - 76px);
      float: left;
      text-align: center;
    }

    .stats_item {
      float: left;
      width: 33.1%;
      color: $dark-text;
    }

    .stats_number {
      font-weight: 800;
      display: block;
    }

    .stats_label {
      display: block;

      @include media($break1115) {
        font-size: .8em;
      }
    }

    .caption_container {
      color: $dark-text;
      margin-bottom: .75rem;
    }

    .account_username {
      font-weight: 800;
      margin-right: .5rem;
    }

    .caption_text {
      display: inline;
    }

    .content_link{
       text-decoration:none;
    }

    .contentQuality_container {
      position: relative;
    }

    .contentQuality_icon {
      display: inline-block;
    }

    .contentQuality_number {
      font-weight: 800;
      color: $dark-text;
      display: inline-block;
    }

    .contentQuality_tooltip {
      left: -102px;
      top: -99px;

      strong {
        font-weight: 800;
      }
    }

    .contentQuality_descrip {
      color: $dark-text;
      font-size: .9em;
    }

    .revComment_container {
      border: 1px solid $lightgray-border;
      border-radius: 5px;
      padding: 1rem;
      margin-top: 1em;

      .avatar_container {
        width: 50px;
        float: left;
        margin-right: .75rem;
        position: relative;
      }

      .revTeam_indicator {
        position: absolute;
        bottom: 0;
        left: 0;
      }

      .avatar_image {
        max-width: 100%;
        border-radius: 100px;
      }

      .revComment {
        float: left;
        width: calc(100% - 62px);
        color: $dark-text;
      }

      .username {
        font-weight: 800;
      }

      .revComment_text {}
    }
  }
}

.nvtooltip {
  background-color: black !important;
  background-color: rgba(0,0,0,.8) !important;
  color: white;
  font-size: 3em;
  padding: .5em !important;
  margin-bottom: 4px !important;
  border: none;
  text-align: center !important;
  display: block;
  border-radius: 5px;
}
