.content_approval_tool {

  .dragDrop_wrapper {
    display: inline-block;
    margin: 0 0 1rem 0;

    .drag_drop_container {
      margin-right: 1rem;
      margin-bottom: 1rem;
    }
  }

  .upload_content {
    padding-bottom: 2rem;

    .send_content_btn {

      .arrow_icon {
        vertical-align: -1px;
        margin-left: 5px;
      }
    }

    .disclaimer {
      font-size: 1.25em;
      line-height: 1.25em;
      display: block;
      margin-top: .75rem;
      clear: both;
      float: left;
    }
  }

  .upload_instagram_content, .upload_pinterest_content {
    width: 99%;
    margin: 1rem 0;

    .row {
      margin-bottom: 1.5rem;
    }

    .img_container {
      float: left;
      margin-right: 1rem;

      @include media($break950) {
        float: none;
        width: 100%;
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }

    .img_label {
      display: block;
      margin-bottom: .65rem;
      cursor: pointer;
      font-weight: 600;
    }

    .uploaded_img {
      max-width: 250px;
      display: block;
      width: 100% !important;
      height: auto !important;

      @include media($break950) {
        max-width: 170px;
      }
    }

    .caption_container {
      float: left;
      width: calc(100% - 206px);

      .full_caption_text {
        white-space: pre-wrap;
      }

      @include media($break950) {
        float: none;
        width: 100%;
      }

      .caption_label {
        font-weight: 600;
        margin-bottom: .65rem;
      }

      .caption_input {}
    }

    .content_checklist_container {
      margin: 0;
      padding: 0 0 0 .2rem;
      border: none;

      .checklist_title {
        padding-bottom: 0;
      }

      .control-indicator {
        top: 1px;
      }
    }

    .dropped_slot_item {
      height: 100%;
    }

    .dropped_img_wrapper {
      height: 100%;
    }

    .loading_progressBar_container {
      padding-bottom: 0.5rem;
    }
  }

  .selectStory_wrap {
    border: 1px solid $lightgray-border;
    border-radius: 7px;
    padding: 1rem;
    margin-top: 1rem;
    width: 99%;
    margin-bottom: 2rem;

    @include media($break630) {
      background-color: white;
    }

    .selectStory_title {
      font-weight: 600;
      display: block;
      padding-bottom: .25rem;
    }

    .selectStory_list {

      @include media($break630) {
        background: white;
      }
    }

    .story_thumb {}
  }

  .upload_facebook_content {
    width: 99%;
    margin: 1rem 0;

    .row {
      margin-bottom: 1.5rem;
    }

    .img_container {
      float: left;
      width: 190px;
      margin-right: 1rem;

      @include media($break950) {
        float: none;
        width: 100%;
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }

    .img_label {
      display: block;
      margin-bottom: .65rem;
      cursor: pointer;
      font-weight: 600;
    }

    .uploaded_img {
      max-width: 250px;
      display: block;
      width: 100% !important;
      height: auto !important;

      @include media($break950) {
        max-width: 170px;
      }
    }

    .caption_container {
      float: left;
      width: calc(100% - 206px);

      .full_caption_text {
        white-space: pre-wrap;
      }

      @include media($break950) {
        float: none;
        width: 100%;
      }

      .caption_label {
        font-weight: 600;
        margin-bottom: .65rem;
      }

      .caption_input {}

      .disclaimer {
        margin-top: .75rem;
      }
    }

    .content_checklist_container {
      margin: 0;
      padding: 0 0 0 .2rem;
      border: none;

      .checklist_title {
        padding-bottom: 0;
      }

      .control-indicator {
        top: 1px;
      }
    }

    .dropped_slot_item {
      height: 100%;
    }

    .dropped_img_wrapper {
      height: 100%;
    }

    .loading_progressBar_container {
      padding-bottom: 0.5rem;
    }
  }

  .upload_youtube_content {

    .upload_youtube_fieldset {
      margin: .5rem 0;
      display: inline-block;
      width: 100%;

      @include media($break630) {
        background-color: white;
        border-radius: 6px;
        border: 1px solid $lightgray-border;
        padding: .65rem .75rem 1rem;
      }
    }

    .video_url_input {
      max-width: 375px;
      margin-bottom: .75rem;
    }

    .unlisted_url_label {
      display: block;
      font-weight: 600;
      margin-bottom: .5rem;
    }

    .content_checklist_container {
      margin-top: 1rem;
      padding: 0 0 0 .2rem;
      border: none;

      @include media($break630) {
        padding: .5rem .75rem;
        border: 1px solid $lightgray-border;
        width: 100%;
        margin-bottom: .7rem;
      }

      .checklist_title {
        padding-bottom: 0;
      }

      .control-indicator {
        top: 1px;
      }
    }

    .upload_vid_btn {
      margin-left: .15rem;
    }
  }

  .upload_extra_content {
    width: 99%;
    margin: 1rem 0;

    .send_extra_title {
      font-weight: 600;
      font-size: 1.09rem;
      margin-bottom: .4rem;
    }

    p {
      margin-bottom: 1rem;
    }

    .img_label {
      display: block;
    }

    .dragDrop_wrapper {
      padding-bottom: 1rem;
    }

    .upload_container {
      display: block;
    }
  }
}

.upload_content_checklist {

  .upload_content_title {
    margin-bottom: .25rem;
    font-weight: 800;
  }

  .network_icon {
    margin-right: 5px;
  }

  .main_descrip {
    margin-bottom: 1rem;
  }

  .content_checklist_container {
    margin-bottom: 2rem;
  }
}
