.analyze_view {

.analyze_main_header {
	background-color: white;
	border-bottom: 1px solid $lightgray-border;

	@include media($break420) {
		margin-bottom: 0;
	}

	.standard_tooltip {
		width: 250px;
		left: 50%;
		bottom: initial;

		&:after, &:before {
			display: none;
		}
	}

	.inner_container {
		margin: 0 auto;
		padding: 2.5em 1.618rem 3.65em;

		@include media($break985) {
			padding: 1.5em 2em 1.75em;
		}

		@include media($break420) {
			padding: 1.5em 1rem 1.75em;
		}
	}

	.back_button {
		width: 50px;
		height: 50px;
		text-indent: -9999px;
		vertical-align: -8px;
		float: left;
		background: url('/images/analyze/previous_page_gray.svg') center no-repeat;
		background-size: 14px;
		margin-top: 2em;
		margin-right: 1em;
	}

	.intro_container {
		float: left;
		width: calc(100% - 770px);

		@include media($break990) {
			float: none;
			width: 100%;
		}
	}

	.intro_title {
		color: $dark-text;
		line-height: 1.25em;
		font-weight: 600;
		margin-bottom: .25em;
		font-size: 4em;

		@include media($break615) {
			font-size: 3.5em;
		}

		@include media($break485) {
			text-align: center;
		}
	}

	.intro_descrip {
		font-size: 1.65em;
		color: $dark-text;
		font-weight: 300;
		line-height: 1.5em;
		display: inline;
		vertical-align: middle;

		@include media($break485) {
			text-align: center;
		}
	}

	.loading_spinner {
		background-size: 18px;
		margin: 0;
		vertical-align: -4px;
		display: inline-block;
	}

	.filter_actions {
		display: block;
	}

	.export_btn {
		display: inline-block;
		margin-top: .5rem;
		max-width: 145px;
	}

	.filters_container {
		float: right;
		margin-top: 1.65em;
		}
	}

	.large_filters_container {
		width: 769px;

		@include media($break990) {
			display: none;
		}
	}

	.toggle_filter_container {
		float: left;
		margin-right: .75rem;

		&:last-child {
			margin-right: 0;
		}
	}

	.time_range_container {

		.timeRange_dropdown_btn {
			padding: .5rem 1.75rem .45rem .7rem;
			max-width: 220px;
			background-position: 88% center;
		}

		.active_timeRange_dropdown_btn {
			background-color: $ultraLight_gray;
		}

		.timeRange_dropdown_list {
			right: -12px;
			top: 40px;
		}

		.date_pickers_container {

			.date_input {
				max-width: 94px;
				margin-right: 3px;
				display: inline;
				padding: .51em .7em;
			}

			.date_range_dash {
				vertical-align: 5px;
				margin-right: 3px;
			}

			.date_picker_btn {
				border-radius: 6px;
				padding: 7px 10px;
				vertical-align: 0;
			}
		}
	}

	.filter_label {
		font-weight: 400;
		display: block;
		margin-bottom: 1.25em;
		position: relative;

		.filter_label_txt {
			letter-spacing: .08em;
			color: $dark-text;
			text-transform: uppercase;
			font-size: 1em;
		}

		.tooltip_container {
			position: absolute;
			left: 60px;
			top: -5px;
		}
	}

	.creators_filter_label {
		margin-bottom: 15px;
	}

	.filter_list {
		padding-bottom: 1px;
	}

	.network_type_container {
		position: relative;

		.network_type_btn {
			background-position: 90% center;
		}

		.doubleArrow_dropdown {
			@include media($break990) {
				left: -2px;
			}
		}

		.network_icon {
			margin-right: .25rem;
			width: 15px;
		}

		.pinterest_icon {
			width: 13px;
		}

		.all_icon {
			vertical-align: 0;
		}

		.youtube_icon {
			height: 20px;
			vertical-align: -5px;
		}

		.instagram_icon {
			height: 20px;
			vertical-align: -5px;
		}

		.facebook_icon {}

		.twitter_icon {
			vertical-align: 0;
		}

		.blog_icon {}

		.pinterest_icon {}

		.snapchat_icon {
			vertical-align: 0;
		}

		.gplus_icon {}

		.filterBtn_txt {
			height: 20px;
		}

		.all_field {
			background: url('/images/style_guide/icon_networks_black.svg') center no-repeat;
		}

		.doubleArrow_select_btn {
			opacity: 1.0;
			position: relative;

			.pinterest_icon {
				width: 12px;
				vertical-align: -2px;
			}

			.instagram_icon {
				vertical-align: -2px;
			}

			.all_icon {
				vertical-align: 0;
			}

			.youtube_icon {
				vertical-align: -2px;
			}

			.facebook_icon {}

			.twitter_icon {
				vertical-align: 0;
			}

			.blog_icon {}

			.pinterest_icon {}

			.snapchat_icon {
				vertical-align: 0;
			}

			.gplus_icon {}

			.filterBtn_txt {}
		}
	}

	.select_btn_txt {
		max-width: 80px;
	}

	.watchlistAcct_filter {

		.timeRange_dropdown_list {
			width: 175px;
			top: 39px;

			&:before, &:after {
				left: 143px;
			}
		}

		.dropdown_btn {
			background: none;
		}

		.dropdown_list {
			display: block;
		}
	}

	.creator_type_container {
  	margin-top:-3px;
	}

	.tooltip_link {
		width: 15px;
		height: 15px;
		display: inline-block;
		background: url('/images/analyze/tooltip_icon.svg') center no-repeat;
		top: -3px;
		position: absolute;
		display: none;
	}

	.revfluence_logomark {
		background: url('/images/analyze/revfluence_logomark.svg') center no-repeat;
		margin-right: .5em;
	}

	.globe_icon {
		background: url('/images/analyze/all_creators_icon.svg') center no-repeat;
		margin-right: .5em;
	}

	.active_filter_item {

		.globe_icon {
			background: url('/images/analyze/all_creators_icon_light.svg') center no-repeat;
		}

		.revfluence_logomark {
			background: url('/images/analyze/revfluence_logomark_light.svg') center no-repeat;
			margin-right: .5em;
		}

		.instagram_icon {
			background-size: 23px;
		}

		.youtube_icon {}

		.facebook_icon {}

		.twitter_icon {}

		.blog_icon {}

		.pinterest_icon {}

		.snapchat_icon {}

		.gplus_icon {}
	}

	.small_filters_container {
		border-radius: 6px;
		display: none;
		width: 100%;
		background-color: white;
		border: 1px solid $lightgray-border;

		@include media($break990) {
			display: block;
		}

		.content_searchFilters_container {
			padding: 1rem;

			@include media($break990) {
				padding: .25rem 1rem 0;
				border-bottom: 1px solid $lightgray-border;
				margin-bottom: 1rem;
				display: block !important;
				width: 100%;
				float: left;
				border-bottom: none;
			}

			.filters_dropdown_list {

				@include media($break900) {
					right: -53px;
				}

				&:before, &:after {

					@include media($break900) {
						left: 12px;
					}
				}
			}
		}

		.toggle_filters_btn {
			background-color: white;
			border-radius: 6px 6px 0 0;
			width: 100%;
			padding: .6rem 20px;
			border: none;

			&:active, &:focus {
				box-shadow: none;
			}

			.down_arrow_icon {
				width: 15px;
				margin-left: .5rem;
			}

			.open_icon {}
		}

		.filters_group {
			border-top: 1px solid $lightgray-border;
		}

		.filter_button {
			font-size: .9em;
		}

		@include media($break985) {
			display: block;
		}

		.btn_text {
			display: inline-block;
			margin-right: .5em;
		}

		.btn_icon {
			display: inline-block;
			width: 13px;
			height: 10px;
		}

		.open_indicator {
			background: url('/images/analyze/down_arrow_ico.svg') center no-repeat;
			background-size: 100%;
		}

		.close_indicator {
			background: url('/images/analyze/up_arrow_ico.svg') center no-repeat;
		}

		.filters_container {
			display: none;
			padding: 2em 2em 0;
			margin-top: 0;
			background-color: #F9F9F9;
			border-top: 1px solid $lightgray-border;
		}

		.toggle_filter_container {
			margin-left: 1rem;
			margin: 1rem 0 1rem 1rem;

			@include media($break740) {
				float: none;
				margin-bottom: 1rem;
			}

			&:last-child {
				margin-right: none !important;
			}
		}
		}
}
