
.demographics_section {
  margin-bottom: 1.7rem;

  .youtube_warning {
    margin-bottom: 1.0rem;
  }
  .section_title {
    margin-bottom: .7rem;

    @include media($break875) {
      display: none;
    }
  }

  .updateDemo_btn {

    .icon_addItem {
      margin-right: .25rem;
      width: 20px;
      vertical-align: -4px;
    }
  }

  .gender_container, .ages_container, .country_container {
    margin-bottom:1rem;
  }


  .chart_container {
    position:relative
  }

  .chart-legend{
    position:absolute;
    bottom:15px;
    left:125px;
    display:inline-block;
  }

  .doughnut-legend{
    font-size:1.5em;
    line-height:1.5em;
  }

  .no_demographics_notice {
    padding-top: 1rem;
    display: block;
  }

  .demographic_container {}

  .chart {
    display: block;
    max-width: 100%;
  }

  .txt_container {
    float: left;
    width: calc(100% - 66px);
  }

  .gender_category {}

  .category_name {
    font-weight: 600;
  }

  .stats_list {}

  .stat_item {}

  .stat_color_indicator {
    width: 10px;
    height: 10px;
    display: inline-block;
    background-color: #8EA4E8;
    margin-right: .2rem;
    vertical-align: 1px;
  }

  .stat_txt {
    display: inline-block;
  }

  .ages_container {}

  .country_container {}
}

.no_charts {

  .chart_container {
    display: none;
  }

  .stat_color_indicator {
    display: none;
  }
}
