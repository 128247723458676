.advertiser_access_tool {

    .tutorial, .view_dashboard_button, .help_flashNotice, .loading_progressBar_container {
        margin: 1rem 0;
    }

    .tool_main_container {
        padding-bottom: 2rem;

        @include media($break845) {
            padding-bottom: 8rem;
        }
    }

    .intro_descrip, .almost_complete_descrip, .setup_complete_descrip, .waiting_descrip {
        padding-bottom: .5rem;

        i {
            font-style: italic;
        }

        p {
            font-size: 1rem;
        }

        .strong {
            font-weight: 800;
        }

        .small_text {
            font-size: .9rem;
        }
    }

    .intro_descrip {

        p {
            padding-bottom: .75rem;
        }

        .small_text {
            padding-bottom: 0;
        }
    }

    .almost_complete_descrip {

        p {
            padding-bottom: 0;
        }

        .strong {
            padding-bottom: .75rem;
        }
    }

    .setup_complete_descrip, .waiting_descrip {

        .strong {
            padding-bottom: .25rem;
        }

        p {
           padding-bottom: 0;
        }
    }

    .facebook_login_button {
        display: block;
        margin: 0 0 1.5rem;

        .icon_facebook {}
    }

    .tutorial {
        display: block;

        .tutorial_media {
            width: 205px;
        }

        .caption_text {
            font-size: .75rem;
            color: $aiq_greyTxt;
            padding-top: .65rem;
            display: block;
        }
    }

    .date_range_wrap {
        font-size: .9rem;
        padding-bottom: .75rem;

        .date_range_item {
            display: inline-block;
        }

        .calendar_icon {
            margin-right: .05rem;
            vertical-align: -5px;
        }
    }

    .account_list {
        margin: 0 0 1rem;

        li {
            border-bottom: 1px solid $lightgray-border;
            padding: .75rem;
            font-weight: 600;
        }

        .network_icon {
            margin-right: .25rem;
        }

        .complete {
            background: url('/images/icons/multicolor/checkmark.svg') 99% center no-repeat;
            background-size: 20px;
        }

        .waiting {
            background: url('/images/icons/outline/clock.svg') 99% center no-repeat;
            background-size: 20px;
        }

        .username {}

        .network_icon {
            width: 16px;
            vertical-align: -4px;
        }

        .icon_pinterest {}
    }

    .view_dashboard_button {}

    .help_flashNotice {
        margin-top: 1.5rem;
    }

    .new_progress_bar {
        margin-top: 1.5rem;
    }

    .reminder_message {
        padding-top: .5rem;

        .strong {
            font-weight: 800;
            padding-bottom: 0;
        }

        p {
            padding-bottom: 1rem;
        }

        .form_control {
            margin-bottom: .5rem;
        }

        .caption_text {
            display: block;
            color: $aiq_greyTxt;
            padding-bottom: .75rem;
            font-size: .75rem;
            margin-bottom: 0;
        }

        .copy_button {
            display: block;
            width: 105px;
        }
    }
}
