.agreement_success {

	.modal-content {
		padding: 3em 5em;
	}

	.close-modal-link {
		position: absolute;
		top: 2em;
		left: 2em;
	}

	.agreement_icon {
		display: block;
		margin: 0 auto 2em;
		border-radius: 6px;
	}

	.agreement_success_title {
		@include title25;
		margin-bottom: .5em;
		text-align: center;
		color: $dark-text;
		padding-top: 100px;
		background: url('../images/agreements/agreement_complete.png') center top no-repeat;
	}

	.details {
		@include fontsize15;
		color: $dark-text;
		margin-bottom: 2em;
		text-align: center;
	}

	.okay_button {
		display: block;
		margin: 0 auto;
		width: 100px;
		text-align: center;
	}
}