.bestPerform_content2 {
  padding: 0 2.25rem;
  min-width: 1200px;

  .bestPerform_list {

    .bestPerform_item {
      background: #FFFFFF;
      box-shadow: 1px 1px 8px 0 #E1E4EA;
      border-radius: 3px;

      .leftCol, .rightCol {
        float: left;
      }

      .leftCol {}

      .contentType {}

      .contentImg {}

      .rightCol {

        .titleWrap {}

        .icon_fb {}

        .icon_ig {}

        .icon_yt {}

        .icon_blog {}

        .contentTitle {}

        .bodyWrap {}

        .detail_txt {}

        .detail_label {}

        .detail_descrip {}

        .contentGraph_wrap {}

        .mainStat_wrap {}

        .mainStat_number {}

        .mainStat_label {}

        .sample_emv_graph {}

        .moreStats_wrap {}

        .barChart_wrap {}

        .sample_ageRange_chart {}

        .pieChart_wrap {}

        .sample_pieChart_wrap {}
      }
    }
  }
}
