.resource_article {

  strong {
    font-weight:900 !important;
  }

  .welcome_nav {
    background: white;

    .white_logo_link {
        display: none !important;
    }

    .black_logo_link {
        display: inline-block !important;

        .revfluence_logo_black {
            display: block;

            @include media($break600) {
                display: none !important;
            }
        }

        .revfluence_logomark_black {

            @include media($break600) {
                display: block !important;
            }
        }
    }

    .creator_suggest {
        color: $dark-text !important;
        vertical-align: 18px !important;

        a {
            color: $revfluence-blue;
        }
    }

    .entry_link {
        color: $dark-text !important;

        &:hover {
            color: $revfluence-blue !important;
        }
    }

    .smallNav_links {

        .whiteHam_icon {
            display: none !important;
        }

        .blackHam_icon {
            display: block !important;
        }
    }
  }

  .main_content {
    padding: 4rem 2rem;
    max-width: 1400px;
    margin: 0 auto;

    @include media($break525) {
      padding: 4rem 1rem;
    }

    .main_article, .contactForm_container {
      float: left;

      @include media($break900) {
        float: none;
      }
    }

    .main_article {
      width: 70%;
      padding-right: 5%;

      @include media($break900) {
        width: 100%;
        padding-right: 0;
      }

      .article_header {}

      .article_title {
        padding-bottom: .5rem;
        font-size: 1.9rem;
      }

      .article_body {
        font-size: .95rem;
        font-weight: 400;
        line-height: 1.5rem;

        > p {
          padding: .45rem 0;
        }

        > h2 {
          font-size: 1.5rem;
          padding: 1.25rem 0 .7rem;
          line-height: 2rem;
        }

        > ol {
          list-style-type: decimal;
          list-style-position: inside;
        }

        a {
          color: $revfluence-blue;
          text-decoration: none;
        }

        > ul {
          list-style-type: disc;
          list-style-position: inside;
        }

        ul, ol {
          padding-left: 1.5rem;
        }

        li {
          padding: .45rem 0;
        }

        > img {
          padding: 1rem 0;
          width: 100%;
        }
      }

      .first_article_img {
        max-width: 370px;
        float: left;
        padding: 0 30px 15px 0;

        @include media($break525) {
          float: none;
          width: 100%;
          padding: 0 0 15px 0;
          display: block;
        }
      }
    }

    .contactForm_container {
      width: 30%;
      padding-top: 17px;

      @include media($break900) {
        max-width: 390px;
        width: 100%;
      }

      > h3 {
        padding-bottom: .75rem;
        font-weight: 600;
      }

      .contact_form {}

      fieldset {
        padding-bottom: .5rem;
      }

      .firstName_fieldset, .lastName_fieldset {
        float: left;
      }

      .firstName_fieldset {
        width: 45%;
        margin-right: 5%;
      }

      .lastName_fieldset {
        width: 50%;
      }

      .standard_label {
        font-weight: 400;
      }

      .form_control {}

      .send_btn {
        display: block;
        width: 80px;
        margin-top: .25rem;
      }
    }

    .article_title, h1, h2, h3, h4, .h4 {
      font-weight: 600;
    }
  }
}
