.creator_profile {

  .content_gallery {
    position: relative;
    border-bottom: 1px solid $lightgray-border;

    @include media($break875) {
      display: block;
    }

    .arrow_left_icon {}

    .arrow_icon {
      width: 20px;
    }

    .gallery_nav_list {
      position: absolute;
      top: 50%;
      margin-top: -25px;
      width: 100%;
    }

    .gallery_nav_item {}

    .nav_left_btn {
      border-radius: 0 8px 8px 0;
      padding: .8rem 1rem .8rem .5rem;
    }

    .nav_right_btn {
      border-radius: 8px 0 0 8px;
    }

    .nav_left_item {
      position: absolute;
      left: 0;
    }

    .nav_right_btn {
      padding: .8rem .5rem .8rem 1rem;
    }

    .nav_right_item {
      position: absolute;
      right: 0;
      border-radius: 8px 0 0 8px;
    }

    .nav_btn {
      background-color: rgba(0,0,0,.5);
    }

    .amount_indication_container {
      background-color: $dark-text;
      position: absolute;
      bottom: 0px;
      left: 45%;
      opacity:0.7;
      border-radius: 5px 5px 0 0;
    }

    .amount_indication_list {
      padding: 0.8rem;
      text-align: center;
    }

    .amount_indication_item {
      display: inline-block;
      margin-right: .5rem;
      opacity: .4;

      &:last-child {
        margin-right: 0;
      }
    }

    .active_item {
      opacity: 1;
    }

    .indication_link {

      &:hover {
        .amount_indication_item {
          opacity: 1;
        }
      }
    }

    .amount_indicator_icon {}

    .instagram_content {}

    .content_img {
      max-width: 100%;
      width: 100%;
    }

    iframe {
      max-width: 100%;
      width: 100%;
    }
  }
}
