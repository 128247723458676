.new_manage {

	.inbox_filters {
		width: 210px;
		float: left;
		margin: 1px 28px 0 0;
		height: 100%;
		padding-top: 15px;
		transition: all .18s ease-in-out;

		@include media($break1200) {
			z-index: 7000;
		}

		@include media($break1000) {
			padding: 17px;
			margin: 1px 0 0 0;
			left: -255px;
			width: 245px;
			position: fixed;
			background-color: white;
			top: 48px;
			box-shadow: 1px 1px 20px rgba(0,0,0,.2);

			&.open {
				padding-top: 11px;
				transform: translate(255px,0);
			}
		}

		.dropdown_btn {
			margin-bottom: .6rem;
		}

		.newMsg_indication {
			font-weight: 600;
		}

		.closeBtn_container {
			display: none;
			overflow: hidden;
			position: relative;
			height: 45px;

			@include media($break1000) {
				display: block;
			}
		}

		.close_filtersBtn {
			background: none;
			padding: 14px;
			border: none;
			position: absolute;
			right: -10px;

			.close_filters_icon {
				width: 13px;
			}
		}

		.campaign_selection_container {
			border-right: none;
			float: none;
			width: 100%;

			.campaign_switcher_btn {
				width: 100%;
				max-width: 100%;
				background-position: 92% center;

				.campaign_img {
					float: left;
					margin-right: .5rem;
					width: 24px;
					border-radius: 100px;
					height: 24px;
				}

				.btn_txt {
					float: left;
					width: calc(100% - 32px);
					line-height: 1rem;
					margin-top: 4px;
				}
			}

			.all_campaigns_avatar {
				overflow: hidden;

				.first_qtr_img, .second_qtr_img, .third_qtr_img, .fourth_qtr_img {
					float: left;
					width: 12px;
					height: 12px;
				}

				.first_qtr_img {}

				.second_qtr_img {}

				.third_qtr_img {}

				.fourth_qtr_img {}
			}

			.dropdown_list {
				right: 15px;
			}

			.campaign_dropdown_list {
				display: block;
				width: 255px;
				top: 38px;
				left: 0;
				padding: .6em 0 .7em;

				.campaign_img {
		  		float: left;
		  		margin-right: .5em;
		  		width: 27px;
		  		height: 27px;
		  		border-radius: 100px;

		      @include media($break575) {
		        margin-right: 0;
		      }
		  	}

	      .campaign_item {
	        padding: 0;
	      }

	  		&:after, &:before {
	  			right: 219px;
					left: initial;
	  		}

	      .campaign_btn {
	        display: block;
	        background: none;
	        border-radius: 0;
	        width: 100%;
	        padding: 6px 12px;

					.first_qtr_img, .second_qtr_img, .third_qtr_img, .fourth_qtr_img {
						width: 13px;
						height: 13px;
					}

	        .campaign_title {
	          width: calc(100% - 60px);
						font-size: .9rem;
						line-height: 22px;
						margin-top: 2px;
						margin-right: 0;
	        }

	        .edit_link {
	          padding: 0;
	        }
	      }

	      .active_campaign_btn {
	        background-color: $revfluence-blue;

					.first_qtr_img, .second_qtr_img, .third_qtr_img, .fourth_qtr_img {
						border: none;
					}

	        .selected_indicator {
	    			display: block;
						margin-top: 8px;
	    		}

	        .campaign_title {
	          color: white;
	        }

	        .edit_icon {
	          display: none;
	        }

	        .edit_icon_light {
	          display: block;
	        }

	        .actions_container {
	          margin-right: -3px;
	        }
	      }

	  		.campaign_title {
	  			@include media($break575) {
	  				display: inline-block;
	  			}
	  		}
	  	}

			.campaign_list {
	      max-height: 300px;
				overflow: auto;
	      scrollbar-face-color: #AFAFAF;
				scrollbar-track-color: none;
				background-color: white;

				::-webkit-scrollbar {
					width:  6px;
				}

				::-webkit-scrollbar-thumb {
					background: #D8D8D8;
					border-radius: 100px;
				}

				::-webkit-scrollbar-track {
					background: none;
				}
	    }

			.campaign_item {
	  		padding: 6px 15px;

	  		&:hover {
	  			background-color: $lightgray-border;
	  		}
	  	}

	  	.campaign_button {
	  		padding: 0;
	  		border: none;
	  		background: none;
	  		height: 27px;
	  		width: 100%;
	  		text-align: left;
	  	}

	    .add_campaign_item {
				border-top: 1px solid $lightgray-border;
				display: block;
			}

	    .campaign_link {
	      padding: 7px 12px 9px;
				background: none;
				border-radius: 0;
				cursor: pointer;

				&:active {
					box-shadow: none;
				}

				&:hover {
					background-color: $lightgray-border;
				}
	    }

			.add_campaign_link {
				color: $revfluence-blue;
				padding: 7px 12px 8px;

	      &:hover {
					background-color: $lightgray-border;
				}
			}

			.add_campaign_icon {
				width: 25px;
				display: inline-block;
				margin-right: 9px;
			}

			.add_campaign_text {
				display: inline-block;
				vertical-align: 6px;
				color: $revfluence-blue;
				font-weight: 300;
				font-size: 1.5em;
			}
		}

		.relationByOwner_container {

			.dropdown_btn {
				height: 40px;
				background-position: 92% center;
				width: 100%;
				padding: .7rem 1.9rem .5rem .95rem;

				.btn_txt {
					display: inline-block;
					line-height: 1rem;
					width: calc(100% - 18px);
					vertical-align: 2px;
				}
			}

			.dropdown_btn_icon {
				margin-right: .13rem;
				width: 17px;
				vertical-align: -3px;
			}

			.icon_allRelation {
				vertical-align: 2px !important;
			}

			.dropdown_list {
				width: 211px;
				left: 2px;
				top: 25px;

				&:before, &:after {
					left: 174px;
				}

				a {
					font-size: .9rem;
				}
			}

			.relationshipDropdown_list {
				max-height: 300px;
				overflow-y: auto;
				scrollbar-face-color: #AFAFAF;
				scrollbar-track-color: none;

				@include media($break630) {
				  background-color: #F8F8F8;
				  padding: 0;
				}

				::-webkit-scrollbar {
				  width:  6px;
				}

				::-webkit-scrollbar-thumb {
				  background: #D8D8D8;
				  border-radius: 100px;
				}

				::-webkit-scrollbar-track {
				  background: none;
				}
			}
		}

		.toggleClose_filters_btn {
			display: none;
			background: none;
			padding: 10px 4px 10px 0;

			&:active, &:focus {
				box-shadow: none;
			}

			@include media($break1000) {
				display: block;
			}
		}

		.close_filters_icon {
			width: 34px;
		}

		.inbox_search_input {
			background-image: url('/images/new_manage/search_icon.svg');
			background-repeat: no-repeat;
			background-position: 12px center;
			padding-left: 33px;
			margin-bottom: 15px;
		}

		.progressBar_container {
			padding-bottom: 1rem;

			.progressBar_txt_container {
				font-size: .9em;
				padding-bottom: .25rem;
			}

			.loadingStatus_txt {
				float: left;
				width: calc(100% - 41px);
			}

			.loadingProgress_percentage {
				float: right;
				width: 41px;
				text-align: right;
			}

			.progressBar {
				position: relative;
				height: 6px;
				border-radius: 100px;
				background-color: #E3E3E3;
			}

			.progressBar_fill {
				position: absolute;
				left: 0;
				width: 25%;
				background-color: $accent_gold;
				height: 6px;
				border-radius: 100px;
			}
		}

		.filter_category {
			font-weight: 600;
		}

		.inbox_filters_list {
			/* gonna have to chnage this if you add back button to top of input */
			height: calc(100% - 52px);
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;

			@include media($break1000) {
				height: calc(100% - 127px);
			}

			&.has_campaign_selector {
				padding-bottom: 150px;
				height: calc(100% - 111px);

				@include media($break1000) {
					height: calc(100% - 188px);
				}
			}

			.loading_spinner {
				background-size: 16px;
			}
		}

		.child_filters_list, .second_child_filters_list {
			margin: 0 0 .7em;
			font-size: .9em;

			.collection {
				border-bottom: none;
				margin: 0 0 0 6px;
			}
		}

		.collection_name {
			width: 142px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: inline-block;
			margin-top: -2px;

			&.new {
				font-weight: 600;
			}
		}

		.collection_amount {
			color: #A0A0A0;
			margin-left: .2rem;
			font-weight: 300;
		}

		.loading_spinner {
			vertical-align: bottom;
		}

		.collection_count {
			padding: 0 10px;
			background-color: $revfluence-blue;
			border-radius: 100px;
			height: 20px;
			flex-shrink: 0;
			display: inline-flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			color: white;
			font-size: .65em;
			font-weight: 600;
		}

		.new_activity_indicator {
			flex-shrink: 0;
			width: 7px;
			height: 7px;
			border-radius: 100px;
			background-color: $revfluence-blue;
		}

		.closed_arrow {
			background: url('/images/style_guide/closed_arrow_icon.svg') 4px center no-repeat;
		}

		.open_arrow {
			background: url('/images/style_guide/open_arrow_icon.svg') 2px center no-repeat;
		}

		.collection_bundle {
			border-bottom: 1px solid $lightgray-border;
			margin-bottom: .35rem;
			padding-bottom: .35rem;
		}

		.parent_filter_item {
			font-weight: 300;
			padding: 6px 4px 7px 14px;
			background-size: 10px;
		}

		.viewFilter_btn {
			font-size: 1em;
			background: none;
			display: inline-flex;
			flex-direction: row;
			align-items: center;
			position: relative;
			border-radius: 0;
			padding: 0 7px 0 7px;
			text-align: left;
			height: 31px;
			border: none;
			margin-left: -5px;
			font-weight: 300;
			width: 186px;

			&:hover {
				background-color: #f3f3f3;
			}

			&:active {
				background-color: #e3e3e3;
			}

			&:active, &:focus {
				box-shadow: none;
			}
		}

		.statusFilters_list {
			position: relative;

			.collection {
				margin: 0;
				padding-left: 14px;
				background-size: 10px;
				position: relative;
				z-index: 2;
			}

			.createTeam_btn {
				font-size: .825rem;
				padding-left: 5px;

				.collection_name {
					width: auto !important;
					margin-left: 3px;
				}
			}

			.add_icon {
				width: 19px;
			}

			.viewFilter_btn {
				width: 172px;
			}

			.timeline_line {
				display: none;
			}
		}

		.timelined_status {
			.collection {
				background: url('/images/new_manage/timeline_node_icon.svg') 3px 10px no-repeat;
			}

			.timeline_line {
				border-left: 1px solid #C6C6C6;
				display: block;
				height: calc(100% - 26px);
				width: 2px;
				position: absolute;
				top: 13px;
				left: 7px;
			}
		}

		.parent_filter_item, .child_filter_item, .second_child_filter_item {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: block;
			position: relative;
		}

		.parent_filter_item {
			width: 200px;
		}

		.child_filter_item, .second_child_filter_item {
			padding: .2rem 0 0 1.0rem;
			margin: 0 0 .3rem 6px;
			position: relative;
			border: none;
			text-align: left;
			cursor: pointer;
			width: 198px;

			.collection_count {
				top: 8px;
			}
		}

		.loadMore_btn {
			margin: .35rem 0 0 20px;
			font-size: .75rem;
			width: 167px;
			padding: 4px 20px;
		}

		.avatar_container {
			flex-shrink: 0;
			width: 25px;
			margin-right: .4rem;
			margin-left: 11px;
		}

		.user_avatar {
			max-width: 100%;
			border-radius: 100px;
			width: 25px;
			height: 25px;
		}

		.relation_name {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 90px;
			margin-right: .35rem;
		}

		.timestamp {
			flex-shrink: 0;
			color: #A1A1A1;
			font-size: .7rem;
			white-space: nowrap;
			overflow: hidden;
		}

		.filters_icon {
			display: inline-block;
			margin-right: 2px;
			cursor: pointer;
			cursor: pointer;
		}

		.filters_text {
			display: inline-block;
			cursor: pointer;
		}

		.second_child_filter_item {
			margin: 0 0 0 6px;
			width: 190px;
			padding: 5px 7px;
			display: flex;
			flex-direction: row;
			align-items: center;

			&:hover {
				background-color: #f3f3f3;
			}

			&:active {
				background-color: #e3e3e3;
			}

			.relation_name {
				width: 104px;
			}
		}

		.more_text {
			display: inline-block;
			margin-right: 2px;
		}

		.more_icon {
			display: inline-block;
			width: 9px;
			height: 4px;
			vertical-align: 2px;
		}

		.more_icon_more {
			background: url('/images/new_manage/more_arrow_open.svg') left center no-repeat;
		}

		.more_icon_less {
			background: url('/images/new_manage/more_arrow_closed.svg') left center no-repeat;
		}

		.active_item {
			color: $revfluence-blue;
			font-weight: 600;
		}

		.toggleExpansion_button {
			display: inline-block;
			width: 19px;
			height: 31px;
			border-radius: 0;
			padding: 0;
			border: none;
			margin: 0;
			background-size: 51%;

			&:active, &:focus {
				box-shadow: none;
			}
		}

		.toggle_open {
			background: url('/images/style_guide/open_arrow_icon.svg') center no-repeat;
			background-size: 51%;

			&:hover {
				background: #f3f3f3 url('/images/style_guide/open_arrow_icon.svg') center no-repeat;
				background-size: 51%;
			}

			&:active {
				background: #e3e3e3 url('/images/style_guide/open_arrow_icon.svg') center no-repeat;
				background-size: 51%;
			}
		}

		.toggle_closed {
			background: url('/images/style_guide/closed_arrow_icon.svg') center no-repeat;

			&:hover {
				background: #f3f3f3 url('/images/style_guide/closed_arrow_icon.svg') center no-repeat;
			}

			&:active {
				background: #e3e3e3 url('/images/style_guide/closed_arrow_icon.svg') center no-repeat;
			}
		}

		.relations-spinner {
			position:absolute;
	  	top:7px;
	  	right:30px;
	  	width:16px;
	  	height:16px;
		}
	}

	.open_inbox_filters {
		left: 1px;
	}
}
