.label_item {
	border: 1px solid $lightgray-border;
	border-radius: 100px;
	display: inline-block;
	margin-right: 7px;
}

.label_text {
	font-size: 1.25em;
	color: $dark-text;
	font-weight: 300;
	padding: 5px 3px 5px 7px;
	display: inline-block;
}

.remove_button {
	display: inline-block;
	width: 8px;
	height: 10px;
	vertical-align: 2px;
	background: url('/images/new_manage/remove_label_icon.svg') center no-repeat;
	margin-right: 8px;
	border: none;
}
