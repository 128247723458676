@mixin checkbox_input {

	.checkbox_input {
		display: inline-block;
		margin-right: .5em;
	}

	.checkbox_text {
		display: inline-block;
		@include fontsize14;
		color: $dark-text;
	}
}

.edit_collaboration {
	background-color: #f8f8f8;

	.topNavContainer {
		position: fixed;
		z-index: 9999;
	}

    .standard-error-message {
        @include standard-error-message;
        display: none;
    }

    .form-error-message {
        @include form-error-message;
        display: none;
        background-color: #ffcfcf;
        color: $error;
        border: 1px solid $error;
        padding: .5em;
        margin-top: 1em;
    }

	.intro_container {
		margin: 10em 0 4em;
		text-align: center;
		@include media($break900) {
			width: 95%;
			margin: 9em auto 4em;
		}

		.intro_title {
			display: inline-block;
			@include title35;
			color: $dark-text;
			vertical-align: 8px;
			letter-spacing: 0;
			@include media($break575) {
				@include title25;
				font-weight: 600;
			}
		}

		.intro_description {
			@include fontsize15;
			color: $dark-text;
		}
	}

	.main_container {
		@include outer-container;
		margin-bottom: 13em;
	}

	.main_col {
		@include span-columns(9);
		@include shift(1);
		@include media($break900) {
			@include shift(0);
			width: 95%;
			margin: 0 auto;
			float: none;
		}
		@include media($break575) {
			width: 100%;
		}

		.section_container {
			background-color: white;
			margin-bottom: 1em;
			padding: 0 2em;
			@include media($break400) {
				padding: 0 2em;
			}
		}

		.section_header {
			padding: 2em 0;

            .incomplete_icon {
                display:none;
            }
		}

		.section_title {
			@include standard_bold;
			@include title20;
			color: $dark-text;
		}

        .section_skipped_description {
            display: none;
        }

		.section_body {
			border-top: 1px solid $lightgray-border;
			padding-bottom: 2em;
			padding: 1.5em 0;
            display: none;
		}

		.label_container {
			margin-bottom: .5em;
		}

		.standard_label {
			@include standard_label;
			@include standard_bold;
			display: inline-block;
			margin-bottom: 0;
		}

		.fieldset_descrip {
			@include fontsize16;
			color: $dark-text;
			margin-bottom: 1em;

			strong {
				@include standard-bold;
			}

            .waiting_input {
                font-style: italic;
            }
		}

		.standard_input {
			@include standard-input;
			width: 300px;
			@include media($break400) {
				width: 100%;
			}
		}

		.standard_textarea {
			@include standard_textarea;
			width: 100%;
			height: 100px;
		}

		.fieldset_container {
			padding-bottom: 2em;

			&:last-child {
				padding-bottom: 0;
			}
		}

		.save_container {
			display: inline-block;

			input[type="checkbox"] {
				display: inline-block;
				margin-right: .5em;
			}

			.save_text {
				display: inline-block;
				@include fontsize14;
				color: $dark-text;
			}
		}

        .shared_input {
            color: $gray-text;
        }

		.creator_account {

			.network_icon {
				display: inline-block;
				width: 15px;
				height: 15px;
				margin-right: .5em;
				vertical-align: -.2em;
			}

			.instagram_icon {
				background: url('../images/collab_details/instagram_icon.svg') center no-repeat;
			}

			.youtube_icon {
				background: url('../images/collab_details/youtube_icon.svg') center no-repeat;
			}

			.creator_account_name {
				@include fontsize16;
				color: $dark-text;
			}
		}

		.post_type, .choosing_product {

			.choice_container {
				display: inline-block;
				margin-right: 1em;
				width: 295px;
				vertical-align: top;
				overflow: hidden;
				margin-top: 1em;
				@include media($break1235) {
					width: 100;
					margin-top: 2em;
				}
				@include media($break400) {
					width: 100%;
				}
			}

			.type_radio_button {
				display: inline-block;
				margin-right: 1.25em;
				float: left;
				@include media($break400) {
					display: block;
				}
			}

			.type_description {
				display: inline-block;
				@include fontsize14;
				color: $dark-text;
				float: left;
				width: 260px;
				@include media($break400) {
					width: 100%;
					margin-top: .75em;
				}

				strong {
					@include standard_bold;
				}
			}
		}

		.compensation_fieldset {

			.small_input {
				@include small_input;
			}
		}

		.target_date_fieldset {
			border-bottom: none;

			.small_input {
				@include small_input;
			}
		}

		.error_container {
			display: block;
			margin-bottom: 1em;

			.error-description {
				color: white;
				padding: .25em 1em;
				@include standard-radius;
				@include fontsize13;
				background-color: $error;
				display: inline-block;
			}
		}

		.section_container {

			.section_header {
				overflow: hidden;
				position: relative;

				.section_title {
					float: left;
					@include standard_bold;
				}

				.display_toggle {
					text-indent: -9999px;
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
				}
			}
		}

		.close {

            .display_toggle {
				background: url('../images/collab_details/open_toggle.svg') center right no-repeat;
				background-size: 4%;
				@include media($break700) {
					background-size: 5%;
				}
				@include media($break325) {
					background-size: 6%;
				}
			}

			.needs_completion {
                .display_toggle {
					background: url('../images/collab_details/unfinished_open_toggle.svg') center right no-repeat !important;
					background-size: 4% !important;
					@include media($break700) {
						background-size: 5% !important;
					}
					@include media($break325) {
						background-size: 6% !important;
					}
				}
			}

            .section_body {
                display: none;
            }
		}

		.open {

            .display_toggle {
				background: url('../images/collab_details/close_toggle.svg') center right no-repeat;
				background-size: 4%;
				@include media($break700) {
					background-size: 5%;
				}
				@include media($break325) {
					background-size: 6%;
				}
			}

			.needs_completion {
                .display_toggle {
					background: url('../images/collab_details/unfinished_close_toggle.svg') center right no-repeat !important;
					background-size: 4% !important;
					@include media($break700) {
						background-size: 5% !important;
					}
					@include media($break325) {
						background-size: 6% !important;
					}
				}
			}

            .section_body {
                display: block;
            }
		}

		/* Add this class to section_header and add the image incomplete_checklist_item_icon.svg next to section_title */
		.needs_completion {

			.incomplete_icon {
				margin-right: 1em;
				width: 20px;
				height: 20px;
				float: left;
				margin-top: .3em;
                display: block;
			}

			.section_title {
				color: $mantis;
			}
		}

        .high_level_terms {
            .standard_label {
                display:block;
            }
        }

		.product_choices {
			border-bottom: none;

			strong {
				color: $gray-text;
				font-style: italic;
				font-weight: 300 !important;
			}
		}

		.address_inputs {

			.info {
				@include fontsize16;
				color: $dark-text;
				display: block;
			}

			.shipping_address_title {
				@include standard_bold;
			}

			.row {
				margin-bottom: 2em;

				&:last-child {
					margin-bottom: 0;
				}

				.first_name_fieldset, .last_name_fieldset {
					width: 200px;
					display: inline-block;
					@include media($break1235) {
						width: 215px;
					}
					@include media($break523) {
						width: 100%;
					}

					.standard_input {
						@include media($break1235) {
							width: 100%;
						}
						@include media($break523) {
							width: 100%;
						}
					}
				}

				.first_name_fieldset {
					margin-right: 1em;
					@include media($break523) {
						margin-bottom: 2em;
					}
				}

				.street_fieldset, .city_fieldset {
					width: 200px;
					display: inline-block;
					@include media($break1235) {
						width: 215px;
					}
					@include media($break523) {
						width: 100%;
					}

					.standard_input {
						@include media($break1235) {
							width: 100%;
						}
						@include media($break523) {
						width: 100%;
					}
					}
				}

				.street_fieldset {
					margin-right: 1em;
					@include media($break523) {
						margin-bottom: 2em;
					}
				}

				.state_fieldset, .zip_fieldset {
					width: 300px;
					display: inline-block;

					@include media($break1235) {
						width: 215px;
					}
					@include media($break523) {
						width: 100%;
					}

					.standard_input {
						@include media($break1235) {
							width: 100%;
						}
						@include media($break523) {
						width: 100%;
					}
					}
				}

				.state_fieldset {
					margin-right: 1em;
					@include media($break523) {
						margin-bottom: 2em;
					}
				}
			}

			.zip_input {
				@include small_input;
				@include media($break1235) {
					width: 150px !important;
				}
				@include media($break523) {
					width: 100%;
				}
			}

			.select_dropdown {
				@include fontsize12;
				@include media($break1235) {
					width: 215px;
				}
				@include media($break523) {
					width: 100%;
				}
			}

			.select_dropdown:after {
				top: 1.9em;
			}

			.country_fieldset {
				width: 300px;
				@include media($break523) {
					width: 100%;
				}
			}

		}

		.product_inputs {

			.standard_label {
				margin-bottom: 1em;
			}

			.shipment_status_checkbox {
				@include checkbox-input;
			}

			.tracking_link_input {
				margin-top: 1em;
			}

			.tracking_link_label {
				@include standard_label;
				font-weight: 300;
			}
		}

		.products {

			.section_title {
				display: inline-block;
				margin-right: .5em;
				float: none !important;
			}

			.customize_link {
				@include blue-link;
				@include fontsize16;
			}

			.no_product_container {
				display: inline-block;
				position: relative;
				z-index: $zindexLowerBody;
				margin-left: .5em;

				.type_checkbox_button {
					display: inline-block;
					margin-right: .25em;
				}

				.type_description {
					@include fontsize14;
					color: $dark-text;
					display: inline-block;
				}
			}
		}

		.talking_points {

			.standard_label {
				margin-right: .5em;
			}

		}

		.links_for_product, .uploaded_content {

			.link_name {

				a {
					@include fontsize16;
					@include blue_link;
				}
			}

			.fieldset_descrip {
				marign-bottom: 1em;
			}

			.link_item {
				margin-bottom: 1em;

				.remove_link {
					width: 17px;
					height: 17px;
					display: inline-block;
					text-indent: -9999px;
					background: url('../images/collab_details/remove_link_icon.svg') center no-repeat;
					vertical-align: .4em;
					margin-left: .5em;
				}

				.link_input {
					@include standard-input;
					@include standard-radius-bottom;
					width: 300px;
					@include media($break400) {
						width: 100%;
					}
				}
			}

			.uploaded_links {

				strong {
					color: $dark-text;
					font-style: inherit;
					font-weight: 500 !important;
				}
			}

			.add_another_link {
				@include blue-link;
				@include fontsize16;
			}
		}

		.uploaded_content {

			strong {
				font-weight: 300 !important;
				color: $gray-text;
				font-style: italic;
			}
		}

		.coupon_code {

			.fieldset_descrip {
				margin-bottom: 1em;

				strong {
					color: $gray-text;
					font-style: italic;
					font-weight: 300;
				}
			}

		}

		.additional_details {
			border-bottom: none;
		}

        .section_skipped {

            .section_body {
                display: none;
            }

            .section_skipped_description {
                display: block;
            }

        }

        .edit_field {
            .read_mode {
                display: none;
            }
        }

        .read_field {
            .edit_mode {
                display: none;
            }
        }
	}

	.propose_button {
		text-align: center;
		width: 100%;
		border-radius: 0;
	}

	.support_container {
		@include span-columns(4);
		@include shift(.5);
		@include media($break900) {
			display: none;
		}

		.broadcast_container {

			.standardTooltip {
  				@include standard_tooltip;
  				width: 180px;
				bottom: 31px;
				left: -83px;
  			}
		}

		.broadcast_title {
			@include title20;
			@include standard-bold;
			color: $dark-text;
			border-bottom: 1px solid $lightgray-border;
			margin-bottom: 1em;
			padding: 1em 0;
		}

		.creator_avatar {
			display: inline-block;
			margin-right: 1em;
			vertical-align: -6px;
		}

		.creator_username {
			display: inline-block;
			@include fontsize18;
			color: $dark-text;
		}

		.message_text {
			@include fontsize14;
			color: $dark-text;
			margin-top: 1em;
			margin-bottom: 1em;
		}



		.collab_requirements, .collab_offer {
			margin-bottom: 2em;

			.body_title {
				@include fontsize14;
				color: $dark-text;
				@include standard-bold;
                display: inline;
			}

            .hint_icon {
                width: 15px;
                height: 15px;
                display: inline-block;
                text-indent: -9999px;
                margin-left: .4em;
                vertical-align: -2px;
                position: relative;
				cursor: pointer;
                background: url('../images/collab_details/hint_icon.svg') center no-repeat;

                .standardTooltip {
                    text-indent: 0px;
                }
            }

            .standardTooltip {
                @include standard_tooltip;
                width: 180px;
                bottom: 31px;
                left: -83px;
            }

			p {
				@include fontsize14;
				color: $dark-text;
			}
		}
	}

    .main_container.manager_collaboration {
        .publisher_field {
            display:none;
        }
    }

    .main_container.publisher_collaboration {
        .manager_field {
            display:none;
        }
    }

	.high_level_readmode {

		.section_container {
			margin-bottom: 2.5em;
			padding: 0;
		}

		.subtitle {
			@include fontsize16;
			color: $dark-text;
			@include standard_bold;
			margin-bottom: .5em;
		}

		.edit_link {
			@include blue-link;
		}

		.creator_account_container {

			.network_icon {
				display: inline-block;
				width: 15px;
				height: 15px;
				margin-right: .5em;
				vertical-align: -.2em;
			}

			.instagram_icon {
				background: url('../images/collab_details/instagram_icon.svg') center no-repeat;
			}

			.youtube_icon {
				background: url('../images/collab_details/youtube_icon.svg') center no-repeat;
			}

			.creator_account_name {
				@include fontsize16;
				color: $dark-text;
			}
		}

		.video_type_container {

			strong {
				@include standard_bold;
				display: block;
				@include fontsize16;
				color: $dark-text;
			}

			p {
				@include fontsize16;
				color: $dark-text;
			}

		}

		.compensation_container {

			.small_input {
				@include small_input;
			}

			.compensation_amount {
				@include fontsize16;
				color:se $dark-text;
			}
		}


		.target_date_container {

			.date {
				@include fontsize16;
				color: $dark-text;
			}
		}
	}
}
