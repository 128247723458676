.sales_integration {
	
	.main_content {
		max-width: 1200px;
		margin: 0 auto;
		padding-top: 8em;
	}

	.main_header {
		overflow: hidden;
		position: relative;
		margin-bottom: 2em;
	}

	.back_link_container {
		position: absolute;
		width: 200px;
		left: 1em;
		top: 0;
		display: block;
		text-decoration: none;

		.back_icon {
			display: inline-block;
			vertical-align: -4px;
			background: url('../images/sales_integration/back_icon.svg') center no-repeat;
			width: 20px;
			height: 20px;
			margin-right: .5em;
			@include media($break370) {
				margin-top: .65em;
			}
		}

		.back_text {
			display: inline-block;
			@include fontsize15;
			color: $dark-text;
			@include media($break370) {
				display: none;
			}
		}
	}

	.main_title {
		text-align: center;
		@include title25;
		@include standard_light;
		color: $dark-text;
		margin: 0;
	}

	.integration_result {
		margin: 0 auto 2em;
		width: 650px;
		border: 1px solid $lightgray-border;
		@include standard-radius;
		padding: 2em 0;
		@include media($break652) {
			width: 95%;
		}

		.result_header {
			margin-bottom: 1em;
			text-align: center;
		}

		.success_icon {
			width: 20px;
			height: 20px;
			background: url('../images/sales_integration/success_icon.svg') center no-repeat;
			display: inline-block;
			margin-right: .5em;
			vertical-align: -3px;
		}

		.fail_icon {
			width: 20px;
			height: 20px;
			background: url('../images/sales_integration/fail_icon.svg') center no-repeat;
			display: inline-block;
			margin-right: .5em;
			vertical-align: -3px;
		}

		.result_text {
			@include fontsize18;
			color: $dark-text;
			display: inline-block;
		}

		.result_descrip {
			@include fontsize15;
			color: $dark-text;
			text-align: center;
			margin-bottom: 1em;
		}

		.finish_button {
			display: block;
			width: 90px;
			margin: 0 auto;
		}
	}

	.integration_option {
		width: 650px;
		margin: 0 auto;
		border: 1px solid $lightgray-border;
		@include standard-radius;
		padding: 2em 0 3em;
		@include media($break652) {
			width: 95%;
		}

		.integration_name {
			display: block;
			text-indent: -9999px;
			margin: 0 auto 2em;
		}

		.shopify_logo {
			width: 126px;
			height: 35px;
			background: url('../images/sales_integration/shopify_logo.png') center no-repeat;
			background-size: 100%;
		}

		.inputs_container {
			width: 350px;
			margin: 0 auto;
			@include media($break370) {
				width: 90%;
			}

			.error_container {
				@include errors;
				margin-bottom: 1em;
				width: 100%;
			}

			.store_name_container {
				margin-bottom: 1em;
				overflow: hidden;
			}

			.store_name_input {
				@include standard-input;
				@include standard-radius-left;
				float: left;
				width: 245px;
				@include media($break370) {
					width: calc(100% - 105px);
				}
			}

			.url_ending {
				float: left;
				border-top: 1px solid $lightgray-border;
				border-right: 1px solid $lightgray-border;
				border-bottom: 1px solid $lightgray-border;
				@include standard-radius-right;
				padding: .6em;
				@include fontsize15;
				color: $dark-text;
				width: 105px;
			}

			.integrate_button {
				width: 100%;
			}
		}

		.added_account_container {
			width: 250px;
			margin: 0 auto;

			.added_account {
				border-radius: 45px;
				background-color: $gray-bg;
				padding: .5em 1.5em;
				position: relative;
			}

			.account_text {
				color: white;
				@include fontsize15;
			}

			.delete_link {
				cursor: pointer;
				width: 15px;
				height: 15px;
				background: url('../images/sales_integration/close_icon.svg') center no-repeat;
				position: absolute;
				right: 1em;
				top: 1em;
			}
		}
	}
}