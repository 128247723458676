.add_creditCard_modal {

  .add_creditCard_container {
    position: relative;
    border: none;
  }

  .permissions_container {
    width: 100%;
    margin-bottom: 2em;
  }

  .permissions_label {
    margin-right: .5rem;

    @include media($break540) {
      display: block;
      margin-bottom: .5rem;
    }
  }

  .select {
    max-width: 225px;

    @include media($break540) {
      display: block;
    }
  }

  .creditIntroContainer {

    @include media($break519) {
      padding: 4em 5em 0;
    }
  }

  .creditCardForm {
    @include media($break519) {
      padding: 0 5em;
    }

    #card-element {
      margin-top: 2em;
      margin-bottom: 2em;
    }
  }

  .startTrialButtonCenter {
    margin-right: auto;
    display: block;
    margin-left: auto;
    width: 130px;
    margin: 0 auto 3rem;
    display: block;

    @include media($break960) {
      margin-bottom: 2em;
    }
  }
}
