.choose_network_container {
	display: none;

	.network_list {
		width: 285px;
		margin: 0 auto;
		padding: 5px;

		@include media($break340) {
			width: 207px;
		}
	}

	.network_item {
		float: left;
		margin-right: 2em;
		margin-bottom: 2em;

		&:last-child {
			margin-right: 0;
		}
	}

	.network_button {
		text-align: center;
		-webkit-appearance: none;
		background-color: white;
		box-shadow: 0px 1px 2px 0px rgba(1, 1, 1, 0.09);
		padding: 2em;
		border: 1px solid white;
		cursor: pointer;
		width: 125px;
		height: 125px;
		transition: all .15s ease-in-out;
		border-radius: 32px;

		&:hover {
			background-color: #fdfdfd;
			border: 1px solid #d9d9d9;
			transform: translate(0,-5px);
		}

		&:focus {
			outline: none;
			background-color: #e2efff;
			border: 1px solid $revfluence-blue;
			transform: translate(0,-5px);
		}

		&:active {
			background-color: #e2efff;
			border: 1px solid $revfluence-blue;
		}
	}

	.network_icon {
		width: 30px;
		height: 30px;
		display: block;
		margin: 0 auto 2em;
		@include media($break340) {
			margin: 0 auto 1em;
		}
	}

	.youtube_icon {
		background: url('/images/choose_network/youtube_icon.svg') center no-repeat;
	}

	.instagram_icon {
		background: url('/images/choose_network/instagram_icon.svg') center no-repeat;
	}

	.network_text {
		color: $dark-text;
		display: block;
		font-size: 1.2em;
		font-weight: 500;
		@include media($break340) {
			display: inline-block;
		}
	}

	.recommended_container {

		.recommended_sentence {
			color: $dark-text;
			font-weight: 300;
			line-height: 1.5em;
			text-align: center;
			font-size: 1.35em;
			padding-top: 2.3em;

			strong {
				font-weight: 600;
			}
		}
	}
}

.instagram_recommended_container {

	.instagram_icon {
		background: url('/images/choose_network/instagram_icon_color.svg') center no-repeat;
	}

	.recommended_sentence {
		background: url('/images/choose_network/pointer_arrow_1.svg') 47% top no-repeat;
		background-size: 70px;
	}

	.instagram_network_text {
		color: #3f729b;
	}
}

.youtube_recommended_container {

	.recommended_sentence {
		background: url('/images/choose_network/pointer_arrow_2.svg') 53% top no-repeat;
		background-size: 70px;
		color: #3f729b;
	}

	.youtube_icon {
		background: url('/images/choose_network/youtube_icon_color.svg') center no-repeat;
	}

	.youtube_network_text {
		color: #f32732;
	}
}
