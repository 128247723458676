.content_approval_tool {

  .additional_image_content {
    margin-top: 1.0rem;
  }

  .view_instagram_content {

    .view_content_title {
      font-weight: 600;
      font-size: 1.09rem;
    }

    .view_content_descrip {
      margin-bottom: 1rem;
    }

    .content_list {}

    .content_list_item {
      position: relative;
      float: left;
      width: 45%;
      margin-right: 2%;
      margin-bottom: .5rem;
      border: 1px solid $lightgray-border;
      border-radius: 6px;
      overflow: hidden;
      height: 150px;

      @include media($break1024) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 1rem;
        height: auto;
      }

      @include media($break630) {
        width: 48%;
        margin-right: 2%;
        margin-bottom: .5rem;
        height: 150px;
      }

      @include media($break375) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 1rem;
        height: auto;
      }

      &:hover {

        .download_btn {
          transform: translate(0,5px);
        }

        opacity: .93;
      }
    }

    .taller_content_listItem {
      height: 225px;
    }

    .download_link {
      display: block;
    }

    .download_btn {
      position: absolute;
      top: 1rem;
      left: 1rem;
      width: 45px;
      height: 45px;
      background: white;
      border: 1px solid $lightgray-border;
      border-radius: 100px;
      padding: 0;
      transition: all .15s ease-in-out;

      &:hover {
        opacity: .9;
        box-shadow: 1px 1px 10px rgba(0,0,0,0.2);
      }
    }

    .download_icon {
      display: block;
      margin: 0 auto;
    }

    .content_img {
      display: block;
      max-width: 100%;
      width: 100%;
      border-radius: 6px;
    }
  }

}
