.already_live_modal {

  .modal-dialog {
    padding: 1.5rem;
  }

  .main_title {
    text-align: center;
    padding-bottom: .5rem;

    @include media($break480) {
      font-size: 1.6rem;
    }
  }

  .btn_close_modal {
    background: none;
    padding: 10px;
    position: absolute;
    right: 5px;
    top: 5px;

    &:hover {
      background-color: $ultraLight_gray;
    }
  }

  .actions_container {
    text-decoration: none;
    text-align: center;
    padding: 1rem 0 .5rem;

    .reactivate_btn {
      margin-right: .5rem;
    }
  }

  .icon_warning {
    width: 26px;
    vertical-align: -2px;
  }

  .icon_close {
    width: 14px;
  }
}
