.faq {

	.faq_wrapper {
		max-width: 1000px;
		margin: 100px auto;

		@include media($break1015) {
			padding: 0 2%;
		}
	}

	.faq_header {
		margin-bottom: 60px;

		.main_title {
			font-size: 4em;
			font-weight: 300;
			text-align: center;
			color: $dark-text;
		}

		.small_pageNav_container {
			display: none;
			margin-top: 28px;

			@include media($break650) {
				display: block;
				width: 65%;
				margin: 30px auto 0;
			}

			@include media($break485) {
				margin: 30px 0 0 0;
				display: block;
				width: 90%;
			}

			.select {
				margin: 0 auto;

				select {
					padding-right: 3.5rem;
					text-overflow: ellipsis;
				}
			}
		}
	}

	.main_container {
		overflow: hidden;
		position: relative;

		.faq_nav {
			width: 30%;
			float: left;
			margin-right: 7%;

			@include media($break650) {
				display: none;
			}

			.faq_nav_list {
				list-style-position: inside;
				font-weight: 300;
			}

			.faq_nav_item {
				font-size: 1.35em;
				line-height: 1.5em;
				color: $dark-text;
				border-bottom: 1px solid #E9E9E9;
			}

			.faq_nav_link {
				text-decoration: none;
				color: $revfluence-blue;
				padding: 1em 2em 1em 1em;
				display: block;
				background: url('/images/faq/right_arrow.svg') 97% center no-repeat;
				background-size: 3%;
				cursor: pointer;

				&:hover {
					color: $revfluence-blue-hover;
					background-color: #FDFDFD;
				}
			}
		}

		.faq_items_container {
			float: left;
			width: 63%;

			@include media($break650) {
				width: 100%;
			}
		}

		.faq_item {
			border-bottom: 1px solid #E9E9E9;
			margin-bottom: 2em;
			padding-bottom: 2em;
			margin-top: -50px;
			padding-top: 50px;

			.question_title {
				font-size: 1.5em;
				font-weight: 600;
				line-height: 1.5em;
				margin-bottom: .5em;
				color: $dark-text;
			}

			.answer_details {
				font-size: 1.5em;
				font-weight: 300;
				line-height: 1.5em;
				margin-bottom: 1em;
				color: $dark-text;

				&:last-child {
					margin-bottom: 0;
				}
			}

			a {
				color: $revfluence-blue;
				text-decoration: none;

				&:hover {
					color: $revfluence-blue-hover;
				}
			}
		}
	}
}
