.aspireiq_theme {

  .dropdown_container {

    .bottom_dropdown_btn {

      &:hover {
        color: $aiq_darkblue;
        background-color: $aiq_lightblue_bg;
      }
    }
  }

  .dropdown_list {

    li {

      a {
        font-weight: 400;

        &:hover {
          color: $aiq_darkblue;
          background-color: $aiq_lightblue_bg;
        }

        &:active, &:focus {
          background-color: $aiq_lightblue_bg;
          color: $aiq_darkblue;
        }
      }

      a.active {
        background-color: $aiq_darkblue;
      }
    }

    li>button {

      &:hover {
        color: $aiq_darkblue;
        background-color: $aiq_lightblue_bg;
      }
    }

    .selected_item {

      a {
        background-color: $aiq_darkblue;
      }
    }
  }

  .inbox_filters {

    .campaign_selection_container {

      .campaign_dropdown_list {

        .active_campaign_btn {
          background-color: $aiq_darkblue;
        }
      }
    }
  }

  .doubleArrow_select_container .doubleArrow_dropdown .doubleArrow_dropdown_btn:hover:not(.btn_disabled) {
    color: $aiq_darkblue;
    background-color: $aiq_lightblue_bg;
  }

  .doubleArrow_dropdown {

    .doubleArrow_dropdown_btn {

      &:hover {
        color: $aiq_darkblue;
        background-color: $aiq_lightblue_bg;
      }
    }
  }

  .dropdown_accord {

    .active_toggle_btn {
      color: $aiq_darkblue;
    }

    .accordItem {

      label {

        &:hover {
          color: $aiq_lightblue_bg;
        }
      }
    }
  }

  .doubleArrow_toggle {

    &:hover {
      background: $aiq_lightblue_bg url("/images/style_guide/icon_select_doubleArrow_black.svg") 97% center no-repeat;
    }
  }

  .dropdown_btn, .dropdown_btn2 {
    background-color: $aiq_lightblue_bg;
    border: none;
    border-radius: 100px;
    color: $aiq_black;

    &:hover, &:focus {
      background-color: $aiq_lightblue_bg_hover;
    }

    &:active {
      background-color: $aiq_lightblue_bg;
    }
  }

  .dropdown_btn2 {
    padding: .5rem 1.75rem .45rem .75rem;

    .icon_dropdown {
      right: 13px;
    }
  }

  .network_type_container {

    .doubleArrow_select_btn {
      background-color: $aiq_lightblue_bg;
      border-radius: 100px;
      border: none;

      &:hover {
        background-color: $aiq_lightblue_bg_hover;
      }

      &:active {
        background-color: $aiq_lightblue_bg;
      }
    }
  }

  .btn_hoveredIcon {

    .icon_hovered {
      display: none;
    }

    &:hover {

      .icon_hovered {
        display: none;
      }

      .icon_unhovered {
        display: inline-block;
      }
    }
  }

  .dropdown_accord {

    .accordItem {

      label {

        &:hover {
          color: $aiq_darkblue;
        }
      }
    }
  }

  /* Starter HTML

  <div class="dropdown_container">
    <button class="dropdown_btn2 btn">
      <img src="/images/style_guide/icon_singleuser_gray.svg" class="icon_user dropdownLeft_icon">
      <span class="btn_txt">Button</span>
      <img src="/images/style_guide/icon_dropdownIndicator_gray.svg" class="icon_dropdown">
      <img src="/images/style_guide/icon_doubleArrow_gray.svg" class="icon_doubleArrow">
    </button>
    <ul class="dropdown_list">
      <li class="dropdown_li">
        <a href title class="">
          <img src="/images/style_guide/.icon_singleuser_gray.svg" class="icon_user dropdownLeft_icon">
        </a>
      </li>
    </ul>
  </div>

  */

  .dropdown_btn_with_icon {
    background-image: url('/images/icons/outline/flag.svg');
    background-position: 12px center;
    background-repeat: no-repeat;
    background-size: 20px;
    padding-left: 2.25rem;
  }
}
