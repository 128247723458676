.resources {

    .signed_up_header {
        max-width: 1400px;
        margin: 0 auto;
    }

  .main_header {
    text-align: center;
    padding: 6.5rem 0 2.5rem;

    @include media($break1000) {
      padding: 5.5rem 0 1.5rem;
    }

    @include media($break600) {
      padding: 4.5rem 0 1rem;
    }

    .main_title {

      @include media($break600) {
        font-size: 800;
        font-size: 2.3rem;
      }
    }
  }

  .main_content {
    max-width: 1400px;
    padding: 0 2rem 2rem;
    margin: 0 auto;

    @include media($break600) {
      padding: 0 1rem 2rem;
    }

    .main_content_list {}

    .main_content_item {
      float: left;
      width: 24%;
      margin-right: 1%;
      margin-bottom: 2rem;
      height: 440px;

      &:hover {
        background-color: $ultraLight_gray;
      }

      &:active {
        background-color: white;
      }

      @include media($break1050) {
        width: 32%;
      }

      @include media($break800) {
        width: 49%;
      }

      @include media($break575) {
        width: 100%;
        margin-right: 0;
        height: auto;
      }

      .resource_image {
        padding-top: 65%;
        max-width: 100%;
      }

      .txt_container {
        padding: 1rem;

        @include media($break575) {
          padding: 1rem 0;
        }
      }
    }

    a {
      color: $dark-text;
      text-decoration: none;
      display: block;
      border-radius: 6px;
    }


    .contentTitle {
      font-size: 1.15rem;
      padding-bottom: .5rem;
      line-height: 1.55rem;
      font-weight: 800;

      @include media($break575) {
        font-weight: 600;
        font-size: 2em;
        height: auto;
        line-height: 1.75rem;
      }
    }

    .contentDescrip {}
  }

  .welcome_nav {
    background: white;
    background-color: rgba(255,255,255,.9);

		.white_logo_link {
			display: none !important;
		}

		.black_logo_link {
			display: inline-block !important;

			.revfluence_logo_black {
				display: block;

				@include media($break600) {
					display: none !important;
				}
			}

			.revfluence_logomark_black {

				@include media($break600) {
					display: block !important;
				}
			}
		}

    .primaryEntry_links {
      vertical-align: 18px;
    }

		.creator_suggest {
			color: $dark-text !important;
			vertical-align: 18px !important;

			a {
				color: $revfluence-blue;
			}
		}

		.entry_link {
			color: $dark-text !important;

			&:hover {
				color: $revfluence-blue !important;
			}
		}

		.smallNav_links {

			.whiteHam_icon {
				display: none !important;
			}

			.blackHam_icon {
				display: block !important;
			}
		}
	}
}
