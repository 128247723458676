.report_name_modal {

  .close-modal-link {
    right: 0;
    position: absolute;
    top: .75rem;
  }

  .modal-dialog {
    padding: 1rem 2rem 2.5rem;
  }

  .main_title {
    padding: 1rem 0 1.7rem;
  }

  .row {
    padding-bottom: 1rem;
  }

  .apply_btn {
    margin-right: .5rem;
  }

  .modal_actions_container {
    padding-top: .5rem;
  }
}
