.quickmatch {

  .recommended_more_info {

    .modal-dialog {
      position: relative;
      z-index: 9999;
      margin: 0 auto;
    }

    .close_modal_link {
      top: 1.5rem;
      right: 1.5rem;
    }

    .modal-body {
      padding: 2rem;
    }

    .back_btn {
      background: none;

      &:active, &:focus {
        box-shadow: none;
      }
    }

    .back_icon {
      position: absolute;
      left: 1.5rem;
      top: 1.5rem;
      width: 25px;
    }

    .creator_username {
      text-align: center;
      margin-bottom: 1rem;
    }

    .creator_avatar {
      display: block;
      width: 70px;
      margin: 0 auto 1rem;
      border: 1px solid white;
      box-shadow: 1px 1px 3px rgba(0,0,0,.25);
      border-radius: 100px;
    }

    .row {
      margin-bottom: 1rem;
    }

    .creator_stat_container {
      float: left;
      width: 50%;
      padding-right: 1rem;
      text-align: center;

      &:last-child {
        padding-right: 0;
      }
    }

    .stat_number, .stat_label {
      display: block;
    }

    .stat_number {
      font-size: 1.6rem;
      margin-bottom: .4rem;
    }

    .stat_label {}

    .avg_views_stat {}

    .creator_stat_container {}

    .creator_bio_txt {}

    .main_actions_list {
      width: 293px;
      margin: 2rem auto 0;

      @include media($break375) {
        width: 227px;
      }
    }

    .recommended_btn {}

    .action_icon {

      @include media($break375) {
        width: 63px;
      }
    }

    .main_action_item {
			float: left;
			margin-right: 2rem;

			&:last-child {
				margin-right: 0;
			}

			@include media($break375) {
				margin-right: 1rem;
			}
		}

    .recommended_btn {
      padding: 0;
    }
  }
}
