.tiktok_send_invitation_modal {
  max-width: 500px;
  min-height: 500px;

  .modal-dialog {
    padding: 2rem 4rem;
  }

  .tiktok_send_invitation_section {
    text-align: center;

    .main_title {
      font-size: 3em;
      margin-bottom: 60px;
    }

    .tiktok_icon {
      width: 69px;
      height: 69px;
      margin: 0 auto;
      border-radius: 12px;
      margin-bottom: 30px;
      background-repeat: no-repeat;
      background-position: center;
      background-color: #000000;
      background-image: url('/images/style_guide/icon_tiktok_black.svg');
    }

    .main_descrip {
      line-height: 18px;
      margin-bottom: 30px;

      .tiktok_handle {
        font-weight: 700;
      }
    }

    .secondary_descrip {
      line-height: 18px;
      margin-top: 30px;
      font-style: italic;
      font-size: 0.75rem;
    }

    .extra_links {
      margin-top: 40px;
    }

    .extra_links a {
      display: block;
      margin-bottom: 20px;
      font-size: 1.5em;
      color: #3996e0;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    .extra_links span {
      display: block;
      margin-bottom: 20px;
      font-size: 1.5em;
      color: #3996e0;
      text-decoration: none;
    }

    .invitation_btn {
      cursor: pointer;
      font-size: 1.5em;
    }
  }
}
