.add_creditCard_modal {

  .modal-dialog {

    @include media($break625) {
      margin: 25px auto;
    }
  }

  .add_creditCard_container {
    background-color: white;
    border: 1px solid $lightgray-border;
    border-radius: 6px;

    .error-container {
      @include errors;
      width: 100%;
    }

    .creditIntroContainer {
      padding: 4em 3em 0;
      margin-bottom: 2em;

      @include media($break960) {
        padding: 4em 5em 0;
      }

      @include media($break625) {
        padding: 2rem 2rem 0;
      }

      .creditIntroTitle {
        text-align: center;
        margin-bottom: .5em;
        line-height: 1.4em;

        @include media($break960) {
          text-align: left;
          margin-bottom: .25em;
        }
      }

      .creditIntroDescrip {
        text-align: center;

        @include media($break960) {
          text-align: left;
        }
      }
    }

    .creditCardForm {
      padding: 0 8em;

      #card-element {
        margin-top: 2em;
        margin-bottom: 2em;
      }

      @include media($break1186) {
        padding: 0 7em;
      }

      @include media($break1141) {
        padding: 0 6em;
      }

      @include media($break1095) {
        padding: 0 5em;
      }

      @include media($break960) {
        width: 570px;
      }

      @include media($break625) {
        width: 100%;
        padding: 0 2rem;
      }


      .row {
        margin-bottom: 2em;
      }

      label {
        @include standard-label;
      }
    }

    input[type="text"] {
      @include standard-input;
      width: 100%;
    }

    .small_inlineFieldset {
      width: 31%;
      float: left;
      margin-right: 3%;

      @include media($break479) {
        width: 30%;
      }

      @include media($break374) {
        width: 100%;
      }
    }

    .medium_inlineFieldset {
        width: 45%;
        display: inline-block;
        margin-right: 1em;
        vertical-align: top;

        @include media($break374) {
            width: 100%;
        }
    }

    .noMarginRight {
      margin-right: 0;
    }

    .widthAdjuster {
      @include media($break960) {
        width: 32% !important;
      }
    }

    .actions_container {
      padding-bottom: 2.25rem;
      text-align: center;
    }

    .startTrialButton, .cancel_btn {
      display: inline-block;
    }

    .startTrialButton {
      margin-right: .5rem;
    }

    .startTrialButtonSmall, .cancel_btn {
      display: inline-block;
    }

    .startTrialButtonSmall {
      margin-right: .5rem;
    }

    .cancel_btn {}
  }
}
