.bestPerform {

  .bestPerform_list {

    .bestPerform_item {
      position: relative;
      float: left;
      width: 32%;
      margin-right: 2%;
      border-radius: 3px;
      cursor: pointer;

      &:hover {

        .hoverContent {
          opacity: 1;
        }

        .shadowsWrap {
          display: block;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
