.creator_detail_modal {
  overflow: auto;

  .close-modal-link {
    position: absolute;
    right: .85rem;
    top: .55rem;
    padding: .5rem;
    background: #E0E0E0;
    background: rgba(224,224,224,.6);
    z-index: 9999;
    padding: .5rem !important;
    width: auto !important;

    @include media($break1000) {
      right: .25rem;
    }

    .icon_close_modals {
      width: 20px;
    }
  }


  .modal-dialog {
    max-width: 1165px;
    padding: 1rem 0 2rem;
    background: none;
    box-shadow: none;
    position: relative;
    margin: 0 auto;
    top: initial;
    left: initial;
    transform: none;
    overflow: visible;

    @include media($break650) {
      padding: 3.25rem 0;
    }

    @include media($break450) {
      width: 100%;
    }
  }

  .modal-content {}

  .creatorPreview_row1 {
    padding-bottom: 3.25rem;

    .admin_link {
        display: block;
        margin-top: 1em;
        font-size: 1.25em;
        text-decoration: none;
        color: $revfluence-blue;
        padding-bottom: 1rem;

        &:hover {
            color: $revfluence-blue-hover;
        }
    }

    .col {
      padding: 1.65rem;

      @include media($break600) {
        padding: 1.25rem .75rem;
      }
    }

    .col1, .col2 {

      @include media($break1000) {
        width: 100%;
      }
    }

    .col1 {
      background-color: white;
      border-radius: 6px;
      margin-right: 2%;
      padding: 1rem 1.65rem;
      width: 44%;
      float: left;

      @include media($break1000) {
        margin-right: 0;
        margin: 0 auto 1rem;
        max-width: 580px;
        float: none;
        width: 100%;
      }

      @include media($break500) {
        padding: 1rem .65rem;
      }

      .icon_reject, .icon_hide, .icon_messages, .icon_offer, .icon_invite {
        margin-right: 3px;
      }

      .mainActions_container {
        padding-bottom: 1rem;

        .square_btn {
          height: 40px;
          padding: 6px 15px;

          @include media($break400) {
            font-size: .8rem;
            padding: 6px 10px;
            height: 35px;
            border-radius: 4px;
          }
        }

        .leftActions {
          float: left;

          .reject_btn {
            padding: 7px 16px 6px 16px;
          }

          .leftActions_list {}

          .favBtn {
            padding: 11px;
            vertical-align: -6px;
            margin-bottom: 0;
          }

          .favDropdown_list {
            top: 26px;
            left: 3px;
          }

          .leftActions_item {
            display: inline-block;
            margin-right: .25rem;

            &:last-child {
              margin-right: 0;
            }
          }

          .reject_item, .hide_item, .viewMsg_btn {}

          .leftActions_item {}

          .hide_btn, .viewMsg_btn {
            color: $dark-text;
          }

          .hide_btn {}

          .viewMsg_btn {}

          .moreOptions_dropdown {}

          .moreOptions_btn {}

          .icon_hide {
            width: 22px;
            vertical-align: -5px;
          }

          .icon_messages {
            vertical-align: -4px;
          }

          .icon_ellipses {}

          .special_item {
            display: none;

            @include media($break1400) {
              display: block;
            }
          }

          .border_item {
            padding-bottom: .25rem;
            border-bottom: 1px solid $lightgray-border;
            margin-bottom: .25rem;
          }

          .moreOptions_list {
            top: 43px;
            left: 0;

            &:before, &:after {
              left: 12px;
            }
          }

          .icon_hide, .icon_messages {
            width: 19px;
          }

          .icon_reject {
            margin: 0 6px 0 0 !important;
            width: 16px !important;
            vertical-align: -1px;

            @include media($break400) {
              vertical-align: -5px;
            }
          }
        }

        .rightActions {
          float: right;

          .rightActions_list {}

          .rightActions_item {
            display: inline-block;
            margin-right: .25rem;

            &:last-child {
              margin-right: 0;
            }
          }

          .invitedBtn, .offeredBtn {
            color: #A2A2A2;
            background-color: $lightgray-border !important;
          }

          .invited_icon, .offered_icon {
            display: none;
          }

          .invitedBtn, .offeredBtn {

            &:hover {
              background: #F6F6F6;
            }

            .icon_invite, .offer_icon {
              display: none;
            }

            .invited_icon, .offered_icon {
              display: inline-block;
            }

            .invited_icon {
              width: 23px;
              vertical-align: -2px;
            }
          }

          .invitedBtn {
            background-color: white;
          }

          .offer_btn {}

          .icon_offer {
            vertical-align: -2px;
          }

          .invite_btn {}

          .icon_invite {
            margin: 0 .25rem 0 0 !important;
          }
        }
      }

      .creatorContent_preview {
        margin-bottom: 1rem;

        .topControls_container {
          display: none;
        }

        .previewContent_list {
          overflow: hidden;
          width: 100%;
        }

        .previewContent_item {
          width: 10.73%;
          opacity: .6;
          transition: all .15s ease-in-out;

          &:hover, &:focus, &:active {
            opacity: 1;
          }
        }

        .active_previewContent_item {
          opacity: 1;
        }

        .contentNav_btn {
          padding: 2.5% 3% 3.5%;
          top: 25%;

          @include media($break400) {
            margin-top: -19px;
            top: 50%;
          }
        }

        .previewContent_container {
          position: relative;
          opacity: 1;
          padding-top: 5px;
        }
      }

      .name_container {
        color: $dark-text;
        padding: 0;

        .creator_name {
          font-size: 1.2rem;
          font-weight: 600;
          margin-bottom: .25rem;
          display: inline-block;
          max-width: calc(100% - 50px);
          line-height: 1.5rem;
        }

        .creator_tags {}

        .creator_tag_item {
          display: inline-block;
        }

        .favBtn_dropdown_container {
          display: inline-block;
          vertical-align: 12px;
        }

        .favDropdown_list {
          top: 35px;
          left: -3px;
        }

        .dark_favBtn {
          padding: 8px;
          vertical-align: -3px;
        }

        .icon_fav {
          width: 19px;
          height: 17px;
        }
      }
    }

    .col2 {
      width: 54%;
      padding: 1rem 1.65rem 1.65rem;
      float: left;

      @include media($break1000) {
        margin-right: 0;
        margin: 0 auto;
        max-width: 580px;
        float: none;
        width: 100%;
      }

      @include media($break500) {
        padding: 1rem .65rem 1.65rem;
      }

    }

    .data_preview {
      background: #FBFBFB;
      border-radius: 6px;

      .socialAccounts_title {
        font-weight: 300;
        margin-bottom: 1.1rem;
        padding-top: .5rem;
        font-size: 1.25rem;
      }

      .socialAccts_list {}

      .socialAcct_item {
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .breakdown_sec {
        padding: 1rem 0;
        float: left;
        width: 100%;

        .audBreakdown_header {
          border-bottom: 1px solid $lightgray-border;
          text-align: center;
          position: relative;
          margin: .75rem 0 1.5rem;
        }

        .colWrap {
          padding-top: .75rem;
        }

        .audBreakdown_title {
          padding: .5rem 1rem;
          background-color: white;
          margin-top: -5px;
          color: #AEAEAE;
          position: absolute;
          left: 50%;
          top: -15px;
          margin-left: -70px;
          font-weight: 500;
        }

        .igInsights_wrap {
          display: inline-block;
          vertical-align: 3px;
          margin-left: .35rem;

          .tooltip {
            width: 186px;
            left: -80px;

            &:before, &:after {
              left: 82px;
            }

            &:before {
              bottom: -10px;
            }
          }
        }
      }

      .low_item {
        color: $error_red;
      }

      .high_item {
        color: $emeraldgreen;
      }

      .med_item {
        color: $marigold;
      }

      .socialAcct_header {}

      .disclosure_icon {
        width: 15px;
        vertical-align: 2px;

        @include media($break460) {
          vertical-align: 27px;
        }
      }

      .network_icon {
        width: 24px;
        margin-right: .35rem;
        vertical-align: -5px;

        @include media($break460) {
          vertical-align: -2px;
          width: 20px;
        }
      }

      .username, .likesPerPost, .followers {
        vertical-align: 2px;
      }

      .username, .likesPerPost {

        @include media($break460) {
          display: block;
          margin-top: .5rem;
        }
      }

      .username {
        font-weight: 600;
        margin-right: .5rem;

        @include media($break460) {
          display: inline-block;
          margin-top: 0;
        }
      }

      .likesPerPost {
        margin-right: .5rem;
      }

      .followers {}

      .accordionMain_content {
        background: white;
        padding: 1.5rem 2rem;

        @include media($break460) {
          padding: 1rem;
        }
      }

      .dataPreview_col {
        float: left;
        width: 49%;

        @include media($break460) {
          width: 100%;
          padding-bottom: 1rem;
        }
      }

      .leftCol {
        margin-right: 2%;

        @include media($break460) {
          margin-right: 0;
        }
      }

      .rightCol {}

      .dataSet {
        padding-bottom: 2rem;

        &:last-child {
          padding-bottom: 0;
        }
      }

      .icon_container, .txt_container {
        float: left;
      }

      .icon_container {
        width: 23px;
        margin-right: 1.25rem;

        @include media($break460) {
          width: 23px;
          margin-right: .75rem;
        }
      }

      .dataSet_icon {
        width: 23px;

        @include media($break460) {
          max-width: 100%;
        }
      }

      .savedPost_icon {
        width: 21px;
        display: block;
        margin: 0 auto;

        @include media($break460) {
          width: 16px;
        }
      }

      .likesPerPost_icon {
        width: 30px;

        @include media($break460) {
          max-width: 100%;
        }
      }

      .smaller_dataSet_icon {
        width: 26px;

        @include media($break460) {
          max-width: 100%;
        }
      }

      .txt_container {
        width: calc(100% - 50px);
      }

      .dataSet_title {
        font-weight: 700;
        display: block;
        padding-bottom: 5px;

        .tooltip_container {
          margin-left: .15rem;
        }

        .tooltip {
          left: -108px;
          bottom: 33px;
          text-align: center;

          &:before, &:after {
            left: 108px;
            bottom: -10px;
          }
        }
      }

      .dataSet_title, .dataSet_list, .dataSet_amount {
        font-size: .85rem;
      }

      .mentionedBrandLink {
        text-decoration: none;
        color: $dark-text;
      }

      .dataSet_amount, .dataSet_item {
        display: block;
        padding: 0;
      }

      .dataSet_item {
        padding-bottom: .25rem;
      }

      .green_number {
        color: #20A962;
      }

      .gender_icon {
        width: 17px;
        vertical-align: -2px;

        @include media($break460) {
          max-width: 100%;
        }
      }

      .topBrand_logo {
        width: 25px;
        margin-right: 5px;
        vertical-align: -8px;
      }

      .standardAccordion {
        padding: 1px;
      }
    }
  }

  .creatorPreview_row2 {

    .mentionsList {

      .contentNav_btn {
        display: block;
      }
    }
  }

  .creator_list_container {
    width: 100% !important;
    float: none !important;
    padding-left: 0 !important;
    border-left: none !important;
  }

  .contentNav_btn {
    display: none;
  }

  .mentionsTitle {
    font-size: 1.25rem;
    font-weight: 600;
    padding-bottom: 1.65rem;
  }

  .mentionsList {

    .contentNav_btn {
      display: block;
    }
  }

  .detail_view_version {
    display: block;
  }

  .secondaryActions_container {}
}

.creator_preview_overlay {
  background: #E0E0E0;
  background: rgba(224,224,224,.96);
}
