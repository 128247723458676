.noContent_contentList_container {
  text-align: center;
  margin: 0 0 1rem;

  .noContent_title {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 1rem !important;
  }

  .noContent_descrip {
    margin-bottom: 0;
  }
}

/* NEW CONTENT LIST */
.content_list {

  .content_item {
    width: 31.33%;
    float: left;
    margin: 0 2% 1rem 0;

    @include media($break700) {
      width: 48%;
    }

    @include media($break500) {
      width: 100%;
    }
  }

  .likeContent_btn {}

  /* Add First Snap */

  .addFirst_snap_item {
    border: 2px dashed $lightgray-border !important;
    background-color: $ultraLight_gray !important;
    padding: 15px 25px;
    height: 250px;

    &:hover {
      background-color: $pressedBtn_gray !important;
    }

    .addFirst_snap_btn {}

    .add_icon {
      width: 32px;
      margin-bottom: 0;
      padding-top: 48px;
      padding-bottom: 31px;
    }

    .addFirst_snap_txtContainer {
      padding-bottom: 35px;
    }

    .addFirst_snap_title {
      font-weight: 600;
      display: block;
    }

    .addFirst_snap_descrip {
      font-size: .85rem;
      display: block;
      line-height: 1.15rem;
    }
  }

  .engageList {
    padding: .3rem 0;
    width: 100%;
    float: left;

    &.editMode {
      width: calc(100% - 49px);
    }

    .engageItem {
      display: inline-block;
      margin-right: 1rem;
      height: 30px;

      &:last-child {
        margin-right: 0;
      }
    }

    .updateViews_item {
      padding-left: .5rem;
    }

    .engageIcon {
      width: 16px;
    }

    .addViews_item {}

    .commentIcon {
      vertical-align: -2px;
    }

    .loveIcon {
      vertical-align: -3px;
    }
  }
}

.creator_profile {

  .creator_content_list {

    .creator_content_item {
      width: 30.33%;
      float: left;
      margin-right: 3%;
      margin-bottom: 1.25rem;
    }

    .content_link {
      color: $dark-text;
      text-decoration: none;
    }

    .video_descrip {
      display: none;

      @include media($break875) {
        display: block;
      }
    }

    .creator_content {
      width: 100%;
      max-width: 100%;
      display: block;

      @include media($break875) {
        margin-bottom: 0;
      }

      &:hover {
        opacity: .87;
      }
    }

    .youtube_content {
      max-width: 100%;
    }

    .content_stats_list {
      border-bottom: 1px solid $lightgray-border;
      padding: 0.3rem 0;
      display: block;

      .mobile_only {
        display:none;
        @include media($break875) {
          display: block;
        }
      }

      .content_stats_item {
        float: left;
        margin-right: 1rem;

        &:last-child {
          margin-right: 0;
        }
      }

      em {
        font-weight: 600;
      }
    }
  }

  .instagram_grid_layout {

    @include media($break875) {

      .creator_content_item, .creator_content {
        margin-bottom: 0;
        margin-right: 0;
      }

      .creator_content_item {
        float: left;
        width: 33.33%;
      }

      .content_caption_txt, .content_stats_list {
        display: none;
      }
    }
  }

  .instagram_detailed_layout {

    @include media($break875) {

      .creator_content_item {
        float: none;
        width: 100%;
      }

      .content_stats_list {
        padding: .65rem 0 .8rem;
        margin: 0 1rem;
      }

      .content_caption_txt {
        padding: .6rem 1rem .75rem;
      }
    }
  }

  .youtube_list_layout {

    .youtube_stats_list {
      display: none;
    }

    @include media($break875) {
      padding: 1rem;

      .creator_content_item {
        width: 100%;
        margin-right: 0;
      }

      .video_thumbnail {
        float: left;
        width: 150px;
        margin-right: 1rem;
      }

      .info_container {
        float: left;
        width: calc(100% - 166px);
        font-size: .85em;
      }

      .content_caption_txt {
        font-weight: 600;
      }

      em {
        font-weight: 600;
      }

      .content_caption_txt {
        margin-bottom: .4rem;
      }

      .youtube_stats_list {
        display: block;
      }

      .youtube_stat_item {}
    }
  }

  .content_moreOptions_dropdown_container {
    float: right;
    position: relative;

    .content_moreOptions_btn {
      background: none;
      border-radius: 0;
      border: none;
      margin-bottom: 0;
      max-width: 100%;
      padding: 22px 15px;
    }

    .moreOptions_icon {
      width: 19px;
    }

    .content_moreOptions_dropdownList {
      display: block;
      right: -2px;
      top: 42px;
    }

    .dropdown_li {

      a {
        line-height: 1rem;
      }
    }

    .icon_trash {
      width: 15px;
      vertical-align: -2px;
    }
  }

  .youtube_detailed_layout {

    @include media($break875) {

        .creator_content_item {
          width: 100%;
          float: none;
          margin-right: 0;
        }

        .content_caption_txt {
          font-weight: 600;
          padding: .6rem 1rem .25rem;
        }

        .content_stats_list {
          padding: .6rem 0 .75rem;
          margin: 0 1rem;
          border-top: 1px solid $lightgray-border;
        }

        .content_caption_txt {
          padding: .6rem 1rem .75rem;
        }

        p {
          padding: .6rem 1rem 1rem;
        }

        .content_stats_item {

          @include media($break400) {
            font-size: .85rem;
          }

          @include media($break340) {
            font-size: .7rem;
            margin-right: .5rem;
          }
        }
    }
  }
}
