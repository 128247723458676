.analyze_view {

    /* =============================================================================
       CAMPAIGN RESULTS DIV
       ========================================================================== */

    .campaignResults {
        position: relative;
        width: 70%;
        min-width: 62.5em;
        margin: auto;
    }

    /* =============================================================================
       CHART
       ========================================================================== */

    .chartdiv {
        width: 100%;
        height: 24em;
        margin: 1em auto 2em;
        opacity: 0;
        text-align: center;
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -ms-transition: all 1s ease;
        -o-transition: all 1s ease;
    }

    .chartdiv.load {
        opacity: 1;
        -webkit-transition-delay: .4s;
        transition-delay: .4s;
        position: relative;
    }

    .chartOptions {
        border: 1px solid #ddd;
        border-radius: 0.125em;
        color: #aaa;
        width: 28em;
        overflow: auto;
        margin: auto;
        text-align: center;
        background-color: #ddd;
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.3) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.5)), color-stop(100%, rgba(0, 0, 0, 0.3))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.3) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.3) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.3) 100%); /* IE10+ */
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.3) 100%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#4d000000', GradientType=0); /* IE6-9 */
    }

    .chartOptions span {
        display: table-cell;
        border-right: 1px solid #ddd;
        width: 8em;
        padding: 0;
        font-size: 0.875em;
        line-height: 2em;
        cursor: pointer;
        color: #6b6b6b;
        background: rgb(255, 255, 255);
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(250, 250, 250, 1) 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(250, 250, 250, 1)));
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(250, 250, 250, 1) 100%);
        background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(250, 250, 250, 1) 100%);
        background: -ms-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(250, 250, 250, 1) 100%);
        background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(250, 250, 250, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#fafafa', GradientType=0);
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -ms-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
    }

    .chartOptions span.selected {
        background-color: #fff;
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.3) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.5)), color-stop(100%, rgba(0, 0, 0, 0.3))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.3) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.3) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.3) 100%); /* IE10+ */
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.3) 100%); /* W3C */
        color: rgba(255, 255, 255, 1.0);
    }

    .chartOptions span:first-child {
        border-radius: 0.125em 0 0 0.125em;
    }

    .chartOptions span:last-child {
        border-radius: 0 0.125em 0.125em 0;
        border-right: none;
    }

    /* Copyright 2012 Google Inc. All Rights Reserved. */

    .google-visualization-tooltip {
        border: solid 1px #ddd !important;
        border-radius: 0.3125em !important;
        background-color: white !important;
        padding: 0 !important;
        width: auto;
        box-shadow: 0 0 0.5em 0 rgba(0, 0, 0, 0.1) !important;
        -moz-box-shadow: 0 0 0.5em 0 rgba(0, 0, 0, 0.1) !important;
        -webkit-box-shadow: 0 0 0.5em 0 rgba(0, 0, 0, 0.1) !important;
    }

    .loadingtext {
        margin-top: 0.5em;
        color: #6b6b6b;
        font-weight: 400;
        text-align: center;
        font-size: 1.25em;
    }

    .getBetterAnalytics {
        margin: 30px auto 10px;
        line-height: 2.0em;
        font-weight: 300;
    }

    .creatorTableSpinner {
        margin: 20px auto;
    }

    .authLink {
        color: #6b6b6b;
        font-weight: bolder;
    }

    .spinnerdiv {
        position: absolute;
        left: 0;
        top: 70px;
        right: 0;
        bottom: 0;
        height: 10em;
        margin: auto;
        .newSpinner {
            width: 60px;
        }
    }
}