.aspireiq_theme {

  .creator_main_nav {

    .revfluence_logo {
      margin-top: .6rem;

      .revfluence_logo_link {
        background: url('/images/themes/aspireiq/aiq_logo.png') center no-repeat;
        background-size: 100%;
        width: 133px;
        height: 31px;
        transition: all .25s ease-in-out;

        &:hover {
          opacity: .7;
        }
      }
    }

    .largeScreen_nav_links {

      .help_li {

        .help_dropdown_list {

          .help_dropdown_item {

            .help_dropdown_link {
              font-weight: 400;

              &:hover {
                color: $aiq_darkblue;
                background-color: $aiq_lightblue_bg;
              }
            }
          }
        }
      }

      .account_li {

        .accountDropdown_list {

          li {

            a {
              font-weight: 400;

              &:hover {
                color: $aiq_darkblue !important;
                background-color: $aiq_lightblue_bg !important;
              }
            }
          }
        }
      }

      li {

        .messages_number {
          background-color: $aiq_darkblue;
        }

        .active {
          color: $aiq_darkblue;
        }

        a {
          font-weight: 400;

          &:hover {
            color: $aiq_darkblue;
          }
        }
      }
    }
  }

  .main_nav {
    border-bottom: 1px solid $aiq_border;

    .logo_link {
      display: none;
    }

    .core_link {
      color: $aiq_black;

      &:hover {
        color: $aiq_darkblue;
      }
    }

    .active.core_link {
      color: $aiq_darkblue;

      &:hover {
        color: $aiq_lightblue;
      }
    }

    .help_li {

      .active {
        color: $aiq_darkblue;
      }

      .help_link {
        cursor: default;

        &:hover {
          color: $aiq_black;
        }
      }
    }
  }

  .core_subnav {

    .core_links_container {

      .core_link {
        color: $aiq_black;

        &:hover {
          color: $aiq_darkblue;
        }
      }

      .active_core_link {
        color: $aiq_darkblue;

        &:hover {
          color: $aiq_darkblue;
        }
      }
    }

    .brandSummary_report_link {

      &:hover {
        color: $aiq_darkblue;
      }
    }

    .campaignSelection_dropdownBtn {

      .campaignBtn_title {
        color: $aiq_black;
        font-weight: 400;
        margin-top: 3px;
      }
    }

    .brandSummary_report_link {
      color: $aiq_black;
    }
  }

  .campaign_selection_container {

    .campaign_dropdown_list {

      .active_campaign_btn {
        background-color: $aiq_darkblue;
      }
    }
  }

  .large_nav_links {

    .start_subscription_item {
      padding: 8px 0 8px 10px;

      .btn_primary {
        padding: 8px 18px 8px 18px;
      }
    }

    .help_li {

      .help_dropdown_list {

        .help_dropdown_item {

          .help_dropdown_link {
            font-weight: 400;

            &:hover {
              color: $aiq_darkblue;
              background-color: $aiq_lightblue_bg;
            }
          }
        }
      }
    }

    .main_nav_li {

      .messages_number {
        background-color: $aiq_darkblue;
        font-weight: 600;
      }
    }
  }

  .campaignNav_selection_container {

    .campaign_dropdown_list {

      .active_campaign_btn {
        background-color: $aiq_darkblue;

        &:hover {
          background-color: $aiq_lightblue;
        }
      }
    }
  }

  .advertiser_nav {

    .brandSwitcher_nav_item {

      .active_brand_indicator {
        border-left: 4px solid $aiq_darkblue;
      }
    }
  }

  .core_subnav {
    background-color: $aiq_lightblue_bg;
    background-color: rgba(239, 245, 249, .98);
    border-bottom: none;
  }

  .help_center_link {
    color: $aiq_black;

    &:hover {
      color: $aiq_darkblue;
    }

    .icon_help_center {
      width: 20px;
      vertical-align: -4px;
    }

    .help_link_txt {
      vertical-align: 1px;
      font-weight: 400;
    }
  }

  .desktopHelp_center_link {
    margin-top: 3px;
  }

  .campaignSelection_dropdownBtn {

    .campaignBtn_title {
      font-weight: 600;
      color: $aiq_black;
    }

    &:hover {

      .campaignBtn_title {
        color: $aiq_darkblue;
      }
    }
  }

  .campaign_navbar {

    .campaignSelection_dropdownBtn {
      color: $aiq_black;

      &:hover {
        color: $aiq_darkblue;
        background-color: $aiq_lightblue_bg;

        .campaignBtn_title {
          color: $aiq_darkblue;
        }
      }
    }
  }

  .main_nav, .creator_main_nav {

    .small_nav_links {

      .menu_dropdown {

        a.active {
          color: $aiq_darkblue;
          background-color: $aiq_lightblue_bg;
        }

        .messages_number {
          background-color: $aiq_darkblue;
        }
      }
    }

    .aspireiq_logolink {
      display: block;
      padding: 10px 12px;

      @include media($break500) {
        padding: 8px;
      }
    }

    .aiq_logo {
      width: 93px;
      margin-top: -2px;

      @include media($break565) {
        display: none;
      }
    }

    .aiq_logomark {
      display: none;
      width: 32px;
      background: none;

      @include media($break565) {
        display: inline-block;
      }
    }
  }

  .creator_main_nav {

    .aspireiq_logolink {
      display: inline-block;
      float: left;
    }
  }
}
