.manageSocial_accounts_step {
  margin: 50px auto;

  .manageSocial_accounts_header {
    padding: 1rem 2rem .5rem;
    text-align: center;

    @include media($break575) {
      padding: 1rem 1rem .5rem;
    }

    .manageSocial_title {
      padding-bottom: .5rem;

      @include media($break575) {
        font-size: 1.6rem;
      }
    }

    .manageSocial_subtitle {
      padding-bottom: .5rem;

      @include media($break575) {
        font-size: 1.2rem;
      }
    }

    .addedAccount_success_msg {
      font-weight: 500;
    }

    .failedto_add_msg {

      a {
        color: white;
        text-decoration: underline;
      }
    }
  }

  .main_content {
    padding: 0 2rem 2rem;

    @include media($break575) {
      padding: 0 1rem 2rem;
    }

    .checkbox_control {
      margin: .25rem 0;
    }

    .row {
      padding-top: .25rem;
    }

    .youtube_warning {
      padding: .25rem 0 .5rem;
    }

    .addedAccounts_col, .addAccounts_col {
      width: 47%;
      float: left;

      @include media($break575) {
        float: none;
        width: 100%;
      }
    }

    .section_title {
      font-weight: 600;
      font-size: 1.05rem;
      text-align: center;
      padding-bottom: .6rem;
    }

    /* ADDED ACCOUNTS */
    .addedAccounts_col {
      margin-right: 3%;

      .newlyAdded_accounts_container {
        margin-bottom: 1rem;
        border-bottom: 1px solid $lightgray-border;
        padding-bottom: 1.5rem;
      }

      /* MOBILE ONLY: ADD ACCOUNTS BUTTON */
      .mobileOnly_addAccounts {
        display: none;

        @include media($break575) {
          display: block;
          padding-bottom: 1rem;
          border-bottom: 1px solid $lightgray-border;
          margin-bottom: 1rem;
        }
      }

      .addedAccounts_item {
        position: relative;
        padding: .7rem;
        border: 1px solid $lightgray-border;
        border-radius: 6px;
        margin-bottom: .6rem;

        &:hover {
          background-color: #fbfbfb;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .notAddedAccounts_item {
        background-color: lightgray;

        &:hover {
          background-color: lightgray;
        }
      }

      .noAccounts_item {
        border: 1px dashed $lightgray-border;
      }

      .instagram_icon {}

      .youtube_icon {}

      .snapchat_icon {}

      .vine_icon {}

      .twitter_icon {}

      .facebook_icon {}

      .blog_icon {}

      .pinterest_icon {}

      .googleplus_icon {}

      .network_icon {
        margin-right: .25rem;
      }

      .manage_network_icon {
        float: left;
        width: 17px;
        margin-right: 6px;
        margin-top: 4px;
      }

      .accountTxt_container {
        float: left;
        width: calc(100% - 40px);
        position: relative;
      }

      .username {
        font-weight: 500;
        display: inline-block;
        max-width: calc(100% - 130px);
        vertical-align: -4px;
      }

      .reachStat_txt {}

      .tooltip_container {
        position: absolute;
        top: .43rem;
        right: .25rem;
      }

      .confirmDelete_tooltip {
        right: -3px;
      }

      .removeAccount_btn {
        background: none;
        padding: 9px;
      }

      .remove_icon {}

      .previouslyAdded_accounts_container {
        margin-bottom: 1rem;
        border-bottom: 1px solid $lightgray-border;
        padding-bottom: 1.5rem;
      }

      .notAdded_accounts_container {
        margin-bottom: 1rem;
        border-bottom: 1px solid $lightgray-border;
        padding-bottom: 1.5rem;

        .not_added_msg {
          margin-bottom: 1rem;
        }

        .not_added_text {
          position: absolute;
          right: .25rem;
        }
      }

      .other_accounts_container {}

    }

    /* ADD ACCOUNTS */
    .addAccounts_col {

      @include media($break575) {
        display: none;
      }

      .addAccounts_list {
        text-align: center;
      }

      .missingNetwork_txt {
        font-size: .85rem;
        padding-top: .5rem;
        text-align: center;
        display: block;
      }

      .addAccounts_item {
        display: inline-block;
        margin: 0 8px 8px 0;
      }

      .auth_instagram_btn {}

      .oauth_btn {
        background-color: white;
        border: 1px solid $lightgray-border;
        padding: 0;
        border-radius: 100px;
        width: 50px;
        height: 50px;
        display: inline-block;

        &:hover {
          background-color: #fbfbfb;
        }
      }

      .newNetwork_indicator {
        position: absolute;
        background-color: $revfluence-blue;
        padding: 4px 5px;
        border-radius: 100px;
        color: white;
        text-transform: uppercase;
        left: initial;
        margin-left: -4px;
        margin-top: 25px;
        bottom: auto;
        line-height: 7px;
        font-size: 9px;
      }

      .instagram_icon {}

      .youtube_icon {}

      .snapchat_icon {}

      .vine_icon {}

      .twitter_icon {}

      .facebook_icon {}

      .blog_icon {}

      .pinterest_icon {}

      .googleplus_icon {}

      .network_icon {}

      .socialNetworks_section {}

      .blog_networks_section {
        border-top: 1px solid $lightgray-border;
        padding-top: 1rem;
        margin-top: 1rem;

        .missingService_txt {
          font-size: .85rem;
          padding-top: .5rem;
          text-align: center;
          display: block;
        }
      }

      .addBlog_accounts_list {}

      .addBlog_accounts_item {
        margin-bottom: .5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .addBlog_btn, .addGoogle_analytics_btn {
        display: block;
        width: 100%;
        font-weight: 500;
        padding: 8px 12px;
      }

      .addBlog_btn {}

      .addGoogle_analytics_btn {}

      .add_standardBlog_icon {
        width: 22px;
        margin-right: 6px;
        vertical-align: -5px;
      }

      .icon_googleAnalytics {
        margin-right: 6px;
        width: 21px;
        border-radius: 3px;
        vertical-align: -4px;
      }
    }
  }

  .mainActions_container {
    border-top: 1px solid $lightgray-border;
    padding: 1.25rem 0;

    .finish_btn {
      width: 200px;
      display: block;
      margin: 0 auto;
    }

    .future_changes_notice {
      text-align: center;
      padding-top: 1.25rem;
    }
  }
}
