.tab_nav {
  border-bottom: 1px solid $lightgray-border;
  margin-bottom: 1.3rem;

  .nav_links_list {

    .nav_links_item {
      display: inline-block;
      margin-left: -4px;

      &:first-child {
        margin-left: 0;
      }
    }

    .nav_link {
      color: $dark-text;
      text-decoration: none;
      padding: .74rem 1rem .94rem;
      display: inline-block;
      border-bottom: 1px solid $lightgray-border;

      &:hover {
        background-color: $lightgray_bg2;
        border-bottom: 1px solid $revfluence-blue;
      }
    }

    .active_link {
      font-weight: 600;
      border-bottom: 1px solid $revfluence-blue;
      background-color: $lightgray_bg2;

      &:hover {
        color: $dark-text;
      }
    }
  }
}
