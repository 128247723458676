.off_platform_pinterest_auth {
  margin: auto;
  padding-top: 50px;
  width: 100%;
  max-width: 800px;

  .businessMigrationSuccessContainer {
    margin-top: 1rem;
  }

  .addDemo_btn {
    margin-bottom: 0.5rem;
  }

  .convertBusinessBtn {
    width: 141px;
  }
}