.aspirex_member_app {
    .authorization_popup {
        .modal-dialog {
            padding: 2rem 2rem 3rem 2rem;
        }

        .add_account_section {
            text-align: center;
            max-width: 550px;

            .main_title {
                font-size: 3em;
            }

            .main_descrip {
                font-size: 1.6em !important;
                line-height: 18px;
                margin-bottom: 2rem;
            }

            .warning {
                font-size: 1.25em;
            }

            .setup_account_form {
                max-width: 350px;
                margin: 0 auto;
            }

            fieldset {
                margin-bottom: 1rem;
            }

            .finish_btn {
                cursor: pointer;
                font-size: 1.5em;
                min-width: 150px;

                .hide {
                    display: none;
                }

                .show {
                    display: block;
                }

                .loading {
                    margin: 0 auto;
                    background-image: url('/images/style_guide/loading_spinner_white.svg');
                    animation: spin 0.7s linear infinite;
                    background-size: 100%;
                    width: 22px;
                    height: 22px;
                }
            }
        }

        .main_descrip {
            font-size: 14px;
            line-height: 22px;
            font-weight: 400;
            color: #595959;
            margin-bottom: 1.5rem;
        }
    }
}