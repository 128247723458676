.aspireiq_theme {

  .brand_profile_header {

    .externalLinks_container {

      .externalLink_txt {

        &:hover {
          color: $aspireiq_persian_blue;
        }
      }
    }
  }
}
