.standard_campaign_details_container {

    h6 {
        @include fontsize16;
        color: #dark-text;
        font-weight: 600;
        padding: 0;
        margin-bottom: 0.2em;
    }

    .section_title {
        margin-top: 1.5em;
    }

    .profileparagraph {
        @include fontsize14;
        margin-bottom: 2.0em;
        text-align: left;
        font-weight: normal;
        width: 100%;
    }

    .profile_subtitle {
        text-align: left;
        font-weight: normal;
        text-color: $dark-text;
        line-height: 1.6em;
    }

    .profile_subparagraph {
        margin-left: 2.5em;
        text-align: left;
        font-weight: normal;
        color : $gray-text;
        width: calc(100% - 3.0em);
    }

    h3 {
        padding: 0;
        text-shadow: 0px 1px 0px white;
        margin: 3px 0 3px;
        text-align: left;
        font-weight: 300;
        width: auto;
    }

    h5 {
        @include title22;
        margin-bottom: 1.0em;
        font-weight: 500;
        color: #aaa;
        font-weight: 300;
    }
}
