.unsubscribe_reason {

	.backdrop-close-link {
		display: none;
	}

	.modal-dialog {
		padding: 50px;
		margin: 40px auto;

		@include media($break635) {
			width: 95%;
		}

		@include media($break575) {
			padding: 35px;
		}
	}

	.main_header {
		margin-bottom: 25px;
		text-align: center;
	}

	p {
		margin-bottom: 35px;
		text-align: center;
	}

	.actions_container {
		text-align: center;
	}

	.checkbox_control {
		width: 100%;
	}

	.btn_primary {
		display: block;
		width: 200px;
		margin: 0 auto 15px;
	}

	.btn_link {
		display: block;
		width: 200px;
		margin: 0 auto;
	}

	.unsubscribe_reason_form {
		width: 280px;
		margin: 0 auto 35px;

		@include media($break410) {
			width: 100%;
		}
	}

	.form_control {
		font-size: 1em;
		margin-top: 10px;
		height: 75px;
		width: 250px;

		@include media($break410) {
			width: 100%;
		}
	}
}