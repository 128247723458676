.new_manage {

	.collab_review_tool {

		@include media($break630) {
			padding: 0;
		}

		.tool_main_container {
			padding-bottom: 2rem;
		}

		.reviewForm {
			padding-top: 1rem;

			@include media($break630) {
				background-color: white;
				@include panel;
				padding: 1rem 1rem 3rem;
			}
		}

		.your_rating_container {
			.star_rating_icon {
				cursor: default;
			}
		}

		.txt_container{
		  font-size:1em;
		}

		.tool_name {

			@include media($break320) {
				margin-top: -5px;
			}
		}

		.tool_descrip {
			margin-bottom: 1em;
		}

		.star_rating_icon {
			width: 38px;
		}

		.your_rating_review {
			font-weight: 600;
			display: block;
			margin-bottom: 10px;
		}

		.goal_text {
			font-size: 15px;
		}

		.goal_dropdown_list {
			width: 320px;
		}

		.goal_dropdown_button {
			width: 320px;
		}

		.review_input_container {
			margin-bottom: 20px;
		}

		.review_input_list {}

		.review_input {
			display: inline-block;
			margin-right: 10px;
			cursor: pointer;

			@include media ($break365) {
				width: 30px;
			}
		}

		.gift_badge_wrap {
			border: 1px solid $lightgray_border;
			border-radius: 6px;
			padding: 1rem;
			max-width: 750px;
			margin: 1.75rem 0 1.5rem;

			.gift_badge_wrap_header {
				text-align: center;
				padding-bottom: 1rem;
			}

			.gift_badge_title {
				font-weight: 700;
				font-size: 1.2rem;
			}

			.gift_badge_descrip {}

			.badge_list {}

			.badge_item {
				float: left;
				margin-right: 2%;
				width: 32%;
				text-align: center;

				&:first-child {

					.tooltip_img_wrap {

						.tooltip_bottomCenter {
							left: -60px;

							&:before, &:after {
								left: 81px;
							}
						}
					}
				}

				&:last-child {
					margin-right: 0;

					.tooltip_img_wrap {

						.tooltip_bottomCenter {
							left: -102px;

							&:before, &:after {
								left: 122px;
							}
						}
					}
				}
			}

			.badge_btn {

				@include media($break600) {
					padding: .5rem;
				}
			}

			.badge_img {

				@include media($break600) {
					width: 50px;
				}
			}

			.badge_txt {

				@include media($break600) {
					font-size: .75rem;
					white-space: initial;
				}
			}

			.giftReason_wrap {
				margin-top: 1rem;

				.standard_label {
					font-weight: 600;
				}

				.error {
					display: flex;
					margin: .75rem 0 .15rem;
				}
			}

			.reason_example {
				padding-top: .5rem;
				font-size: 1.45em;
				display: block;
			}
		}

		.review_input_activated {}

		.additional_comments_container {
			margin-bottom: 15px;
		}

		.additional_comments_label {
			display: block;
			margin-bottom: 10px;
		}

		.additional_comments_input {
			max-width: 475px;
			margin-bottom: 15px;

			@include media($break845) {
				width: 100%;
				height: auto;
			}
		}

		.enteredReview_descrip {
			margin-bottom: 2em;
		}

		.entered_comment {}

		.submit_review_btn {
			display: inline-block;
			margin-right: 0.5rem;
		}

		.their_rating_container {
			margin-top: 30px;
		}

		.their_review_title {
			font-weight: 600;
		}

		.cannot_view_disclaimer {}

		.their_star_rating_container {}

		.star_rating_list {
			margin-top: 10px;
		}

		.star_rating_item {
			display: inline-block;
			margin-right: 10px;
		}

		.unfair_review_container {
			margin-top: 25px;
		}

		.unfair_review_help {}

		.support_link {}

		.theirRating_container {
			border-top: 1px solid $lightgray-border;
			border-bottom: 1px solid $lightgray-border;
			padding: 2em 0 2.5em 0;
			margin-top: 2em;

			.theirRating_label {
				font-weight: 600;
				display: block;
				margin-bottom: 10px;
			}

			.theirRating_list {
				margin-bottom: 1em;
			}

			.starRating_item {
				display: inline-block;
				margin-right: 10px;
				cursor: pointer;

				@include media ($break365) {
					width: 30px;
				}
			}

			.star_rating_icon {
				cursor: default;
			}

			.theirRating_descrip {}
		}
	}
}
