.flash-notification {
	background-color: $dark-text;
	background-color: rgba(0,0,0,.85);
	text-align: center;
	position: absolute;
	left: 5%;
	width: 90%;
	right: 5%;
	top: 5%;
	z-index: $zindexFlashNotification;
	padding: 1em;
	@include standard-radius;

	.flash-text {
		color: white;
		@include uppercase12;
		display: block;
	}

	a {
		@include blue-link;
	}
}

@mixin flash-notification {
	background-color: $dark-text;
	background-color: rgba(0,0,0,.85);
	text-align: center;
	position: absolute;
	left: 5%;
	width: 90%;
	right: 5%;
	top: 5%;
	z-index: $zindexFlashNotification;
	padding: 1em;
	@include standard-radius;

	.flash-text {
		color: white;
		@include uppercase12;
		display: block;
	}

	a {
		@include blue-link;
	}
}