.basicCofirmationModal {
  text-align: center;

  .modal-dialog {
    padding: 2rem 1.5rem;

    @include media($break650) {
      width: 90%;
    }

    @include media($break380) {
      width: 95%;
    }
  }

  .confirmDelete_title {
    padding-bottom: .5rem;
  }

  .confirmDelete_descrip {
    padding-bottom: 1rem;
  }

  .actions_container {}

  .confirm_btn {
    margin-right: .25rem;
  }

}
