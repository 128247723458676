/* Example html

<progress class="standard_flashNotice">
  <button class="close_btn btn"><img src="/images/style_guide/icon_close_flashNotice.svg" alt class="close_flashNotice_icon"></button>
  <span class="flashNotice_txt default_body_copy">Successfully </span>
</progress>

*/

.standard_flashNotice {
  position: fixed;
  top: 38px;
  width: 100%;
  max-width: 600px;
  left: 50%;
  margin-left: -300px;
  text-align: center;
  padding: .6rem 3rem .8rem 1rem;
  z-index: 10001;
  background-color: $dark-text;
  background-color: rgba(0,0,0,.85);
  border-radius: 100px;
  box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.39);
  -webkit-animation-duration: .25s;
  animation-duration: .25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;

  @include media($break605) {
    border-radius: 0;
    top: 0;
    left: 0;
    margin-left: 0;
    text-align: left;
    box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.55);
    padding: 1rem 3rem 1.3rem 1rem;
  }

  .close_btn {
    position: absolute;
    right: 1rem;
    top: 10px;
    background: none;
    padding: 0;
    border: none;

    @include media($break605) {
      top: 17px;
    }
  }

  .close_flashNotice_icon {
    width: 23px;
  }

  .flashNotice_txt {
    color: white;

    @include media($break605) {

    }
  }
}

/*
<div class="negative_flashNotice flashNotice" style="display:block;">
  <div class="iconWrap">
    <img src="/images/style_guide/icon_alert_red_lighter.svg" class="icon_alert icon_flashNotice">
  </div>
  <div class="txtWrap">
    <p class="default_body_copy">We're no longer able to access information from your @rrayyme Instagram account.</p>
    <button type="button" class="btn_link btn">Reconnect your account</button>
  </div>
</div>

<div class="info_flashNotice flashNotice" style="display:block;">
  <div class="txtWrap">
    <p class="flashTitle default_body_copy">You were invited by Hydro Flask!</p>
    <p class="default_body_copy">Sign up with your social account or enter your email below and create a password to find campaigns</p>
  </div>
</div>
*/
.flashNotice {
  border-radius: 5px;
  padding-left: 2.5rem;

  .iconWrap, .txtWrap {
    display: inline-block;
  }

  /*.iconWrap {
    border-right: 1px solid $aiq_border_errorbg;
    padding: 0 .25rem;
    position: relative;
    width: 150px;

    .icon_flashNotice {
      width: 19px;
      position: absolute;
      top: 50%;
      margin-top: -9px;
    }
  }*/

   .txtWrap {
     border-left: 1px solid $aiq_border_errorbg;
     padding: .75rem;

     .flashTitle {
       font-weight: 700;
     }

     .default_body_copy {
      font-weight: 700;
      font-size: .9rem;
     }

     .btn_link {
       margin-top: .5rem;
       display: block;
       cursor: pointer;
       font-weight: 700;
     }
   }
}

/*
<div class="negative_flashNotice flashNotice_floating flashNotice" style="display:block;">
  <div class="txtWrap">
    <p class="default_body_copy">We're no longer able to access information from your @rrayyme Instagram account.</p>
    <button type="button" class="btn_link btn">Reconnect your account</button>
  </div>
</div>
*/
.flashNotice_floating {
  position: fixed;
  left: 1.25rem;
  bottom: 1.25rem;
  width: 400px;
  z-index: 2147483001;
  box-shadow: 0 2px 3px 0 rgba(0,0,0,0.44);

  @include media($break435) {
    left: 0;
    bottom: 0;
    border-radius: 0;
    width: 100%;
  }
}

.negative_flashNotice {
  color: $aiq_offwhite;
  background: $aiq_dangerRed url("/images/icons/multicolor/alert_red_light.svg") 11px center no-repeat;
  background-size: 20px;

  .txtWrap {

    .btn_link {
      color: #7FD4F4;
      padding: 0;
    }

    .default_body_copy {
      color: $aiq_offwhite;
    }
  }
}

.light_negative_flashNotice {
  color: $aiq_offwhite;
  background: $aiq_dangerLightRed url("/images/icons/multicolor/alert_red_light.svg") 11px center no-repeat;
  background-size: 20px;

  .txtWrap {
    border-left: 1px solid $aiq_offwhite;

    .btn_link {
      color: #7FD4F4;
      padding: 0;
    }

    .default_body_copy {
      color: $aiq_black;
    }
  }
}

.help_flashNotice {
  background: #F6EBEF url("/images/icons/multicolor/help.svg") 8px center/25px no-repeat;
  color: $aiq_offwhite;

  .txtWrap {
    color: $aiq_black;
    border-left: 1px solid $aiq_offwhite;
    font-weight: 300;

    .default_body_copy {
      font-weight: 300;
    }

    .standard_link {
      font-weight: 300;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.info_flashNotice {
  color: $aiq_offwhite;
  background: $bg_default url("/images/style_guide/icon_info_circle_color.svg") 11px center no-repeat;

  .txtWrap {
    border-left: 1px solid $aiq_offwhite;
    color: $aiq_black;

    .default_body_copy {
      font-weight: 300;
      color: $aiq_black;
    }

    .flashTitle {
      font-weight: 700;
    }

    .btn_link {
      color: #7FD4F4;
      padding: 0;
    }
  }
}

.info_flashNotice {
  color: $aiq_offwhite;
  background: $bg_default url("/images/style_guide/icon_info_circle_color.svg") 11px center no-repeat;

  .txtWrap {
    border-left: 1px solid $aiq_offwhite;
    color: $aiq_black;

    .default_body_copy {
      font-weight: 300;
    }

    .flashTitle {
      font-weight: 700;
    }

    .btn_link {
      color: #7FD4F4;
      padding: 0;
    }
  }
}

.notification_flashNotice {
  color: $aiq_offwhite;
  background: $aiq_nude url("/images/icons/multicolor/notification_badge.svg") 11px center no-repeat;
  background-size: 20px;

  .txtWrap {
    border-left: 1px solid $aiq_offwhite;
    color: $aiq_black;

    .default_body_copy {
      font-weight: 400;
    }

    .flashTitle {
      font-weight: 700;
    }
  }
}
