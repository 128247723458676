.proposal_sent {
    margin: 6rem auto;
    max-width: 550px;

    @include media($break545) {
      margin: 5rem auto;
    }

    .whitebox {
        background-color: white;
        border-radius: 5px;
        border: 1px solid $lightgray-border;
        padding: 3rem;
        margin: 1em;
        text-align: center;
    }

    .avatar_container {
      width: 76px;
      margin: 0 auto;
      position: relative;
    }

    .complete_indicator {
      width: 25px;
      height: 25px;
      position: absolute;
      right: 0;
      top: 0;
    }

    .user_avatar {
      width: 75px;
      height: 75px;
      border: 2px solid white;
      box-shadow: 0 1px 10px rgba(0,0,0,0.1);
      border-radius: 100px;
    }

    strong {
      font-weight: 800;
    }

    .main_title {
      text-align: center;
      padding-bottom: 1rem;

      @include media($break340) {
        font-size: 3em;
      }
    }

    .main_descrip {
      text-align: center;
      margin-bottom: 1.5rem;
    }

    .back_btn {
      width: 180px;
      margin: 0 1em 2em;
      display: inline;
    }

    .writeMsg_btn {
      width: 180px;
    }
}
