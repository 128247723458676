.account_settings {
	height: 100%;
	overflow: auto;

	.main_container {
		max-width: 1200px;
		margin: 0 auto;
		padding: 4.5rem 0 6rem;
		overflow: hidden;

		@include media($break1200) {
			padding: 4.5rem 3% 3rem;
		}
	}

	.section_error_descrip, .section_success_descrip {
		margin: 0 43px 10px;

		@include media($break590) {
			margin: 0 20px 10px;
		}
	}

	.form_group {

		@include media($break1090) {
			margin-bottom: 12px;
		}
	}

	.main_descrip {
		padding-bottom: 1.25rem;
	}

	.nonForm_group {
		padding-bottom: 2rem;
		margin-bottom: 2rem;
		border-bottom: 1px solid $lightgray-border;

		.sectionHeader {
			padding-bottom: .65rem;

			.headerTxt {
				font-weight: 600;
			}
		}

		.headerIcon {
			width: 22px;
			margin-right: .25rem;
			vertical-align: -1px;
		}

		.icon_email {
			width: 17px;
			vertical-align: -4px;
		}

		.icon_address {
			width: 19px;
			vertical-align: -6px;
		}

		.icon_name {
			width: 22px;
			vertical-align: -2px;
			margin-right: .35rem;
		}

		.icon_social {
			width: 20px;
			vertical-align: -4px;
			margin-right: .35rem;
		}

		.icon_paypal {
			width: 20px;
			vertical-align: -5px;
		}

		.icon_phone {
			vertical-align: -5px;
		}

		.bodyTxt {
			padding-bottom: .8rem;
		}

		.warningWrap {
			color: $error;
			padding-bottom: 1rem;
		}

		.icon_warning {
			margin-right: .15rem;
			vertical-align: -3px;
		}

		.deleteBtn {
			margin-bottom: 1rem;
		}

		.warningTxt {
			color: $error;
		}

		.smallTip {
			font-weight: 600;
			font-size: .8rem;
		}
	}

	.lastGroup {
		border-bottom: none;
		margin-bottom: 0;
		padding-bottom: 0;
	}

	.checkbox_control {

		@include media($break560) {
			width: 100%;
		}
	}

	.multi_option_col {

		@include media($break860) {
			float: none;
			padding: 0;
		}

		@include media($break560) {
			float: none;
		}
	}

	.agency_proposal_hint {
		background-color: #6bc271;
		color: white;
		padding: 0.5rem 1rem;
		margin-bottom: 1.5rem;
	}

	.settings_nav {
		float: left;
		width: 262px;
		margin-right: 25px;
		position: relative;

		@include media($break860) {
			float: none;
			width: 100%;
			margin-right: 0;
		}

		.settings_nav_list {
			background-color: #FCFCFC;
			border-radius: 6px;
			border: 1px solid $lightgray_border;

			@include media($break860) {
				display: none;
			}
		}

		.mini_settings_nav_container {
			display: none;
			width: 250px;
			margin: 0 auto 25px;
			padding: 3px;

			@include media($break860) {
				display: block;
				margin-bottom: 16px;
			}

			@include media($break450) {
				width: 100%;
			}

			.mini_settings_nav {
				display:block;
			}

			 /* Custom arrow - could be an image, SVG, icon font, etc. */
			.select_dropdown {
				right: 15px;

				&:after {
					right: 15px;
				}
			}

			select {
				max-width: 100%;
				min-width: 100%;
				font-weight: 600;
			}

			.select::after {
				right: 15px;
			}
		}

		.settings_nav_item {
			border-bottom: 1px solid #F2F2F2;

			&:last-child {
				border-bottom: none;
			}
		}

		.settings_nav_link {
			padding: 13px 20px;
			background: url('/images/settings/arrow_right_icon.svg') 95% center no-repeat;
			color: $dark-text;
			font-size: 1.5em;
			line-height: 1.5em;
			display: block;
			text-decoration: none;
			font-weight: 300;
			cursor: pointer;

			&:hover {
				background: white url('/images/settings/arrow_right_icon.svg') 95% center no-repeat;
			}
		}

		.active_nav_link {
			font-weight: 600;
			background: white url('/images/settings/active_arrow_right_icon.svg') 95% center no-repeat;

			&:hover {
				background: white url('/images/settings/active_arrow_right_icon.svg') 95% center no-repeat;
			}
		}
	}

	.main_section {
		float: left;
		width: 913px;

		@include media($break1200) {
			width: calc(100% - 287px);
		}

		@include media($break860) {
			width: 100%;
			float: none;
		}

		.section_header {
			border-bottom: 1px solid #F2F2F2;
			padding: 0 43px;
			margin-bottom: 25px;

			@include media($break590) {
				padding: 0 20px;
			}
		}

		.main_title {
			padding: .5em 0;
			font-famiy: 'Lato', sans-serif;

			@include media($break470) {
				font-size: 1.7rem;
			}
		}

		.action_buttons_container {
			border-top: 1px solid #F2F2F2;
			padding: 20px 0;
			margin-top: 25px;
		}

		.save_button {
			background-color: $revfluence-blue;
			border-radius: 100px;
			line-height: 1.5em;
			font-size: 1.5em;
			color: white;
			font-weight: 500;
			display: block;
			text-align: right;
			cursor: pointer;
			border: none;
		}

		.standard_fieldset {
			overflow: hidden;
			margin-bottom: 20px;
		}
	}

	.main_form {
		padding: 0 43px;

		@include media($break590) {
			padding: 0 20px;
		}
	}

	.control_label {

		@include media($break1090) {
			width: auto;
			float: none;
			display: block;
			text-align: left;
			margin-bottom: 8px;
			padding: 7px 0 0;
		}

		@include media($break860) {
			padding: 7px 0 0;
		}
	}

	.form_control_container {

		@include media($break1090) {
			float: none;
			width: 100%;
			padding: 0;
		}

		.default_body_copy {
			margin-top: 7px;
			display: block;
		}
	}

	.settings_section {
		margin-bottom: 25px;
	}

	.notifications_section {

		.form_group {
			margin-bottom: 35px;
		}
	}

	.shipping_address_fieldset {

		.entered_shipping_address {
			margin-top: 7px;
		}

		.edit_address_link {
			display: block;
		}
	}

	.referral_section {

		.vertical_control_label {
			font-weight: 300;
			display: block;
			margin-bottom: 7px;
		}

		.was_referred_container {
			margin-top: 7px;

			@include media($break1090) {
				width: 300px;
			}

			@include media($break375) {
				width: 100%;
			}
		}

		.balance_container {
			padding: 0 15px;
			float: left;
			width: 450px;
			margin: 7px 0;

			@include media($break1090) {
				padding: 0;
			}
		}

		.referral_code_input {
			display: block;
			margin-bottom: 10px;
		}

		.withdrawal_balance_container {

			@include media($break1090) {
				padding: 0;
			}

			@include media($break500) {
				width: 100%;
				padding: 0;
			}
		}

		.balance_amount {
			display: block;
			margin-bottom: 7px;
			margin-top: 0px;
		}

		.referral_code {
			margin-top: 7px;
			display: block;
		}
	}

	.billing_section, .general_section {

		.plan_container {
			border: 1px solid $lightgray-border;
			border-radius: 6px;
			padding: .75rem 1rem 1.3rem;
			float: left;
			width: 450px;
			margin: 0 15px;

			@include media($break1122) {
				width: 350px;
			}

			@include media($break1090) {
				width: 450px;
				float: none;
				margin: 0;
			}

			@include media($break590) {
				width: 100%;
			}

			.plan_name {
				font-size: 1.5rem;
			}

			.subscrip_card_container {
				padding: .5rem .9rem .6rem;
				border: 1px solid $lightgray-border;
				border-radius: 6px;

				.subscrip_card_label {
					display: block;
					margin-bottom: .2rem;
				}

				.subscrip_card {}

				.subscrip_card {}

				.change_plan_button {
					margin-left: .7rem;
				}
			}
		}

		.h4 {
			margin-bottom: .25em;
		}

		.plan_details_list {
			color: $dark-text;
			margin-bottom: .85em;
		}

		.plan_details_item {
			margin-bottom: 5px;

			@include media($break350) {
				margin-bottom: 10px;
			}
		}

		.credit_card_container, .balance_container, .review_addingBalance_container {
			padding: 0 15px;
			float: left;
			width: 450px;
			margin-top: 7px;

			@include media($break1090) {
				padding: 0;
			}
		}

		.credit_card_container {

			@include media($break510) {
				width: 100%;
			}
		}

		.balance_container {

			@include media($break1090) {
				padding: 0;
			}

			@include media($break500) {
				width: 100%;
				padding: 0;
			}
		}

		.add_card_btn {
			padding: 0;
			border: none;
			margin-bottom: 1rem;
			display: block;

			&:focus, &:active {
				box-shadow: none;
			}
		}

		.card_object {
			width: 450px;
			padding: 1rem;
			border-radius: 6px;
			border: 1px solid $lightgray-border;
			margin: 0 0 .8rem;
			position: relative;

			@include media($break1122) {
				width: 350px;
			}

			@include media($break1090) {
				width: 450px;
				margin: 0 0 .8rem;

				&:last-child {
					margin: 0;
				}
			}

			@include media($break590) {
				width: 100%;
			}

			.remove_card_btn {
				position: absolute;
				top: .75rem;
				right: .75rem;
				background: none;
				padding: 0;
				border: none;

				&:active, &:focus {
					box-shadow: none;
				}
			}

			.remove_icon {
				width: 17px;
				display: block;
			}

			&:last-child {
				margin-bottom: .8rem;
			}

			.card_number_label, .permissions_label {
				display: block;
				text-transform: uppercase;
				font-size: .7rem;
				margin-bottom: .75rem;
				font-weight: 300;
				letter-spacing: .07rem;

				@include media($break1122) {
					margin-bottom: 0;
				}
			}

			.permissions_label {

				@include media($break1122) {
						margin-bottom: .5rem;
				}
			}

			.card_type, .card_number {
				margin-top: .45rem;
				display: inline-block;
			}

			.credit_card_details {
				float: left;
				width: 180px;
				margin-right: 1rem;

				@include media($break1122) {
					margin-bottom: 1rem;
				}

				@include media($break515) {
					float: none;
					margin-bottom: 1.4rem;
				}
			}

			.permissions_container {
				float: left;
				width: 150px;

				@include media($break475) {
					float: none;
				}
			}

			.select {
				width: 100%;
			}
		}

		.form_group {
			margin-bottom: 35px;
		}

		.balance_container {

			.balance_amount, .add_balance_link {
				display: block;
			}

			.balance_amount {
				margin-bottom: 9px;
			}

			.continue_link {
				display: block;
			}

			.add_balance_label {
				font-weight: 300;
			}

			.input_container {
				overflow: hidden;
				padding: 5px 0;
			}

			.add_money_input {
				float: left;
				width: 140px;
				margin-right: 10px;
			}

			.continue_addingBalance_btn {
				float: left;
				width: 90px;
			}
		}

		.review_addingBalance_container {
			border: 1px solid $lightgray-border;
			border-radius: 6px;
			padding: 22px 25px;
			margin: 0 15px;

			@include media($break1123) {
				width: 350px;
			}

			@include media($break1090) {
				width: 450px;
				margin: 0;
			}

			@include media($break590) {
				width: 99%;
			}

			.btn_primary {
				margin-right: 5px;
			}

			.h5 {
				text-align: center;
				margin-bottom: 10px;
			}

			.payment_container {}

			.payment_list {
				margin-bottom: 15px;
			}

			.payment_item {
				overflow: hidden;
				padding: 15px 0;
				border-bottom: 1px solid $lightgray_border;

				&:last-child {
					border-bottom: none;
				}
			}

			.amount_to_add {}

			.label_text {
				float: left;
			}

			.amount_text {
				float: right;
			}

			.label_text, .amount_text {

				@include media($break388) {
					float: none;
					display: block;
				}
			}

			.service_fee {}

			.total {}

			.card_charged {
				border: 1px solid $lightgray_border;
				padding: 15px;
				border-radius: 3px;
				margin-bottom: 15px;

				.h6 {
					text-align: center;
					margin-bottom: 20px;
				}
			}

			.credit_card_logo {
				display: inline-block;
				width: 46px;
				height: 32px;
				margin-right: 10px;
				vertical-align: -7px;
			}

			.card_number {
				margin-right: 5px;
			}

			.standard_link {
				display: inline-block;
			}

			.btn_default {
				margin-right: 3px;
			}
		}

		.add_creditcard_container, .editing_shipping_address {
			padding: 0 15px;
			float: left;
			width: 480px;
			margin-top: 7px;

			@include media($break1122) {
				width: 381px;
			}

			@include media($break1090) {
				padding: 0;
				width: 450px;
				float: none;
			}

			@include media($break590) {
				width: 100%;
			}

			.row {
				overflow: hidden;
				margin-bottom: 20px;
				padding: 3px;

				@include media($break350) {
					margin-bottom: 0;
				}
			}

			.vertical_control_label {
				font-weight: 300;
			}

			.card_number_container, .street_container  {
				margin-bottom: 18px;
			}

			.expiration_month_container, .expiration_year_container, .cvc_container, .city_container, .state_container, .zip_container {
				float: left;
				width: 148px;

				@include media($break1222) {
					width: 33%;
				}

				@include media($break350) {
					width: 100%;
					float: none;
					margin-bottom: 20px;
				}
			}

			.add_card_button, .add_address_button {
				margin-right: 3px;
			}
		}
	}

	.general_section {

		.form_group {

			@include media($break1090) {
				margin-bottom: 10px;
				width: 450px;
			}

			@include media($break550) {
				width: 100%;
			}
		}

		.editing_shipping_address {
			width: 450px;

			@include media($break550) {
				width: 100%;
			}
		}

		.city_container, .state_container, .zip_container {
			float: left;
			width: 30% !important;

			@include media($break1222) {
				width: 33%;
			}

			@include media($break550) {
				width: 100% !important;
				float: none !important;
				margin-bottom: 20px !important;
			}
		}

		.city_container, .state_container {
			margin-right: 5%;
		}

		.manage_accounts_link {
			margin-top: 7px;
			display: block;
		}
	}

	.integrations_section {}

	.subscription_paymentHistory_section, .marketplace_paymentHistory_section {

		.payment_history_item {
			padding-bottom: 1.25em;
			border-bottom: 1px solid $lightgray_border;
			margin-bottom: 1em;

			&:last-child {
				border-bottom: none;
			}
		}

		.history_headers {

			@include media($break555) {
				display: none;
			}
		}

		.billing_period, .billing_period_label {
			width: 23%;

			@include media($break1045) {
				width: 32%;
			}

			@include media($break555) {
				width: auto;
				display: block;
			}
		}

		.payment_amount, .payment_amount_label {
			width: 37%;

			@include media($break1045) {
				width: 28%;
			}

			@include media($break555) {
				width: auto;
				display: block;
			}
		}

		.payment_plan, .payment_plan_label, .note, .note_label {
			width: 19%;
		}

		.billing_period, .billing_period_label {
			margin-right: 10%;
			display: inline-block;

			@include media($break1045) {
				margin-right: 5%;
			}

			@include media($break555) {
				display: block;
			}
		}

		.payment_amount, .payment_amount_label {
			margin-right: 10%;
			display: inline-block;

			@include media($break1045) {
				margin-right: 5%;
			}

			@include media($break555) {
				display: block;
			}
		}

		.payment_plan, .payment_plan_label, .note, .note_label {
			display: inline-block;
		}

		.billing_period_label, .payment_amount_label, .payment_plan_label, .note_label  {
			font-weight: 600;

			@include media($break555) {
				display: none;
			}
		}

		.billing_period, .billing_period_label, .payment_amount, .payment_amount_label, .payment_plan, .payment_plan_label, .note, .note_label {
			vertical-align: top;
		}

	}

	.managers_section {

	  .resend_invite {
		font-style: normal;
		padding: 0;
		font-size: 1em;
		margin-left: .25em;

		&:focus, &:active {
		  box-shadow: none;
		}
	  }


		.current_managers_fieldset {

			.entered_manager_list {
				margin-left: 1rem;
			}

			.entered_manager_item {
				border: 1px solid $lightgray-border;
				border-radius: 6px;
				padding: 1.2em 1.5em 1.35em;
				position: relative;
				margin-bottom: 1.25em;

				&:last-child {
					margin-bottom: 0;
				}

				.existing_perm_select_container {
					margin-top: .75rem;
					border-top: 1px solid $lightgray-border;
					padding-top: 1em;

					.permissions_select_container {
						display: inline-block !important;
					}
				}
			}

			.disabled_manager_item {
				background-color: #FAFAFA;
			}

			.disable_manager_btn {
				position: absolute;
				padding: 0;
				top: 16px;
				right: 15px;
				padding: .15em .8em .2em;
				font-size: 1.15em;
				background-color: white;
				color: rgb(217, 83, 79);
				border: 1px solid $lightgray-border;
				font-weight: 300;
			}

			.enable_manager_btn {
				background-color: white;
				border: 1px solid white;
				color: $revfluence-blue;
				font-weight: 300;
			}

			.manager_name {
				display: inline-block;
				font-weight: 600;
				margin-top: 0;
				width: calc(100% - 66px);
			}

			.invitation_status {
				display: inline-block;
			    font-style: italic;
			}

			.manager_email, .owner_label, .admin_label, .invitation_status {
				font-size: 1.35em;
				margin-top: 0;
			}

			.manager_email {
				margin-top: 0;
				width: calc(100% - 66px);
			}

			.permissions_dropdown {}

			.permissions_item {}
		}

		.permissionDropdown_btn {
			display: block;
			background-color: white;
			width: 100%;
			text-align: left;
			border-radius: 0;
			font-weight: 300;
			color: $revfluence-blue;

			&:hover {
				background-color: $lightgray-border;
			}

			&:active, &:focus {
				box-shadow: none;
			}
		}

		.permissions_item {
			margin-bottom: 0;
		}

		.permissions_select_container {
			position: relative;
			display: inline-block;

			&:hover {

				.permissions_dropdown {
					display: block;
					left: 0 !important;
					top: 35px;
				}
			}
		}

		.existing_perm_select_container {

			.permission_label {
				font-size: 1.35em;
				margin-right: .25em;
				display: inline-block;
			}
		}

		.permissions_dropdown {

			&:before, &:after {
				left: 12px;
			}
		}

		.permissions_select_btn {
			background: $aiq_lightblue_bg url('/images/themes/aspireiq/icon_sortDown_blue.svg') calc(100% - 14px) center no-repeat;
			padding: 6px 37px 6px 17px;
			background-size: 14px;

	    &:hover, &:focus {
	      color: $aiq_darkblue_hover;
				background: $aiq_lightblue_bg_hover url('/images/themes/aspireiq/icon_sortDown_blue.svg') calc(100% - 14px) center no-repeat;
				background-size: 14px;
	    }

	    &:active {
	      background: $aiq_lightblue_bg url('/images/themes/aspireiq/icon_sortDown_blue.svg') calc(100% - 14px) center no-repeat;
				background-size: 14px;
	    }
		}

		.add_new_manager {

			.email_fieldset, .name_fieldset, .permissions_fieldset {

				.control_label {
					text-align: left;
					margin-bottom: .35em;
				}
			}

			.permissions_select_container {}

			.permissions_select_btn {}

			.permissions_item {}

			.send_invite_btn {
				margin: 1em 0;
			}

			.section_success_descrip {
				width: 100%;
				margin: 0 0 .35em 1em;

				@include media($break1090) {
					margin: 0 0 .35em 0;
				}
			}
		}
	}

	#add_manager_form {

		.form_group {
			margin-bottom: .75em;
		}
	}

	.new_landing_footer {
		border-top: 1px solid $lightgray-border;
	}

	.phone_number_fieldset {

		.standard_link {
			font-weight: 500;
		}
	}

	.paymentTable_form_control {
		width: 625px;
		font-weight: 300;

		a {
			color: $aspireiq_persian_blue;
		}

		th {
			font-weight: 600;
		}

		th, td {
			padding: .5rem;
			line-height: 1rem;
		}

		@include media($break630) {
			overflow: auto;
		}

		th {
			font-weight: 600;
			text-align: left;
		}

		td {
			font-weight: 300;
			line-height: 1.15rem;
		}

		td, th {
			padding: .5rem;
		}

		a {
			color: $revfluence-blue;
			text-decoration: none;

			&:hover {
				color: $revfluence-blue-hover;
			}
		}
	}

	.tableControls_wrapper {
		padding: 1rem 0 1.5rem;
	}

	.searchPaging_section {
		width: calc(100% - 122px);
	}

	.searchPaging_section, .downloadPayments_btn {
		float: left;
	}

	.searchPaging_btn {
		font-weight: 300;
		height: 34px;
	}

	.searchPaging_item {
		display: inline-block;
	}

	.icon_pageLeft, .icon_pageRight {
		width: 9px;
	}

	.downloadPayments_btn {
		font-size: .85rem;
		font-weight: 300;
		margin-right: 1rem;
	}

	.icon_download {
		margin-right: .25rem;
		width: 16px;
		vertical-align: -2px;
	}

	.paymentTable {
		display: block;
		font-size: .85rem;

		@include media($break630) {
			white-space: nowrap;
		}

		td {
			font-size: .85rem;
		}
	}

	.no_results_container {

		.noResults_title {
			font-weight: 600;
		}
	}
}
