@import "../../common";

.aspirex_embedded .add_posts_popup {
  .modal-dialog {
    border-radius: 0;
    max-width: none;
    height: 100%;
  }
  .close_btn {
    display: none;
  }
  .add_types_title {
    @include aspirex_title;
  }
  .add_types_subtitle {
    @include aspirex_subtitle;
  }
  .add_posts_container {
    padding: 0;
    max-width: 754px;
    margin: 0 auto 20px;

    .account_item {
      margin-top: 38px;
    }
  }

  .missingTemplatesWarning {
    background-color: $bg_default;
  }
}
