.h1 {
	font-weight: 300;
	color: $dark-text;
	line-height: 1.5em;
	font-size: 5em;
}

.h2 {
	font-weight: 300;
	color: $dark-text;
	line-height: 1.5em;
	font-size: 4.25em;
}

.h3 {
	font-weight: 300;
	color: $dark-text;
	line-height: 2.5rem;
	font-size: 3.45em;
}

.h4 {
	font-weight: 300;
	color: $dark-text;
	line-height: 2.05rem;
	font-size: 2.75em;
}

.h5 {
	font-weight: 300;
	color: $dark-text;
	line-height: 1.5em;
	font-size: 2em;
}

.h6 {
	font-weight: 300;
	color: $dark-text;
	line-height: 1.5em;
	font-size: 1.5em;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: 700;
  }

.default_body_copy {
	font-size: 1.5em;
	line-height: 1.5em;
	font-weight: 400;
}

.alert_copy {
	color: $error_red;
}

.complete_copy {
	color: green;
}

/* deprecate this small_text plz, use small_txt */
.small_text {
	font-size: 1.25em;
	font-weight: 300;
}

.small_txt {
	font-size: .85rem;
	font-weight: 400;
	line-height: 1.5em;
}

.bold_text, .bold_txt {
	font-weight: 600;
}

u, .underline {
	text-decoration: underline;
}

em {
	font-style: italic;
}

blockquote {
	padding: 10px 20px !important;
	margin: 0 0 20px !important;
	border-left: 5px solid #eee !important;
}

.truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	position: relative;
}

.tiny_uppercase_text {
	display: inline-block;
	text-transform: uppercase;
	letter-spacing: .2em;
	font-size: 1em;
}

.diff_added_copy {
	color: white;
	background-color: $mantis;
	padding: 0 4px;
	margin: 0 5px;
	display: inline;
}

.diff_added_block {
	color: $aiq_forest_green !important;

	.diff_added_copy {
		color: $aiq_forest_green;
		background-color: white;
	}
}

.diff_removed_copy {
	text-decoration: line-through;
	color: #A3A3A3;
}

.diff_removed_block {
	text-decoration: line-through;
	color: #A3A3A3;
}

.positiveTxt {
	color: $aiq_pink;
}

.quote_txt {
	padding: .5rem;
	border: 1px solid $lightgray-border;
	border-radius: 3px;
	margin-bottom: 1rem;
	font-weight: 600;
	display: block;
}

.notifcationNumber_indicator {
	background-color: #2582f4;
	color: white;
	display: inline-block;
	padding: 0 10px;
	text-align: center;
	font-size: .8em;
	border-radius: 100px;
	font-weight: 300;
	line-height: 1.65em;
	margin-left: .3rem;
}

.nothingPlaceholder {
	background-color: $aiq_grey;
	padding: .75rem 1.25rem;
	border-radius: 6px;
	display: inline-block;
	font-size: .9rem;
	color: $aiq_greyTxt;
	font-weight: 300;
	text-align: center;
}

/* NEW FONTS ONLY USE THESE FROM NOW ON */@at-root

.headline_1 {
	font-size: 96px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: -1.5px;
	color: $aiq_black;
}

.headline_2 {
	font-size: 60px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: -0.5px;
	color: $aiq_black;
}

.headline_3 {
	font-size: 48px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: $aiq_black;
}

.headline_4 {
	font-size: 34px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: 0.3px;
	color: $aiq_black;
}

.headline_5 {
	font-size: 24px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: $aiq_black;
}

.headline_6 {
	font-size: 20px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: 0.2px;
	color: $aiq_black;
}

.subtitle_1 {
	font-size: 16px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: 0.2px;
	color: $aiq_black;
}

.body_1 {
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.35;
	letter-spacing: 0.4px;
	color: $aiq_black;
}

.subtitle_2 {
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.71;
	letter-spacing: 0.1px;
	color: $aiq_black;
}

.button_text {
	font-size: 14px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: 0.3px;
	color: $aiq_black;
}

.body_2 {
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.43;
	letter-spacing: 0.3px;
	color: $aiq_black;
}

.caption {
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.33;
	letter-spacing: 0.4px;
	color: $aiq_black;
}

.overline {
	font-size: 10px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.6;
	letter-spacing: 1.5px;
	color: $aiq_black;
}
