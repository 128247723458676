.campaign_generator {
  background: url('/images/style_guide/bg_avatars_light.jpg') center top no-repeat;
  background-size: 1250px;
  position: relative;

  @include media($break768) {
    background-size: 800px;
  }

  .main_container {}

  .back_btn {
    position: absolute;
    left: 2rem;
    top: -10rem;
    background: none;

    @include media($break768) {
      top: -3.5rem;
      left: .5rem;
    }

    @include media($break425) {
      display: none !important;
    }

    .back_icon {
      width: 32px;

      @include media($break425) {
        width: 25px;
      }
    }
  }

  .intro_msg_container {
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 16rem;

    @include media($break768) {
      margin-top: 11rem;
    }

    @include media($break425) {
      margin-top: 6rem;
    }

    @include media($break320) {
      margin-top: 5rem;
    }
  }

  .intro_msg_title {

    @include media($break1050) {
      font-size: 2.25rem;
    }

    @include media($break768) {
      font-size: 1.75rem;
      margin-bottom: .25rem;
    }
  }

  .intro_msg_descrip {
    font-size: 2.1em;

    @include media($break1050) {
      font-size: 1rem;
    }
  }

  .target_collab_form {
    textarea {
      height: 8rem;
    }
  }

  .ig_account_fieldset, .email_fieldset {
    text-align: center;
    margin: 0 auto;
  }

  .ig_account_fieldset {
    max-width: 360px;

    @include media($break375) {
      padding: .5rem;
    }
  }

  .email_fieldset {
    max-width: 700px;
    margin-top: 15rem;

    @include media($break768) {
      margin-top: 8rem;
    }

    @include media($break425) {
      margin-top: 5rem;
    }
  }

  .ig_account_label, .email_label {
    font-weight: 600;
    text-align: center;
    margin-bottom: .5rem;
  }

  .ig_icon {
    width: 19px;
    margin-right: .5rem;
    vertical-align: -4px;
  }

  .ig_account_input {
    float: left;
    width: calc(100% - 100px);
    border-radius: 6px 0 0 6px;
    border-right: none;

    @include media($break425) {
      float: none;
      width: 100%;
      margin-bottom: 1rem;
      border-radius: 6px;
      border: 1px solid #F2F2F2;
    }
  }

  .igAccount_next_btn {
    float: left;
    border-radius: 0 6px 6px 0;
    padding: 6px 15px;

    @include media($break425) {
      float: none;
      border-radius: 100px;
      width: 100%;
    }
  }

  .right_chevron_icon {
    margin-left: 5px;
  }

  .email_fieldset_txt {
    margin-bottom: 1.5rem;

    @include media($break425) {
      font-size: 1.35rem;
    }
  }

  .username_txt {
    font-weight: 600;
  }

  .email_input {
    max-width: 260px;
    display: block;
    margin: 0 auto .65rem;
  }

  .analyze_now_btn {
    margin-bottom: 1.5rem;
  }

  .request_received_section {
    margin-top: 4rem;

    .ig_successful_icon {
      margin: 0 auto;
      display: block;
      width: 48px;
    }

    .request_received_title {
      margin-bottom: .5rem;
      text-align: center;

      @include media($break425) {
        font-size: 1.75rem;
      }
    }

    .row {
      max-width: 550px;
      margin: 0 auto;

      @include media($break425) {
        padding: 1rem;
      }
    }

    .request_receieved_p {
      padding: .5rem 0;
      font-size: 1.85em;
    }

    .request_demo_btn {
      margin: 1rem auto 0;
      display: block;
      width: 200px;
    }
  }
}

.request_results {
  margin-top: 3.15rem;

  .account_header_container {
    margin-bottom: 1rem;
  }

  .main_title {

    @include media($break1024) {
      font-size: 3.5em;
    }

    @include media($break425) {
      font-weight: 600;
      margin-bottom: .85rem;
      font-size: 2.6em;
    }
  }

  .main_header {
    text-align: center;
    padding: 1.2rem 0 2.5rem;
    border-bottom: 1px solid $lightgray-border;
    margin-top: 2.1rem;
    margin-bottom: 2rem;

    @include media($break425) {
      padding: 1.2rem 0 1.75rem;
    }
  }

  .main_descrip {
    font-size: 1.25rem;

    @include media($break425) {
      font-size: 1.15rem;
    }
  }

  .creator_bio {
    font-size: 1.8em;
    margin-bottom: 1rem;
    display: block;
  }

  .cta_container {

    .request_demo_btn {

      @include media($break445) {
        display: block;
        margin-top: .5rem;
      }
    }
  }

  .creator_username {
    font-size: 2.9em;
  }

  .results_container {
    max-width: 950px;
    margin: 0 auto;

    @include media($break1024) {
      padding: 0 .75rem;
    }
  }

  .result_item {
    border-bottom: 1px solid $lightgray-border;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .avatar_container {
    position: relative;
    float: left;
    margin-right: 1rem;
    margin-top: 2px;
  }

  .txt_container {
    float: left;
    width: calc(100% - 100px);
  }

  .network_icon_container {
    position: absolute;
    left: 0;
    top: 0;
    background-color: white;
    border: 1px solid $lightgray-border;
    width: 32px;
    height: 32px;
    border-radius: 100px;
  }

  .ig_icon {
    width: 16px;
  }

  .youtube_icon {}

  .network_icon {
    width: 15px;
    display: block;
    margin: 8px auto 0;
  }

  .avatar_border_wrapper {
    width: 75px;
    height: 75px;
    display: block;
    border-radius: 100px;
    border: 1px solid $lightgray-border;
  }

  .social_account_avatar {
    display: block;
    max-width: 100%;
    width: 65px;
    height: 65px;
    border-radius: 100px;
    margin: 4px auto 0;
  }

  .arrow_icon {
    margin-left: 3px;
    vertical-align: -1px;
  }

  .engagement_stat_label {
    font-size: 1.2em;
    margin-left: .3rem;
  }

  .hint_msg {
    margin-bottom: 1.3rem;
  }

  .hint_txt {
    font-weight: 600;
    display: inline;
    margin-right: .5rem;
    vertical-align: -4px;
    margin-bottom: 0;
  }

  .content_list {}

  .content_item {
    width: 32%;
    margin-right: 1%;
    float: left;
    padding-bottom: 1rem;

    &:last-child {
      border-bottom: none;
    }
  }

  .content_link {}

  .content_img {
    display: block;
    max-width: 100%;

    &:hover {
      opacity: .9;
    }
  }

  .closing_section {
    text-align: center;
    padding-bottom: 5rem;
    padding-top: 2rem;

    .closing_title {

      @include media($break1024) {
        font-size: 3.5em;
      }

      @include media($break425) {
        font-weight: 600;
        margin-bottom: .85rem;
        font-size: 2.6em;
        margin-bottom: 0;
      }
    }

    .closing_section_descrip {
      font-size: 1.25rem;
      margin-bottom: 1rem;

      @include media($break425) {
        font-size: 1.15rem;
      }
    }

    .request_demo_btn {}
  }
}
