.sales_pixel {
	.pixel_activity {
		padding: 24px 0 0;
		border-top: 1px solid $lightgray-border;
		margin-top: 25px;
	}

	.pixel_description {
		font-size: 14px;
	}

	.last_event {
		margin-top: 8px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.pixel_off {
		height: 8px;
		width: 8px;
		background-color: #aaa;
		border-radius: 50%;
		margin-right: 9px;
	}

	.pixel_on {
		height: 8px;
		width: 8px;
		background-color: #b8cc0a;
		border-radius: 50%;
		margin-right: 9px;
	}
}