.structured_response {

	.demographicWrap {
		padding: 1.25rem 0;

		.demographicNot_connected_wrap {}

		.section_title {
			padding: 0 0 .25rem;
		}

		.introDescrip {
			padding-bottom: .75rem;
		}

		.icon_ig {
			width: 16px;
			margin-right: .25rem;
			vertical-align: -2px;
		}

		.authorizeIG_btn {}

		.demographic_connected {}

		.demographicSuccess_icon {
			display: inline-block;
			width: 23px;
			margin-right: .5rem;
			vertical-align: 6px;

			@include media($break560) {
				vertical-align: top;
			}
		}

		.authorizedDescrip {
			display: inline-block;
			width: calc(100% - 50px);
		}
	}

	.edit_profile_modal {

    .standard-error-message {
        @include standard-error-message;
        display: none;
    }

		.modal-header {
			overflow: hidden;
		}

		.close_link {
			position: absolute;
			right: .75rem;
			top: .5rem;
		}

		.modal-title {
			text-align: center;
			padding: 1.5rem .5rem 0;
			font-size: 1.5rem;
		}

		.modal-body {
			padding: 3em 4em;
			@include media($break475) {
				padding: 2em;
			}
		}

		input[type="text"] {
			width: 100%;
      		margin-bottom: .25em;
		}

		label {
			@include standard-label;
		}

		.row {
			margin-bottom: 1em;
			overflow: hidden;
			@include media($break475) {
				margin-bottom: 2em;
			}
		}

		.first_name_fieldset {
			display: inline-block;
			margin-right: 1%;
			width: 48%;
			@include media($break475) {
				width: 100%;
				margin-right: 0;
				margin-bottom: 2em;
			}
		}

		.last_name_fieldset {
			display: inline-block;
			width: 50%;
			@include media($break475) {
				width: 100%;
			}
		}

		.street_fieldset {
			width: 100%;
		}

		.city_fieldset {
			display: inline-block;
			margin-right: 1%;
			width: 48%;
			@include media($break475) {
				width: 100%;
				margin-right: 0;
				margin-bottom: 2em;
			}

		}

		.state_fieldset {
			display: inline-block;
			width: 50%;
			@include media($break475) {
				width: 100%;
			}
		}

    .zip_fieldset {
			display: inline-block;
			margin-right: 1%;
			width: 48%;
			padding-top: 1em;
      @include media($break475) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 2em;
      }
		}

		.country_fieldset {
			display: inline-block;
			width: 48%;

      @include media($break475) {
      	width: 100%;
      }
		}

    .paypal_fieldset {
      display: inline-block;
      width: 100%;
    }

    .actions_container {
			overflow: hidden;
		}

		.save_button {
			margin: 1.25rem auto 0;
			display: block;
			max-width: 120px;

			@include media($break475) {
				float: none;
				width: 100%;
			}
		}
	}
}
