.new_feature_tool {
  padding: 1rem;

  .collabHeader_container {

    .tool_icon {
      width: 22px;
      vertical-align: -3px;
    }
  }

  .tools_header {

    .fb_icon {}

    .tool_name {}
  }

  .tool_main_container {

    .yourPricing_section {}

    .spendBased_section {}

    .resources_section {}

    .info_section {
      padding: 1.35rem 0 .9rem;
      border-bottom: 1px solid $lightgray_border;

      &:last-child {
        border-bottom: none;
      }
    }

    .info_section_title {
      font-weight: 700;
      font-size: 1.05rem;
      padding-bottom: .5rem;
    }

    .formula_txt {
      padding: .5rem;
      border: 1px solid $lightgray-border;
      border-radius: 3px;
      margin-bottom: 1rem;
      font-weight: 600;
    }

    p {
      padding-bottom: 1rem;
    }

    .spendBased_bar {
      height: 8px;
      width: 67%;
      border-radius: 100px;
      background-image: linear-gradient(-90deg, #17E065 0%, #F8EC68 50%, #D9534F 100%);
      display: block;
    }

    .pointer_line {
      height: 14px;
      width: 1px;
      background-color: #E2E2E2;
      border-radius: 100px;
      display: block;
      margin-bottom: .4rem;
    }

    .txt_wrapper {
      padding-top: .75rem;
    }

    .spendBased_txt_container {
      float: left;
      width: 33.33%;
    }

    .object_title {
      display: block;
      font-weight: 600;
      padding-bottom: .25rem;
    }

    .object_descrip {
      font-size: .75rem;
      font-weight: 600;
      display: block;
    }

    .feature_list {
      color: #909090;
      font-size: .75rem;
      line-height: 1.15rem;
      padding-top: .35rem;
    }

    .feature_item {}

    .resources_list {
      padding-top: .35rem;
    }

    .resources_item {
      float: left;
      width: 50%;
      padding-right: 1rem;
      padding-bottom: 1.5rem;
      height: 295px;
      margin-bottom: 1.0rem;

      @include media($break1350) {
        height: 250px;
      }

      @include media($break800) {
        height: 295px;
      }
    }

    .resource_thumb {
      max-width: 100%;
      display: block;
    }

    .resource_link {
      display: block;
      color: $dark-text;
      text-decoration: none;

      &:hover {
        color: $revfluence-blue;

        .resource_thumb {
          opacity: .95;
        }
      }
    }

    .image_container {
      height: 250px;
      overflow: hidden;
    }

    .resource_title {
      display: block;
      font-weight: 600;
      margin-top: 1.0rem;
    }
  }
}
