.side_nav {
	float: left;
	width: 262px;
	margin-right: 25px;
	background-color: #FCFCFC;
	border-radius: 6px;
	border: 1px solid #F2F2F2;

	.nav_list {}

	.nav_item {
		border-bottom: 1px solid #F2F2F2;

		&:last-child {
			border-bottom: none;
		}
	}

	.nav_link {
		padding: 13px 20px;
		background: url('/images/settings/arrow_right_icon.svg') 95% center no-repeat;
		color: $dark-text;
		font-size: 1.5em;
		line-height: 1.5em;
		display: block;
		text-decoration: none;
		font-weight: 300;

		&:hover {
			background: white url('/images/settings/arrow_right_icon.svg') 95% center no-repeat;
		}
	}

	.active_nav_link {
		font-weight: 600;
		background: white url('/images/settings/active_arrow_right_icon.svg') 95% center no-repeat;

		&:hover {
			background: white url('/images/settings/active_arrow_right_icon.svg') 95% center no-repeat;
		}
	}
}

.linkNav {

	.linkNav_list {
		border-bottom: 1px solid $lightgray-border;
	}

	.linkNav_item {
		display: inline-block;
	}

	.standard_link {
		color: $dark-text;
		padding: 1rem 1rem .9rem;
		display: inline-block;
		font-size: .8rem;
		border-bottom: 2px solid white;
	}

	.active_link {
		font-weight: 600;
		color: $aiq_darkblue;
		border-bottom: 2px solid $aiq_darkblue;
	}

	.standard_link {}

}

.tabNav {

	.linkNav_list {}

	.linkNav_item {
		display: inline-block;
		margin-right: 1.5rem;
	}

	.standard_link {
		color: $dark-text;
	}

	.active_item {

		 .standard_link {
			 color:  $aiq_darkblue;
			 font-weight: 600;
		 }
	}

	.active_link {
		font-weight: 600;
		color: $revfluence-blue;
	}

	.notificationIndicator {}

	.tabNav {
		border-top: 1px solid $lightgray-border;

		.standard_link {
			padding: .5rem;
		}
	}
}

.navNotification_number {
	background-color:  $aiq_darkblue;
	border-radius: 100px;
	color: white;
	font-weight: 600;
	font-size: .7rem;
	padding: .05rem .65rem .18rem;
	margin-left: .35rem;
}
