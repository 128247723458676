.creator_profile {
  padding-top: 3rem;

  #intercom-container {
    display: none !important;;
  }

  .modal_dialog {

    @include media($break830) {
      margin: 0 auto;
    }
  }

  .main_nav {
    z-index: 9050;
  }

  ._720kb-tooltip {

  }

  .wrapper {
    max-width: 1250px;
    margin: 0 auto;
  }

  .edit_btn {
    padding: 0;
    background: none;
  }

  .edit_icon {
    width: 25px;
  }

  .content_list {

    .content_container {
      border-radius: 6px 6px 0 0;
    }

    .content_image_container {
      border-radius: 6px 6px 0 0;
    }
  }

  .main_content {

    .main_col {
      float: left;
      width: 64%;
      margin-right: 4%;

      @include media($break875) {
        float: none;
        width: 100%;
        margin-right: 0;
      }
    }

    .secondary_col {
      float: left;
      width: 31%;
      padding-top: .7rem;

      @include media($break875) {
        width: 100%;
        float: none;
        padding: 0 2rem 1rem;
      }

      @include media($break550) {
        padding: 0 1rem 1rem;
      }
    }
  }
}
