@mixin standard-bold {
	font-weight: 500;
}

@mixin bold {
	font-weight: 600;
}

@mixin standard_light {
	font-weight: 300;
}

@mixin title70 {
	font-size: 7em;
	letter-spacing: .05em;
}

@mixin title50 {
	font-size: 5em;
	letter-spacing: .05em;
}

@mixin title43 {
	font-size: 4.3em;
	letter-spacing: .05em;
}

@mixin title42 {
	font-size: 4.2em;
	letter-spacing: .2em;
	text-transform: uppercase;
}

@mixin title40 {
	font-size: 4em;
	letter-spacing: .05em;
	line-height: 1.35em;
}

@mixin title35 {
	font-size: 3.5em;
}

@mixin title32 {
	font-size: 3.2em;
}

@mixin title30 {
	font-size: 3em;
	line-height: 1.5em;
}

@mixin title27 {
	font-size: 2.7em;
	line-height: 1.35em;
}

@mixin title25 {
	font-size: 2.5em;
	line-height: 1.28em;
}

@mixin title23 {
	font-size: 2.3em;
	line-height: 1.45em;
}

@mixin title22 {
	font-size: 2.2em;
	line-height: 1.45em;
}

@mixin title20 {
	font-size: 2em;
	line-height: 1.35em;
	font-weight: 300;
}

@mixin fontsize18 {
	font-size: 1.8em;
	line-height: 1.35em;
	font-weight: 300;
}

@mixin fontsize17 {
	font-size: 1.7em;
	line-height: 1.45em;
	font-weight: 300;
}

@mixin fontsize16 {
	font-size: 1.6em;
	line-height: 1.7em;
	font-weight: 300;
}

@mixin fontsize15 {
	font-size: 1.5em;
	line-height: 1.7em;
	font-weight: 300;
}

@mixin fontsize14 {
	font-size: 1.4em;
	line-height: 1.45em;
	font-weight: 300;
}

@mixin fontsize13 {
	font-size: 1.3em;
	line-height: 1.6em;
	font-weight: 300;
}

@mixin fontsize12 {
	font-size: 1.2em;
	line-height: 1.5em;
	font-weight: 300;
}

@mixin fontsize11 {
	font-size: 1.1em;
	line-height: 1.5em;
	font-weight: 300;
}

@mixin fontsize10 {
	font-size: 1.2em;
}

@mixin uppercase15 {
	font-size: 1.5em;
	line-height: 1.4em;
	letter-spacing: .5em;
	text-transform: uppercase;
}

@mixin uppercase15 {
	font-size: 1.5em;
	line-height: 1.8em;
	text-transform: uppercase;
}

@mixin uppercase14 {
	font-size: 1.4em;
	line-height: 1.8em;
	letter-spacing: .04em;
	text-transform: uppercase;
}

@mixin uppercase13 {
	font-size: 1.3em;
	line-height: 1.8em;
	letter-spacing: .08em;
	text-transform: uppercase;
}

@mixin uppercase12 {
	font-size: 1.2em;
	line-height: 1.8em;
	text-transform: uppercase;
	letter-spacing: .07em;
}

@mixin uppercase11 {
	font-size: 1.1em;
	line-height: 1.8em;
	letter-spacing: .04em;
	text-transform: uppercase;
}

@mixin uppercase8 {
	font-size: .8em;
	letter-spacing: .03em;
	text-transform: uppercase;
}

@mixin uppercase5 {
	font-size: .5em;
	letter-spacing: .03em;
	text-transform: uppercase;
}

@mixin fontsize13 {
	font-size: 1.3em;
	line-height: 1.6em;
	font-weight: 300;
}

@mixin fontsize12 {
	font-size: 1.2em;
	line-height: 1.5em;
	font-weight: 300;
}

@mixin fontsize10 {
	font-size: 1em;
	line-height: 1.5em;
	font-weight: 300;
}

@mixin fontsize8 {
	font-size: .8em;
	line-height: 1.5em;
	font-weight: 300;
}

@mixin blue-link {
    color: $revfluence-blue;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: $revfluence-blue-hover;
    }

    &:focus {
        color: $revfluence-blue-hover;
        outline: none;
    }
}

@mixin red-link {
    color: $error;
    text-decoration: none;
    cursor: pointer;
}

@mixin italics {
	font-style: italic;
}


@mixin link-lineheight {
	line-height: 3em;
}

::selection {
  background: $revfluence-blue-hover; /* WebKit/Blink Browsers */
  color: white;
}
::-moz-selection {
  background: $revfluence-blue-hover; /* Gecko Browsers */
  color: white;
}

@mixin standard-ul {
	list-style-image: url('../images/icons/list_bullet.png');
	list-style-position: inside;

	li {
		margin-bottom: 1em;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

@mixin standard-ol {
	list-style-position: inside;

	li {
		margin-bottom: 1em;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.red_txt{
  color:red;
}

.limit_error_txt{
  color: #878787;
}