.new_manage {

  .content_approval_tool {

    .content_checklist_container {
      width: 99%;
      padding: 1.35rem 1.5rem 1rem;
      border: 1px solid $lightgray-border;
      border-radius: 5px;
      display: inline-block;
      margin: 1rem 0;
      background-color: white;

      .checklist_title {
        font-weight: 600;
        padding-bottom: .7rem;
      }

      .incomplete_notice {}
    }

    .brands_checklist {

      .checklist_title {
        padding-bottom: 0;
        text-align: left;
        margin-bottom: .5rem;
      }

      .status_icon {
        position: absolute;
        left: -4px;
        top: 21px;
      }

      .control-indicator {
        display: none;
      }

      .checklist_item {
        padding: 1rem 1rem 1rem 2rem;
      }

      .request_changes_btn {
        display: block;
        padding: 0;
        font-size: .9rem;

        &:focus, &:active {
          box-shadow: none;
        }
      }
    }

    .creators_checklist {

      .status_icon {
        display: none;
      }
    }

    .content_checklist {
      font-weight: 600;
      margin-bottom: .5rem;

      .change_request_note {
        font-weight: 600;
      }

      .checkbox_control {
        margin-bottom: 0;
        padding: 0 15px 0 33px;
      }

      .checklist_item {
        position: relative;
        border-bottom: 1px solid $lightgray-border;


        &:last-child {
          border-bottom: none !important;
        }
      }

      .automated_checklist_item {

        .complete_icon {
          display: inline-block;
          margin-right: .5em;
          margin-top: .5em;
        }

      }

      .form_group {
        padding: .86rem 0 1rem;
        margin-bottom: 0;
      }

      .close_btn {
        background: none;
        padding: 0;
        position: absolute;
        right: 0;
        top: .66rem;
      }

      .checklist_text_container {
        overflow-wrap: break-word;
      }

      .revfluence_logomark {
        margin-right: .15rem;
        vertical-align: 1px;
      }

      .control-indicator {
        top: 4px;
      }
    }

    .approve_btn {
      margin: 0 0 .75rem;
    }

    .form_error_text{
      margin-bottom:0.75em;
    }

    .compose_request_changes_container {
      margin: .5rem 0 .75rem;

      fieldset {}

      .add_comment_label {
        font-weight: 600;
        display: block;
        margin-bottom: .5rem;
      }

      .comment_input {
        margin-bottom: .6rem;
      }

      .row {}

      .request_changes_btn, .cancel_btn {
        display: inline-block;
      }

      .send_request_btn {
        margin-right: .5rem;
      }

      .cancel_btn {}
    }

    .change_requested_module {
      border: 1px solid $lightgray-border;
      padding: 1rem 1.5rem;

      .change_request_msg {
        margin-bottom: 0;
      }

      .cancel_request_btn{
        margin-top:1rem;
      }
    }
  }
}
