.affiliate_nav {
	background-color: white;
	border-bottom: 1px solid $lightgray-border;
	z-index: $zindexBody;
	position: fixed;
	top: 5em;
	left: 0;
	width: 100%;
	background-color: white;

	@include media($break445) {
		top: 5em;
	}

	.inner_container {
		max-width: 1200px;
		margin: 0 auto;
		overflow: hidden;
	}

	.nav_link_list {
		float: left;
		width: calc(100% - 221px);
        padding: 0;

		@include media($break1205) {
			width: calc(100% - 236px);	
		}

		@include media($break530) {
			width: 100%;
			float: none;
			overflow: hidden;
		}

		li {
			float: left;
            padding: 0;
            margin-right: 3em;

			@include media($break530) {
				width: 50%;
				text-align: center;
			}

			@include media($break300) {
				width: 100%;
				float: none;
				display: block;
				border-right: none;
			}
		}

		.standard_link {
			color: $lightgray-text;
			padding: .75em 0 .75em;
			display: block;
			@include fontsize15;
			text-decoration: none;
			@include standard_transition;
			&:hover {
				color: $dark-text;
			}
		}

		.active {
			color: $dark-text;
			font-weight: 400;
			border-bottom: 3px solid $revfluence-blue;

			.link_text {
				color: $dark-text;
			}

		}

		.link_text {
			display: inline-block;
			margin-right: .25em;
		}

		.number_of_suggestions {
			background-color: $revfluence-blue;
			color: white;
			@include fontsize8;
			display: inline-block;
			border-radius: 40px;
			padding: .01em .5em;
		}

		.broadcast_live {
			background-color: $revfluence-green;
			@include fontsize8;
			display: inline-block;
			border-radius: 40px;
			padding: .01em .5em;
			color: white;
		}

		.broadcast_off {
			background-color: $gray-bg;
			@include fontsize8;
			display: inline-block;
			border-radius: 40px;
			padding: .01em .5em;
			color: white;
		}
	}

	.add_affiliate_container {
		float: right;

		.add_affiliate_button {
			margin-top: .4em;
			display: inline-block;
			padding: .05em 1.25em;
		}

		.add_affiliate_icon {
			width: 20px;
			height: 20px;
			display: inline-block;
			margin-right: .25em;
			background: url('../images/affiliates/add_affiliate_icon.svg') center no-repeat;
			padding-top: 3em;
			vertical-align: -1.1em;
		}

		.add_affilate_text {
			display: inline-block;
		}
	}
}

td {
	font-size: 16px;
}