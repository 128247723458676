.choose_campaign_modal {

	.modal-dialog {

		@include media($break650) {
			width: 95%;
		}
	}

	.choose_campaign_header {
		border-bottom: 1px solid $lightgray-border;
		padding: 2em;
		text-align: center;

		.choose_campaign_title {
			font-size: 1.7rem;

			@include media($break380) {
				font-size: 2.5em;
				font-weight: 600;
			}
		}

		.close_btn {
			background: url('/images/connect/close_icon.svg') center no-repeat;
			width: 20px;
			height: 20px;
			position: absolute;
			top: 12px;
			right: 12px;
			background-size: 20px;
			padding: 0;
		}
	}

	.choose_campaign_body {

		.invite_btn {
			opacity: 0;
			float: right;

			@include media($break650) {
				display: none;
			}
		}

		.campaign_list {
			max-height: 364px;
			overflow: auto;
			padding-bottom: 1.75rem;
			scrollbar-face-color: #AFAFAF;
			scrollbar-track-color: none;

			@include media($break630) {
			  background-color: #F8F8F8;
			  padding: 0;
			}

			::-webkit-scrollbar {
			  width:  6px;
			}

			::-webkit-scrollbar-thumb {
			  background: #D8D8D8;
			  border-radius: 100px;
			}

			::-webkit-scrollbar-track {
			  background: none;
			}
		}

		.campaign_item {
			padding: 1em 2em;
			cursor: pointer;

			&:hover {
				background-color: $aiq_lightblue_bg;
				color: $aiq_darkblue;

				.invite_btn {
					opacity: 1;

					@include media($break380) {
						display: none;
						opacity: 0;
					}
				}
			}

			@include media($break380) {
				padding: .5em 2em;
			}
		}

		.campaign_image {
			border-radius: 100px;
			width: 35px;
			height: 35px;
			display: inline-block;
			margin-right: 1em;
		}

		.campaign_title {
			display: inline-block !important;
			vertical-align: 11px;
			font-size: 1.6em;
			font-weight: 300;
			line-height: 19px;
			margin-bottom: -3px;
			width: calc(100% - 174px);

			@include media($break650) {
				width: calc(100% - 48px);
			}
		}

		.unavailable_campaigns_container {
			text-align: center;
			padding: 2rem 1rem;
		}

		.choose_campaign_create {
			text-align: center;
			padding: 0 1rem 2rem;

			.create_campaign_btn {
				margin-right: .5rem;
			}
		}
	}
}

.choose_watch_list_modal {

	.modal-dialog {

		@include media($break650) {
			width: 95%;
		}
	}

	.choose_watch_list_header {
		border-bottom: 1px solid $lightgray-border;
		padding: 2em;
		text-align: center;

		.choose_watch_list_title {

			@include media($break380) {
				font-size: 2.5em;
				font-weight: 600;
			}
		}

		.close_btn {
			background: url('/images/connect/close_icon.svg') center no-repeat;
			width: 20px;
			height: 20px;
			position: absolute;
			top: 12px;
			right: 12px;
			background-size: 20px;
			padding: 0;
		}
	}

	.choose_watch_list_body {

		.create_watch_list_btn, .view_watch_list_btn {
			margin: 1rem 1.4rem;
		}

		.invite_btn {
			opacity: 0;
			float: right;

			@include media($break650) {
				display: none;
			}
		}

		.watch_list_list {
			max-height: 364px;
			overflow: auto;
			scrollbar-face-color: #AFAFAF;
			scrollbar-track-color: none;

			@include media($break630) {
			  background-color: #F8F8F8;
			  padding: 0;
			}

			::-webkit-scrollbar {
			  width:  6px;
			}

			::-webkit-scrollbar-thumb {
			  background: #D8D8D8;
			  border-radius: 100px;
			}

			::-webkit-scrollbar-track {
			  background: none;
			}
		}

		.watch_list_item {
			padding: 1em 2em;
			cursor: pointer;

			&:hover {
				background-color: $lightgray-border;

				.invite_btn {
					opacity: 1;

					@include media($break380) {
						display: none;
						opacity: 0;
					}
				}
			}

			@include media($break380) {
				padding: .5em 2em;
			}
		}

		.watch_list_image {
			border-radius: 100px;
			width: 35px;
			height: 35px;
			display: inline-block;
			margin-right: 1em;
		}

		.watch_list_title {
			display: inline-block !important;
			vertical-align: 11px;
			font-size: 1.6em;
			font-weight: 300;
			line-height: 19px;
			margin-bottom: -3px;
			width: calc(100% - 174px);

			@include media($break650) {
				width: calc(100% - 48px);
			}
		}

		.unavailable_watch_lists_container {
			text-align: center;
			margin: 1rem;
		}

		.choose_watch_list_create {
			text-align: center;
		}
	}
}

.liked_creators, .recently_added, .watchlist, .mentioned_creators {

	.creatorList_header {
		padding-bottom: 4.25rem !important;
		min-height: 80px !important;
	}
}
