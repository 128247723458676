.error-modal {

 	.modal-body {
 		padding: 4em;
 	}

 	.close-modal-link {
		position: absolute;
		top: 2em;
		right: 2em;

		&:hover {}
	}

 	.error-title {
 		@include title25;
 		color: $dark-text;
 		margin-bottom: 1em;
 		text-align: center;
 	}

 	.error-description {
 		@include fontsize15;
 		color: $dark-text;
 		text-align: center;
 		font-weight: 300;
 		margin-bottom: 2em;
 	}

 	.okay-button {
 		width: 100px;
 		margin: 0 auto;
 		display: block;
 	}
}
