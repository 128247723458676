.example_brand_campaign {
	background-color: #fbfbfb;

	.standard-input-error {
		@include standard-input-error;
	}

	.standard-error-message {
		@include standard-error-message;
		display: none;
	}

	.error_container {
		margin-bottom: 1em;

		.error_text {
			color: white;
		}
	}

	.example_campaign_header {
		max-width: 1200px;
		margin: 0 auto;
		padding: 2em 0;
		@include media($break1235) {
			padding: 2em 4em;
		}
		@include media($break470) {
			padding: 2em;
		}
		@include media($break380) {
			padding: 2em;
		}

		.revfluence_logo {
			text-indent: -9999px;
			background: url('/images/themes/aspireiq/aiq_logo.png') center no-repeat;
			background-size: 100%;
			width: 139px;
			height: 31px;
			display: inline-block;
			margin-right: 2em;
			@include media($break325) {
				background: url('/images/themes/aspireiq/logo_a_mark.png') center no-repeat;
				background-size: 100%;
				width: 38px;
				height: 23px;
			}
		}

		.view_title {
			font-size: 1.5em;
			color: $dark-text;
			display: inline-block;
			vertical-align: -11px;
			@include media($break380) {
				text-align: right;
				float: right;
				font-weight: 300;
				margin-top: .3em;
			}
		}
	}

	.main_section {
		overflow: hidden;
		max-width: 1200px;
		margin: 0 auto;
		@include media($break1235) {
			padding: 0 4em;
		}
		@include media($break975) {
			margin: 0 auto 0;
		}
		@include media($break750) {
			padding: 0 1em;
		}
		@include media($break470) {
			padding: 0;
		}

		.budget_and_value {
			width: calc(100% - 415px);
			float: left;
			box-shadow: 0px 1px 18px 0px rgba(0, 0, 1, 0.08);
			border: 1px solid #rgb(226, 226, 226);
			padding: 3em 5em 4em 0;
			background-color: white;
			margin: 1em 2em 2em 1em;
			@include media($break936) {
				width: 100%;
				float: none;
				margin: 1em 0 2em;
			}

			.budget_input_container {
				margin-bottom: 3em;
				margin-top: 0;
				border-bottom: 1px solid #f9f9f9;
				padding-bottom: 1em;
				padding-left: 35px;
			}

			.budget_input_label {
				font-size: 3.4em;
				font-weight: 300;
				display: inline-block;
				margin-right: .25em;
				color: $dark-text;
				line-height: 1.3em;

				@include media($break675) {
					font-size: 2.65em;
					font-weight: 300;
					line-height: 1.4em;
				}
				@include media($break460) {
					font-size: 2em;
					font-weight: 500;
				}
			}

			.currency_input {
				display: inline-block;
				font-size: 3.4em;
				font-weight: 300;
				padding: .15em .35em .26em 0;
				border-radius: 3px;
				background-color: rgba(255,255,255,.5);
				width: 263px;
				color: #a9a9a9;
				-webkit-appearance: none;
				@include standard-transition;
				&:focus {
					border: 1px solid #3a96eb;
					outline: none;
					background-color: rgba(255,255,255,1);
				}
				@include media($break675) {
					font-size: 2.65em;
					font-weight: 300;
					line-height: 1.4em;
				}
				@include media($break460) {
					font-size: 2em;
				}
			}

			.budget_input {
				border: 0;
				background: none;
				font-size: 1em;
				display: inline-block;
				width: 85%;
				outline: none;
				margin-left: -.2em;
				color: $dark-text;
				font-weight: 300;

				&:focus {
					.currency_input {
						color: $dark-text !important;
					}
				}
			}

			.value_breakdown {
				margin-bottom: 3.3em;
				padding-left: 35px;
			}

			.value_breakdown_title {
				font-size: 2.35em;
				color: $dark-text;
				line-height: 1.3em;
				font-weight: 100;
				margin-bottom: .5em;
				@include media($break675) {
					font-size: 2em;
				}
				@include media($break460) {
					font-size: 1.4em;
				}
			}

			.value_list {
				list-style-position: inside;

				li {
					font-size: 1.6em;
					color: $dark-text;
					line-height: 2.4em;
					font-weight: 300;
					@include media($break460) {
						font-size: 1.4em;
					}

					strong {
						font-weight: 600;
					}
				}
			}

			.total_value_container {
				border-top: 1px solid #f9f9f9;
				padding-top: 3em;
				padding-left: 35px;
			}
		}

		.signup_form_container {
			width: 365px;
			float: right;
			box-shadow: 0px 1px 18px 0px rgba(0, 0, 1, 0.08);
			border: 1px solid #rgb(226, 226, 226);
			margin: 1em 1em 1em 0;
			background-color: white;
			@include media($break936) {
				float: none;
				width: 100%;
			}

			.signup_form {
				margin: 3em 35px 4em 35px;
				@include media($break936) {
					width: 315px;
					padding-bottom: 4em;
					margin: 3em 35px 1em;
				}
				@include media($break470) {
					width: 100%;
					margin: 3em auto 1em;
					padding: 0 3em 4em;
				}
			}

			.text_input {
				border: 1px solid #c7c7c7;
				border-radius: 3px;
				padding: .75em;
				font-size: 1.4em;
				margin-bottom: .5em;
				display: block;
				-webkit-appearance: none;
				width: 100%;
				&:focus {
					border: 1px solid #3a96eb;
					outline: none;
				}
			}

			.input_filled_in {
				background-color: rgba(255,255,255,.40);
				border: 1px solid rgba(199,199,199,.40);
			}

			.submit_button {
				background-color: #3a96eb;
				border: 1px solid #2b81c5;
				border-radius: 3px;
				padding: .75em;
				display: block;
				-webkit-appearance: none;
				cursor: pointer;
				font-size: 1.4em;
				color: white;
				width: 100%;
				&:hover {
					background-color: $revfluence-blue-hover;
				}
				&:focus {
					background-color: $revfluence-blue-hover;
					outline: none;
				}
			}
		}

		.signup_form_title {
			color: $dark-text;
			font-size: 2.35em;
			color: #201e1e;
			line-height: 1.3em;
			font-weight: 100;
			border-bottom: 1px solid #f9f9f9;
			padding-bottom: 1em;
			padding-left: 35px;
			margin: 1.2em 2.25em .5em 0;
			@include media($break936) {
				magin: 0 2.25em .5em 0;
				padding-top: 1.2em;
			}
			@include media($break460) {
				font-size: 2em;
				font-weight: 500;
			}
		}

		.title_cta_container {
			margin-bottom: 5em;
			margin-top: 3em;
			box-shadow: 0px 1px 18px 0px rgba(0, 0, 1, 0.08);
			border: 1px solid #rgb 226, 226, 226;
			padding: 3em;
			margin: 1em 1em 1em 0;
			background-color: white;
			margin-left: 1em;
			text-align: center;

			.title_cta {
				color: $dark-text;
				margin-bottom: .15em;
				font-size: 3.4em;
				font-weight: 300;
				display: inline-block;
				color: #201e1e;
				line-height: 1.3em;


				@include media($break710) {
					display: block;
					margin-right: 0;
					margin-bottom: .5em;
				}
				@include media($break460) {
					font-size: 2em;
					font-weight: 500;
				}
			}

			.start_now_button {
				background-color: #3a96eb;
				border: 1px solid #2b81c5;
				border-radius: 45px;
				padding: .75em 1.5em;
				display: block;
				text-align: center;
				width: 140px;
				-webkit-appearance: none;
				cursor: pointer;
				font-size: 1.5em;
				color: white;
				text-decoration: none;
				vertical-align: 3px;
				margin: 0 auto;
				&:hover {
					background-color: $revfluence-blue-hover;
				}
				&:focus {
					background-color: $revfluence-blue-hover;
					outline: none;
				}
				@include media($break710) {
					display: block;
					width: 125px;
					text-align: center;
					margin-bottom: 1em;
				}
			}

			.amounts_container {
				margin-bottom: 1.5em;
			}

			.cost_amount {
                font-size: 3.0em;
                color: $dark-text;
                font-weight: 300;
				display: block;
				line-height: 2em;
			}

			.videos_amount {
				font-size: 1.6em;
				color: $dark-text;
				font-weight: 300;
				display: inline-block;
				line-height: 2em;

				strong {
					font-weight: 600;
				}
			}
		}

		.campaign_details {
			max-width: 1200px;
			margin: 0 auto;
			overflow: hidden;
			@include media($break975) {
				margin: 0 auto 5em;
			}
			@include media($break750) {
				margin: 0 auto;
			}
		}

		.projected_stats_container {
			width: 48%;
			float: left;
			box-shadow: 0px 1px 18px 0px rgba(0, 0, 1, 0.08);
			border: 1px solid #rgb(226, 226, 226);
			margin: 1%;
			background-color: white;
			@include media($break1065) {
				margin-bottom: 4em;
			}
			@include media($break750) {
				margin: 1em;
				width: 97%;
				float: none;
			}

			.projected_stats_title {
				border-bottom: 1px solid #F2F2F2;
				padding-bottom: .7em;
				padding-left: 35px;
				margin-right: 60px;
				margin-top: 1em;
				font-size: 2.35em;
				color: $dark-text;
				line-height: 1.3em;
				font-weight: 100;
				margin-bottom: .75em;
				padding-top: 1em;
			}

			.total_value {
				margin-right: 0;
				margin-bottom: .3em;
				display: block;
				line-height: 1.2em;
			}

			.projected_stats_list {
				margin-bottom: 	2em;
				padding-left: 35px;
				margin-right: 3em;

				li {
					font-size: 1.6em;
					color: $dark-text;
					line-height: 2em;
					font-weight: 300;
				}

				strong {
					font-weight: 600;
				}
			}

			.total_value_container {
				padding-left: 35px;
				margin: 0 60px 3em 0;
				border-top: 1px solid #F2F2F2;
				padding-top: 2.4em;
				@include media($break750) {
					padding-bottom: 3em;
				}
			}
		}

		.notes_container {
			width: 48%;
			float: left;
			box-shadow: 0px 1px 18px 0px rgba(0, 0, 1, 0.08);
			border: 1px solid #rgb(226, 226, 226);
			margin: 1%;
			background-color: white;
			@include media($break750) {
				margin: 1em;
				width: 97%;
				float: none;
			}

			.notes_title {
				border-bottom: 1px solid #F2F2F2;
				padding-left: 35px;
				margin-right: 3em;
				margin-top: 1em;
				font-size: 2.35em;
				color: #201e1e;
				line-height: 1.3em;
				font-weight: 100;
				margin-bottom: .75em;
				padding-bottom: .7em;
				@include media($break750) {
					padding-top: 1em;
				}
			}

			.notes_list {
				list-style-type: disc;
				list-style-position: inside;
				margin-bottom: 2em;
				padding-left: 35px;
				margin-right: 4em;
				@include media($break750) {
					padding-bottom: 3em;
				}

				li {
					font-size: 1.6em;
					color: $dark-text;
					line-height: 2em;
					font-weight: 300;
					margin-bottom: .5em;
				}
			}
		}
	}

	.total_value {
		font-size: 3.0em;
		color: $dark-text;
		font-weight: 300;
		display: inline-block;
		margin-right: .35em;

		@include media($break1146) {
			display: block;
			margin-bottom: .35em;
		}
		@include media($break675) {
			font-size: 2.65em;
			font-weight: 300;
			line-height: 1.4em;
		}
		@include media($break460) {
			font-size: 2em;
			font-weight: 500;
			margin-bottom: .5em;
		}
	}

	.traditional_ad_comparison {
		font-size: 1.4em;
		color: $dark-text;
		display: inline-block;
		font-weight: 200;
		font-style: italic;
		@include media($break1146) {
			display: block;
		}
		@include media($break460) {
			font-size: 1.4em;
		}
	}

	.example_vids_container {
		background-color: #fbfbfb;
		padding: 3em 0 0;
		@include media($break1235) {
			padding: 3em 4em;
		}
		@include media($break750) {
			padding: 3em 2em;
		}
		@include media($break380) {
			padding: 3em 2em;
		}

		.example_vids_innerContainer {
			max-width: 1200px;
			margin: 0 auto;
		}

		.example_vids_title_container {
			margin-bottom: 3em;
			margin-left: 1em;
			@include media($break750) {
				margin-left: 0;
			}
			@include media($break515) {
				display: none;
			}
		}

		.small_example_vids_titles {
			display: none;
			margin-bottom: 2em;
			@include media($break515) {
				display: block;
			}

			.example_vids_text1 {
				margin-bottom: .75em;
			}

			.select_dropdown {
				margin-bottom: 1.5em;
			}
		}

		.example_vids_title {

		}

		.example_vids_text1 {
			font-size: 1.6em;
			color: $dark-text;
			margin-right: .5em;
			display: inline-block;
			line-height: 1.33em;
			font-weight: 200;
		}

		.select_dropdown {
			display: inline-block;
			margin-right: 1em;
			width: 200px;
			margin-top: 0;
			vertical-align: -12px;
		}

		.example_vids_text2 {
			font-size: 1.6em;
			color: $dark-text;
			display: inline-block;
			margin-left: 1.6em;
			font-weight: 200;
		}

		.video_list {
			overflow: hidden;
			margin-left: 1em;
			@include media($break750) {
				margin-left: 0;
			}
			@include media($break525) {
				margin-left: 0;
			}

			li {
				position: relative;
				float: left;
				width: 23.83%;
				margin-right: 1%;
				margin-bottom: 1%;
				-moz-osx-font-smoothing: grayscale;
				-webkit-transition-duration: 0.3s;
				transition-duration: 0.3s;
				-webkit-transition-property: transform;
				transition-property: transform;

				&:hover {
  					// transform: scale(1.04);
				}

				&:last-child {
					margin-right: 0;
				}

				@include media($break1015) {
					width: 32.33%;
				}

				@include media($break785) {
					width: 49%;
				}

				@include media($break525) {
					width: 100%;
					margin-right: 0;
					margin-bottom: 5%;
				}
			}
		}

		.play_icon {
			background: url('../images/example_brand_campaign/play_icon.svg') center no-repeat;
			width: 37px;
			height: 37px;
			position: absolute;
			left: 50%;
			top: 50%;
			margin: -18.5px 0 0 -18.5px;
		}

		.video_thumbnail_image {
			max-width: 100%;
		}
	}

	.new_landing_footer {
		background-color: #fbfbfb;

		.inner_container {
			max-width: 1200px;
			position: relative;
			width: auto;
			@include media($break1235) {
			margin: 0 4em;
		}

		}
	}
}
