.aspireiq_theme {

  .toggle_filter_container {

    .toggle_filter_button {
      background-color: $aiq_lightblue_bg;

      &:hover {
        background-color: $aiq_lightblue_bg_hover;
      }
    }

    .first_button {
      border-radius: 100px 0 0 100px;
    }

    .last_button {
      border-radius: 0 100px 100px 0;
    }

    .filterBtn_txt {
      font-weight: 400;
    }

      .couple_filter_list {

        .active_filter_item {

          .first_button {
            border-left: 1px solid $aiq_darkblue;
            border-right: 1px solid $aiq_darkblue;
            border-radius: 100px 0 0 100px;
          }

          .toggle_filter_button {
            background-color: $aiq_darkblue;
            border-top: 1px solid $aiq_darkblue;
            border-bottom: 1px solid $aiq_darkblue;
          }
        }
      }

      .active_filter_item {

        .first_button {
          border-left: 1px solid $aiq_darkblue;
          border-right: 1px solid $aiq_darkblue;
        }

        .toggle_filter_button {
          background-color: $aiq_darkblue;
          border-top: 1px solid $aiq_darkblue;
          border-bottom: 1px solid $aiq_darkblue;
        }
      }
  }

  .invited_type_container {

    .active_action_button {
      background-color: $aiq_darkblue;
      border: 1px solid $aiq_darkblue;
    }
  }
}
