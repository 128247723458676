.creator_profile {

  .learn_more_popup {

    .modal-dialog {
      max-width: 852px;
      padding: 2rem 2rem 2.5rem;

      @include media($break850) {
        margin: 0 auto;
        border-radius: 0;
      }

      @include media($break425) {
        padding: 2rem 1.25rem 2rem;
      }
    }

    .rev_logo {
      display: block;
      margin: 0 auto 1rem;
    }

    .learn_more_title {
      text-align: center;
      margin-bottom: 1.5rem;

      @include media($break425) {
        font-size: 1.5rem;
      }
    }

    .cta_container {
			max-width: 787px;
			margin: 0 auto;

			@include media($break940) {
				margin: 0 auto 2rem;
			}

			@include media($break350) {
				width: 100%;
			}

			.role_btn {
				border: 1px solid $lightgray-border;
				background-color: white;
				border-radius: 12px;
				padding: 1.5rem 2rem;
				width: 385px;
				float: left;
				margin-right: 1rem;
				cursor: pointer;
				display: block;
				color: $dark-text;
        height: 135px;

				&:focus, &:active {
					box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem $revfluence-blue;
				}

				&:last-child {
					margin-right: 0;
				}

				@include media($break940) {
					margin-right: 0;
					width: 100%;
					margin-bottom: .5rem;
          height: auto;
				}

				@include media($break350) {
					padding: 1rem 1.35rem;
				}
			}

			.title_container {
				margin-bottom: 1rem;
			}

			.arrow_icon {
				display: inline-block;
				vertical-align: -9px;
			}

			.left_arrow_icon {
				margin-right: 1rem;
			}

			.right_arrow_icon {
				margin-left: 1rem;
			}

			.title_txt {
				font-weight: 600;
				text-transform: uppercase;
				letter-spacing: .3rem;
				display: inline-block;
				font-family: sans-serif;
			}

			.brands_btn {
				transition: all .15s ease-in-out;

				&:hover {
					transform: scale(.95,.95);
				}

				.left_arrow_icon {

					@include media($break940) {
						display: none;
					}
				}

				.right_arrow_icon {
					display: none;

					@include media($break940) {
						display: inline-block;
					}
				}
			}

			.agency_btn {
				transition: all .15s ease-in-out;

				&:hover {
					transform: scale(.95,.95);
				}
			}

			.role_descrip {
				font-size: 1em;
				white-space: normal;
			}

			.email_text_input {
				background: white;
				padding: .5em;
				font-size: 1.5em;
				color: #201e1e;
				border: 1px solid #e3e3e3;
				border-radius: 2em;
				background-size: 15px;
				outline: none;
				font-weight: 300;
				margin: 0 auto .75em;
				width: 230px;
				display: block;
				transition: all .25s ease-in-out;
				text-align: center;
				line-height: 1.5em;

				@include media($break350) {
					width: 100%;
				}

				&:focus {
					background: #fff;
					box-shadow: 0 0 16px rgba(0,0,0,.1);

				}
			}

			.get_started_button {
				background-color: $revfluence-blue;
				padding: .5em 1.5em;
				color: white;
				display: block;
				text-align: center;
				text-decoration: none;
				font-size: 1.5em;
				border-radius: 2em;
				border: none;
				margin: 0 auto;
				line-height: 1.5em;
												cursor: pointer;
																	-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;

				@include media($break400) {
					display: block;
				}

				@include media($break350) {
					width: 100%;
				}

				&:hover {
					background-color: $revfluence-blue-hover;
				}
			}

			.open_dashboard_button {
				width: 180px;
			}

			.error_text {
				background-color: #ff5959;
				padding: .5em;
				border-radius: 2em;
				text-align: center;
				margin-top: 1em;
				font-size: 1.3em;
				color: white;
				font-weight: 300;
				width: 220px;
				margin: 1em auto 0;
			}
		}
  }
}
