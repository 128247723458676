.introCreator_modal {

  .wrapImg {
    background-color: $aiq_darkblue;
  }

  .introGraphic {
    margin: 0 auto;
    display: block;
  }

  .firstGraphic {
    max-width: 406px;
    padding-top: 1rem;
    margin: 0 auto;
    display: block;
  }

  .secondGraphic {
    padding: 1rem;
  }

  .thirdGraphic {
    padding: 2rem;
  }

  .introGraphic {
    max-width: 100%;
    padding: 1.5rem;
  }

  .pageIndicator_list {}

  .wrapTxt {
    text-align: center;
    padding: 0 1rem 2rem;
  }

  .main_title {
    margin: 0;
    padding: 0 0 .35rem 0;
    font-size: 1.75rem !important;
  }

  .main_descrip {
    padding-bottom: 1.25rem;
    font-size: 1.1rem;
  }

  .btn_primary {}

  .btn_next {
    width: 111px;
    margin: 0 auto;
    display: block;
  }
}
