.newAnalyze_view {

  .globalDiscuss {
    padding: 1.5rem 1.5rem 2rem;
    background-color: white;

    .col1, .col2, .col3 {
      float: left;
    }

    .col1, .col2 {
      padding-right: 2rem;
    }

    .col1, .col3 {
      width: 25%
    }

    .col1 {
      height: 100%;

      .mainTitle {
        font-weight: 400;
        padding-bottom: 1rem;
        font-size: 1.25rem;
      }

      .searchDiscuss {
        background: white url('/images/style_guide/icon_search_black.svg') 7px center no-repeat;
        background-size: 17px;
        padding-left: 1.75rem;
        margin-bottom: .75rem;
      }

      .discussList {}

      .discussItem {
        padding: .6rem .5rem;
        cursor: pointer;
        border-radius: 4px;

        &:hover {
          background-color: #F4F6FA;
        }

        &:active {
          background-color: white;
        }
      }

      .activeItem {
        background-color: #F4F6FA;

        &:active {
          background-color: white;
        }
      }

      .readStatus_wrap, .mini_contentWrap, .txtWrap {
        float: left;
      }

      .readStatus_wrap {
        width: 12px;
        padding-right: 1rem;
        height: 15px;
        padding-top: 21px;

        .icon_unread {
          width: 7px;
        }
      }

      .unreadItem {

        .txtWrap {
          font-weight: 600;
        }
      }

      .mini_contentWrap {
        width: 65px;
        padding-right: .85rem;
      }

      .img_thumbnail {
        max-width: 100%;
        border-radius: 3px;
      }

      .txtWrap {
        width: calc(100% - 81px);
      }

      .name, .msgPreview, .timestamp {
        display: block;
      }

      .name {}

      .name, .msgPreview, .timestamp {
        font-size: .75rem;
        line-height: 1.128rem;
      }

      .msgPreview {}

      .timestamp {
        font-size: .65rem;
        font-weight: 300;
      }
    }

    .col2 {
      width: 50%;

      .inputWrap {
        margin-bottom: 1rem;
      }
    }

    .col3 {

      .contentWrap {
        margin-bottom: .85rem;
      }

      .headerTitle {
        font-weight: 600;
        font-size: 1.05rem;
        width: calc(100% - 40px);
      }

      .editName_btn {
        border: none;
      }

      .icon_edit {
        width: 15px;
      }

      .contentMetadata_wrap {
        width: 100%;
      }

      .breakdownStat_list {
        padding-top: 1.4rem;
      }

      .breakdownStat_item {
        line-height: 1.25rem;
        width: 33%;

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}
