.new_creator_item {
  border-radius: 6px;
  padding: 10px;
  max-width: 500px;
  -webkit-transition: all ease 800ms;
  transition: all ease 800ms;
  opacity: 1.0;

  &.ng-enter {
    opacity: 0;
  }

  &.ng-leave {
    opacity: 0;
    &.ng-leave-active {
      opacity: 0;
    }
  }

  &:hover {
    background-color: #F5F5F5;

    .topControls_container, .top_shadow, .bottom_shadow, .previewContent_container {
      opacity: 1;
    }

    .topControls_container {
      top: .65rem;
    }

    .previewContent_container {
      bottom: -4%;
    }

    .caption_container {
      opacity: 0;
      top: .65rem;
    }
  }

  a {
    text-decoration: none;
  }

  .disabled {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: 0.7;
  };

  .row2 {
    padding: 1.25rem 0 0;

    .username {
      font-weight: 600;
      color: $dark-text;
      text-decoration: none;
      display: inline-block;
      max-width: calc(100% - 29px);
    }

    .tag_list {
      padding-bottom: .6rem;
      color: $dark-text;
    }

    .tag_item {
      display: inline-block;
    }

    .socialAccount_stats {}

    .socialNetwork_icon {
      width: 17px;
      margin-right: .15rem;
    }

    .youtube_icon {
      vertical-align: -2px;
    }

    .instagram_icon {
      width: 16px;
      vertical-align: -3px;
    }

    .favBtn_dropdown_container {
      vertical-align: 7px;
    }

    .favBtn {
      padding: 4px;
    }

    .icon_fav {
      width: 18px;
    }

    .favDropdown_list {
      top: 23px;
      left: -8px;
    }

    .admin_link {
      padding-bottom: 1rem;
      display: block !important;
    }

    .statTxt {
      color: $dark-text;
      font-weight: 300;
      vertical-align: -1px;
    }

    .socialAccount_item {
      display: inline-block;
      margin-right: .75rem;
      cursor: pointer;

      &:last {
        margin-right: 0;
      }

      &:hover {

        .statTxt {
          color: $revfluence-blue;
        }
      }
    }
  }

  .bottomActions_container {
    display: none;
  }
}

.new_creator_item2 {

  .bottomActions_container {
    display: block;
    padding-top: 20px;

    .inviteBtn, .offerBtn {
      width: 49%;
      padding: 8px 20px;
      font-weight: 400;

      &.invitedBtn, .offeredBtn {
        color: $gray-text;
      }
    }

    .invited_icon {
      width: 23px;
    }

    .inviteBtn {
      margin-right: 1%;
    }

    .invite_icon {
      margin-right: 1rem;
      width: 18px !important;
      vertical-align: -1px;
    }

    .offer_icon {
      width: 18px !important;
      vertical-align: -2px;
    }

    .invitedBtn, .offeredBtn {
      color: $aiq_grey;
      background-color: $aiq_grey;

      &:hover, &:focus, &:active {
        color: #A2A2A2;
        background-color: $aiq_grey;
      }
    }

    .manageBtn {
      color: $aiq_darkblue;

      &:hover, &:focus {
        color: $aiq_darkblue_hover;
        background-color: $aiq_lightblue_bg_hover;
      }
    }
  }

  .inelligible_creator {
    background-color: #EFEFEF;
    border-radius: 5px;
    padding: .5rem 1rem;
    text-align: center;
    position: relative;
    z-index: 5;
    margin-top: 20px;

    .inelligible_txt {
      color: #656565;
      font-weight: 400;
      line-height: 1.15rem;
      font-size: .85rem;
    }

    .report_btn {
      font-size: .85rem;
      font-weight: 400;
    }
  }
}

.youtube_creator_item {

  .previewContent_item {
    width: 24.26%;
    background-color: $dark-text;
  }

  .previewContent_thumb {}

  .row2 {
    padding: 1.5rem 0;

    @include media($break725) {
      padding: 1.25rem 0;
    }
  }
}

.instagram_creator_item {}
