.how_it_works {

	.intro_container {
		border-bottom: 1px solid $lightgray-border;

		.main_header {
			max-width: 1000px;
			margin: 7em auto 0;
			padding: 4em;

			@include media($break515) {
				margin: 5em auto 0;
			}

			@include media($break325) {
				padding: 3em;
			}

			.main_title {
				font-size: 5em;
				color: $dark-text;
				font-weight: 300;
				text-align: center;

				@include media($break515) {
					font-size: 4em;
				}

				@include media($break325) {
					font-size: 3em;
				}
			}
		}

		.video_container2 {
			max-width: 500px;
			margin: 0 auto 2rem;

			@include media($break525) {
				width: 400px;
			}

			@include media($break405) {
				width: 300px;
			}

			@include media($break325) {
				margin: 0 auto 2em;
			}

			@include media($break325) {
				width: 250px;
			}

			iframe {
				width: 100%;
			}
		}

		.cta_container {
			margin-bottom: 85px;
			text-align: center;

			@include media($break325) {
				margin-bottom: 45px;
			}
		}

		.cta_title {
			margin-bottom: .85em;

			@include media($break325) {
				font-size: 2.25em;
			}
		}

		.start_sub_btn {}

		.hint_link {
			display: block;
			text-decoration: none;
			text-align: center;
			padding-bottom: 3em;
		}

		.hint_label {
			font-size: 1.5em;
			display: block;
			margin-bottom: 1em;
			color: #988D90;
		}

		.hint_icon {
			width: 35px;
			height: 20px;
			margin: 0 auto;
			background: url('/images/how_it_works/hint_arrow_icon.svg') center no-repeat;
		}

	}

	.more_details_container {
		max-width: 1000px;
		margin: 0 auto;
		@include media($break1025) {
			padding: 0 30px;
		}

		.step_container {
			border-bottom: 1px solid #f6f6f6;
			overflow: hidden;
			padding: 4em 0;
		}

		.step1 {}

		.text_container {
			float: left;
			width: calc(90% - 300px);
			margin-top: 5.5em;
			@include media($break875) {
				margin-top: 0;
			}
			@include media($break680) {
				float: none;
				width: 100%;
			}
		}

		.step_title {
			color: $dark-text;
			font-size: 2.25em;
			line-height: 1.5em;
			margin-bottom: 1em;
			font-weight: 300;
		}

		.step_description {
			font-size: 1.68em;
			color: #988d90;
			font-weight: 300;
			line-height: 1.6em;
		}

		.visual_container {
			float: right;
			width: 260px;
			box-shadow: 0px 1px 11px 0px rgba(1, 1, 1, 0.13);
			border: 6px solid white;
			margin-right: .65em;
			@include media($break680) {
				float: none;
				margin-top: 3em;
				margin-left: 1em;
			}
			@include media($break380) {
				width: 100%;
			}
		}

		.step_visual {
			display: block;
			max-width: 100%;
		}

		.alternate_layout {

			.text_container {
				float: right;
				@include media($break680) {
					float: none;
				}
			}

			.visual_container {
				float: left;
				margin-left: .65em;
				@include media($break680) {
					float: none;
					margin-top: 3em;
					margin-left: 1em;
				}
			}
		}

		.step2 {}

        .start_campaign_button {
            border: 1px solid $revfluence-blue;
            border-radius: 45px;
            width: 160px;
            color: $revfluence-blue;
            display: block;
            text-decoration: none;
            text-align: center;
            padding: .75em 0;
            font-size: 1.5em;
            margin-bottom: 2em;
            margin-top: 1.0em;
            &:hover {
                border: 1px solid $revfluence-blue-hover;
            }
        }


        .get_started_button {
            background-color: $revfluence-blue;
            border-radius: 45px;
            width: 160px;
            color: white;
            display: block;
            text-decoration: none;
            text-align: center;
            padding: .75em 0;
            font-size: 1.5em;
            margin-bottom: 2em;
            margin-top: 1.0em;
            &:hover {
                background-color: $revfluence-blue-hover;
            }
        }

	}

	.closing_cta_container {
		max-width: 1000px;
		margin: 0 auto;
		padding: 5em 0;

		.cta_title {
			font-weight: 300;
			color: $dark-text;
			text-align: center;
			line-height: 1.5em;
			margin-bottom: 1em;
			font-size: 2.25em;
		}

		.cta_descrip {
			font-size: 1.5em;
			color: #201e1e;
			font-weight: 300;
			line-height: 1.6em;
			text-align: center;
		}

		.get_started_button {
			background-color: $revfluence-blue;
			border-radius: 45px;
			width: 160px;
			margin: 0 auto 2.4em;
			color: white;
			display: block;
			text-align: center;
			text-decoration: none;
			padding: .85em;
			font-size: 1.5em;
			&:hover {
				background-color: $revfluence-blue-hover;
			}
		}

		.see_case_study {
			font-size: 1.5em;
			line-height: 1.5em;
			color: #988d90;
			font-weight: 300;
			display: block;
			text-align: center;

			a {
				color: $revfluence-blue;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}
