.change_campaign_popup {

  .modal-dialog {
    padding: .75rem 1.5rem 1.5rem;

    @include media($break600) {
      margin: 35px auto;
    }

    .close-modal-link {
      position: absolute;
      right: .75rem;
      top: .75rem;
    }

    .close_modal_icon {}

    .main_title {
      padding-bottom: .25rem;
    }

    .main_descrip {
      padding-bottom: .75rem;
    }

    .campaigns_container {}

    .guidance_detail {
      padding-bottom: .75rem;
      font-weight: 400;
    }

    .campaigns_list {}

    .campaigns_item {
      margin-bottom: .5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .select_campaign_btn {
      border: 1px solid $lightgray-border;
      padding: .5rem 3rem .5rem .5rem;
      background: none;
      display: block;
      width: 100%;
      border-radius: 6px;
      text-align: left;
      font-weight: 300;

      &:hover {
        background: $ultraLight_gray;
      }
    }

    .actively_selected {
      background: $revfluence-blue url('/images/style_guide/icon_checkmark_white.svg') 96% center no-repeat;
      border: 1px solid $revfluence-blue;
      color: white;
      font-weight: 500;
      background-size: 17px;

      &:hover {
        background: $revfluence-blue url('/images/style_guide/icon_checkmark_white.svg') 96% center no-repeat;
        background-size: 17px;
      }
    }

    .campaign_img, .campaign_name {
      float: left;
    }

    .campaign_img {
      width: 42px;
      margin-right: .5rem;
      border-radius: 100px;
    }

    .campaign_name {
      width: calc(100% - 60px);
      margin-top: 8px;
    }

    .actions_container {
      padding-top: 1.25rem;
    }

    .btn_primary {
      width: 100px;
      display: block;
      margin: 0 auto;
    }
  }
}
