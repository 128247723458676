 .btn_preview_mediaKit {
   border-radius: 4px;
   padding: 7px 11px 8px 10px;

  .mediaKit_preview {
     overflow: hidden;
     height: 38px;
     width: 50px;
     border: 1px solid white;
     box-shadow: 0 1px 1px 0 rgba(0,0,0,0.15);
     border-radius: 4px;
     display: inline-block;
     position: relative;

     .icon_search {
       width: 19px;
       margin-top: 8px;
     }

     .mediaKit_preview_thumbnail {
       max-width: 100%;
     }

     .view_icon_container {
       position: absolute;
       top: 0;
       right: 0;
       bottom: 0;
       left: 0;
       background-color: black;
       background-color: rgba(0,0,0,.2);
       opacity: 0;
       transition: all .15s ease-in-out;
       border-radius: 4px;
     }
   }

   &:hover {

     .view_icon_container {
       background-color: rgba(0,0,0,.4);
       opacity: 1;
     }
   }
}
