.analyze_view {

	/* FILTERS FOR CONTENT TAB */
	.content_searchFilters_container {
		padding: 1rem 0;
		max-width: 1400px;
		margin: 0 auto;

		@include media($break1400) {
			padding: 1rem;
		}

		@include media($break990) {
			display: none;
		}

		.search_input, .contentFilters_container, .order_btn {
			float: left;
		}

		.search_input {
			background-image: url('/images/style_guide/icon_search_black.svg');
			background-position: .5rem center;
			background-repeat: no-repeat;
			padding-left: 1.85rem;
			max-width: 300px;
			margin-right: .5rem;
			background-size: 17px;

			@include media($break625) {
				max-width: 550px;
				margin-bottom: 1rem;
			}
		}

		.filters_dropdown_list {
			display: block;
			right: 2px;
			top: 35px;
			text-align: left;
		}

		.contentFilters_container {
			margin-right: .4rem;
			position: relative;
		}

		.filters_dropdown_btn {
			background-position: 88% center;
			padding: .5rem 1.7rem .5rem .95rem
		}

		.filters_btn {
			display: block;
			color: $aiq_black;

			&:hover, &:focus {
				color: $aiq_darkblue;
			}
		}

		.btn_txt {}

		.dropdown_indicator, .order_indicator {
			width: 11px;
			margin-left: .25rem;
		}

		.order_btn {}

		/* Apply active_orderBtn class when user engages this button */
		.active_orderBtn {
			background-color: $revfluence-blue;
			border: 1px solid $revfluence-blue;

			.btn_txt {
				color: white;
			}
		}

		.btn_txt {
			color: $dark-text;
		}

		.ascending_indicator {}

		.descending_indicator {}

		.order_indicator {}
	}

	.noContent_contentList_container {
		display: none;
	}

	.start_campaign_banner {
		margin-top: 92px;
	}

	.main_content {

		.tableToolbar {
			padding: .5rem .65rem;
			background-color: $ultraLight_gray;

			.search_creators_input, .export_btn {
				float: left;
			}

			.inner_container {}

			.search_creators_input {
				background: white url('/images/style_guide/icon_search_black.svg') 5px center	 no-repeat;
				background-size: 17px;
				padding-left: 1.75rem;
				width: 400px;
				margin-right: .5rem;

				@include media($break990) {
					width: 100%;
				}
			}

			.export_btn {
				border-radius: 6px;
			}
		}

		.overview_main_container {
			margin-bottom: 2em;
		}

		.inner_container {
			margin: 0 auto;
			background-color: white;
			box-shadow: 1px 1.732px 10px 0px rgba(1, 1, 1, 0.02);
			padding: 0 1.618rem;

			@include media($break420) {
				padding: 0 .9rem;
			}
		}

		.overview_stats_container {
			overflow: hidden;
			padding: 4em;
		}

		.overview_stats_list {}

		.overview_stats_item {
			float: left;
			width: 15%;
			margin-right: 7em;
			border-radius: 8px;
			padding: 2em;
			cursor: pointer;
			border: 1px solid white;

			&:last-child {
				margin-right: 0;
			}
			/*
                    &:hover {
                        border: 1px solid #ececec;
                        box-shadow: 1px 1px 5px rgba(0,0,0,.045);
                    }
            */
		}

		.stat_number {
			display: block;
			font-size: 3em;
			color: $dark-text;
			font-weight: 300;
			margin-bottom: .25em;
		}

		.stat_label {
			display: block;
			font-size: 1.3em;
			color: $dark-text;
			font-weight: 300;
			line-height: 1.5em;
		}

		.posts_stat {}

		.like_stat {}

		.comments_stat {}

		.impressions_stat {}

		.active_stat {
			border: 1px solid #dcdcdc;
			box-shadow: 1px 1px 5px rgba(0,0,0,.095);

			&:hover {
				border: 1px solid #dcdcdc;
			}

			.stat_number {
				font-weight: 600;
			}

			.stat_label {
				font-weight: 600;
			}
		}

		.graph_container {
			padding: 0 4em 4em 4em;
			margin-bottom: 2em;
		}

		.graph_title {
			font-size: 1.65em;
			color: $dark-text;
			line-height: 1.5em;
			text-align: center;
			font-weight: 600;
			margin-bottom: 1.5em;
		}

		.sample_barchart {
			max-width: 100%;
		}
	}

	.no_content, .error_container {
		max-width: 1400px;
		margin: 3em auto;
		background-color: white;
		padding: 1.4em 0 6em;
		box-shadow: 1px 1.732px 10px 0px rgba(1, 1, 1, 0.02);

		@include media($break985) {
			padding: 2em 0 6em;
		}

		.main_title {
			font-size: 1.65em;
			line-height: 1.5em;
			color: $dark-text;
			font-weight: 600;
			text-align: center;
			margin-bottom: .5em;
		}

		.main_descrip {
			font-size: 1.65em;
			line-height: 1.5em;
			color: $dark-text;
			text-align: center;
			margin-bottom: 2em;
			font-weight: 300;
		}

		.main_cta {
			background-color: $revfluence-blue;
			border-radius: 45px;
			color: white;
			font-weight: 500;
			font-size: 1.5em;
			line-height: 1.5em;
			text-align: center;
			display: block;
			width: 250px;
			margin: 0 auto;
			text-decoration: none;
			padding: .65em 1em;
			&:hover {
				background-color: $revfluence-blue-hover;
			}
		}
	}

	.creator_username_link, .brand_username_link {
		text-decoration: none;
		color: $dark-text;
		display: inline-block;
		max-width: 150px;


		&:hover {
			color: $revfluence-blue;
		}
	}

	.content_main_container {

		.content_list {
			max-width: 1400px;
			margin: 0 auto;
			width: 100%;
			padding: 0 1rem 2rem;

			@include media($break900) {
				padding: 0 1rem 2rem;
				margin-top: 1rem;
			}
		}

		.content_item {
			width: 23.33%;
			margin-right: 1.67%;
			margin-bottom: 2%;
			float: left;
			background-color: white;
			border-radius: 6px;

			@include media($break900) {
				width: 31.66%;
			}
			@include media($break700) {
				width: 48.33%;
			}
			@include media($break465) {
				float: none;
				width: 100%;
				margin: 0 auto 2em;
			}

			.moreDetails_container {
				&.dropdown_active {
					display: block;
				}
			}

			&:hover {

				.moreDetails_container {
					display: block;
					z-index: 100;
				}
			}
		}

		.imgContent_container {
			border-radius: 5px 5px 0 0;
			border: none;

			.imgContent {
				border-radius: 5px 5px 0 0;
			}
		}

		.content_image_container {
			position: relative;
			opacity: 1;
			height: 250px;

			&:hover {
				opacity: .9;
			}
		}

		.youtube_image_container {
			border-bottom: 1px solid #eeeeee;
		}

		.play_icon {
			position: absolute;
			top: 50%;
			left: 50%;
			margin-top: -25px;
			margin-left: -25px;
			width: 50px;
			height: 50px;
			display: block;
			background: url('/images/analyze/play_icon.svg') center no-repeat;
		}

		.captionContainer {
			border-radius: 0;
		}

		.content_image {
			width: 100%;
		}

		.youtube_image {
			padding: 52px 0 51px;
		}

		.content_info_container {
			border-right: 1px solid $lightgray-border;
			border-bottom: 1px solid $lightgray-border;
			border-left: 1px solid $lightgray-border;
			border-radius: 0 0 6px 6px;
		}

		.content_stats_section {
			height: 48px;
		}

		.content_stats {
			padding: 1.25em 1.5em;
		}

		.content_stats_list {}

		.content_stats_item {
			font-size: 1.25em;
			color: $dark-text;
			display: inline-block;
			margin-right: 1em;
			line-height: 1.5em;
			font-weight: 300;
			&:last-child {
				margin-right: 0;
			}
		}

		.content_caption_container {
			padding: 0 1.5em 1.25em;
			height: 70px;
		}

		.content_caption {
			font-size: 1.25em;
			color: $dark-text;
			line-height: 1.5em;
			font-weight: 300;

			a {
				color: $revfluence-blue;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.content_metadata {
			padding: 0 1.5em 1.25em;
			border-bottom: 1px solid #eeeeee;
		}

		.content_metadata_list {}

		.content_metadata_item {
			color: #b4b4b4;
			display: inline-block;
			margin-right: 1em;
			font-size: 1.25em;
			line-height: 1.5em;
			font-weight: 300;
			&:last-child {
				margin-right: 0;
			}
		}

		.date_published {}

		.creator_type {
			display: inline-block;
			width: 15px;
			height: 15px;
			vertical-align: -4px;
		}

		.content_metadata_image {
			display: inline-block;
		}

		.all_creator {
			width: 20px;
			vertical-align: -6px;
			margin-left: 6px;
		}

		.revfluence_creator {}

		.content_creator_info {
			overflow: hidden;
		}

		.creator_profile_link {
			display: block;
			overflow: hidden;
			padding: 1.5em;
			&:hover {
				background-color: #fbfdff;
			}
		}

		.avatar_container {
			float: left;
			width: 50px;
			margin-right: 1.25em;
		}

		.avatar_image {
			width: 50px;
			height: 50px;
			display: block;
			border-radius: 100px;
		}

		.text_container {
			float: left;
			margin-top: .65em;
		}

		.creator_username {
			font-size: 1.25em;
			font-weight: 600;
			line-height: 1.5em;
			text-decoration: none;
			color: #a3a3a3;
		}

		.network_icon {
			width: 15px;
			height: 15px;
			display: inline-block;
			margin-right: .5em;
			vertical-align: -3px;
		}

		.network_type_text {
			font-size: 1.25em;
			line-height: 1.5em;
			color: #a3a3a3;
			font-weight: 300;
		}
	}

	.creators_main_container {
		margin-bottom: 2em;

		.inner_container {
			padding: 0;
		}

		.creators_table {
			white-space: nowrap;
			width: 100%;

			.standard_tooltip {
				width: 250px;
				left: 50%;
				bottom: initial;

				&:after, &:before {
					display: none;
				}
			}

			tbody {}

			thead {
				border-bottom: 1px solid #e3e3e3;
			}

			tr {
				border-bottom: 1px solid #f2f1f1;
			}

			th {
				padding: 17px 10px;
			}

			td {
				padding: 10px;
			}

			tfoot {

				tr {
					border-bottom: none;
				}
			}

			.creator_row {
				&:hover {
					background-color: #fcfcfc;
				}
			}

			.table_header {
				color: #a8a8a8;
				font-weight: 300;
				text-transform: uppercase;
				letter-spacing: .05em;
				font-size: 1.15em;
				text-align: left;
				cursor: pointer;
				&:hover {
					color: $dark-text;
				}

				.tooltip_container {
					display: inline-block;
				}



				.standardTooltip {
					width: 250px;
					left: initial;
					bottom: initial;

					&:after, &:before {
						display: none;
					}
				}
			}

			.active_sort_header {
				font-weight: 600;
				color: $dark-text;
			}

			.sort_down_icon {
				width: 15px;
				height: 9px;
				display: inline-block;
				background: url('/images/analyze/sort_down_arrow.svg') center no-repeat;
			}

			.sort_up_icon {
				width: 15px;
				height: 9px;
				display: inline-block;
				background: url('/images/analyze/sort_up_arrow.svg') center no-repeat;
			}

			.creator_avatar {
				width: 42px;
				height: 42px;
				display: inline-block;
				margin-right: 1em;
				vertical-align: -17px;
				border-radius: 100px;
			}

			.network_type {
				display: inline-block;
				width: 15px;
				height: 15px;
				vertical-align: -3px;
			}

			.creator_username, .followers, .posts, .views, .clicks, .order_count, .order_amount, .click_rate, .likes, .comments, .common_followers, .date_posted {
				font-size: 1.5em;
				color: $dark-text;
				line-height: 1.25em;
				font-weight: 300;
			}

			.actions {
				text-align: right;
			}

			.pages_label {
				display: inline-block;
				color: $dark-text;
				margin-right: 1em;
				font-size: 1.5em;
				line-height: 1.5em;
				display: inline-block;
			}

			.pages_list {
				display: inline-block;

				a {
					display: inline-block;
					color: $revfluence-blue;
					text-decoration: none;
					padding: 10px;

					&:hover {
						color: $revfluence-blue-hover;
					}
				}
			}

			.page_item {
				display: inline-block;
				font-size: 1.5em;
				margin-right: 1em;
			}

			.page_arrow_icon {
				width: 20px;
				height: 22px;
				text-indent: -9999px;
				vertical-align: -8px;
			}

			.page_left {
				background: url('/images/analyze/previous_page_gray.svg') center no-repeat;
			}

			.page_right {
				background: url('/images/analyze/next_page_blue.svg') center no-repeat;
			}
		}

		.view_posts_icon {
			background: url('/images/analyze/view_posts_icon.svg') center no-repeat;
		}

		.messages_icon {
			background: url('/images/analyze/view_messages_icon.svg') center no-repeat;
		}

		.invite_icon {
			background: url('/images/analyze/like_icon.svg') center no-repeat;
		}

		.action_link {
			text-decoration: none;
			display: inline-block;
			margin-right: 1rem;
			vertical-align: -11px;

			&:last-child {
				margin-right: 0;
			}

			&:hover {
				.action_text {
					color: $revfluence-blue;
				}
			}
		}

		.action_link_disabled {
			pointer-events: none;
			cursor: default;
			&:hover {
				.action_text {
					color: #a8a8a8;
				}
			}

			.invite_icon {
				background: url('/images/analyze/like_icon_disabled.svg') center no-repeat;
			}

			.messages_icon {
				background: url('/images/analyze/view_messages_icon_disabled.svg') center no-repeat;
			}

			.view_posts_icon {
				background: url('/images/analyze/disabled_view_posts_icon.svg') center no-repeat;
			}

		}

		.action_icon {
			display: block;
			margin-bottom: 1em;
			width: 20px;
			height: 20px;
			margin: 0 auto .5em;
		}

		.action_text {
			display: block;
			color: $revfluence-blue;
			text-decoration: none;
			text-decoration: none;
			font-size: .85em;
			font-weight: 300;
			line-height: 1.5em;
		}
	}

	.brands_main_container {
		margin-bottom: 2em;

		.inner_container {
			padding: 0 3%;
		}

		.brands_table {
			width: 100%;

			thead {
				border-bottom: 1px solid #e3e3e3;
			}

			tr {
				border-bottom: 1px solid #f2f1f1;
			}

			th {
				padding: 17px 10px;
			}

			td {
				padding: 10px;
			}

			tfoot {

				tr {
					border-bottom: none;
				}
			}

			.brand_row {
				&:hover {
					background-color: #fcfcfc;
				}
			}

			.table_header {
				color: #a8a8a8;
				font-weight: 300;
				text-transform: uppercase;
				letter-spacing: .05em;
				font-size: 1.15em;
				text-align: left;
				cursor: pointer;
				&:hover {
					color: $dark-text;
				}

				.hint-icon {
					display: inline-block;
					position: relative;
					width: 15px;
					height: 15px;
					cursor: pointer;
					vertical-align: -2px;
					background: url('../images/manage/hint_icon.svg') center no-repeat;
				}

				.standardTooltip {
					@include standard_tooltip;
					width: 180px;
					bottom: 31px;
					left: -83px;
					text-transform: none;
				}
			}

			.active_sort_header {
				font-weight: 600;
				color: $dark-text;
			}

			.sort_down_icon {
				width: 15px;
				height: 9px;
				display: inline-block;
				margin-left: 5px;
				background: url('/images/analyze/sort_down_arrow.svg') center no-repeat;
			}

			.sort_up_icon {
				width: 15px;
				height: 9px;
				display: inline-block;
				margin-left: 5px;
				background: url('/images/analyze/sort_up_arrow.svg') center no-repeat;
			}

			.brand_avatar {
				width: 42px;
				height: 42px;
				display: inline-block;
				margin-right: 1em;
				vertical-align: -17px;
			}

			.network_type {
				display: inline-block;
				width: 15px;
				height: 15px;
				vertical-align: -3px;
			}

			.brand_username, .followers, .posts, .views, .clicks, .order_amount, .order_count, .click_rate, .likes, .comments, .date_posted {
				font-size: 1.5em;
				color: $dark-text;
				line-height: 1.25em;
				font-weight: 300;
			}

			.actions {
				text-align: right;
			}

			.pages_label {
				display: inline-block;
				color: $dark-text;
				margin-right: 1em;
				font-size: 1.5em;
				line-height: 1.5em;
				display: inline-block;
			}

			.pages_list {
				display: inline-block;

				a {
					display: inline-block;
					color: $revfluence-blue;
					text-decoration: none;
					padding: 10px;

					&:hover {
						color: $revfluence-blue-hover;
					}
				}
			}

			.page_item {
				display: inline-block;
				font-size: 1.5em;
				margin-right: 1em;
			}

			.page_arrow_icon {
				width: 20px;
				height: 22px;
				text-indent: -9999px;
				vertical-align: -8px;
			}

			.page_left {
				background: url('/images/analyze/previous_page_gray.svg') center no-repeat;
			}

			.page_right {
				background: url('/images/analyze/next_page_blue.svg') center no-repeat;
			}
		}

		.view_posts_icon {
			background: url('/images/analyze/view_posts_icon.svg') center no-repeat;
		}

		.messages_icon {
			background: url('/images/analyze/view_messages_icon.svg') center no-repeat;
		}

		.invite_icon {
			background: url('/images/analyze/like_icon.svg') center no-repeat;
		}

		.action_link {
			text-decoration: none;
			display: inline-block;
			margin-right: 1rem;
			vertical-align: -11px;

			&:last-child {
				margin-right: 0;
			}
			&:hover {
				.action_text {
					color: $revfluence-blue;
				}
			}
		}

		.action_link_disabled {
			pointer-events: none;
			cursor: default;
			&:hover {
				.action_text {
					color: #a8a8a8;
				}
			}

			.invite_icon {
				background: url('/images/analyze/like_icon_disabled.svg') center no-repeat;
			}

			.messages_icon {
				background: url('/images/analyze/view_messages_icon_disabled.svg') center no-repeat;
			}

			.view_posts_icon {
				background: url('/images/analyze/disabled_view_posts_icon.svg') center no-repeat;
			}

		}

		.action_icon {
			display: block;
			margin-bottom: 1em;
			width: 20px;
			height: 20px;
			margin: 0 auto .5em;
		}

		.action_text {
			display: block;
			color: #a8a8a8;
			text-decoration: none;
			text-decoration: none;
			font-size: 1em;
			lin-height: 1.5em;
		}
	}

	.loadMore_container {
		padding: 1rem;

		.loadMore_btn {
			width: 200px;
			display: block;
			margin: 0 auto;
		}
	}

	.new_content_list {

		.default_body_copy {
			font-size: .9rem;
		}

		.engageList {
			padding: .9rem .6rem .6rem;
		}

		.accoutLink {
			color: $dark-text;
			text-decoration: none;
		}

		.txtContent_container {
			border-radius: 5px 5px 0 0;
		}

		.content_container {
			border: none;
			border-radius: 6px 6px 0 0;
		}

		.retweetItem {}

		.loveItem {}

		.likeItem {}

		.viewItem {}

		.shareItem {}

		.engageItem {
			float: left;
			margin-right: 1rem;

			&:last-child {
				margin-right: 0;
			}
		}

		.engageIcon {
			width: 17px;
		}

		.likeIcon {
			vertical-align: 1px;
		}

		.retweetIcon {}

		.loveIcon {
			vertical-align: -2px;
			width: 16px;
		}

		.reachIcon {
			width: 21px;
		}

		.clickIcon {
			width: 15px;
			vertical-align: -2px;
		}

		.viewIcon {
			width: 22px;
			vertical-align: 1px;
		}

		.shareIcon {
			width: 20px;
		}

		.engageAmount {
			vertical-align: 2px;
			color: $dark-text;
			text-decoration: none;
		}

		.account_section {
			padding: .7rem .6rem .7rem;
			font-size: .8rem;
			height: 67.38px;
			border-top: 1px solid $lightgray-border;
			position: relative;

			.moreDetails_container {
				position: absolute;
				right: 0;
				top: 0;
				bottom: 0;
				background: url('/images/style_guide/right_white_fade_bg2.png') -42px top no-repeat;
				z-index: 100;
				padding: 12px 10px 12px 18px;
				background-size: 100%;
				display: none;

				.moreDetails_btn {
					padding: .75rem 1.75rem .75rem .5rem;
					background: #fff url("/images/style_guide/dropdown_indicator_icon.svg") 92% center no-repeat;
					background-size: 14px;
					border: 1px solid #D7D7D7;
					box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);

					&:hover {
						background-color: $ultraLight_gray;
					}

					&:focus, &:active {
						box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem $aiq_darkblue;
					}
				}

				.btn_txt {
					font-weight: 600;
					font-size: .8rem;
					line-height: .8rem;
					color: $aiq_darkblue;
				}

				.moreDetails_dropdown {
					width: 300px;
					top: 53px;
					padding: 1rem;

					&:after, &:before {
						right: 18px;
						left: initial;
					}
				}

				.dropdown_list {
					display: block;
				}

				.detailSet {
					margin-bottom: 1rem;

					&:last-child {
						margin-bottom: 0;
					}
				}

				.detailSet_info {
					font-size: .8rem;
				}

				.dataSet {}

				.iconCol, .txtCol {
					float: left;
				}

				.iconCol {
					width: 20px;
					margin-right: 1rem;
				}

				.standard_label {
					font-size: .8rem;
					font-weight: 600;
					display: block;
					margin-bottom: 0;
				}

				.dataSet_icon {
					max-width: 100%;
				}

				.detailSet_link_btn {
					font-size: .8rem;
					border: none;
					padding: 0;
				}

				.icon_winner {}

				.txtCol {
					width: calc(100% - 50px);
				}

				.standardTags_list {
					padding-top: .4rem;
				}

				.standardTags_item {

					&:last-child {
						border-bottom: 1px solid $lightgray-border;
						padding: 5px 6px;
					}
				}
			}
		}

		.avatarImg {
			max-width: 100%;
			height: 45px;
			overflow-x: hidden;
			width: 100%;

			&:hover {
				opacity: .9;
			}
		}

		.account_network_icon {
			position: absolute;
			bottom: -2px;
			right: -7px;
			width: 25px;
			z-index: 1;
		}

		.avatar_container, .txt_container {
			float: left;
		}

		.avatar_container {
			width: 45px;
			position: relative;
			z-index: 0;
		}

		.txt_container {
			width: calc(100% - 61px);
		}

		.instagram_icon {}

		.youtube_icon {}

		.snapchat_icon {}

		.vine_icon {}

		.facebook_icon {}

		.blog_icon {}

		.pinterest_icon {}

		.accountName_container {
			line-height: 1rem;
		}

		.accountName {
			font-weight: 600;
			display: inline-block;
			max-width: calc(100% - 101px);
			margin-right: .15rem;
			color: $aiq_black;
			text-decoration: none;

			&:hover {
				color: $aiq_darkblue;
			}
		}

		.accountFollowers {
			display: inline-block;
			width: 95px;
		}

		.dateContainer {}

		.dateTxt {}

		.boldText {
		}

		.positiveSentimentText {
			color: green;
		}

		.veryPositiveSentimentText {
			color: lightgreen;
		}

		.neutralSentimentText {
		}

		.negativeSentimentText {
			color: red
		}

		.revLogo {
			width: 16px;
			vertical-align: -3px;
			margin: 0 4px;
		}
	}

	.valueSection {
		border-top: 1px solid $lightgray-border;
		padding: .5rem .6rem;
		font-size: .8rem;

		> li {
			float: left;
			margin-right: 3%;

			&:last-child {
				margin-right: 0;
			}
		}

		.standard_tooltip {
			width: 250px;
			left: 50%;
			bottom: initial;

			&:after, &:before {
				display: none;
			}
		}

		.estPaid_item {
			width: 29%;
		}

		.mediaValue_item {
			width: 37%;
		}

		.roi_item {
			width: 28%;
		}

		.valueLabel {
			display: block;
		}

		.valueLabel_txt {}

		.tooltip_container {
			display: inline-block;
			margin-left: .05rem;
		}

		.hint_btn {
			padding: 0;
			border: none;
			background: none;
		}

		.hint_icon {
			background: none;
		}

		.valueAmount {
			display: block;
		}
	}
}
