.creator_profile {

  .edit_name_modal {

    .modal-dialog {
      padding: 2rem;
    }

    .main_title {
      text-align: center;
      margin-bottom: 1.5rem;
    }

    fieldset {
      display: block;
      max-width: 250px;
      margin: 0 auto .85rem;
    }

    .last_name_fieldset {
      margin: 0 auto 1.75rem;
    }

    .location_input {}

    .actions_container {
      text-align: center;
    }

    .save_btn {
      display: inline;
      margin-right: .5rem;
    }

    .cancel_btn {
      display: inline;
    }
  }
}
