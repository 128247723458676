.alert {
	display: none;
	position: fixed;
	top: 82px;
	z-index: 7002;
	left: 50%;
	margin-left: -28.5%;
	width: 57%;
	background-color: #feffce;
	border-radius: 4px;
	padding: 1em;
	border: 1px solid white;
	box-shadow: 1px 1px 7px rgba(0,0,0,.25);
	@include media($break850) {
		width: 95%;
		margin-left: -47.35%;
	}
	@include media($break550) {
		top: 118px;
	}
	@include media($break400) {
		top: 160px;
	}

	.alert_text {
		color: $dark-text;
		font-weight: 500;
		display: block;
		margin-right: 50px;
		text-align: center;
		font-size: 1.4em;
		line-height: 1.5em;
	}

	.dismiss_button {
		display: block;
		position: absolute;
		top: 12px;
		right: 13px;
		width: 14px;
		height: 14px;
		background: url('/images/alerts/dismiss_icon.svg') center no-repeat;
	}
}
