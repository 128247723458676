.creator_profile {

  .share_profile_banner {
    background-color: $light_action_blue;
    padding: 1rem 1rem 1.15rem;
    text-align: center;
    display: block !important;

    .main_descrip {
      font-weight: 600;
      margin-bottom: .65rem;
    }

    .entered_url_state {

      .profile_url {
        margin-right: .35rem;
        vertical-align: -2px;
      }

      .edit_btn {}

      .edit_icon {}
    }

    .success_text {
      color: #CDCC10;
    }

    .editing_url_state {
      width: 502px;
      margin: 0 auto;

      @include media($break530) {
        width: 100%;
      }

      .url_container {
        float: left;
        margin-right: .5rem;

        @include media($break530) {
          float: none;
          margin-bottom: .5rem;
        }
      }

      .beginning_of_url {
        float: left;
        margin-right: .5rem;
        margin-top: .4rem;

        @include media($break530) {
          float: none;
          margin: .4rem auto .5rem;
          display: block;
        }
      }

      .custom_url_input {
        float: left;
        width: 235px;

        @include media($break530) {
          float: none;
          margin: 0 auto .25rem;
          width: 100%;
          max-width: 250px;
        }
      }

      .save_url_btn {
        float: left;

        @include media($break530) {
          float: none;
        }
      }
    }

    .change_username_btn {
      padding: 0;
      font-size: .8rem;
      display: none;
    }

  }
}
