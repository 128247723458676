.aspireiq_theme {

  .ui-state-default,
  .ui-widget-content .ui-state-default,
  .ui-widget-header .ui-state-default {
    background: $aiq_darkblue;
  }

  .ui-state-hover,
  .ui-widget-content .ui-state-hover,
  .ui-widget-header .ui-state-hover,
  .ui-state-focus,
  .ui-widget-content .ui-state-focus,
  .ui-widget-header .ui-state-focus {
    background: $aiq_darkblue;
  }

  .ui-state-hover a,
  .ui-state-hover a:hover,
  .ui-state-hover a:link,
  .ui-state-hover a:visited,
  .ui-state-focus a,
  .ui-state-focus a:hover,
  .ui-state-focus a:link,
  .ui-state-focus a:visited {
    background: $aiq_darkblue;
  }

  .ui-state-active,
  .ui-widget-content .ui-state-active,
  .ui-widget-header .ui-state-active {
    background: $aiq_darkblue;
  }

  .ui-state-highlight,
  .ui-widget-content .ui-state-highlight,
  .ui-widget-header .ui-state-highlight {
    background: $aiq_darkblue;
  }
}
