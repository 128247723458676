.new_proposal_body {
	overflow-y: hidden;

	@include media($break845) {
		overflow-y: initial;
		background-color: #F8F8F8;
	}

	.relationship_view {

		@include media($break845) {
			overflow: initial;
			margin: 50px auto 0;
			padding: 0;
		}
	}
}

.new_manage {

	.new_proposal_title {

		a {
			color: $dark-text;
			text-decoration: none;
		}
	}

	.offer_summary {
		margin-top: 1rem;
		margin-bottom: 0;

		.offer_details_text {
			vertical-align: 0;
			width: calc(100% - 32px);
		}
	}

	.new_proposal_container {
		height: 100%;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;

		.compensation_section {
			margin-bottom: 0;
		}

		.creator_reviews_container {
			padding: .9rem 1.35rem 1rem;
			border-bottom: 1px solid $lightgray-border;

			.main_label {
				display: block;
				font-weight: 600;
				margin-bottom: .5rem;
			}

			.row {}

			.reviews_label, .star_reviews_list, .star_review_item {
				float: left;
			}

			.reviews_label {
				margin-right: .5rem;
			}

			.star_reviews_list {
				margin-right: .5rem;
				margin-top: .1rem;
			}

			.star_review_item {
				margin-right: .25rem;
				position: relative;

				&:last-child {
					margin-right: 0;
				}
			}

			.review_icon {}

			.based_on_indicator {
				color: #8B8B8B;
			}
		}

		.mediaKit_container {
			padding: .9rem 1.35rem 1rem;
			border-bottom: 1px solid $lightgray-border;

			.main_label {
				display: block;
				font-weight: 600;
				margin-bottom: .5rem;
			}

			.mediaKit_preview {
				margin-right: .4rem;
				vertical-align: -13px;
			}
		}

		.account_name_container {
			margin-bottom: 1.5em;
		}

		.invited_indicator_container {
			margin-top: 2em;

			.invited_icon {
				width: 25px;
				height: 25px;
				display: inline-block;
				background: url('/images/manage/invited_indicator_icon.svg') center no-repeat;
				margin-right: .5em;
				vertical-align: -8px;
			}

			.invited_text {
				display: inline-block;
				font-size: 1.3em;
				font-weight: 300;
				color: $dark-text;
				line-height: 1.5em;
				font-style: italic;
				width: calc(100% - 33px);
			}
		}

		.collab_terms_tool {
			/* display: block !important; */
			height: 100%;
			background-color: white;

			.tools_header {
				display: none;
			}

			.tool_main_container {
				height: calc(100% - 178px);
				overflow-y: auto;
				-webkit-overflow-scrolling: touch;

				@include media($break845) {
					height: 100%;
					overflow-y: initial;
					overflow: initial;
				}
			}

			.terms_container {
				width: 51%;
				margin-right: 4%;
				background: white;

				@include media($break875) {
					width: 100%;
				}
			}

			.add_posts_container {
				width: 45%;
			}
		}

		.new_proposal_header {
			padding-bottom: 22px;
			border-bottom: 1px solid $lightgray-border;
			overflow: hidden;
			margin-bottom: 25px;

			@include media($break845) {
				background-color: white;
				padding: 20px 2% 22px;
			}

			@include media($break560) {
				padding: 15px 2% 17px;
			}

			@include media($break550) {
				margin-bottom: 16px;
				padding: 12px 2% 14px;
			}

			@include media($break400) {
				padding: 8px 2% 10px;
			}

			.back_button {
				width: 25px;
				float: left;
				background: url('/images/new_manage/back_icon.svg') center no-repeat;
				height: 25px;
				border: none;
				text-indent: -9999px;
				cursor: pointer;
				padding: 20px;
				margin: 5px 10px 0 0;

				@include media($break630) {
					float: none;
					position: absolute;
					left: 5px;
					margin: 0;
					top: 75px;
				}

				@include media($break550) {
					top: 61px;
				}

				@include media($break400) {
					top: 58px;
				}
			}

			.title_container {
				overflow: hidden;
				float: left;
				width: calc(100% - 50px);

				@include media($break630) {
					width: 86%;
					margin: 0 auto;
					float: none;
					text-align: center;
				}

				@include media($break500) {
					width: 84%;
				}
			}

			.avatar_container {
				width: 50px;
				height: 50px;
				display: inline-block;
				margin-right: 16px;

				@include media($break550) {
					width: 35px;
					height: 35px;
					vertical-align: -9px;
					margin-right: 7px;
				}
			}

			.new_user_avatar {
				max-width: 100%;
				border-radius: 100px;
			}

			.new_proposals_title {
				line-height: 1.2em;
				display: inline-block;
				margin: 6px 10px 0 0;

				@include media($break560) {
					font-size: 2.7em;
					margin: 10px 10px 0 0;
				}

				@include media($break550) {
					display: none;
					line-height: 1.4em;
				}
			}

			.new_proposal_username {
				display: none;

				@include media($break550) {
					display: inline-block;
					font-size: 2.1em;
					font-weight: 500;
				}

				@include media($break400) {
					margin: 0;
				}
			}
		}

		.new_proposals_container {
			margin-bottom: 35px;
			height: 100%;

			.proposals_title {
				display: none;
				padding: 0 3%;
				margin-bottom: 12px;
				font-weight: 600;
				font-size: 1.75em;

				@include media($break550) {
					display: block;
					font-weight: 500;
				}
			}

			.new_proposal_list {
				height: calc(100% - 178px);
				overflow-y: auto;
				-webkit-overflow-scrolling: touch;

				@include media($break845) {
					height: 100%;
					overflow-y: initial;
					padding: 0 3%;
				}
			}
		}
	}

	.demographics_container {
		border: 1px solid $lightgray-border;;
		border-radius: 3px;
		margin-bottom: .2rem;
		background-color: white;
    position: relative;

		.demographics_display_toggle {
			display: block;
			background: none repeat scroll 0 0 transparent;
			border-spacing: 0;
			margin: 0;
			text-align: left;
			text-decoration: none;
			outline: none;
			cursor: pointer;
			-webkit-appearance: none;
			border: none;
			width: 100%;
			padding: .75rem 1rem .75rem;
			position: relative;

			&:hover {
				background-color: #fafafa;
			}

			.toggle_icon {
				width: 15px;
				height: 15px;
				display: inline-block;
				margin-right: .35em;

				@include media($break350) {
					vertical-align: 17px;
				}
			}

			.toggle_icon_up {
				background: url('../images/manage/toggle_arrow_up.svg') center no-repeat;
			}

			.toggle_icon_down {
				background: url('../images/manage/toggle_arrow_down.svg') center no-repeat;
			}

			.toggle_text {
				font-size: 1.2em;
				color: $dark-text;
				display: inline-block;
				vertical-align: 5px;
				font-weight: 300;
				margin-left: .25rem;

				@include media($break350) {
					width: calc(100% - 22px);
				}
			}
		}

		.demographics_data_container {
			overflow: hidden;
			padding: .5rem 1rem;
			border-top: 1px solid #E8E8E8;

			.stat_list {
				font-size: 1.2em;
				color: $dark-text;
				line-height: 1.4em;
				font-weight: 300;
				float: left;
				margin-right: 5%;
				width: 28.33%;


				&:last-child {
					margin-right: 0;
				}

				@include media($break400) {
					float: none;
					margin-right: 0;
					margin-bottom: 1em;
				}
			}

			.stat_name {
				font-weight: 400;
			}
		}

		.device_stats {
			margin-top: 1em;
		}
	}

	.audience_report_container {
		margin-bottom: 1.25em;

		.audience_report_icon {
			margin-right: .4rem;
			vertical-align: -4px;
		}

		.data_showcase_button {
			border: none;
			font-size: 1.3em;
			color: $dark-text;
			padding: .75rem 1rem .75rem 1.2rem;
			border: 1px solid #E8E8E8;
			border-radius: 3px;
			display:block;
			text-align: left;
			background-color: white;

			&:hover {
				background-color: #fafafa;
			}
		}
	}

	.post_price {
		border: 1px solid $lightgray-border;
		padding: 1.25em 1.75em 1.7em;
		border-radius: 5px;
		margin-bottom: 1.25em;
		background-color: white;

		&:last-child {
			margin-bottom: 0;
		}

		.postPrice_title {
			margin-right: .15em;
			vertical-align: -2px;
			display: inline-block;
		}

		.price_amount {
			font-size: 2.5em;
			display: block;
		}

		.percentageFrom_average {
			display: block;
		}

		.below_average {
			color: green;
		}

		.above_average {
			color: red;
		}

		.just_above_average {
			color: #AFAF0D;
		}
	}

	.proposalContent_previewContainer {
		margin-bottom: 1em;

		.content_container {
			cursor: pointer;
		}
	}

	.accountsWith_history_container {
		transition: all .15s ease-in-out;

		.warningNotification {
			background-color: $aiq_dangerRed;
			border-radius: 3px;
			margin-left: 0.25rem;
			color: white;
			padding: 0 0.25rem;
		}

		.talentAgency_disclaimer {
			padding-bottom: 1.25rem;

			.talentAgency_disclaimer_txt {
				font-weight: 600;
			}
		}

		.accordionBtn {
			position: relative;
		}

		.arrow_icon {
			position: absolute;
			right: 0;
			top: 50%;
			width: 12px;
			margin-right: 16px;
			margin-top: -5px;
		}

		.social_accounts_item {
			margin-bottom: .75rem;

			&:last-child {
				margin-bottom: 0;
			}
		}


		/* Apply this to .arrow_icon this when the account accordions are opened */
		.open_arrow_icon {
			transform: rotate(180deg);
		}

		.network_icon {
			width: 16px;
			margin-right: .14rem;
		}

		.nameContainer {
			padding-bottom: .25rem;
		}

		.nameTxt {
			font-weight: 600;
			vertical-align: -4px;
			font-size: .9rem;
			display: inline-block;
			max-width: calc(100% - 118px);
		}

		/*.viewAccount_arrowColor_icon {
			display: none;
		}*/

		.tabs_container {

			.nav_tabs > li {
				margin-bottom: -1px;
			}

			.nav_tabs>.active {
				margin-bottom: -1px;
			}
		}

		.viewAccount_arrowColor_icon, .viewAccount_arrow_icon {
			width: 8px;
			margin-left: .35rem;
			vertical-align: -2px;
		}

		.viewAccount_btn {
			font-size: .8rem;
			display: inline-block;
			font-weight: 600;
			color: $revfluence-blue;
			padding: 3px 11px;
			margin-left:.25rem;
			position: relative;
			z-index: 3;
			vertical-align: 0;

			&:hover, &:focus {
				color: $revfluence-blue-hover;

				.viewAccount_arrowColor_icon {
					display: inline-block;
				}

				.viewAccount_arrow_icon {
					display: none;
				}
			}
		}

		.metrics_container {
			font-weight: 300;
		}

		.followersContainer, .engageContainer, .priceContainer {
			float: left;
		}

		.followersContainer, .engageContainer {
			width: 27%;

			@include media($break1460) {
				width: 27%;
			}

			@include media($break1200) {
				width: 100%;
			}

			@include media($break800) {
				width: 27%;
			}

			@include media($break650) {
				width: 27%;
			}

			@include media($break515) {
				width: 100%;
			}
		}

		.priceContainer {
			width: 45%;

			@include media($break1000) {
				width: 100%;
			}

			@include media($break800) {
				width: 45%;
			}

			@include media($break650) {
				width: 45%;
			}

			@include media($break515) {
				width: 100%;
			}
		}

		.metricTxt {
			font-weight: 300;
			font-size: .85rem;
		}

		.metric_number {

			@include media($break1460) {
				display: block;
			}

			@include media($break1200) {
				display: inline;
			}

			@include media($break650) {
				display: block;
			}

			@include media($break515) {
				display: inline;
			}
		}

		.metric_label {}

		.active_tab_content {
			background: white;
			margin-top: 0;

			@include media($break400) {
				padding: .5rem;
			}
		}

		.nav_tabs {
			border-bottom: none;
		}

		.collabHistory_list {

			.search_input {
				background: url('/images/style_guide/icon_search_black.svg') 8px center no-repeat;
				background-size: 16px;
			}

			> li {
				margin-bottom: 1rem;
				border-bottom: 1px solid $lightgray-border;
				padding-bottom: 1rem;

				&:last-child {
					margin-bottom: 0;
					border-bottom: none;
					padding-bottom: 0;
				}
			}

			.collabTitle {
				padding-bottom: .75rem;
			}

			.collabContent {}

			.contentImg_container {
				width: 150px;
				float: left;
				margin-right: 1rem;
				margin-bottom: .25rem;
			}

			.noCollabs_txt {}

			.contentImg {
				display: block;
				max-width: 100%;
				border-radius: 6px;
			}

			.contentControls_container {
				position: relative;
			}

			.contentControls_container {
				position: relative;
			}

			.scrollLeft_btn {
				left: 0;
			}

			.scrollRight_btn {
				right: 0;
			}

			.scrollBtn {
				position: absolute;
				padding: 0;
				background: none;
				top: 8px;
			}

			.contentAmount {
				text-align: center;
				display: block;
				padding-top: 10px;
			}

			.navLeft_icon {}

			.navRight_icon {}

			.navIcon {
				display: block;
			}

			.contentDescrip_container {
				float: left;
				width: calc(100% - 166px);
			}

			.contentDescrip {}

			.content_item_link {
				z-index: 10000;
			}
		}

		.postsInRed_hint {
			margin-top: .75rem;
			font-weight: 600;
			font-size: .8rem;

			.icon_alert {
				vertical-align: -5px;
				margin-right: .25rem;
			}
		}
	}
}
