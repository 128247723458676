.creator_signup_with_offer {

	.main_wrapper {
		width: 900px;
		margin: 0 auto;
		position: relative;
		background: url('../images/themes/aspireiq/background_photo3.jpg') right 144px	 no-repeat;

		@include media($break1000) {
			width: 90%;
		}
		@include media($break800) {
			background: none;
			width: 80%;
		}
	}

	.signin_link {
		position: absolute;
		right: 0;
		top: 2em;
		display: block;
		padding: .45em 1.25em;
		@include fontsize14;
		color: $new_gray_text;
		text-decoration: none;
		background-color: white;
		border-radius: 45px;
		border: 1px solid $lightgray-border;


		&:hover {
			color: $revfluence_blue;
		}
	}

	.open_portal_link {
		position: absolute;
		right: 0;
		top: 2em;
		display: block;
		padding: .75em;
		@include fontsize14;
		color: $new_gray_text;
		text-decoration: none;

		&:hover {
			color: $revfluence_blue;
		}
	}

	.main_content {
		padding-top: 3em;
		width: 435px;
		@include media($break520) {
			width: 100%;
		}

		.revfluence_branding {
			margin-bottom: 8em;
			@include media($break465) {
				margin-bottom: 4em;
			}

			.revfluence_logo {

				a {
					display: block;
					width: 154px;
					height: 34px;
					text-indent: -9999px;
					background: url('/images/themes/aspireiq/aiq_logo.png') center no-repeat;
					background-size: 100%;
				}
			}
		}

		.brand_who_offered {
			margin-bottom: 2em;
			position: relative;
			width: 75px;

			.brand_logo {
				width: 75px;
				height: 75px;
				display: block;
				@include circle-radius;
				border: 1px solid $lightgray-border;
			}

			.reply_icon {
				position: absolute;
				right: 0;
				bottom: 0;
				width: 25px;
				height: 25px;
				background: url('../images/creator_signup_with_offer/reply_icon.svg') center no-repeat;
			}
		}

		.intro_message {
			margin-bottom: 3em;

			.intro_title {
				@include title30;
				color: #3e3e3e;
			}

			.intro_descrip {
				font-size: 1.4em;
				color: #a9a9a9;
				line-height: 1.6em;
				@include media($break520) {
					width: 100%;
				}
			}
		}

		.main_cta {
			margin-bottom: 2em;
			display: inline-block;

			.main_cta_icon {
				display: inline-block;
				margin-right: .5em;
				vertical-align: -8px;
				width: 25px;
				height: 25px;
			}

			.youtube_icon {
				background: url('../images/creator_signup_with_offer/youtube_icon.svg') center no-repeat;
			}

			.instagram_icon {
				background: url('../images/style_guide/icon_instagram_white.svg') center no-repeat;
			}

			.blog_icon {
				background: url('../images/creator_signup_with_offer/icon_googleAnalytics_color@2x.png') center no-repeat;
				background-size: 100%;
			}

			.main_cta_text {
				display: inline-block;
			}
		}

		.additional_info_container {
			margin-bottom: 3em;
			width: 400px;
			padding-bottom: 3em;
			border-bottom: 1px solid #e2e2e2;
			@include media($break460) {
				width: 100%;
			}

			.additional_info_message {
				@include fontsize14;
				color: white;
				margin-bottom: 1em;
				background-color: #fc8f80;
				border: 1px solid #fa371d;
				padding: .75em;
				border-radius: 6px;
			}

			.email_input_container {
				overflow: hidden;
				margin-bottom: 1em;

                .block-error {
                    display: block;
                }
			}

			.email_input {
				@include standard-input;
				display: inline-block;
				float: left;
				border-right: none;
				border-radius: 3px 0 0 3px;
				width: 220px;
				&:focus {
					border-right: none;
				}

				@include media($break460) {
					width: 100%;
					display: block;
					float: none;
					border-radius: 3px;
					border: 1px solid #e8e8e8;
					margin-bottom: 1em;
				}
			}

			.submit_email_button {
				display: inline-block;
				float: left;
				border-radius: 0 3px 3px 0;
				@include fontsize15;
				padding: .6em 1em;
				@include media($break460) {
					width: 100%;
					float: none;
					border-radius: 3px;
					display: block;
				}
			}

			.sign_in_link {
				@include fontsize14;
				color: $new_gray_text;

				a {
					@include blue-link;
					font-size: 1em;
				}
			}

			.browse_marketplace_button {}
		}

		.why_authorize {
			margin-bottom: 18em;
			@include media($break465) {
				margin-bottom: 10em;
			}

			.why_authorize_title {
				font-size: 1.4em;
				@include bold;
				color: #3e3e3e;
				letter-spacing: 0em;
				line-height: 1.6em;
			}

			.why_authorize_list {
				list-style: inherit;
				padding-left: 1.3em;
				li {
					font-size: 1.4em;
					color: #a9a9a9;
					line-height: 1.6em;
				}
			}

			.why_authorize_text {
				font-size: 1.4em;
				color: #a9a9a9;
				line-height: 1.6em;
			}
		}
	}

	.signup_with_offer_footer {
		@include media($break800) {
			position: relative;
			bottom: auto;
			margin-bottom: 3em;
		}

		.signup_footer_list {

			li {
				@include fontsize14;
				color: $new_lightgray_text;
				display: inline-block;
				margin-right: 1.5em;

				&:last-child {
					margin-right: 0;
				}

				@include media($break465) {
					display: block;
				}
			}

			a {
				color: $new_lightgray_text;
				text-decoration: none;

				&:hover {
					color: $revfluence-blue;
				}
			}

		}
	}
}
