.agreement_iteration {
    overflow: scroll;

    .absolute_center {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 300px;
        display: block;
    }

    .collaboration_proposal_container {
        padding: 2em;
    }
}