$lightgray_bg: #F6F6F6;
$pressedBtn_gray: #F2F2F2;
$revfluence_blue: #2582f4 !default;
$revfluence_blue_hover: #4998f9;

/* ASPIRE IQ THEME */
$aiq_lightblue: #7FD4F4;
$aiq_darkblue: #3996E0;
$aiq_darkblue_hover: #4FA7EE;
$aiq_bluegrey: #2E426D;
$aiq_purple: #70397C;
$aiq_purplepink: #C6549E;
$aiq_orange: #EF5682;
$aiq_pink: #EA92AF;
$aiq_grey: #E9E8EA;
$aiq_greyTxt: #8F8D91;
$aiq_black: #1A1818;
$aiq_lightblue_bg: #EFF5F9;
$aiq_lightblue_bg_hover: #E6EDF2;
$aiq_yellow: #EACD60;
$aiq_nude: #FFE9D9;
$aiq_border: #E6EDF2;
$aiq_dangerRed: #F1515F;
$aiq_dangerRed_hover: #FF6E7B;
$aiq_dangerLightRed: #F8EDEE;
$aiq_nude: #FFF1E7;
$aiq_nude_hover: #FFF6F0;
$aiq_nude_border: #F4E5DB;
$aiq_offwhite: #FDFDFD;
$bg_default: #D9EFF8;
$aiq_border_errorbg: #EF6C78;
$aiq_forest_green: #869661;

$facebook_blue: #3A5997;
$facebook_blue_hover: #4969A8;

$aspireiq_oxford_blue: #0f2753;
$aspireiq_space_cadet_blue: #263e69;
$aspireiq_persian_blue: #218cb9;
$aspireiq_bondi_blue: #4aafd6;
$aspireiq_blue_dark: #136391;
$aspireiq_blue_medium: #178CBB;
$aspireiq_blue_light: #7ACEE5;
$aspireiq_blue_lighter: #e3ebf4;
$aspireiq_blue_lightest: #e7eff7;
$aspireiq_border: #E9E8EA;

$aspireiq_indian_yellow: #FFB262;
$aspireiq_dark_coral: #F85C5A;
$aspireiq_desert_sand: #FFDCBF;
$aspireiq_desert_sand_tone1: #FCF9F0;
$aspireiq_desert_sand_tone1_border: #EDEAE1;
$aspireiq_melon: #FAC0BB;
$aspireiq_bigdiporuby: #C4246D;
$aspireiq_tumbleweed: #FAC0BB;
$aspireiq_turkish_rose: #E7A6C5;

$aspireiq_mellow_apricot: #fdb269;
$aspireiq_burntsienna: #f55b5e;
$aspireiq_hotgradient: linear-gradient(to right, #fdb269 0%,#f55b5e 100%);
$aspireiq_hotgradient-hover: linear-gradient(to right, #fdb269 0%,#fdb269 67%,#f55b5e 100%);
$aspireiq_hotgradient2: linear-gradient(-45deg, $aiq_bluegrey, $aiq_lightblue, $aiq_darkblue);

$helpDull_gray: #84D78F;

$lightgray_border: #ECECEC;
$lightgray_border2: #DADADA;

/* old
$dark-text: #1c1c1c;
 */

$dark-text: #1A2430;

$marigold: #F3B300;
$emeraldgreen: #349A56;

$error_red: #FF4949;
$error_red_hover: #E67774;
$success: #DarkSeaGreen;
$user_msg_blue: #F4F9FF;
$ultra_light_blue: #F8FBFF;
$accent_gold: #FFCA00;
$medium_gray: #B1ABAB;
$user_msg_blue: #F4F9FF;
$limit_txt_error: #878787;

/* SUPER LIGHT BLUE */
$light_action_blue: #F4F9FF;
$light_action_blue_hover: #eef5fd;

$lightgray_bg2: #FAFAFA;
$ultraLight_gray: #FAFAFA;
$ultraLight_blue: #F8FBFF;
$hoverBtn_bg: #F6F6F6;
$lightblue: #EEF3FA;

$hint_yellow: #E7FFF2;
$hint_border_yellow: #B3EBCC;

$helpful_yellow: #E7FFF2;
$helpful_yellow_border: #CAEEDB;

$aiq_pink: #EA92AF;
$aiq_pink-hover: #EA92AF;
$forestGreen: #4EB468;

$lightgray_txt: #B1B1B1;
