.direct_offer, .massCompose_terms {
  height: auto;

  .main_header {
    text-align: center;
    padding: 2rem .5rem;
    border-bottom: 1px solid $lightgray-border;
    position: relative;

    .btn_back {
      position: absolute;
      top: 25px;
      left: 0;

      .icon_back {
        width: 27px;
      }
    }

    .main_title {
      font-size: 2.25rem;
      padding-bottom: .3rem;

      @include media($break695) {
        font-size: 2.75em;
      }
    }

    .main_descrip {
      font-weight: 600;
      font-size: 1.75em;

      @include media($break575) {
        font-size: 1.5em;
        font-weight: 300;
        line-height: 1.25rem;
      }
    }
  }

  .whitebox {
    background-color: white;
    border-radius: 6px;
    border: 1px solid $lightgray-border;
  }

  .campaign_selection_list {
    max-width: 600px;
    padding: 1.5rem .5rem;
    margin: 0 auto;

    @include media($break425) {
      padding: 1.5rem 0;
    }
  }

  .choose_campaign {
    margin: 5rem auto 5rem;
    max-width: 800px;

    @include media($break865) {
      margin: 1rem auto 5rem;
      width: 96%;
    }

    .btn_select {
      position: absolute;
      right: 1rem;
      top: .95rem;
    }

    .campaign_item {
      padding: .75rem 1rem;
      border-radius: 3px;
      margin-bottom: 1rem;
      cursor: pointer;
      position: relative;

      &:hover {
        background-color: $aiq_lightblue_bg;
        color: $aiq_darkblue;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .campaign_img {
      width: 45px;
      height: 45px;
      border-radius: 3px;
      float: left;
      margin-right: 1rem;
    }

    .text_container {
      float: left;
      margin-top: .7rem;
      width: calc(100% - 66px);
    }

    .campaign_title {
      display: inline-block;
      margin-right: .5rem;
      max-width: 100%;
      font-weight: 300;
      font-size: 1rem;

      @include media($break400) {
        font-size: 1.75em;
      }
    }

    .invite_status {
      letter-spacing: .07rem;
      text-transform: uppercase;
      font-weight: 300;
      font-size: .68rem;
      display: inline-block;
    }
  }

  .compose_terms {
    margin: 5rem auto 5rem;
    max-width: 800px;

    .tools_header {
      display: none;
    }

    @include media($break865) {
      margin: 1rem auto 5rem;
      width: 96%;
    }

    .main_header {
      border-bottom: 1px solid $lightgray-border;
    }

    .main_title {

      @include media($break695) {
        font-size: 2.75em;
      }
    }

    .main_descrip {

      @include media($break695) {
        font-size: 1.5em;
      }
    }

    .offer_container, .introduction {
      padding: 2rem;
    }

    .section_title {
      font-weight: 700;
      font-size: 1.08rem;
    }

    .offer_container {
      margin: 1.35rem 2rem 1.25rem 2rem;
      border: 1px solid $lightgray-border;
      padding: 0 0 1rem;
      border-radius: 8px;

      @include media($break630) {
        margin: 0;
        border: none;
      }
    }

    .content_guidelines, .payment_section, .post_date_section, .brief_section {

      .section_title {
        margin-bottom: .25rem;
      }
    }

    .include_offer_header {

      .checkbox_control {
        margin-bottom: 0;
        padding: 1rem 1.3rem 1.1rem 3.5rem;

        @include media($break575) {
          font-size: 2.35em;
        }
      }

      .control-indicator {
        top: 26px;
        left: 24px;
      }
    }

    .checkbox {}

    .no_offer_alert {
      padding: 0 1.3rem;
      margin-bottom: 1rem;
    }

    .no_offer_text {
      color: red;
    }

    .collab_terms_tool {

      .accounts_section {
        padding-top: 1rem;
      }

      .main_container {
        padding-top: 0;

        @include media($break630) {
          border: none;
          padding: .5rem 0 0;
        }
      }

      .collabHeader_container {
        position: relative;
        padding: .3rem 0 .35rem;
        border-bottom: 1px solid $lightgray-border;

        @include media($break630) {
          padding: .6rem 0 0;
          border-bottom: none;
        }
      }

      .backBtn_charles {
        display: none !important;
      }

      .collabTitle {
        text-align: center;
      }

      .tool_icon {
        vertical-align: -13px;
        margin-right: .5rem;
        width: 40px;
      }

      .terms_container {
        border: none;
        padding: 0 1.3rem;
        border-radius: 0;
        width: 100%;
        float: none;
        margin-right: 0;
      }
    }

    .introduction {
      padding: .75rem 2rem 2rem;
      margin: 0 2rem 1.25rem 2rem;
      border-radius: 8px;
      border: 1px solid $lightgray-border;

      .intro_title {
        margin-bottom: .45em;

        @include media($break575) {
          font-size: 2.35em;
        }
      }

      .form_control {}

      .active_intro_input {
        border: 1px solid $revfluence-blue;
      }
    }

    .actions_container {
      margin: 0 0 2rem;
    }
  }

  .preview_offer {
    max-width: 900px;
    margin: 2rem auto 5rem;
    padding: 0 4rem;

    @include media($break1200) {
      width: 96%;
      margin: 1rem auto 5rem;
    }

    @include media($break930) {
      padding: 0 1rem;
    }

    .offer_item {
      border: 1px solid $lightgray-border;
      border-radius: 8px;
      box-shadow: 0 3px 16px rgba(0,0,0,0.07);
    }

    .offer_header {
      text-align: center;
      padding: 1rem 0;
      border-bottom: 1px solid $lightgray-border;
      margin-bottom: 2rem;

      @include media($break700) {
        padding: 1rem;
      }

      @include media($break520) {
        padding: .75rem 1rem;
      }
    }

    .brand_logo {
      display: block;
      width: 50px;
      height: 50px;
      border-radius: 8px;
      margin: 0 auto .5rem;
    }

    .new_offer_title {
      display: inline-block;
      vertical-align: 14px;
      padding: 0 1rem;

      @include media($break575) {
        font-size: 2.5em;
      }
    }

    .main_offer_content {
      padding: 0 2rem 2rem;
    }

    .campaign_img_container {
      width: 32%;
      float: left;
      margin-right: 3%;

      @include media($break525) {
        width: 100%;
        margin-right: 0;
        float: none;
        margin-bottom: 2rem;
      }
    }

    .campaign_img {
      display: block;
      max-width: 100%;
    }

    .offer_text_container {
      float: left;
      width: 65%;

      @include media($break525) {
        width: 100%;
        float: none;
      }
    }

    .intro_msg {
      margin-bottom: 2rem;

      a {
        color: $revfluence-blue;
        text-decoration: none;

        &:hover {
          color: $revfluence-blue-hover;
        }
      }
    }

    .intro_msg_p_freeform {
      white-space: pre-line;
    }

    .actions_container {
      text-align: center;
      padding: 2rem 0;

      .edit_btn, .send_btn {
        width: 190px;
      }

      .send_btn {
        margin-right: 1rem;
      }

      .btn {

        @include media($break455) {
          display: block;
          width: 100%;
          margin: 0 0 1rem 0;
        }
      }
    }
  }

  .offer_sent {
    max-width: 550px;
    margin: 2rem auto;
    padding: 3rem;
    text-align: center;

    @include media($break835) {
      width: 650px;
    }

    @include media($break700) {
      width: 95%;
    }

    @include media($break350) {
      padding: 2rem 1rem;
    }

    .avatar_container {
      width: 76px;
      margin: 0 auto;
      position: relative;
      padding-bottom: .5rem;
    }

    .complete_indicator {
      width: 25px;
      height: 25px;
      position: absolute;
      right: 0;
      top: 0;
    }

    .user_avatar {
      width: 75px;
      height: 75px;
      border: 2px solid white;
      box-shadow: 0 1px 10px rgba(0,0,0,0.1);
      border-radius: 100px;
    }

    strong {
      font-weight: 800;
    }

    .main_title {
      text-align: center;
      padding-bottom: .5rem;

      @include media($break340) {
        font-size: 3em;
      }
    }

    .main_descrip {
      text-align: center;
      margin-bottom: 1.5rem;
    }

    .back_btn {
      width: 200px;
      margin: 0 auto;
      display: block;
    }
  }

  .cant_send_offers {
    width: 800px;
    margin: 5rem auto 0;
    text-align: center;
    padding: 2rem 3rem;

    @include media($break925) {
      width: 90%;
    }

    @include media($break475) {
      padding: 2rem;
    }

    .main_title {
      margin-bottom: .5rem;
    }

    .main_descrip {
      margin-bottom: 2rem;
      display: block;
    }

    strong {
      font-weight: 600;
    }
  }
}

.offer_summary {
  border: 1px solid $lightgray-border;
  border-radius: 8px;
  margin-bottom: 1rem;

  .summary_header {
    border-bottom: 1px solid $lightgray-border;
    text-align: center;
    padding: .25rem;
  }

  .offer_summary_title {}

  .offer_list {
    padding: 1rem;
  }

  .offer_list_item {}

  .network_icon {
    display: inline-block;
    margin-right: .35rem;
  }

  .offer_details_text {
    font-weight: 800;
    display: inline-block;
    vertical-align: 3px;
    width: calc(100% - 27px);

    @include media($break700) {
      vertical-align: top;
    }
  }
}

.massCompose_terms {
  margin: 4rem auto 5rem !important;
  width: 100%;

  .main_title {
    font-size: 2.25rem;
    font-weight: 700
  }

  .collabHeader_container {
    display: none;
  }

  .usage_rights_container {
    border-bottom: none;
  }

  .btn_back {

    @include media($break600) {
      display: none;
    }
  }
}

.massChoose_campaign {

  @include media($break865) {
    margin: 3.5rem auto 5rem !important;
  }

  .btn_back {

    @include media($break425) {
      display: none;
    }
  }
}

.massReview {
  margin: 5rem auto 5rem !important;
}
