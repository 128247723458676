.trends_report {
  min-width: 1000px;

  @include media($break630) {
    background-color: white;
  }

  .trends_wrap {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 3rem;
  }

  .trends_header {
    padding-bottom: 2rem;
    text-align: center;

    .intro_title {
      padding-bottom: 1.25rem;
      font-size: 2.5rem;
    }

    .descripWrap {}

    .intro_descrip {
      font-size: 1.15rem;
      display: inline-block;
      vertical-align: -2px;
      margin-right: .5rem;
    }

    .industryDropdown {
      display: inline-block;
      width: 165px;

      .icon_dropdown {
        width: 11px;
      }

      .dropdown_btn2 {
        margin-bottom: 0;
        background-image: none;
      }

      .dropdown_list {
        top: 37px;
        right: -7px;
      }
    }
  }

  .trends_nav {
    margin: 0 auto 1rem;
    max-width: 650px;
    border-bottom: 1px solid $lightgray-border;
    text-align: center;

    .simpleTabs_link {
      display: inline-block;
      padding: .6rem .75rem;
    }
  }

  .trends_body {
    margin: 0 auto;
    max-width: 650px;
    padding-bottom: 5rem;

    .inbox_list_container {
      width: 100%;
      float: none;
      padding-top: 0;

      .inbox_table {

        thead {

          th {
            font-weight: 600;
            color: $dark-text !important;


            &:hover {
              color: $aiq_darkblue !important;
            }
          }

          tr {
            background: none !important;
            border-bottom: none !important;
          }
        }

        tbody {

          .increase, .decrease, .newItem {
            font-weight: 600;
          }

          .rising, .falling {
            padding-left: 0 !important;
            transform: none !important;
            width: 15px !important;
            margin-right: 4px;
          }

          .increase {

            div {
              color: #50C878;
            }
          }

          .decrease {

            div {
              color: #FD3553;
            }
          }

          .search_btn {
            font-size: .8rem;
            font-weight: 400;
            cursor: pointer;
            visibility:: hidden;
          }

          .search_col {
            text-align: right;
          }

          tr {
            border-bottom: none !important;

            &:nth-child(even) {
              background: #F6F8FB !important;
            }

            &:hover {
              background: #EBEEF7 !important;

              .search_btn {
                visibility:: visible;
              }
            }
          }
        }
      }
    }

    .inbox_table_container {
      height: auto;
    }

    .inbox_table {
      width: 100%;
      display: table;
    }
  }
}
