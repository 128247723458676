.crop_avatar_modal {

  .modal-dialog {
    padding: 2rem;
    max-width: 500px !important;
  }

  .main_title {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .img_container {
    max-width: 300px;
    margin: 0 auto 1.75rem;
  }

  .profile_img {
    display: block;
    max-width: 100%;
  }

  .slider_container {
    max-width: 300px;
    margin: 0 auto 1.5rem;
  }

  .actions_container {
    text-align: center;
  }

  .save_btn {
    display: inline;
    margin-right: .5rem;
  }

  .cancel_btn {
    display: inline;
  }
}
