.my_campaigns {
	height: 100%;

	::-webkit-scrollbar {
		width:  6px;

		&:horizontal {
			height: 6px;
		}
	}

	::-webkit-scrollbar-thumb {
		background: #D8D8D8;
		border-radius: 100px;
	}

	::-webkit-scrollbar-track {
		background: none;
	}

	.my_campaigns_wrapper {
		height: 100%;
	}

	.my_campaign_main {
		height: 100%;

		@include media($break900) {
			padding-top: 3.75rem;
		}

		.inner_container {
			max-width: 1400px;
			margin: 0 auto;
			overflow: hidden;
			height: 100%;
			padding: 4rem .35rem 0;

			@include media($break900) {
				padding: 0 10px;
				overflow: inherit;
			}
		}

		/* Left column */
		.campaign_nav {
			width: 27%;
			float: left;
			margin-right: 1.5%;
			padding-left: 3px;
			height: 100%;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			padding-right: 3px;
			position: relative;

			@include media($break900) {
				width: 100%;
				margin-right: 0;
				float: none;
				overflow-y: inherit;
				padding-right: 0;
				height: auto;
			}

			.brand_container {}

			.newCampaign_btn {
				background: none;
				font-size: .9rem;
				font-weight: 300;
				padding: .25rem;
				border: none;

				.newBrand_icon {
					vertical-align: -2px;
					width: 20px;
				}

				.newBrand_btn_txt {
					padding-left: 1px;
					vertical-align: 3px;
				}

				&:hover {

					.newBrand_btn_txt {
						color: $revfluence-blue;
					}
				}
			}

			.brand_header {
				padding-bottom: .65rem;
			}

			/* Brand Selection Dropdown */
			.brand_selection_dropdown {
				text-align: left;
				float: left;
				width: calc(100% - 48px);
				padding: 3px 5px 3px 0;
			}

			.brandSelection_dropdown_btn {
				width: 100%;
				display: block;
				margin-bottom: 0;
				background-size: 9px;
				background-position: 94% center;

			}

			.brand_logo {
				width: 30px;
				height: 30px;
				margin: 0 .3rem 0 0;
				display: inline-block;
				border-radius: 100px;
				vertical-align: -5px;
			}

			.btn_txt {
				display: inline-block;
				font-weight: 600;
				vertical-align: -1px;
				font-size: 1rem;
				width: calc(100% - 41px);
			}

			.brand_dropdown_list {
				left: 0;
				top: 50px;
				width: 100%;

				&:before, &:after {
					left: auto;
					right: 14px;
				}

				.brand_logo {
					margin: 0 .5rem 0 0;
				}

				.brand_name_txt {
					vertical-align: -2px;
					display: inline-block;
					width: calc(100% - 38px);
				}
			}

			.brand_dropdown_li {}

			.brand_selection_link {
				padding: .5rem .75rem;

				&:active, &:focus {
					background: $revfluence-blue url('/images/style_guide/icon_checkmark_white.svg') 95% center no-repeat !important;
					background-size: 16px !important;

				}
			}

			.brand_actions_list {
				float: right;
				margin-top: 4px;
			}

			.brand_actions_item {
				float: left;
			}

			.add_campaign_btn {}

			.icon_add_campaign {
				width: 24px;
			}

			.activeBrand_li {

				.brand_selection_link {
					color: white;
					background: $revfluence-blue url('/images/style_guide/icon_checkmark_white.svg') 95% center no-repeat;
					background-size: 16px;
					font-weight: 500;

					.brand_name_txt {
						color: white;
					}

					&:hover, &:active, &:focus {
						background: $revfluence-blue url('/images/style_guide/icon_checkmark_white.svg') 95% center no-repeat !important;
						background-size: 16px !important;
					}
				}
			}

			.newBrand_li {
				text-align: center;
				border-top: 1px solid $lightgray-border;

				.newBrand_link {
					padding: .5rem 0;
					cursor: pointer;
				}
			}

			.add_newBrand_icon {
				margin-right: 2px;
				vertical-align: -6px;
			}

			.add_newBrand_txt {
				font-weight: 600;
			}

			.brand_actions_btn {
				background: none;
				padding: 11px 11px;
				border-radius: 6px;

				&:hover {
					background: $ultraLight_gray;
				}

				&:active {

				}
			}

			.brand_name_txt {
				vertical-align: 11px;
			}

			/* ACTIVE/DEACTIVE CAMPAIGN TABS */
			.campaignTabs_container {
				padding: .2rem 0 .7rem;
			}

			/* Campaigns Nav List */
			.nav_campaigns_list {
				border: 1px solid $lightgray-border;
				border-radius: 5px;

				@include media($break900) {
					display: none;
				}
			}

			.campaigns_item {
				border-bottom: 1px solid $lightgray-border;

				&:first-child {

					.campaigns_btn {
						border-radius: 5px 5px 0 0;
					}
				}

				&:last-child {
					border-bottom: none;

					.campaigns_btn {
						border-radius: 0 0 5px 5px;
					}
				}
			}

			.campaigns_btn {
				display: block;
				width: 100%;
				border-radius: 0;
				padding: 10px;
				background: white;
				background-size: 11px;
				cursor: auto;

				&:hover {
					background: $ultraLight_gray;
					background-size: 11px;
				}

				&:last-child {
					border-bottom: none;
				}
			}

			.campaign_img_container {
				float: left;
				margin-right: 15px;
				width: 60px;
				max-height: 60px;
				overflow: hidden;
			}

			.campaign_img_link {}

			.campaign_img {
				max-width: 100%;
			}

			.campaign_info_container {
				float: left;
				width: calc(100% - 75px);
				text-align: left;
				padding-top: 4px;

				.campaignStatus_txt {
					display: inline-block;
					color: #B1ABAB;
				}
			}

			.campaign_title_container {
				padding-bottom: 3px;
			}

			/* Show when campaign nav item is NOT active */
			.blue_alert_icon {}

			/* Show when campaign nav item is active */
			.white_alert_icon {}

			.alert_icon {
				margin-right: 2px;
				vertical-align: 1px;
			}

			.campaign_title_txt {
				font-weight: 300;
				display: inline-block;
				width: calc(100% - 3px);
			}

			.campaignAlert_item {

				.campaign_title_txt {
					font-weight: 600;
				}

				.campaignStatus_txt {
					font-weight: 300;
				}
			}

			.campaign_status_container {}

			.icon_campaign_status {
				width: 12px;
				vertical-align: 5px;
				margin-right: 2px;
				display: inline-block;
			}

			.icon_ready_campaign {
				width: 12px;
			}

			/* Show when campaign is LIVE */
			.icon_campaign_live {
				width: 9px;
			}

			.icon_campaign_changes {
				width: 13px;
			}

			.icon_waiting_campaign {
				width: 15px;
			}

			.icon_campaign_alert {
				width: 15px;
				vertical-align: 4px;
			}

			.icon_campaign_complete {
				width: 11px;
			}

			/* Show when campaign is UNDER REVIEW */
			.icon_campaign_under_review {}

			.campaign_status_txt {
				font-weight: 300;
				width: calc(100% - 15px);
				display: inline-block;
			}

			.active_campaigns_btn {
				background: #f2f2f2;
				background-size: 11px;

				&:hover {
					background: $ultraLight_gray;
					background-size: 11px;
				}

				.campaign_title_txt, .campaignStatus_txt {
					color: $revfluence-blue !important;
				}
			}
		}


		/* Right column */
		.main_campaigns_list_container {
			float: left;
			width: 71.5%;
			height: 100%;
			position: relative;
			-webkit-overflow-scrolling: touch;
			padding-top: 3px;

			@include media($break900) {
				width: 100%;
				float: none;
				overflow-y:inherit;
				height: auto;
			}

			.my_campaigns_list {
				overflow-y: auto;
				height: 100%;
			}

			/* No Campaigns Placeholder */
			.no_campaigns_item {
				text-align: center;
				padding: 1rem;
				border: 1px solid $lightgray-border;

				.noCampaigns_back_btn {
					display: none;

					@include media($break900) {
						display: inline-block;
					}

					@include media($break335) {
						display: block;
						width: 100%;
					}
				}

				.no_campaigns_title {
					font-weight: 600;
					padding-bottom: 1rem;
					font-size: 1.1rem;
				}

				.newCampaign_btn {
					max-width: 200px;
					margin-right: .35rem;

					@include media($break335) {
						max-width: 100%;
						width: 100%;
						display: block;
						margin-bottom: 1rem;
					}
				}

				.newCampaign_btn_icon {
					vertical-align: -2px;
					margin-right: .25rem;
				}

				.newCampaign_btn_txt {
					vertical-align: 3px;
				}
			}

			.my_campaigns_item {
				border: 1px solid $lightgray-border;
				border-radius: 7px;
				margin-bottom: 1.5rem;
				background-color: white;
				position: relative;

				@include media($break900) {
					border: none;
					padding-top: .3rem;
					border-bottom: 1px solid $lightgray-border;
					margin-bottom: 1rem;
				}

				.deactivate_shield {
					display: none;
					position: absolute;
					width: 100%;
					height: 100%;
					background-color: rgba(255,255,255,.5);
					z-index: 3;
				}

				&.deactivated_item {
					/*-webkit-filter: grayscale(100%);
					filter: grayscale(100%);
					opacity: 0.5;*/

					.deactivate_shield {
						display: block;
					}

					.reactivate_btn {
						z-index: 4;

						.icon_reactivate {
							vertical-align: -4px;
							width: 13px;
							margin-right: .15rem;
						}
					}
				}
			}

			.stageBar {
				width: 97.75%;

				@include media($break850) {
					width: 96.75%;
				}

				@include media($break480) {
					width: 90.75%;
				}
			}

			.mainInfo_container {
				padding: 1.25rem;

				@include media($break900) {
					padding: 0;
				}
			}

			.progress_container {
				padding: 0 1rem;

				@include media($break900) {
					padding: 0 .15rem;
				}
			}

			.primary_actions_container {
				border-top: 1px solid $lightgray-border;

				.primary_action_item {
					width: 33.33%;
					float: left;
				}

				.primary_action_btn {
					display: block;
					width: 100%;
					background-color: white;
					padding: .9rem;
					cursor: auto;

					&:hover {
						background-color: #FAFAFA;
					}
				}

				.connect_btn {
					border-radius: 0 0 0 7px;
    			border-right: 1px solid #ECECEC;
				}

				.manage_btn {
					border-right: 1px solid #ECECEC;
					border-radius: 0;
				}

				.analyze_btn {
		    	border-radius: 0 0 7px 0;
				}

				.primary_action_txt {
					padding-right: 5px;
    			font-weight: 600;
				}

				.icon_primary_action {
					width: 9px;
					vertical-align: -3px;
					margin-left: 1px;
				}
			}

			.stageLabel {

				@include media($break765) {
					display: none;
				}
			}

			.stageNumber {

				@include media($break765) {
					margin-top: 9px;
				}
			}

			.active_stageBar_item {

				.stageLabel {

					@include media($break765) {
						display: inline-block;
					}

					@include media($break650) {
						display: none;
					}
				}
			}

			.goalProgress_wrapper {
				padding-bottom: .75rem;
			}

			.row {
				padding: 0 1rem 1rem;
			}

			.currentGoal_amount, .campaign_progressBar, .icon_trophy {
				float: left;
			}

			.progressStats_item {
				float: left;
				margin-right: 4.5%;
				width: 16.39%;

				&:last-child {
					margin-right: 0;
				}
			}

			.icon_trophy {
				margin-top: 2rem;
				margin-left: .65rem;
			}

			.campaign_progressBar {
				margin-top: 2.35rem;
			}

			.currentGoal_amount {
				margin-top: 1.75rem;
				margin-right: 1rem;
			}

			.icon_target {
				vertical-align: -3px;
			}

			.progress_tooltip {
				left: 25%;
			}

			.currentGoal_txt {
				font-weight: 600;
			}

			.campaign_cta_btn, .progressStats_list {
				float: left;

				@include media($break600) {
					float: none;
				}
			}

			.progressStats_list {
				width: calc(100% - 200px);

				@include media($break600) {
					width: 100%;
					padding-top: 1rem;
				}
			}

			.campaign_cta_btn {
				margin-right: 1.25rem;
				max-width: 180px;

				@include media($break415) {
					margin-bottom: 1.25rem;
				}
			}

			.metric_number {
				line-height: 1.15rem;
				padding-bottom: .35rem;
				display: block;
			}

			.campaign_img_container {
				width: 173px;
				float: left;
				margin-right: 1.35rem;
			}

			.campaign_img_link {
				display: block;
			}

			.campaign_img {
				max-width: 100%;

				&:hover {
					opacity: .9;
				}
			}

			.campaign_details_col {
				float: left;
				width: calc(100% - 232px);

				@include media($break900) {
					width: 100%;
					float: none;
				}
			}

			.campaign_title {
				color: $dark-text;
				padding-bottom: .25rem;
				font-size: 1.45rem;
				line-height: 1.3em;
			}

			.campaign_title_link {
				color: $dark-text;
				text-decoration: none;
				position: relative;
				z-index: 2;

				&:hover {
					color: $revfluence-blue;
				}
			}

			.status_container {
				padding-bottom: .6rem;
			}

			.status_icon {
				display: inline-block;
				width: 15px;
				height: 15px;
				margin-right: 3px;
				vertical-align: -2px;
			}

				.needs_icon {}

			.status_txt {
				display: inline;
			}

			.campaign_stats_list {
				padding-bottom: 1.05rem;

				@include media($break900) {
					padding-bottom: 1rem;
				}
			}

			.campaign_stats_item {
				float: left;
				width: 130px;

				@include media($break995) {
					width: 33.33%;
				}

				@include media($break900) {
					width: 130px;
				}

				@include media($break410) {
					width: 33.33%;
				}
			}

			.campaign_stat_number {
				font-size: 1.4rem;
				display: block;
				padding-bottom: .4rem;
				font-weight: 300;

				@include media($break320) {
					font-size: 1.2rem;
				}
			}

			.campaign_stat_label {
				font-size: .75rem;
				text-transform: uppercase;
				color: $dark-text;
				display: block;
				letter-spacing: .08rem;
				font-weight: 300;
				line-height: 1.1rem;
			}

			.campaign_actions_list {

				@include media($break900) {
					padding-bottom: 1rem;
				}
			}

			.campaign_actions_item {
				float: left;
				padding: .25rem;

				@include media($break360) {
					width: 24%;
					margin-right: 1%;
				}
			}

			.campaign_control_icon {

				@include media($break900) {
					display: none;
				}
			}

			.campaign_control_btn {
				border: none;
			}

			.icon_edit {
				width: 14px;
			}

			.icon_publish {
				width: 16px;
				vertical-align: -2px;
				margin-right: .15rem;
				height: 16px;
			}

			.icon_unpublish {
				width: 19px;
				vertical-align: -4px;
			}

			.icon_deactivate {
				width: 12px;
				vertical-align: -1px;
			}

			.icon_duplicate {
				width: 16px;
				vertical-align: -3px;
				margin-right: 2px;
			}

			.icon_share {
				width: 17px;
				vertical-align: -3px;
				margin-right: 2px;
			}

			.campaign_control_btn {
				position: relative;
				padding: 6px 11px;
				color: $dark-text;

				@include media($break360) {
					font-size: .8rem;
					display: block;
					width: 100%;
				}

				@include media($break335) {
					font-size: .7rem;
				}
			}

			.share_btn {}

			.campaign_control_txt {
				font-weight: 400;
			}

			.campaign_notifications_container {

				@include media($break900) {
					padding-bottom: 1.15rem;
				}
			}

			.campaign_notifications_list {
				padding: 0 1.25rem 1.25rem;

				@include media($break900) {
					padding: 0;
				}
			}

			.campaign_notifications_item {
				padding: .6rem 1rem .8rem .6rem;
				margin-bottom: .75rem;
				position: relative;

				&:last-child {
					margin-bottom: 0;
				}
			}

			.campaign_notification_icon {}

			.remove_notification_btn {
				position: absolute;
				top: .25rem;
				right: .25rem;
				background: none;
				padding: 5px;
			}

			.remove_notification_icon {
				width: 12px;
			}

			.revfluence_avatar {
				width: 35px;
				border-radius: 100px;
			}

			.revfluence_team_container {
				position: relative;
			}

			.revfluence_team_container {
				float: left;
				margin-right: 13px;
				position: relative;
			}

			.revfluence_team_avatar {
				border-radius: 100px;
				width: 35px;
			}

			.revLogo_container {
				background-color: white;
				border-radius: 100px;
				position: absolute;
				bottom: -7px;
				right: -6px;
				height: 22px;
				width: 22px;
			}

			.revLogo {
				width: 14px;
				display: block;
				margin: 4px auto 0;
			}

			.notifications_txt_container {
				float: left;
				width: calc(100% - 60px);
			}

			.notification_txt {
				font-weight: 600;
				margin-bottom: 0;

				.notification_link {
					color: $revfluence-blue;
					text-decoration: none;
					cursor: pointer;
				}
			}

		.live_icon {
			background: url('/images/style_guide/icon_campaign_live.svg') center no-repeat;
			background-size: 100%;
			width: 9px;
			height: 9px;
			vertical-align: 1px;
		}

		.ready_icon {
			background: url('/images/campaign/campaign_ready_indicator.svg') center no-repeat;
			background-size: 13px;
		}

		.waiting_icon {
			background: url('/images/campaign/campaign_waiting_indicator.svg') center no-repeat;
			background-size: 15px;
		}

		.needs_icon {
			background: url('/images/style_guide/icon_alert_outline_color.svg') center no-repeat;
			background-size: 15px;
		}

		.changes_icon {
			background: url('/images/campaign/campaign_changes_indicator.svg') center no-repeat;
			background-size: 15px;
		}

		.completed_icon {
			background: url('/images/style_guide/icon_checkmark_circle_color.svg') center no-repeat;
			background-size: 15px;
		}

		.secondary_actions_container {
			background: white;
			padding: 14px 20px;
		}

		.secondary_actions_list {}

		.secondary_actions_item {
			display: inline-block;
			margin-right: 15px;

			&:last-child {
				margin-right: 0;
			}
		}

		.secondary_actions_link {
			text-decoration: none;
			color: #8C8C8C;
			font-size: .9em;

			&:hover {
				color: $revfluence-blue;
			}
		}

		/* Getting rid of desktop elements for mobile */
		.campaign_img, .campaign_title, .status_container {

			@include media($break900) {
				display: none;
			}
		}

		/* Mobile Campaign Header */
		.mobile_campaign_header {
			display: none;
			padding-bottom: .9rem;
			border-bottom: 1px solid $lightgray-border;
			margin-bottom: .8rem;

			@include media($break900) {
				display: block;
			}

			.back_btn {
				background: none;
				padding: 16px 15px 16px 6px;
				float: left;
			}

			.back_icon {}

			.mobileCampaign_avatar_container {
				float: left;
				position: relative;
				padding-right: 1rem;
			}

			.brand_logo {
				position: absolute;
				width: 32px;
				left: -10px;
				top: -9px;
				border-radius: 100px;
				border: 1px solid $lightgray-border;
			}

			.mobile_campaign_img {
				width: 55px;
			}

			.mobileCampaign_txt_container {
				float: left;
				padding-top: 2px;
				width: calc(100% - 107px);
			}

			.mobileCampaign_title {
				font-size: 1.25rem;
				font-weight: 600;
				line-height: 1.8rem;
			}

			.mobileCampaign_status_container {}

			.status_icon {}

			.status_txt {
				display: inline;
			}
		}

		.icon_chevron {
			vertical-align: -1px;
			margin-left: .3rem;
		}
	}

	.no_campaigns_container {
		text-align: center;
		padding: 1rem 2rem;
		margin: 0 auto 1rem;
		border-radius: 6px;
		background-color: #F7F7F7;

		@include media($break715) {
			width: 95%;
		}

		.main_title {
			font-weight: 600;
		}
	}
}

}
