.select_report_modal {

  .main_title {}

  .main_body {

    .viewReport_btn {
      display: none;
    }

    .report_list {
      max-height: 350px;
      overflow-y: auto;
    }

    .report_item {
      padding: .75rem 2rem;

      &:hover {
        background-color: $ultraLight_gray;

        .viewReport_btn {
          display: block;
        }
      }
    }

    .report_name {
      font-weight: 600;
    }

    .col1, .col2, .col3 {
      float: left;
    }

    .col1 {
      width: 34%;
      padding-right: 1rem;
    }

    .col2 {
      width: 41%;
      padding-right: 1rem;
    }

    .col3 {
      position: relative;
      width: 25%;
    }

    .viewReport_btn {
      position: absolute;
      top: -6px;
    }

    .icon_chevron {
      padding-left: 5px;
      vertical-align: -1px;
    }


  }
}
