.creator_profile {

  .update_avatar_modal {

    .modal-dialog {
      padding: 2rem;
      max-width: 900px;

      @include media($break535) {
        padding: 2rem 1rem;
      }
    }

    .main_title {

      @include media($break535) {
        font-size: 1.25rem;
        font-weight: 500;
      }
    }

    .update_avatar_header {
      padding-bottom: 1rem;
      border-bottom: 1px solid $lightgray-border;
    }

    .avatar_container {
      width: 100px;
      float: left;
      margin-right: 1rem;

      @include media($break535) {
        width: 60px;
      }
    }

    .avatar_img {
      border-radius: 100px;
      max-width: 100%;
    }

    .creator_content_item {

      @include media($break600) {
        width: 47% !important;
      }

      @include media($break450) {
        width: 100% !important;
      }
    }

    .txt_container {
      width: calc(100% - 130px);
      float: left;
      padding-top: .75rem;

      @include media($break535) {
        padding-top: 0;
        width: calc(100% - 76px);
      }
    }

    .filters_btn {
      border-radius: 5px;
      padding: 6px 10px;
      color: $dark-text;

      .btn_txt {
        margin-right: 2px;
      }

      .dropdown_indicator {
        width: 16px;
      }
    }

    .main_title {}

    .change_crop_btn {
      padding: 0;
      color: $dark-text;

      &:hover {
        color: $revfluence-blue;
      }
    }

    .load_more_btn {
      width: 100%;
    }

    .nav_link {
      border-bottom: none !important;

      @include media($break535) {
        font-size: .85rem;
        padding: .74rem .5rem .94rem;
      }
    }

    .active_link {
      border-bottom: 1px solid $revfluence-blue !important;
    }

    .tab_nav {
      margin-bottom: .75rem;
    }

    .uploadPhoto_form {
      padding: 0 !important;
    }

    .uploadPhoto_btn {
      padding: .74rem 1rem .94rem;

      &:hover, &:active {
        background-color: #FAFAFA;
      }
    }

    .content_searchFilters_container {
      padding-bottom: .65rem;

      .search_input, .contentFilters_container, .doubleArrow_select_container {
        float: left;
      }

      .search_input, .contentFilters_container {
        margin-right: .5rem;
      }

      .search_input {
        width: calc(100% - 262px);
        background: url('/images/style_guide/icon_search_black.svg') 5px center no-repeat;
        padding: .4em .7em .4em 1.75em;
        background-size: 16px;
        float: left;

        @include media($break535) {
          width: 100%;
          float: none;
          margin-right: 0;
          margin-bottom: .5rem;
        }
      }
    }

    .btn_txt, .select_btn_txt, .search_input, .change_crop_btn {
      font-size: .85rem;
    }
  }
}
