.brand_signin {
	background-color: #fbfbfb;
	@include media($break560) {
		background-color: white;
	}

	.revfluence_logo {
		background: url('/images/themes/aspireiq/aiq_logo.png') center no-repeat;
		width: 150px;
		height: 40px;
		display: block;
		margin: 1rem auto 0;
		background-size: 100%;

		@include media($break560) {
			margin: 20px auto 0;
		}
	}

	.loginmodalwindow {
		background-color: white;
		padding: 50px;
		width: 510px;
		margin: 2.5em auto 5em;
		@include media($break560) {
			width: 95%;
			margin: 0 auto 5em;
			padding: 30px 50px 50px 50px;
		}
		@include media($break400) {
			padding: 30px 25px 50px;
		}

		h1 {
			color: $aiq_black;
			font-size: 3.5em;
		}

	}

	.loginform {
		width: 280px;
		@include media($break420) {
			width: 100%;
		}

		.email_input {
			margin-bottom: 1rem;
		}

		.loginLink {
			color: $revfluence-blue;
			font-size: .98em;
			font-weight: 300;
			line-height: 1.4em;

			&:hover {
				color: $revfluence-blue-hover;
			}
		}
	}

	.loginLink.su {
		font-size: 1.4em;
		font-weight: 300;
		color: $revfluence-blue;
		margin: 2em 0 0 0;
		line-height: 1.4em;

		&:hover {
			color: $revfluence-blue-hover;
		}
	}

	.submitbutton {
		background-color: $revfluence-blue;
		font-size: 1.1em;
		border-radius: 50px;
		border: none;

		&:hover {
			background-color: $revfluence-blue-hover;
		}
	}

	.forgotmodal {

		h2 {
			color: $aiq_black;
			font-size: 3.5em;
			margin: 0 0 1em 0;
		}

		.loginLink {
			display: block;
			text-align: center;
			color: $revfluence-blue;
			font-weight: 300;
			font-size: 1rem;
			padding: 1rem 0;
			margin: 0;

			&:hover {
				color: $revfluence-blue-hover;
			}
		}

		.msgReceived, .msgFailed {
			font-size: 1rem;
			line-height: 1.45em;
			margin-top: 1rem;
			font-weight: 600;
			text-align: center;
			border-radius: 6px;
			padding: .5em;
			margin: .25rem 0;
			height: auto;
			position: relative;

			p {
				color: white;
				font-weight: 500;
			}
		}

		.msgReceived {
			color: $dark-text;
			background-color: $hint_yellow;
		}

		.msgFailed {
			background-color: $error_red;
		}

		.submitbutton {
			margin: 10px auto 5px;
			padding: 11px 17px;
			line-height: 1.35em;
		}
	}
}
