$lightgray-border: #ECECEC;
$darkblue-border: #3e5d6a;

$dark-text: #1c1c1c;
$gray-text: #9d9d9d;
$lightgray-text: #adadad;
$new_dark_text: #545657;
$new_gray_text: #7b797a;
$new_lightgray_text: #afafaf;

$revfluence-blue: #2582f4;
// Old one $revfluence-blue: #2087d0;
$revfluence-blue-hover: #4998f9;
$revfluence-blue-darker: #1b97ca;
$revfluence-blue-secondary: #288bb3;
$revfluence-blue-secondary-hover: #2c98c5;
$revfluence-lightblue-bg: #79cdf0;
$revfluence-green: #9cb673;
$revfluence-green-hover: #b6ce90;

/* Yellow color used for drop down tooltip and for reacivation systm message */
$maize: #feffce;

/* Green color used on Collab Checklist, it should be used sparingly on very important things only */
$mantis: #6bc271;
$mantis-hover: #7bd682;

/* Used on Send Payment and Collab Details icon in Manage in Actions tab */
$cornflower: #91C3E7;

$darkblue-bg: #334b55;
$darkerblue-bg: #293f48;
$darkerblue-bg-transparent: rgba(41, 63, 72, .9);
$darkerblue-bg-hover: #223841;
$gray-bg: #bfbfbf;
$gray-bg-hover: #d7d7d7;
$lightgray-bg: #f6f6f6;
$lightgray-bg-hover: #f9f9f9;
$mediumgray-bg: #bfbfbf;
$mediumgray-bg-hover: #a6a6a6;
$gray_header_bg: #fffafa;

$paleblue-text: #719fb0;

$label-red: #fa4040;
$label-green: #7dca61;
$label-green-hover: #8cdc6f;
$label-yellow: #fff600;
$label-purple: #a185d9;
$label-brown: #ad7264;
$label-pink: #fa4098;

$system-lightGreen: #f4fff0;
$system-lightBlue: #ebfbff;
$system-lightBlueHover: #b6f0ff;
$system-queensWreath: #eff0f7;

$instagram-color: #416f95;
$instagram-color-hover: #5385ae;
$youtube-color: #f32732;
$youtube-color-hover: #ff444e;
$facebook-color: #3b5997;
$facebook-color-hover: #3b5997;
$twitter-color: #1ab2e8;

$error: #fa4040;
$error-hover: #f96767;
$error-border: #ff5858;

$agreements_purple: #7d829e;
$agreements_purple_subtle: #f3f4f8;

$gray_button_bg: #adadad;
$gray_button_bg_hover: #949494;

$highlight_color: #fffdc3;

$queens_wreath: #7d829e;
