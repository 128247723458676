.start_campaign_banner {
	background: #1c1c1c url('/images/demo_product/dark_avatars_bg.jpg') center no-repeat;
	background-size: 1050px;
	
	.inner_container {
		max-width: 1200px;
		margin: 50px auto 0;
		padding: 3em 0 4em;
	}

	.start_campaign_title {
		color: white;
		line-height: 1.5em;
		font-size: 1.8em;
		font-weight: 600;
		text-align: center;
		margin-bottom: .4em;
	}

	.start_campaign_descrip {
		color: white;
		font-weight: 300;
		line-height: 1.5em;
		font-size: 1.5em;
		text-align: center;
		margin-bottom: 1em;
	}

	.start_campaign_button {
		background-color: #1b7ff7;
		border-radius: 45px;
		padding: .65em;
		text-align: center;
		display: block;
		width: 200px;
		margin: 0 auto;
		color: white;
		text-decoration: none;
		font-size: 1.6em;
		&:hover {
			background-color: #3b92fb;
		}
	}

}