.aspireiq_theme {

  .new_creator_item2 {

    .row2 {

      .favBtn {
        background: none;
        margin-left: 3px;

        &:hover, &:focus {
          background: none;
        }
      }
    }
  }

  .update_featured_content {

    .update_avatar_header {

      .avatar_container {

        .avatar_img {
          border-radius: 100px;
        }
      }
    }

    .creator_content_container {

      .content_searchFilters_container {

        .order_btn {
          border-radius: 100px;
          padding: 9px 10px;
        }

        .filters_dropdown_list {

          .filters_btn {

            &:hover {
              color: $aiq_darkblue;
              background-color: $aiq_lightblue_bg;
            }
          }
        }
      }
    }
  }

  .proposal_sent {

    .btn_primary {
      padding: 10px;

      &:hover {
        background-color: $aiq_darkblue_hover;
        border-radius: 100px;
      }
    }

    .btn_default {

      &:hover, &:focus {
        color: $aiq_darkblue_hover;
        border-radius: 100px;
        background-color: $aiq_lightblue_bg_hover;
      }
    }
  }

  .structured_response {

    .creator_response {

      .account_selection_container {

        .accounts_list {

          .account_name {

            &:hover {
              color: $aiq_darkblue;
            }
          }
        }
      }
    }
  }

  .noBrands {

    .add_accounts_button {
      background: $aiq_darkblue;
      padding: .75em 1.25em .75em 1.25em;

      &:hover, &:focus {
        background: $aiq_darkblue_hover;
      }

      &:active {
        background: $aiq_darkblue;
      }
    }
  }

  .start_subscription_blocker {

    .main_descrip, .more_descrip {

      a {
        color: $aiq_darkblue;

        &:hover, &:focus {
          color: $aiq_darkblue_hover;
          text-decoration: underline;
        }
      }
    }
  }

  .campaignNav_selection_container {

    .campaign_dropdown_list {

      .active_campaign_btn {

        &:hover {
          background-color: $aiq_darkblue;
        }
      }
    }
  }

  .account_stats {

    .filters_link_container {

      .filters_link {
        color: $aiq_darkblue;

        &:hover {
          color: $aiq_lightblue;
        }
      }
    }

    .report_btn {
      color: $aiq_darkblue;
    }
  }

  .account_link {

    &:hover {
      color: $aiq_darkblue;
    }
  }

  .active_tab_content, .apply_filters_container {

    .apply_filters_button {
      box-shadow: 1px 1px 5px rgba(0,0,0,.15);
      border: none;

      &:active {
        background: $aiq_darkblue;
      }
    }
  }

  .accord_filter {

    .accord_filter_btn {

      &:hover {

        .accord_filter_txt {
          color: $aiq_darkblue;
        }
      }
    }
  }

  .gender_container, .audience_gender_container, .invited_type_container {

    .active_action_button {
      background-color: $aiq_darkblue;
      border: 1px solid $aiq_darkblue;
    }
  }

  .imgSearch_wrap {

    .dragDrop_img_wrap {

      .dragDrop_img_btn {
        background-color: $aiq_lightblue_bg;
        border: 1px dashed $aiq_darkblue;
        cursor: pointer;

        &:hover {
          background-color: $aiq_lightblue_bg_hover;
        }
      }
    }

    .recentUsed_section {

      .dropdown_btn {
        background: $aiq_lightblue_bg;

        &:hover, &:focus {
          background-color: $aiq_lightblue_bg_hover;
        }

        &:active {
          background: $aiq_lightblue_bg;
        }
      }

      .suggestedImages_top_shadow {
        width: 183px;
        left: 5px;
      }
    }
  }

  .main_actions_container {

    .like_txt, .offer_txt {
      color: $aiq_darkblue;
    }
  }

  .new_creator_item2 {

    &:hover {
      background: none;
    }
  }

  .creator_list_container {

    .admin_link {
      color: $aiq_darkblue;

      &:hover {
        color: $aiq_lightblue;
      }
    }
  }

  .search_container {
    outline: none;
  }

  .search_input {
    font-weight: 400;
  }

  .browse_creators {

    .savedSearch_dropdown {

      .selected_li {

        a {
          background-color: $aiq_lightblue_bg;
          color: $aiq_darkblue;

          &:hover {
            color: $aiq_darkblue;

            .pageNum_txt {
              color: #909090;
            }
          }

          .icon_remove_savedSearch {
  					display: block;
  				}

  				.icon_remove_white {
  					display: none !important;
  				}
        }
      }

      a {

        &:hover {

          .pageNumb_txt {
            color: #909090;
          }

          .icon_remove_savedSearch {
  					display: block;
  				}

  				.icon_remove_white {
  					display: none;
  				}
        }
      }
    }

    .action_button {
      box-shadow: none;
      background-color: $aiq_lightblue_bg;
      border: none;

      &:hover, &:focus {
        background-color: $aiq_lightblue_bg_hover;
      }

      &:active {
        background-color: $aiq_lightblue_bg;
      }
    }

    .active_action_button {
      background-color: $aiq_darkblue;

      &:hover, &:focus, &:active {
        background-color: $aiq_darkblue;
      }
    }
  }

  .account_link {
    color: $aiq_darkblue;

    &:hover {
      color: $aiq_darkblue_hover;
    }
  }

  .creator_detail_modal {

    .creatorPreview_row1 {

      .col1 {

        .mainActions_container {

          .square_btn {
            height: auto;
          }

          .rightActions {

            .icon_offer {
              vertical-align: -5px;
              width: 18px;
            }
          }
        }
      }
    }
  }

  .direct_offer_header {

    .current_step_indication {

      .step_number {
        background-color: $aiq_darkblue;
        border: 1px solid $aiq_darkblue;
      }
    }
  }
}
