/* REMEMBER TO CREATE 3 LAYOUTS, 1 WITH ONE CHANNEL, 1 WITH 2 CHANNELS, 1 WITH 3

DEFAULT TO 3

*/

.breakBy_channel {

  .breakdownBody {

    .navBtn, .breakdownList, .breakdownItem {
      float: left;
      margin-right: 1%;
    }

    .navBtn, .breakdownItem {
      background: #FFFFFF;
      box-shadow: 1px 1px 8px 0 #E1E4EA;
      border-radius: 3px;
    }

    .leftNav_btn {}

    .rightNav_btn {
      margin-right: 0;
    }

    .navBtn {
      padding: 4.29rem 0;
      width: 3%;
    }

    .iconChevronLeft {}

    .navIcon {}

    .breakdownList {
      width: calc(100% - 109px);

      .breakdownItem {
        float: left;
        width: 32.66%;
        margin-right: 1%;
        padding: 1.35rem 1rem 1.2rem;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          color: $revfluence-blue;

          .channelTitle {
            color: $revfluence-blue;
          }
        }

        &:active {
          color: $dark-text;

          .channelTitle {
            color: $dark-text;
          }
        }

        .breakdownItem {}

        .breakdownItem_header {
          padding-bottom: 1rem;
        }

        .icon_ig {}

        .icon_fb {}

        .icon_yt {}

        .icon_network {
          margin-right: .5rem;
          width: 19px;
          vertical-align: 82%;
        }

        .channelTitle {
          font-weight: 600;
          display: inline-block;
          font-size: 1rem;
          line-height: 1.35rem;
          width: calc(100% - 31px);
        }
      }
    }

    .1channel_list {

      .breakdownItem {

      }
    }

    .2channel_list {

      .breakdownItem {}
    }
  }
}
