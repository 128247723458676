.aspireiq_theme {

  .add_creditCard_container {

    input[type="text"] {

      &:focus {
        border: 1px solid $aspireiq_persian_blue;
      }
    }
  }
}
