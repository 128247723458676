.browse_creators, .campaign_shared {
	height: 100%;

	.filters_container {
		float: left;
		width: 320px;
		background-color: white;
		border-radius: 3px;
		position: relative;
		margin-right: 1.75rem;

		@include media($break1200) {
			width: 318px;
		}
		@include media($break865) {
			display: none;
		}

		.main_title_container {
			padding: 12px 15px 0;

			.main_title {
				font-size: 1.5em;
				line-height: 1.5em;
				color: $dark-text;
				font-weight: 600;
				text-align: center;
			}
		}

		.tabs_container {

			.nav_tabs {
				border-bottom: 1px solid $lightgray-border;

				a {
					margin-right: 0 !important;
					border-radius: 5px 0 0 0;
					text-align: center !important;
				}

				li {
					width: 50%;

					a {
						border: none;
						border-bottom: 3px solid transparent;
						background: none;
						color: $aiq_black;
						border-radius: 0;

						&:hover {
							background-color: $aiq_lightblue_bg;
						}
					}
				}

				.active_tab_icon {
					display: none;
				}

				.active > a {
					border: one;
					border-bottom: 3px solid $aiq_darkblue;
					color: $aiq_darkblue;

					.icon_searchTab {
						display: none;
					}

					.active_tab_icon {
						display: inline-block;
					}
				}

				.first_tab  {
					border-right: none !important;
					border-radius: 6px 0 0 0;
				}

				.second_tab {
					border-radius: 0 6px 0 0;
				}

				.active {
					font-weight: 600;
				}

				.icon_searchTab {
					vertical-align: -4px;
					width: 20px;
					margin-right: .15rem;
				}
			}
		}
	}

	.savedSearch_dropdown {

		.dropdown_btn {
			width: 100%;
			max-width: 100%;
			display: block;
			padding: .7rem 1.85rem .7rem .9rem;
			text-align: left;

			&:focus {
				box-shadow: none;
			}
		}

		.btn_txt {
			font-weight: 600;
		}

		.icon_remove_white {
			display: none;
		}

		.actual_list {
			padding-bottom: .25rem;
		}

		.sample_content {
			width: 25px;
			border-radius: 3px;
			margin-right: .3rem;
			vertical-align: -7px;
		}

		a {
			position: relative;
			padding: .4em 2.1em .4em 1em;

			&:hover {

				.icon_remove_savedSearch {
					display: none;
				}

				.icon_remove_white {
					display: block;
				}

				.pageNumb_txt {
					color: white;
				}
			}
		}

		.icon_saved_search {
			width: 13px;
			margin-right: .5rem;
		}

		.btn_txt {
			display: inline-block;
		}

		.selectSearch_dropdown {
			left: 0;
			width: 322px;
			top: -17px;
			z-index: 5999;

			&:before, &:after {
				left: 292px;
			}

			.removeSavedSearch_btn {
				padding: .25rem;
				border-radius: 3px;
				background: none;
				position: absolute;
				right: .6rem;
				top: 9px;
			}

			.icon_remove_savedSearch {
				width: 10px;
			}
		}

		.noSelection_wrap {
			padding: .5rem .75rem .25rem;
			border-top: 1px solid $lightgray-border;

			.saveNew_search_btn {
				display: block;
				width: 100%;
			}
		}

		.selection_wrap {
			padding: .5rem .75rem .25rem;
			border-top: 1px solid $lightgray-border;

			.updateSearch_btn, .saveNew_search_btn2 {
				display: inline-block;
				font-size: .8rem;
				width: 48%;
			}

			.updateSearch_btn {
				margin-right: .25rem;
			}
		}

		.savedSearch_name_txt {
			max-width: 274px;
			display: inline-block;
		}

		.pageNumb_txt {
			color: #909090;
			font-style: italic;
		}

		.img_thumb {
			display: inline-block;
			width: 25px;
			border-radius: 4px;
			vertical-align: -7px;
			margin-right: .25rem;
		}

		.selected_li {

			a {
				background-color: $aiq_darkblue;
				color: white;

				.icon_remove_savedSearch {
					display: none;
				}

				.icon_remove_white {
					display: block;
				}
			}

			.icon_checkmark {
				width: 13px;
				margin-right: .7rem;
			}

			.savedSearch_name_txt {
				max-width: 274px;
			}

			.has_img {

				.savedSearch_name_txt {
					max-width: 215px !important;
				}
			}
		}
	}

	.has_img {

		.savedSearch_name_txt {
			max-width: 239px !important;
			vertical-align: -7px;
		}
	}

	.nameSearch_dropdown {
		width: 322px;
		padding: 0 .75rem .5rem;

		.nameSearch_header {
			padding-bottom: .25rem;

			.back_btn {
				padding: 6px;
				vertical-align: -2px;
			}

			.icon_back {
				width: 18px;
			}

			.nameSearch_label {
				font-weight: 600;
				font-size: .9rem;
			}
		}

		.nameSearch_body {

			.form_control {
				width: calc(100% - 83px);
				display: inline-block;
				margin-right: .35rem;
			}

			.save_btn {
				display: inline-block;
				vertical-align: 1px;
			}
		}
	}

	.imgSearch_wrap {
		padding: 1.25rem 0;
		border-radius: 0 0 5px 5px;

		.dragDrop_img_wrap {
			padding: 0;
			border-bottom: none;
			position: relative;

			.dragDrop_img_btn {
				background-color: #EEF5FF;
				border: 1px dashed $aiq_darkblue;
				border-radius: 6px;
				display: block;
				padding: .7rem;
				margin-bottom: 1rem;
				transition: all .15s ease-in-out;

				&:hover {
					background-color: #E7EEFA;
				}
			}

			.dropped_dragDrop_btn {
				width: max-content;
				margin: 0 auto;
			}

			.loading_wrap {

				.icon_loading {
					margin: 0 auto;
					padding: 1rem;
					width: 94px;
					display: block;
				}
			}

			.beforeDrop_wrap {

				.icon_content_black {
					width: 28px;
					float: left;
					margin-right: 5%;
					margin-top: 4px;
				}

				.drag_txt_wrap {
					float: left;
					width: calc(100% - 45px);
					font-size: 1rem;
					text-align: left;
				}

				.drag_title_txt {
					font-weight: 600;
					display: block;
					font-size: .85rem;
				}

				.drag_descrip_txt {
					font-weight: 400;
					font-size: .75rem;
				}
			}

			.dropped_wrap {
				text-align: left;

				.dropped_img_thumb {
					border-radius: 6px;
					float: left;
					width: 165px;
					height: 165px;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center;
					border: 1px solid lightgray;
				}

				.file_txt {
					display: block;
					float: left;
					margin-right: .65rem;
					width: calc(100% - 130px);
					font-weight: 600;
					margin-top: 13px;
					font-size: .9rem;
				}

				.truncate {
					display: inline-block;
					max-width: 50px;
					vertical-align: -5px;
				}

				.droppedActions_list {
					float: left;
					margin-top: 11px;
				}

				.droppedActions_item {
					display: inline-block;
				}

				.btn_remove {
					position: absolute;
					top: -16px;
					right: 33px;
					z-index: 2;
					background-color: white;
					border-radius: 100px;
					border: 1px solid $lightgray-border;
					padding: 4px;
					box-shadow: 1px 1px 3px 0 rgba(0,0,0,.1);
				}

				.icon_remove {
					width: 20px;
					padding: 0;
				}
			}
		}

		.hoverDrop_wrap {
			position: absolute;
			left: 0;
			top: 0;
			height: 152px;
			background-color: #E6EFFC;
			border: 1px dashed #2582f4;
			border-radius: 6px;
			display: block;
			width: 100%;
			padding: 2.7rem .9rem .9rem;
			transition: all .15s ease-in-out;
			box-shadow: 1px 1px 11px 0 rgba(0,0,0,0.19);
			z-index: 2;

			.hoverDrop_close_btn {
				position: absolute;
				top: .5rem;
				right: .5rem;
				padding: .25rem;
				background: none;
				border-radius: 6px;
			}

			.hoverDrop_close_icon {
				width: 13px;
			}

			.icon_hoverDrop {
				width: 28px;
				display: block;
				margin: 0 auto .35rem;
			}

			.hoverDrop_title {
				display: block;
				font-weight: 600;
				text-align: center;
			}
		}

		.or_divider {
			border-top: 1px solid $lightgray-border;
			margin-top: 1.25rem;
			margin-bottom: .35rem;

			.or_txt {
				display: block;
				margin: -17px auto 0;
				background-color: white;
				padding: .25rem;
				width: 35px;
				text-align: center;
			}
		}

		.recentUsed_section {
			padding: .25rem 0 1rem;

			.dropdown_btn {
				display: block;
				width: 100%;
				max-width: 100%;
				margin-bottom: 0;
				padding: .5rem;
				background: none;

				&:hover {
					background-color: $lightgray_bg;
				}

				&:active, &:focus {
					background-color: $pressedBtn_gray;
				}
			}

			.icon_recentLiked_images {
				display: inline-block;
				margin-right: .25rem;
				vertical-align: -4px;
			}

			.btn_txt {
				display: inline-block;
				vertical-align: 2px;
			}

			.dropdown_list {
				left: 285px;
				top: -201px;
				width: 417px;
				height: 460px;
				border: none;
				padding: 0;
				box-shadow: 2px 2px 11px 0 rgba(0,0,0,0.17);
				z-index: 5999;

				@include media($break845) {
					top: 40px;
					left: -72px;
				}

				&:after, &:before {
					top: 207px;
				}

				&:after {
					border-width: 15px 15px 15px 0;
					left: -14px;
					border-color: transparent #fff;
					z-index: 1;
				}

				&:before {
					border-width: 15px 15px 15px 0;
					border-color: transparent $lightgray-border;
					left: -15px;
				}
			}

			.nav_tabs {
				width: auto;
				border: none;
				border-bottom: 1px solid $lightgray-border;

				.listName {
					padding: .45rem .75rem;
				}

				a {
					padding: 0;
				}

				.first_tab {
					border-radius: 5px 0 0 0 !important;
				}

				.second_tab {
					border-radius: 0 5px 0 0 !important;
				}

				li {
					position: relative;
					z-index: 6;
				}

				.active {

					.listName {
						font-weight: 600;
					}
				}
			}

			.removeImage_btn {
				position: absolute;
				padding: .5rem;
				right: .5rem;
				top: .5rem;
				display: none;
				z-index: 3;
			}

			.suggestImages_top_shadow {
				position: absolute;
				transition: all .15s ease-in-out;
				opacity: 0;
				cursor: pointer;
				z-index: 1;
				border-radius: 4px 4px 0 0;
				width: 178px;
				left: 6px;
				top: 5px;
			}

			.dropdown_li {
				float: left;
				width: 50%;
				position: relative;

				&.none_placeholder {
					width: 100%;
				}

				.contentItem {
					padding: 5px;
					border-radius: 5px;

					&:hover {

						.removeImage_btn {
							display: block;
							background: none;
						}

						.suggestImages_top_shadow {
							top: 5px;
							opacity: .5;
						}
					}
				}

				.removeIcon {
					width: 13px;
				}

				.img_content {
					max-width: 100%;
					border-radius: 5px;
				}
			}
		}
	}

	.section_container {
		padding: 1rem 0;
	}

	.accord_filter {

		.icon_drop {
			width: 13px;
			margin-right: .15rem;
			transform: rotate(270deg);

			&.open_icon_drop {
				transform: rotate(0);
			}
		}

		.accord_filter_btn {
			background: none;
			padding: .7rem 0 .85rem;
			display: block;
			width: 100%;
			text-align: left;
			border-radius: 4px;

			&:hover {

				.accord_filter_txt {
					color: $aiq_darkblue;
				}
			}
		}

		.section_container {
			padding: .75rem 0 .75rem 3px;

			&:first-child {
				padding: 0 0 .75rem 3px;
			}
		}

		.region_dropdown {

			.dropdown_btn {
				margin-top: 1rem;
			}

			.dropdown_list {
				width: 100%;
				top: 29px;
				left: 0;

				&:before, &:after {
					display: none;
				}
			}
		}

		.dropdown_btn {
			margin-bottom: 0;
			width: 100%;
			text-align: left;
		}

		.gender_container {
			padding: 0 0 1rem;
		}

		.audience_gender_container {
			padding: 0 0 1rem;
		}

		.accord_filter_inside {
			padding-left: 1.25rem;
		}

		.creators_demograph {
			border-bottom: 1px solid $lightgray-border;
		}
	}

	.small_filters_container {
		display: none;
		float: none;
		width: 100%;
		margin: 8px auto 0;
		z-index: 1;

		.filters_inner_container {
			width: 100%;
			margin: 0 auto;
			position: relative;
		}

		.main_title_container {
			margin-bottom: 1em;
			text-align: center;

			.main_title {
				font-size: 2.6em;
				font-weight: 500;
				color: $dark-text;
				line-height: 1.5em;
				padding-top: .25em;
			}
		}
	}

	.filters_inner_container {
		padding-bottom: 100px;
	}

	.active_small_filters_container {

		@include media($break865) {
			display: block;
		}
	}

	.mini_filters_container {
		display: none;
		background-color: white;
		z-index: 2;
		width: 100%;
		position: fixed;
		margin: 0 auto;
		border-bottom: 1px solid $lightgray-border;
		top: 91px;
		left: 0;

		@include media($break865) {
			display: block;
		}

		.toggle_button_container {

			&:hover {
				.filters_container {
					display: block !important;
				}
			}
		}
	}

	.toggle_button {
		border: none;
		background-color: white;
		cursor: pointer;
		padding: 10px 13px 13px;
		outline: none;
		display: block;
		margin: 0 auto;
		width: 100%;

		.toggle_icon {
			background: url('/images/browse_creators/toggle_filters_icon.svg') center no-repeat;
			display: inline-block;
			width: 25px;
			height: 13px;
			margin-right: 6px;
			vertical-align: -2px;
		}

		.toggle_text {
			font-size: 1.2em;
			font-weight: 500;
			display: inline-block;
			color: $dark-text;
		}

		&:hover {
			.toggle_icon {
			}

			.toggle_text {
			}
		}
	}

	.active_toggle_button {
		background-image: -moz-linear-gradient( 90deg, rgb(255,255,255) 0%, rgb(246,246,246) 100%);
		background-image: -webkit-linear-gradient( 90deg, rgb(255,255,255) 0%, rgb(246,246,246) 100%);
		background-image: -ms-linear-gradient( 90deg, rgb(255,255,255) 0%, rgb(246,246,246) 100%);
		display: block;
		box-shadow: 1px 0 10px rgba(0,0,0,.15);
	}

	.network_types_container {

		.section_title {
			padding-bottom: .25rem;
		}

		.title_container {
			margin-bottom: .4rem;
		}

		.large_networks_list {
			border: 1px solid $lightgray-border;
			border-radius: 6px;
		}

		.twitterToggle_filter_btn {
			border-right: none;
		}

		.filterNetwork_icon {
			width: 27.12px;
			height: 25px;
		}

		.blog_btn, .pin_btn, .gplusToggle_filter_btn {
			border-bottom: none;
		}

		.instagram_icon, .youtube_icon, .snapchat_icon, .vine_icon, .twitter_icon, .pinterest_icon, .facebook_icon, .blog_icon, .gplus_icon {
			display: block;
			width: 27.3px;
		}

		.instagram_icon {
			background: url('/images/style_guide/icon_instagram_black.svg') center no-repeat;
		}

		.youtube_icon {
			background: url('/images/style_guide/icon_youtube_black.svg') center no-repeat;
		}

		.snapchat_icon {
			background: url('/images/style_guide/icon_snapchat_black.svg') center no-repeat;
		}

		.vine_icon {
			background: url('/images/style_guide/icon_vine_black.svg') center no-repeat;
		}

		.twitter_icon {
			background: url('/images/style_guide/icon_twitter_black.svg') center no-repeat;
		}

		.pinterest_icon {
			background: url('/images/style_guide/icon_pinterest_black.svg') center no-repeat;
		}

		.facebook_icon {
			background: url('/images/style_guide/icon_facebook_black.svg') center no-repeat;
		}

		.blog_icon {
			background: url('/images/style_guide/icon_blog_black.svg') center no-repeat;
		}

		.gplus_icon {
			background: url('/images/style_guide/icon_gplus_black.svg') center no-repeat;
		}

		.gplusToggle_filter_btn {

			.gplus_icon {
				width: 29px;
			}
		}

		.active_filter_item {

			.instagram_icon {
				background: url('/images/style_guide/icon_instagram_white.svg') center no-repeat;
			}

			.youtube_icon {
				background: url('/images/style_guide/icon_youtube_white.svg') center no-repeat;
			}

			.snapchat_icon {
				background: url('/images/style_guide/icon_snapchat_white.svg') center no-repeat;
			}

			.vine_icon {
				background: url('/images/style_guide/icon_vine_black.svg') center no-repeat;
			}

			.twitter_icon {
				background: url('/images/style_guide/icon_twitter_white.svg') center no-repeat;
			}

			.pinterest_icon {
				background: url('/images/style_guide/icon_pinterest_white.svg') center no-repeat;
			}

			.facebook_icon {
				background: url('/images/style_guide/icon_facebook_white.svg') center no-repeat;
			}

			.blog_icon {
				background: url('/images/style_guide/icon_blog_white.svg') center no-repeat;
			}

			.gplus_icon {
				background: url('/images/style_guide/icon_gplus_white.svg') center no-repeat;
			}
		}
	}

	.actions_item {
		float: left;
		margin-right: 10px;
		&:last-child {
			margin-right: 0;
		}
	}

	.action_button {
		-webkit-appearance: none;
		box-shadow: 0.5px 0.866px 5px 0px rgba(1, 1, 1, 0.06);
		border: 1px solid #c3c3c3;
		border-radius: 4px;
		background-color: white;
		cursor: pointer;
		padding: 8px 11px;
		outline: none;
	}

	.active_action_button {
		background-color: $aiq_darkblue;
		box-shadow: inset 0px 2px 4px 0px rgba(37, 130, 244, 0.07);
		border: 1px solid $aiq_darkblue;
	}

	.action_icon {
		width: 15px;
		height: 15px;
		display: block;
		text-indent: -9999px;
	}

	.search_container {
		padding: 0 0 .25rem 0;
	}

	.search_input {
		background-image: url('/images/icons/outline/search_black.svg');
		background-size: 18px;
		background-position: 12px center;
		background-repeat: no-repeat;
		padding: .65rem 1rem .65rem 2.25rem;
	}

	.followers_filter_container {

		.section_title {
			margin-bottom: 14px;
		}
	}

	.slider_container {
		margin-bottom: 14px;

		.ui-widget-content {
			border: none;
			background: #efefef;
		}

		.ui-slider-handle {
			background: white;
			border: 1px solid #c3c3c3;
			width: 20px;
			height: 20px;
			top: -.6em;
			outline: none;
			border-radius: 100px;
			cursor: move;
			z-index: 1;
		}

		.ui-slider-range {
			background: $aiq_darkblue;
		}

		.ui-slider-horizontal {
			height: .5em;
			width: 94%;
			margin-left: 4px;
		}
	}

	.slider_description {
		font-size: 1.1em;
		color: $dark-text;
		line-height: 1.5em;
	}

	.engagements_filter_container {

		.section_title {
			margin-bottom: 14px;
		}
	}

	.engagements_title {}

	.col1 {
		display: inline-block;
		margin-right: 70px;
	}

	.col2 {
		float: left;
	}

	.standard_fieldset {
		margin-bottom: 10px;
	}

	.age_ranges_container {

		.section_title {
			margin-bottom: 10px;
		}

		.standard_fieldset {
			margin-bottom: 0;
		}

		.checkbox_control {
			margin-bottom: 10px;
		}
	}

	.standard_checkbox {}

	.label_text {
		font-size: 1.1em;
		color: #201e1e;
		line-height: 1.5em;
	}

	.control-indicator {
		width: 1.15rem;
		height: 1.15rem;
		border: none;
		background: $aiq_lightblue_bg;

		&:hover, &:focus {
			background: $aiq_lightblue_bg_hover;
		}

		&:active {
			background: $aiq_lightblue_bg;
		}
	}

	.control {
		font-size: 1.2em;
		color: $dark-text;
		line-height: 1.5em;
		padding-left: 1.65rem;
	}

	.action_text {
		display: inline-block;
		font-size: 1.1em;
		line-height: 1.5em;
		color: $dark-text;
	}

	.creator_type_container {

		.title_container {
			float: left;
			width: 65px;
			margin-right: 15px;
			margin-top: 10px;
		}

		.actions_container {
			width: 190px;
		}

		.actions_item {
			float: left;
			margin-right: 10px;
		}

		.action_button {
			padding: 10px;
		}

		.action_icon {
			display: inline-block;
			margin-right: 5px;
			width: 16px;
			height: 14px;
			vertical-align: -2px;
		}

		.action_text {
			display: inline-block;
			font-size: 1.1em;
			line-height: 1.5em;
			color: $dark-text;
		}

		.revfluence_icon {
			background: url('/images/browse_creators/revfluence_logomark.svg') center no-repeat;
		}

		.all_creators_icon {
			background: url('/images/browse_creators/all_creators_icon.svg') center no-repeat;
		}

		.active_action_button {
			background-color: $aiq_darkblue;
			box-shadow: inset 0px 2px 4px 0px rgba(37, 130, 244, 0.07);
			border: 1px solid $aiq_darkblue;

			.revfluence_icon {
				background: url('/images/browse_creators/revfluence_logomark_white.svg') center no-repeat;
			}

			.all_creators_icon {
				background: url('/images/browse_creators/all_creators_icon_white.svg') center no-repeat;
				background-size: 30px;
			}

			.action_text {
				color: white;
			}
		}
	}

	.invited_type_container {
		overflow: hidden;
		border-bottom: none;
		padding: 1rem 0 0;

		.title_container {
			float: left;
			width: 65px;
			margin-right: 15px;
			margin-top: 10px;
		}

		.actions_container {
			width: 190px;
			float: left;
		}

		.actions_item {
			display: inline-block;
			margin-right: 10px;

			&:last-child {
				margin-right: 0;
			}
		}

		.action_button {
			padding: 10px;
		}

		.action_icon {
			display: inline-block;
			margin-right: 5px;
			width: 16px;
			height: 14px;
			vertical-align: -2px;
		}

		.action_text {
			display: inline-block;
			font-size: 1.1em;
			line-height: 1.5em;
			color: $dark-text;
		}

		.revfluence_icon {
			background: url('/images/browse_creators/revfluence_logomark.svg') center no-repeat;
		}

		.all_creators_icon {
			background: url('/images/browse_creators/all_creators_icon.svg') center no-repeat;
		}

		.active_action_button {
			background-color: $aiq_darkblue;
			box-shadow: inset 0px 2px 4px 0px rgba(37, 130, 244, 0.07);
			border: 1px solid $aiq_darkblue;

			.revfluence_icon {
				background: url('/images/browse_creators/revfluence_logomark_white.svg') center no-repeat;
			}

			.all_creators_icon {
				background: url('/images/browse_creators/all_creators_icon_white.svg') center no-repeat;
				background-size: 30px;
			}

			.action_text {
				color: white;
			}
		}
	}

	.section_header, .accord_filter_txt {
		font-size: .8rem;
		color: $aiq_black;
		line-height: 1.25rem;
		font-weight: 700;
	}

	.section_header {
		padding-bottom: 1rem;
		position: relative;

		.head {
			padding-right: 4rem;
		}

		.clear_filter_button {
			position: absolute;
			right: 0;
			top: -3px;
		}
	}

	.cities_container, .countries_container, .tags_container, .language_container {

		.fake_input {
			border-radius: 6px;
			width: 100%;
			background-size: 15px;
			outline: none;
			font-weight: 400;
			background-color: $aiq_lightblue_bg;
			position: relative;
			padding: 8px 8px 5px 8px;

			&:hover, &:focus {
				background-color: $aiq_lightblue_bg_hover;
			}
		}

		.keywords_item {
			display: inline-block;
			margin-right: 3px;
			margin-bottom: 3px;
			background-color: $aiq_darkblue;
			border-radius: 100px;
			padding: 0 7px 0 12px;

			&:hover, &:focus {
				background-color: $aiq_darkblue_hover;
			}

			&:active {
				background-color: $aiq_darkblue;
			}

			&:last-child {
				margin-right: 0;
			}

			&.hidden {
				display: none;
			}
		}

		.has_input {
			width: 100%;
			background: none;
			padding: 0;

			&:hover, &:active, &:focus {
				background: none;
			}
		}

		.keywords_text {
			color: $aiq_offwhite;
			font-weight: 500;
			font-size: .75rem;
			line-height: 16px;
			display: inline-block;
			vertical-align: 6px;
		}

		.remove_keyword_link {
			background: url('/images/icons/outline/remove_white.svg') center no-repeat;
			width: 10px;
			height: 10px;
			display: inline-block;
			padding: 12px 0 12px 19px;;
			background-size: 10px;
			vertical-align: -2px;
		}

		.real_text_input {
			font-size: .85rem;
			color: $aiq_black;
			border: none;
			outline: none;
			font-weight: 400;
			background: none;
			display: inline-block;
			padding: 5px 6px;
			width: 100%;
			vertical-align: 10px;
			height: 29px;
		}
	}

	.tag-item {
		background-color: $aiq_darkblue;

		&:hover {
		  background-color: $aiq_darkblue_hover;
		}
	  }
	}

	.gender_container, .audience_gender_container {

		.actions_container {
			width: 100%;
		}

		.action_icon {
			display: inline-block;
			margin-right: 5px;
			vertical-align: -2px;
		}

		.active_action_button {
			background-color: $aiq_darkblue;
			box-shadow: inset 0px 2px 4px 0px rgba(37, 130, 244, 0.07);
			border: 1px solid $aiq_darkblue;

			.female_icon {
				background: url('/images/browse_creators/female_gender_icon_white.svg') center no-repeat;
			}

			.male_icon {
				background: url('/images/browse_creators/male_gender_icon_white.svg') center no-repeat;
			}

			.action_text {
				color: white;
			}
		}
	}

	.apply_filters_container {
		position: fixed;
		bottom: 1.5rem;
		padding: 0;
		left: 22px;
		bottom: 1rem;
		z-index: 4;

			@include media($break865) {
				left: auto;
				margin-left: -19px;
			}

		.more_filters_link,.less_filters_link {
			color: $aiq_darkblue;
			font-weight: 300;
			display: block;
			text-align: center;
			font-size: 1.3em;
			text-decoration: none;
			padding: 15px 0 2px;
			&:hover {
				color: $aiq_darkblue-hover;
			}
		}

		.apply_filters_button {
			display: block;
			font-weight: 500;
			width: 100%;
			padding: 10px 20px;
			width: 305px;
			border: 3px solid white;

			&:active {
				background-color: $aiq_darkblue;
			}
		}
	}

	.tabbed_content {

		.apply_filters_container {
			left: 34px;
		}

		.apply_filters_button {
			width: 285px;
		}
	}

	.advanced_filter {
		border-bottom: 1px solid $lightgray-border;

		.section_title {
			padding-bottom: .5rem;
		}

		.countries_container {
			margin-bottom: 0;
			margin-top: 1rem;

			.remove_keyword_link {
				vertical-align: -5px;
			}

			.keywords_text {
				vertical-align: 3px;
			}
		}
	}

	.advanced_filter, .text_container {
		font-size: .85rem;
		font-weight: 400;
		line-height: 1.25rem;
	}

	.creator_skillsets_container {
		.checklist_1col_wrap {
			padding-left: 1.25rem;
		}
	}

	$toggle-height: 26px;
	$toggle-padding: 3px;
	$toggle-width: 50px;
	$toggle-circle-size: ($toggle-height - 2 * $toggle-padding);

	.has_sponsored_posts_container {
		.toggle {
			background-color: grey;
			height: $toggle-height;
			padding: $toggle-padding;
			width: 50px;
			border-radius: $toggle-height / 2;
			transition-property: background-color;
			transition-duration: 250ms;
			transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
			transition-delay: 0ms;

			&.checked {
				background-color: #3996E0;

				.circle {
					transform: translateX(($toggle-width - $toggle-padding) / 2) ;
				}
			}

			.circle {
				background: white;
				height: $toggle-circle-size;
				width: $toggle-circle-size;
				border-radius: $toggle-circle-size / 2;
				transition-property: transform;
				transition-duration: 250ms;
				transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
				transition-delay: 0ms;
			}
		}
	}

	.checklist_1col_wrap, .checklist_2col_wrap {
		.col {
			display: inline-block;
			vertical-align: top;
		}

		.checkbox_control {
			margin: 0;
		}

		li {
			margin-bottom: .5rem;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.checklist_2col_wrap {
		.col {
			width: 96px;
		}
	}

	.inline_controls {

		li {
			display: inline-block;
			margin-right: 1.5rem;
		}

		.radio {
			padding: 0 0 0 30px;
		}

		.radio_inline {
			padding-left: 26px;
		}
	}

	.audience_amount {
		padding-top: .75rem;
		position: relative;

		.disabled_overlay {
			backgroud-color: white;
			background-color: rgba(255,255,255,.75);
			opacity: .75;
			position: absolute;
			top: 10px;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 2;
			width: 100%;
		}

		.group_icon {
			vertical-align: -5px;
			margin-right: .15rem;
		}

		.buttons_wrap {
			padding-left: .25rem;
			display: inline-block;
			vertical-align: 1px;
		}

		.define_amount {
			display: inline-block;
		}

		.dropdown_list {
			width: 124px;
			top: 25px;

			&:before, &:after {
				display: none;
			}
		}

		.percentange {

			.dropdown_list {
				width: 96px;
			}
		}
	}

.browse_creators {

	.active_tab_content {
		border: none;
		padding: 1rem 0;

		.apply_filters_container {
			left: 22px;
		}

		.apply_filters_button {
			width: 305px;
			border: 3px solid white;

			&:active {
				background-color: $aiq_darkblue;
			}
		}
	}

	.recentLiked_tabs {

		.active_tab_content {
			height: 423px;
			padding: .6rem 0 .6rem .6rem;
			overflow-y: auto;

		  ::-webkit-scrollbar-thumb {
		      background: #red;
		      border-radius: 100px;
		  }

		  ::-webkit-scrollbar-track {
		      background: none;
		  }

		  // For Internet Explorer
		  body {
		    scrollbar-face-color: #AFAFAF;
		    scrollbar-track-color: none;
		  }
		}

		.actual_list {
			height: 412px !important;
			width: auto !important;
		}
	}
}
