.campaign_selection_container {
  position: relative;
  border-right: none;

  .campaignSelection_dropdownBtn {
    background: none;
    border-radius: 0;
    padding: 6px 10px;
    display: block;
    width: 100%;
    border-right: 1px solid $lightgray-border;
    padding: 6px 18px 6px 7px;
    font-weight: 300;
    text-align: left;

    .campaign_title {
      width: 132px;
    }

    &:hover {
      background-color: #EEEEEE;
      background-color: rgba(238,238,238,.6);
      background-color: $ultraLight_gray;

      .campaign_title {
        color: $aiq_darkblue;
      }
    }
  }

  .campaign_img {
    float: left;
    margin-right: .35rem;
    width: 27px;
    height: 27px;
    border-radius: 100px;
  }

  .selected_indicator {
    display: none;
    width: 13px;
    margin-right: 9px;
    float: left;
    margin-top: 10px;
  }

  .campaign_actions_container {
    width: 22px;
    float: right;
    margin-top: 2px;

    .edit_icon {
      display: block;
      padding: 0;
    }

    .edit_icon_light {
      display: none;
    }
  }

  .campaign_dropdown_list {
    width: 260px;
    top: 39px;
    left: 3px;
    padding: 1em 0 .7em;
    display: none;
    scrollbar-face-color: #AFAFAF;
    scrollbar-track-color: none;

    @include media($break630) {
      background-color: #F8F8F8;
    }

    ::-webkit-scrollbar {
      width:  6px;
    }

    ::-webkit-scrollbar-thumb {
      background: #D8D8D8;
      border-radius: 100px;
    }

    ::-webkit-scrollbar-track {
      background: none;
    }

    @include media($break575) {
      right: -203px;
      top: 41px;
    }

    .campaign_item {
      padding: 0;
    }

    &:after, &:before {
      left: 19px;
    }

    .campaign_btn {
      display: block;
      background: none;
      border-radius: 0;
      width: 100%;
      padding: 6px 12px;
      font-weight: 300;
      text-align: left;

      .campaign_title {
        width: calc(100% - 77px);
        font-size: .82rem;
        float: left;
        margin-top: 4px;
      }

      &:hover {
        color: $dark-text;
      }

      .edit_link {
        padding: 0;
        display: inline-block;

        &:hover {
          background: none;
        }
      }

      .actions_container {
        float: right;
      }
    }

    .active_campaign_btn {
      background-color: $revfluence-blue;
      &:hover {
        background-color: $revfluence-blue;
      }

      .selected_indicator {
        display: block;
      }

      .campaign_title {
        color: white;
      }

      .edit_icon {
        display: none;
      }

      .edit_icon_light {
        display: block;
      }

      .actions_container {
        margin-right: -3px;
      }
    }

    .campaign_title {
      @include media($break575) {
        display: inline-block;
      }
    }
  }

  .campaign_list {
    max-height: 300px;
    overflow: auto;
    scrollbar-face-color: #AFAFAF;
    scrollbar-track-color: none;
    background-color: white;

    ::-webkit-scrollbar {
      width:  6px;
    }

    ::-webkit-scrollbar-thumb {
      background: #D8D8D8;
      border-radius: 100px;
    }

    ::-webkit-scrollbar-track {
      background: none;
    }
  }

  .campaign_item {
    padding: 6px 15px;

    &:hover {
      background-color: $lightgray-border;
    }
  }

  .campaign_button {
    padding: 0;
    border: none;
    background: none;
    height: 27px;
    width: 100%;
    text-align: left;
  }

  .add_campaign_item {
    border-top: 1px solid $lightgray-border;
    display: block;
  }

  .campaign_link {
    padding: 7px 12px 9px;
    background: none;
    border-radius: 0;
    cursor: pointer;

    &:active {
      box-shadow: none;
    }

    &:hover {
      background-color: $lightgray-border;
    }
  }

    .add_campaign_link {
      color: $revfluence-blue;
      padding: 7px 12px 8px;

      &:hover {
        background-color: $lightgray-border;
      }
    }

    .add_campaign_icon {
      width: 25px;
      display: inline-block;
      margin-right: 9px;
    }

    .add_campaign_text {
      display: inline-block;
      vertical-align: 6px;
      color: $revfluence-blue;
      font-weight: 300;
      font-size: 1.5em;
    }
  }

  .campaignBtn_title {
    max-width: 125px;
    font-weight: 600;
    margin-top: -3px;
    float: left;
    color: $dark-text;
    text-align: left;

    @include media($break575) {
      display: none;
    }
  }
