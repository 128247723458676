* {
  box-sizing: border-box;
}

.share_campaign_modal {

  .modal-dialog {
    padding: 1rem 0 0;
    background-color: $aiq_offwhite;

    @include media($break500) {
      top: 16rem;
    }
  }

  .main_header {
    padding-bottom: 2rem;
  }

  .main_title {
    padding-bottom: .5rem;
  }

  .main_descrip {
    text-align: center;
  }

  .shareCamp_form {}

  fieldset {
    padding: 0 1.5rem;
    margin-bottom: 1rem;
  }

  .standard_label {
    font-weight: 700;
  }

  .btnWrap {
    display: flex;
    justify-content: space-around;
  }

  .btn_bigSq {
    border-radius: 6px;
    color: $aiq_black;
    padding: 1rem;

    &:first-child {
      margin-right: 1rem;
    }
  }

  .icon_share {
    width: 25px;
  }

  .icon_collab {
    width: 35px;
  }

  .icon_share, .icon_collab {
    margin-bottom: .67rem;
  }

  .txt_title, .txt_descrip {
    white-space: normal;
    display: block;
  }

  .txt_title {
    font-weight: 700;
    padding-bottom: .5rem;
  }

  .txt_descrip {
    font-size: .8rem;
    letter-spacing: -.01rem;
  }

  .btn_bigSq {
    padding: 1.5rem 1rem;
  }

  .btn_active {
    border-bottom: 3px solid $aiq_darkblue;
    background-color: $aiq_offwhite;
    border-top: 1px solid $aiq_border;
    border-right: 1px solid $aiq_border;
    border-bottom: 3px solid $aiq_darkblue;
    border-left: 1px solid $aiq_border;

    &:hover, &:focus {
      background-color: $aiq_offwhite;
    }
  }

  .labelWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: .65rem;
  }

  .btn_copy {}

  .form_sampleInv {}

  .noMarg {
    background-color: $aiq_darkblue;
    padding: 1rem 1.5rem 1.5rem 1.5rem;
    margin-bottom: 0;

    .standard_label {
      color: white;
      text-align: center;
      display:block;
      padding-bottom: .5rem;
    }
  }

  .copyLink_wrap {
    display: flex;
    justify-content: space-between;
  }

  .share_input {
    border-radius: 100px 0 0 100px;
    background-color: #58A8E8;
    color: $aiq_offwhite;

    &:hover, &:focus {
      background-color: #65B2EF;
    }

    &:active {
      background-color: #58A8E8;
    }
  }

  .btn_copyLink {
    border-radius: 0 100px 100px 0;
  }

  .error {
    display: flex;
    margin-top: 1rem;
    text-align: center;
  }
}
