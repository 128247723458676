.affiliate {
	background-color: #f9f9f9;

	.main_container {
		width: 1200px;
		margin: 12em auto 0;
		background-color: white;
		border: 1px solid $lightgray-border;
		overflow: hidden;
		position: relative;
		padding: 3em 4em;
	}

	.intro_container {
		margin-bottom: 2em;
		border-bottom: 1px solid $lightgray-border;
		padding-bottom: 1.5em;

		.intro_title {
			@include title25;
		}
	}

	.time_period_container {
		position: absolute;
		right: 5em;
		top: 3em;

		.time_period_list {

			li {
				display: inline-block;
			}
		}

		.time_period_button {
			background-color: $lightgray-bg;
			color: $dark-text;
			@include fontsize14;
			padding: .25em;
			border: 1px solid $lightgray-border;
			cursor: pointer;
			@include standard-radius;

			&:hover {
				background-color: white;
			}
		}

		.active {
			background-color: $revfluence-blue;
			color: white;
			border: 1px solid $revfluence-blue;

			&:hover {
				background-color: $revfluence-blue;
			}
		}
	}

	.main_dashboard_content {
		padding-top: 1em;
	}

	.total_rev_from_affiliates_container {
		display: inline-block;
		width: 330px;
		margin-right: 7em;
		margin-bottom: 7em;

		.total_rev_icon {
			display: inline-block;
			margin-right: 2em;
			width: 50px;
			height: 50px;
			vertical-align: top;
			background: url('../images/affiliates/total_rev_icon.svg') center no-repeat;
		}

		.total_rev_text {
			display: inline-block;
		}

		.total_rev_title {
			@include fontsize16;
			color: $dark-text;
			display: block;
		}

		.total_rev {
			@include title40;
			color: $dark-text;
			display: block;
			font-weight: 300;
		}
	}

	.total_sales_from_affiliates_container {
		display: inline-block;
		width: 330px;
		margin-bottom: 7em;

		.total_sales_icon {
			display: inline-block;
			margin-right: 2em;
			width: 50px;
			height: 50px;
			vertical-align: top;
			background: url('../images/affiliates/total_sales_icon.svg') center no-repeat;
		}

		.total_sales_text {
			display: inline-block;
		}

		.total_sales_title {
			@include fontsize16;
			color: $dark-text;
			display: block;
		}

		.total_sales {
			@include title40;
			color: $dark-text;
			display: block;
			font-weight: 300;
		}
	}

	.total_commissions_paid_container {
		display: inline-block;
		width: 330px;
		margin-right: 7em;
		margin-bottom: 0;

		.total_commissions_icon {
			display: inline-block;
			margin-right: 2em;
			width: 50px;
			height: 50px;
			vertical-align: top;
			background: url('../images/affiliates/total_commissions_icon.svg') center no-repeat;
		}

		.total_commissions_text {
			display: inline-block;
		}

		.total_commissions_title {
			@include fontsize16;
			color: $dark-text;
			display: block;
		}

		.total_commissions {
			@include title40;
			color: $dark-text;
			display: block;
			font-weight: 300;
		}
	}

	.number_of_affiliates_container {
		display: inline-block;
		width: 330px;
		margin-bottom: 0;

		.number_of_affiliates_icon {
			display: inline-block;
			margin-right: 2em;
			width: 50px;
			height: 50px;
			vertical-align: top;
			background: url('../images/affiliates/number_of_affiliates_icon.svg') center no-repeat;
		}

		.number_of_affiliates_text {
			display: inline-block;
		}

		.number_of_affiliates_title {
			@include fontsize16;
			color: $dark-text;
			display: block;
		}

		.number_of_affiliates {
			@include title40;
			color: $dark-text;
			display: block;
			font-weight: 300;
		}
	}

	.about_affiliate_program {
		@include fontsize16;
		color: $dark-text;
		margin-bottom: 2em;

	}

	.coupon_codes_container {
		display: inline-block;
		margin-right: 6em;

		.coupon_codes_title {
			@include fontsize16;
			color: $dark-text;
			margin-bottom: 1em;
			@include standard-bold;
		}

		.coupon_codes_list {
			width: 200px;

			.copy_coupon_code {
				@include blue-link;
				position: absolute;
				top: 7px;
				right: 15px;
				display: none;
			}

			li {
				@include fontsize16;
				color: $dark-text;
				padding: .5em;
				border: 1px solid $lightgray-border;
				margin-bottom: .5em;
				position: relative;

				&:hover {
					border: 1px solid $revfluence-blue;

					.copy_coupon_code {
						display: block !important;
					}
				}
			}
		}
	}

	.commission_payments_container {
		display: inline-block;
		vertical-align: top;

		.commission_payments_title {
			@include fontsize16;
			color: $dark-text;
			margin-bottom: 1em;
			@include standard-bold;
		}

		.commission_payments_list {

			li {
				@include fontsize16;
				color: $dark-text;
				margin-bottom: 1em;
			}
		}
	}
}