.creator_profile, .manageSocial_accounts_container {

  /* MIN OFFER */
  .minOffer_container {
    padding-top: 11px;

    .receiveProduct_optIn {}

    .minPrice_form {
      margin-top: 10px;
    }

    .checkbox_control {
      margin: .25rem 0;
    }

    .text_container, .standard_label, .minPrice_input, .notShown_toBrands_hint {
      font-size: .85rem;
    }

    .youtube_warning {
      padding: .25rem 0 .5rem;
      font-weight: 600;
    }

    .row {
      padding-top: .25rem;
    }

    .minPrice_input {
      max-width: 90px;
      margin-bottom: .15rem;
      display: inline-block;
      margin-right: .25rem;
    }

    .saveMinOffer_btn {
      display: inline-block;
      font-size: .79rem;
      vertical-align: 0;
    }

    .text_container {}

    .standard_label {
      font-weight: 400;
    }

    .enteredMinPrice_row {
      position: relative;

      .edit_minOffer_btn {
        line-height: 0.5em;
        padding: 0 3px 5px;
        background: none;
      }

      .enteredMinPrice_txt {
        display: inline-block;
        vertical-align: 7px;
      }

      .edit_icon {
        margin-left: .25rem;
        display: inline-block;
      }

    }


  }

  .accountToggle_wrap {
    background: #F3F3F3;
    padding: .5rem 1rem;
    font-size: .5rem;
    border-radius: 6px;
    margin-top: 1rem;

    .accountToggle_title, .accountToggle_descrip {
      display: block;
      padding-bottom: .5rem;
    }

    .accountToggle_title {
      color: $dark-text;
      font-weight: 600;
    }

    .accountToggle_descrip {}

    .icon_warning {
      width: 17px;
      vertical-align: -4px;
    }

    .warningDescrip {
      font-weight: 600;
      color: $error;
    }
  }

  /* ADD SOCIAL ACCOUNTS CALLOUT */
  .socialAccounts_callout {
    padding: .78rem 1rem .6rem;
    text-align: center;

    @include media($break875) {
      width: 97%;
      margin: 1rem auto 0;
    }

    /* HEADER */
    .addAccounts_header {
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid $lightgray-border;

      strong {
        display: block;
      }
    }

    .callout_wrapper {
      margin-bottom: .58rem;
      padding-bottom: .9rem;
      border-bottom: 1px solid $lightgray-border;
    }

    .addSocial_accounts_section, .addBlogs_section {
      float: left;
      width: 50%;

      @include media($break535) {
        float: none;
        width: 100%;
      }
    }

    .addSocial_accounts_section {
      border-right: 1px solid $lightgray-border;

      @include media($break535) {
        border-right: none;
        padding-bottom: 1rem;
      }

      .missingNetwork_txt {
        font-size: .85rem;
        padding-top: 0;
        text-align: center;
        display: block;
        padding: 0 1rem;
      }
    }

    .addBlogs_section {

      .missingService_txt {
        text-align: center;
        display: block;
        padding: 0 1rem;
      }
    }

    .socialCallout_header {
      padding-bottom: .5rem;
    }

    .addBlog_url_btn, .addAnalytics_btn {
      margin: 0 auto .5rem auto;
      display: block;
      font-weight: 500;
      font-size: .9rem;
    }

    .icon_calloutBlog {
      width: 20px;
      margin-right: 6px;
      vertical-align: -3px;
    }

    .icon_analyticsBlog {
      width: 20px;
      margin-right: 6px;
      border-radius: 3px;
      vertical-align: -3px;
    }

    /* SOCIAL ADD BUTTONS */
    .addAccounts_btn_list {

      .addAccounts_btn {
        background: white;
        border: 1px solid $lightgray-border;
        width: 50px;
        height: 50px;
        padding: 0;
        position: relative;
      }

      > li {
        display: inline-block;
        margin-right: .5rem;
        margin-bottom: .75rem;

        &:last-child {
          margin-right: 0;
        }
      }

      .newNetwork_indicator {
        position: absolute;
        background-color: $revfluence-blue;
        padding: 4px 5px;
        border-radius: 100px;
        color: white;
        text-transform: uppercase;
        left: 50%;
        margin-left: -15px;
        bottom: -6px;
        line-height: 7px;
        font-size: 9px;
      }

      .network_icon {}
      
      .demo_mode {
        width: 28px;
      }

      .instagram_icon {}

      .youtube_icon {}

      .snapchat_icon {}

      .vine_icon {}

      .twitter_icon {}

      .facebook_icon {}

      .blog_icon {}

      .pinterest_icon {}

      .googleplus_icon {}
    }

    /* MANAGE ACCOUNT BUTTONS */
    .manageAccounts_btn {
      background: none;
      color: $revfluence-blue;
      padding: 0;

      .manageAccounts_icon {
        width: 21px;
        margin-right: .25rem;
      }

      .manageAccounts_btn_txt {
        font-size: 1rem;
        font-weight: 500;
        vertical-align: 4px;
      }
    }
  }

  .main_col {
    margin-bottom: 1rem;

    .socialAccounts_callout {
      margin-bottom: 1rem;
    }
  }

  /* MANAGE ACCOUNTS POPUP STUFF */
  .manageSocial_accounts_popup {

    .modal-dialog {
      max-width: 880px;
    }

    .manageSocial_accounts_header {
      padding: 1rem 2rem .5rem;
      text-align: center;

      @include media($break575) {
        padding: 1rem 1rem .5rem;
      }

      .manageSocial_title {
        padding-bottom: .5rem;
        font-size: 1.75rem;
        font-weight: 700;

        @include media($break575) {
          font-size: 1.6rem;
        }
      }

      .noIG_associated {
        margin-top: 1rem;
        padding: .5rem;

        strong {
          font-weight: 700;
        }

        .smallerTxt {
          font-size: .85rem;
          padding-top: .25rem;
        }

        .standard_link {
          color: #9DCAED;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .addedAccount_success_msg {
        font-weight: 500;
      }

      .failedto_add_msg {

        a {
          color: white;
          text-decoration: underline;
        }
      }
    }

    .main_content {
      padding: 0 2rem 2rem;

      @include media($break575) {
        padding: 0 1rem 2rem;
      }

      .addedAccounts_col, .addAccounts_col {
        width: 47%;
        float: left;

        @include media($break575) {
          float: none;
          width: 100%;
        }
      }

      .section_title {
        font-weight: 600;
        font-size: 1.05rem;
        text-align: center;
        padding-bottom: .6rem;
      }

      /* ADDED ACCOUNTS */
      .addedAccounts_col {
        margin-right: 3%;

        .newlyAdded_accounts_container {
          margin-bottom: 1rem;
          border-bottom: 1px solid $lightgray-border;
          padding-bottom: 1.5rem;
        }

        /* MOBILE ONLY: ADD ACCOUNTS BUTTON */
        .mobileOnly_addAccounts {
          display: none;

          @include media($break575) {
            display: block;
            padding-bottom: 1rem;
            border-bottom: 1px solid $lightgray-border;
            margin-bottom: 1rem;
          }

          .network_icon {
            margin-right: 0rem;
          }
        }

        .addedAccounts_item {
          position: relative;
          padding: .7rem;
          border: 1px solid $lightgray-border;
          border-radius: 6px;
          margin-bottom: .6rem;

          &:hover {
            background-color: #fbfbfb;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        .notAddedAccounts_item {
          background-color: lightgray;

          &:hover {
            background-color: lightgray;
          }
        }

        .noAccounts_item {
          border: 1px dashed $lightgray-border;
        }

        .instagram_icon {}

        .youtube_icon {}

        .snapchat_icon {}

        .vine_icon {}

        .twitter_icon {}

        .facebook_icon {}

        .blog_icon {}

        .pinterest_icon {}

        .googleplus_icon {}

        .network_icon {
          margin-right: .25rem;
        }

        .manage_network_icon {
          float: left;
          width: 17px;
          margin-right: 6px;
          margin-top: 4px;
        }

        .demo_mode {
          width: 28px;
        }

        .accountTxt_container {
          float: left;
          width: calc(100% - 40px);
          position: relative;
        }

        .username {
          font-weight: 700;
          display: inline-block;
          max-width: calc(100% - 130px);
          vertical-align: -4px;
        }

        .reachStat_txt {}

        .tooltip_container {
          position: absolute;
          top: .43rem;
          right: .25rem;
        }

        .confirmDelete_tooltip {
          right: -3px;
        }

        .removeAccount_btn {
          background: none;
          padding: 9px;
        }

        .remove_icon {}

        .previouslyAdded_accounts_container {
          margin-bottom: 1rem;
          border-bottom: 1px solid $lightgray-border;
          padding-bottom: 1.5rem;
        }

        .notAdded_accounts_container {
          margin-bottom: 1rem;
          border-bottom: 1px solid $lightgray-border;
          padding-bottom: 1.5rem;

          .not_added_msg {
            margin-bottom: 1rem;
          }

          .not_added_text {
            position: absolute;
            right: .25rem;
          }
        }

        .other_accounts_container {}

      }

      /* ADD ACCOUNTS */
      .addAccounts_col {

        @include media($break575) {
          display: none;
        }

        .addAccounts_list {
          text-align: center;
        }

        .missingNetwork_txt {
          padding-top: .5rem;
          text-align: center;
          display: block;
        }

        .addAccounts_item {
          display: inline-block;
          margin: 0 8px 8px 0;
        }

        .auth_instagram_btn {}

        .oauth_btn {
          background-color: white;
          border: 1px solid $lightgray-border;
          padding: 0;
          border-radius: 100px;
          width: 50px;
          height: 50px;
          display: inline-block;

          &:hover {
            background-color: #fbfbfb;
          }
        }

        .newNetwork_indicator {
          position: absolute;
          background-color: $revfluence-blue;
          padding: 4px 5px;
          border-radius: 100px;
          color: white;
          text-transform: uppercase;
          left: initial;
          margin-left: -4px;
          margin-top: 25px;
          bottom: auto;
          line-height: 7px;
          font-size: 9px;
        }

        .instagram_icon {}

        .youtube_icon {}

        .snapchat_icon {}

        .vine_icon {}

        .twitter_icon {}

        .facebook_icon {}

        .blog_icon {}

        .pinterest_icon {}

        .googleplus_icon {}

        .network_icon {}

        .demo_mode {
          width: 28px;
        }

        .socialNetworks_section {}

        .blog_networks_section {
          border-top: 1px solid $lightgray-border;
          padding-top: 1rem;
          margin-top: 1rem;

          .missingService_txt {
            font-size: .85rem;
            padding-top: .5rem;
            text-align: center;
            display: block;
          }
        }

        .addCreator_section {

          .network_icon {
            margin-right: 3px;
            vertical-align: -4px;
          }

          .standard_label {
            font-weight: 600;
          }

          .finish_btn {
            margin-top: 1.0rem;
          }

          .dropdown_btn {
            width: 100%;
            max-width: 100% !important;
            padding: .4rem 1.75rem .3rem .5rem;
          }

          .yt_icon {
            vertical-align: -2px;
          }

          .dropdown_list {
            top: 36px;
            right: -7px;
            display: block;
          }
        }

        .addBlog_accounts_list {}

        .addBlog_accounts_item {
          margin-bottom: .5rem;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .addBlog_btn, .addGoogle_analytics_btn {
          display: block;
          width: 100%;
          font-weight: 500;
          padding: 8px 12px;
        }

        .addBlog_btn {}

        .addGoogle_analytics_btn {}

        .add_standardBlog_icon {
          width: 22px;
          margin-right: 6px;
          vertical-align: -5px;
        }

        .icon_googleAnalytics {
          margin-right: 6px;
          width: 21px;
          border-radius: 3px;
          vertical-align: -4px;
        }
      }
    }

    .mainActions_container {
      border-top: 1px solid $lightgray-border;
      padding: 1.25rem 0;

      .finish_btn {
        width: 200px;
        display: block;
        margin: 0 auto;
      }
    }
  }
}
