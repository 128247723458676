.edit_affiliate_program {
	background-color: #f9f9f9;

	.main_container {
		width: 1200px;
		margin: 12em auto 0;
		border: 1px solid $lightgray-border;
		background-color: white;
		padding: 4em 5em;
	}

	.program_details_container {
		position: relative;
		display: inline-block;
		margin-right: 6em;
		width: 300px;

		.program_name_container {
			position: relative;

			.edit_link {
				position: absolute;
				left: -2.75em;
				top: 1.5em;
				display: none;
				width: 16px;
				height: 16px;
				text-indent: -9999px;
				background: url('../images/affiliates/edit_icon.svg') center no-repeat;
			}

			.name_of_program {
				@include title35;
				color: $dark-text;
				letter-spacing: 0;
				margin-bottom: .25em;
				word-wrap: break-word;
				line-height: 1.2em;
                outline: none;
                border: none;
                width: 100%;
                &:focus {
                    outline: none;
                    border: none;
                }
			}

			&:hover {
				.edit_link {
					display: block;
				}
			}
		}

		.view_type {
			@include uppercase12;
			color: $dark-text;
			display: block;
			margin-bottom: 2em;
			display: none;
		}

		.pubs_enrolled_container {
			margin-bottom: 4em;

			.pubs_enrolled_icon {
				display: none;
				margin-right: 1em;
				width: 21px;
				height: 21px;
				vertical-align: -.3em;
				background: url('../images/affiliates/pubs_enrolled_icon.svg') center no-repeat;
			}

			.pubs_enrolled {
				@include fontsize16;
				color: $dark-text;
				display: inline-block;
			}
		}

		.rate_container {
			margin-bottom: 3em;

			.choose_flat_rate {
				@include fontsize15;
				color: $lightgray-text;
				display: inline-block;
				cursor: pointer;
				padding: .1em .5em;
				@include standard-radius;
				margin-right: .5em;
			}

			.choose_percentage_rate {
				@include fontsize15;
				color: $lightgray-text;
				display: inline-block;
				cursor: pointer;
				padding: .1em .5em;
				@include standard-radius;
			}

			.choose_flat_rate, .choose_percentage_rate {
				border: 1px solid white;
				&:hover {
					border: 1px solid $gray_button_bg;
				}
			}

			.active {
				color: white;
				background-color: $gray_button_bg;
				border: 1px solid $gray_button_bg;

				&:hover {
					border: 1px solid $gray_button_bg;
				}
			}

			.rate_input {
				margin-top: .5em;
				@include standard-input;
				display: block;
				width: 225px;
			}

			.hint {
				@include fontsize12;
				color: $lightgray-text;
				margin-top: .5em;
			}
		}

		.additional_terms_container {

			.additional_terms_title {
				@include standard-label;
			}

			.additional_terms_textarea {
				@include standard-textarea;
				height: 150px;
				margin-bottom: 2em;
				width: 100%;
			}
		}
	}

	.non_editable {

		.name_of_program {
			display: block;
		}

		.non_editable_rate {
			@include fontsize16;
			color: $dark-text;
			display: inline-block;
			margin-right: .25em;
		}

		.hint {
			display: inline-block;
		}

		.save_button {
			display: none;
		}

		.additional_terms_container {
			margin-bottom: 2em;

			.additional_terms_title {
				@include fontsize16;
				@include standard-bold;
			}

			.additional_terms_text {
				@include fontsize16;
				color: $dark-text;
			}
		}
	}

	.products_container {
		display: inline-block;
		vertical-align: top;
		width: 735px;

		.products_title {
			@include title20;
			color: $dark-text;
			font-weight: 700;
			padding-bottom: .5em;
			margin: 0 0 1em 0;
		}

		.products_list {

			li {
				margin-bottom: 3em;
				margin-right: 5em;
				overflow: hidden;
				display: inline-block;
				width: 214px;
				height: 338px;
			}
		}

		.product_image_container {
			height: 150px;
			overflow: hidden;
			margin-bottom: 1em;
			text-align: center;
		}

		.product_details_container {
			position: relative;
		}

		.product_image {
			max-width: 100%;
			max-height: 100%;
		}

		.product_title {
			@include fontsize14;
			font-weight: 700;
			color: $dark-text;
		}

		.product_price {
			@include fontsize14;
			color: $dark-text;
			display: block;
		}

		.product_sku {
			@include fontsize14;
			color: $dark-text;
			display: block;
			margin-bottom: .5em;
		}

		.override_commission_container {

			label {
				@include standard-label;
				@include fontsize14;
				font-weight: 700;
			}

			input {
				@include standard-input;
				@include fontsize14;
			}
		}
	}

	.save_button {
		display: inline-block;
		text-align: center;
	}
}