/*
<div class="selectStory_wrap">
  <span class="selectStory_title default_body_copy">Select which story is the post made for the brand:</span>
  <ol class="select_storyList clearfix">
    <li class="selectStory_item">
      <div class="active_btn selectStory_btn">
        <div class="visualWrap">
          <img src="/images/style_guide/icon_checkmark_circle_blue_whiteBG.svg" class="icon_selected">
          <img src="/images/style_guide/sample_ig_story.png" class="story_thumb">
        </div>
        <p class="caption_txt default_body_copy">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
      </div>
    </li>
    <li class="selectStory_item">
      <div class="selectStory_btn">
        <div class="visualWrap">
          <img src="/images/style_guide/icon_checkmark_circle_blue_whiteBG.svg" class="icon_selected">
          <img src="/images/style_guide/sample_ig_story.png" class="story_thumb">
        </div>
        <p class="caption_txt default_body_copy">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
      </div>
  </ol>
</div>
*/
.select_storyList {
  height: 511px;
  padding: .5rem;
  overflow: auto;
  white-space: nowrap;

  @include media($break335) {
    height: 405px;
  }

  .selectStory_item {
    display: inline-block;
    margin-right: 1rem;
    width: 235px;
    vertical-align: top;

    @include media($break335) {
      width: 175px;
    }
  }

  .visualWrap {
    position: relative;
  }

  .selectStory_btn {
    padding: .5rem .5rem .75rem;
    width: 100%;
    border-radius: 7px;

    &:hover {
      background-color: #F1F7FF;

      .story_thumb {
        opacity: .95;
      }
    }

  	&:focus, &:active {
	    box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem $revfluence-blue;
      opacity: 1;
      background: none;
    }
  }

  .visualWrap {}

  .story_thumb {
    max-width: 100%;
    border-radius: 7px;
    margin-bottom: .7rem;
  }

  .icon_selected {
    display: none;
    position: absolute;
    top: .5rem;
    right: .5rem;
    width: 20px;
    z-index: 2;
  }

  .active_btn {
    border: 2px solid $revfluence-blue;
    background-color: #F1F7FF;

    .icon_selected {
      display: block;
      opacity: 1;
    }

    &:hover {
      display: block;
    }

    .caption_txt {
      font-weight: 600;
    }
  }

  .caption_txt {
    font-size: .85rem !important;
    overflow: hidden;
    margin-bottom: 0.5rem;
    white-space: normal;
  }
}

/* CHECKLIST STARTER html

<ul class="innerList checkList default_body_copy">
  <li class="checkList_item">Your email - Used to to send you email (never shared with anyone else)</li>
  <li class="checkList_item">(Optional) Your name - Used to identify you to the brand you work with</li>
  <li class="checkList_item">(Optional) Your address - Used to ship products to you (shared to brands you are collaborating with)</li>
  <li class="checkList_item">(Optional) Your phone number - Used to send you SMS updates (never shared to anyone)</li>
  <li class="checkList_item">(Optional) Public information from your social accounts to help brands decide to work with you</li>
  <li class="checkList_item">(Optional) Your PayPal address for us to pay you (never shared)</li>
</ul>

*/

.checkList {
  padding-bottom: 2rem;
  font-size: .85rem;

  .checkList_item {
    padding: 0 .5rem 0 1.25rem;
    background: url('/images/style_guide/icon_checkmark_blue.svg') center left no-repeat;
    background-size: 12px;
    line-height: 1.5rem;
  }
}

/* ACCOUNT TOGGLE LIST

<ul class="accountToggle_list">
  <li class="accountToggle_item">
    <!-- Rounded switch -->
    <label class="toggle">
      <input type="checkbox">
      <span class="slider round"></span>
    </label>
    <div class="toggleTxt_wrap">
      <img src="/images/style_guide/icon_instagram_color.svg" class="icon_ig icon_network"> <span class="new_toggleTxt default_body_copy trucate">megs_legs</span>
    </div>
  </li>
</ul>
*/
.accountToggle_list {
  padding-top: .4rem;

  .accountToggle_item {
    line-height: 1.75rem;

    .truncate {

    }
  }
}

.list_borderBox {

  .borderBox_item {
    margin-bottom: .65rem;
  }

  .btn_borderBox {
    cursor: pointer;
    border: 1px solid $aiq_border;
    border-radius: 5px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    padding: .85rem .35rem;
    background-color: $aiq_offwhite;
    color: $dark-text;
    text-decoration: none;

    &:hover, &:focus {
      background-color: #EFF5F9;
    }

    &:active {
      background-color: $aiq_offwhite;
    }
  }

  .things_title {
    font-weight: 600;
  }
}
