.howto_addTiktok_modal {

  .modal-dialog {
    padding: 1.5rem 1.5rem 2rem;
  }

  .howto_addTiktok_title {
    padding-bottom: 1rem;
    padding-top: 2rem;
    text-align: center;

    @include media($break465) {
      font-size: 1.8rem;
    }
  }

  .howto_addTiktok_list {
    padding-bottom: 1.5rem;
  }

  .howto_addTiktok_item {
    float: left;
    margin-right: 0%;
    width: 33%;
    text-align: center;

    &:last-child {
      margin-right: 0;
    }

    @include media($break465) {
      float: none;
      margin-right: 0;
      width: 100%;
      padding-bottom: 2rem;
    }

    strong {
      font-weight: bold;
    }
  }

  .tiktok_visualContainer {
    height: 90px;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .tiktok_codeVisual {
    width: 160px;
  }

  .tiktok_sendVisual {
    width: 100px;
    margin-top: 30px;
  }

  .tiktok_verifiedVisual {
    width: 100px;
    margin-top: 30px;
  }

  .howto_addTiktok_txt {
    display: block;
    font-weight: 500;
    word-wrap: break-word;
    margin-top: 75px;
  }

  .howto_addTiktok_ok_btn {
    display: block;
    margin: 0 auto;
    width: 150px;

    @include media($break400) {
      width: 100%;
      margin: 0;
    }
  }
}
