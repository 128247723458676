.demo_proposals {
	background-color: #fafafa;

	.main_header {
		background-color: white;
		position: relative;
		z-index: 2;
		box-shadow: 0px 1px 2px 0px rgba(1, 1, 1, 0.09);

		.inner_container {
			max-width: 1300px;
			margin: 0 auto;
			position: relative;
		}

		.back_link {
			display: block;
			width: 40px;
			height: 55px;
			position: absolute;
			top: .5em;
			left: 5px;
			background: url('/images/demo_product/back_arrow.svg') center no-repeat;
			background-size: 11px 35px;
			text-indent: -9999px;
      cursor: pointer;

			@include media($break770) {
				display: none;
			}
		}

		.best_fit_title {
			padding-bottom: 0;
		}

		.main_descrip {
			font-size: 1.95em;
			color: $dark-text;
			line-height: 1.5em;
			font-weight: 200;
			text-align: center;
			padding-bottom: 3.9em;

			@include media($break930) {
				font-size: 1.65em;
				font-weight: 300;
			}
		}

    .total {
    	font-weight: 800;
    }

		.main_button {
			background-color: $revfluence-blue;
			display: block;
			border-radius: 65px;
			box-shadow: 0px 1px 8px 0px rgba(1, 1, 1, 0.35);
			width: 100px;
			height: 100px;
			text-decoration: none;
			position: absolute;
			bottom: -49px;
			left: 50%;
			margin-left: -50px;

			.main_button_icon {
				width: 38px;
				height: 18px;
				margin: 2.5em auto 1em;
				display: block;
				background: url('/images/demo_product/long_right_arrow.svg') center no-repeat;
			}

			.main_button_text {
				color: white;
				text-align: center;
				display: block;
				font-weight: 500;
				font-size: 1.4em;
				line-height: 1.5em;
			}

			&:hover {
				background-color: $revfluence-blue-hover;
			}
		}
	}

	.proposal_list_container {
        padding-bottom: 10em;
        margin-top: 7em;
        background-color: #ffffff;

		.inner_container {
			max-width: 1300px;
			margin: 0 auto;
			@include media($break1180) {
				max-width: 1000px;
			}

			@include media($break925) {
				max-width: 750px;
			}

			@include media($break750) {
				max-width: 502px;
			}

			@include media($break490) {
				max-width: 242px;
			}
		}

		.proposal_list_descrip {
			display: block;
			text-align: center;
			font-size: 1.6em;
			color: $dark-text;
			margin: 1em 0;
			line-height: 1.5em;
			font-weight: 300;

      .proposal_list_count {
        font-weight: 600;
      }
		}

		.proposal_list {}

		.proposal_item {
			background-color: white;
			box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.01);
			border: 1px solid rgb(242, 241, 241);
			text-align: center;
			display: inline-block;
			width: 18.7%;
			margin: 0 .65% 1.5em;

			@include media($break1180) {
				width: 23.7%;
			}

			@include media($break925) {
				width: 32.03%;
			}

			@include media($break750) {
				width: 48.03%;
			}

			@include media($break490) {
				width: 100%;
				margin: 0 0 2em;
				float: none;
			}

			a {
				text-decoration: none;
				transition: all .15s ease-in-out !important;
				display: block;

				&:hover {
					transform: translate(0,-5px) !important;
				}

				&:active {
					transform: translate(0,5px) !important;
				}
			}
		}

		.creator_image_container {
			position: relative;
            max-width: 100%;
            width: 100%;
            padding-top: 100%;
		}

		.proposal_amount_container {
			position: absolute;
			bottom: 0;
			background: url('/images/social_account_browse/proposal_amount_shadow.png') repeat-x;
			background-position: left bottom;
			text-align: left;
			width: 100%;
			padding: 1em 1.5em .5em;
		}

		.proposal_amount {
			color: white;
			font-size: 2.45em;
			width: 100%;
			margin-right: .2em;
			line-height: 1.5em;
			font-family: 'Old Standard TT', serif;
			font-weight: 300;
			font-style: italic;
		}

		.proposal_type {
			color: white;
			font-size: 1.3em;
			font-weight: 300;
			line-height: 1.5em;
		}

		.creator_image {
			max-width: 100%;
			width: 100%;
		}
	  	.onboarding_creator_image{
			max-width:100%;
			position:absolute;
			top:0;
			bottom:0;
			left:0;
			right:0;
		}

		.creator_info_container {
			padding: 2em 0 2.45em;
		}

		.creator_name {
			font-size: 1.9em;
			color: $dark-text;
			font-weight: 300;
			margin-bottom: .45em;
			padding: 0 8%;
			line-height: 1.5em;
			width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
		}

		.network_stats_container {
			padding: 0 8%;
		}

		.network_icon {
			width: 15px;
			height: 15px;
			display: inline-block;
			margin-right: .5em;
			vertical-align: -2.85px;
		}

		.youtube_icon {
			background: url('/images/social_account_browse/youtube_icon.svg') center no-repeat;
		}

		.instagram_icon {
			background: url('/images/social_account_browse/instagram_icon.svg') center no-repeat;
		}

		.stat_text {
			font-size: 1.3em;
			font-weight: 300;
			line-height: 1.5em;
			color: $dark-text;
		}

		.actions_container {
			border-top: 1px solid #f1f1f1;
		}

		.invite_link {
			color: #2582f4;
			font-weight: 400;
			line-height: 1.5em;
			display: block;
			text-align: center;
			text-decoration: none;
			font-size: 1.6em;
			padding: .75em 8%;
			width: 100%;

			&:hover {
				background-color: #fcfdff;
				color: #3b90f9;
			}
		}
	}

	.create_account_container {
		position: relative;
		text-align: center;
		float: left;
		width: 100%;
		margin-top: -29em;
		z-index: 10;
		height: 300px;
		background: url('/images/demo_product/create_account_gradient.png') repeat-x;
		background-position: left center;

		.inner_container {
			max-width: 750px;
			padding: 3em 0;
			margin-top: 20em;

	    .get_started_button {
	        background-color: $revfluence-blue;
	        border-radius: 45px;
	        width: 160px;
	        color: white;
	        display: block;
	        text-decoration: none;
	        text-align: center;
	        padding: .75em 0;
	        font-size: 1.5em;
	        margin-bottom: 2em;
	        margin-top: 1.0em;
	        margin-left: auto;
	        margin-right: auto;
	        &:hover {
	            background-color: $revfluence-blue-hover;
	        }
	    }
		}

		.main_title {
			font-size: 2.25em;
			color: $dark-text;
			font-weight: 300;
			line-height: 1.5em;
			margin-bottom: .5em;

			strong {
				font-weight: 600;
			}
		}

		.create_account_link {
			color: $revfluence-blue;
			display: block;
			font-size: 2em;
			font-weight: 500;
			line-height: 1.5em;
			text-decoration: none;

			&:hover {
				color: $revfluence-blue-hover;
			}
		}
	}
}
