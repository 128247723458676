.newAnalyze_view {

  .core_subnav {
    background-color: white;
    top: 50px;

    @include media($break400) {
      height: auto;
    }

    .newAnalyze_subnav {

      .core_links_list {
        border-right: none !important;

        @include media($break900) {
          display: block !important;
        }
      }

      .core_link {

        @include media($break400) {
          font-size: .8rem;
        }
      }
    }
  }
}
