.generic_creator_list_container {
	background-color: white;
	height: auto;

	.main_container {
		margin: 148px auto 0;
		position: relative;

		@include media($break3000) {
			padding: 0 1rem;
		}

		@include media($break1400) {
			padding: 0 1em;
		}

		@include media($break850) {
			margin: 110px auto 0;
		}
	}

	.filters_container {
		width: 300px !important;
		margin-right: 25px;

		@include media($break350) {
		  	margin: 0 auto;
		}
	}

  .title_container {
		margin-bottom: 2.0em;
    padding-top: .5rem;

		@include media($break1220) {
      margin-left: 1.85%;
    }
  }

	.absHeader {
    position: absolute;
    left: 18px;
    top: 0;
    width: calc(100% - 680px);
		z-index: 1;

		@include media($break1000) {
			position: relative;
			width: auto;
			margin-left: 0;
			left: auto;
		}
  }

	.creatorList_header {

		@include media($break1000) {
			display: none;
		}
	}

  .main_title {
    font-size: 3.65em;
    color: $dark-text;
    line-height: 2.5rem;

		@include media($break1145) {
			font-size: 1.78rem;
			font-weight: 300;
			line-height: 2rem;
		}

    @include media($break500) {
      font-size: 2em;
      font-weight: 500;
    }

    @include media($break285) {
      font-size: 1.6em;
      vertical-align: -0.35em;
      display: inline-block;
    }
  }

  .main_subtitle {
    font-size: 1rem;
    font-weight: 300;
    margin-top: 1.0em;
    margin-left: 0.2em;
    color: $dark-text;
		line-height: 1.25rem;
  }

	.changeList_link {

	}

	.creator_list_container {
    padding: 0;
    margin: auto;
    float: none;
    border: none;
		width: 100%;

		@include media($break1000) {
			padding-top: 0;
		}

		.no_results_container, .search_error_container {
			text-align: center;
			margin: 150px 0;

			@include media($break850) {
				margin: 200px 0;
			}

			@include media($break515) {
				margin: 55px0;
			}

			.main_title {
				font-size: 3em;
				color: $dark-text;
				font-weight: 300;
				margin-bottom: .5em;
			}

			.main_descrip {
				font-size: 1.5em;
				color: $dark-text;
				font-weight: 300;
				line-height: 1.5em;
			}
		}

		.no_results_container {
			margin: 100px auto;
			display: block;
			max-width: 740px;
		}

		.creator_item {
			width: 18.65%;
			float: left;
			background-color: white;
			margin-right: 1.34%;
			margin-bottom: 14px;
			border-radius: 6px;
			border: 1px solid $lightgray-border;
			@include media($break1200) {
				width: 23.37%;
				margin-right: 1.62%;
			}
			@include media($break975) {
				width: 31.71%;
			}
			@include media($break850) {
				width: 31.715%;
			}
			@include media($break600) {
				width: 48.37%;
			}
			@include media($break390) {
				width: 100%;
			}

			.networkIcon {
				width: 15px;
				height: 15px;
			}

			.instagram_icon {}

			.youtube_icon {
				width: 16px;
				vertical-align: 1px;
				padding-bottom: 3px;
			}

			.snapchat_icon {}

			.vine_icon {
				width: 14px;
			}

			.twitter_icon {
				width: 17px;
			}

			.facebook_icon {}

			.blog_icon {
				width: 16px;
			}

			.pinterest_icon {
				width: 14px;
				vertical-align: -2px;
			}

			.gplus_icon {
				width: 22px;
			}
		}

		.creator_avatar_container {
			height: 210px;
			overflow: hidden;
			border-radius: 6px 6px 0 0;

			a {

				.creator_avatar {
					opacity: 1;
				}

				&:hover {
					.creator_avatar {
						opacity: .9;
					}
				}
			}
		}

		.yt_avatar_container {
			.creator_avatar {
				width: 100%;

				@include media($break720) {
					width: 200%;
				}

				@include media($break600) {
					width: 164%;
				}

				@include media($break600) {
					width: 184%;
				}

				@include media($break320) {
					width: 126%;
				}
			}
		}

		.ig_avatar_container {

			.creator_avatar {
				max-width: 100%;
				width: 100%;
			}
		}

		.creator_avatar {
			/* max-width: 100%;
			width: 100%;  */
			border-radius: 3px 3px 0 0;
		}

		.ig_avatar {
			width: 100%;

			@include media($break1245) {}
		}

		.yt_avatar {

		}

		.creator_info_container {
			padding: 20px 0 0 0;
		}

		.username_link {
			color: $dark-text;
			text-decoration: none;
		}

		.creator_username {
			font-size: 2em;
			font-weight: 300;
			color: $dark-text;
			line-height: 1.5em;
			margin-bottom: .5em;
			text-align: center;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			padding: 0 .5em;
		}

		.details_container {
			text-align: center;
			border-bottom: 1px solid #f2f2f2;
			padding-bottom: 0;
		}

		.admin_link {
			display: block;
			margin-top: 1em;
			font-size: 1.25em;
			text-decoration: none;
			color: $revfluence-blue;
			padding-bottom: 1rem;

			&:hover {
				color: $revfluence-blue-hover;
			}
		}

		.engagement_amount {
			font-size: 1.3em;
			color: $dark-text;
			font-weight: 300;
			line-height: 1.5em;
			display: inline-block;
		}

		.follower_amount {
			font-size: 1.3em;
			color: $dark-text;
			font-weight: 300;
			line-height: 1.5em;
			display: inline-block;
		}

		.creator_actions_container {
			text-align: center;
		}

	  	.invite_in_progress{
		  height:15px;
		}

		.btn_icon {
			display: inline-block;
			vertical-align: -3px;
			margin-right: .2rem;
			width: 19px;
		}

		.actions_link {
			display: block;
			padding: 14px;
			text-decoration: none;
			color: $revfluence-blue;
			cursor: pointer;
			float: left;
			width: 50%;
			border-radius: 0;
			transition: all .15s ease-in-out;

			&.invited_link {
				cursor: default;

				&:hover {
					background-color: white;
				}
			}

			&:last-child {
				border-right: none;
			}

			&:hover {
				background-color: $ultra_light_blue;
			}

			&:active, &:focus {
				box-shadow: none;
				background-color: white;
			}

			&.full_width_actions_link {
				width: 100%;
			}
		}

		.like_action_link {
			border-right: 1px solid $lightgray-border;
		}

		.btn_txt {
			font-weight: 300;
		}

		.actionable_btn {
			color: $revfluence-blue;
			cursor: pointer;
		}

		.actions_icon {
			display: inline-block;
			width: 20px;
			height: 20px;
			background: url('/images/browse_creators/like_icon_outline.svg') center no-repeat;
			margin-right: 3px;
			vertical-align: -4px;
		}

		/* .invited_icon {
			background: url('/images/browse_creators/like_icon_solid.svg') center no-repeat;
		} */

		.actions_text {
			display: inline-block;
			font-size: 1.3em;
			font-weight: 300;
			line-height: 1.5em;
		}

		.invited_link {
			.btn_txt {
				color: #9d9d9d;
			}
		}

		.main_loading_section {
			margin: 30px 0;

			.spinner-image {
				margin: 0 auto;
			}
		}
	}

	.inactive_creator_list_container {
		@include media($break850) {
			display: none;
		}
	}
}
