.sent_proposal_onboarding {

  .back_btn {
    position: absolute;
    top: 5rem;
    left: 2rem;
    background: none;
    padding: 0;
    border: none;

    @include media($break600) {
      top: 4.25rem;
      left: 1rem;
    }

    .back_icon {
      width: 33px;
    }
  }

  .new_proposal_sent {
    text-align: center;
    max-width: 550px;
    margin: 6rem auto;

    .proposal_sent_graphic {
      display: block;
      margin: 0 auto .5rem;
    }

    .main_title {
      margin-bottom: .5rem;
    }

    .main_descrip {
      margin-bottom: 1.25rem;
    }

    .next_btn {}
  }

  .setup_creatorAccount_a {
    text-align: center;
    margin-top: 6rem;

    .main_title {

      @include media($break325) {
        font-size: 3em;
      }
    }

    .main_descrip {
      margin-bottom: 2rem;
      font-size: 1.85em;
    }

    .setup_account_form {
      max-width: 430px;
      margin: 0 auto;
    }

    .error {
      margin-top: 1rem;
    }

    .email_collect {}

    .standard_label {
      display: block;
      text-align: left;
    }

    .email_input {}

    .paypal_collect {}

    .password_collect {}

    .next_btn {
      display: block;
      margin: 0 auto 2rem;
    }

    .form_control {
      margin-bottom: .75rem;
    }

    .finish_btn {}

    .disclosure_icon {
      vertical-align: -2px;
      margin-left: .95rem;
    }
  }

  .setup_creatorAccount_b {
    text-align: center;
    max-width: 550px;
    margin: 6rem auto;

    .main_title {

      @include media($break325) {
        font-size: 3em;
      }
    }

    .main_descrip {
      margin-bottom: 1rem;
    }

    .setup_account_form {
      max-width: 350px;
      margin: 0 auto;
    }

    fieldset {
      margin-bottom: .5rem;
    }

    .standard_label {
      text-align: left;
      display: block;
    }

    .email_input {}

    .paypal_input {}

    .password_input {}

    .finish_btn {
      margin-top: .5rem;
    }
  }

  .setup_creatorAccount_complete {
    text-align: center;
    max-width: 550px;
    margin: 6rem auto;

    .account_complete_graphic {
      display: block;
      margin: 0 auto .5rem;
    }

    .main_title {

      @include media($break325) {
        font-size: 3em;
      }
    }

    .main_descrip {
      margin-bottom: 1.25rem;
    }

    .see_brands_btn {}

    .disclosure_icon {
      vertical-align: -2px;
      margin-left: .5rem;
    }
  }

  .new_proposal_sent, .setup_creatorAccount_a, .setup_creatorAccount_b, .setup_creatorAccount_complete {

    @include media($break550) {
      padding: 0 1.25rem;
    }

    @include media($break400) {
      margin: 5rem auto;
    }
  }
}
