/* Sample starter HTML

<section class="toggle_filter_container">
  <h1 class="filter_label">Time Range</h1>
  <ul class="filter_list">
    <li class="filter_item">
      <button class="toggle_filter_button first_button">
        <span class="filterBtn_txt">Day</span>
      </button>
    </li>
    <li class="active_filter_item filter_item">
      <button class="disabled_filterBtn toggle_filter_button middle_button">
        <span class="filterBtn_txt">Week</span>
      </button>
    </li>
    <li class="filter_item">
      <button class="toggle_filter_button middle_button">
        <span class="filterBtn_txt">Month</span>
      </button>
    </li>
    <li class="filter_item">
      <button class="toggle_filter_button last_button">
          <span class="filterBtn_txt">All-time</span>
      </button>
    </li>
  </ul>
</section>

*/

.toggle_filter_container {

  .toggle_filter_button {
    border-top: 1px solid #f2f1f1;
    border-bottom: 1px solid #f2f1f1;
    padding: .75em 1.15em .7em;
    background-color: white;
    outline: none;

    &:hover {
      background-color: #fbfbfb;
    }
  }

  .toggle_filter_button_icon {
    width: 15px;
    height: 15px;
    display: inline-block;
    vertical-align: -1px;
  }

  .filter_list {
		overflow: hidden;
		position: relative;
	}

  .filter_item {
		float: left;
	}

  .filterBtn_txt {
		display: inline-block;
		font-size: 1.3em;
		color: #201e1e;
		font-weight: 300;
	}

  .first_button {
		border-radius: 4px 0 0 4px;
		border-left: 1px solid #f2f1f1;
		border-right: 1px solid #f2f1f1;
	}

	.middle_button {
		border-left: none;
		border-right: 1px solid #f2f1f1;
	}

	.last_button {
		border-radius: 0 4px 4px 0;
		border-right: 1px solid #f2f1f1;
		border-left: none;
	}

  /* Disable filtered button - Use on any unavailable buttons through JS */
  .disabled_toggle_filterBtn {
    background: #E2E2E2;

    .filterBtn_txt {
      color: gray;
    }
  }

  .disabled_filter_item {

    .toggle_filter_button {
      cursor: inherit;
    }

    .toggle_filter_button_icon, .filterBtn_txt {
      opacity: .25;
    }
  }

  .active_filter_item {

    .toggle_filter_button {
			background-color: $revfluence-blue;
      border-top: 1px solid $revfluence-blue;
      border-bottom: 1px solid $revfluence-blue;
		}

    .first_button {
      border-radius: 4px 0 0 4px;
      border-left: 1px solid $revfluence-blue;
      border-right: 1px solid $revfluence-blue;
    }

    .middle_button {
      border-left: 1px solid $revfluence-blue;
      border-right: 1px solid $revfluence-blue;
    }

    .last_button {
      border-radius: 0 4px 4px 0;
      border-right: 1px solid $revfluence-blue;
    }

    .filterBtn_txt {
			color: white;
		}
  }

  .couple_filter_list {

		.first_button {
			border-right: 1px solid $lightgray-border;
		}

		.last_button {
			border-left: none;
		}

		.active_filter_item {
            color: white;

			.first_button {
				border-right: 1px solid $revfluence-blue;
			}
		}
	}
}

/* TOGGLE BUTTON */
.toggle_btn {
	position: relative;
	transition: all .15s ease-in-out;
	line-height: 19px;
}

.toggleHandle {
	width: 15px;
	height: 15px;
	border-radius: 100px;
	background-color: white;
	position: absolute;
	top: 2px;
}

.toggleTxt {
	font-size: .6rem;
	color: white;
	text-transform: uppercase;
	letter-spacing: .08rem;
	font-weight: 600;
}

.activeToggle {
	background-color: $revfluence-blue;
	padding: 0 22px 0 5px;

	.toggleHandle {
		right: 3px;
	}
}

.disabledToggle {
	background-color: #C1C1C1;
	padding: 0 5px 0 22px;

	.toggleHandle {
		left: 3px;
	}
}


/* The Toggle - the box around the slider

<ul class="accountToggle_list">
	<li class="accountToggle_item">
		<!-- Rounded switch -->
		<label class="toggle">
			<input type="checkbox">
			<span class="slider round"></span>
		</label>
		<div class="toggleTxt_wrap">
			<img src="/images/style_guide/icon_instagram_color.svg" class="icon_ig icon_network"> <span class="new_toggleTxt default_body_copy truncate">megs_legsmegs_legsmegs_legsmegs_legsmegs_legsmegs_legsmegs_legsmegs_legsmegs_legsmegs_legsmegs_legsmegs_legs megs_legsmegs_legsmegs_legsmegs_legsmegs_legsmegs_legsmegs_legsmegs_legsmegs_legsmegs_legsmegs_legsmegs_legs</span>
		</div>
	</li>
</ul>

 */
.toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 23px;
}

/* Hide default HTML checkbox */
.toggle input {display:none;}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $revfluence-blue;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.toggleTxt_wrap {
	display: inline-block;
	vertical-align: 7px;
	padding-left: .85rem;
	width: calc(100% - 53px);

	.icon_network {
		width: 16px;
		vertical-align: -3px;
	}

  .demo_mode {
    width: 28px;
  }

	.icon_ig {}

	.icon_yt {}

	.icon_fb {}

	.icon_tw {}

	.new_toggleTxt {
		font-weight: 600;
		font-size: .8rem;
		padding-left: .4rem;
		width: calc(100% - 19px);
		display: inline-block;
		vertical-align: -4px;
	}
}
