.creator_main_nav {
	background-color: white;
	background-color: hsla(255, 0%, 100%, 0.98);
	z-index: 9000;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	border-bottom: 1px solid $lightgray-border;
	height: 50px;

	.inner_container {
		margin: 0 auto;
		position: relative;
	}

	.revfluence_logo {
		float: left;
		margin-top: .45rem;
		margin-left: .5rem;

		@include media($break400) {
			margin-left: 1em;
			margin-top: .8rem;
		}

		.revfluence_logo_link {
			display: block;
			width: 153px;
			height: 34px;
			background: url('/images/themes/aspireiq/aiq_logo.png') center no-repeat;
			background-size: 100%;
			text-indent: -9999px;

			@include media($break400) {
				background: url('/images/themes/aspireiq/logo_a_mark.png') center no-repeat;
				background-size: 100%;
				width: 30px;
				height: 19px;
			}
		}
	}

	.creator_profile_logo {
		display: none;
		width: 150px;
		position: absolute;
		margin-left: -75px;
		left: 50%;
		top: .7rem;
	}

	.help_dropdown_list {
		display: none;
		top: 47px;
		right: -3px;
		width: 250px;

		&:after, &:before {
			left: 212px;
		}

		.help_dropdown_item {
			text-align: left;
			display: block;
			margin-right: 0;
			width: 100%;

			&:last-child {
				margin-bottom: 0;
			}

			.help_dropdown_link {
				padding: .45em 0;
				display: flex;
				align-items: center;
				justify-content: space-around;
				color: $dark-text;
				font-size: 1.5em;
				border: none;
				line-height: 1.7em;
				font-weight: 300;
				text-decoration: none;

				&:hover, &:active, &:focus {
					color: white;
					background-color: $revfluence-blue;
				}
			}

			.icon_watchTut {
				width: 40px
			}

			.icon_userChat {
				width: 21px;
			}

			.iconHint {
				width: 21px;
			}

			.visualWrap {
				flex: .5;
				text-align: center;
			}

			.txtWrap {
				flex: 1;
				font-size: .85rem;
				line-height: 1.25rem;
			}

			.itemTitle {
				font-weight: 700;
				display: block;
			}

			.itemDesc {
				display: block;
			}
		}
	}

	.largeScreen_nav_links {
		float: right;
		padding: 0;
		list-style: none;
		margin: 0 !important;

		@include media($break1205) {
			margin-right: 1em;
		}

		@include media($break840) {
			display: none;
		}

		.core_link {
			padding: 17px 16px;
		}

		.account_link {
			padding: 8px 16px 9px;
		}

		.help_link {
			padding: 14px 16px;
		}

		.account_link, .help_link {

			&:hover {
				background-color: $aiq_lightblue_bg;	
			}
		}

		.dropdown_indicator {
			display: inline-block;
			width: 15px;
			height: 15px;
			background: url('/images/style_guide/icon_dropdown_indicator.svg') center no-repeat;
			vertical-align: -3px;
		}

		li {
			float: left;
			font-size: 1.0em;
			padding: 0;
			border: 0;
			outline: 0;
			font-weight: inherit;
			font-style: inherit;
			font-size: 100%;

			a {
				font-size: 1.5em;
    		font-weight: 300;
				color: $dark-text;
				text-decoration: none;
        display: inline-block;

				&:hover, &:focus {
					color: $revfluence-blue;
				}
			}

			.active {
				color: $revfluence-blue;

				&:hover {
					color: $revfluence-blue-hover;
				}
			}

			.messages_text {
				display: inline-block;
				margin-right: .25em;
			}

			.messages_number {
				background-color: $revfluence-blue;
				color: white;
				@include fontsize8;
				display: inline-block;
				padding: 0 10px;
				border-radius: 100px;
				line-height: 1.5em;
			}
		}

		.divider_item {
			border-left: 1px solid $lightgray-border;
		}

		.help_li {
			position: relative;

			.iconHint_nav {
				width: 20px;
			}

			&:hover {

				.help_dropdown_list {
					display: block;
				}
			}
		}

		.account_li {
			position: relative;
			padding: 0;
			margin-right: 0;

			.accountDropdown_list {
				display: none;
				position: absolute;
				top: 47px;
				right: 5px;
				width: 150px;

				&:after, &:before {
					left: 112px;
				}

				@include media($break1235) {
					right: 14px;
				}

				.accountDropdown_li {
					margin-right: 0;
					margin-bottom: 0;
					display: block;
					width: 100%;
				}

				.active {
					color: white;
				}

				li {
					margin-bottom: 1em;
					text-align: left;

					&:last-child {
						margin-bottom: 0;
					}

					a {
						padding: .25em 1em;
						display: block;
						color: $dark-text;
						font-size: 1.5em;
						border: none;
						line-height: 1.7em;
						font-weight: 300;
						text-decoration: none;

						&:hover, &:focus, &:active {
							color: white !important;
							background-color: $revfluence-blue !important;
						}
					}
				}
			}

			.border_bottom {
				padding-bottom: .5em;
				border-bottom: 1px solid #E2E2E2;
				margin-bottom: .5em;
			}

			&:hover {

				.accountDropdown_list {
					display: block;
				}
			}
		}

		.account_link {
			display: block;
			border: none;

			img {
				border-radius: 20px;
				width: 32px;
				height: 32px;
			}
		}
	}

	.small_nav_links {
		display: none;
		float: right;

		.core_link {
			padding: 12px 10px 12px;

			@include media($break840) {
				padding: 10px;
			}
		}

		.help_dropdown_list {
			right: 0;
		}

		.smallNav_li {
			display: inline-block;
			vertical-align: 2px;
		}

		.help_li {
			vertical-align: 5px;
			position: relative;
		}

		.help_link {
			display: inline-block;
			padding: 12px;
			border: none;
			background: none;
		}

		.menu_li {
			cursor: pointer;
		}

		@include media($break840) {
			display: block;
		}

		.menu_dropdown {
			margin: 1em 0;
			width: 150px;
			right: 0;
			padding: .5em 0;
			display: none;
			background-color: white;
			position: absolute;
			top: 23px;
			z-index: $zindexFlashNotification;
			box-shadow: 1px 1.732px 10px 0px rgba(1, 1, 1, 0.08);
			border-radius: 10px;
			border: #E2E2E2 solid 1px;

			@include media($break840) {
				top: 39px;
				right: .5rem;
			}

			&:after {
				content: '';
				position: absolute;
				border-style: solid;
				border-width: 0 11px 12px;
				border-color: #FFFFFF transparent;
				display: block;
				width: 0;
				z-index: 1;
				top: -12px;
				left: 112px;
			}

			&:before {
				content: '';
				position: absolute;
				border-style: solid;
				border-width: 0 11px 12px;
				border-color: #E2E2E2 transparent;
				display: block;
				width: 0;
				z-index: 0;
				top: -13px;
				left: 112px;
			}

			li {
				margin-right: 0;
				margin-bottom: 0;
				display: block;
			}

			.border_bottom {
				padding-bottom: .5em;
				border-bottom: 1px solid #E2E2E2;
				margin-bottom: .5em;
			}

			.smallLinks_helpCenter_link {

				.hint_icon {
					vertical-align: -5px;
				}
			}

			.how_it_works_item {
				padding-top: .5em;
				border-top: 1px solid #E2E2E2;
				margin-top: .5em;
			}

			.case_studies_item {
				padding-top: .5em;
				border-top: 1px solid #E2E2E2;
				margin-top: .5em;
			}

			.select_plan_item {
				padding: .5em 0;
				border-top: 1px solid #E2E2E2;
				border-bottom: 1px solid #E2E2E2;
				margin: .5em 0;
			}

			.divider_top_item {
				padding-top: .5em;
				border-top: 1px solid #E2E2E2;
				margin-top: .5em;
			}

			.divider_bottom_item {
				padding-bottom: .5em;
				border-bottom: 1px solid #E2E2E2;
				margin-bottom: .5em;
			}

			a {
				padding: .25em 1em;
				display: block;
				color: $dark-text;
				font-size: 1.5em;
				border: none;
				line-height: 1.7em;
				font-weight: 300;
				text-decoration: none;

				&:hover, &:focus, &active {
					color: white;
					background-color: $revfluence-blue;
				}

				&.active {
					color: white;
					background-color: $revfluence-blue;
					font-weight: 600;
				}
			}



			.messages_text {
				display: inline-block;
				margin-right: .25em;
			}

			.messages_number {
				background-color: $revfluence-blue;
				color: white;
				@include fontsize8;
				display: inline-block;
				padding: .09em .65em .01em;
				border-radius: 100px;
			}

			.select_plan_button {
				background-color: $revfluence-blue;
				border-radius: 100px;
				border: 1px solid white;
				padding: .45em 1.25em;
			}
		}

		.menu_li {
			position: relative;
	        		z-index: 8000;

			&.active {
				.menu_dropdown {
					display: block;
				}
			}

			.menu_link {
				color: #9D9D9D;
				font-size: 1.5em;
				line-height: 1.7em;
				font-weight: 300;
				text-decoration: none;
				display: inline-block;

				&:hover {
					color: $dark-text;
				}
			}
		}
	}
}

.creator_profile {

	.main_nav {

		.logo_link {

			@include media($break700) {
				display: none;
			}
		}
	}

	.creator_main_nav {

		.revfluence_logo {

			@include media($break700) {
				display: block;
			}
		}

		.menu_dropdown {
			top: 44px;
			right: .25rem;
		}
	}

	.public_profile_nav {

		.revfluence_logo, .left_group {

			@include media($break700) {
				display: none;
			}
		}

		.entry_links_container {

			@include media($break700) {
				display: none;
			}
		}

		.small_entry_links_container {

			@include media($break700) {
				display: block !important;
			}

			.signin_links_list {

				@include media($break700) {
					display: none !important;
				}
			}
		  .indented_dropdown_item {
			padding-left:20px;
		  }

		  .border_bottom {
				padding-bottom: .5em;
				border-bottom: 1px solid #E2E2E2;
				margin-bottom: .5em;
		  }
		}
	}

	.learn_more_nav {
		float: right;
		width: 205px;

		@include media($break625) {
			display: none !important;
		}

		.learn_more_item {
			float: left;
			margin-right: .5rem;

			&:last-child {
				margin-right: 0;
			}
		}

		.learn_more_btn {
			margin-top: .3rem;
		}

		.signin_link {
			padding: .75rem;
			color: #878787;
			text-decoration: none;
			display: block;
		}
	}

	.learn_more_items {
		border-bottom: 1px solid $lightgray-border;
	}
}
