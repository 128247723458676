.add_aud_demographics {

  .mainWrap {
    max-width: 725px;
    margin: 100px auto 0;
    padding: 1rem 1rem 3rem;
  }

  .mainTitle, .mainDescrip {
    text-align: left;
  }

  .mainTitle {
    padding-bottom: 1.75rem;
    text-align: center;
    font-weight: 700 !important;
    font-size: 1.85rem;

    @include media($break400) {
      font-size: 1.75rem;
    }
  }

  .mainDescrip {
    font-size: 1rem;
    padding-bottom: 1.5rem;
  }

  .icon_add {
    margin-right: .5rem;
    vertical-align: -4px;
  }

  .mainList {
    padding-bottom: 3rem;
  }

  .mainItem {
    border: 1px solid $lightgray-border;
    padding: .5rem;
    margin-bottom: .5rem;

    &:last-child {
      margin-bottom: 0;
    }

    @include media($break525) {
      text-align: center;
    }
  }

  .accountAvatar, .detailWrap, .addDemo_btn {
    float: left;

    @include media($break525) {
      float: none;

    }
  }

  .nameWrap {
    padding-bottom: .1rem;
  }

  .accountName {
    font-weight: 600;
    display: inline-block;
    width: calc(100% - 20px);

    @include media($break525) {
      width: auto;
    }

    @include media($break360) {
      display: block;
    }
  }

  .addDemo_btn {
    margin-top: .25rem;
  }

  .mainItem {
    padding: .9rem;
    border-radius: 6px;
    margin-bottom: .8rem;

    &:hover {
      background-color: $ultraLight_gray;
    }
  }

  .helpText {
    width: 100%;
    text-align: center;
    margin-top: 1.0rem;
    background-color: #F4F4F4;
    border-radius: 6px;
    padding: 1rem;

    .btn_link {
      cursor: pointer;
    }
  }

  .accountAvatar {
    width: 36px;
    margin-right: .75rem;
    border-radius: 100px;
    margin-top: .15rem;

    @include media($break525) {
      margin-bottom: .5rem;
      margin-right: 0;
    }
  }

  .detailWrap {
    margin-right: .7rem;
    width: calc(100% - 220px);

    @include media($break525) {
      margin-right: 0;
      width: 100%;
      padding-bottom: .5rem;
    }
  }

  .addedSuccess {
    font-weight: 600;
  }

  .icon_success {
    width: 15px;
    margin-right: .3rem;
    vertical-align: -2px;
  }

  .notAdded {}

  .addedStatus {
    font-size: .75rem;
  }

  .icon_ig {
    vertical-align: -1px;
  }

  .icon_network {
    width: 15px;
    margin-right: .3rem;
    vertical-align: 3px;

    @include media($break360) {
      margin-right: 0;
      display: block;
      margin: 0 auto .25rem;
    }
  }

  .icon_addItem {
    margin-right: .35rem;
    vertical-align: -4px;
  }

  .added_mainItem {
    background-color: $ultraLight_gray;

    &:hover {
      background-color: $ultraLight_gray;
    }
  }

  .btn_finished {
    display: block;
    max-width: 800px;
    margin: 1.25rem auto;
  }

  .faqWrap {
    border-top: 1px solid $aiq_border;
    padding-top: 2rem;

    .faqTitle {
      font-weight: 700;
      padding-bottom: 1.5rem;
      text-align: center;
      font-size: 1.4rem;
    }

    .faqQuestion {
      font-weight: 700;
      padding-bottom: 1rem;
    }

    .faqAnswer {
      padding-bottom: 2rem;
    }
  }
}
