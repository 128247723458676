/* STARTER HTML

<!--PROPOSAL NOTICE - COMPLETING TRIAL -->
<div class="proposalCD_notice hint_msg clearfix" style="display:none;">
  <span class="icon_5">5</span>
  <div class="proposalCD_txt_wrap">
    <h4 class="proposalCD_title default_body_copy">Completing Your Campaign Limit Trial</h4>
    <p class="default_body_copy">Welcome to Revfluence! In your first few months, <strong>you can apply to work with up to 5 campaigns you are not invited to</strong>. Once you received a positive review on our platform (4 or 5 stars), you’ll no longer be limited in how many brands you can collaborate with. Good luck!</p>
  </div>
</div>

*/

/* PROPOSAL CD NOTICE */
.proposalCD_notice {

  .icon_5, .proposalCD_txt_wrap {
    float: left;
  }

  .icon_5 {
    background-color: $revfluence-blue;
    border-radius: 100px;
    text-align: center;
    width: 35px;
    line-height: 35px;
    display: inline-block;
    color: white;
    font-weight: 600;
    margin-right: 1rem;
    font-size: 1.25rem;
    margin-top: 3px;

    .icon_5 {
			@include media($break375) {
				width: 37px;
				line-height: 37px;
			}
		}
  }

  .proposalCD_txt_wrap {
    display: inline-block;
    width: calc(100% - 59px);
  }

  .proposalCD_title {
    display: block;
    font-weight: 700;
    padding-bottom: .25rem;
  }

  .proposalCD_descrip {}
}
