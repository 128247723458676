.avatarImg {
  border-radius: 100px;
}

/* UPLOAD AVATAR MODULE STARTER HTML
<div class="avatar_container">
  <div class="contentImg_form" ui-sref='profile.main.home.update_avatar' rf-ui-sref-disable="!isPublisher">
    <div class="img_upload_label">
      <img alt class="avatar_img" ng-src="[[publisher.profile_picture || '/images/brand_onboarding/default_avatar.png']]"
           rf-image-fallback="/images/brand_onboarding/default_avatar.png"
           rf-image-list="backupAvatarImages">
    </div>
    <a ng-if="isPublisher">
      <img src="/images/creator_profile/edit_icon.svg" alt class="edit_icon">
    </a>
  </div>
</div>

*/
.uploadAvatar_container {
  float: left;
  width: 100px;
  margin-right: 1rem;
  position: relative;
  height:100px;

  @include media($break375) {
    width: 75px;
  }

  .edit_icon {
    position: absolute;
    top: 5px;
    left: 1px;
    cursor: pointer;
    display: none;

    @include media($break875) {
      display: block;
    }
  }

  &:hover {

    .edit_icon {
      display: block;
    }
  }

  .avatar_img {
    max-width: 100%;
  }
}
