.msgGrp_popup {

  .close_modal {
    position: absolute;
    right: .75rem;
    top: .75rem;
  }

  .msgGrp_header {
    text-align: center;
    padding: 1rem 0 .5rem;

    .msgGrp_title {}
  }

  .msgGrp_form {
    padding: 0 1.5rem 2rem;

    .composeMsg_fieldset {
      padding-bottom: 1rem;
    }

    .standard_label {}

    .msgGrp_textarea {}

    .alsoStart_project_fieldset {
      max-width: 340px;
      margin: 0 auto;
    }

    .msgGrp_campaign_selection {
      width: 258px;
      margin: 0 auto;
    }

    .dropdown_descrip {
      padding-right: .25rem;
      vertical-align: 6px;
    }

    .dropdown_container {
      display: inline-block;
    }

    .dropdown_btn {}

    .msgGrp_actions_fieldset {}

    .msgGrp_action {
      width: 200px;
      margin: 0 auto;
      display: block;
    }

  }
}
