@mixin small-radius {
	border-radius: 3px;
}

@mixin standard-radius {
	border-radius: 3px;
}

@mixin standard-radius-left {
	border-radius: 3px 0 0 3px;
}

@mixin standard-radius-right {
	border-radius: 0 3px 3px 0;
}

@mixin standard-radius-top {
	border-radius: 3px 3px 0 0;
}

@mixin standard-radius-top-right {
	border-radius: 0 3px 0 0;
}

@mixin standard-radius-bottom-right {
	border-radius: 0 0 3px 0;
}

@mixin standard-radius-bottom {
	border-radius: 0 0 3px 3px;
}

@mixin small-radius-right {
	border-radius: 0 3px 3px 0;
}

@mixin small-radius-left {
	border-radius: 3px 0 0 3px;
}

@mixin circle-radius {
	border-radius: 45px;
}