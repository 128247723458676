.analyze_ads_summary {

  .inner_container {}

  .graphNav_number, .graphNav_label {
    display: block;
  }

  .graphNav_number {
    margin-bottom: .75rem;
    font-weight: 600;
    font-size: 1.3rem;
  }

  .graphNav_label {
    font-size: .9rem;
    line-height: 1.4rem;
  }

  .overallSummary_section {
    padding: 2rem 0 3rem;
    border-bottom: 1px solid $lightgray-border;

    .inner_container {
      margin: 0 auto;
      padding: 0 1rem;
    }

    .overallSummary_title {
      text-align: center;
      font-size: 2rem;
      margin-bottom: 1.75rem;
    }

    .creator_avatar {
      border-radius: 100px;
      width: 40px;
      vertical-align: -7px;
      margin-right: 1rem;

      &:hover {
        opacity: .8;
      }
    }

    .graphNav_list {
      text-align: center;
      margin-bottom: 2rem;
    }

    .graphNav_item {
      display: inline-block;
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }

    .graphNav_btn {
      text-align: center;
      border-radius: 6px;
      background: none;
      padding: 1rem;
      width: 173px;
      transition: all .15s ease-in-out;

      &:hover {
        color: $revfluence-blue;
        background-color: #F5F5F5;
      }
    }

    .active_graphNav_btn {
      color: $revfluence-blue;
      background-color: #F5F5F5;
    }

    .graphContainer {}

    .graphPlaceholder {
      max-width: 100%;
    }
  }

  .accountSummary_section {
    padding: 2rem 0 3rem;
    border-bottom: 1px solid $lightgray-border;

    .inner_container {
      margin: 0 auto;
      padding: 0 1rem;
    }

    .accountSummary_title {
      font-size: 2rem;
      margin-bottom: 1.75rem;
    }

    .mainMetrics_container {
      padding-bottom: 3rem;
    }

    .mainMetrics_list {}

    .mainMetrics_item {
      display: inline-block;
      width: 13%;
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }

    .mainMetrics_number {}

    .mainMetrics_label {}

    .adMetrics_table_container {

      thead {

        tr:nth-child(odd) {
          background-color: #FFF;
        }
      }

      tr:nth-child(odd) {
        background-color: #F9F9F9;
      }

      tr:nth-child(even) {
        background-color: white;
      }

      .accountSummary_title {
        margin-bottom: 1.25rem;
      }

      .adMetrics_table {
        font-weight: 400;
        width: 100%;
      }

      th {

      }

      thead {}

      .creator_avatar {
        width: 45px;
        border-radius: 6px;
        vertical-align: -16px;
        margin-right: .5rem;
      }

      .viewMore_btn {
        width: 158px;
        display: block;
        margin: 2.25rem auto 0;
      }
    }
  }

  .brandAccount_section {}
}
