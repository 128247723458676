.btn {
	display: inline-block;
	padding: 6px 20px;
	margin-bottom: 0;
	font-size: .9rem;
	line-height: 1.5em;
	font-weight: 400;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	touch-action: manipulation;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-webkit-apperance:none;
  	user-select: none;
  	background-image: none;
  	border: none;
  	border-radius: 100px;
  	outline: none;
  	text-decoration: none;
  	-webkit-appearance: none;

  	&:hover {
  		text-decoration: none;
  	}

  	&:focus, &:active {
		box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem $revfluence-blue;
	}
}

.add_btn {
	padding: 6px 14px;

	.add_btn_icon {
		display: inline-block;
		vertical-align: -4px;
		margin-right: .4rem;
		width: 19px;
	}

	.add_btn_txt {
		display: inline-block;
	}
}

.circle_btn {
	display: block;
	margin-bottom: 0;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-webkit-apperance:none;
  	user-select: none;
  	background-image: none;
  	border: 1px solid transparent;
  	border-radius: 100px;
  	outline: none;
  	text-decoration: none;
  	-webkit-appearance: none;
  	padding: 15px;
	border-radius: 100px;
	height: 59px;
	width: 59px;
	text-indent: -9999px;

  	&:hover {
  		text-decoration: none;
  	}

  	&:focus, &:active {
		box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem $revfluence-blue;
	}
}

.btn_default {
	border: none;
	background-color: $aiq_lightblue_bg;
	color: $aiq_darkblue;
	font-weight: 500;
	cursor: default;

	&:hover, &:focus {
		color: $aiq_darkblue_hover;
		background-color: $aiq_lightblue_bg_hover;
	}

	&:active {
		background-color: $aiq_lightblue_bg;
	}
}

.btn_default_danger {
	border: 1px solid $lightgray_border;
	background-color: white;
	color: #D9534F;
	font-weight: 500;
	cursor: default;

	&:hover {
		background-color: $lightgray_bg;
	}

	&:active, &:focus {
		background-color: $pressedBtn_gray;
	}
}

.btn_primary {
	color: white;
	background-color: $revfluence-blue;
	cursor: default;

	&:hover {
		background-color: $revfluence-blue-hover;
	}

	&:active {
		background-color: $revfluence-blue;
	}
}

.btn_danger {
	color: white;
	background-color: #d9534f;
	cursor: default;
}

.btn_disabled {
	cursor: inherit;
	opacity: .25;

	&:focus, &:active {
		box-shadow: none;
	}

	&:hover {
		background-color: inherit;
		color: inherit;
	}
}

.btn_link {
	font-weight: 500;
	color: $aiq_darkblue;
	border-radius: 0;
	border-radius: 100px;
	background: none;

	&:hover {
		color: $aiq_darkblue_hover;
	}
}

.btn_link_inline {
	font-size: 1em;
	padding: 0;
	vertical-align: initial;

	&:focus, &:active {
		box-shadow: none;
	}
}

.btn_lg {
	padding: 10px 23px;
	font-size: 1.5em;
	line-height: 1.5em;
}

.btn_sm {
	padding: 5px 10px;
	font-size: 1.25em;
	line-height: 1.25em;
}

.standard_link {
	text-decoration: none;
	color: $revfluence-blue;
	cursor: pointer;

	&:hover {
		color: $revfluence-blue-hover;
	}
}

.disabled_link {
	cursor: default;
}

.btn_block {
	display: block;
	width: 100%;
}

a.btn.disabled {
	pointer-events: none;
	opacity: .65;
	cursor: not-allowed;
}

.circle_btn {
	background: $revfluence-blue url('/images/my_campaigns/add_campaign_icon_white.svg') center no-repeat;
	padding: 15px;
	border-radius: 100px;
	display: block;
	height: 59px;
	width: 59px;
	margin-bottom: -25px;
	text-indent: -9999px;

	&:hover {
		background: $revfluence-blue-hover url('/images/my_campaigns/add_campaign_icon_white.svg') center no-repeat;
	}
}

.circle_btn_lg {
	width: 100px;
	height: 100px;
}

.circle_btn_sm {
	width: 44px;
	height: 44px;
}

.no_shadow_focus {

	&:focus, &:active {
		box-shadow: none;
	}
}

.bar_btn {
  border-radius: 5px;
  border: 1px solid $lightgray-border;
  background-color: white;
  width: 100%;
  display: block;
  text-align: left;
  padding: .75rem 1rem;

  &:hover {
	background-color: $ultraLight_gray;
  }

  .disclosure_icon {
	width: 9px;
	display: inline-block;
	margin-right: .5rem;
	vertical-align: -2px;
  }

  .open {

	.dropdown_icon {
	  transform: rotate(90deg);
	}
  }

  .bar_btn_txt {
	font-weight: 300;
	display: inline-block;
  }
}
.hint_btn {
	padding: 0;
	border: none;
}

.backBtn {
	padding: .75rem;
	background: none;
	border: none;

	.back_icon {
		display: block;
		width: 22px;
	}
}


/* btn_socialAcct starter HTML

<button class="btn_socialAcct primary_btn btn">
	<img ng-src="[[ product.account.networkInfo().networkIcon ]]" alt="" class="icon_youtube network_icon" ng-if="!!product.account">
	<span class="socialAcct_txt truncate">[[ product.account.name ]]</span>
</button>

*/
.btn_socialAcct {
	border: 1px solid $lightgray-border;
	border-radius: 5px;
	padding: 4px 8px;
	background-color: white;
	font-size: .9rem;
	color: $dark-text;
	text-decoration: none;
	line-height: 16px;

	&:hover {
		background-color: #FAFAFA;
		color: $revfluence-blue;
	}

	.network_icon {
		margin-right: 5px;
		width: 16px;
		height: 16px;
		vertical-align: -1px;
	}

	.icon_youtube {}

	.icon_instagram {}

	.icon_vine {}

	.icon_twitter {}

	.icon_facebook {}

	.icon_pinterest {}

	.icon_googleplus {}

	.icon_blog {}

	.socialAcct_txt {
		display:inline-block;
		max-width: 120px;
		vertical-align: -2px;
	}
}

/* Help Center Link */

/* Starter HTML
	<a href="" title class="help_center_link"><img src="/images/style_guide/icon_help_center.svg" alt class="icon_help_center"><span class="help_link_txt">Learn About X</span></a>
*/

.help_center_link {
	text-decoration: none;
	color: gray;
	font-size: .9rem;
	font-weight: 300;

	.icon_help_center {
		width: 25px;
		margin-right: 4px;
		transition: all .15s ease-in-out;
	}

	.help_link_txt {
		vertical-align: 7px;
	}

	&:hover {
		color: $helpDull_gray;

		.icon_help_center {
			transform: scale(1.15, 1.15);
		}
	}
}

.rectangle_btn {
	border: 1px solid $lightgray-border;
	border-radius: 6px;
	background-color: white;
	padding: 6px 11px;
	cursor: default;

	&:hover {
		background-color: $ultraLight_gray;
	}
}

/* SAMPLE HTML btn_icon WITH TOOLTIP
<div class="tooltip_container">
	<button type="button" class="btn_icon tooltip_btn btn"><img src="/images/style_guide/icon_edit_dark.svg" class="icon_edit"></button>
	<div class="smallCenter_tooltip tooltip_black tooltip_bottomCenter tooltip">
		<span class="tooltip_txt">Lorem ipsum</span>
	</div>
</div>
*/
.btn_icon {
	background: none;
	padding: 10px;
	border-radius: 4px;

	&:hover {
		background-color: $aiq_lightblue_bg;
	}

	&:active, &:focus {
		background-color: $aiq_lightblue_bg_hover;
	}
}

.disabledBtn_coverup {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(255,255,255,.85);
}

.square_btn {
	border-radius: 6px;
}

.borderless_btn {
	border: none;
}

.txtBtn {
	padding: 0;
	font-size: .8rem;
	background: none;
	font-weight: 300;

	&:hover {
		color: $revfluence-blue;
	}
}

/* BADGE STARTER HTML

<button type="button" class="badge_btn btn">
	<img src="/images/style_guide/badge_abovebeyond.png" class="badge_professional">
	<span class="badge_txt">Above &amp; Beyond</span>
</button>

*/

.badge_btn {
	text-align: center;
	padding: 1rem;
	background: none;
	border-radius: 8px;
	display: block;
	width: 100%;

	&:hover {
		background-color: $ultraLight_gray;
	}

	&:active {
		background-color: $lightgray_bg;
	}

	.badge_img, .badge_txt {
		display: block;
	}

	.badge_img {
		width: 62px;
		margin: 0 auto .5rem;
	}

	.badge_txt {
		font-weight: 600;
	}
}

.active_badge_btn {

	.badge_txt {
		color: white;
	}

	.badge_btn {
		background-color: $revfluence-blue;

		&:hover {
			background-color: $revfluence-blue-hover;
		}
	}
}

/* PLACEHOLDER HTML FOR FAV BUTTON
<div class="favBtn_dropdown_container dropdown_container">
	<button type="button" class="favBtn dropdown_btn btn no_shadow_focus">
		<img src="/images/style_guide/icon_star_outline_white.svg" class="icon_fav">
		<img src="/images/style_guide/icon_star_outlineHover_white.svg" class="icon_fav_hover icon_fav">
		<img src="/images/style_guide/icon_star_colorOutline.svg" class="icon_fav_active icon_fav">
	</button>
	<div class="favDropdown_list dropdown_list">
		<div class="addWrapper" style="display:none;">
			<span class="favDropdown_title default_body_copy">Add to Your Favorites</span>
			<ul class="favDropdown_list_list">
				<li class="dropdown_li"><a href title class="truncate">SD Creators</a></li>
				<li class="dropdown_li"><a href title class="addNew_link">
					<img src="/images/style_guide/icon_plus_black.svg" class="icon_addNew">
					<img src="/images/style_guide/icon_plus_white.svg" class="icon_addNew_hover icon_addNew">
					New List&hellip;
				</a></li>
			</ul>
		</div>
		<div class="addNew_fav_wrapper">
			<div class="addNew_fav_header">
				<button type="button" class="addNew_back_btn btn no_shadow_focus"><img src="/images/style_guide/icon_back_black.svg" class="icon_back"></button>
				<span class="addNew_title default_body_copy">Add to New Favorites List</span>
			</div>
			<input type="text" class="form_control" placeholder="Name your list&hellip;">
			<button type="button" class="btn_primary btn">Add</button>
		</div>
	</div>
</div>
*/
.favBtn {
	background: none !important;
	padding: 14px;

	&:active, &:focus {
		background: none !important;
	}

	.icon_fav {
		width: 20px;
	}
}

.favBtn_dropdown_container {

	.dropdown_btn {
		background: none;
		padding: 10px 14px 10px;
		margin-bottom: 0;
		max-width: inherit;
		border: none;

		&:hover {
			background: none;
		}
	}
}

/** DIVIDER BTN STARTER HTML
<button type="button" class="toggleGraph_btn divider_btn no_shadow_focus btn">
	<div class="btnLabel">
		<img src="/images/style_guide/icon_graph.svg" class="icon_graph">
		<span class="btnLabel_txt">Show Graph</span>
		<img src="/images/style_guide/icon_dropdownIndicator_gray.svg" class="icon_dropdown">
	</div>
</button>
**/
.divider_btn {
	background: url('/images/style_guide/divider_btn_bg.png') center no-repeat;
	width: 100%;
	border-radius: 0;
	padding: .25rem 0;

	&:hover {
		background: #FAFAFA url('/images/style_guide/divider_btn_bg.png') center no-repeat;
	}

	&:active {
		background: #f1f1f1 url('/images/style_guide/divider_btn_bg.png') center no-repeat;
	}

	.btnLabel {
		width: 171px;
		margin: 0 auto;
		border-radius: 4px;
		border: 1px solid $lightgray-border;
		background: white;
	}

	.btnLabel_txt {
		text-transform: uppercase;
		color: #8E8E8E;
		margin-right: .3rem;
		font-weight: 700;
		font-size: .7rem;
		letter-spacing: .12rem;
	}

	.icon_dropdown {
		width: 12px;
		vertical-align: 0;
	}
}

/* LOVE CONTENT BUTTON

Starter HTML

<!-- LIKE BUTTON -->
<button type="button" class="active_likeContent_btn likeContent_btn btn no_shadow_focus">
	<img src="/images/style_guide/icon_like_btn.svg" class="icon_like_btn">
	<img src="/images/style_guide/icon_like_btn_active.svg" class="icon_like_active icon_like_btn">
</button>
*/
.searchContent_btn {
	position: absolute;
	z-index: 9999;
	right: 2.8rem;
	top: .65rem;
	padding: .25rem;
	background: none;
	z-index: 4;
	/* transition: all .15s ease-in-out; */

	.icon_search_btn {
		width: 22px;
	}

	/* &:hover, &:focus {
		transform: scale(1.15, 1.15);
	} */
}

.likeContent_btn {
	position: absolute;
	z-index: 9999;
	right: .5rem;
	top: .65rem;
	padding: .25rem;
	background: none;
	z-index: 4;
	/* transition: all .15s ease-in-out; */

	.icon_like_btn {
		width: 22px;
	}

	/* &:hover, &:focus {
		transform: scale(1.15, 1.15);
	}

	&:active {
		transform: scale(1, 1);
	}*/
}

.btn_hoveredIcon {

	.icon_hovered {
		display: none;
	}

	&:hover {

		.icon_hovered {
			display: inline-block;
		}

		.icon_unhovered {
			display: none;
		}
	}
}

.ig_btn {
	background-image: linear-gradient(-270deg, #FFC750 0%, #F8443E 27%, #D53592 53%, #A928A6 77%, #972FC0 100%);
	border-radius: 4px;
	color: white;

	&:hover {
		opacity: .9;
	}
}

.yt_btn {
	background-color: #FF0202;
	border-radius: 4px;
	color: white;

	&:hover {
		background-color: #FF3535;
	}
}

.finishedBtn_icon {
	display: none;
}

/*.finishedBtn {
	color: #ADACAE;

	.invited_icon {
		display: none;
	}

	.finishedBtn_icon {
		display: inline-block;
	}
}*/

.btn_primary, .btn_default, .btn_outline, .btn_white {
	border-radius: 100px;
	padding: 9px 18px 9px 18px;
	font-size: .87rem;
	letter-spacing: .02rem;
	font-weight: 400;
}

.btn_white {
	color: $aiq_darkblue;
	background-color: $aiq_offwhite;

	&:hover, &:focus {
		background-color: $aiq_border;
	}

	&:active {
		background-color: $aiq_offwhite;
	}
}

.btn_primary, .btn_default, .btn_outline {
	border-radius: 100px;
	padding: 10px 18px 8px 18px;
	font-size: .87rem;
	letter-spacing: .02rem;
}

.clear_filter_button {
	padding: .25rem;
	cursor: pointer;
	font-size: .8rem;

	.icon {
		margin-right: .15rem;
		width: 16px;
		vertical-align: -3px;
	}
}

.facebook_button {
	background-color: $facebook_blue;
	border-radius: 100px;
	color: $aiq_offwhite;
	font-weight: 400;

	&:hover, &:focus {
		background-color: $facebook_blue_hover;
	}

	&:active {
		background-color: $facebook_blue;
	}

	.icon_facebook {
		width: 16px;
		margin-right: .25rem;
		vertical-align: -3px;
	}
}
