.startFreeTrial {

	.mainContainer {
		@include outer-container;
		margin-top: 8em;
		margin-bottom: 5em;

		@include media($break960) {
			margin: 6em 0 5em;
		}
	}

	.add_creditCard_container {
	  width: 43.75%;
	  margin: auto;

		@include media($break1049) {
	    width: 50%;
	  }

		@include media($break960) {
	    width: 100%;
	    height: auto;
	  }

	  @include media($break519) {
	    padding: 0 3em;
	  }
	}

	.introContainer {
		@include span-columns(7);
		@include shift(1);
		height: 475px;
        background: $revfluence-blue-darker url('../images/start_trial/modelBlueBG.jpg') center no-repeat;

		@include media($break1049) {
			@include shift(0);
			@include span-columns(8);
		}

		@include media($break960) {
			@include span-columns(16);
			background: $revfluence-blue-darker;
			height: auto;
		}

		.textContainer {
			margin: 7.5em 0 5em;
			padding: 0 5em;

			@include media($break960) {
				margin: 5em 0;
				height: auto;
			}

			@include media($break519) {
				margin: 3em 0;
				padding: 0 3em;
			}
		}

		.introTitle {
			@include title35;
			color: white;
			font-weight: 200;
			margin-bottom: 1em;

			@include media($break960) {
				margin-bottom: .25em;
			}

			@include media($break480) {
				@include title25;
				font-weight: 400;
			}
		}

		.introDescrip {
			@include fontsize15;
			color: white;
			margin-bottom: 2em;
			line-height: 2.1em;

			@include media($break480) {
				line-height: 1.7em;
			}
		}

		.viewPlansLink {
			color: white;
			@include uppercase13;
			text-decoration: none;
			border-bottom: 1px solid white;
			padding-bottom: .5em;

			@include media($break960) {
			}
		}
	}
}
