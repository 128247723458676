/* Starter HTML

<!-- DEPRECATED -->
<div class="dropdown_container">
	<button class="dropdown_btn btn">
		<span class="btn_txt">Button</span>
	</button>
	<ul class="dropdown_list">
		<li class="dropdown_li"><a href title class=""></a></li>
	</ul>
</div>

<!-- NEW DROPDOWN BUTTON -->
<div class="dropdown_container">
	<button class="dropdown_btn2 btn">
		<img src="/images/style_guide/icon_singleuser_gray.svg" class="icon_user dropdownLeft_icon">
		<span class="btn_txt">Button</span>
		<img src="/images/style_guide/icon_dropdownIndicator_gray.svg" class="icon_dropdown">
		<img src="/images/style_guide/icon_doubleArrow_gray.svg" class="icon_doubleArrow">
	</button>
	<ul class="dropdown_list">
		<li class="dropdown_li">
			<a href title class="">
				<img src="/images/style_guide/.icon_singleuser_gray.svg" class="icon_user dropdownLeft_icon">
			</a>
		</li>
	</ul>
</div>

<-- DROPDOWN BOTTOM BUTTON WITH MAX HEIGHT SCROLLING

	<div class="dropdown_container">
		<button type="button" class="dropdown_btn2 btn">
			<img src="/images/style_guide/icon_singleuser_gray.svg" class="icon_user dropdownLeft_icon">
			<span class="btn_txt">Button</span>
			<img src="/images/style_guide/icon_dropdownIndicator_gray.svg" class="icon_dropdown">
			<img src="/images/style_guide/icon_doubleArrow_gray.svg" class="icon_doubleArrow">
		</button>
		<div class="dropdown_list">
			<ul class="actual_dropdown_list">
				<li class="dropdown_li">
					<a href title class="">
						<img src="/images/style_guide/.icon_singleuser_gray.svg" class="icon_user dropdownLeft_icon">
					</a>
				</li>
			</ul>
			<div class="bottomBtn_container">
				<button type="button" class="dropdown_btn2 btn">
					<img src="/images/style_guide/icon_addItem_circle_color.svg" class="icon_addItem"> Bottom Button
				</button>
			</div>
		</div>
	</div>

 -->

*/

.dropdown_container {
	position: relative;

	.actual_dropdown_list {
		max-height: 200px;
		overflow-y: auto;
		scrollbar-face-color: #AFAFAF;
		scrollbar-track-color: none;

		@include media($break630) {
		  background-color: #F8F8F8;
		  padding: 0;
		}

		::-webkit-scrollbar {
		  width:  6px;
		}

		::-webkit-scrollbar-thumb {
		  background: #D8D8D8;
		  border-radius: 100px;
		}

		::-webkit-scrollbar-track {
		  background: none;
		}
	}

	.bottom_dropdown_btn {
		border-radius: 0;
		background: none;
		font-weight: 300;
		border-top: 1px solid $lightgray-border;
		width: 100%;
		display: block;
		cursor: default;
		text-align: left;
		padding: .25em 1em;

		&:hover {
			background: $revfluence-blue;
			color: white;
		}
	}
}

.dropdown_btn {
	background: $aiq_lightblue_bg url('/images/style_guide/dropdown_indicator_icon.svg') calc(100% - .7rem) center no-repeat;
	padding: .5rem 1.9rem .5rem .95rem;
	margin-bottom: 1rem;
	background-size: 10px;
	font-weight: 400;
	border-radius: 100px;
	cursor: default;

	&:hover, &:focus {
		background-size: 10px;
		background: $aiq_lightblue_bg_hover url('/images/style_guide/dropdown_indicator_icon.svg') calc(100% - .7rem) center no-repeat;
	}

	&:active {
		background: $aiq_lightblue_bg url('/images/style_guide/dropdown_indicator_icon.svg') calc(100% - .7rem) center no-repeat;
	}

	.btn_txt {
		font-size: .9rem;
		text-align: left;
		display: block;
	}
}

.dropdown_btn2 {
	border-radius: 100px;
	padding: .5rem 1.9rem .5rem .95rem;
	margin-bottom: 1rem;
	font-weight: 400;
	cursor: default;
	position: relative;
	background: $aiq_lightblue_bg url('/images/style_guide/dropdown_indicator_icon.svg') 95% center no-repeat;
	width: 100%;
	text-align: left;

	&:hover {
		background-color: $aiq_lightblue_bg_hover;
	}

	&:active, &:focus {
		background-color: $aiq_lightblue_bg;
	}

	.dropdownLeft_icon {
		margin-right: .05rem;
		width: 20px;
		vertical-align: -1px;
	}

	.btn_txt {
		font-size: .9rem;
		text-align: left;
		display: inline-block;
		vertical-align: -2px;
	}

	.icon_dropdown, .icon_doubleArrow {
		position: absolute;
	}

	.icon_dropdown {
		right: 8px;
		top: 15px;
		width: 13px;
	}

	.icon_doubleArrow, .doubleArrow_icon {
		right: 11px;
		top: 11px;
		width: 7px;
	}
}

.doubleArrow_toggle_btn, .doubleArrow_select_btn, .doubleArrow_btn {
	background: $aiq_lightblue_bg url('/images/style_guide/icon_select_doubleArrow_black.svg') 97% center no-repeat;
	background-size: 7px;

	&:hover, &:focus {
		background: $aiq_lightblue_bg_hover url('/images/style_guide/icon_select_doubleArrow_black.svg') 97% center no-repeat;
		background-size: 7px;
	}

	&:active {
		background: $aiq_lightblue_bg url('/images/style_guide/icon_select_doubleArrow_black.svg') 97% center no-repeat;
		background-size: 7px;
	}
}

rf-dropdown {
	.dropdown_list {
		display: block;
	}
}

.link_dropdown_button {
	color: $aiq_darkblue;
	font-size: .75rem;
	font-weight: 400;
	padding: 4px;
	background: none;

	&:hover {
		color: $aiq_darkblue_hover;
		text-decoration: underline;
	}

	.indicator {
		margin-left: .15rem;
	}
}

/* DOULE ARROW SELECT DROPDOWN

<div class="doubleArrow_select_container">
	<button class="doubleArrow_select_btn">
		<span class="select_btn_txt truncate">Variable A</span>
		<img src="/images/style_guide/icon_select_doubleArrow_black.svg" alt class="doubleArrow_icon">
	</button>
	<ul class="dropdown_list" style="display:none;">
		<li></li>
	</ul>
</div>

 */
.doubleArrow_select_container {
	position: relative;

	.doubleArrow_select_btn {
		background-color: white;
		border: 1px solid $lightgray-border;
		border-radius: 5px;
		width: 136px;
		text-align: left;
		padding: 6px 23px 6px 10px;

		.select_btn_txt {
			font-weight: 300;
			display: inline-block;
			line-height: 1.19rem;
			padding-top: .1rem;
		}

		.doubleArrow_icon {
			position: absolute;
			right: .95rem;
			top: .67rem;
			width: 7px;
		}
	}

	.doubleArrow_dropdown {
    display: block;
		top: 42px;
		right: -13px;

		> li {
			margin-bottom: 0;
		}

		.doubleArrow_dropdown_btn {
			background-color: white;
			border-radius: 0;
			display: block;
			border: none;
			width: 100%;
			text-align: left;
			padding: 8px 13px;
			right: -.86rem;

			&:hover:not(.btn_disabled) {
				background-color: $revfluence-blue;
				color: white;
			}

			&:focus, &:active {
				box-shadow: none;
			}
		}

		.networkDropdown_btn_txt {
			font-weight: 300;
			font-size: .85rem;
		}
	}
}

.dropdown_list {
	display: none;
	background-color: white;
	background-color: rgba(255,255,255,.97);
	position: absolute;
	top: 40px;
	right: -5px;
	z-index: 8999;
	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.07);
	border-radius: 6px;
	border: #E2E2E2 solid 1px;
	width: 180px;
	padding: .5em 0;

	.hasDivider {
		border-bottom: 1px solid $lightgray-border;
		padding-bottom: 3px;
	}

	/* Use left: property to change position of tail */
	&:after {
		content: '';
		position: absolute;
		border-style: solid;
		border-width: 0 12px 10px;
		border-color: #FFFFFF transparent;
		display: block;
		width: 0;
		z-index: 1;
		top: -9px;
		left: 112px;
	}

	/* Use left: property to change position of tail */
	&:before {
		content: '';
		position: absolute;
		border-style: solid;
		border-width: 0 12px 10px;
		border-color: #E2E2E2 transparent;
		display: block;
		width: 0;
		z-index: 0;
		top: -10px;
		left: 112px;
	}

	.dropdown_li {
		margin-right: 0;
		margin-bottom: 0;
		display: block;
	}

	.dropdown_li_Lefticon {
		margin-right: .15rem;
		width: 12px;
	}

	li {
		text-align: left;

		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: none;
		}

		> button {
			text-align: left;
			display: block;
			width: 100%;
			border-radius: 0;
			color: $dark-text;
			cursor: default;

			&:hover, &:active {
				background-color: $revfluence-blue;
				color: white;
			}
		}

		a {
			padding: .25em 1em;
			display: block;
			color: $dark-text;
			font-size: .85rem;
			border: none;
			line-height: 1.7em;
			font-weight: 300;
			text-decoration: none;
			cursor: default;

			&:hover, &:active, &:focus {
				color: white;
				background-color: $revfluence-blue;
			}

			&.active {
				color: white;
				background-color: $revfluence-blue;
			}
		}

		.active {
			/* font-weight: 600; */
			/* color: $dark-text; */
		}
	}

	.selected_item {

		a {
			color: white;
			background-color: $revfluence-blue;
		}

		.icon_selected {
			width: 13px;
			margin-right: .3rem;
		}
	}
}

.dropdown_list_top {

	&:before, &:after {
		border-width: 12px 12px 0;
		top: initial;
	}

	&:after {
		bottom: -11px;
	}

	&:before {
		bottom: -12px;
	}
}

.dropdowns_container {

	.dropdown_button {
		position: relative;

		&:hover {

			.dropdown_list {
				display: block;
			}
		}
	}

	.inner_dropdown_btn {
		background: none;
		font-weight: 300;

		&:hover {
			background-color: $revfluence-blue;
			color: white;
		}
	}

	.button_text {
		display: inline-block;
		vertical-align: -1px;
		margin-right: .5em;
	}

	.dropdown_indicator {
		display: inline-block;
		width: 10px;
		height: 10px;
		background: url('/images/style_guide/dropdown_indicator_icon.svg') center no-repeat;
	}
}

/* AUTOCOMPLETE */
.autocomplete {

	.suggestion-list {
		background-color: white;
		background-color: rgba(255,255,255,.97);
		box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.07);
		border-radius: 6px;
		border: #E2E2E2 solid 1px;
		padding: .5em 0;

		/* Use left: property to change position of tail */
		&:after {
			content: '';
			position: absolute;
			border-style: solid;
			border-width: 0 12px 10px;
			border-color: #FFFFFF transparent;
			display: block;
			width: 0;
			z-index: 1;
			top: -9px;
			left: 112px;
		}

		/* Use left: property to change position of tail */
		&:before {
			content: '';
			position: absolute;
			border-style: solid;
			border-width: 0 12px 10px;
			border-color: #E2E2E2 transparent;
			display: block;
			width: 0;
			z-index: 0;
			top: -10px;
			left: 112px;
		}

		.suggestion-item {
			padding: .25rem;
		}

		.selected {
			background-color: $revfluence-blue;
			color: white;
		}

		em {
			font-style: normal;
		}
	}
}

/* PLACEHOLDER HTML FOR FAV BUTTON
<div class="favBtn_dropdown_container dropdown_container">
	<button type="button" class="favBtn dropdown_btn btn no_shadow_focus">
		<img src="/images/style_guide/icon_star_outline_white.svg" class="icon_fav">
		<img src="/images/style_guide/icon_star_outlineHover_white.svg" class="icon_fav_hover icon_fav">
		<img src="/images/style_guide/icon_star_colorOutline.svg" class="icon_fav_active icon_fav">
		<img src="/images/style_guide/icon_star_outline_dark.svg" class="darkIcon_fav icon_fav">
		<img src="/images/style_guide/icon_star_dark_colorOutline.svg" class="darkIcon_fav_hover darkIcon_fav icon_fav">
	</button>
	<div class="favDropdown_list dropdown_list">
		<div class="addWrapper" style="display:none;">
			<span class="favDropdown_title default_body_copy">Add to Your Favorites</span>
			<ul class="favDropdown_list_list">
				<li class="dropdown_li"><a href title class="truncate">SD Creators</a></li>
				<li class="dropdown_li"><a href title class="addNew_link">
					<img src="/images/style_guide/icon_plus_black.svg" class="icon_addNew">
					<img src="/images/style_guide/icon_plus_white.svg" class="icon_addNew_hover icon_addNew">
					New List&hellip;
				</a></li>
			</ul>
		</div>
		<div class="addNew_fav_wrapper">
			<div class="addNew_fav_header">
				<button type="button" class="addNew_back_btn btn no_shadow_focus"><img src="/images/style_guide/icon_back_black.svg" class="icon_back"></button>
				<span class="addNew_title default_body_copy">Add to New Favorites List</span>
			</div>
			<input type="text" class="form_control" placeholder="Name your list&hellip;">
			<button type="button" class="btn_primary btn">Add</button>
		</div>
	</div>
</div>
*/
.favBtn_dropdown_container {

	.favBtn {
		background: none;
		padding: 14px;
		border: none;

		.darkIcon_fav {
			display: none;
		}

		.icon_fav {
			width: 20px;
			height: 18px
		}

		.icon_fav_hover, .icon_fav_active {
			display: none;
		}

		&:hover {

			.icon_fav {
				display: none;
			}

			.darIcon_fav {
				display: none;
			}

			.icon_fav_hover {
				display: block;
			}
		}

		/*sad times &:active, &:focus {
			background: none;

			.icon_fav {
				display: none;
			}

			.darkIcon_fav {
				display: none;
			}

			.icon_fav_active {
				display: block;
			}
		}*/
	}

	.dark_favBtn {

		.icon_fav {
			display: none;
		}

		.darkIcon_fav {
			display: block;
		}

		.darkIcon_fav_hover, .darkIcon_fav_active {
			display: none;
		}

		&:hover {

			.icon_fav {
				display: none;
			}

			.darkIcon_fav {
				display: none;
			}

			.darkIcon_fav_hover {
				display: block;
			}
		}

		/* sad times &:active, &:focus {
			background: none;

			.icon_fav {
				display: none;
			}

			.darkIcon_fav {
				display: none;
			}

			.icon_fav_active {
				display: none;
			}

			.darkIcon_fav_active {
				display: block;
			}
		}
		*/
	}

	.favDropdown_list {
		top: 26px;
		left: 3px;
		width: 275px;

		&:before, &:after {
			left: 8px;
		}

		.icon_fav_hover, .icon_fav_active {
			display: none;
		}

		.favBtn_dropdown_container {

			.dropdown_btn {
				background: none;
				padding: 10px 14px 10px;
				margin-bottom: 0;
				max-width: inherit;
				border: none;

				&:hover {
					background: none;
				}
			}
		}

		.favDropdown_title {
			display: block;
			text-align: center;
			font-weight: 600;
			padding: .25rem .25rem .35rem;
		}

		li {

			a {
				padding: .25em 1em;
				display: block;
				color: $dark-text;
				font-size: 1.5em;
				border: none;
				line-height: 1.7em;
				font-weight: 300;
				text-decoration: none;
				cursor: default;

				&:hover, &:active, &:focus {
					color: white;
					background-color: $revfluence-blue;
				}

				&.active {
					color: white;
					background-color: $revfluence-blue;
				}
			}
		}

		.favDropdown_list_list {
			max-height: 200px;
			overflow: auto;
			scrollbar-face-color: #AFAFAF;
			scrollbar-track-color: none;

			::-webkit-scrollbar {
				width:  6px;
				display: block !important;
			}

			::-webkit-scrollbar-thumb {
				background: #D8D8D8;
				border-radius: 100px;
			}

			::-webkit-scrollbar-track {
				background: none;
			}
		}

		.addNew_link {
			border-top: 1px solid $lightgray_border;
			margin-top: 6px;
			padding: .4rem .9rem .3rem;

			.icon_addNew {
				width: 22px;
				margin-right: .25rem;
				vertical-align: -5px;
			}
		}

		.addNew_fav_wrapper {
			padding: 0 .75rem;

			.addNew_fav_header {
				padding: .3rem 0 .65rem;
			}

			.addNew_back_btn {
				padding: .15rem;
				display: inline-block;
				background: none;
				border-radius: 3px;
			}

			.icon_back {
				width: 17px;
			}

			.addNew_title {
				font-weight: 600;
				display: inline-block;
				padding: 0 .15rem;
				vertical-align: -3px;
			}

			.form_control {
				margin-bottom: .65rem;
			}

			.btn_primary {
				margin-bottom: .5rem;
			}
		}
	}
}
