.off_platform_auth {
  max-width: 1500px;
  width: 100%;
  margin: auto;

  .off_platform_insights_auth_hero {
    width: 1505px;
    overflow: visible;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;

    .hero_image_container {
      display: inline-block;

      .hero_image {
        height: 300px;
        width: 300px;
      }
    }

    .client_logo_container {
      top: 134px;
      margin: auto;
      left: 0;
      right: 0;
      .client_logo {
        height: 84px;
        width: 84px;
        border: 3px solid #fdfdfd;
        border-radius: 8px;
        position: absolute;
        background: white;
        top: 280px;
        margin-left: -42px;
      }
    }
  }

  .add_aud_demographics {
    max-width: 725px;
    margin: auto;
    padding-top: 380px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;

    .social_account_list {
      .social_account_name {
        font-size: 0.8em;
        font-weight: bold;
      }
      .network_icon {
        width: 15px;
        height: 15px;
        display: inline-block;
        margin-left: 0.5em;
        margin-right: 0.3em;
        vertical-align: -3px;
      }
    }

    .alert_notice {
      background: #fff1f0;
      border: 1px solid #ffa39e;
      box-sizing: border-box;
      border-radius: 6px;
      margin-bottom: 20px;
    }

    .default_notice {
      background: #e6f7ff;
      border: 1px solid #91d5ff;
      box-sizing: border-box;
      border-radius: 6px;
      margin-bottom: 20px;
    }

    .notice {
      padding: 16px;
      font-size: 0.9rem;

      .info_icon {
        display: inline-block;
        margin-right: 0.5em;
        height: 16px;
        vertical-align: -3px;
      }

      .header {
        font-weight: bold;
        margin-bottom: 8px;
      }

      .notice_body {
        .notice_p {
          margin-top: 4px;
          margin-left: 1.8em;
        }

        .notice_link {
          color: $aiq_darkblue;
          text-decoration: none;

          &:hover,
          &:focus {
            color: $aiq_darkblue_hover;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
