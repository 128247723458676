.import_content_template {

  .modal-dialog {
    padding: 1rem 2rem 2rem;
  }

  .btn_close_modal {
    position: absolute;
    right: 0;
    top: .75rem;
  }

  .main_header {}

  .main_title {
    font-size: 1.85rem;
    padding: 1rem 0 1.5rem;
  }

  .main_body {}

  .selectTemp_wrap {

    .icon_template_dropdown {
      display: inline-block;
      margin-right: .25rem;
      vertical-align: 0;
      width: 15px;
    }

    .dropdown_btn2 {

      .icon_ig {}

      .icon_yt {}

      .icon_flag {}

      .btn_txt {
        display: inline-block;
        font-weight: 600;
        vertical-align: -4px;
      }

      .icon_template_dropdown {
        vertical-align: 2px;
      }

      .icon_campaign {
        vertical-align: -1px;
      }
    }

    .dropdown_list {
      width: 536px;
      left: 0;
      top: 38px;

      &:before, &:after {
        left: 506px;

        @include media($break600) {
          left: 8px;
        }
      }

      @include media($break600) {
        width: 100%;
      }

      .icon_template_dropdown {
        vertical-align: -1px;
      }
    }

    .actual_list {
      max-height: 250px;
      overflow-y: auto;

      ::-webkit-scrollbar {
          width:  6px;
      }

      ::-webkit-scrollbar-thumb {
          background: #red;
          border-radius: 100px;
      }

      ::-webkit-scrollbar-track {
          background: none;
      }
    }

    .dropdown_li {}

    .icon_ig {}

    .icon_yt {}

    .icon_campaign {}

    .icon_dropdown {
      width: 14px;
    }
  }

  .sampleTemp_wrap {
    background-color: #F6F6F6;
    border-radius: 4px;
    position: relative;
    height: 345px;
    overflow: scroll;
    border: 1px solid $lightgray_border;

    .emptyPlaceholder_txt {
      position: absolute;
      top: 50%;
      margin-top: -25px;
      left: 50%;
      margin-left: -138px;
      color: #8B8B8B;

      @include media($break425) {
        margin-top: 0;
        left: auto;
        position: relative;
        margin-left: 0;
        top: auto;
        padding: 1rem;
      }
    }

    .sampleTemp_list {}

    .sampleTemp_item {
      background-color: white;
      border-bottom: 1px solid $lightgray_border;
      padding: 1rem;
      position: relative;

      &:hover {
        background-color: $ultraLight_gray;
      }

      &:first-child {
        border-radius: 4px 4px 0 0;
      }
    }

    .sampleTemp_title {
      font-weight: 600;
    }

    .sampleTemp_main_icon {
      margin-right: .65rem;
      margin-top: 5px;
    }

    .sampleTemp_main_icon, .txt_wrap {
      float: left;
    }

    .txt_wrap {
      width: calc(100% - 126px);

      @include media($break375) {
        width: calc(100% - 31px);
      }
    }

    .sampleTemp_title, .sampleTemp_purpose, .sampleTemp_descrip {
      display: block;
    }

    .import_btn {
      position: absolute;
      right: 1rem;
      top: 1rem;

      @include media($break375) {
        position: relative;
        right: auto;
        top: auto;
        display: block;
        width: 100%;
        margin-top: .5rem;
        float: left;
      }
    }
  }
}
