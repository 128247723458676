.new_manage {
  .intro_creator_messageBrand {
    .introCreator_title {
      padding-bottom: 16px;
    }

    .txtWrap {
      padding: 0 8px;
    }

    .write_message_btn {
      margin: 16px 0;
    }
  }
}
