.new_manage {

  .buyerProposal {
    padding: 20px 30px 30px !important;

    .new_proposal_actions_container {
      border-top: 1px solid $lightgray-border;
      padding-top: 1rem;
      margin-top: 1.25rem;
    }

    .buyerProposal_header {
      padding-bottom: .5rem;
    }

    .new_proposal_message {
      padding-top: 0;
    }

    .collab_btn {
      margin-right: .5rem;

      @include media($break390) {
        margin-bottom: .5rem;
      }
    }

    .collab_btn, .dismiss_btn {

      @include media($break390) {
        display: block;
        margin-right: 0;
        width: 100%;
      }
    }

    .standard_label {
      font-weight: 600;
      padding-bottom: .5rem;
      margin-bottom: 0;
    }

    .contentGuidelines {

  		.desired_content_title {}

  		> p {}

  		.exampleContent_container {}

  		.exampleContent_list {
  			padding: 12px 0 16px;

  			> li {
  				margin-right: 2%;
  				float: left;
  				width: 31.33%;

  				&:last-child {
  					margin-right: 0;
  				}
  			}
  		}

  		.content_link {
  			display: block;
  		}

  		.ig_content {}

  		.yt_content {}

  		.contentImg {
  			display: block;
  			max-width: 100%;
  			border-radius: 4px;

  			&:hover {
  				opacity: .95;
  			}
  		}

  		.seeExamples_btn {
        padding: 0;
        border: none;
      }

  		.disclosure_icon {
  			margin-left: 8px;
        vertical-align: -1px;
  		}
  	}

    .standard_section {
      padding: .5rem 0;
    }

    .new_proposal_message .budget_range_container, .new_proposal_message .desired_content_container, .new_proposal_message .about_brand_container, .new_proposal_message .inspiration_container {
      margin-bottom: 0;
    }

    .free_product_container {

      .new_proposal_label {
        margin-bottom: 0;
      }
    }

    .inspiration_container {

      .link_list {
        list-style-type: disc;
        list-style-position: inside;
      }
    }

    .budget_range_container {

      .new_proposal_label, .new_proposal_descrip {
        display: inline;
      }

      .new_proposal_descrip {}
    }

    .compensation_options_list {

      > li {
        display: inline-block;
        width: 250px;
        margin: 0 2em 2em 0;
      }
    }

    .desired_content_container {
      padding-bottom: 1rem;
      border-bottom: 1px solid $lightgray-border;
    }

    .whatYouGet_container {
      padding-bottom: .75rem;
      border-bottom: 1px solid $lightgray-border;
    }

    .website_container {

      .new_proposal_label {
        display: inline;
        margin-right: .5rem;
      }
    }

      .exampleContent_view {

        .bestPerforming_label {
      		font-weight: 600;
      		display: block;
      		margin-bottom: .8rem;
      	}

        .otherContent_descrip {
      		padding-bottom: 1rem;
      	}

    		@include media($break400) {
    			padding: .5rem 1rem 3rem;
    		}

    		.exampleContent_header {
    			padding: 1rem;
    			text-align: center;
    		}

    		.main_title {
    			font-size: 2.75em;
    		}

    		.cta_container {
    			margin-bottom: 1rem;
    		}

    		.avatar_img {
    			width: 40px;
    			border-radius: 100px;
    			display: block;
    			margin: 0 auto .75rem;

    			&:hover {
    				opacity: .95;
    			}
    		}

    		.cta_descrip {
    			text-align: center;
    			margin-bottom: 1rem;
    		}

    		.proposeTerms_btn {
    			width: 200px;
    			margin: 0 auto;
    			display: block;
    		}

    		.bestPerforming_content {
    			padding-bottom: 1rem;
    			border-bottom: 1px solid $lightgray-border;
    			margin-bottom: 1.4rem;
    		}

    		.bestPerforming_list {

    			> li {
    				float: left;
    				width: 33%;
    				margin-right: .35%;

    				@include media($break425) {
    					width: 100%;
    					margin-right: 0;
    					float: none;
    					padding-bottom: 1rem;
    					margin-bottom: .5rem;
    				}

    				&:last-child {
    					margin-right: 0;
    				}
    			}

    			.contentImg {
    				padding-bottom: .5rem;
    			}
    		}

    	.contentImg {
    		display: block;
    		max-width: 100%;
    		width: 100%;
    		padding-bottom: .75rem;

    		&:hover {
    			opacity: .95;
    		}
    	}

    	.qualityScore_container {

    		@include media($break725) {
    			padding: 0 .5rem;
    		}
    	}

    	.qualityScore_label {
    		display: block;
    	}

    	.qualityScore_number {
    		display: block;
    	}

    	.otherContent_container {}

    	.intro_container {

    		> p {}
    	}

    	.content_link {
    		display: block;
    	}

    	.standard_label {}

    	.content_list {

    		> li {
    			width: 19.75%;
    			float: left;
    			margin: 0 .25% .25% 0;
    			overflow: hidden;

    			@include media($break750) {
    				width: 24.75%;
    			}

    			@include media($break350) {
    				width: 33%;
    			}
    		}
    	}

    	.contentImg {
    		display: block;
    		max-width: 100%;
    		width: 100%;
    		padding-bottom: 0;
    	}
    }
  }

  .compensation_section {
		margin-bottom: 3em;
    padding-top: .85rem !important;
    border-bottom: 1px solid $lightgray-border;

		.compensation_title {
			font-size: 1.4em;
			color: $dark-text;
			line-height: 1.85em;
			font-weight: 600;
			margin-bottom: .8em;
		}

		.compensation_amount {
			font-size: 3em;
			color: $dark-text;
			font-weight: 200;
			margin-bottom: .35em;
			display: block;
		}

		.compensation_options_list {

			li {
				display: inline-block;
				width: 245px;
				margin: 0 2em 2em 0;
				@include media($break972) {
					display: block;
					margin: 0 0 2em 0;
					width: 100%;
				}
			}

			.checkmark_icon {
				width: 20px;
				height: 20px;
				display: inline-block;
				text-indent: -9999px;
				margin-right: 1em;
				vertical-align: 5px;
			}

			.available {
				background: url('/images/brand_profile/checkmark_icon_color.svg') center no-repeat;
			}

			.unavailable {
				background: url('/images/brand_profile/checkmark_icon_gray.svg') center no-repeat;
			}

			.compensation_text_container {
				display: inline-block;
				width: 210px;
				vertical-align: top;

				@include media($break972) {
					width: calc(100% - 33px);
				}
			}

			.option_title {
				font-size: 1.4em;
				font-weight: 300;
				color: $dark-text;
				display: block;
				margin-bottom: .5em;
				margin-top: 0.2em;
			}

			.option_descrip {
				font-size: 1.2em;
				font-weight: 300;
				color: $dark-text;
				line-height: 1.55em;
				white-space: pre-line;
			}
		}
	}
}
