.reportIssue_modal {
  z-index: 10000;

  .modal-dialog {
    padding: 1.5rem 2rem 2rem;

    @include media($break375) {
      padding: 1rem 1rem 1rem;
    }
  }

  .close_modal_icon {
    width: 14px;
  }

  .reportIssue_modal_header {
    text-align: center;
    padding-bottom: .75rem;
  }

  .reportIssue_title {
    padding-bottom: .25rem;

    @include media($break375) {
      font-size: 1.65rem;
    }
  }

  .reportIssue_descrip {
    font-size: 1.05rem;
  }

  .icon_report_user {
    margin-right: .25rem;
  }

  .reportUser_form {
    max-width: 360px;
    margin: 0 auto;

    .standard_label {
      font-weight: 600;
    }
  }

  fieldset {
    padding-bottom: 1rem;
  }

  .sendReport_btn {
    margin-right: .5rem;
  }

  .doubleArrow_select_container {
    max-width: 230px;
  }

  .doubleArrow_select_btn {
    width: 100%;
    max-width: 230px;
  }

  .dropdown_list {
    width: 190px;

    &:after, &:before {
      left: 159px;
    }

    li {
      margin-bottom: 0;
    }
  }

}
