.case_studies {
	background-color: #fdfdfd;

	.main_header {
		max-width: 1000px;
		margin: 5em auto 0;
		padding: 4em;

		.main_title {
			font-size: 5em;
			color: $dark-text;
			font-weight: 300;
			text-align: center;
			@include media($break385) {
				font-size: 4em;
			}
		}
	}

	.main_content {
		width: 890px;
		margin: 0 auto;
		@include media($break900) {
			width: 95%;
		}

		.case_studies_list {
			overflow: hidden;
			padding: 40px 5px 0;
		}

		.case_study_item {
			float: left;
			width: 398px;
			box-shadow: 1px 1.732px 10px 0px rgba(1, 1, 1, 0.08);
			background-color: white;
			margin: 0 4em 8em 0;
			@include media($break900) {
				width: 45%;
				margin: 0 5% 7% 0;
			}
			@include media($break625) {
				width: 100%;
				margin: 0 0 60px 0;
			}
		}

		.brand_logo {
			width: 75px;
			height: 75px;
			border: 2px solid white;
			box-shadow: 0px 2px 3px 0px rgba(1, 1, 1, 0.35);
			border-radius: 100px;
			margin: -3.9em auto 0;
			display: block;
		}

		.case_study_summary {
			font-size: 1.5em;
			color: $dark-text;
			font-weight: 300;
			line-height: 1.6em;
			padding: 1.5em 2.5em;

			a {
				color: $revfluence-blue;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.start_campaign_button {
			border: 1px solid $revfluence-blue;
			border-radius: 45px;
			width: 160px;
			margin: 0 auto;
			color: $revfluence-blue;
			display: block;
			text-align: center;
			text-decoration: none;
			padding: .75em 0;
			font-size: 1.5em;
			margin-bottom: 2em;
			&:hover {
				border: 1px solid $revfluence-blue-hover;
			}
		}
	}

	.closing_cta_container {
		max-width: 1000px;
		margin: 0 auto;
		padding: 0 0 5em;

		.cta_title {
			font-weight: 300;
			color: $dark-text;
			text-align: center;
			line-height: 1.5em;
			margin-bottom: 1em;
			font-size: 2.25em;
		}

		.cta_descrip {
			font-size: 1.5em;
			color: #201e1e;
			font-weight: 300;
			line-height: 1.6em;
			text-align: center;
			margin-bottom: 2em;
		}

		.get_started_button {
			background-color: $revfluence-blue;
			border-radius: 45px;
			width: 160px;
			margin: 0 auto 2.4em;
			color: white;
			display: block;
			text-align: center;
			text-decoration: none;
			padding: .85em;
			font-size: 1.5em;
			&:hover {
				background-color: $revfluence-blue-hover;
			}
		}

		.see_case_study {
			font-size: 1.5em;
			line-height: 1.5em;
			color: #988d90;
			font-weight: 300;
			display: block;
			text-align: center;

			a {
				color: $revfluence-blue;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}