.confirm_unsubscribe {

	.backdrop-close-link {
		display: none;
	}

	.modal-dialog {
		padding: 50px;
		margin: 40px auto;

		@include media($break635) {
			width: 95%;
		}

		@include media($break575) {
			padding: 35px;
		}
	}

	.main_header {
		margin-bottom: 25px;
		text-align: center;
	}

	p {
		margin-bottom: 25px;
		text-align: center;
	}

	.actions_container {
		text-align: center;
	}

	.btn_primary {
		margin-bottom: 15px;

		@include media($break345) {
			width: 100%;
		}
	}

	.btn_primary, .btn_link {

		@include media($break345) {
			width: 100%;
			display: block;
			white-space: initial;
		}
	}
}