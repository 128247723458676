.creator_profile {

  .creator_profile_nav {
    border-bottom: 1px solid $lightgray-border;
    margin-bottom: 1.3rem;

    @include media($break875) {
      display:none;
      margin-bottom: 0;
      border-bottom: none;
    }

    .nav_links_list {

      @include media($break475) {
        text-align: center;
      }

      &.three_items {
        .nav_links_item {
          @include media($break475) {
            width: 33%;
          }
        }
      }

      .nav_links_item {
        display: inline-block;
        margin-left: -4px;

        @include media($break475) {
          float: left;
          margin-left: 0;
          width: 25%;
        }

        &:first-child {
          margin-left: 0;
        }
      }

      .nav_link {
        color: $dark-text;
        text-decoration: none;
        padding: .74rem 1rem .94rem;
        display: inline-block;
        border-bottom: 1px solid $lightgray-border;

        @include media($break875) {
          border-bottom: none;
        }

        &:hover {
          background-color: $lightgray_bg2;
          border-bottom: 1px solid $revfluence-blue;

          @include media($break875) {
            border-bottom: none;
          }
        }

        @include media($break875) {
          border-bottom: none;
        }

        @include media($break400) {
          font-size: .8rem;
          padding: .5rem .75rem .6rem;
        }
      }

      .active_link {
        font-weight: 600;
        border-bottom: 1px solid $revfluence-blue;
        background-color: $lightgray_bg2;

        @include media($break875) {
          border-bottom: 2px solid $revfluence-blue;
        }

        &:hover {
          color: $dark-text;

          @include media($break875) {
            border-bottom: 2px solid $revfluence-blue;
          }
        }
      }
    }
  }

  .mobile_profile_nav {
    display:none;
    margin-bottom: 0;

    @include  media($break875) {
      display:block;
    }

    @include media($break475) {
      border-bottom: none;
    }

    .nav_links_list {

      @include media($break475) {
        text-align: center;
      }

      &.three_items {
        .nav_links_item {
          @include media($break475) {
            width: 33%;
          }
        }
      }

      .nav_links_item {

        @include media($break475) {
          float: left;
          margin-left: 0;
          width: 25%;
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .nav_link {

      @include media($break475) {
        display: block !important;
      }

      @include media($break340) {
        padding: .5rem .5rem .6rem;
        font-size: .8em;
      }
    }
  }

  
}
