.structured_date_picker {
	height: initial !important;
}

.mention_price_input, .dedicated_price_input, .instagram_price_input {
	height: initial !important;
}

.structured_response {
	position: relative;

	.form_error {
		margin-left: 2.0em;
		margin-top: 1em;
	}

	.date_picker {
		height: intitial !important;
	}

	.checkmark_icon {
		display: inline-block;
		background: url('/images/brand_profile/checkmark_icon_color.svg') center no-repeat;
		width: 20px;
		height: 20px;
		margin-right: 1em;
		vertical-align: -4px;
	}

	.profile_subtitle {
		margin-bottom: 10px;
	}

	td {
		font-size: inherit;
	}

	h6 {
		margin-bottom: 10px;
	}

	.proposalCD_notice {
		max-width: 1200px;
		margin: 0 auto 2rem;

		@include media($break720) {
			margin: 2rem auto 0;
		}
	}

	.intro_container {
		text-align: center;
		margin-bottom: 1.5rem;

		@include media($break1075) {
			margin-bottom: 3em;
		}

		@include media($break800) {
			text-align: center;
			margin-bottom: 0;
		}

		.inner_container {
			position: relative;
			max-width: 1200px;
			margin: 0 auto;
			padding: 70px 4rem 23px;
			position: relative;
			border-bottom: 1px solid $lightgray-border;

			@include media($break800) {
				padding: 70px 1rem 0;
				border-bottom: none;
			}
		}

		.back_link {
			position: absolute;
			left: 1rem;
			top: 5.5rem;

			@include media($break800) {
				left: 20px;
				top: 4.5rem;
			}

			.back_icon {
				width: 28px;
			}
		}

		.intro_title_container {
			margin-bottom: 1em;

			@include media($break625) {
				margin-bottom: 0;
			}
		}

		.collab_icon_container {
			display: inline-block;
			margin-right: .75rem;
			position: relative;

			@include media($break800) {
				display: block;
				width: 48px;
				margin: 0 auto;
			}
		}

		.brand_logo {
			border-radius: 100px;
			width: 55px;

			@include media($break800) {
				width: 45px;
			}
		}

		.send_proposal_icon {
			width: 29px;
			position: absolute;
			top: -2px;
			right: -7px;

			@include media($break800) {
				width: 32px;
				top: -5px;
				right: -13px;
			}
		}

		.intro_title {
			display: inline-block;
			vertical-align: 12px;

			@include media($break800) {
				font-size: 3.4em;
				display: block;
			}

			@include media($break630) {
				font-size: 2.85em;
			}
		}

		.intro_descrip {
			@include fontsize15;
			color: $dark-text;
		}
	}

	.main_container {
		padding-bottom: 2rem;

		@include media($break800) {
			padding: 0 0 2rem;
		}

		@include media($break545) {
			padding: 0 0 2rem;
			max-width: 100%;
			margin: 0;
		}
	}

	.intro_message {
		padding: 0 3em;
		@include media($break500) {
			padding: 0 1.5em;
		}
	}

	.account_pricing_section {

		.section_subtitle {
			font-weight: 300;
		}
	}

	.disabled_accountContainer {
		opacity: .45;
	}

	.audInsights_wrap {
		padding-top: .35rem;

		.audInsights_title {
			font-weight: 600;
			font-style: italic;
			display: block;
		}

		.audInsights_link {
			padding: .25rem 0;
			display: inline-block;
		}

		.icon_chevron {
			width: 7px;
			vertical-align: -1px;
			margin-left: .25rem;
		}
	}

	.collab_details {

		.campaign_article {
			margin-top: 1.0em;
			width: 100%;
		}

		@include span-columns(5);
		@include shift(2);
		@include pad(3em 0 0 0);

		@include media($break1075) {
			@include span-columns(6);
			@include shift(0);
		}

		@include media($break795) {
			@include shift(0);
			@include pad(3em);
			@include span-columns(16);
			margin-bottom: 3em;
			background-color: white;
			border: 1px solid $lightgray-border;
		}

		@include media($break545) {
			display: none;
		}

		.collab_header {
			overflow: hidden;
			@include media($break795) {
				margin-bottom: 0;
			}
		}

		.brand_avatar {
			width: 50px;
			height: 50px;
			display: inline-block;
			@include circle-radius;
			margin-right: 1em;
			vertical-align: -37px;

			@include media($break415) {
				width: 35px;
				height: 35px;
			}
		}

		.collab_title {
			@include title20;
			color: $dark-text;
			display: inline-block;
			font-weight: 400;
			vertical-align: -1em;
			@include media($break545) {
				font-weight: 500;
			}

			@include media($break415) {
				@include fontsize15;
				font-weight: 600;
			}
		}

		.toggle-details-button {
			display: none;
			float: right;

			@include media($break795) {
				display: block;
			}

			@include media($break335) {
				float: none;
				width: 100%;
				margin-top: 1em;
			}

		}
	}

	.templates_selection {
		margin-bottom: 1rem;

		.templates_label {
			margin-bottom: .5rem;
			display: block;
		}

		.templates_label {}

		.choose_template_btn {
			padding: 0;

			&:focus, &:active {
				box-shadow: none;
			}
		}
	}

	.proposal_container {
		background-color: white;
		border: 1px solid $lightgray-border;
		border-radius: 12px;
		padding-bottom: 2.0em;
		padding-top: 1rem;

		@include media($break800) {
			box-shadow: none;
			border: none;
			padding-bottom: 0;
		}
	}

	.creator_response {
		padding-bottom: 2rem;
		margin: 0 auto;
		max-width: 600px;

		@include media($break800) {
			max-width: 100%;
			width: 100%;
		}

		&.no_campaign_details {
			float: none;
			margin-left: auto;
			margin-right: auto;
		}

		.form_error {
			text-align: center;
			position: relative;
			display: block;
			margin: 1em 1em 0 1em;
		}

		.response_header {
			margin-bottom: 1.5em;
			border-bottom: 1px solid $lightgray-border;
			padding: 0rem 3em 1rem;

			@include media($break500) {
				padding: 1.5em;
			}
		}

		.standard-input-error {
			@include standard-input-error;
		}

		.standard-error-message {
			background-color: $aiq_dangerRed;
			color: $aiq_offwhite;
			border-radius: 5px;
			padding: .25em 1em;
			font-size: .8rem;
			line-height: 1.7em;
			font-weight: 600;
		}

		.campaignLimit_reached_error {
			margin-top: .75rem;
		}

		.main_title {
			float: left;
			border-bottom: none;
			width: calc(100% - 50px);
			font-size: 2.4em;
			margin-top: .2rem;

			@include media($break545) {
				font-weight: 500;
			}

			@include media($break415) {
				@include fontsize15;
				font-size: 1.85em;
				font-weight: 600;
			}
		}

		.section_title {
			font-weight: 600;
			font-size: 1.15rem;
			padding: 0 1rem;
		}

		.shippingAddress_title {
			padding: 0;
		}

		.section_subtitle {
			@include fontsize16;
			color: $dark-text;
			@include standard-bold;
			letter-spacing: -0.01em;
			display: block;
		}

		.standard_label {
			@include standard_label;
			@include media($break400) {
				@include fontsize15;
			}
		}

		.descriptive_text {
			@include fontsize16;
			margin-bottom: 0.8em;
			color: $lightgray-text;
		}

		.creator_avatar {
			float: left;
			width: 40px;
			height: 40px;
			@include circle-radius;
			margin-right: 1em;

			@include media($break415) {
				width: 35px;
				height: 35px;
			}

			@include media($break325) {
				display: none;
			}
		}

		.actions_container {
			padding: 0 1rem;
		}

		.send_button {
			display: inline-block;
			width: calc(100% - 1px);

			@include media($break800) {
				display: block;
				width: 110px;
			}

			@include media($break600) {
				display: block;
				width: calc(100% - 1px);
			}
		}

		.message_form {}

		.section_container {
			margin-bottom: 2em;
		}

		.subsection_container {
			padding: .65rem 1rem .65rem;
			cursor: pointer;
		}

		.currency_label {
			@include fontsize18;
			color: $dark-text;
			margin-left: 0.5em;
		}

		input[type="text"], textarea {

			&.standard-input-error {
				@include standard-input-error;
			}
			&.small_input {
				width: 80px;
			}
			&.medium_input {
				width: 160px;
			}
		}

		textarea {
			height: 150px;
			font-weight: 400;
			@include fontsize16;

			@include media($break795) {
				height: 130px;
			}

			@include media($break400) {
				@include fontsize15;
			}
		}

		.account_group {
			margin: 0 1rem;
		}

		.account_selection_container {

			&:hover {
				background-color: #FAFAFA;

				.account_name {
					color: $revfluence-blue;
				}
			}

			&:active {
				background-color: #fff;
				color: $dark-text;
			}

			.account_container {
				max-height: 2em;
				-webkit-transition: all .5s ease;
				-moz-transition: all .5s ease;
				-o-transition: all .5s ease;
				transition: all .5s ease;
				cursor: pointer;

				&.hidden {
					max-height: 0em;
					margin: 0;
				}
			}

			.subsection_container {
				margin-bottom: 2em;

				@include media($break800) {
					padding: 0 0 1em;
				}
			}

			.section_subtitle {
				font-weight: 300;
			}

			.accounts_list {
				list-style: none;
				opacity: 1;
				@include standard-radius;

				li {
					margin-bottom: 1em;
					cursor: pointer;
					opacity: 1.0;
				}

				.account_name {
					display: inline-block;
					@include fontsize16;
					color: $dark-text;
					@include standard-bold;
					line-height: 1.3em;
					vertical-align: 1px;
					font-weight: 300;

					&:hover {
						color: $revfluence-blue;
					}
				}

				.network_icon {
					display: inline-block;
					margin-right: .75em;
					vertical-align: -.05em;
					width: 15px;
					height: 15px;
				}
			}
		}

		.price_label_container {
			display: block;
		}

		.fixed_price {
			font-size: 1.6em;
			font-weight: 400;
		}

		.price_label {
			@include standard-label;
			display: inline-block;
			position: relative;

			.offer_amount {
				font-weight: 700;
			}

			@include media($break321) {
				@include fontsize13;
			}
		}

		.no_payment_label {
			font-size: 1.8em;
		}

		.creator_prices_container {
			background: #ededed url("/images/structured_response/chat_bubble_tail.svg") 50px -3px no-repeat;
			background-size: 5%;
			padding: 2em 0em;
			margin-bottom: 2.0em;
			-webkit-transition: all .5s ease;
			-moz-transition: all .5s ease;
			-o-transition: all .5s ease;
			transition: all .5s ease;

			&.active {
				max-height: 70em;
				padding: 2em 0 .65em;
			}

			.tooltip_hint {
				display: inline-block;
				position: relative;
				width: 15px;
				height: 15px;
				background: url('../images/structured_response/tooltip_hint.svg') center no-repeat;
				cursor: pointer;
				vertical-align: -.1em;
				margin-left: .05em;
			}

			.price_description_tooltip {
				position: absolute;
				width: 250px;
				padding: .5em;
				background: #FFFFFF;
				-webkit-border-radius: 4px;
				-moz-border-radius: 4px;
				border-radius: 4px;
				border: #e8e8e8 solid 1px;
				@include fontsize14;
				line-height: 1.5em;
				padding: .75em 1em;
				left: 2.5em;
				top: -1.3em;
			}

			.price_description_tooltip:after {
				content: '';
				position: absolute;
				border-style: solid;
				border-width: 9px 13px 9px 0;
				border-color: transparent #FFFFFF;
				display: block;
				width: 0;
				z-index: 1;
				left: -13px;
				top: 17px;
			}

			.price_description_tooltip:before {
				content: '';
				position: absolute;
				border-style: solid;
				border-width: 9px 13px 9px 0;
				border-color: transparent #e8e8e8;
				display: block;
				width: 0;
				z-index: 0;
				left: -14px;
				top: 17px;
			}

			input[type="text"] {
				margin-right: .5em;
			}

			.creator_prices_title {
				@include fontsize16;
				@include standard-bold;
				color: $dark-text;
				margin-bottom: 1em;

				i {
					font-weight: 300;
				}
			}

			strong {
				@include standard-bold;
			}

			.descriptive_text {
				color: $dark-text;
			}



			.section_subtitle {
				color: $dark-text;
			}

			.dedicated_video_fieldset {
				margin-bottom: 2em;
				border-bottom: 1px solid white;
				padding-bottom: 2em;

				.small_input {
					background-color: white;
					border: 1px solid white;
				}
			}

			.col1 {
				display: inline-block;
				margin-right: .5em;
				vertical-align: top;

				input[type="checkbox"] {
					margin-top: .65em;
					display: block;
				}
			}

			.col2 {
				display: inline-block;
			}

			.disabled {
				opacity: .5;
			}

			.mention_video_fieldset {

				.subtext {
					margin-bottom: 1em;
				}

				.small_input {
					background-color: white;
					border: 1px solid white;
				}
			}

			.instagram_post_fieldset {
				.subtext {
					margin-bottom: 1em;
				}

				.small_input {
					background-color: white;
					border: 1px solid white;
				}
			}

			.creator_prices_descrip {
				@include fontsize16;
				color: $dark-text;
			}

			input[type="text"] {
				@include standard-input;
				width: 200px;
				background-color: white;
			}

			.subtext {
				@include fontsize14;
				color: $dark-text;
				margin-top: .5em;
			}

			.opt_out_text {
				@include fontsize15;
				opacity: 0.8;
			}

			.opt_out_link {
				@include blue-link;
				@include standard-radius;
				display: inline-block;
			}

			.opt_in_link {
				@include blue-link;
				@include fontsize15;
				display: none;
				margin-top: 0.5em;
				@include standard-radius;
			}

			.price_range {
				clear: both;
				@include fontsize15;
				margin-top: 0.5em;
			}

			.preferred_price_range {
				clear: both;
				@include fontsize15;
			}
		}

		.attachment_field {
			margin-bottom: 1.5em;

			.add_new_address {
				color: $dark-text;
				padding-bottom: .5rem;
			}

			input[type="checkbox"] {
				display: inline-block;
				margin-right: .5em;
				vertical-align: 2px;
				width: 20px;
			}

			.address_label {
				display: inline-block;
				width: 89%;
				@include media($break365) {
					width: 88%;
				}
				@include media($break340) {
					width: 87%;
				}
				@include media($break318) {
					width: 86%;
				}
			}

			.edit-label {
				@include blue-link;
				cursor: pointer;
				@include standard-bold;
			}

			.text_container {
				display: inline-block;
				width: 89%;
				@include fontsize14;

				.info {
					white-space: pre-line;
				}
			}
		}

		.guidelines {
			@include fontsize14;
			color: $lightgray-text;
			padding: 25px 0;
			margin: 0px 30px;

			a {
				@include blue-link;
			}
		}
	}

	.address_container {
		padding: 0 1rem;

		.row {
			padding-bottom: .5rem;
		}

		.city_fieldset, .state_fieldset {
			width: 48%;
			float: left;
		}

		.city_fieldset {
			margin-right: 3%;
			width: 48%;
		}

		.country_fieldset {
			max-width: 270px;

			@include media($break425) {
				max-width: 100%;
			}
		}
	}

	.data_warning_container {
		padding: 0 1rem;
	}

	.campaignQuirks_container {

		.campaignQuirks_title {
			font-weight: 600;
    	font-size: 1.15rem;
    	line-height: 1.5em;
		}
	}
}
