.edit_contentTags_modal {

  .modal-dialog {
    padding: 1rem 2rem 2rem;

    @include media($break535) {
      width: 95%;
    }
  }

  .close_modal_btn {
    padding: 10px;
    position: absolute;
    right: .25rem;
    top: .25rem;
    cursor: pointer;

    &:hover {
      background-color: $hoverBtn_bg
    }
  }

  .remove_modal_icon {
    width: 15px;
  }

  .contentTags_title, .contentTags_descrip {
    text-align: center;
    font-size: 2rem;
  }

  .contentTags_title {}

  .error {
    margin-bottom: .5rem;

    a {
      color: white;
    }
  }

  .contentTags_descrip {
    font-size: 1.15rem;
    padding-bottom: 1rem;
  }

  .tagInput_container {
    float: left;
    width: calc(100% - 85px);
    margin-right: .5rem;

    @include media($break535) {
      float: none;
      width: 100%;
      margin: 0 0 .5rem 0;
    }

    .tag-item {
      span {
        text-transform: capitalize;
      }
    }
  }

  .tag_input {
    font-size: 1rem;
  }

  .saveContent_tags_btn {
    font-size: 1rem;
    border-radius: 6px;
    float: left;
    padding: 9px 20px;
    margin-top: 7px;

    @include media($break535) {
      float: none;
      width: 100%;
    }
  }

  .edit_note {
    padding-top: .75rem;
    font-size: .8rem;
    line-height: 1.05rem;
  }
}
