.aspireiq_theme {

  .my_campaign_main {

    .campaign_nav {

      .activeBrand_li {

        .brand_selection_link {
          color: $aiq_darkblue !important;
          background: $aiq_lightblue_bg url("/images/style_guide/icon_checkmark_dark.svg") 95% center no-repeat;
          background-size: 16px;

          &:hover {
            color: $aiq_darkblue !important;
            background: $aiq_lightblue_bg url("/images/style_guide/icon_checkmark_dark.svg") 95% center no-repeat !important;
            background-size: 16px !important;
          }

          .brand_name_txt {
            color: $aiq_darkblue !important;
          }
        }
      }
    }

    .campaign_nav {

      .icon_campaign_status {
        vertical-align: 7px;
      }

      .brand_actions_btn {

        &:hover {
          background: $aiq_lightblue_bg;
        }
      }

      .newCampaign_btn {

        &:hover {

          .newBrand_btn_txt {
            color: $aiq_darkblue;
          }
        }
      }

      .campaigns_btn {

        &:hover {
          background: $aiq_lightblue_bg;
        }
      }

      .active_campaigns_btn {
        background: $aiq_lightblue_bg;

        .campaign_title_txt {
          color: $aiq_darkblue !important;
        }

        .campaignStatus_txt {
          color: $aiq_darkblue !important;
        }
      }
    }

    .main_campaigns_list_container {

      .campaign_title {
        color: $aiq_black;
      }

      .campaign_title_link {

        &:hover {
          color: $aiq_darkblue;
        }
      }

      .primary_actions_container {

        .primary_action_btn {

          &:hover {
            background-color: $aiq_lightblue_bg;
          }
        }
      }
    }
  }

  .companyInfo_header {
    margin-top: 5rem;
  }

  .companyInfo_title {
    font-size: 2rem;
  }

  .basicDetails_form {

    label {
      font-weight: 700;
    }
  }


}
