.start_campaign_success {

	.success {
		margin-bottom: 5em;

		.success_header {
			margin: 3em 0 3em 0;

			.avatar_container {
				position: relative;
				width: 65px;
				margin: 0 auto 2em;
			}

			.checkmark_icon {
				width: 25px;
				height: 25px;
				border-radius: 50px;
				position: absolute;
				bottom: 0;
				right: 0;
			}

			.creator_avatar {
				border: 2px solid white;
				box-shadow: 0px 1px 1px 0px rgba(0, 0, 1, 0.2);
				width: 60px;
				height: 60px;
				display: inline-block;
				margin-right: 1.5em;
				border-radius: 50px;
			}

			.header_title {
				font-size: 2.5em;
				color: $dark-text;
				text-align: center;
				line-height: 1.2em;
			}
		}

		.join_revfluence {
			box-shadow: 0px 1px 30px 0px rgba(0, 0, 1, 0.035);
			border: 1px solid rgb(242, 241, 241);
			max-width: 850px;
			margin: 0 auto;
			background-color: white;

			.join_header {
				margin: 1.5em 0;

				.revfluence_logo {
					background: url('/images/themes/aspireiq/aiq_logo.png') center no-repeat;
					background-size: 100%;
					width: 131px;
					height: 29px;
					display: block;
					text-indent: -9999px;
					margin: 0 auto;
				}
			}

			.main_message {
				background: url('/images/public_offer/main_message_bg.jpg') center top no-repeat;
				background-size: 100%;
				padding-top: 6em;
				margin-bottom: 2em;
				padding-bottom: 8em;
				position: relative;
				@include media($break743) {
					background: #fdfdfd;
				}
				@include media($break500) {
					padding-top: 3em;
					padding-left: 2em;
					padding-right: 2em;
					padding-bottom: 7em;
				}

				.join_title {
					color: $dark-text;
					font-weight: 300;
					font-size: 3.9em;
					text-align: center;
					margin-bottom: .5em;
					@include media($break500) {
						font-size: 3em;
						line-height: 1.2em;
					}
				}

				.why_join {
					color: $dark-text;
					font-weight: 400;
					font-size: 1.6em;
					text-align: center;
					margin-bottom: .75em;
					line-height: 1.4em;
				}

				.signup_speed {
					color: $dark-text;
					font-weight: 300;
					text-align: center;
					font-size: 1.6em;
					line-height: 1.4em;
				}

				.large_button {
					border-radius: 45px;
					border: 1px solid #247fc8;
					color: white;
					font-weight: 300;
					background-color: #3494ee;
					cursor: pointer;
					font-size: 1.6em;
					padding: .75em 1.5em;
					text-decoration: none;
					margin: 0 auto;
					display: block;
					width: 150px;
					text-align: center;
					position: absolute;
					bottom: -20px;
					left: 50%;
					margin-left: -75px;

					&:hover {
						background-color: $revfluence-blue-hover;
					}
				}
			}

			.learn_more {
				border-top: 1px solid #f9f9f9;
				border-bottom: 1px solid #f9f9f9;
				padding: 2em 0;
				margin-bottom: 4em;
				margin-top: 5em;
				@include media($break743) {
					margin-top: 4em;
				}

				.learn_more_link {
					color: #3494ee;
					font-size: 1.5em;
					display: block;
					text-align: center;
					font-weight: 300;
					text-decoration: none;
					line-height: 1.4em;
				}
			}

			.brands_using_rev {
				margin-bottom: 3em;

				.brand_quantity {
					font-size: 1.5em;
					color: $dark-text;
					font-weight: 300;
					margin-bottom: 1em;
					text-align: center;
				}

				.logo_list {
					text-align: center;

					li {
						display: inline-block;
						margin-right: 2em;
						&:last-child {
							margin-right: 0;
						}
						@include media($break705) {
							margin-bottom: 1em;
						}
					}

					img {
						width: 76px;
						height: 55px;
						@include media($break705) {
							width: 49px;
							height: 36px;
						}
					}
				}
			}
		}


	}
}
