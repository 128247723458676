/* STARTER HTML

<div class="standardTags_container">
  <ul class="standardTags_list">
    <li class="addTag_item standardTags_item">
      <a class="icon_addTag_link">
        <img src="/images/style_guide/icon_plus_black.svg" class="icon_addTag img-loaded">
      </a>
    </li>

    <!--FIRST TAG ITEM-->
    <li class="firstContent_item standardTags_item">Add tags</li>
    <li class="standardTags_item automaticTag">
      hair
      <button type="button" class="remove_tag_btn btn no_shadow_focus">
        <img src="/images/style_guide/icon_close_gray.svg" class="icon_remove_tags img-loaded">
      </button>
    </li>
    <li class="standardTags_item automaticTag">
      red
      <button type="button" class="remove_tag_btn btn no_shadow_focus">
        <img src="/images/style_guide/icon_close_gray.svg" class="icon_remove_tags img-loaded">
      </button>
    </li>
    <li class="standardTags_item automaticTag">
      beauty
      <button type="button" class="remove_tag_btn btn no_shadow_focus">
        <img src="/images/style_guide/icon_close_gray.svg" class="icon_remove_tags img-loaded">
      </button>
    </li>
    <li class="standardTags_item automaticTag">
      hairstyle
      <button type="button" class="remove_tag_btn btn no_shadow_focus">
        <img src="/images/style_guide/icon_close_gray.svg" class="icon_remove_tags img-loaded">
      </button>
    </li>
    <li class="standardTags_item automaticTag">
      photography
      <button type="button" class="remove_tag_btn btn no_shadow_focus">
        <img src="/images/style_guide/icon_close_gray.svg" class="icon_remove_tags img-loaded">
      </button>
    </li>
    <li class="moreTags_item overflowTags_item">
      <button type="button" class="overflowTags_btn btn">
        <img src="/images/style_guide/icon_ellipses_black.svg" class="ellipses_icon img-loaded">
      </button>

      <!-- Overflow Tags Dropdown -->
      <ul class="overflowTags_dropdown dropdown_list_top dropdown_list">
        <li class="standardTags_item automaticTag">
          photography
          <button type="button" class="remove_tag_btn btn no_shadow_focus">
            <img src="/images/style_guide/icon_close_gray.svg" class="icon_remove_tags img-loaded">
          </button>
        </li>
      </ul>
    </li>
  </ul>
</div>

*/

.standardTags_container {

  .standardTags_list {}

  .remove_tag_btn {
    display: none;
    background: url('/images/style_guide/tag_gradient_bg.png') center no-repeat;
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px 4px 4px 8px;
  }

  .remove_tag_icon {
    width: 10px;
  }

  .standardTags_item {
    display: inline-block;
    margin: 0 3px 5px 0;
    background: white;
    padding: 5px 6px;
    font-size: .75rem;
    border: 1px solid $lightgray-border;
    border-radius: 4px;
    font-weight: 300;
    position: relative;
    text-transform: capitalize;
    

    &:last-child {
      margin-right: 0;
    }

    &:hover {

      .remove_tag_btn {
        display: block;
      }
    }

    .icon_addTag {
      width: 12px;
    }

    .addTag_item {
      vertical-align: -2px;
      margin-right: 0;

      .icon_addTag_link {
        padding: 0;
        border: none;
        background: none;
        cursor: pointer;
      }
    }

    .overflowTags_item {
      display: inline-block;
      margin: 0 3px 5px 0;
      position: relative;
    }

    .firstContent_item {
      color: $lightgray_txt;
      border: none;
      background: none;
      font-style: italic;
    }

    .overflowTags_dropdown {
      padding: .5rem;
      top: -46px;
      right: -10px;

      .editTag_btn {

        &:hover {
          color: $dark-text;
          background-color: $ultraLight_gray;
        }
      }
    }

    .moreTags_item {

      &:hover {

        .overflowTags_dropdown {
          display: block !important;
        }
      }
    }

    .overflowTags_btn {
      border-radius: 4px;
      border: 1px solid $lightgray-border;
      font-weight: 300;
      font-size: .75rem;
      padding: 5px 7px;
      vertical-align: 3px;
      height: 24px;
      display: inline-block;
      background: white;
    }

    .automaticTag {
      background: $lightblue;

      .remove_tag_btn {
        background: url('/images/style_guide/tag_gradient_bg_auto.png') center no-repeat;
      }
    }
  }
}

.blue_tag_input {
  background-color: $aiq_lightblue_bg;
  border-radius: 6px;
  padding: 8px;

  &:hover {
    background-color: $aiq_lightblue_bg_hover;
  }

  .blue_tag_list {

    .tag_item {
      background-color: $aiq_darkblue;
      padding: .25rem .5rem;
      border-radius: 100px;
      font-size: .75rem;
      font-weight: 400;
      color: white;
      display: inline-block;

      &:hover, &:focus {
        background-color: $aiq_darkblue_hover;
      }

      &:active {
        background-color: $aiq_darkblue;
      }
    }

    .remove_tag_button {
      border: none;
      padding: 4px;
    }

    .has_input {
      color: $aiq_greyTxt;
      font-size: .75rem;
    }
  }
}