.noFav_wrapper {
  text-align: center;
  margin: 12em auto 0;
  max-width: 650px;
  padding: 2rem 0;
  font-size: .75rem;

  .noFav_title {
    font-weight: 600;
  }

  .noFav_descrip {
    padding-bottom: 1rem;
  }

  .noFav_cta {
    margin-bottom: 1.5rem;
    font-size: 1rem;
  }

  .noFav_csv_cta {
    display: inline-block;
    margin-left: 0.5rem;
    vertical-align: 9px;
  }

  .noFav_visual {
    max-width: 100%;
  }
}
