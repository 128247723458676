.creator_profile {

  .edit_username_modal {

    .modal-dialog {
      padding: 1.5rem 2rem 2rem;
      max-width: 450px;
    }

    .main_title {
      margin-bottom: 1rem;
      text-align: center;
    }

    .username_fieldset {
      margin-bottom: 1rem;
    }

    .standard_label {}

    .useranme_input {}

    .actions_container {
      text-align: center;
    }

    .save_btn {
      margin-right: .5rem;
    }
  }
}
