.confirmDelete_draft_modal {
  text-align: center;

  .modal-dialog {
    padding: 2rem 1.5rem;
    position: relative;

    @include media($break650) {
      width: 90%;
    }

    @include media($break380) {
      width: 95%;
    }
  }

  .close_confirm_btn {
    position: absolute;
    top: .25rem;
    right: .25rem;
    background: none;
    padding: .25rem;
  }

  .confirmDelete_title {
    padding-bottom: .5rem;
  }

  .confirmDelete_descrip {
    padding-bottom: 1rem;
  }

  .actions_container {}

  .confirm_btn {
    margin-right: .25rem;
  }

  .back_btn {}
}
