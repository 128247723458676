.add-new-card {
	border-bottom: 1px solid $lightgray-border;

	.modal-dialog {
		padding: 5em 0;
		width: 750px;
	}

	.modal-header {
		background: white;
		border-bottom: none;
	}

	.close-modal-link {
		position: absolute;
		top: 1em;
		right: 1em;

		&:hover {}
	}

	.modal-title {
		@include title20;
		margin-bottom: .25em;
		font-weight: 200;
		color: $dark-text;
	}

	.add-card-title {
		text-align: center;
		margin-bottom: 2em;
		@include fontsize15;
		color: $dark-text;
		font-weight: 700;
	}

	.revfluence-gaurantee {
		display: block;
		text-align: center;
		@include fontsize15;
		font-weight: 200;
		color: $dark-text;
		margin-bottom: 3em;
	}

	.add-card-form {
		width: 450px;
		margin: 0 auto;
		overflow: auto;

		label {
			display: block;
			@include fontsize15;
			margin-bottom: .4em;
			color: $dark-text;
		}

		input[type="text"] {
			@include light-textInput-medium;
			width: 100%;
		}

		.form-row {
			margin: 1em 0 2em;
			overflow: hidden;
			@include media($break400) {
				margin: 1em 0 1em;
			}
		}

		.card-number {
			display: block;
		}

		.card-number-input {
			display: block;
			@include light-textInput-medium;
			width: 100%;
		}

		.expiration, .year, .cvc {
			float: left;
			width: 32%;

			@include media($break570) {
				width: 100%;
			}
		}

		.expiration, .year {
			margin-right: 1em;

			@include media($break570) {
				margin-right: 0;
			}
		}

		.expiration, .year, .cvc {

			@include media($break570) {
				padding-bottom: .5rem;
			}
		}

		.cvc {}

		.cvc-textInput {
			padding: .53em .75em;
			width: 130px;
			height: 54px;

			@include media($break570) {
				width: 100%;
			}
		}

		.add-card-button {
			display: block;
			width: 125px;
			text-align: center;
			margin: 2em auto 0 auto;
			@include media($break400) {
				float: none;
			}
		}

		.styled-select {
			width: 100%;
			overflow: hidden;
			@include light-textInput-medium;
			float: left;
			background-color: white;

			@include media($break785) {
				width: 100%;
			}

			@include media($break360) {
				width: 100%;
			}
		}

		.styled-select select {
		  width: 100%;
		  border: 0;
		  font-size: 15px;
			cursor: pointer;
		  font-weight: 200;
		  color: $dark-text;
		  line-height: 1.5;
		  -webkit-appearance: none;
		  -moz-appearance: none;
		  text-indent: 0.01px;
		  text-overflow: '';
		  appearance: none;
		  background: transparent url("/images/creator-payments/dropdown-arrow-icon.svg") 87% center no-repeat;
		  outline: none;
		  margin: 0;
		  background-size: 15px;
		}

		.cvc-textInput {
			margin-top: 0;
		}
	}
}
