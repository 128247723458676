.add_creditCard_container {
  background-color: white;
  border: 1px solid $lightgray-border;
  border-radius: 6px;

  .error-container {
    @include errors;
    width: 100%;
  }

  .creditIntroContainer {
    padding: 4em 3em 0;
    margin-bottom: 4em;

    @include media($break960) {
      padding: 4em 5em 0;
    }

    @include media($break519) {
      padding: 4em 0 0;
    }

    .creditIntroTitle {
      @include title25;
      font-weight: 300;
      color: $dark-text;
      text-align: center;
      margin-bottom: .5em;

      @include media($break960) {
        text-align: left;
        margin-bottom: .25em;
      }
    }

    .creditIntroDescrip {
      @include fontsize15;
      color: $dark-text;
      text-align: center;

      @include media($break960) {
        text-align: left;
      }
    }
  }

  .creditCardForm {
    padding: 0 8em;

    #card-element {
      margin-top: 2em;
      margin-bottom: 2em;
    }

    @include media($break1186) {
      padding: 0 7em;
    }

    @include media($break1141) {
      padding: 0 6em;
    }

    @include media($break1095) {
      padding: 0 5em;
    }

    @include media($break960) {
      width: 570px;
    }

    @include media($break615) {
      width: 100%;
    }

    @include media($break519) {
      padding: 0;
    }

    .row {
      margin-bottom: 2em;
    }

    label {
      @include standard-label;
    }
  }

  input[type="text"] {
    @include standard-input;
    width: 100%;
  }

  .small_inlineFieldset {
    width: 31%;
    display: inline-block;
    margin-right: 1em;
    vertical-align: top;

    @include media($break479) {
      width: 30%;
    }

    @include media($break374) {
      width: 100%;
    }
  }

  .medium_inlineFieldset {
    width: 45%;
    display: inline-block;
    margin-right: 1em;
    vertical-align: top;

    @include media($break374) {
      width: 100%;
    }
  }

  .noMarginRight {
    margin-right: 0;
  }

  .widthAdjuster {
    @include media($break960) {
      width: 32% !important;
    }
  }

  .startTrialButton {
    width: 130px;
    margin: 0 auto 3rem;
    display: block;

    @include media($break960) {
      margin-bottom: 2em;
    }
  }
}
