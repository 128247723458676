.update_featured_content {
    .modal-dialog {
        padding: 2rem;
        max-width: 900px;
    }

    .update_avatar_header {
        margin-bottom: 1.25rem;

        .avatar_container {
            width: 60px;
            position: relative;

            .avatar_img {
                height: 60px;
                width: 100%;
            }

            .account_network_icon {
                position: absolute;
                bottom: -2px;
                right: -7px;
                width: 25px;
                z-index: 1;
            }
        }
    }

    .txt_container {
        padding-top: .3rem !important;

        @include media($break425) {
            padding-top: 0 !important;
        }
    }

    .main_title {

        @include media($break425) {
            font-size: 2.25em;
        }
    }

    .creator_content_container {

        /* FILTERS FOR CONTENT TAB */
        .content_searchFilters_container {
            max-width: 1370px;
            margin: 0 auto;

            @include media($break990) {
                display: none;
            }

            .search_input, .contentFilters_container, .order_btn {
                float: left;
            }

            .search_input {
                background: url('/images/style_guide/icon_search_grey.svg') .5rem center no-repeat;
                padding-left: 1.85rem;
                max-width: 300px;
                margin-right: .5rem;
                background-size: 17px;

                @include media($break625) {
                    max-width: 550px;
                    margin-bottom: 1rem;
                }
            }

            .filters_dropdown_list {
                display: block;
                right: -7px;
                top: 36px;
                text-align: left;

                .filters_btn {
                    border-radius: 0 !important;

                    &:hover, &:focus {
                        color: white;
                        background-color: $revfluence-blue;
                    }

                    .active {
                        color: white;
                    }
                }
            }

            .contentFilters_container {
                margin-right: .4rem;
                position: relative;
            }

            .filters_btn {
                display: block;
            }

            .btn_txt {}

            .dropdown_indicator, .order_indicator {
                width: 14px;
                margin-left: .25rem;
            }

            .order_btn {
                border-radius: 6px;
                padding: 6px 10px;
            }

            /* Apply active_orderBtn class when user engages this button */
            .active_orderBtn {
                background-color: $revfluence-blue;
                border: 1px solid $revfluence-blue;

                .btn_txt {
                    color: white;
                }
            }

            .btn_txt {
                color: $dark-text;
            }

            .ascending_indicator {}

            .descending_indicator {}

            .order_indicator {}
        }

        .main_content {

            .overview_main_container {
                margin-bottom: 2em;
            }

            .inner_container {
                max-width: 1400px;
                margin: 0 auto;
                background-color: white;
                box-shadow: 1px 1.732px 10px 0px rgba(1, 1, 1, 0.02);
                padding: 0 1.618rem;

                @include media($break420) {
                    padding: 0 .9rem;
                }
            }

            .overview_stats_container {
                overflow: hidden;
                padding: 4em;
            }

            .overview_stats_list {}

            .overview_stats_item {
                float: left;
                width: 15%;
                margin-right: 7em;
                border-radius: 8px;
                padding: 2em;
                cursor: pointer;
                border: 1px solid white;

                &:last-child {
                    margin-right: 0;
                }
                /*
                        &:hover {
                            border: 1px solid #ececec;
                            box-shadow: 1px 1px 5px rgba(0,0,0,.045);
                        }
                */
            }

            .stat_number {
                display: block;
                font-size: 3em;
                color: $dark-text;
                font-weight: 300;
                margin-bottom: .25em;
            }

            .stat_label {
                display: block;
                font-size: 1.3em;
                color: $dark-text;
                font-weight: 300;
                line-height: 1.5em;
            }

            .posts_stat {}

            .like_stat {}

            .comments_stat {}

            .impressions_stat {}

            .active_stat {
                border: 1px solid #dcdcdc;
                box-shadow: 1px 1px 5px rgba(0,0,0,.095);

                &:hover {
                    border: 1px solid #dcdcdc;
                }

                .stat_number {
                    font-weight: 600;
                }

                .stat_label {
                    font-weight: 600;
                }
            }

            .graph_container {
                padding: 0 4em 4em 4em;
                margin-bottom: 2em;
            }

            .graph_title {
                font-size: 1.65em;
                color: $dark-text;
                line-height: 1.5em;
                text-align: center;
                font-weight: 600;
                margin-bottom: 1.5em;
            }

            .sample_barchart {
                max-width: 100%;
            }
        }

        .content_main_container {

            .content_list {
                max-width: 1400px;
                margin: 0 auto;
                overflow: hidden;
                width: 100%;
                padding-top: .5rem;

                @include media($break900) {
                    padding: 0 0 2rem;
                    margin-top: 1rem;
                }
            }

            .content_item {
                width: 31.66%;
                margin-right: 1.67%;
                margin-bottom: 2%;
                float: left;
                background-color: white;
                border: 1px solid $lightgray-border;
                border-radius: 6px;

                @include media($break900) {
                    width: 31.66%;
                }
                @include media($break700) {
                    width: 48.33%;
                }
                @include media($break465) {
                    float: none;
                    width: 100%;
                    margin: 0 auto 2em;
                }
            }

            .imgContent_container {
                border-radius: 5px 5px 0 0;
                border: none;

                .imgContent {
                    border-radius: 5px 5px 0 0;
                }
            }

            .content_image_container {
                position: relative;
                opacity: 1;
                border-bottom: 1px solid $lightgray-border;

                &:hover {
                    opacity: .9;
                }
            }

            .youtube_image_container {
                border-bottom: 1px solid #eeeeee;
            }

            .play_icon {
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -25px;
                margin-left: -25px;
                width: 50px;
                height: 50px;
                display: block;
                background: url('/images/analyze/play_icon.svg') center no-repeat;
            }

            .captionContainer {
                border-radius: 0;
            }

            .content_image {
                width: 100%;
            }

            .youtube_image {
                padding: 52px 0 51px;
            }

            .content_info_container {}

            .content_stats_section {
                height: 48px;
            }

            .content_stats {
                padding: 1.25em 1.5em;
            }

            .content_stats_list {}

            .content_stats_item {
                font-size: 1.25em;
                color: $dark-text;
                display: inline-block;
                margin-right: 1em;
                line-height: 1.5em;
                font-weight: 300;
                &:last-child {
                    margin-right: 0;
                }
            }

            .content_caption_container {
                padding: 0 1.5em 1.25em;
                height: 70px;
            }

            .content_caption {
                font-size: 1.25em;
                color: $dark-text;
                line-height: 1.5em;
                font-weight: 300;

                a {
                    color: $revfluence-blue;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .content_metadata {
                padding: 0 1.5em 1.25em;
                border-bottom: 1px solid #eeeeee;
            }

            .content_metadata_list {}

            .content_metadata_item {
                color: #b4b4b4;
                display: inline-block;
                margin-right: 1em;
                font-size: 1.25em;
                line-height: 1.5em;
                font-weight: 300;
                &:last-child {
                    margin-right: 0;
                }
            }

            .date_published {}

            .creator_type {
                display: inline-block;
                width: 15px;
                height: 15px;
                vertical-align: -4px;
            }

            .content_metadata_image {
                display: inline-block;
            }

            .revfluence_creator {}

            .content_creator_info {
                overflow: hidden;
            }

            .creator_profile_link {
                display: block;
                overflow: hidden;
                padding: 1.5em;
                &:hover {
                    background-color: #fbfdff;
                }
            }

            .avatar_container {
                float: left;
                width: 50px;
                margin-right: 1.25em;
            }

            .avatar_image {
                width: 50px;
                height: 50px;
                display: block;
                border-radius: 100px;
            }

            .text_container {
                float: left;
                margin-top: .65em;
            }

            .creator_username {
                font-size: 1.25em;
                font-weight: 600;
                line-height: 1.5em;
                text-decoration: none;
                color: #a3a3a3;
            }

            .network_icon {
                width: 15px;
                height: 15px;
                display: inline-block;
                margin-right: .5em;
                vertical-align: -3px;
            }

            .network_type_text {
                font-size: 1.25em;
                line-height: 1.5em;
                color: #a3a3a3;
                font-weight: 300;
            }
        }

        .loadMore_container {
            padding: 1rem;

            .loadMore_btn {
                width: 200px;
                display: block;
                margin: 0 auto;
            }
        }

        .new_content_list {

            .default_body_copy {
                font-size: .9rem;
            }

            .engageList {
                padding: .9rem 0 .6rem .6rem;
            }

            .accoutLink {
                color: $dark-text;
                text-decoration: none;
            }

            .txtContent_container {
                border-radius: 5px 5px 0 0;
            }

            .content_container {
                border: none;
            }

            .retweetItem {}

            .loveItem {}

            .likeItem {}

            .viewItem {}

            .shareItem {}

            .engageItem {
                float: left;
                margin-right: 1rem;

                &:last-child {
                    margin-right: 0;
                }
            }

            .engageIcon {
                width: 17px;
            }

            .likeIcon {
                vertical-align: 1px;
            }

            .retweetIcon {}

            .loveIcon {
                vertical-align: -2px;
                width: 16px;
            }

            .viewIcon {
                width: 22px;
                vertical-align: 1px;
            }

            .shareIcon {
                width: 20px;
            }

            .engageAmount {
                vertical-align: 2px;
                color: $dark-text;
                text-decoration: none;
            }

            .account_section {
                border-bottom: 1px solid $lightgray-border;
                padding: 0 .6rem 1rem;
                font-size: .8rem;
            }

            .avatarImg {
                max-width: 100%;

                &:hover {
                    opacity: .9;
                }
            }

            .account_network_icon {
                position: absolute;
                bottom: -2px;
                right: -7px;
                width: 25px;
                z-index: 1;
            }

            .avatar_container, .txt_container {
                float: left;
            }

            .avatar_container {
                width: 45px;
                position: relative;
            }

            .txt_container {
                width: calc(100% - 61px);
            }

            .instagram_icon {}

            .youtube_icon {}

            .snapchat_icon {}

            .vine_icon {}

            .facebook_icon {}

            .blog_icon {}

            .pinterest_icon {}

            .accountName_container {
                line-height: 1rem;
            }

            .accountName {
                font-weight: 600;
                display: inline-block;
                max-width: calc(100% - 98px);
                margin-right: .15rem;
                color: $dark-text;
                text-decoration: none;

                &:hover {
                    color: $revfluence-blue;
                }
            }

            .accountFollowers {
                display: inline-block;
                width: 86px;
            }

            .dateContainer {}

            .dateTxt {}

            .revLogo {
                width: 27px;
                vertical-align: -9px;
            }
        }

        .valueSection {
            border-bottom: 1px solid $lightgray-border;
            padding: .5rem .6rem;
            font-size: .8rem;

            > li {
                float: left;
                margin-right: 3%;

                &:last-child {
                    margin-right: 0;
                }
            }

            .standard_tooltip {
                width: 250px;
                left: 50%;
                bottom: initial;

                &:after, &:before {
                    display: none;
                }
            }

            .estPaid_item {
                width: 29%;
            }

            .mediaValue_item {
                width: 37%;
            }

            .roi_item {
                width: 28%;
            }

            .valueLabel {
                display: block;
            }

            .hint_btn {
                padding: 0;
                border: none;
                background: none;
            }

            .hint_icon {
                background: none;
            }

            .valueAmount {
                display: block;
            }
        }
    }
}
