.elevate_compensation_section {
  .compensation_title {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 8px;
  }

  .compensation_subtitle {
    margin-bottom: 12px;
  }

  .compensation_content {
    float: left;
    width: calc(100% - 34px);
  }

  .compensation_edit_container {
    float: left;
    width: calc(100% - 34px);
    padding-left: 2.1rem;
  }

  .compensation_checkbox {
    float: left;
    margin-right: 0.5rem;
    margin-left: 0.2rem;
  }

  .compensation_container {
    display: block;
    overflow: hidden;
    padding-top: 1rem;
  }
}
