.example_content_popup {

  .contentImg {
    max-width: 100%;
    width: 100%;
    display: block;
    border: none;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.25);
  }
}
