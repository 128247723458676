.creator_profile {

  .choose_role_modal {
    text-align: center;
    padding: 2rem;

    .modal-dialog {
      padding: 1rem 1rem 2rem;
    }

    .modal-dialog {}

    .main_title {
      margin-bottom: 1.5rem;
    }

    .choose_role_list {
      max-width: 225px;
      margin: 0 auto;
    }

    .role_item {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .role_btn {
      display: block;
      width: 100%;
    }
  }
}
