/* Major breakpoints: 750 */

.pricing {

	a {
		text-decoration: none;
	}

	.main_header {
		margin: 8em 0 3.6em;
		text-align: center;

		@include media($break400) {
			padding: 0 2em;
		}

		.main_title {
			margin-bottom: .12em;
			font-weight: 500;
			font-size: 4.7em;

			@include media($break400) {
				font-size: 3em;
			}
		}

		.main_descrip {
			font-size: 2.09em;
			font-weight: 400;

			@include media($break400) {
				font-size: 1.85em;
			}
		}
	}

	strong {
		font-weight: 600;
	}

	.pricing_options {
		overflow: hidden;
		max-width: 1400px;
		margin: 0 auto 3em;
		padding: 1.2em 1rem 1.4em;

		@include media($break750) {
			padding: 1.2em 2rem 1.4em;
		}

		@include media($break425) {
			padding: 1.2em 1rem 1.4em;
		}

		.plan_indicator {
			float: left;
			width: 100%;
			text-align: center;
			padding: .5rem 0 .45rem;
			border-radius: 4px 4px 0 0;
			background: #C1C763;

			.plan_indicator_txt {
				display: inline-block;
				color: white;
				text-transform: uppercase;
				font-weight: 600;
				font-size: .75rem;
				letter-spacing: .2em;
				vertical-align: 5px;
			}
		}

		.bestValue_indicator {

			.bestValue_icon {
				width: 18px;
			}
		}

		.current_plan {

			.current_plan_icon {
				display: inline-block;
				margin-right: .6em;
				vertical-align: 5px;
			}
		}

		.last_plan{
			margin-right:0 !important;

			@include media($break1080) {
				margin: 0 auto 4em !important;
			}
		}

		.plan {
			float: left;
			margin-right: 1.5%;
			width: 23.87%;
			border: 1px solid $lightgray-border2;
			text-align: center;
			border-radius: 6px;
			transition: all .15s ease-in-out;

			&:hover {
				// -ms-transform: scale(1.018,1.018); /* IE 9 */
				//-webkit-transform: scale(1.018,1.018); /* Safari */
				//transform: scale(1.018,1.018);
				//box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
			}

			@include media($break1080) {
				text-align: left;
				width: 750px;
				margin: 0 auto 4em;
				float: none;
			}

			@include media($break830) {
				width: 100%;
				margin: 0 0 4em;
			}

			@include media($break425) {
				margin: 0 0 2em;
			}
		}

		.pricing_header {
			padding: 2em;
			color: $dark-text;

			@include media($break1080) {
				padding: 2.5em 5em 0;
			}

			@include media($break750) {
				text-align: center;
				height: auto;
			}
		}

		.plan_interval {
			font-size: 1.3em;
			padding-bottom: .2em;
			font-weight: 600;
			display: block;
			text-transform: uppercase;
			color: #9C9C9C;
			letter-spacing: .19rem;
			display: none;
		}

		.pricing_title {
			color: $dark-text;
			margin-top: .5em;
			font-weight: 500;
			font-size: 3.6em;
		}

		.price_amount {
			display: block;
			font-size: 2em;
			font-weight: 400;
		}

		.networks_container {
			margin-bottom: 2em;
		}

		.networks_text {}

		.instagram_icon, .youtube_icon {
			display: inline-block;
			vertical-align: -2.5px;
		}

		.instagram_icon {
			padding: 0 .16em 0 .08em;
		}

		.youtube_icon {
			padding: 0px 0.26em 0px 0.1em;
		}

		.plan_features {
			padding: 1rem 1em 0;
			height: 334px;
			font-size: 1.65em;

			@include media($break1265) {
				padding: 1em 1rem 0;
				height: auto;
			}

			@include media($break1080) {
				padding: 1em 3.35em;
				height: auto;
			}

			@include media($break750) {
				text-align: center;
			}
		}

		.feature_item {
			padding: .6em 0;
			font-weight: 400;
			color: $dark-text;
			font-size: 1em;

			&:last-child {
				margin-bottom: 0;
				border-bottom: none;
			}
		}

		/* Use this class for items that are unique to the plan, it bolds them */
		.unique_item {
			font-weight: 700;
		}

		.actions_container {
			padding: 2em 2em 2.5rem 2em;
			text-align: center;

			@include media($break1080) {
				text-align: left;
				padding: 1em 2em 3em 5em;
			}

			@include media($break400) {
				padding: 1em 3em 3em 3em;
			}

			.choose_plan_btn {
				border-radius: 6px;
				display: block;
				max-width: 200px;
				margin: 0 auto;
				width: 100%;
				padding: 7px 20px;
				font-size: 1rem;
			}
		}
	}

	.current_plan {

		.choose_plan_btn {
			opacity: .5;
			border-radius: 6px;
			width: 220px;
		}
	}

	.supporting_info {
		max-width: 1400px;
		margin: 0 auto;
		padding: 1.2em 1rem 3rem;

		@include media($break750) {
			padding: 1.2em 0 0;
		}

		.testimonial_container, .contact_us {
			float: left;
			width: 50%;

			@include media($break750) {
				float: none;
				width: 100%;
			}
		}

		.testimonial_container {
			padding-right: 3%;

			@include media($break750) {
				padding-right: 0;
				padding-bottom: 3rem;
				text-align: center;
			}

			.testimonial_avatar, .testimonial_txt_container {
				float: left;

				@include media($break750) {
					float:none;;
				}
			}

			.testimonial_avatar {
				width: 55px;
				border-radius: 100px;
				margin-right: 4%;

				@include media($break750) {
					margin: 0 auto 1.25rem;
					display: block;
				}
			}

			.testimonial_txt_container {
				width: calc(100% - 81px);

				@include media($break750) {
					width: 100%;
				}
			}

			.testimonial_txt {
				font-weight: 400;
				padding-bottom: 1rem;
				font-size: 2.09em;

				@include media($break750) {
					padding: 0 1rem;
				}
			}

			.testimonial_author {
				font-weight: 900;
				font-size: 2.09em;
			}
		}

		.contact_us {
			background-color: $hint_yellow;
			border-radius: 6px;
			padding: 1.25rem 1.25rem 1.65rem;
			position: relative;

			@include 	media($break750) {
				text-align: center;
				padding: 1.25rem 1.25rem 2.5rem;
			}

			&:hover {
				background-color: #DCFBEA;
			}

			.contact_us_link {
				color: $dark-text;
				text-decoration: none;
				position: absolute;
				width: 100%;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}

			.help_icon, .contact_us_txt {
				float: left;

				@include 	media($break750) {
					float: none;
				}
			}

			.help_icon {
				width: 57px;
				padding-right: 1rem;

				@include 	media($break750) {
					padding-right: 0;
					display: block;
					margin: 0 auto 1.25rem;
				}
			}

			.contact_us_txt {
				width: calc(100% - 57px);

				@include 	media($break750) {
					width: 100%;
				}
			}

			.main_title {
				padding-bottom: .25rem;
				font-weight: 500;
				font-size: 1.55rem;
			}

			.main_descrip {
				font-weight: 400;
				font-size: 1rem;

				@include media($break750) {
					font-size: 1.2rem;
				}
			}

			.contact_link {}
		}
	}

	.first-loading-view {
		background-color:rgba(255, 255, 255, 1.0);
	}
}


.pricing-modal {

	.modal-dialog {
		width:550px;
		padding: 2rem 2rem 3rem;
	}

	.main_title, .main_descrip {
		text-align: center;
	}

	.main_title {
		margin-bottom: .5rem;
	}

	.main_descrip {
		margin-bottom: 1.25rem;
	}

	.okay_btn {
		width: 100px;
		margin: 0 auto;
		display: block;
	}

	.confirmation_actions{
		text-align:center;
	}

	.confirmation_button{
		width: 100px;
		display:inline;
		margin-right:0.5rem;
	}
}
