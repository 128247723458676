.campaign_marketplace {

  .fixed_marketplaceHeader {
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0,0,0,.05);
    z-index: 6200;
    background-color: white;
    background-color: rgba(255,255,255,.97);
    border-top: 1px solid $lightgray-border;

    @include media($break840) {
      border-top: none;
    }

    /* Desktop: Turnt his off for mobile) */
    .inner_container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 1rem 28em 1.55rem 0;
      position: relative;
      display: none !important;

      @include media($break1000) {
				padding: 2em 30em 2em 1rem;
			}

			@include media($break800) {
        display: none !important;
			}
    }

    .fixed_mainTitle {
      float: left;

      @include media($break1200) {
        float: none;
      }

      @include media($break800) {
        font-size: 1.95rem;
        display: none;
      }
    }


    /* Desktop: Search for fixed bar */
    .searchInput_container {
      top: 1.75rem;

      @include media($break800) {
        display: none !important;
      }
    }

    .searchCampaigns_input {}

    .clear_txt_btn {
			top: .4rem;
			right: .25rem;

      &:focus {
        outline: none;
      }
		}

    /* Mobile: Turns on search input */
    .toggleSearch_btn {
      display: none;
      background: none;
      padding: 0;
      border: none;
      position: relative;
      padding: 1rem;
      text-align: left;
      width: 100%;

      @include media($break800) {
        display: block;
        padding: 1rem 3.3rem 1rem 1rem;

        .fixed_mainTitle {
          display: block;

          @include media($break400) {
            font-size: 1.65rem;
          }
        }
      }

      .search_icon {
        width: 36px;
    		position: absolute;
    		right: .95rem;
    		top: 1.4rem;
    		background: none;
    		padding: .25rem;

        @include media($break400) {
          width: 32px;
        }
      }
    }

    /* Mobile: Search Input */
    .mobileSearch_inputContainer {
      display: none;
      position: fixed;
      top: 50px;
      left: 0;
      width: 100%;
      box-shadow: 1px 1px 10px rgba(0,0,0,.12);

      @include media($break800) {
        display: block;
      }

      .clear_txt_btn2 {
        position: absolute;
        right: .25rem;
        top: 1rem;
        border: none;
        background-color: rgba(0,0,0,0);

        &:focus {
          outline: none;
        }
      }

      .remove_icon2 {
        width: 20px;
      }

      .mobileSearch_input {
        border-radius: 0;
        border: none;
        padding: 1rem 2.5rem 1rem 1rem;
        background-color: white;
        background-color: rgba(255,255,255,.95);
        font-size: 2em;
      }
    }

    /* Mobile: Entered Search header */
    .enteredSearch_header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 1rem 2.5rem 1rem 1rem;
      display: none !important;
      box-shadow: 1px 1px 10px rgba(0,0,0,.12);
      background-color: white;
      background-color: rgba(255,255,255,.95);

      @include media($break800) {
        display: none !important;
      }

      .enteredSearch_txt {
        display: block;
      }

      .remove_search_btn {
        position: absolute;
        right: .25rem;
        top: 1rem;
        background: none;
        padding: .5rem;

        .remove_icon3 {
          width: 20px;
        }
      }
    }

    .mobileFiltersContainer {
      padding: .5rem 1rem;
      background-color: white;
      .filter_dropdown {
        display: block;
        padding: .5rem 0 0;
      }

      .filtersDismissBtn {
        width: calc(70% - 12px);
        margin: 1rem 0 .5rem;
        display: inline-block;
      }
      .clearFiltersBtn {
        width: calc(30%);
        margin: 1rem 0 .5rem .5rem;
        display: inline-block;
      }
    }
  }
}
