.creator_profile {

  .brand_msg_modal {
    .brand_msg_form {
      text-align: left;

      .arrow_icon {
        vertical-align: -1px;
        margin-left: .4rem;
      }
    }
  }

  .collab_wrapper {
    padding-top: 2rem;
    max-width: 600px;
    margin: 0 auto;
    
    &.no_padding {
      padding: 0rem;
    }

    @include media($break575) {
      padding: 1rem 1.5rem;
    }

    .avatar_container {
      position: relative;
      width: 100px;
      margin: 0 auto;

      @include media($break575) {
        width: 75px;
      }
    }

    .avatar_link {
      display: inline-block;
    }

    header {
      padding-bottom: 1rem;
      text-align: center;
    }

    .main_title {

      @include media($break575) {
        font-size: 2rem;
      }
    }

    .collab_icon {
      position: absolute;
      top: 0;
      right: -10px;
      width: 43px;

      @include media($break575) {
        width: 35px;
      }
    }

    .creator_avatar {
      border-radius: 100px;
      border: 2px solid white;
      box-shadow: 1px 2px 8px rgba(0,0,0,.1);
      max-width: 100%;
    }

    .collab_back_btn {
      position: absolute;
      left: 0;
      top: 3.5rem;
      background: none;
      padding: 1rem;
    }

    .collab_back_icon {
      width: 32px;

      @include media($break575) {
        width: 25px;
      }
    }
  }

  .choose_role {
    text-align: center;
    max-width: 550px;
    margin: 0 auto;

    .modal-dialog {
      padding: 2rem 2rem 3rem;
    }

    .modal-dialog {}

    .main_title {
      margin-bottom: 1.5rem;
    }

    .choose_role_list {
      max-width: 225px;
      margin: 0 auto;
    }

    .role_item {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .role_btn {
      display: block;
      width: 100%;
    }
  }

  .creator_msg {

    .main_title {}

    .creator_msg_form {
      max-width: 550px;
      margin: 0 auto;
    }

    .row {}

    fieldset {
      margin-bottom: .5rem;
    }

    .first_name_fieldset {
      float: left;
      width: 48%;
      margin-right: 3%;

      @include media($break450) {
        float: none;
        width: 100%;
        margin-right: 0;
      }
    }

    .last_name_fieldset {
      float: left;
      width: 49%;

      @include media($break450) {
        float: none;
        width: 100%;
        margin-right: 0;
      }
    }

    .standard_label {}

    .first_name_input {}

    .form_control {}

    .last_name_input {}

    .email_input {}

    .msg_fieldset {
      padding-bottom: .5rem;
    }

    .msg_input {}

    .actions_container {
      padding-bottom: 2rem;
    }

    .next_btn {
      margin-right: .5rem;
    }

    .cancel_btn {}
  }

  .brand_msg {

    &.simplified_brand_msg {
      header {
        padding-bottom: 1rem;
      }

      .brand_msg_form {
        width: 80%;
        margin: auto;
      }
    }
    
    header {
      padding-bottom: 2rem;
    }

    .main_title {}

    .main_descrip {}

    main {}

    .brand_msg_form {}

    .row {}

    fieldset {
      margin-bottom: .5rem;
    }

    .first_name_fieldset, .password_fieldset {
      float: left;
      width: 48%;
      margin-right: 3%;

      @include media($break450) {
        float: none;
        width: 100%;
        margin-right: 0;
      }
    }

    .last_name_fieldset, .confirm_pass_fieldset {
      float: left;
      width: 49%;

      @include media($break450) {
        float: none;
        width: 100%;
        margin-right: 0;
      }
    }

    .password_row {
      padding-bottom: .75rem;
    }

    .standard_label {}

    .first_name_input {}

    .last_name_input {}

    .company_input {}

    .website_iput {}

    .email_input {}

    .phone_input {}

    .password_input {}

    .actions_container {
      padding-bottom: 2rem;
      margin-top: 1.0rem;
      text-align: center;
    }

    .next_btn {
      margin-right: .5rem;
    }

    .cancel_btn {}

    .form_control {}

  }
  
  .target_collab_details {

    header {
      padding-bottom: 2rem;
    }

    .h3 {

      @include media($break575) {
        font-size: 2rem;
      }

      @include media($break475) {
        font-size: 1.75rem;
      }
    }

    main {}

    .target_collab_form {}

    fieldset {
      margin-bottom: 1rem;
    }

    .form_group {
      margin-right: 1rem;
      margin-bottom: 0;
    }

    .offering_fieldset {

      .form_group, .budget_range_fieldset {
        float: left;

        @include media($break575) {
          float: none;
          display: block;
          margin-bottom: 1.5rem;
        }
      }

      .budget_range_fieldset {
        margin-top: .75rem;
        margin-bottom: 0;
      }

      .checkbox_control {
        margin-bottom: 0;
      }

      .standard_label {
        display: block;
        margin-bottom: .65rem;
      }
    }

    .standard_label {}

    .subject_input {}

    .form_control {}

    .budget_range_select {}

    .looking_form_input {}

    .advanced_fieldset {
      border: 1px solid $lightgray-border;
      border-radius: 5px;
      padding: 0;

      .showAdvanced_mainContent {
        padding: .75rem 1rem 1rem;
        border-radius: 0 0 6px 6px;
        background-color: $ultraLight_gray;
        border-top: 1px solid $lightgray-border;
      }

      .form_group {

        @include media($break575) {
          margin-bottom: 1rem;
        }
      }

      .disclosure_icon {
        transition: all .15s ease-in-out;
      }

      fieldset {
        margin-bottom: 0;
      }

      .offering_fieldset {
        margin-bottom: 1rem;

        @include media($break575) {
          margin-bottom: 0;
        }
      }
    }

    .brand_info_input {}

    .inspiration_input_list {}

    .inspiration_input_item {
      margin-bottom: .5rem;
    }

    .inspiration_input {
      float: left;
      margin-right: .5rem;
      width: calc(100% - 30px);

      @include media($break475) {
        width: calc(100% - 36px);
      }
    }

    .remove_btn {
      background: none;
      padding: 0;
      float: left;
      margin-top: .35rem;
    }

    .remove_icon {}

    .add_inspiration_btn {}

    .add_icon {
      display: inline-block;
      vertical-align: -4px;
      margin-right: .25rem;
    }

    .btn_txt {}

    .receive_email_notice {
      margin-bottom: 1rem;
    }

    .actions_container {
      padding-bottom: 2rem;
    }

    .submit_btn {
      margin-right: .5rem;
    }

    .cancel_btn {}
  }
}
