.contentRegister_modal {

  .modal-dialog {
    padding: 2.5rem 2rem;
  }

  .close_modal_btn {
    padding: .5rem;
    border-radius: 100px;
  }

  .remove_modal_icon {
    width: 15px;
  }

  .contentRegister_title {
    text-align: center;
    padding-bottom: .5rem;
  }

  .contentRegister_form {
    max-width: 275px;
    margin: 0 auto 1.5rem;
    padding: .75rem 0 0;
  }

  .error {
    margin-bottom: .5rem;
    display: inline-block;
  }

  .register_descrip {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
  }

  fieldset {
    margin-bottom: .5rem;
  }

  .contentRegister_actions_container {
    text-align: center;
  }

  .form_error_text {
    text-align: center;
    width: 100%;
    margin: 1rem auto 0;
    font-weight: 700;
  }

  .login_note {
    padding-top: 1.5rem;
    line-height: 1.05rem;
    text-align: center;

    .standard_link {
      color: $aiq_darkblue;

      &:hover, &:focus {
        color: $aiq_darkblue_hover;
      }

      &:active {
        color: $aiq_darkblue;
      }
    }
  }
}
