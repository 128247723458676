.invites_closed_modal {
  z-index: 5500;

  .modal-dialog {
    padding: 1.5rem 5rem 2.4rem;
    max-width: 670px;

    @include media($break650) {
      top: 62%;
      width: 95%;
    }

    @include media($break450) {
      padding: 1rem 1rem 2rem;
      top: 62%;
      width: 95%;
    }
  }

  .dialog_header {
    text-align: center;
    padding-bottom: 2.5rem;

    .icon_cancel {}

    .main_modal_title {
      padding-bottom: .5rem;

      @include media($break450) {
        font-size: 1.45rem;
        font-weight: 400;
      }
    }

    .main_descrip {

      strong {
        font-weight: 600;
      }
    }
  }

  .progress_wrapper {
    max-width: 320px;
    margin: 0 auto;
    padding-bottom: .65rem;

    .campaign_progressBar, .icon_trophy {
      float: left;
    }

    .campaign_progressBar {
      width: calc(100% - 37px);
      margin-right: .65rem;
    }

    .icon_trophy {
      margin-top: -6px;
    }
  }

  .mainMetrics_container {
    text-align: center;
    padding-bottom: 1rem;

    .invitesMetric_container, .proposalMetric_container {
      display: inline-block;
    }

    .invitesMetric_container, .proposalMetric_container {
      margin-right: 1.5rem;
      :last-child {
        margin-right: 0.0rem;
      }
    }

    .metric_number {
      font-size: 1.4rem;
    }

    .proposalMetric_contaier {}
  }

  .viewProposal_btn {
    margin: 0 auto;
    display: block;

    .icon_chevron {
      vertical-align: -1px;
      margin-left: 3px;
    }
  }
}

.invitesClosed_modalOverlay {
  z-index: 5400;
}
