.editName_modal {

  .modal-dialog {
    padding: 2.5rem 2.5rem 3.5rem;
    max-width: 500px;

    @include media($break600) {
      padding: 1.5rem 1.5rem 2.5rem;
    }
  }

  .popup_close_btn {
    padding: .5rem;
  }

  .popup_close_icon {
    width: 15px;
    height: 15px;
  }

  .mainHeader {
    text-align: center;
    padding-bottom: 1rem;
  }

  .mainTitle {
    padding-bottom: 1rem;
  }

  .mainDescrip {}

  .error {
    margin-bottom: 1rem;
    display: table;
    width: 100%;
    text-align: center;
  }

  .editContent_name_form {

    .nameInput, .saveName_btn {
      float: left;
    }

    .nameInput {
      margin-right: 1rem;
      width: calc(100% - 108px);
    }

    .saveName_btn {
      border-radius: 3px;
    }
  }


}
