.aspireiq_theme {

  .addGrp_dropdown {

    .addtoGrp_btn {

      &:hover {

        .grp_icon {
          display: inline-block;
        }

        .grp_hover_icon {
          display: none;
        }
      }
    }

    .createGrp_btn {

      &:hover {

        .createGrp_btn_txt {
          color: $aiq_darkblue;
        }
      }
    }
  }

  .content_approval_tool {

    .content_checklist {

      .revfluence_logomark {
        width: 17px;
        vertical-align: -2px;
      }
    }
  }

  .ax_content_approval_tool {
    padding: 30px 40px;
    margin-bottom: 30px;

    &.no_padding {
      padding: 0;
    }

    .upload_extra_content.no_margin_top {
      margin-top: 0;
    }

    .load_more_btn {
      margin: auto;
      display: block;
    }

    .upload_content_container {
      .upload_content_header {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 1.6875rem;
        line-height: 1;
        font-weight: bold;
        margin-bottom: 0.75rem;

        .tool_icon {
          width: 2rem;
          height: 2rem;
          margin-right: 0.5rem;
        }

        @include media($break630) {
          display: none;
        }
      }

      .upload_content_navigation {
        flex: 1;
        display: flex;
        align-items: center;

        .upload_content_nav_item {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          height: 3.125rem;
          display: flex;
          align-items: center;
          margin-right: 2rem;
          margin-bottom: 1rem;
          color: #1F1F21;
          position: relative;
          cursor: pointer;

          &.selected {
            color: #167CF4;

            &::after {
              content: ' ';
              display: block;
              width: 100%;
              height: 2px;
              background: #167CF4;
              position: absolute;
              left: 0;
              bottom: 0;
            }
          }

          &:last-child {
            margin-right: 0;
          }

          @include media($break630) {
            font-size: 0.875rem;
            height: 2rem;
            margin-right: 0.75rem;
            margin-bottom: 1.875rem;
          }
        }
      }
    }
  }

  .accountsWith_history_container {

    .viewAccount_btn {
      color: $aiq_darkblue;

      &:hover {
        color: $aiq_darkblue_hover;
      }
    }
  }

  .inbox_view {

    .default_body_copy {
      font-size: .9rem;
    }
  }

  .edit_team {

    .edit_team_header {

      .back_btn {
        padding: .5rem;
        top: 1.1rem;
      }
    }
  }

  .inbox_list_container {

    .inbox_table {

      th {

        &:hover {
          color: $aiq_darkblue;
          background-color: $aiq_lightblue_bg;
        }

        &:active {
          background-color: $aiq_lightblue_bg_hover;
        }
      }

      tr {
        border-bottom: 1px solid $aiq_border;
      }

      tbody {

        tr {
          &:hover {
            background-color: #F3F6FA;
          }
        }
      }

      .read_row {
        background-color: $aiq_lightblue_bg;

        &:hover {
          background-color: $aiq_lightblue_bg;
        }
      }

      .read_row.selected_row {
        background-color: #F0F7FF;

        &:hover {
          background-color: #F0F7FF;
        }

        .control-indicator {
        	border: 1px solid white;
        	background-color: $aiq_darkblue_hover;
        }
      }
    }
  }

  .inbox_filters {

    .new_activity_indicator {
      background-color: $aiq_darkblue;
    }

    .newMsg_indication {
      font-weight: 700;
      color: $aiq_bluegrey;
    }

    .timestamp {
      font-size: .7rem;
    }

    .collection_count {
      background-color: $aiq_darkblue;
    }

    .viewFilter_btn {

      &:hover {
        color: $aiq_darkblue;
        background-color: $aiq_lightblue_bg;

        .collection_name {
          color: $aiq_darkblue;
        }
      }

      &:active {
        background-color: $aiq_lightblue_bg_hover;
      }
    }

    .collection_name {
      font-weight: 400;
    }

    .collection_name.new {
      color: $aiq_bluegrey;
      font-weight: 700;

      &:hover {
        color: $aiq_darkblue;
      }
    }

    .second_child_filter_item {
      width: 184px;

      &:hover {
        color: $aiq_darkblue;
        background-color: $aiq_lightblue_bg;
      }

      &:active {
        background-color: $aiq_lightblue_bg_hover;
      }

      .relation_name {
        width: 98px;
      }
    }

    .campaign_selection_container {

      .campaign_dropdown_list {

        .campaign_item {

          &:hover {
            color: $aiq_darkblue;
            background-color: $aiq_lightblue_bg;

            .campaign_btn {
              color: $aiq_darkblue;
            }
          }
        }
      }
    }

    .active_item {
      color: $aiq_darkblue;
    }

    .toggle_open {
      background-size: 10px 7px;

			&:hover {
				background: $aiq_lightblue_bg url('/images/style_guide/open_arrow_icon.svg') center no-repeat;
				background-size: 10px 7px;
			}

			&:active {
				background: $aiq_lightblue_bg_hover url('/images/style_guide/open_arrow_icon.svg') center no-repeat;
				background-size: 10px 7px;
			}
		}

		.toggle_closed {
			background: url('/images/style_guide/closed_arrow_icon.svg') center no-repeat;
      background-size: 7px;

			&:hover {
				background: $aiq_lightblue_bg url('/images/style_guide/closed_arrow_icon.svg') center no-repeat;
        background-size: 7px;
			}

			&:active {
				background: $aiq_lightblue_bg_hover url('/images/style_guide/closed_arrow_icon.svg') center no-repeat;
        background-size: 7px;
			}
		}

    .statusFilters_list {

      .viewFilter_btn {

        &:hover {
          color: $aiq_darkblue;
          background-color: $aiq_lightblue_bg;

          .collection_name {
            color: $aiq_darkblue;
          }
        }

        &:active {
          background-color: $aiq_lightblue_bg_hover;
        }
      }
    }
  }

  .relationship_main {

    .general_message {

      .logo_avatar {
        border-radius: 0 !important;
        height: 25px;
        width: 25px;
      }
    }

    .personal_message {

      a {
        color: $aiq_darkblue;

        &:hover, &:focus {
          color: $aiq_darkblue_hover;
        }
      }
    }

    .collabs_container {

      .new_collab_btn {
        padding: 8px 18px;

        .add_new_icon {
          vertical-align: -6px;
          margin-right: .1rem;
        }
      }

      .stronger_new_collab_btn {
        background-color: $aiq_darkblue;

        &:hover, &:focus {
          background-color: $aiq_darkblue_hover;
        }

        &:active {
          background-color: $aiq_darkblue;
        }

        .add_new_icon_light {
          vertical-align: -5px;
        }
      }
    }

    .proposal_underReview_notice {
      margin-top: 1rem;
    }

    .note_message {
      border: none !important;
      background-color: $aiq_nude !important;
    }

    .noteMsg_container {

      .message_input {
        background-color: $aiq_nude;
      }
    }

    .new_message_container {
      border: 1px solid $aiq_border;
      border-radius: 6px 6px 0 0;
    }

    .collabContent_item {
      margin-bottom: 0;
    }

    .inactive_collabTerms_icon {
      background: url("/images/themes/aspireiq/inactive_collabTerms_icon.svg") center no-repeat;
      background-size: 21px;
    }

    .inactive_conversionTracker_icon {
      background: url("/images/themes/aspireiq/inactive_conversionTracker_icon.svg") center no-repeat;
    }

    .inactive_productShipment_icon {
      background: url("/images/themes/aspireiq/inactive_productShipment_icon.svg") center no-repeat;
      background-size: 27px;
    }

    .inactive_contentApproval_icon {
      background: url("/images/themes/aspireiq/inactive_contentApproval_icon.svg") center no-repeat;
      background-size: 23px;
    }

    .inactive_collabReview_icon {
      background: url("/images/themes/aspireiq/inactive_collabReview_icon.svg") center no-repeat;
      background-size: 24px;
    }

    .inactive_payment_icon {
      background: url("/images/themes/aspireiq/inactive_payment_icon.svg") center no-repeat;
      background-size: 11px;
    }

    .inactive_contentTracker_icon {
      background: url("/images/themes/aspireiq/icon_img.svg") center no-repeat;
      background-size: 22px;
    }

    .active_collabTerms_icon {
      background: url("/images/themes/aspireiq/active_collabTerms_icon.svg") center no-repeat;
      background-size: 33px;
    }

    .active_productShipment_icon {
      background: url("/images/themes/aspireiq/active_productShipment_icon.svg") center no-repeat;
      background-size: 33px;
    }

    .active_contentApproval_icon {
      background: url("/images/themes/aspireiq/active_contentApproval_icon.svg") center no-repeat;
      background-size: 33px;
    }

    .active_collabReview_icon {
      background: url("/images/themes/aspireiq/active_collabReview_icon.svg") center no-repeat;
      background-size: 33px;
    }

    .active_payment_icon {
      background: url("/images/themes/aspireiq/active_payment_icon.svg") center no-repeat;
      background-size: 33px;
    }

    .active_contentTracker_icon {
      background: url("/images/themes/aspireiq/active_contentTracker_icon.svg") center no-repeat;
      background-size: 33px;
    }

    .timeline_item {

      .read_receipt {

        .readLabel {
          font-weight: 600;
          padding-left: 0;
        }
      }
    }

    .timeline_collabTerms_icon {
				background: url('/images/themes/aspireiq/icon_terms_black.svg') center no-repeat;
		}

    .message_tail {
      display: none;
    }

    .active_tool {

      .activeTool_header_txt, .activeTool_descrip {
        color: $aiq_bluegrey;
      }

      .activeTool_header_txt {
        font-size: 1.1rem;
      }

      .btn_default {
        background: white;
      }
    }

    .tool_name, .tool_status {
      color: $aiq_black;
    }

    .youtube_icon {
      margin-top: 5px;
    }

    .collab_item_header {
      margin-bottom: 0;
      width: 100%;
      border-radius: 6px 6px 0 0;

      a {
        color: $aiq_black;
        display: block;

        &:hover {
          color: $aiq_darkblue;
        }
      }
    }

    .tools_item {
      border: none;
      box-shadow: 0 1px 2px 0 rgba(26,24,24,0.12);
      padding: 13px .65rem;
    }

    .collab_item_header {
      border-top: 1px solid $aiq_border;
      padding: 19px 25px 3px 25px;
    }

    .collab_item_header, .collab_summary_info {
      border-left: 1px solid $aiq_border;
      border-right: 1px solid $aiq_border;
    }

    .collab_summary_info {
      padding-bottom: 1.45rem;

      .icon_help_center {
        width: 20px;
      }
    }

    .collab_item_container {
      border: none;
    }

    .collab_invite_inquiry {
      border: 1px solid $aiq_border;
      border-radius: 6px;
      padding: 24px 24px 36px;


      .invite_inquiry_from {
        margin-bottom: 8px;
        text-decoration: none;

        .invite_inquiry_title:hover {
          color: $aiq_darkblue;
          cursor: pointer;
        }
      }

      .invite_inquiry_sent {
        margin-bottom: 24px !important;
        @include fontsize16;
      }

      .invite_inquiry_account_details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px !important;
        padding: 16px;
        @include fontsize14;
        border: 1px solid $aiq_border;
        border-radius: 6px;

        .invite_inquiry_account_name {
          display: flex;
          flex-direction: row;
          align-items: center;
          img {
            margin-right: 8px;
          }
        }

        a {
          cursor: pointer;
          user-select: none;
          color: $aiq_darkblue;
          text-decoration: none;
        }
      }

      .offer_icon {
        width: 18px !important;
        vertical-align: -3px;
      }
    }

    .collab_expanded_content {
      background-color: $aiq_lightblue_bg;
      box-shadow: none;
      border-radius: 0 0 6px 6px;
    }

    .user_message {

      .message_container {
        background-color: $aiq_lightblue_bg;
        border: none;
        width: calc(100% - 45px);
        word-wrap: break-word;
      }
    }

    .new_timeline_item {

      .message_container {
        border: 1px solid $aiq_darkblue;
      }
    }

    .relationship_main_header {
      border-bottom: none;
      margin-bottom: 1rem;

      @include media($break630) {
        margin-bottom: 0;
      }

      .large_backButton {
        padding: 10px;
      	border-radius: 4px;
        height: auto;
        width: auto;
        margin-top: 10px;
        margin-right: .5rem;

      	&:hover {
      		background-color: $aiq_lightblue_bg;
      	}

      	&:active, &:focus {
      		background-color: $aiq_lightblue_bg_hover;
      	}
      }
    }

    .more_accounts_container {

      .more_accounts_btn {
        height: 28px;
        vertical-align: 1px;
      }

      .more_accounts_dropdown {

        .moreAccount_link {

          &:hover {
            color: $aiq_darkblue;
            background-color: $aiq_lightblue_bg;
          }
        }
      }
    }

    .messages_header {
      display: none;
    }

    .account_link {
      margin-right: .5rem;

      a {
        border: none;
        border-radius: 100px;
        background: $aiq_lightblue_bg;
        font-weight: 400;

        &:hover {
          background: $aiq_lightblue_bg_hover;
        }

        &:active {
          background: $aiq_lightblue_bg;
        }
      }

      .accountName_txt {
        vertical-align: -7px;
      }
    }

    .more_accounts_container {
      margin-left: .5rem;
    }

    .more_accounts_container {
      margin-left: .35rem;

      .more_accounts_btn {
        border: none;
        border-radius: 100px;
        background: $aiq_lightblue_bg;
        font-weight: 400;
        padding: 6px 14px;

        &:hover {
          background: $aiq_lightblue_bg_hover;
        }

        &:active {
          background: $aiq_lightblue_bg;
        }
      }
    }

    .messages_container {
      border-right: none;
      border-left: none;
      padding: 0;
    }

    .messages_container.focused {
      height: calc(100% - 279px);
    }

    .social_accounts_container {
      background: $aiq_lightblue_bg;
    }

    .system_message {

      a {
        color: $aiq_darkblue;

        &:hover {
          color: $aiq_darkblue_hover;
        }
      }
    }

    .composeMsg_container {

      .composeMsg_tabsList {

        .composeMsg_tabBtn {
          color: $aiq_black;

          &:hover {
            color: $aiq_darkblue;
          }
        }

        .active_composeMsg_tabBtn {
          color: $aiq_darkblue;

          &:hover {
            color: $aiq_darkblue_hover;
          }
        }
      }
    }

    .send_message_button {
      background: $aiq_darkblue;
      background-size: 400% 400%;
      transition: all .15s ease-in-out;
      border: none;
      height: 37px;

      &:hover, &:focus {
        animation: inherit;
        background: $aiq_darkblue_hover;
        color: white;
      }

      &:active {
        animation: inherit;
        background: $aiq_darkblue;
      }
    }

    .message_input {
      width: calc(99% - 80px);

      @include media($break630) {
        width: 100%;
      }
    }

    .tools_list {

      .toolsTimeline_fill {
        border-left: 3px solid $aiq_yellow;
      }
    }

    .mark_completed_btn {
      font-size: .9rem;

      .checkmark_icon {
        width: 15px;
      }
    }

    .completed_indicator {
      font-weight: 600;

      .checkmark_icon {
        width: 15px;
      }
    }

    .groups_container {

      .assignOwner_container {

        .dropdown_btn {

          &:hover {
            color: $aiq_darkblue;
          }
        }
      }

      .addGrp_container {

        .addGrp_btn {

          &:hover {

            .addGrp_btn_txt {
              color: $aiq_darkblue;
            }
          }
        }

        .addGrp_dropdown {

          .createGrp_btn {

            &:hover {
              color: $aiq_darkblue;
              background-color: $aiq_lightblue_bg;

              .createGrp_btn_txt {
                color: $aiq_darkblue;
              }
            }
          }
        }
      }
    }

    .completed_collab_item {
      border: 1px solid $lightgray-border;

      .collab_item_header {
        border: none;
      }

      .collab_summary_info {
        border: none;
      }
    }
  }

  .add_posts_container {

    .content_type_item {

      &:nth-child(odd) {
        background-color: $aiq_lightblue_bg;
      }
    }
  }

  .tool_view_container {

    .tool_main_container {

      .termsTools_header {

        .tool_name {
          color: $aiq_black;
        }

        .embeddedHeader {
          padding-top: 16px;
        }
      }
    }
  }

  .brand_instructions {

		.header {
			padding-bottom: 1rem;
			font-size: 1rem;
		}
	}
}
