.aspireiq_theme {

  .slider_container {

    .ui-slider-range {
      background: $aiq_darkblue !important;
    }
  }

  .checkbox_control input:focus ~ .control-indicator {
    box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem $aiq_darkblue;
  }

  .checkbox_control input:checked ~ .control-indicator {
    background-color: $aiq_darkblue;
  }

  .radio input:checked ~ .control-indicator {
    background-color: $aiq_darkblue;
  }

  .error {
    background-color: $aiq_dangerRed;
  }

  .error_text {
    color: $aspireiq_dark_coral;
  }

  .radio input:checked ~ .control-indicator {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.2rem $aiq_darkblue;

    .select {

      select {

        &:focus {
          box-shadow: none;
        }

        &:hover {
          background-color: $ultraLight_gray;
        }
      }
    }
  }
}
