.creator_profile {

  .social_account_stats {
    display:none;

    @include media($break875) {
      display: block;
      padding: 1.5rem 0 0;
    }

    .social_accounts_list {}

    .social_account_item {
      float: left;
      width: 33.33%;
      text-align: center;
      margin-bottom: 1.5rem;
    }

    .only_1_item {
      width: 100%;
    }

    .only_2_items {
      width: 49.33%;
    }

    .social_account_link {
      display: block;
      text-decoration: none;
      position: relative;
    }

    .account_name {
      display: none;
      font-weight: 600;
      display: inline-block;
      color: $dark-text;
      font-size: 1.5em;
      margin-right: 10px;

      @include media($break875) {
        display: inline-block;
      }
    }

    .mobile_addSnap_content_btn {
      padding: 0;
      border: none;
      position: absolute;
      left: 32%;
      top: 1px;
      z-index: 3;

      @include media($break550) {
        left: 23%;
      }

      @include media($break470) {
        left: 15%;
      }

      @include media($break320) {
        left: 10%;
      }
    }

    .mobile_addSnap_content_icon {
      width: 25px;
    }

    .instagram_icon {}

    .youtube_icon {}

    .twitter_icon {}

    .network_icon, .stat_number, .stat_label {
      display: block;
    }

    .network_icon {
      width: 25px;
      margin: 0 auto .5rem;
      height: 28px;
    }

    .stat_number {
      font-size: 1.5rem;

      @include media($break875) {
        font-weight: bold;
        font-size: .9rem;
        line-height: .9em;
      }
    }

    .stat_label {
      font-size: 1.5em;
    }

    .only_1_items {
      padding: 0 1rem;

      .network_icon {
        margin: 0 .25rem .5rem;
        display: inline;
        vertical-align: -16px;
      }

      .social_account_list {
        padding: 0 1rem;
      }

      .social_account_item {
        width: 100%;
        text-align: left;
        float: none;
        margin-bottom: .75rem;
      }

      .stat_number, .stat_label {
        display: inline;
      }
    }

    .only_2_items {

      .social_account_item {
        width: 49.33%;
      }
    }
  }

  .mobile_account_stats {

    @include media($break875) {
      display: block;
    }

    .demographics_section {
      padding: 0 1rem;
    }

    .account_header {
      position: relative;
      border-bottom: 1px solid $lightgray-border;
      margin-bottom: 1rem;
      padding: .75rem 0 1rem 3rem;

      @include media($break375) {
        padding: .69rem 0 1rem 3rem;
      }
    }

    .back_btn {
      position: absolute;
      top: 50%;
      left: .6rem;
      background: none;
      padding: 0;
      margin-top: -15px;
      border: none;
    }

    .data_showcase_container {

      .data_showcase_button {
        color: $dark-text;
        margin-right: .4rem;
        width: 100%;
        border-radius: 0;
        border-right: none;
        border-left: none;
        border-top: none;
        padding: 0 0 1rem;

        .audience_report_icon {
          margin-right: .4rem;
          vertical-align: -4px;
        }
      }
    }

    .account_name_container {

      @include media($break875) {
        width: 100%;
        padding-top: 0;
        margin-bottom: .25rem;
      }
    }

    .see_content_link {
      text-decoration: none;
      display: inline-block;

      .content_link_txt {
        color: $revfluence-blue;
      }
    }

    .back_icon {
      width: 26px;
    }

    .details_toggle_link {
      text-decoration: none;
      color: $dark-text;
    }

    .network_icon {
      margin-right: .75rem;
      float: left;
      padding-top: .55rem;
      width: 25px;
    }

    .instagram_icon {}

    .youtube_icon {}

    .twitter_icon {}

    .facebook_icon {}

    .vine_icon {}

    .blog_icon {}

    .snapchat_icon {}

    .pinterest_icon {}

    .googleplus_icon {}

    .account_name_txt {
      float: left;
      width: calc(100% - 37px);
      font-size: 1.5rem;
    }

    .content_link {}

    .arrow_icon {}

    .stats_container {
      padding-bottom: 1.43rem;
      border-bottom: 1px solid $lightgray-border;
      margin-bottom: 1rem;
    }

    .stats_list {}

    .stats_item {
      text-align: center;
      width: 25%;
      float: right;
    }

    .stats_number, .stat_label {
      display: block;
    }

    .stats_number {
      font-size: 1.35rem;
    }

    .stats_label {
      font-size: .8rem;
    }

    .section_title {
      font-size: 1.5rem;
    }
  }
}
