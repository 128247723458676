.new_manage {
  .content_approval_tool {

    .choose_content_container {
      padding-top: 1rem;
    }

    .approve_content {
      padding-bottom: 2rem;

      .story_content_container {

        .dropped_slot_item {
          height: 100%;
        }

        .dropped_img_wrapper {
          height: 100%;
        }
      }

      .no_review_requirement {
        margin-bottom: 1rem;
      }

      .enter_liveLink_fieldset {
        margin-bottom: 1.25rem;

        .enter_link_label {
          font-weight: 600;
        }

        .live_upload_link_input {}
      }

      .request_payment_container {
        margin-left: .15rem;
      }

      .included_payment_section {
        margin-bottom: 1rem;

        .payment_amount_input {
          max-width: 200px;
          margin-bottom: .5rem;
        }

        .content_not_submitted_txt {
          color: red;
          display: block;
        }

        .agreement_reminder_container {

          .reminder_txt {}

          .review_terms_link {
            margin-left: .2rem;
          }
        }
      }

      label {
        display: block;
      }

      .actions_container {
        margin-top: 1.5rem;
        margin-left: .15rem;
      }

      .upload-spinner {
        padding-left: .25rem;
      }
    }
  }
}
