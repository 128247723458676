.new_brand_edit_view {
	max-width: 1000px;
	margin: 4rem auto 0;

	.help-block {
		color: #d9534f;
		font-size: 1.5em;
		margin: .618em 0;
		line-height: 2em;
		font-weight: bold;
	}

	.character_count {
		margin-top: .25rem;
		display: block;
	}

	.companyInfo_header {
		padding-bottom: 2em;
		margin-top: 1.618rem;

		@include media($break620) {
			margin-bottom: 0;
			margin-top: 4.618rem;
		}

		.inner_container {
			position: relative;
			max-width: 1400px;
			margin: 0 auto;
		}

		.back_btn {
			position: absolute;
			top: 11px;
			background: none;
			padding: 0;

			.back_icon {
				width: 30px;
				display: block;
			}
		}
	}

	.main_wrapper {
		padding: 1.4rem 2rem;
		border: 1px solid $lightgray-border;
		border-radius: 7px;
		max-width: 700px;
		margin: 0 auto 3rem;
		background-color: white;
	}

	.brand_logo_label {
		font-weight: 500;
		display: block;
	}

	.companyInfo_title {
		text-align: center;

		@include media($break900) {
			margin: 1.5em 0 0;
		}

		@include media($break550) {
			font-size: 3.5em;
		}

		@include media($break350) {
			font-size: 2.75em;
		}
	}

	.companyInfo_subtitle {
		text-align: center;
		color: $dark-text;
		font-size: 2em;
		margin-bottom: 1rem;
	}

    .creatorsLiked_list {
        text-align: center;
        margin-top: 1.8em;

        li {
            display: inline-block;
            margin-right: 1em;
        }

        .creatorAvatar {
            display: block;
            width: 56px;
            height: 56px;
            @include circle-radius;
        }
    }

	.brandPhoto_form {
		float: left;
		width: 40%;
		margin-right: 5%;

		@include media($break705) {
			width: 100%;
			margin-right: 0;
			margin-bottom: 2rem;
		}

		.img_upload_label {
			display: block;
			cursor: pointer;
			width: 100%;
			overflow: hidden;

			@include media($break705) {
				width: 300px;
			}

			@include media($break375) {
				width: 100%;
			}
		}

		.brandPhoto {
			border: none;
			margin: 0;
			cursor: pointer;
			width: 100%;
			max-width: 100%;
			border-radius: 6px;
		}

		.brandPhoto_input {
		}

		.brandPhoto_details {
			display: block;
			margin-top: .5rem;
		}
	}

	.basicDetails_form {
		float: left;
		width: 55%;

		@include media($break705) {
			width: 100%;
		}

		.section1 {

			.standard_input {
				width: 100%;
			}

			fieldset {
				margin-bottom: 2em;
			}
		}

		fieldset {
			margin-bottom: 1em;
		}

		label {
			margin-bottom: .5em;
			font-weight: 500;
			color: $dark-text;
		}



		.aboutBrand_fieldset {
			margin-bottom: 3em;
		}

    .aboutBrand_fullWidth {
			width: 100%;
		}

		.contactDetails {
			border-top: 1px solid $lightgray-border;
			padding-top: 3em;
			margin-bottom: 3em;

			fieldset {
				display: inline-block;
				@include media($break478) {
					display: block;
				}
			}

			.rightSpacing {
				margin-right: 3em;
				@include media($break478) {
					margin-right: 0;
				}
			}

			.contactHeader {
				margin-bottom: 3em;
			}

			.contactTitle {
				@include title25;
				color: $dark-text;
				margin-bottom: .25em;
			}

			.contactDescription {
				@include fontsize15;
				color: $dark-text;
			}

			input[type="text"] {
				width: 100%;
			}
		}

		.currency_input {
			display: inline-block;

			.dropdown_btn {
			  min-width: 100px;
			  margin-bottom: 0;
			}

			.dropdown_list {
			  display: block;

			  &:before, &:after {
				left: unset;
				right: .5rem;
			  }
			}
		  }
	}

	/* .brandPhoto_img {
		display: block;
		margin-bottom: 1em;
		cursor: pointer;
		max-width: 100%;
	} */

	.comment {
		@include fontsize13;
		margin: 0;
		margin-top: .5em;
		color: $error;
        display: block;
        &.grey {
            color: $dark-text;
        }
	}

	.hint {
		@include fontsize15;
		color: $dark-text;
		display: block;
	}
}
