.new_proposal_message {
  padding-top: 1rem;

  .campaign_title {
    font-size: 1.4em;
    margin-bottom: .75rem;
  }

  .budget_range_container, .desired_content_container, .about_brand_container, .inspiration_container {
    margin-bottom: .75rem;
  }

  .new_proposal_label {
    display: block;
    font-weight: 600;
    margin-bottom: .5rem;
  }

  .new_proposal_descrip {
    display: block;
    margin: .5rem 0;
  }

  .inspiration_container {

    .link_list {}

    .link_item {}

    .link_link {}
  }

  .new_proposal_actions {

    .collab_btn, .dismiss_btn {
      font-size: 1em;
    }

    .collab_btn {
      margin-right: .5rem;
    }

    .dismiss_btn {}
  }
}
