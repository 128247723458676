.new_manage {

  .message_team {

    .randomDiv {
      height: 100%
    }

    .message_randomWrapper {
      height: 100%;
    }

    .create_newTeam_title {
      font-size: 2.5em;
    }

    .shorten_title {

      @include media($break700) {
        display: none;
      }
    }

    .nameTeam_fieldset, .writeMsg_fieldset {
      margin-bottom: .5rem;
      max-width: 400px;

      @include media($break845) {
        margin-bottom: .8rem;
        padding: 0 .75rem;
      }

      .standard_label {}

      .nameTeam_input {}
    }

    .startProject_fieldset {
      padding: .75rem 0 1rem 3px;

      @include media($break845) {
        padding: .75rem .75rem 1rem;
      }

      @include media($break385) {
        font-size: .55rem;
      }

      .checkbox_control {}

      .checkbox {}

      .control-indicator {}

      .text_container {}

      .chooseCampaign {
        padding: .5rem 0 0 1.25rem;

        .chooseCampaign_label {
          margin-right: .5rem;
        }

        .selectCampaign {}
      }
    }

    .creatorsContainer {

      @include media($break845) {
        height: calc(100% - 308px) !important;
      }

      @include media($break630) {
        height: calc(100% - 304px) !important;
      }

      @include media($break500) {
        height: calc(100% - 319px) !important
      }

      @include media($break345) {
        height: calc(100% - 318px) !important
      }

      &.has_startNew_project {

        @include media($break845) {
          height: calc(100% - 435px) !important;
        }

        @include media($break630) {
          height: calc(100% - 432px) !important;
        }

        @include media($break500) {
          height: calc(100% - 460px) !important;
        }

        @include media($break385) {
          height: calc(100% - 455px) !important;
        }

        @include media($break345) {
          height: calc(100% - 474px) !important;
        }
      }
    }
  }
}
