.brand_summary_report {
  /* min-width: 1250px; */
  max-width: 1500px;
  margin: 0 auto;

  .networkStats_item {}

  .statNumber_txt {
    display: inline-block;
    padding-bottom: .3rem;
    font-size: 1.35rem;
    font-weight: 300;
  }

  .statLabel_txt {
    font-size: .75rem;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: .06rem;
    color: #949494;
    display:block;
    padding-bottom: .55rem;
  }

  .metricStatus_container {
    display: inline-block;
    margin-left: 5px;
  }

  .stat_item {
    float: left;
    width: 210px;
    margin-right: .75rem;
  }

  .changeReport_link {
    margin-left: .5rem;
    display: inline-block;
  }

  .printRev_logo {
    display: none;
    margin: 0 auto;
    width: 85px;
  }

  /* HEADER */
  .brandSummary_header {
    padding: 1.35rem 1.5rem 1rem;
    border-bottom: 1px solid $lightgray-border;

    .logos_container {
      float: left;
      position: relative;
      width: 123px;
      height: 92px;
      margin-right: 1.25rem;
    }

    .brand_logo {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 75px;
      border-radius: 6px;
      border: 1px solid $lightgray-border;
    }

    .revfluence_logo {
      width: 75px;
      position: absolute;
      z-index: 1;
      bottom: 0;
      right: 0;
      border-radius: 6px;
      border: 1px solid $lightgray-border;
    }

    .header_txt_container {
      float: left;
    }

    .brandSummary_header_title {}

    .brandSummary_header_descrip {
      font-size: 1.1rem;
    }

    .standard_link {
      cursor: pointer;
    }

    .download_pdf_btn {
      border-radius: 6px;
      float: right;
    }

    .icon_download_pdf, .download_pdf_txt {
      display: block;
      margin: 0 auto;
    }

    .icon_download_pdf {
      margin-bottom: 5px;
    }

    .download_pdf_txt {
      display: block;
    }
  }

  /* SUMMARY SECTION */
  .summary_section {
    padding: 0 1.5rem 1.35rem;

    .noStats_placeholder {
      background-color: $ultraLight_gray;
      padding: .75rem 1.25rem;
      border-radius: 6px;
      display: inline-block;
      font-size: 1rem;
      color: #868686;
      font-weight: 300;
    }

    .summary_title {
      padding-bottom: .85rem;
      font-size: 1.5rem;
    }

    .activity_title {
      padding-bottom: 0;
    }

    .section_icon {
      margin-right: 7px;
      margin-left: 3px;
    }

    .icon_overallSummary {
      width: 27px;
      margin-right: 5px;
      vertical-align: -4px;
      margin-left: 0;
    }

    .summary_list {}

    .summary_item {}

    .network_row {
      padding-bottom: 1.5rem;

      &:last-child {
        padding-bottom: 0;
      }
    }

    .icon_instagram {}

    .icon_youtube {}

    .networkStats_list {}

    .networkStats_item {}

    .colorMetric_status {

      .metricStatus_txt {
        color: #26C63B;
      }
    }

    .grayMetric_status {

      .metricStatus_txt {
        color: #B5B5B5;
      }
    }

    .metricStatus_container {

      .metricStatus_txt {
        font-size: .85rem;
      }

      .icon_up {
        vertical-align: 3px;
      }

      .icon_down {
        vertical-align: 1px;
      }
    }
  }

  .overallSummary_section {
    padding: 1.15rem 1.5rem 1.18rem;
  }

  /* CAMPAIGN BREAKDOWN SECTION */
  .campaignBreakdown_section {
    border-bottom: 1px solid $lightgray-border;
    border-top: 1px solid $lightgray-border;
    padding: 1.15rem 1.5rem 1.18rem;

    .campaignBreakdown_table {
      font-size: 1rem;
      overflow: auto;
    }

    .scrollWrap {
      white-space: nowrap;
      width: 1450px;
    }

    th, td {
      padding: 10px;
    }

    th {
      font-weight: 600;
      text-align: left;
    }

    td {
      width: 8%;
    }

    .row_title {
      font-weight: 600;
      padding: 10px 15px;
      line-height: 1.35rem;
    }

    .campaignBreakdown_table {

      .alternate_row {
        background-color: #F7F7F7;
      }

      .campaignBreakdown_row {}

      .campaignBreakdown_col {
        float: left;
        width: 214px;
      }

      .first_col {
        width: 300px;
      }

      .row_title {}

      span {
        padding: 10px;
        display: inline-block;
        max-width: 100%;
      }

      .row_data {}
    }

    .first_col_link {
      color: $revfluence-blue;

      &:hover {
        color: $revfluence-blue-hover;
      }
    }
  }

  /* SAMPLE CONTENT SECTION */
  .sample_content_section {
    padding: 2.25rem 1.5rem 2.3rem;
    display: block;

    .sample_content_row {
      padding-bottom: 4rem;
      max-width: 1300px;
      margin: 0 auto;

      &:last-child {
        padding-bottom: 0;
      }
    }

    strong {
      font-weight: 600;
    }

    .sample_contentTxt_container {
      float: left;
      width: calc(100% - 641px);
      padding-right: 2rem;
      padding-top: 2.5rem;
    }

    .sample_contentTxt_title {
      padding-bottom: .25rem;
    }

    .sample_contentTxt_descrip {
      font-size: 1.1rem;
    }

    .sample_contentImg_container {
      float: left;
      padding-right: 2rem;
      padding-top: 1rem;
    }

    .sample_content_img {
      width: 295px;
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }

    .alternate_row {

      .sample_contentImg_container {
        padding-right: 2rem;
      }

      .sample_contentTxt_container {
        padding-top: 6rem;
      }
    }

    .final_row {

      .sample_contentTxt_container {
        padding-top: 4.5rem;
      }
    }
  }

  /* CLOSING CTA SECTION */
  .closing_cta_section {
    text-align: center;
    padding: 1.15rem 1.5rem 1.18rem;

    .closing_cta_title {
      padding-bottom: 1rem;
    }

    .closing_cta_btn {}
  }
}

@media print {

  @page {
    margin: 0.5cm;
  }

  .brand_summary_report {
    min-width: initial !important;
  }

  /* TYPOGRAPHY */
  .h2 {
    font-size: 25pt !important;
  }

  .h3 {
    font-size: 22pt !important;
  }

  .statNumber_txt {
    font-size: 10pt !important;
    padding-bottom: 0 !important;
    font-weight: 400 !important;
  }

  .metricStatus_txt {
    font-size: 8pt !important;
  }

  .icon_up {
    vertical-align: 1px !important;
  }

  .brandSummary_header_title {
    font-size: 19pt !important;
    padding-top: 14pt !important;
  }

  .noStats_placeholder {
      font-size: 8pt !important;
    }

  .default_body_copy, .sample_contentTxt_descrip, td {
    font-size: 10pt !important;
  }

  .statLabel_txt {
    font-size: 6pt !important;
    padding-bottom: .18rem !important;
    color: #DFDFDF !important;
  }

  /* HEADER */
  .main_nav, .core_subnav {
    display: none;
  }

  .printRev_logo {
    display: block !important;
    width: 150px !important;
  }

  .brandSummary_header {
    padding: 0 0 1rem !important;
  }

  .header_txt_container {
    padding-top: 0 !important;
  }

  .start_campaign_banner {
    margin-top: 0 !important;
  }

  .brand_logo, .revfluence_logo {
    width: 50px !important;
  }

  .download_pdf_btn {
    display: none;
  }

  .changeReport_link {
    display: none !important;
  }

  .logos_container {
    width: 82px !important;
    height: 66px !important;
    margin-top: 14px !important;
    margin-right: .8rem !important;
  }

  /* BODY */
  .section_padding {
    padding: .5rem 0 1rem !important;
  }

  .campaignBreakdown_col {
    width: 111px !important;
  }

  .first_col {
    width: 175px !important;
  }

  .section_icon {
    vertical-align: -4px !important;
  }

  .icon_overallSummary {
    vertical-align: -6px !important;
  }

  .row_title, .row_data {
    font-size: 10pt !important;
  }

  .row_data {
    font-weight: 300 !important;
  }

  .overallSummary_section {
    padding: 1rem 0 .25rem !important;
  }

  .overallSummary_title {
    padding-bottom: 0 !important;
  }

  .overallSummary_item {
    width: 275px !important;
  }

  .summary_title {
    font-size: 13pt !important;
    font-weight: 300 !important;
  }

  .network_icon {
    width: 20px !important;
    margin-right: 1rem !important;
  }
  .network_row {
    padding-bottom: .5rem !important;

    &:last-child {
      padding-bottom: 0 !important;
    }
  }

  .icon_instagram {
    margin-top: 15px !important
  }

  .icon_youtube {
    margin-top: 20px !important;
  }

  .stat_item {
    width: 120px !important;
    margin-right: 0 !important;
  }

  .overallSummary_item {
    width: 115px !important;
  }

  .metricStatus_container {
    margin-left: 2px !important;
  }

  .metricStatus_txt {
    font-size: 7pt !important;
  }

  .sample_content_row {
    max-width: 100% !important;
    padding-top: 10px !important;
    padding-bottom: 3rem !important;
  }

  .sample_contentTxt_container {
    width: 345px !important;
    padding-right: 15px !important;
    padding-top: 0 !important;
  }

  .sample_contentImg_container {
    width: 370px !important;
    padding-right: 0 !important;
  }

  .sample_content_img {
    width: 180px !important;
    margin-right: 0 !important;
  }

  .closing_cta_section {
    display: none !important;
  }

  .alternate_row {

    .sample_contentTxt_container {
      padding-top: 38px !important;
      padding-left: 9px !important;
    }
  }

  .final_row {

    .sample_contentTxt_container {
      padding-top: 21px !important;
    }
  }

  .sample_contentTxt_descrip {
    font-size: 8pt !important;
  }

  /* BREAKDOWN SECTION */
  .campaignBreakdown_section {
    border-bottom: none !important;
  }

  .campaignBreakdown_table {
    padding-top: 10px !important;

    span {
      padding: 8px !important;
    }
  }

  .row_title, .row_data {
    font-size: 8pt !important;
  }

  .first_col_link {
    color: $dark-text !important
  }

  .activity_title {
    padding-bottom: 0 !important;
  }
}
