/* STARTER HTML

<div class="select_report_modal modal open" style="display:block;">
  <div class="backdrop-close-link"></div>

  <div class="modal-dialog">
    <button type="button" class="popup_close_btn">
      <img src="/images/style_guide/icon_close_dark.svg" class="popup_close_icon">
    </button>

    <div class="modal-content">
      <header class="main_header">
        <a href="#" title="" class="close_modal_btn btn no_shadow_focus"><img src="/images/style_guide/icon_dismiss_gray.svg" alt class="icon_close"></a>
        <h1 class="main_title h3">Select Report</h1>
      </header>
      <section class="main_body"></section>
    </div>
  </div>
</div>
<div class="modal-overlay" id="modal-overlay"></div>
*/

#modals {
  position: relative;
  z-index: 9999;
}

.modal_open {
  overflow: hidden;
}

.modal {
  position: fixed;
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9055;
  -webkit-overflow-scrolling: touch;
  opacity: 0;
  transition: all .1s ease-in-out;

  &:hover {
    text-decoration: none;
  }

  &.open {
    opacity: 1;
    display: flex;
  }

  &.close {
    display: none;
  }

  scrollbar-face-color: #AFAFAF;
  scrollbar-track-color: none;

  ::-webkit-scrollbar {
    width:  6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #D8D8D8;
    border-radius: 100px;
  }

  ::-webkit-scrollbar-track {
    background: none;
  }

  outline: 0;
  animation-name: fade_in;
  animation-duration: .35s;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  animation-play-state: running;
}

.modal-dialog {
  max-width: 600px;
  max-height: 100%;
  background-color: white;
  z-index: 9055;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  width: 100%;

  @include media($break450) {
    width: 97%;
  }

  .main_title {
    text-align: center;
    padding: 1rem 0;
  }

  .modal-content {
    border: none;
  }

  .icon_close {
    width: 15px;
  }

  .modal-header {
    position: relative;

    .close-icon {
      position: absolute;
      top: 1.25em;
      right: 1.25em;
      cursor: pointer;

      &:hover {

        path {
          fill: $label-red;
        }
      }

      path {
        fill: $lightgray-text;
      }
    }
  }
}

.modal-overlay {
  background-color: rgba(0, 0, 0, .85);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9054;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9000;
  display: none;
}

.modal-backdrop.in {
  opacity: 0.90;
}

.modal-backdrop.active {
  display: block;
}

.modal-backdrop.out {
  display: none;
}

.backdrop-close-link {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 8000;
}

.error-modal {

  .close-modal-link {
    width: 20px;
  }

  .close_modal_icon {}
}

.close_modal_btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  padding: 0;
  border: none;

  &:active, &:focus {
    box-shadow: none;
  }

  .close_icon {
    width: 20px;
  }
}

.generic-modal {

 	.modal-body {
 		padding: 4em;
 	}

 	.close-modal-link {
		position: absolute;
		top: 2em;
		right: 2em;

		&:hover {}
	}

 	.modal-title {
 		color: $dark-text;
 		margin-bottom: 1em;
 		text-align: center;
 		font-weight: 300;
 	}

 	.modal-description {
 		color: $dark-text;
 		text-align: center;
 		font-weight: 300;
 		margin-bottom: 2em;
 	}

 	.okay-button {
 		width: 100px;
 		margin: 0 auto;
 		display: block;
 		text-align: center;
 	}
}

.modal {

  .popupMain_title {
    text-align: center;
  }

  .popup_close_btn {
    background: none;
    padding: .5rem;
    border: none;
    position: absolute;
    top: .5rem;
    right: .5rem;
    z-index: 1;
  }

  .newClose_modalBtn {
    position: absolute;
    top: .5rem;
    right: .5rem;

    @include media($break300) {
      top: .5rem;
      right: .5rem;
    }
  }

  .popup_close_icon {
    width: 16px;
    height: 16px;
    display: block;
  }

  .modal_header {
    position: relative;
    padding: 1rem 1rem 0;
    margin-bottom: .5rem;
  }

  .modal_body {
    padding: 0 1rem 1.5rem;

    .popupMain_descrip {
      text-align: center;
      margin-bottom: 1rem;
    }

    .popupMain_actions_container {
      text-align: center;
    }

    .popMain_action {}
  }

  .modal_footer {
    padding: 0 1rem;
  }
}

.darkMinimal_modal {

  .darkMini_closeBtn {
    position: absolute;
    right: 0;
    padding: 0;
    top: -10px;
    border: none;
    background: none;

    .darkMini_closeIcon {
      width: 40px;
      display: block;
    }
  }

  .modal-dialog {
    border: none;
    background: none;
    margin: 90px auto;
    position: relative;
    padding-top: 50px;
    box-shadow: none;
  }
}

.subnav_modal {
  z-index: 5001;
}

.subnav_modal_overlay {
  z-index: 5000;
}

.btn_closeModal {
	top: .5rem;
	right: .5rem;
  position: absolute;
}
