.companyInfo_container {
	margin-bottom: 3em;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    @include media($break721) {
        margin-top: 8em;
    }

	.companyInfo_header {
		margin-bottom: 4em;
		padding-bottom: 2em;
		border-bottom: 1px solid $lightgray-border;
		@include media($break620) {
			margin-bottom: 5em;
		}
	}

	.companyInfo_title {
		@include title30;
		color: $dark-text;
		text-transform: inherit;
		text-align: center;
		display: block;
		margin: 1em 0 .5em;
		@include media($break900) {
			margin: 2.3em 0 .5em;
		}
		@include media($break765) {
			margin: 2.3em 0 .25em;
		}
		@include media($break375) {
			@include title25;
		}
	}

	.companyInfo_subtitle {
		@include fontsize17;
		color: $dark-text;
		text-align: center;
		fon-weight: 300;
	}

	.row {
		overflow: hidden;
	}

    .creatorsLiked_list {
        text-align: center;
        margin-top: 1.8em;

        li {
            display: inline-block;
            margin-right: 1em;
        }

        .creatorAvatar {
            display: block;
            width: 56px;
            height: 56px;
            @include circle-radius;
        }
    }

	.brandPhoto_form {
		width: 290px;
		float: left;
		margin-right: 5%;
		margin-bottom: 3em;
		@include media($break620) {
			float: none;
			margin-right: 0;
			width: 100%;
			margin: 0 auto 5em;
		}

		.brandPhoto {
			border: none;
			margin: 0;
			margin-bottom: 1em;
			cursor: pointer;
			width: 100%;
			@include media($break620) {
				min-width: initial;
				width: 300px;
				margin: 0 auto 1em;
				display: block;
			}
		}

		.brandPhoto_input {
		}

		.brandPhoto_details {
			@include fontsize15;
			color: $dark-text;
			display: block;
			@include media($break620) {
				text-align: center;
			}
		}
	}

	.basicDetails_form {

		.section1 {

			.standard_input {
				width: 100%;
			}

			fieldset {
				margin-bottom: 2em;
			}
		}

		fieldset {
			margin-bottom: 1em;
		}

		label {
			@include fontsize17;
			color: $dark-text;
			margin-bottom: .5em;
			display: block;
			font-weight: 500;
		}

		.standard_input {
			@include standard-input;
			background-color: white;

			&:focus {
				background-color: white;
			}
		}

		.aboutBrand_fieldset {
			margin-bottom: 3em;

			textarea {
				@include standard-input;
				background-color: white;
				width: 100%;
				height: 100px;

				&:focus {
					background-color: white;
				}
			}
		}

        .aboutBrand_fullWidth {
            width: 100%;
        }

		.contactDetails {
			border-top: 1px solid $lightgray-border;
			padding-top: 3em;
			margin-bottom: 3em;

			fieldset {
				display: inline-block;
				@include media($break478) {
					display: block;
				}
			}

			.rightSpacing {
				margin-right: 3em;
				@include media($break478) {
					margin-right: 0;
				}
			}

			.contactHeader {
				margin-bottom: 3em;
			}

			.contactTitle {
				@include title25;
				color: $dark-text;
				margin-bottom: .25em;
			}

			.contactDescription {
				@include fontsize15;
				color: $dark-text;
			}

			input[type="text"] {
				width: 100%;
			}
		}

	}

	/* .brandPhoto_img {
		display: block;
		margin-bottom: 1em;
		cursor: pointer;
		max-width: 100%;
	} */

	.comment {
		@include fontsize13;
		margin: 0;
		margin-top: .5em;
		color: $error;
        display: block;
        &.grey {
            color: $dark-text;
        }
	}

	.hint {
		@include fontsize15;
		color: $dark-text;
		display: block;
	}
}