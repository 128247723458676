.confirmDelete_report_modal, .confirmLose_unsaved_modal {

  .modal-dialog {
    padding: .25rem 2rem 1.65rem;
  }

  .close-modal-link {
    padding: .35rem;
    position: absolute;
    right: .5rem;
    top: .5rem;
  }

  .main_header {}

  .close_modal_btn {
    padding: .35rem;
  }

  .main_title {
    font-size: 1.75rem;
    padding: 1rem 0 .7rem;
  }

  .main_body {}

  .confirmDescrip {
    text-align: center;
    padding-bottom: 1.4rem;
  }

  .confirmDelete_actions_wrapper {
    text-align: center;
  }

  .confirmDelete_btn {
    margin-right: .25rem;
  }

  .btn_cancel {}
}
