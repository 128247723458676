.connect_welcome {

	.modal-dialog {
		width: 835px;
		border-radius: 0;
		@include media($break850) {
			width: 95%;
			margin: 2em auto;
		}
	}

	.modal-header {
		padding: 4em 0;
		background-color: black;
		border-radius: 0;
		background: url('../images/brand_onboarding/welcome_photo.jpg') center -176px no-repeat;
		@include media($break485) {
			padding: 2em 0;
			margin: 0;
		}

		.modal-title {
			text-align: center;
			margin-bottom: .3em;
			@include title30;
			text-transform: uppercase;
			font-weight: 600;
			letter-spacing: .1em;
			color: white;
			@include media($break485) {
				@include title25;
				font-weight: 900;
			}	
		}

		.modal_descrip {
			text-align: center;
		      width: 90%;
		      margin: auto;
		      color: white;
			@include fontsize18;
			font-weight: 300;
			@include media($break485) {
				@include fontsize15;
			}

			strong {
				font-weight: 600;
			}
		}
	}

	.modal-body {
		padding: 2em 0 4em;
		@include media($break485) {
			padding: 0 0 1em;
		}

		.three_components_list {
			overflow: hidden;
			width: 800px;
			margin: 0 auto 3em;
			@include media($break850) {
				width: 100%;
			}
			@include media($break485) {
				width: 90%;
				margin: 0 auto;
			}

			.details {
				opacity: 0;
				display: none;
				position: absolute;
				top: 0;
				left: 0;
				background-color: $dark-text;
				background-color: rgba(68,68,68,.95);
				text-align: center;
				@include fontsize15;
				padding: 2em;
				width: 100%;
				height: 100%;
				transition: all .05s ease-in-out;
			}

			.details_text {
				color: white;
			}

			li {
				float: left;
				position: relative;
				width: 266px;
				padding-top: 1em;
				@include media($break850) {
					width: 33.33%;
				}
				@include media($break485) {
					width: 100%;
					padding-top: 0;
				}

				&:hover {

	        .component_icon {
	        }
	        .component_text {
	        }

					.details {
						opacity: 0;
					}
				}
			}

			.component_icon {
				width: 84px;
				height: 82px;
				display: block;
				margin: 0 auto 2em;
				@include media($break485) {
					display: inline-block;
					margin: 0 auto 0;
				}
			}

			.component_text {
				color: #b1b1b1;
				@include fontsize18;
				display: block;
				text-align: center;
				@include media($break485) {
					display: inline-block;
					vertical-align: 2em;
				}
			}

			.connect_li {

				.connect_icon {
					background: url('../images/brand_onboarding/connect_icon_black.png') center no-repeat;
					@include media($break485) {
						background: url('../images/brand_onboarding/connect_icon_black_sm.png') center no-repeat;
					}
				}

				.connect_text {
					color: $dark-text;
					font-weight: 600;
					vertical-align: 3em;
					@include media($break485) {
						vertical-align: 2em;
					}
				}
			}

			.manage_icon {
				background: url('../images/brand_onboarding/manage_icon_gray.png') center no-repeat;
				@include media($break485) {
					background: url('../images/brand_onboarding/manage_icon_gray_sm.png') center no-repeat;
				}
			}

			.analyze_icon {
				background: url('../images/brand_onboarding/analyze_icon_gray.png') center no-repeat;
				@include media($break485) {
					background: url('../images/brand_onboarding/analyze_icon_gray_sm.png') center no-repeat;
				}
			}
		}

		.connect_descrip {
			@include fontsize15;
			color: $dark-text;
			text-align: center;
			width: 636px; /* Same width as three_components_list */
			margin: 0 auto 2em;
			@include media($break850) {
				width: 90%;
			}

      strong {
        font-weight: 600;
      }
		}

		.start_tindering_button {
			display: block;
			width: 215px;
			margin: 0 auto;
			@include media($break485) {
				width: 95%;
			}
		}
	}
}