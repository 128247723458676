.campaign_confirmation {

	.modal-dialog {
		border-radius: 0;
		width: 780px;
		@include media($break850) {
			width: 90%;
			margin: 2em auto;
		}
	}

	.modal-header {
		background-color: #fff;
		border-bottom: none;
		margin-bottom: 4em;
		padding: 4em 2em 0;
		@include media($break315) {
			padding: 2em 2em 0;
			margin-bottom: 2em;
		}
	}

	.modal-title {
		@include title25;
		color: $dark-text;
		margin-bottom: .3em;
		text-align: center;
		font-weight: 600;
		@include media($break485) {
			@include title25;
			font-weight: 900;
		}
	}

	.modal_subtitle {
		color: $dark-text;
		text-align: center;
		@include fontsize18;
		font-weight: 300;
		@include media($break485) {
			@include fontsize15;
		}
	}

	.modal-body {
		padding-bottom: 3em;
	}

	.creators_liked_list {
		margin: 0 auto 2em;
		width: 95%;

		li {
			margin-right: 1%;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.creator_li {
		border: 1px solid $lightgray-border;
		padding: .5em;
		display: inline-block;
		width: 32%;
		overflow: hidden;
		margin-bottom: 1em;
		@include media($break355) {
			width: 100%;
			display: block;
		}
	}

	.creator_avatar {
		margin-bottom: 1.5em;
		width: 100%;
		max-width: 100%;
		@include media($break355) {
			width: 23%;
			display: inline-block;
			margin: .75em 3% 0.75em 0;
		}
	}

	.creator_username {
		@include fontsize15;
		color: $dark-text;
		display: block;
		text-align: center;
		font-weight: 600;
    margin-bottom: 0.5em;
  	overflow: hidden;
  	@include media($break355) {
  		display: inline-block;
  		text-align: left;
  		vertical-align: 36px;
  		width: 73%;
  		margin-bottom: 0;
  	}
  	@include media($break315) {
  		width: 72%;
  	}
  }

    .no_underline_link {
        text-decoration: none;
    }

	.send_offer_button {
		display: block;
		width: 150px;
		margin: 0 auto;
		text-align: center;
	}

}