.content_approval_tool {

  .choose_content_container {
    padding-top: .35rem;

    .choose_content_title {
      margin-bottom: .6rem;

      @include media($break630) {
        padding: .25rem 0 .25rem .25rem;
      }
    }

    .choose_content_list {
      margin-bottom: 1.5rem;
    }

    .choose_content_item {
      padding: .75rem;
      margin-bottom: .75rem;
      width: 98%;
      border-radius: 5px;
      position: relative;

      &.interactable {
        cursor: pointer;
      }

      .primary_action {
        position: absolute;
        right: 30px;
        top: 30px;
      }

      .btn_link_inline {
        cursor: pointer;
      }

      .additionalActionsContainer {
        position: absolute;
        right: 30px;
        top: 27px;

        .buttonWrapper {
          background: inherit;
          border: none;
          width: 40px;
          height: 40px;
          position: relative;
        }

        .markCompleteBtn {
          background: #FFFFFF;
          border: 1px solid #D9D9D9;
          border-radius: 6px;
          width: 40px;
          height: 40px;
          cursor: pointer;
          &:hover {
            background: $aiq_lightblue_bg;
            border: 1px solid #167CF4;
            color: #167CF4;
          }
        }

        .additionalActionsDropdown {
          cursor: pointer;
          padding: 13px 16px;
          position: absolute;
          width: 160px;
          top: 56px;
          right: -22px;
          background: #FFFFFF;
          box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, .05),
          0px 6px 16px rgba(0, 0, 0, .08),
          0px 3px 6px -4px rgba(0, 0, 0, 0.12);
          z-index: 1;

          &:hover {
            background-color: #e6f7ff;
            color: #167CF4;
          }
        }

        .linkOverride {
          display: inline;
          color: $aiq_darkblue;
        }
      }
    }

    .content_additional_info {
      padding-top: 0.5rem;
      font-weight: 300;
      color: $gray-text
    }

    /*** Statuses ***/

    .ready_content_item {
      border: 1px solid $lightgray-border;
      background: white url('/images/new_manage/right_content_nav_icon.svg') 96% center no-repeat;
      transition: all .15s ease-in-out;

      @include media($break630) {
        width: 100%;
      }

      &:hover {
        transform: translate(5px,0);
      }

      .content_submission_status {
        font-weight: 600;
      }
    }

    .uce_ready_content_item {
      border: 1px solid $lightgray-border;
      transition: all .15s ease-in-out;

      @include media($break630) {
        width: 100%;
      }

      .content_submission_status {
        font-weight: 600;
      }
    }

    .available_content_item {
      border: 1px solid $lightgray-border;
      background: white url('/images/new_manage/right_content_nav_icon.svg') 96% center no-repeat;
      transition: all .15s ease-in-out;

      &:hover {
        transform: translate(5px,0);
      }

      .content_submission_status {
        font-weight: 300;
      }

      .primary_alert_icon {
        display: none;
      }
    }

    .waiting_content_item {
      border: 1px solid #E9E9E9;
      cursor: default;

      &.interactable {
        cursor: pointer;
        background: #FAFAFA url('/images/new_manage/right_content_nav_icon.svg') 96% center no-repeat;

        &:hover {
          background: #EFEFEF url('/images/new_manage/right_content_nav_icon.svg') 96% center no-repeat;
        }
      }
    }

    .blocked_content_item {
      border: 1px solid #E9E9E9;
      cursor: default;
      background: #FAFAFA;
    }

    .rejected_content_item {
      border: 1px solid $lightgray-border;
      background: #FAFAFA url('/images/style_guide/icon_error.svg') 96% center no-repeat;
      cursor: default;
    }

    .rejected_ready_content_item {
      border: 1px solid $lightgray-border;
      background: white url('/images/style_guide/icon_error.svg') 96% center no-repeat;
      transition: all .15s ease-in-out;

      &:hover {
        transform: translate(5px,0);
      }

      .content_submission_status {
        font-weight: 600;
      }
    }

    .sent_content_item {
      border: 1px solid $lightgray-border;
      background: #FAFAFA url('/images/style_guide/completed_icon.svg') 96% center no-repeat;
      transition: all .15s ease-in-out;

      &:hover {
        transform: translate(5px,0);
      }
    }

    .blocked_content_item {
      background: #FAFAFA;
      border: 1px solid $lightgray-border;
      transition: all .15s ease-in-out;
      cursor: default;
    }

    .marked_complete_content_item {
      background: #FAFAFA url('/images/style_guide/completed_icon.svg') 96% center no-repeat;
      border: 1px solid $lightgray-border;
      transition: all .15s ease-in-out;
    }

    .sent_content_item, .rejected_content_item, .waiting_content_item,
    .blocked_content_item, .marked_complete_content_item {

      .primary_alert_icon {
        display: none;
      }

      .content_submission_status {
        font-weight: 300;
      }
    }

    .inactive {
      border: 1px solid #E9E9E9;
      background: #f9f9f9 url('/images/new_manage/right_content_nav_icon.svg') 96% center no-repeat;
    }

    .img_container {
      position: relative;
      float: left;
      margin-right: 1rem;
      height: 50px;
    }

    .network_indicator {
      position: absolute;
      top: -7px;
      left: -7px;
    }

    .content_img {
      width: 50px;
      border: 2px solid white;
      box-shadow: 1px 1px 3px rgba(0,0,0,.15);
      border-radius: 6px;
      max-height: 50px;
    }

    .text_container {
      float: left;
      width: calc(93% - 66px);
      padding-top: .85rem;
    }

    .info_container {
      float: left;
      width: calc(93% - 66px);
    }

    .primary_alert_icon {
      padding: .075rem;
      float: left;
      margin-right: .5rem;
    }

    .txt_container2 {
      float: left;
      width: calc(100% - 31px);
      text-align: left;
    }

    .content_info_list {}

    .content_info_item {}

    .content_submission_status {}

    .upload_new_content_container {
      position: relative;
      display: inline-block;

      &:hover {
        .choose_network_dropdown {
          display: block !important;
        }
      }
    }

    .choose_network_dropdown {
      width: 130px;
      left: 2rem;
      top: 2.3rem;
      padding: .75rem 1rem;
      display: none;

      &:after, &:before {
        left: 60px;
      }
    }

    .upload_content_btn {

      &:hover {

        .choose_network_dropdown {
          display: block !important;
        }
      }
    }

    .network_list {}

    .network_item {
      float: left;
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }

    .network_btn {
      width: 40px;
      padding: 6px 11px;
      border-radius: 8px;

      &:hover {
        border: 1px solid $revfluence-blue;
      }
    }

    .extra_content_container {}

    .extra_content_header {
      margin-bottom: .5rem;
    }

    .extra_content_descrip {
      margin-bottom: .7rem;
    }

    .choose_content_btn {

      .txt_container2 {
        font-weight: 300;
      }
    }
  }
}
