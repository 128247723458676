.start_campaign_blocker, .start_subscription_blocker, .default_modal {

	.startSub_main_header {
		position: relative;
		padding: 5em 3em 0;
		box-shadow: none;
	}

	.close-modal-link {
		position: absolute;
		right: 20px;
		top: 20px;
		width: 15px;
		height: 15px;
		background: url('/images/demo_product/close_icon.svg') center no-repeat;
	}

	.startSub_main_title {
		font-size: 3em;
		color: $dark-text;
		line-height: 1.5em;
		margin-bottom: .25em;
		font-weight: 300;
		text-align: center;
	}

	.main_body {
		padding-bottom: 6em;
	}

	.main_descrip {
		color: #8b8b8b;
		font-size: 1.5em;
		line-height: 1.5em;
		margin-bottom: 2em;
		text-align: center;
		font-weight: 300;
		margin-left: 3em;
		margin-right: 3em;
	}

	.more_descrip {
		color: #8b8b8b;
		font-size: 1.5em;
		line-height: 1.5em;
		text-align: center;
		font-weight: 300;
		margin-top: 2em;
		margin-left: 3em;
		margin-right: 3em;
	}

	.main_descrip, .more_descrip {
		a {
			color: $revfluence-blue;
			text-decoration: none;
		}
	}

	.start_campaign_button {
		display: block;
		margin: 0 auto;
		width: 200px;
	}
}
