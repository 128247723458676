.successful-payment {

	.close-modal-link {
		position: absolute;
		top: 1em;
		right: 1em;
		width: 20px;
		height: 20px;
		background: url('../images/creator-payments/close_icon.svg') center no-repeat;

		&:hover {}
	}

	.modal-dialog {
		padding: 5em 0;
		max-width: 750px;

		@include media($break775) {
			padding: 4em;
		}

		@include media($break375) {
			padding: 3em;
		}
	}

	.modal-body {
		padding: 0 5em;
		@include media($break600) {
			padding: 0;
		}
	}

	.modal-header {
		background: white;
		border-bottom: none;
	}

	.visual-container {
		margin-bottom: 2em;

		.payment-icon {
			display: block;
			margin: 0 auto;
			width: 50px;
		}
	}

	.modal-title {
		@include title20;
		font-weight: 200;
		color: $dark-text;
		font-size: 1.65rem;
		text-align: center;
	}

	.successful-payment-descrip {
		@include fontsize15;
		color: $dark-text;
		text-align: center;
		margin-bottom: 1em;
	}

	.buttons-list {
		text-align: center;

		li {
			display: inline-block;
			@include media($break375) {
				display: block;
			}
		}

		.billing-info-button {}

		.finish-button {
			@include media($break375) {
				width: 100%;
			}
		}
	}
}
