.quickmatch {

  .quickmatchBatch_complete_container {
    width: 500px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #ededed;
    margin: 14em auto 0;
    padding: 6em 5em;
    display: none;

    @include media($break540) {
      width: 95%;
      padding: 6em 2em;
      margin: 12em auto 0;
    }

    @include media($break390) {
      padding: 3em 2em;
    }

    .main_title {
      font-size: 2.5em;
      color: #363636;
      margin-bottom: .75em;
      line-height: 1.5em;
      font-weight: 300;
      text-align: center;
      padding-right: .75rem;

      @include media($break530) {
        font-size: 2em;
      }
    }

    .main_descrip {
      font-size: 1.55em;
      color: #a9a9a9;
      font-weight: 300;
      line-height: 1.5em;
      text-align: center;
      margin-bottom: 2em;
    }

    .more_creators_button {
      background-color: $gray-bg;
      padding: .75em 1.5em;
      color: white;
      display: block;
      text-align: center;
      text-decoration: none;
      font-size: 1.6em;
      border-radius: 3px;
      text-align: center;
      margin: 0 auto;
      width: 160px;
      cursor: pointer;

      @include media($break390) {
        width: 100%;
      }

      &:hover {
        background-color: $gray-bg-hover;
      }
    }
  }

  .smallScreen_actions_container {
    display: none;
    width: 331px;
    position: fixed;
    left: 50%;
    bottom: 25px;
    z-index: 5000;
    margin-left: -166px;

    @include media($break320) {
      width: 240px;
      margin-left: -120px;
    }

    .remaining_creators_container {
      display: none;
    }

    .btn_text {
      display: none;
    }

    .main_actions_list {
      text-align: center;
    }

    .main_action_item {
      padding-left: 1rem;
      padding-right: 1rem;
      display: inline-block;

      @include media($break325) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }

    .offer_btn {
      border: 1px solid white;
    }

    .recommended_btn {
      padding: 0;
    }

    @include media($break450) {
      display: block;
    }

    @include media($break325) {

      .action_icon {
        width: 60px;
      }

      .main_action_item {
        padding: 0 .5rem;
      }
    }
  }

  .post-list {
    text-align: center;
    margin: 0 auto;

    li {
      display: inline-block;
      float: left;
      width: 25%;
      position: relative;
      margin-bottom: -1px;
      border-bottom: 1px solid $lightgray-bg;
      border-right: 1px solid $lightgray-bg;
      @include media($break936) {
        width: 33.33%;
      }

      @include media($break620) {
        width: 50%;
      }

      @include media($break423) {
        width: 100%;
        border-right: none;
      }

      .post-info {
        opacity: 0;
        background-color: black;
        background-color: rgba(0,0,0,.8);
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        padding: 2em;
        transition: all 0.25s ease;

        @include media($break768) {
          display: none;
        }
      }

      img {}

      .post-caption {
        text-align: center;
        line-height: 1.7em;
        font-size: 1.3em;
        color: white;
        overflow: hidden;
        height: 220px;
        font-weight: 200;
        @include media($break1195) {
          height: 195px;
        }
        @include media($break1099) {
          height: 170px;
        }
        @include media($break1015) {
          height: 163px;
          @include fontsize14;
        }
        @include media($break975) {
          height: 142px;
        }
        @include media($break936) {
          height: 222px;
        }
        @include media($break909) {
          height: 204px;
        }
        @include media($break848) {
          height: 185px;
        }
        @include media($break792) {
          height: 164px;
        }
        @include media($break735) {
          height: 144px;
        }
        @include media($break673) {
          height: 123px;
        }
        @include media($break620) {
          height: 225px;
        }
        @include media($break600) {
          height: 205px;
        }
        @include media($break553) {
          height: 184px;
        }
        @include media($break510) {
          height: 163px;
        }
        @include media($break470) {
          height: 145px;
        }
        @include media($break423) {
          @include fontsize16;
          height: 315px;
        }
        @include media($break388) {
          height: 292px;
        }
        @include media($break360) {
          height: 265px;
        }
        @include media($break336) {
          height: 245px;
        }
        @include media($break311) {
          height: 218px;
        }
        @include media($break283) {
          height: 197px;
        }

        a {
          color: white;
        }
      }

      .post-likes {
        text-align: center;
        position: absolute;
        bottom: 3em;
        width: 100%;
        left: 0;

        .like-icon {
          display: inline-block;
          margin-right: .5em;
          vertical-align: -3px;
        }

        .like-text {
          display: inline-block;
          @include fontsize14;
          color: white;
        }
      }

      &:hover {
        .post-info {
          opacity: 1;
          border-bottom: 1px solid $lightgray-bg;
        }
      }

      img {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  .creator_content_container {

    .main_content {

      .overview_main_container {
        margin-bottom: 2em;
      }

      .inner_container {
        max-width: 1400px;
        margin: 0 auto;
        background-color: white;
        box-shadow: 1px 1.732px 10px 0px rgba(1, 1, 1, 0.02);
        padding: 0 1.618rem;

        @include media($break420) {
          padding: 0 .9rem;
        }
      }

      .overview_stats_container {
        overflow: hidden;
        padding: 4em;
      }

      .overview_stats_list {}

      .overview_stats_item {
        float: left;
        width: 15%;
        margin-right: 7em;
        border-radius: 8px;
        padding: 2em;
        cursor: pointer;
        border: 1px solid white;

        &:last-child {
          margin-right: 0;
        }
        /*
                &:hover {
                    border: 1px solid #ececec;
                    box-shadow: 1px 1px 5px rgba(0,0,0,.045);
                }
        */
      }

      .stat_number {
        display: block;
        font-size: 3em;
        color: $dark-text;
        font-weight: 300;
        margin-bottom: .25em;
      }

      .stat_label {
        display: block;
        font-size: 1.3em;
        color: $dark-text;
        font-weight: 300;
        line-height: 1.5em;
      }

      .posts_stat {}

      .like_stat {}

      .comments_stat {}

      .impressions_stat {}

      .active_stat {
        border: 1px solid #dcdcdc;
        box-shadow: 1px 1px 5px rgba(0,0,0,.095);

        &:hover {
          border: 1px solid #dcdcdc;
        }

        .stat_number {
          font-weight: 600;
        }

        .stat_label {
          font-weight: 600;
        }
      }

      .graph_container {
        padding: 0 4em 4em 4em;
        margin-bottom: 2em;
      }

      .graph_title {
        font-size: 1.65em;
        color: $dark-text;
        line-height: 1.5em;
        text-align: center;
        font-weight: 600;
        margin-bottom: 1.5em;
      }

      .sample_barchart {
        max-width: 100%;
      }
    }

    .content_main_container {

      .content_list {
        margin: 0 auto;
        overflow: hidden;
        width: 100%;
        max-width: 1600px;

        @include media($break900) {
          padding: 0 1rem 2rem;
          margin-top: 1rem;
        }
      }

      .content_item {
        width: 25%;
        margin-right: 0;
        margin-bottom: 0;
        float: left;
        background-color: white;
        border: 1px solid $lightgray-border;

        @include media($break700) {
          width: 48.33%;
        }

        @include media($break600) {
          width: 100% !important;
        }

        @include media($break465) {
          float: none;
          width: 100%;
          margin: 0 auto 2em;
        }

        .imgContent {
          position: relative;
        }

        .captionContainer {
          display: none;
        }

        .content_image_container {
          height: auto;
          opacity: .99;
          z-index: 1;
        }

        .videoContent_container {
          height: 200px;
        }

        .imgContent_container {
          height: auto;
        }

        .txtContent_container {
          height: 200px;
        }
      }

      .play_icon {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -25px;
        margin-left: -25px;
        width: 50px;
        height: 50px;
        display: block;
        background: url('/images/analyze/play_icon.svg') center no-repeat;
      }

      .content_stats {
        padding: 1.25em 1.5em;
      }

      .content_stats_list {}

      .content_stats_item {
        font-size: 1.25em;
        color: $dark-text;
        display: inline-block;
        margin-right: 1em;
        line-height: 1.5em;
        font-weight: 300;

        &:last-child {
          margin-right: 0;
        }
      }

      .content_caption {
        font-size: 1.25em;
        color: $dark-text;
        line-height: 1.5em;
        font-weight: 300;

        a {
          color: $revfluence-blue;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .content_metadata {
        padding: 0 1.5em 1.25em;
        border-bottom: 1px solid #eeeeee;
      }

      .content_metadata_list {}

      .content_metadata_item {
        color: #b4b4b4;
        display: inline-block;
        margin-right: 1em;
        font-size: 1.25em;
        line-height: 1.5em;
        font-weight: 300;
        &:last-child {
          margin-right: 0;
        }
      }

      .date_published {}

      .creator_type {
        display: inline-block;
        width: 15px;
        height: 15px;
        vertical-align: -4px;
      }

      .content_metadata_image {
        display: inline-block;
      }

      .revfluence_creator {}

      .content_creator_info {
        overflow: hidden;
      }

      .creator_profile_link {
        display: block;
        overflow: hidden;
        padding: 1.5em;
        &:hover {
          background-color: #fbfdff;
        }
      }

      .avatar_container {
        float: left;
        width: 50px;
        margin-right: 1.25em;
      }

      .avatar_image {
        width: 50px;
        height: 50px;
        display: block;
        border-radius: 100px;
      }

      .text_container {
        float: left;
        margin-top: .65em;
      }

      .creator_username {
        font-size: 1.25em;
        font-weight: 600;
        line-height: 1.5em;
        text-decoration: none;
        color: #a3a3a3;
      }

      .network_icon {
        width: 15px;
        height: 15px;
        display: inline-block;
        margin-right: .5em;
        vertical-align: -3px;
      }

      .network_type_text {
        font-size: 1.25em;
        line-height: 1.5em;
        color: #a3a3a3;
        font-weight: 300;
      }
    }

    .loadMore_container {
      padding: 1rem;

      .loadMore_btn {
        width: 200px;
        display: block;
        margin: 0 auto;
      }
    }

    .new_content_list {

      .default_body_copy {
        font-size: .9rem;
      }

      .engageList {
        padding: .9rem .6rem .6rem;
      }

      .accoutLink {
        color: $dark-text;
        text-decoration: none;
      }

      .txtContent_container {
        border-radius: 5px 5px 0 0;
      }

      .content_container {
        border: none;
      }

      .retweetItem {}

      .loveItem {}

      .likeItem {}

      .viewItem {}

      .shareItem {}

      .engageItem {
        float: left;
        margin-right: 1rem;

        &:last-child {
          margin-right: 0;
        }
      }

      .engageIcon {
        width: 17px;
      }

      .likeIcon {
        vertical-align: 1px;
      }

      .retweetIcon {}

      .loveIcon {
        vertical-align: -2px;
        width: 16px;
      }

      .viewIcon {
        width: 22px;
        vertical-align: 1px;
      }

      .shareIcon {
        width: 20px;
      }

      .engageAmount {
        vertical-align: 2px;
        color: $dark-text;
        text-decoration: none;
      }

      .account_section {
        border-bottom: 1px solid $lightgray-border;
        padding: 0 .6rem 1rem;
        font-size: .8rem;
      }

      .avatarImg {
        max-width: 100%;

        &:hover {
          opacity: .9;
        }
      }

      .account_network_icon {
        position: absolute;
        bottom: -2px;
        right: -7px;
        width: 25px;
        z-index: 1;
      }

      .avatar_container, .txt_container {
        float: left;
      }

      .avatar_container {
        width: 45px;
        position: relative;
      }

      .txt_container {
        width: calc(100% - 61px);
      }

      .instagram_icon {}

      .youtube_icon {}

      .snapchat_icon {}

      .vine_icon {}

      .facebook_icon {}

      .blog_icon {}

      .pinterest_icon {}

      .accountName_container {
        line-height: 1rem;
      }

      .accountName {
        font-weight: 600;
        display: inline-block;
        max-width: calc(100% - 98px);
        margin-right: .15rem;
        color: $dark-text;
        text-decoration: none;

        &:hover {
          color: $revfluence-blue;
        }
      }

      .accountFollowers {
        display: inline-block;
        width: 86px;
      }

      .dateContainer {}

      .dateTxt {}

      .revLogo {
        width: 27px;
        vertical-align: -9px;
      }
    }

    .valueSection {
      border-bottom: 1px solid $lightgray-border;
      padding: .5rem .6rem;
      font-size: .8rem;

      > li {
        float: left;
        margin-right: 3%;

        &:last-child {
          margin-right: 0;
        }
      }

      .standard_tooltip {
        width: 250px;
        left: 50%;
        bottom: initial;

        &:after, &:before {
          display: none;
        }
      }

      .estPaid_item {
        width: 29%;
      }

      .mediaValue_item {
        width: 37%;
      }

      .roi_item {
        width: 28%;
      }

      .valueLabel {
        display: block;
      }

      .hint_btn {
        padding: 0;
        border: none;
        background: none;
      }

      .hint_icon {
        background: none;
      }

      .valueAmount {
        display: block;
      }
    }
  }

  .no_suggestions, .no_invites, .recommendedError {
    padding: 4rem 5em 5em;
    overflow: hidden;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;

    @include media($break830) {
      padding: 10rem 1rem 5em;
    }

    @include media($break400) {
      padding: 8rem 1rem 5em;
    }

    .filters_link_container {
      color: $dark-text;

      .filters_link {
        @include blue-link;
      }
    }

    .search_link {
      @include blue-link;
    }

    .noSuggests_innerContainer, .recommendedError_innerContainer {
      width: 62em;
      margin: 0 auto;

      @include media($break775) {
        width: 100%;
      }
    }

    .recommendedError_title {
      font-size: 1.35rem;
      text-align: center;
      color: $dark-text;
      font-weight: 300;
      margin-bottom: .5em;

      @include media($break400) {
        font-size: 1.15rem;
      }
    }

    .noSuggests_descrip, .recommendedError_descrip {
      @include fontsize15;
      color: $dark-text;
      text-align: center;
    }
  }

  .content_item, .content_image_container, .captionContainer, .content_container, .videoThumb, .imgContent {
    border-radius: 0 !important;
  }

  .content_list {

    @include media($break900) {
      padding: 0 !important;
      margin-top: 0 !important;
    }
  }

  .content_item {

    @include media($break700) {
      width: 50% !important;
    }

    @include media($break465) {
      margin: 0 auto !important;
      width: 100% !important;
    }
  }

  .content_image_container {

    @include media($break360) {
      margin: 0 !important;
    }
  }
}
