.new_createNew_campaign {
  padding-top: 25px;

  .nav_tabs {
    display: none;
  }

  .active_tab_content {
    border: 1px solid $lightgray-border;
    border-radius: 8px !important;
    margin-bottom: 2rem;
    border-bottom: 1px solid $lightgray-border !important;
  }

  .campaignCreation_steps {
    padding-bottom: 2rem;
    position: relative;

    @include media($break515) {
      padding-bottom: 1.25rem;
    }

    .dividerLines_container {
      position: absolute;
      top: 1rem;
      left: 0;
      width: 100%;
      padding: 0 7rem;

      @include media($break720) {
        padding: 0 11%;
      }

      .dividerLine {
        border-bottom: 1px solid $lightgray-border;
        height: 2px;
        width: 100%;
        float: left;
      }
    }

    .campaignCreation_stepsList {

      > li {
        text-align: center;
        float: left;
        width: 20%;

        .stepNumber_indicator {
          background-color: white;
          border: 1px solid $lightgray-border;;

          .stepNumber_txt {
            color: #8C8C8C;
          }
        }

        .step_descrip {
          color: #949494;

          @include media($break515) {
            display: none;
          }
        }

        .optional_indicator {
          display: block;
          color: #949494;

          @include media($break515) {
            display: none;
          }
        }
      }

      .stepNumber_indicator {
        width: 35px;
        height: 35px;
        border-radius: 100px;
        text-align: center;
        position: relative;
        margin: 0 auto .5rem;
      }

      .stepNumber_txt {
        position: absolute;
        top: 4px;
        left: 50%;
        margin-left: -5px;
      }

      .current_step {

        .stepNumber_indicator {
          background-color: $aiq_darkblue;
          border: 1px solid $aiq_darkblue;

          .stepNumber_txt {
            color: white;
          }
        }

        .step_descrip {
          font-weight: 600;
          color: $dark-text;

          @include media($break515) {
            display: block;
          }
        }
      }

      .completed_step {

        .stepNumber_indicator {
          background-color: #CBCBCB;
          border: 1px solid #CBCBCB;
        }

        .stepNumber_txt {
          color: white !important;
        }

        .step_descrip {
          color: #949494;

          @include media($break515) {
            display: none;
          }
        }
      }
    }
  }

  .createCampaign_actions {
    padding-top: 1.5rem;

    .btns_container {
      text-align: center;
    }

    .save_btn, .skip_btn {
			margin: 0 .25rem;

      @include media($break450) {
        display: block;
        width: 100%;
				margin: .5rem 0;
      }
    }

    .skip_mention {
      text-align: center;
      padding-top: 1rem;
    }
  }
}
