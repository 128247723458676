.new_manage {

  .content_approval_tool {

    .upload-spinner{
      height:20px;
      width:20px;
    }

    .content_approval_modal {

      .modal-dialog {
        padding: 30px 50px;

        @include media($break450) {
          padding: 25px 20px;
        }
      }

      .add_types_title {

        @include media($break345) {
          font-size: 1.25rem;
          line-height: 1.65rem;
        }
      }

      .unmet_guidelines{
        float: none;
        margin: 30px;
        display: block;
      }

      .content_checklist{
        padding: 5px 15px;
        border: 1px solid $lightgray-border;
        border-radius:6px;
      }

      .label_guideline{
        font-size: 1.5em;
        line-height: 1.5em;
        font-weight: 300;
        display: inline;
      }

      .unmet_guidelines {

        @include media($break650) {
          margin: 30px 0;
        }

        .checklist_item {
          padding: .65rem 0;

          &:nth-last-child(2) {
            border-bottom: 1px solid $lightgray-border;;
          }

          &:last-child {
            border-bottom: none;
          }
        }

        .text_container {
          float: none;
          display: inline;
          width: 100%;
        }
      }

      .go_back_btn {
        margin-right: .5rem;

        @include media($break320) {
          margin-bottom: 1rem;
        }
      }

      .btn {

        @include media($break320) {
          display: block;
          width: 100%;
        }
      }
    }
  }
}
