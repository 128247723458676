.core_subnav {
  background-color: #F9F9F9;
  background-color: rgba(248,248,248,0.98);
  border-bottom: 1px solid $lightgray-border;
  z-index: $zindexBody;
  position: fixed;
  top: 91px;
  left: 0;
  width: 100%;
  height: 42px;

  @include media($break843) {
    top: 50px;
  }

  /* Help Center Link */
  .desktopHelp_center_link {
    float: right;
    padding: 7px 8.7px 7px 8.7px;

    @include media($break1130) {}

    @include media($break885) {}
  }

  .inner_container {
  	margin: 0 auto;
  }

  .help_center_link {

    @include media($break1130) {
      right: 0;
      top: 0;
      position: absolute;
    }

    .help_link_txt {

      @include media($break843) {
        display: none;
      }
    }
  }

  .campaignSelection_dropdownBtn {
    padding: 6px 25px 6px 7px;
    position: relative;
    font-weight: 300;

    &:hover, &:focus {
      background-color: $ultraLight_gray;
    }

    &:active {
      background-color: $lightgray-border;
    }

    .campaignBtn_title {
      font-size: .9rem;
      width: calc(100% - 33px);
      margin-top: 2px;
    }

    .icon_dropdown_indicator {
      position: absolute;
      top: 15px;
      right: 7px;
      width: 10px;
    }
  }

  .campaignSelection_dropdownBtn, .campaign_button {
  	background-color: white;
  	border-radius: 0;

  	&:active, &:focus {
  		box-shadow: none;
  	}
  }

	.campaign_dropdown_active {

		&:hover {
			.dropdown_list {
				display: block;
			}
		}
		.campaignSelection_dropdownBtn {
			padding: 6px 27px 6px 6px;
			border-radius: 0;

			@include media($break575) {
				padding: 6px 26px 6px 7px;
        width: 63px;
			}
		}
	}

  .core_links_container {
    position: relative;
    float: left;

    @include media($break1130) {
      float: none;
    }

  	.core_links_list {
      margin-left: .5rem;

      @include media($break1130) {
        display: none;
      }
  	}

    /* SMALL SCREEN LINKS */
    .small_coreLinks_wrapper {
      position: relative;
    }

  	.core_links_item, .small_coreLinks_item {
  		float: left;
  	}

    .small_coreLinks_item:nth-child(2n) {

      @include media($break360) {
        display: none;
      }
    }

  	.core_link {
  		color: $dark-text;
  		text-decoration: none;
      cursor: pointer;
      padding: 9px 10px 10px;
      display: inline-block;

      @include media($break320) {
        font-size: .85rem;
        padding: 9px 8px 11px;
      }

  		&:hover {
  			color: $revfluence-blue;
  		}
  	}

  	.active_core_link {
      color: $revfluence-blue;

      &:hover {
        color: $revfluence-blue-hover;
      }
  	}
  }

  .campaignNav_selection_container {
    border-right: none;
    float: left;
    position: relative;
    width: 215px;
    display: none;

    @include media($break843) {
      display: inline-block;
    }

    @include media($break575) {
      width: 75px;
    }

    &:hover {

      .campaign_dropdown_list {
        display: block;
      }
    }

    .campaignBtn_img {
      float: left;
      margin-right: .4em;
      width: 27px;
      height: 27px;
      border-radius: 100px;

      @include media($break575) {
        margin-right: 0;
      }
    }

    .campaign_img {
      float: left;
      margin-right: .4em;
      width: 27px;
      height: 27px;
      border-radius: 100px;
    }

    .selected_indicator {
      display: none;
      width: 13px;
      margin-right: 9px;
      float: left;
      margin-top: 10px;
    }

    .campaign_actions_container {
      width: 22px;
      float: right;
      margin-top: 2px;

      .edit_icon {
        display: block;
        padding: 0;
      }

      .edit_icon_light {
        display: none;
      }
    }

    .campaign_dropdown_list {
      width: 260px;
      top: 41px;
      left: 3px;;
      padding: 1em 0 .7em;
      display: none;

      @include media($break575) {
        right: -203px;
        top: 44px;
      }

      .campaign_item {
        padding: 0;
      }

      &:after, &:before {
        left: 19px;
      }

      .campaign_btn {
        display: block;
        background: none;
        border-radius: 0;
        width: 100%;
        padding: 6px 12px;
        font-weight: 300;

        &:hover {
          color: $dark-text;
        }

        .campaign_title {
          width: calc(100% - 85px);
          font-size: .82rem;
          float: left;
        }

        .edit_link {
          padding: 0;
          display: inline-block;

          &:hover {
            background: none;
          }
        }

        .actions_container {
          float: right;
        }
      }

      .active_campaign_btn {
        background-color: $revfluence-blue;
        &:hover {
          background-color: $revfluence-blue;
        }

        .selected_indicator {
          display: block;
        }

        .campaign_title {
          color: white;
        }

        .edit_icon {
          display: none;
        }

        .edit_icon_light {
          display: block;
        }

        .actions_container {
          margin-right: -3px;
        }
      }

      .campaign_title {
        @include media($break575) {
          display: inline-block;
        }
      }
    }

    .campaign_list {
      max-height: 300px;
      overflow: auto;
      scrollbar-face-color: #AFAFAF;
      scrollbar-track-color: none;
      background-color: white;

      ::-webkit-scrollbar {
        width:  6px;
      }

      ::-webkit-scrollbar-thumb {
        background: #D8D8D8;
        border-radius: 100px;
      }

      ::-webkit-scrollbar-track {
        background: none;
      }
    }

    .campaign_item {
      padding: 6px 15px;

      &:hover {
        background-color: $lightgray-border;
      }
    }

    .campaign_button {
      padding: 0;
      border: none;
      background: none;
      height: 27px;
      width: 100%;
      text-align: left;
    }

    .add_campaign_item {
      border-top: 1px solid $lightgray-border;
      display: block;
    }

    .campaign_link {
      padding: 7px 12px 9px;
      background: none;
      border-radius: 0;
      cursor: pointer;

      &:active {
        box-shadow: none;
      }

      &:hover {
        background-color: $lightgray-border;
      }
    }

    .add_campaign_link {
      color: $revfluence-blue;
      padding: 7px 12px 8px;

      &:hover {
        background-color: $lightgray-border;
      }
    }

    .add_campaign_icon {
      width: 25px;
      display: inline-block;
      margin-right: 9px;
    }

    .add_campaign_text {
      display: inline-block;
      vertical-align: 6px;
      color: $revfluence-blue;
      font-weight: 300;
    }
  }

  .campaign_selection_container {
    display: none;

    @include media($break843) {
      display: block;
    }
  }

  .small_coreLinks_list {
    display: none;
    margin-left: .5rem;

    @include media($break1130) {
      display: inline-block;
    }

    .help_center_link {

      @include media($break1130) {
        position: relative;
      }

      .help_link_txt {

        @include media($break1130) {
          display: block;
        }
      }
    }

    .more_link {

      &:hover {

        .more_dropdown_list {
          display: block;
        }
      }
    }

    .more_item {
      position: relative;

      &:hover {

        .more_dropdown_list {
          display: block !important;
          width: 210px;
          right: -51px;
          top: 41px;

          &:before, &:after {
            left: 50%;
          }
        }
      }
    }

    .more_dropdown_link {
      font-size: 1rem;

      @include media($break320) {
        font-size: .85rem;
      }
    }



    .search_link_item {
      display: none;

      @include media($break340) {
        display: inline;
      }
    }
  }

  .connectSearch_input {
    font-size: .8rem;
    border: 1px solid #E2E2E2;
    border-radius: 100px;
    background: #FBFBFB url('/images/style_guide/icon_search_black.svg') 9px center no-repeat;
    background-size: 17px;
    padding: .4em .7em .4em 2.4em;
    background-size: 17px;
    margin-top: 5px;
    width: 225px;
    margin-left: 10px;
  }
}


.hasCampaign_navbar {

  .core_subnav {

    @include media($break843) {
      top: 11.54em;
    }
  }
}

.analyze_subnav {

  .campaign_selection_container {
    display: inline-block;
    float: left;
  }
}

.newAnalyze_subnav {

  .core_links_list {
    border-right: none;
  }

  .navNotification_number {
    vertical-align: 2px;
    margin-left: 0;
  }
}
