/* New progress bar starter HTML

<div class="loading_progressBar_container">
  <div class="loading_progressBar">
    <div class="filled_progressBar"></div>
    <div class="progressBar_container"></div>
  </div>
  <span class="loading_progressTxt">32/114 content loaded</span>
</div>
*/

.new_progress_bar {
  position: relative;

  .loading_progressBar {
    height: 16px;
    border-radius: 100px;
  }

  .filled_progressBar {
    height: 16px;
    border-radius: 100px;
    position: absolute;
    left: 0;
    top: 0;
    width: 33%;
    background-color: $aiq_darkblue;
  }

  .progressBar_container {
    background-color: #E3E3E3;
    width: 100%;
    height: 16px;
    border-radius: 100px;
  }

  .loading_progress_text {
    display: block;
    text-align: center;
    padding: .25rem;
    font-weight: 600;
    font-size: .85rem;
  }
}



/* Loading Progress Bar Starter HTML

<div class="loading_progressBar_container">
  <div class="loading_progressBar">
    <div class="filled_progressBar"></div>
    <div class="progressBar_container"></div>
  </div>
  <span class="loading_progressTxt">32/114 content loaded</span>
</div>

*/

.loading_progressBar_container {
  position: relative;

  .loading_progressBar {
    height: 8px;
    border-radius: 100px;
  }

  .filled_progressBar {
    height: 8px;
    border-radius: 100px;
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    background-color: $aiq_darkblue;
  }

  .progressBar_container {
    background-color: #E3E3E3;
    width: 100%;
    height: 8px;
    border-radius: 100px;
  }
}

/* Campaign Progress Bar Starter HTML

<div class="campaign_progressBar">
  <div class="tooltip_sm tooltip_container">
    <div class="progress_tooltip tooltip_black tooltip_bottomCenter tooltip">
      <span class="tooltip_txt">36% to Goal</span>
    </div>
  </div>

  <div class="fill_bar"></div>
  <div class="container_bar"></div>
</div>

*/


.campaign_progressBar {
  width: calc(100% - 250px);
  position: relative;

  .progress_tooltip {
    display: block;
    padding: .2rem .35rem .18rem .35rem;
    text-align: center;
    width: auto;
    min-width: 75px;
    bottom: 9px;

    &:before, &:after {
      left: 50%;
      margin-left: -10px;
      bottom: -6px;
    }

    .tooltip_txt {
      font-size: .65rem;
    }
  }

  .fill_bar, .container_bar {
    border-radius: 100px;
  }

  .fill_bar {
    background-image: linear-gradient(-269deg, #FFD800 0%, #FFF793 99%);
    height: 9px;
    position: absolute;
  }

  .container_bar {
    background-color: #EBEBEB;
    height: 9px;
    width: 100%;
  }
}
