/* Here's some starting HTML you can use

<section class="section_container tabs_container">
  <div class="tabs_container">
    <ul class="nav_tabs clearfix default_body_copy">
      <li class="active"><a href>Tab A</a></li>
      <li><a href>Tab B</a></li>
      <li class="tab_alert"><a href>Tab C<img src="/images/new_manage/product_shipment/alert_icon.svg" alt class="alert_icon"></a></li>
      <li><a href>Tab D</a></li>
    </ul>
    <div class="active_tab_content">
      <p class="default_body_copy">Empty content</p>
    </div>
  </div>
</section>

*/

.tabs_container {

  .nav_tabs {
    border-bottom: 1px solid $lightgray-border;

    .active_tab_item {
      background-color: white;
    }

    > li {
      float: left;
      margin-bottom: -2px;

      > a {
        padding: .65rem 1rem;
        color: #909090;
        text-decoration: none;
        background: #F9F9F9;
        border: 1px solid #E9E9E9;
        border-bottom-color: $lightgray-border;
        display: block;
        margin-right: 3px;
        border-radius: 5px 5px 0 0;
        font-size: .85rem;
        cursor: pointer;

        &:hover, &:focus {
          background-color: white;
        }
      }

      &:last-child > a {
        margin-right: 0;
      }
    }

    .active {

      > {

        a {
          background-color: white;
          cursor: default;
          border: 1px solid #E2E2E2;
          border-bottom-color: transparent;
          color: $dark-text;
        }

      }
    }

    .tab_alert {

      > {

        a {
          color: $dark-text;
          font-weight: 600;
        }

        li {}
      }

      .alert_icon {
        vertical-align: -2px;
        padding-left: .35rem;
      }
    }
  }

  .active_tab_content {
    border: 1px solid #E2E2E2;
    border-radius: 0 0 5px 5px;
    padding: 1.25rem 1rem;
    background-color: white;
  }
}

/* SIMPLE TABS

Starter HTML:
<ul class="simpleTabs_list">
  <li class="simpleTabs_item">
    <a href class="active_simpleTab_link simpleTabs_link">Active(23)</a>
  </li>
  <li class="simpleTabs_item">
    <a href class="simpleTabs_link">Deactivated(23)</a>
  </li>
</ul>

 */
 .simpleTabs_list {

  .simpleTabs_item {
    cursor: pointer;
    display: inline-block;
    margin-right:.5rem;
  }

  .simpleTabs_link {
    padding: .25rem;
    color: $dark-text;
    font-weight: 300;
    text-decoration: none;

    &:hover, &:focus {
      color: $revfluence-blue;
    }
  }

  .active_simpleTab_link {
    color: $revfluence-blue;
    font-weight: 600;

    &:hover, &:focus {
      color: $revfluence-blue-hover;
    }
  }
}
