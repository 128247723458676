.switchRoles_modal {

  .modal-dialog {
    padding: 2.5rem;
    max-width: 650px;

    @include media($break600) {
      padding: 1.5rem 1rem;
    }
  }

  .popup_close_btn {
    padding: .5rem;
  }

  .popup_close_icon {
    width: 15px;
    height: 15px;
  }

  .mainHeader {
    text-align: center;
    padding-bottom: 1.25rem;
  }

  .mainTitle {
    padding-bottom: 1.25rem;

    @include media($break600) {
      font-size: 1.65rem;
    }
  }

  .mainDescrip {
    color: #737C87;
  }

  .roleList {}

  .roleItem {
    padding: .75rem 3.05rem .75rem .5rem;
  }

  .newMenu_item {}

  .selected_roleItem {
    background: #F4F6FA url("/images/style_guide/icon_checkmark_color.svg") 97% center no-repeat;
    background-size: 18px;
  }

  .iconWrap, .txtWrap {
    float: left;
  }

  .iconWrap {
    padding-right: 1.5rem;
    padding-top: 9px;
  }

  .icon_role {}

  .txtWrap {
    font-size: .85rem;
    width: calc(100% - 44px);
  }

  .roleTitle, .roleDescrip {
    display: block;
  }

  .roleTitle {
    font-weight: 600;
  }

  .roleDescrip {}
}
