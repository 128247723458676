.editLink_modal {

  .modal-dialog {
    padding: 1.5rem;
  }

  .btn_close_modal {
    background: none;
    padding: 10px;
    position: absolute;
    right: 5px;
    top: 5px;

    &:hover {
      background-color: $ultraLight_gray;
    }
  }

  .icon_editLink {
    margin-right: .35rem;
    width: 27px;
  }

  .icon_close {
    width: 14px;
  }

  .standard_label {
    font-weight: 600;
    display: block;
  }

  fieldset {
    padding: .5rem 0;
  }

  .radio_inline {
    margin: .25rem 0;
  }

  .editLink_form {
    max-width: 250px;
    margin: 0 auto;
  }

  .main_header {
    text-align: center;
  }

  .main_title {
    padding-bottom: .5rem;

    @include media($break480) {
      font-size: 1.6rem;
    }
  }

  .actions_container {
    text-align: center;
    padding: 1rem 0 .75rem;
  }

  .reactivate_btn {
    margin-right: .25rem;
  }
}
