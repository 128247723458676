.new_manage {

  .content_approval_tool {

    .tool_main_container {

      @include media($break630) {
        padding: 1rem;
      }

      @include media($break630) {
        padding: 0 .5rem;
      }
    }

    .everything_complete_checkbox {
      margin-left: .15rem;
    }

    .content_approval_modal {

      .add_types_title {
        text-align: center;
      }

      .add_types_title {

        strong {
          font-weight: 600;
        }
      }

      .content_checklist {
        list-style-type: disc;
        list-style-position: inside;
      }

      .content_checklist_container {
        float:none;
        margin: 0 auto;
        background-color: white;
        border-radius: 6px;
        padding: 20px;
      }

      .actions_container {
        margin-top: 10px;
        text-align: center;
      }
    }

    .notification_text {
      color:#93C36F;
    }

    .change_requested_module {
      border: 1px solid $lightgray-border;
      padding: 1.35rem 1.5rem 1rem;
      border-radius: 6px;
      width: 99%;
      margin: .5rem 0;

      .change_requested_title {
        font-weight: 600;
        margin-bottom: .25rem;
      }

      .situation_descrip {
        margin-bottom: 1rem;
      }

      .checklist_item {
        margin-bottom: 1rem;

        .complete_icon {
          float: left;
          margin-right: .7rem;
        }

        .checklist_item_txt {
          float: left;
          width: calc(100% - 33px);
        }
      }

      .change_request_msg {
        border: 1px solid $lightgray-border;
        border-radius: 6px;
        padding: .65rem;
        margin-bottom: 1rem;
        float: left;
        width:100%;
      }

      .avatar_container {
        width: 35px;
        float: left;
        margin-right: 1rem;
        height: auto;
      }

      .brand_user_avatar {
        border-radius: 100px;
        max-width: 100%;
      }

      .txt_container {
        float: left;
        width: calc(100% - 51px);
        padding-top: .35rem;
      }

      .msg_txt {}

      .cancel_request_btn {}
    }
  }
}
