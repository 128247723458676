.creator_profile {

  .edit_location_modal {

    .modal-dialog {
      padding: 1.35rem 2rem 1.75rem;
    }

    .main_title {
      text-align: center;
      margin-bottom: 1rem;
    }

    .location_input {
      display: block;
      max-width: 250px;
      margin: 0 auto 1.5rem;
    }

    .actions_container {
      text-align: center;
    }

    .save_btn {
      display: inline-block;
      margin-right: .5rem;
    }

    .cancel_btn {
      display: inline;
    }

    .form_error_text {
      text-align: center;
    }
  }
}
