.newAnalyze_body {

  .noData_placeholder {
    margin: 0 2.25rem 1rem;
  }

  .mainChart {
    padding: 0 0 1rem;

    .mainChart_nav {
      margin-bottom: 1.5rem;

      .mainChart_metric {
        padding-bottom: .5rem;
        font-size: 1.75rem;
      }

      .metric_label {}

      .mainChart_item {
        opacity: 0.65;
        background: #FDFDFD;
        box-shadow: 1px 1px 8px 0 #E1E4EA;
        border-radius: 3px;
        float: left;
        margin-right: 1%;
        width: 24.25%;
        cursor: pointer;
        padding: 2rem 2rem 2rem;
        transition: all .15s ease-in-out;

        &:hover {
          opacity: 1;
          background: white;

          .mainChart_metric, .metric_label {
            color: $revfluence-blue;
          }
        }

        &:active {
          opacity: 0.65;
          background: #FDFDFD;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .mainChart_item_active {
        background: #FFFFFF;
        box-shadow: 1px 1px 8px 0 #E1E4EA;
        border-radius: 3px;
        border-bottom: 3px solid $revfluence-blue;
        opacity: 1;

        .mainChart_metric, .metric_label {
          color: $revfluence-blue;
        }

        .mainChart_metric {}

        .metric_label {}
      }
    }

    .mainChart_chart {
      background: #FFFFFF;
      box-shadow: 1px 1px 8px 0 #E1E4EA;
      border-radius: 3px;
      padding: 2rem 4rem 3rem;

      .mainChart_example {
        max-width: 100%;
        display: block;
      }
    }
  }

  .reports_mainHeader {
    margin-top: 44px;
  }

  .standard_table2_wrapper, .reports_table {
    border-radius: 0;
  }

  .adSets_container {

    @include media($break415) {
      width: 250px;
    }
  }

  .reports_toolbar {

    @include media($break1300) {
      margin: 0 .5rem 1rem;
    }
  }

  .reports_table {
    width: 100%;
    border-radius: 8px;

    tbody {
      min-height: 70px;
    }
  }

  .secWrap {
    margin-bottom: 1.5rem;

    .secHeader {
      text-align: center;
      position: relative;
      padding-bottom: .75rem;

      .secTitle {
        font-size: 1.65rem;
        text-align: center;
        padding: 1rem 2rem;
        display: inline-block;
        position: relative;
        z-index: 2;
        background-color: $lightgray_bg;
      }

      .line {
        background-color: #E1E4EA;
        height: 1px;
        position: absolute;
        width: 100%;
        top: 37px;
        z-index: 1;
      }
    }
  }
}
