.campaign_navbar {
  background-color: #fff;
  background-color: rgba(255,255,255, .98);
  position: fixed;
  width: 100%;
  top: 50px;
  z-index: $zindexHeader;
  height: 41px;
  border-bottom: 1px solid $lightgray-border;

  @include media($break843) {
    display: none;
  }

  .campaignBtn_title, .stage_dropdown_container .btn_txt, .campaignStats_item, {
    font-size: .85rem;
  }

  .add_campaign_text {
    font-size: .82rem;
  }

  .inner_container {
    margin: 0 auto;
  }

  .all_campaigns_avatar {
      overflow: hidden;
      background-color: lightgray;

      .first_qtr_img, .second_qtr_img, .third_qtr_img, .fourth_qtr_img {
          float: left;
          width: 15px;
          height: 15px;
      }
  }

  .campaignSelection_dropdownBtn, .stage_container, .progressBar_wrapper, .campaignStats_list {
    float: left;
  }

  .icon_dropdown_indicator {
    margin-top: 10px;
    margin-left: 11px;
  }

  .campaign_status_container {
    position: relative;
    float: left;
  }

  .stage_container {
    font-weight: 600;
    margin-right: 1.3rem;
  }

  .restricted_stage_container {
    padding-top: .65rem;
    padding-left: .35rem;
  }

  .stage_dropdown_container {

    .icon_stage {
      vertical-align: -5px;
      margin-right: .15rem;
    }

    .dropdown_btn {
      border-radius: 0;
      border: none;
      padding: .55rem 1.4rem .6rem .5rem;
      max-width: initial;
      margin-bottom: 0;
      background-color: #fff;

      &:hover, &:focus {
        background-color: $aiq_lightblue_bg;
        color: $aiq_darkblue;
      }

      &:active {
        background-color: #fff;
        color: $aiq_black;
      }
    }

    .loading_spinner {
      vertical-align: -4px;
    }

    .btn_txt {
      display: inline-block;
      font-weight: 600;
      vertical-align: 1px;
    }

    .dropdown_list {
      width: 210px;
      top: 26px;
      right: -5px;

      &:before, &:after {
        left: 180px;
      }
    }
  }

  .campaignNav_selection_container {
    border-right: none;
    float: left;
    position: relative;
    width: 215px;

    @include media($break575) {
      width: 75px;
    }

    &:hover {

      .campaign_dropdown_list {
        display: block;
      }
    }

    .campaignBtn_img {
      float: left;
      margin-right: .4em;
      width: 27px;
      height: 27px;
      border-radius: 100px;

      @include media($break575) {
        margin-right: 0;
      }
    }

    .campaign_img {
      float: left;
      margin-right: .4rem;
      width: 27px;
      height: 27px;
      border-radius: 100px;
    }

    .selected_indicator {
      display: none;
      width: 13px;
      margin-right: 9px;
      float: left;
      margin-top: 10px;
    }

    .campaign_actions_container {
      width: 22px;
      float: right;
      margin-top: 2px;

      .edit_icon {
        display: block;
        padding: 0;
      }

      .edit_icon_light {
        display: none;
      }
    }

    .campaign_dropdown_list {
      width: 260px;
      top: 40px;
      left: 3px;;
      padding: 1em 0 .7em;
      display: none;

      @include media($break575) {
        right: -203px;
        top: 44px;
      }

      .campaign_item {
        padding: 0;
      }

      &:after, &:before {
        left: 19px;
      }

      .campaign_btn {
        display: block;
        background: none;
        border-radius: 0;
        width: 100%;
        padding: 6px 12px;

        &:hover {
          color: $dark-text;
        }

        .campaign_title {
          width: calc(100% - 85px);
          font-size: .82rem;
          margin-top: 3px;
          float: left;
        }

        .edit_link {
          padding: 0;
          display: inline-block;

          &:hover {
            background: none;
          }
        }

        .actions_container {
          float: right;
        }
      }

      .active_campaign_btn {
        background-color: $revfluence-blue;
        &:hover {
          background-color: $revfluence-blue;
        }

        .selected_indicator {
          display: block;
        }

        .campaign_title {
          color: white;
        }

        .edit_icon {
          display: none;
        }

        .edit_icon_light {
          display: block;
        }

        .actions_container {
          margin-right: -3px;
        }
      }

      .campaign_title {
        @include media($break575) {
          display: inline-block;
        }
      }
    }

    .campaign_list {
      overflow: auto;
      scrollbar-face-color: #AFAFAF;
      scrollbar-track-color: none;
      background-color: white;

      ::-webkit-scrollbar {
        width:  6px;
      }

      ::-webkit-scrollbar-thumb {
        background: #D8D8D8;
        border-radius: 100px;
      }

      ::-webkit-scrollbar-track {
        background: none;
      }
    }

    .campaign_item {
      padding: 6px 15px;

      &:hover {
        background-color: $lightgray-border;
      }
    }

    .campaign_button {
      padding: 0;
      border: none;
      background: none;
      height: 27px;
      width: 100%;
      text-align: left;
    }

    .add_campaign_item {
      border-top: 1px solid $lightgray-border;
      display: block;
    }

    .campaign_link {
      padding: 7px 12px 9px;
      background: none;
      border-radius: 0;
      cursor: pointer;

      &:active {
        box-shadow: none;
      }

      &:hover {
        background-color: $lightgray-border;
      }
    }

    .add_campaign_link {
      padding: 7px 12px 8px;
      font-weight: 600;
      text-align: center;
      color: $aiq_black;

      &:hover {
        background-color: $aiq_lightblue_bg;
        color: $aiq_darkblue;
      }
    }

    .add_campaign_icon {
      width: 22px;
      display: inline-block;
      margin-right: .25rem;
    }

    .add_campaign_text {
      display: inline-block;
      vertical-align: 6px;
    }
  }

  .metricsContainer {
    float: left;
  }

  .campaignSelection_dropdownBtn {
    background: none;
    border-radius: 0;
    display: block;
    width: 100%;
    border-right: 1px solid #ECECEC;
    padding: 6px 11px 6px 7px;

    &:hover {
      background-color: #EEEEEE;
      background-color: rgba(238,238,238,.6);
    }
  }

  .campaign_img {
    margin-right: .35rem;
  }

  .campaignBtn_title {
    max-width: 200px;
    font-weight: 600;
    float: left;
    color: $dark-text;
    text-align: left;
    width: calc(100% - 55px);
    margin-top: 3px;

    @include media($break575) {
      display: none;
    }
  }

  .progressBar_wrapper {
    width: 237px;
    margin-right: .25rem;
    display: inline-block;
    padding-top: .75rem;
    height: 100%;

    @include media($break1175) {
      width: 188px;
    }

    @include media($break1130) {
      width: 178px;
    }
  }

  .campaign_progressBar {
    width: 150px;
    display: inline-block;

    @include media($break1175) {
      width: 100px;
    }
  }

  .progressBar_amount_txt {
    display: inline-block;
    vertical-align: -1px;
  }

  .campaignStats_list {
    padding-top: .59rem;
  }

  .campaignStats_item {
    float: left;
    margin-right: 1.5rem;

    @include media($break1130) {
      margin-right: .75rem;
    }

    &:last-child {
      margin-right: 0;
    }

    &:nth-last-of-type(-n+2) {

      @include media($break1375) {
        display: none;
      }
    }

    &:nth-last-of-type(-n+3) {

      @include media($break1300) {
        display: none;
      }
    }
  }

  .campaignStats_icon {
    margin-right: .2rem;
    vertical-align: -4px;
    width: 16px;
    height: 19px;
  }
}

.campaign_navbar_sm {
  display: none;
  height: 74px;

  @include media($break1200) {
    display: block;
    top: 92px;
  }

  .basicInfo {
    padding: 0 1.1rem 0 .45rem;
  }

  .campaignSelection_dropdownBtn {
    border-right: none;
  }

  .campaign_img {

    @include media($break575) {
      margin-right: .5em !important;
    }
  }

  .campaignBtn_title {

    @include media($break575) {
      display: block;
    }

    @include media($break400) {
      display: none;
    }
  }

  .stage_container {
    float: right;
    padding-top: .75rem;
    margin-right: 0;
  }

  .campaignTarget_container {
    float: left;
    margin-right: .75rem;
  }

  .timeLeft_container {
    float: left;
  }

  .campaignMetrics {
    padding: 0 1.1rem;
  }

  .progressBar_wrapper {
    padding-top: .45rem;
    width: calc(100% - 266px);
    margin-right: .75rem;
  }

  .campaign_progressBar {
    width: 100%;

    @include media($break1175) {
      width: calc(100% - 75px);
    }
  }
}
