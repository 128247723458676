.aspireiq_theme {

  .autoMsg_tab {

    .autoMsg_main {

      .autoMsg_home_header {

        .importMsg_btn {
          padding: .5rem;
        }
      }

      .autoMsg_home_list {

        .enabled_indicator {
          background-color: $aiq_darkblue;
        }

        .addFilter_btn {

          .addItem_icon {
            vertical-align: -6px;
            margin-right: .15rem;
          }
        }
      }
    }
  }

  .new_campaign_edit_view {

    .contact_rep_container {
      border: none;
      background-color: $aiq_nude;
    }
  }

  .addField_btn {
    padding: 8px 18px;

    .add_icon {
      vertical-align: -6px !important;
      margin-right: .1rem;
    }
  }

  .my_campaign_main {

    .main_campaigns_list_container {

      .campaign_control_btn {
        background-color: $aiq_lightblue_bg;
        color: $aiq_black;
        border: none;
        border-radius: 100px;
        padding: 6px 16px;

        &:hover, &:focus {
          color: $aiq_black;
          background-color: $aiq_lightblue_bg_hover;
        }

        &:active {
          background-color: $aiq_lightblue_bg;
        }
      }

      .primary_actions_container {

        .primary_actions_btn {

          &:hover {
            background-color: $aiq_lightblue_bg;
          }
        }
      }
    }

    .campaigns_btn {

      &:hover {
        background-color: $aiq_lightblue_bg;
      }
    }

    .active_campaigns_btn {
      background: $aiq_lightblue_bg;
    }
  }

  .campaigns_container {

    .status_msg_container {

      .robot_msg_container {
        color: $aiq_black;
      }
    }
  }

  .externalLinks_container {

    .externalLink_txt {
      color: $aiq_darkblue;
      font-weight: 400;

      &:hover {
        color: $aiq_darkblue_hover;
      }
    }
  }

  .brand_profile_header {

    .brand_name_container {

      .brand_name {
        font-size: 2rem;
      }
    }
  }

  .campaign_controls_container {

    .campaign_status {
      color: $aiq_black;
    }

    .campaignControl_link {

      .campaignControl_txt {
        color: $aiq_black;
      }
    }
  }

  .back_btn {

    &:hover {
    	padding: 10px;
    	border-radius: 4px;

    	&:hover {
    		background-color: $ultraLight_gray;
    	}

    	&:active, &:focus {
    		background-color: $lightgray_bg;
    	}
    }
  }

  .campaign_article {
    color: $aiq_black;

    .campaign_title {
      font-size: 1.5rem;
    }

    .networks_available_label  {
      font-weight: 700;
    }

    .compensation_section {

      .compensation_title {
        font-weight: 700;
      }

      .compensation_txt_container {

        .option_title {
          font-weight: 400;
        }
      }
    }

    .desired_content_title {
      font-weight: 700;
    }

    .desired_content_descrip {
      font-weight: 400;
    }
  }
}
