.affiliate_program {
	background-color: #f9f9f9;

	.main_container {
		width: 1200px;
		margin: 12em auto 0;
		position: relative;
		background-color: white;
		border: 1px solid $lightgray-border;
		overflow-y: hidden;
		padding: 3em 4em;
	}

	.intro_container {
		margin-bottom: 3em;

		.intro_title {
			@include title25;
			margin-top: .25em;
		}
	}

	.add_affiliate_program_container {
		position: absolute;
		top: 3em;
		right: 4em;

		.add_affiliate_program_button {
			background-color: $revfluence-green;
			display: inline-block;

			&:hover {
				background-color: $revfluence-green-hover;
			}
		}

		.add_affiliate_program_icon {
			width: 20px;
			height: 20px;
			display: inline-block;
			margin-right: .25em;
			background: url('../images/affiliates/add_affiliate_program_icon.svg') center no-repeat;
			vertical-align: -0.4em;
		}

		.add_affiliate_program_text {
			display: inline-block;
		}
	}

	.affiliate_programs_table {
		margin-bottom: 2em;
		width: 100%;

		table, th, td {
		    border: 1px solid $lightgray-border;
		    border-collapse: collapse;
		    border-spacing: 0;
		}

		th, td {
			padding: .75em;
		}

		th {
			@include fontsize15;
			font-weight: 600;
		}

		.date_created, .number_of_pubs, .flat_rate, .percentage_rage, .number_of_overrides, .preview {
			@include fontsize15;
		}

		.preview {
			a {
				@include blue-link;
			}
		}

		.affiliate_program {

			a {
				@include blue-link;
			}
		}
	}
}