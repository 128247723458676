.edit_affiliate {

	.standard_label {
		@include standard-label;
	}

	.main_container {
		width: 1200px;
		margin: 14em auto 0;
	}

	.intro_container {
		width: 100%;
		background-color: $revfluence-blue;

		.intro_inner_container {
			width: 1200px;
			margin: 0 auto;
		}
	}

    .affiliates_name_container {
        .edit_icon {
            position: absolute;
            left: -33px;
            top: 20px;
            display: none;
            width: 16px;
            height: 16px;
            text-indent: -9999px;
            background: url('../images/affiliates/edit_icon.svg') center no-repeat;
        }

        &:hover {
            .edit_icon {
                display: block !important;
            }
        }
    }

    .affiliates_email_container {
        .edit_icon {
            position: absolute;
            left: -33px;
            top: 7px;
            display: none;
            width: 16px;
            height: 16px;
            text-indent: -9999px;
            background: url('../images/affiliates/edit_icon.svg') center no-repeat;
        }

        &:hover {
            .edit_icon {
                display: block !important;
            }
        }
    }

	.affiliates_name {
		@include title40;
		color: $dark-text;
		display: block;
		letter-spacing: 0;
		word-wrap: break-word;
		position: relative;
        border: none;
        outline: none;
        &:focus {
            outline: none;
        }
	}

	.affiliates_email {
		@include fontsize16;
		color: $dark-text;
		display: block;
		padding-bottom: 2em;
		position: relative;
        border: none;
        outline: none;
        &:focus {
            outline: none;
        }
	}



	.coupon_code_container {
		margin-right: 5em;
		width: 350px;
		display: inline-block;

		.coupon_code_title {
			@include fontsize16;
			@include standard-bold;
			margin-bottom: .5em;
			display: block;
		}

		.coupon_code_list {
			width: 350px;

			.coupon_code_controls {
				position: absolute;
				right: .5em;
				top: 1.6em;
				display: none;
			}

			.edit_link {
				width: 16px;
				height: 16px;
				display: inline-block;
				margin-right: .85em;
				text-indent: -9999px;
				background: url('../images/affiliates/edit_icon.svg') center no-repeat;
			}

			.delete_link {
				width: 16px;
				height: 16px;
				display: inline-block;
				margin-right: .5em;
				text-indent: -9999px;
				background: url('../images/affiliates/delete_icon.svg') center no-repeat;
			}

			li {
				padding: 1em;
				position: relative;
				border: 1px solid $lightgray-border;
				margin-bottom: 1em;

				&:hover {
					border: 1px solid $revfluence-blue;
					.coupon_code_controls {
						display: block;
					}
				}
			}

			.coupon_code_text {
                width: 80%;
				color: $dark-text;
                border: none;
                background-color: transparent;
                @include fontsize16;
                &:focus {
                    border: none;
                    outline: none;
                }
			}
		}
		.add_coupon_code {
			display: inline-block;
		}
	}

	.commission_program_container {
		display: inline-block;
		width: 350px;
		vertical-align: top;

		.commission_program_title {
			@include fontsize16;
			@include standard-bold;
			margin-bottom: .5em;
			display: block;
		}

		.select_dropdown {
			width: 200px;
			margin-bottom: 1em;
		}

		.select {
			@include fontsize16;
		}

		.add_commission_program {
			display: inline-block;
		}
	}

		.standard_input {
			@include standard-input;
		}

		.save_button {
			display: block;
			width: 90px;
			margin-bottom: 1.5em;
			text-align: center;
			margin-top: 2em;
		}
}