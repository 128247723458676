.addto_group_popup {

  .modal-dialog {

    @include media($break600) {
      margin: 25px auto;
      width: 95%;
    }
  }

  .close_modal {
    position: absolute;
    right: 1rem;
    top: 1em;
    display: block;

    .remove_modal_icon {
      display: block;
    }
  }

  /* HEADER */
  .addto_group_header {
    text-align: center;
    padding: 2rem 1rem 1rem;

    .user_avatar {
      border-radius: 100px;
      width: 40px;
      height: 40px;
      margin-right: .5rem;
    }

    .addto_group_title {
      display: inline;
      vertical-align: 7px;
    }
  }

  /* GROUP SELECTOR */
  .group_selector_container {
    padding-bottom: 1rem;
    width: 200px;
    margin: 0 auto;
    position: relative;

    &:hover {

      .addGrp_dropdown {
        display: block;
      }
    }

    .group_selector_btn {
      width: 200px;
    }

    .group_icon {
      vertical-align: 2px;
    }

    .addGrp_dropdown {
      left: -7px;
      top: 42px;
    }
  }

  /* ADD TO GROUP BUTTON */
  .addto_group_actionsContainer {
    padding-bottom: 3rem;
    text-align: center;

    .addto_group_btn {}
  }

  /* SUGGEST */
  .newGroup_suggest_container {
    text-align: center;
    padding: 0 1rem 2rem;

    .newGroup_suggest_txt {
      display: block;
    }
  }
}

.addto_new_group_popup {

  .standard_label {
    text-align: center;
    font-weight: 500;
    display: block;
  }

  .groupInput_container {
    margin: 0 auto .6rem;
    .error_text {
      text-align: center;
    }
  }

  .error {
    margin: 0 auto;
    max-width: 300px;
    text-align: center;
    display: block;
  }

  .group_input {
    width: 240px;
    background-image: url('/images/style_guide/icon_group_gray.svg');
    background-position: .5rem center;
    background-repeat: no-repeat;
    padding-left: 2.1rem;
    margin: 0 auto 1rem;
  }
}
