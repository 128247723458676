.createOffer_container {
	margin-bottom: 3em;
	@include media($break721) {
        margin-top: 8em;
    }

	.createOffer_header {
		margin-bottom: 2em;
		border-bottom: 1px solid $lightgray-border;
		padding-bottom: 2em;
		@include media($break900) {
			margin-top: 7em;
		}
	}

    .comment {
        @include fontsize13;
        margin: 0;
        margin-top: .5em;
        color: $error;
        display: block;
        &.grey {
            color: $dark-text;
        }
    }

	.createOffer_title {
		@include title30;
		color: $dark-text;
		text-transform: inherit;
		text-align: center;
		display: block;
		margin: 1em 0 .5em;
		@include media($break765) {
			margin: 0;
			margin-top: 1.5em;
			margin-bottom: .25em;
		}
		@include media($break375) {
			@include title25;
		}
	}

	.createOffer_subtitle {
		@include fontsize17;
		color: $dark-text;
	  margin: 0 0 1em 0;
	  text-align: center;
	}


	.compensationInformation {
		margin-bottom: 2.5em;
		padding: 1em 2em;

		.compensation_title {
            @include fontsize17;
            @include standard-bold;
            margin-bottom: 1em;
		}

		.checkbox_input {}

		.checkbox_text {
			display: inline-block;
			color: $dark-text;
			font-weight: 300;
		}

        .example_fixed_price_text {
            font-size: 0.9em;
            margin-left: 1.5em;
            margin-top: 0.25em;
            display: inline-block;
            color: $dark-text;
            font-weight: 300;
        }

        .example_fixed_price {
            font-weight: 700;
        }

        .help_text {
            @include fontsize17;
            font-weight: 300;
            color: $dark-text;
            margin-bottom: .5em;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            margin-left: 0.3em;
        }

		.productInstructions {
			display: block;
			width: 100%;
		}

		.commissionDetails_label {
			@include standard_label;
		}

        .payment_details_input {
            width: 70px;
            margin-top: 0.25em;
            margin-left: 1.5em;
            height: 40px;
        }

        .indentation {
            margin-left: 3em;
        }

		.productInstructions_textarea, .commission_details_textarea, .other_compensation_textarea {
			height: 65px;
			width: 519px;
			margin-top: -.45em;
            margin-left: 1.5em;
			@include media($break640) {
				width: 100%
			}
		}

		.commission_instructions {
			width: 100%;
		}


        .hint-icon {
            display: inline-block;
            position: relative;
            margin-left: .5em;
            width: 15px;
            height: 15px;
            cursor: pointer;
            vertical-align: -2px;
            background: url('../images/manage/hint_icon.svg') center no-repeat;
        }

        .standardTooltip {
            @include standard_tooltip;
            width: 180px;
            bottom: 31px;
            left: -83px;
        }

	}

	.campaignform {

		label {
			@include fontsize17;
			font-weight: 500;
			color: $dark-text;
			margin-bottom: .5em;
			display: block;
		}
	}

	.youtube_campaign_form {

		fieldset {
			margin-bottom: .5em;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.campaignform, .youtube_campaign_form {
		margin-bottom: 5em;

		  .fieldset-1 {
		  	width: 64%;
		  	@include media($break900) {
		  		width: 100%;
		  		padding: 0;
		  	}
		  }

		  .fieldset-2 {
		  	width: 68%;

		  	@include media($break900) {
		  		width: 100%;
		  		padding: 0;
		  	}
		  }

        .payment_details_input {
            @include standard-radius;
            @include standard-input;
            background-color: white;

            &:focus {
                background-color: white;
            }
        }

		textarea {
			@include standard-radius;
			@include standard-textarea;
			background-color: white;
			height: 135px;
			width: 100%;

			&:focus {
				background-color: white;
			}
		}

		.container {
			@include standard-radius;
			margin-bottom: 2.5em;

			fieldset {
				display: inline-block;
				margin-right: 2em;
				@include media($break667) {
					margin-right: 0;
				}
			}

			textarea {
				border: 1px solid $lightgray-border;
				padding-bottom: 2em;
				margin-bottom: .5em;
				background-color: white;
			}

			.exampleText_container {
				display: none;
			}
		}

		.focusContainer_active {
			background-color: #dff2ff;
			border: 1px solid #aacde6;
			padding: 1em 2em 1em 0;
			@include media($break900) {
				padding: 1em 2em 1em;
			}

			textarea {
				border: 1px solid #aacde6;
			}

			.exampleText_container {
				display: inline-block;
				@include fontsize15;
				width: 33%;
				vertical-align: top;
				display: inline-block;
				@include media($break900) {
					width: 100%;
				}
				@include media($break667) {
					padding: 0;
				}

				strong {
					color: $dark-text;
					display: block;
					font-weight: 500;
					font-style: italic;
					@include media($break900) {
						display: inline-block;
						margin-right: 1em;
					}
				}

				ul {
					list-style: initial;
					list-style-position: inside;
					@include media($break900) {
						display: inline-block;
					}
				}

				li {
					color: $dark-text;
					font-style: italic;
					@include media($break900) {
						display: inline-block;
						margin-right: 1em;
					}
				}

				p {
					color: $dark-text;
					margin-left: 0 !important;
				}
			}
		}
	}

	.youtube_campaign_form {

		.container {
			padding: 1em 2em;
		}
	}

	.exampleCollab_container {
		margin-bottom: 1em;

		.exampleCollab_input {
			@include standard-input;
			margin-right: .25em;
			width: 91.5%;
			background-color: white;

			&:focus {
				background-color: white;
			}
		}

		.delete_example_collab {
			display: inline-block;
			width: 18px;
			height: 20px;
			text-indent: -9999px;
			background: url('../images/brand_onboarding/delete_example_collab_icon.svg') center no-repeat;
			vertical-align: .6em;
            cursor: pointer;
		}


	}

	.add_another_example {
		@include blue-link;
		@include fontsize16;
		margin-top: .5em;
		display: inline-block;
	}

	

	.informationRequest_container {
		margin-bottom: 3em;
		overflow: hidden;
		padding-left: 2em;
		@include media($break667) {
			padding-left: 0;
		}

		.informationRequestHeader {
			@include fontsize17;
			color: $dark-text;
			display: block;
			font-weight: 500;
			margin-bottom: .5em;

		}

		.informationRequestHeader {}

        .broadcastInformation_inputs {
            float: left;
            width: 60%;
            margin-right: 2em;
        }
	}
}