/*

SAMPLE HTML

<!-- ADD TO GROUP -->
<div class="addGrp_container">
  <button class="addGrp_btn btn no_shadow_focus">
    <img src="/images/style_guide/icon_addGrp_black.svg" class="addGrp_icon">
    <span class="addGrp_btn_txt default_body_copy">Add to Group</span>
  </button>

  <ul class="addGrp_dropdown dropdown_list">
    <li class="dropdown_li">
      <button class="addtoGrp_btn btn no_shadow_focus">
        <img src="/images/style_guide/icon_grp_gray.svg" class="grp_icon">
        <span class="addtoGrp_name default_body_copy truncate">RockstarsRockstarsRockstars</span>
      </button>
    </li>
    <li class="dropdown_li">
      <button class="addtoGrp_btn btn no_shadow_focus">
        <img src="/images/style_guide/icon_grp_gray.svg" class="grp_icon">
        <span class="addtoGrp_name default_body_copy truncate">RockstarsRockstarsRockstars</span>
      </button>
    </li>

    <!-- Group already added: apply the .addedGrp_item -->
    <li class="addedGrp_item dropdown_li">
      <button class="addtoGrp_btn btn no_shadow_focus">
        <img src="/images/style_guide/icon_checkmark_black.svg" alt class="addedGrp_indicator_icon">
        <img src="/images/style_guide/icon_grp_gray.svg" class="grp_icon">
        <span class="addtoGrp_name default_body_copy truncate">RockstarsRockstarsRockstars</span>
      </button>
    </li>
    <li class="dropdown_li">
      <button class="createGrp_btn btn no_shadow_focus">
        <img src="/images/style_guide/icon_add_item.svg" alt class="addItem_icon">
        <span class="createGrp_btn_txt">Create Group</span>
      </button>
    </li>
  </ul>
</div>

<!-- ADDED GROUPS -->
  <ol class="addedGrp_list">
    <li>
      <img src="/images/style_guide/icon_grp_gray.svg" class="grp_icon">
      <span class="addedGrp_name default_body_copy truncate">RockstarsRockstarsRockstars</span>
      <button class="removeFromGrp_btn btn no_shadow_focus"><img src="/images/style_guide/icon_close_gray.svg" alt class="remove_icon"></button>
    </li>
    <li>
      <img src="/images/style_guide/icon_grp_gray.svg" class="grp_icon">
      <span class="addedGrp_name default_body_copy truncate">Lemonjuice</span>
      <button class="removeFromGrp_btn btn no_shadow_focus"><img src="/images/style_guide/icon_close_gray.svg" alt class="remove_icon"></button>
    </li>
    <li>
      <img src="/images/style_guide/icon_grp_gray.svg" class="grp_icon">
      <span class="addedGrp_name default_body_copy truncate">RockstarsRockstarsRockstars</span>
      <button class="removeFromGrp_btn btn no_shadow_focus"><img src="/images/style_guide/icon_close_gray.svg" alt class="remove_icon"></button>
    </li>
    <li>
      <img src="/images/style_guide/icon_grp_gray.svg" class="grp_icon">
      <span class="addedGrp_name default_body_copy truncate">Lemonjuice</span>
      <button class="removeFromGrp_btn btn no_shadow_focus"><img src="/images/style_guide/icon_close_gray.svg" alt class="remove_icon"></button>
    </li>
    <li>
      <img src="/images/style_guide/icon_grp_gray.svg" class="grp_icon">
      <span class="addedGrp_name default_body_copy truncate">RockstarsRockstarsRockstars</span>
      <button class="removeFromGrp_btn btn no_shadow_focus"><img src="/images/style_guide/icon_close_gray.svg" alt class="remove_icon"></button>
    </li>
    <li>
      <img src="/images/style_guide/icon_grp_gray.svg" class="grp_icon">
      <span class="addedGrp_name default_body_copy truncate">Lemonjuice</span>
      <button class="removeFromGrp_btn btn no_shadow_focus"><img src="/images/style_guide/icon_close_gray.svg" alt class="remove_icon"></button>
    </li>
    <li>
      <img src="/images/style_guide/icon_grp_gray.svg" class="grp_icon">
      <span class="addedGrp_name default_body_copy truncate">Lemonjuice</span>
      <button class="removeFromGrp_btn btn no_shadow_focus"><img src="/images/style_guide/icon_close_gray.svg" alt class="remove_icon"></button>
    </li>
    <li>
      <img src="/images/style_guide/icon_grp_gray.svg" class="grp_icon">
      <span class="addedGrp_name default_body_copy truncate">RockstarsRockstarsRockstars</span>
      <button class="removeFromGrp_btn btn no_shadow_focus"><img src="/images/style_guide/icon_close_gray.svg" alt class="remove_icon"></button>
    </li>
    <li>
      <img src="/images/style_guide/icon_grp_gray.svg" class="grp_icon">
      <span class="addedGrp_name default_body_copy truncate">Lemonjuice</span>
      <button class="removeFromGrp_btn btn no_shadow_focus"><img src="/images/style_guide/icon_close_gray.svg" alt class="remove_icon"></button>
    </li>
  <ol>

*/

/* ADD GROUP BUTTON */
.addGrp_btn {
  background: none;
  padding: 6px 6px 6px 0;

  &:hover {

    .addGrp_btn_txt {
      color: $revfluence-blue;
    }
  }

  .addGrp_btn_txt {
    font-size: .85rem;
    font-weight: 500;
  }
}

/* DROPDOWN */
.addGrp_dropdown {
  display: none;
  width: 235px;

  &:after, &:before {
    left: 16px;
  }

  .grp_hover_icon {
    display: none;
  }

  .addedGrp_name, .addtoGrp_name {
    display: inline-block;
    max-width: calc(100% - 24px);
    vertical-align: -5px;
  }

  .addtoGrp_name {
    font-size: .9rem;
  }

  .addedGrp_indicator_icon {
    display: none;
    width: 14px;
    margin-right: 1px;
  }

  .addGrp_btn_txt {
    font-size: .85rem;
    font-weight: 500;
  }

  .grp_icon {
    margin-right: 1px;
  }

  .addtoGrp_name {
    max-width: calc(100% - 51px);
  }

  .addtoGrp_btn {
    padding: .5rem;
    background: none;
    border-radius: 0;
    text-align: left;
    width: 100%;

    &:hover {
      background-color: $revfluence-blue;
      color: white;

      .grp_icon {
        display: none;
      }

      .grp_hover_icon {
        display: inline;
      }
    }
  }

  .createGrp_btn {
    background: none;
    padding: .5rem;
    width: 100%;
    text-align: left;
    display: block;

    &:hover {
      background-color: $revfluence-blue;
      border-radius: 0;

      .createGrp_btn_txt {
        color: white;
      }
    }

    .addItem_icon {
      vertical-align: -5px;
    }

    .createGrp_btn_txt {
      font-size: .9rem;
    }
  }

  /* Already added Group item(dropdown) */
  .addedGrp_item {
    display: block;

    .grp_icon, .addtoGrp_name {}

    .addedGrp_indicator_icon {
      display: inline-block;
    }

    .addtoGrp_name {
      max-width: calc(100% - 35px);
      color: white;
    }

    .addtoGrp_btn {
      background-color: $revfluence-blue;

      &:hover {
        color: $dark-text;
      }
    }

    .grp_icon {
      display: none;
    }

    .grp_hover_icon {
      display: inline;
    }
  }
}

/* ADDED GROUPS LIST */
.addedGrp_list {

  .addedGrp_name {
    display: inline-block;
    max-width: 100px;
    vertical-align: -5px;
  }

  .removeFromGrp_btn {
    background: none;
    position: absolute;
    right: 0;
    top: 5px;
    padding: 0;
    display: none;
  }

  > li {
    position: relative;
    padding-right: .84rem;

    &:hover {

      .removeFromGrp_btn {
        display: block;
      }
    }
  }
}
