.creator_profile {

  .signup_modal {

    .modal-dialog {
      max-width: 500px;
      padding: 2rem;

      @include media($break535) {
        margin: 20px auto 75px;
      }
    }

    .main_title {
      margin-bottom: 1.25rem;
      text-align: center;
    }

    .main_descrip {
      padding: 0 0 1rem;
      text-align: center;
    }

    .signup_form {
      max-width: 300px;
      margin: 0 auto;
    }

    fieldset {
      margin-bottom: 1rem;
      position: relative;
    }

    .forgot_pass_link {
      position: absolute;
      top: 5px;
      right: 0;
      font-size: .75rem;
    }

    .standard_label {}

    .username_input {}

    .password_input {}

    .has_account_notice {
      text-align: center;
      margin-top: 2rem;
      padding-top: 1rem;
      border-top: 1px solid $lightgray-border;
    }
  }
}
