.idealBrandPartnerships {
  padding: 120px 0 20px;

  .welcomeHeader {
    margin-bottom: 16px;
  }

  .headerBreak {
    width: 100%;
    height: 1px;
    background: #D9D9D9;
    margin-bottom: 21px;
  }

  .mainContent {
    padding-top: 1rem;
  }

  .innerContainer {
    margin: auto;
    text-align: center;
    max-width: 488px;
    padding: 0 32px;
  }

  .filterDropdown {
    width: 100%;
    display: block;
    margin: 24px 0 0;
  }

  .saveFiltersButton {
    width: 100%;
    margin: 24px 0 0;
  }

  .helpText {
    margin: 8px 0 0;
  }

  .brandPartnershipItem {
    position: relative;
    padding: 1rem;
    border: 1px solid $lightgray-border;
    border-radius: 6px;
    margin-bottom: 1rem;
    text-align: left;

    .brandPartnershipHeader {
      padding-bottom: 1rem;
    }

    .brandLogo {
      width: 36px;
      margin-right: 6px;
      margin-top: 4px;
      display: inline-block;
      border-radius: 18px;
      vertical-align: top;
    }

    .accountTxt_container {
      width: calc(100% - 50px);
      position: relative;
      display: inline-block;
    }

    .username {
      font-weight: 700;
      max-width: calc(100% - 130px);
    }

    .closeButtonContainer {
      position: absolute;
      display: inline-block;
      right: 0px;
    }

    .removePartnershipBtn {
      background-color: white;
    }
  }

  .addBrandContainer {
    .addBrandInput {
      display: block;
      margin-bottom: 0.75rem;
    }

    .addBrandLabel {
      display: block;
      padding-bottom: 0.5rem;
    }
  }

  .doneBtn {
    margin: auto;
    margin-top: 1rem;
    width: 150px;
  }
}

// Different views for when this page is embedded in creator profile
.creatorProfileIdealBrandPartnerships {
  .idealBrandPartnerships {
    padding: 20px 0;
  }
}
