.contentError_modal {
  text-align: center;

  .modal-dialog {
    padding: 1.5rem 1.5rem 1.65rem;
  }

  .close_modal_btn {
    padding: .5rem;
    border-radius: 100px;
  }

  .remove_modal_icon {
    width: 15px;
  }

  .contentError_title {
    padding-bottom: .25rem;
  }

  .register_descrip {
    padding-bottom: 1rem;
  }
}
