.notEligible_modal {
  .modal-dialog {
    padding: 1.5rem;
  }

  .main_title {
    padding-bottom: .5rem;

    @include media($break480) {
      font-size: 1.6rem;
    }
  }

  .actions_container {
    text-align: center;
    padding: 1rem 0 .75rem;
  }
}