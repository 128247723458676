.newAnalyze_header {
  padding: 1.75rem 0 1.15rem;
  min-width: 1340px;
  margin: 92px 2.25rem 0;

  &.nonav {
    margin: 42px 2.25rem 0;
  }

  @include media($break360) {
    padding: 3.75rem 1.25rem 1.15rem;
  }

  .headerControls {
    padding-top: 1rem !important;
  }

  .colWrap {}

  .leftCol {
    float: left;
    padding-right: 1.5rem;
    width: calc(100% - 88px);

    .mainTitle, .mainDescrip {
      padding-bottom: 1.25rem;
    }

    .icon_channel {
      display: inline-block;
      margin-right: .65rem;
      width: 20px;
      vertical-align: 3px;
    }

    .mainTitle {
      font-size: 2.15rem;
    }

    .mainDescrip {}

    .inputsWrap {

      .inputsType_a {}

      .dropdown_container {
        width: 220px;
        display: inline-block;
        margin-right: .5rem;

        &:last-child {
          margin-right: 0;
        }
      }

      .dropdown_btn2 {}

      .dropdownLeft_icon {
        width: 14px;
        margin-right: .25rem;
      }

      .timeRange_wrap {

        .timeRange_dropdown_list {
          top: 39px;
          right: -10px;
        }

        .date_input {
          max-width: 81px;
        }

        .date_pickers_container {
          display: inline-block;
          vertical-align: 5px;
        }
      }

      .filterWrap {

        .dropdown_list {
          width: 200px;
          top: 39px;

          &:left, &:right {
            left: 167px;
          }
        }
      }

      .btn_txt {}

      .icon_dropdown {}
    }

    .enteredFilter {
      padding: 13px 24px 14px 13px;
      color: $revfluence-blue;
      background-color: #EFF5F9;
      display: inline-block;
      font-size: .8rem;
      border-radius: 6px;
      position: relative;
      margin-right: 0.5rem;

      .removeFilter_btn {
        padding: .4rem;
        background: none;
        display: none;
        position: absolute;
        right: .15rem;
        top: .45rem;
      }

      &:hover {
        background-color: #EAF0F4;

        .removeFilter_btn {
          display: inline-block;
        }
      }

      .icon_remove {
        width: 11px;
      }
    }

    .addFilter_btn {
      color: $revfluence-blue;
      padding: 8px 10px;
      margin-left: .15rem;

      .icon_addFilter {
        vertical-align: -2px;
        margin-right: .25rem;
      }
    }
  }

  .leftCol {

    @include media($break600) {
      float: none;
      width: 100%;
    }
  }
}
