.demo_choose_category {
	background-color: #fafafa;

	.main_header {
		background-color: white;
		position: relative;
		z-index: 2;
		box-shadow: 0px 1px 2px 0px rgba(1, 1, 1, 0.09);

		.inner_container {
			max-width: 1300px;
			margin: 0 auto;
			position: relative;
			@include media($break1300) {
				padding: 0 2em;
			}
		}

		.back_link {
			display: block;
			width: 40px;
			height: 55px;
			position: absolute;
			top: 3.4em;
			left: 5px;
			background: url('/images/demo_product/back_arrow.svg') center no-repeat;
			background-size: 11px 35px;
			text-indent: -9999px;

			@include media($break630) {
				top: -6px;
			}
		}

		.main_title {
			text-align: center;
			padding-top: .5rem;
      padding-bottom: 1.5rem;

			@include media($break630) {
				font-size: 1.75rem;
				padding: 0 1.5rem 1rem;
			}

			@include media($break450) {
				font-size: 1.5rem;
				padding-top: .75rem;
			}
		}
	}

	.main_section {
		padding-top: 3em;

		.main_descrip {
			font-size: 1.8em;
			color: $dark-text;
			line-height: 1.5em;
			font-weight: 300;
			text-align: center;
			padding-bottom: 1.5em;
		}

		.category_list {
			max-width: 450px;
			margin: 0 auto;

			@include media($break630) {
				width: 100%;
				padding: 0 1rem;
			}

			@include media($break375) {
				max-width: 330px;
			}
		}

		.category_item {
			float: left;
			margin-left: 1em;
      margin-right: 1em;
			margin-bottom: 2em;
			cursor: pointer;

			&:last-child {
				margin-right: 0;
			}

			@include media($break630) {
				margin: 0 0 2em;
				width: 33%;
				padding: 0 .5rem;
			}

			@include media($break375) {
				width: 50%;
			}
		}

		.category_button {
			text-align: center;
			-webkit-appearance: none;
			background-color: white;
			box-shadow: 0px 1px 2px 0px rgba(1, 1, 1, 0.09);
			padding: 2em;
			border: 1px solid white;
			cursor: pointer;
			width: 125px;
			height: 125px;
			transition: all .15s ease-in-out;
			border-radius: 32px;

			@include media($break630) {
				width: 100%;
				height: 130px;
			}

			&:hover {
				background-color: #fdfdfd;
				border: 1px solid #d9d9d9;
				transform: translate(0,-5px);
			}

			&:focus {
				outline: none;
				background-color: #e2efff;
				border: 1px solid $revfluence-blue;
				transform: translate(0,-5px);
			}

			&:active {
				background-color: #e2efff;
				border: 1px solid $revfluence-blue;
			}
		}

		.category_icon {
			width: 30px;
			height: 30px;
			display: block;
			margin: 0 auto 2em;
		}

		.womensfashion_icon {
			background: url('/images/demo_product/womens_fashion.svg') center no-repeat;
		}

		.fitness_icon {
			background: url('/images/demo_product/fitness.svg') center no-repeat;
		}

		.beauty_icon {
			background: url('/images/demo_product/beauty.svg') center no-repeat;
		}

		.food_icon {
			background: url('/images/demo_product/food.svg') center no-repeat;
		}

		.lifestyle_icon {
			background: url('/images/demo_product/lifestyle.svg') center no-repeat;
		}

		.gaming_icon {
			background: url('/images/demo_product/gaming.svg') center no-repeat;
		}

		.kidsfamily_icon {
			background: url('/images/demo_product/moms.svg') center no-repeat;
		}

		.mensfashion_icon {
			background: url('/images/demo_product/mens_fashion.svg') center no-repeat;
		}

		.tech_icon {
			background: url('/images/demo_product/tech_category_icon.svg') center no-repeat;
		}

		.category_text {
			color: $dark-text;
			display: block;
			font-size: 1.2em;
			font-weight: 500;
		}
	}
}
