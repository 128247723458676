.noRelationshipForMember {
  .mainContainer {
    width: 70%;
    height: auto;
    margin: 100px auto;
    text-align: center;
  }

  .errorImage {
    width: 45%;
  }

  .header {
    padding-top: 2rem;
    font-size: 1.2rem;
    font-weight: 600;
  }
}
