.contentHeader {
  background: #FFFFFF;
  box-shadow: 0 1px 8px 0 #E1E4EA;
  padding: 1rem 2rem 0;
  margin-bottom: 1.65rem;
  margin-top: 50px;
  min-width: 1200px;

  .inner_container {}

  .leftCol, .middleCol, .rightCol {
    float: left;
  }

  .leftCol {
    width: 192px;
    margin-right: 2rem;
    position: relative;
  }

  .rightCol {
    width: 285px;
  }

  .headerControls {
    padding-top: 3rem;

    .icon_delete {
      width: 21px;
      margin-left: -12px;
      top: 21px;
    }

    .icon_share {
      width: 18px;
      margin-left: -9px;
      top: 18px;
    }

    .icon_download {
      width: 23px;
      margin-left: -11px;
      top: 18px;
    }
  }

  .col_btn {

    &:hover {

      .icon_col {
        display: none;
      }

      .icon_hover_col {
        display: block;
      }
    }
  }

  .contentTab_nav {
    border-top: 1px solid $lightgray-border;

    .standard_link {
      display: inline-block;
      padding: 9px 10px 10px;
    }

    .linkNav_item {
      margin-right: 0;
    }
  }
}

.newContent_detail_body {

  .mainChart {
    padding: 0 2.25rem 1rem;
    min-width: 1200px;
  }
}
