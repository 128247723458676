.creator_profile {

  .other_accounts_container {
    .other_accounts_title {
      margin-bottom: 20px;
    }

    .accounts_list {}

    .account_item_link {
      color: $dark-text;
      text-decoration: none;
      display: block;
      cursor:pointer;
    }

    .account_item {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .other_account_avatar {
      display: inline-block;
      height: 45px;
      width: 45px;
      margin-right: 15px;
      border: 1px solid white;
      box-shadow: 0 1px 3px rgba(0,0,0,.25);
      border-radius: 100px;
    }

    .account_name_container {
      display: inline-block;
      vertical-align: 17px;
    }

    .username {
      display: inline-block;
      margin-right: 10px;
      vertical-align: 3px;
    }

    .network_icon {
      width: 16px;
      height: 15px;
      display: inline-block;
      background-size: 100%;
    }

    .instagram_icon {}

    .youtube_icon {}

    .twitter_icon {}

    .facebook_icon {}

    .vine_icon {}

    .snapchat_icon {}

    .blog_icon {}

    .pinterest_icon {}

    .googleplus_icon {}
  }

  .myTeam_creators {
    margin-top: 1.5rem;
    position: relative;
    display: block;

    .spinner {
      display: block;
      position: relative;
      height:100px;
    }
  }
}
