/* Brands use this to add posts to their relationship */

.add_postSummary_modal {
  .modal_mainTitle {
    text-align: center;
  }

  .modal-dialog {
    padding: 15px 15px 28px;
  }

  .close_modal {
    padding: 9px;
    position: absolute;
    right: 6px;
    top: 3px;
  }

  .remove_modal_icon {
    width: 15px;
  }

  .modal_mainDescrip {
    text-align: center;
    padding-bottom: 1rem;
    font-size: 1.05rem;
  }

  .modal_url_input {
    max-width: 350px;
    margin: 0 auto 1rem;
  }

  .modal_actions_container {
    text-align: center;
  }

  .modalSubmit_btn {
    margin-right: .5rem;
  }

  .error_text {
    text-align: center;
    display: block;
    margin-bottom: 1rem;
  }

  .modalCancel_btn {}
}
