.creator_profile {

  .edit_bio_modal {

    .modal-dialog {
      padding: 1.35rem 2rem 1.75rem;

      @include media($break620) {
        padding: 2rem 1.5rem;
      }
    }

    .main_title {
      text-align: center;
      margin-bottom: 1rem;
    }

    .bio_input {
      margin-bottom: 1.4rem;
    }

    .actions_container {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .save_btn {
      display: inline;
      margin-right: .5rem;
    }

    .cancel_btn {
      display: inline;
    }
  }
}
