.brandSwitcher_nav_item {
	display: none;
}

.advertiser_nav {

	.brandSwitcher_nav_item {
		display: block;
	}

	.brand_switcher_link {
		position: relative;
	}

	.alert_number {
		background-color: $revfluence-blue;
		color: white;
		font-size: .7rem;
		border-radius: 100px;
		text-align: center;
		display: block;
		padding: .12rem 0 0;
		width: 20px;
		height: 20px;
		border: 2px solid white;
		position: absolute;
		top: .5rem;
		left: 0;
		font-weight: 400;
	}

	.main_nav_li {
		margin-top: 0 !important;
	}

	.brandSwitcher_nav_item {
		position: relative;
		float: left;

		&:hover {
			.brandswitcher_dropdown {
				display: block;
			}
		}

		.brand_switcher_link {
			position: relative;
			display: block;
			text-decoration: none;
			padding: 12px 7px;
		}

		.current_brand_avatar {
			width: 26px;
			height: 26px;
			display: inline-block;
			border-radius: 100px;
		}

		.brandswitcher_dropdown {
			width: 285px;
			top: 49px;
			right: -22px;
			scrollbar-face-color: #AFAFAF;
			scrollbar-track-color: none;
			background-color: white;
			background-color: rgba(255,255,255,.97);
			padding: .3rem 0 .35rem;

			::-webkit-scrollbar {
				width:  6px;
			}

			::-webkit-scrollbar-thumb {
				background: #D8D8D8;
				border-radius: 100px;
			}

			::-webkit-scrollbar-track {
				background: none;
			}

			&:before, &:after {
				left: auto;
				right: 30px;
			}

			@include media($break335) {
				width: 265px;
			}

			@include media($break300) {
				width: 250px;
			}
		}

		.brandswitcher_header {
			position: relative;
			padding: .35rem 0 .6rem;
			border-bottom: 1px solid $lightgray-border;

			.brandswitcher_title {
				text-align: center;
				font-weight: 600;
				text-transform: uppercase;
				font-size: .8rem;
				letter-spacing: .1rem;
			}

			.add_brand_btn {
				position: absolute;
				right: 8px;
				top: 7px;
				background: none;
				padding: .25rem;
				cursor: pointer;

				&:focus, &:active {
					box-shadow: none;
				}
			}

			.add_brand_icon {
				width: 20px;
			}
		}

		.brandSwitcher_dropdown_list {
			max-height: 300px;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
		}

		.brandSwitcher_item {
			color: $dark-text;
			border-bottom: 1px solid $lightgray-border;

			&:last-child {
				margin-bottom: 0;
				border: none;
			}
		}

		.brand_avatar {
			width: 25px;
			height: 25px;
			float: left;
			margin: 2px 10px 0 0;
			border-radius: 100px;
		}

		.brand_name_txt {
			float: left;
			width: calc(100% - 66px);
			font-weight: 400 !important;
			margin-top: 6px;
			font-size: 1.45em;
			height: 18px;
		}

		.brand_links_list {
			font-size: .85em;
			margin: 4px 0 0 10px;
			float: left;
			width: 21px;
		}

		.brand_links_item {
			margin-bottom: 0;
			float: left;
			width: 25px;
			margin-right: 5px;

			&:last-child {
				margin-right: 0;
			}
		}

		.brand_links_link {
			line-height: .8em;
			padding: 0;
			cursor: pointer;

			&:hover {
				background: none;
			}
		}

		.brandSwitcher_dropdown_item {}

		.brand_link {
			padding: 7px 12px 9px;
			background: none;
			border-radius: 0;
			cursor: pointer;

			&:active {
				box-shadow: none;
			}

			&:hover {
				background-color: $lightgray-border;
			}
		}

		.active_brand_indicator {
			border-left: 4px solid $revfluence-blue;
			border-bottom: 1px solid $lightgray-border;
			background-color: rgba(249,249,249, .9);
			padding: 7px 12px 9px;
			border-radius: 0;
		}

		.campaign_links_list {}

		.campaign_links_item {
			float: left;
			width: 50%;
			text-align: center;
			border-left: 1px solid $lightgray-border;
			margin-bottom: 0;

			&:first-child {
				border-left: none;
			}
		}

		.campaigns_link {
			position: relative;
			background: none;
			padding: 0;
			font-weight: 300;
			font-size: .8rem;
			padding: 6px 0 8px;
			display: block;
			width: 100%;
			text-align: center;
			cursor: pointer;

			&:hover {
				color: $revfluence-blue;
			}

			&:active, &:focus {
				box-shadow: none;
			}

			.alert_number {
				display: inline-block;
				border: none;
				position: relative;
				top: auto;
				left: auto;
				width: 19px;
				height: 19px;
				padding: 0;
				margin-right: 4px;
			}
		}

	}
}
