.creator_profile {

  .review_section_desktop_only {
    display:block;
    @include media($break875) {
      display:none;
    }
  }

  .creator_reviews {

    @include media($break875) {
      padding: 1rem;
      border-top: none;
    }

    .creator_reviews_list {}

    .creator_reviews_item {
      border-bottom: 1px solid $lightgray-border;
      padding-bottom: 1.5rem;
      margin-bottom: .75rem;

      @include media($break875) {
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    /*

    .brand_avatar_container {
      width: 40px;
      float: left;
      margin-right: 1.5rem;
      padding-top: .65rem;

      @include media($break875) {
        display: none;
      }
    }

    .brand_avatar {
      max-width: 100%;
      border-radius: 6px;
      display: block;
    }

    .mobile_brand_avatar {
      display: none;
      margin-right: .65rem;
      border-radius: 6px;
      width: 50px;
      height: 50px;
      vertical-align: -9px;

      @include media($break875) {
        display: inline-block;
      }
    }

    */

    .campaign_details_container {
      float: left;
      width: 100%;

      @include media($break875) {
        width: 100%;
      }
    }

    .brand_name {
      padding-bottom: .5rem;
    }

    .campaign_info {
      width: 100%;
    }

    .txt_container {
      float: left;
    }

    .star_reviews_list {
      margin-bottom: .7rem;
    }

    .campaign_descrip {

      p {}
    }

    .giftBadge_wrap {
      border: 1px solid $lightgray-border;
      margin-bottom: .75rem;
      padding: 1rem;
      border-radius: 6px;

      .tooltip_img_wrap, .giftBadge_txtWrap {
        float: left;
      }

      .tooltip_img_wrap {
        padding-right: 1rem;
      }

      .badge_img {
        width: 60px;
      }

      .tooltip {
        bottom: 82px;
        left: -89px;
        text-align: center;
      }

      .giftBadge_txtWrap {
        width: calc(100% - 100px);
        font-size: 1.45em;
      }
    }

    .view_content_btn {
      font-size: 1rem;
      display: block;
      margin-bottom: .5rem;
    }

    .mobile_viewContent_btn {
      display: none;
      margin-left: 0;
      margin-bottom: .8rem;

      @include media($break500) {
        display: block;
      }
    }
  }
}
