.account_info_container {
	margin-bottom: 3em;
    @include media($break721) {
        margin-top: 8em;
    }

	.accountInfo_title {
		@include title30;
		color: $dark-text;
		text-transform: inherit;
		text-align: center;
		display: block;
		margin: 1em 0 .15em;
        @include media($break900) {
            margin: 2.5em 0 0.15em;
        }
		@include media($break765) {
			margin: 0;
			margin-top: 1.5em;
			margin-bottom: .25em;
		}
		@include media($break375) {
			@include title25;
		}
	}

	.accountInfo_subtitle {
		@include fontsize17;
		color: $dark-text;
		text-align: center;
		fon-weight: 300;
	}

	.row {
		overflow: hidden;
	}

    .top_border {
        border-top: 1px solid $lightgray-border;
    }

	.signup_form {
        margin-top: 3em;
        padding-top: 3em;
        margin-bottom: 3em;

		fieldset {
			margin-bottom: 1em;
		}

        label {
            @include fontsize17;
            color: $dark-text;
            margin-bottom: .5em;
            display: block;
            font-weight: 500;
        }

		input {
			@include standard-input;
            background-color: white;
            width: 50%;
            min-width: 20em;

            &:focus {
                background-color: white;
            }
        }

        fieldset {
            display: block;
        }

        .rightSpacing {
            margin-right: 3em;
            @include media($break478) {
                margin-right: 0;
            }
        }

        .contactHeader {
            margin-bottom: 3em;
        }

        .contactTitle {
            @include title25;
            color: $dark-text;
            margin-bottom: .25em;
        }

        .contactDescription {
            @include fontsize15;
            color: $dark-text;
        }

        .broadcastEditBtn {
            width: auto;
        }

        button {
            margin-top: 2.0em;
        }
	}

    .comment {
        @include fontsize13;
        margin: 0;
        margin-top: .5em;
        color: $error;
        display: block;
        &.grey {
            color: $dark-text;
        }
    }

	.hint {
		@include fontsize15;
		color: $dark-text;
		display: block;
	}
}