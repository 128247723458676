/* Starter HTML

** TEXT CONTENT WITH CAPTION
<div class="txtContent_container content_container">
  <a href title class="content_item_link"></a>
  <span class="txt_preview">Yesterday I tried drinking <a href title>YourTea</a> and I really thought it was just delightful! I felt a lot better than the standard coffee at Starbucks. Great…Yesterday I tried drinking <a href title>YourTea</a> and I really thought it was just delightful! I felt a lot better than the standard coffee at Starbucks. Great…</span>
  <img src="/images/style_guide/txtContent_bg.png" alt class="txtContent_bg">
</div>


** BLOG CONTENT
<div class="blogContent_container content_container">
  <a href title class="content_item_link"></a>
  <img src="/images/style_guide/sample_blog_screenshot.png" alt class="blog_screenshot">
</div>


** IMAGE CONTENT
<div class="imgContent_container content_container">
  <a href title class="content_item_link"></a>
  <img src="/images/style_guide/sample_ig_content.png" class="imgContent">
</div>


** VIDEO CONTENT WITH CAPTION
<div class="videoContent_container content_container">
  <a href title class="content_item_link"></a>
  <div class="captionContainer">
    <span class="captionTxt truncate">Excited to bring you my first visual dialect Excited to bring you my first visual dialect</span>
  </div>
  <img src="/images/style_guide/sample_yt_content.jpg" class="videoThumb">
  <img src="/images/style_guide/txtContent_bg.png" alt class="txtContent_bg">
</div>

*/

.txtContent_container, .imgContent_container, .blogContent_container, .videoContent_container {
  position: relative;
  overflow: hidden;
  scrollbar-face-color: #AFAFAF;
  scrollbar-track-color: none;

  @include media($break630) {
    background-color: #F8F8F8;
    padding: 0;
  }

  ::-webkit-scrollbar {
    width:  6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #D8D8D8;
    border-radius: 100px;
  }

  ::-webkit-scrollbar-track {
    background: none;
  }

  .content_item_link {
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .content_top_shadow {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    opacity: .3;
  }

  .captionHover_container {
    background-color: black;
    background-color: rgba(0,0,0,.95);
    opacity: 0;
    transition: all .15s ease-in-out;
    position: absolute;
    z-index: 5;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: left;
    padding: 1rem 1rem;

    .captionHover_txt_container {
      max-height: calc(100% - 20px);
      overflow-x: hidden;
      overflow-y: auto;

      .captionHover_txt {
        color: white;
        display: block;
      }
    }
  }
}

/* TEXT CONTENT */
.txtContent_container {
  background-color: white;

  .content_item_link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
  }

  a {
    color: $revfluence-blue;
    text-decoration: none;
  }

  .txt_preview {
    font-weight: 300;
    position: absolute;
    width: 100%;
    white-space: normal;
    overflow: hidden;
    z-index: 1;
  }

  .txtContent_bg {
    max-width: 100%;
    border-radius: 5px;
  }
}

/* BLOG CONTENT */
.blogContent_container {

  .blog_screenshot {
    max-width: 100%;
    border-radius: 5px;
  }
}

/* IMAGE CONTENT */
.imgContent_container {

  .imgContent {
    /* max-width: 100%;
    border-radius: 5px; */
  }
}

/* VIDEO CONTENT */
.videoContent_container {
  background-color: white;

  .txtContent_bg {
    max-width: 100%;
    border-radius: 5px;
  }
}

/* CAPTION - OPTIONALLY FOR IMAGE, VIDEO, BLOG */
.captionContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0,0,0,0.8);
  border-radius: 0 0 5px 5px;
  z-index: 2;

  &.warning {
    background-color: rgba(255,0,0,0.8);
  }
}

.captionTxt {
  color: white;
  font-weight: 300;
}

.content_container {
  border-radius: 5px;
  border: 1px solid $lightgray-border;
}

/* SMALL CONTENT */
.sm_content {
  height: 100px;

  /* sm - text content */
  .txtContent_container {}

  .txt_preview {
    height: 57px;
    top: 50%;
    margin-top: -29px;
    font-size: .6rem;
    line-height: .9rem;
    padding: 0 .5rem;
  }

  /* sm - video content */
  .videoThumb {}

  /* sm - captions */
  .captionContainer {
    padding: .25rem .4rem;

    .captionTxt {
      font-size: .6rem;
      line-height: .9rem;
    }
  }
}

/* MEDIUM CONTENT */
.m_content {

  .txt_preview {

  }
}

/* LARGE CONTENT */
.l_content {

  /* l - text content */
  .txtContent_container {}

  .txt_preview {
    height: 171px;
    top: 1rem;
    font-size: 1.25rem;
    line-height: 1.8rem;
    padding: 0 .5rem;
    left: 0px;
  }

  /* l - video content */
  .videoContent_container {}

  .videoThumb {
    /* margin-top: -87px; */
  }

  /* l - caption */
  .captionContainer {
    padding: .5rem .4rem;

    .captionTxt {
      font-size: .8rem;
      line-height: 1rem;
    }
  }
}

/* Content Lists */

.content_list {

  .content_item {
    margin-bottom: 1rem;
    margin-right: 3%;

    &:hover {

        .captionHover_container {
          opacity: 1;
        }
    }

    .content_image_container {
      border-radius: 6px;
      height: 200px;
      position: relative;

      @include media($break465) {
        height: 250px;
      }

      @include media($break360) {
        height: 218px;
        margin: 0 auto;
      }

      @include media($break325) {
        height: 184px;
      }

      &:hover {
				opacity: .9;
			}
    }

    .contentCheckbox_control {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 10;
    }

    .content_container {
      height: 100%;
    }

    .captionContainer {
      border-radius: 0 0 6px 6px;
    }

    .imgContent_container {
      border-radius: 6px;
    }

    .videoThumb {
      position: absolute;
      top: 0;
      left: 0;
      width: 160%;
      z-index: 1;
      overflow: hidden;
      border-radius: 6px;
    }

    .imgContent {
      position: absolute;
      overflow: hidden;
      width: 100%;
      border-radius: 6px;
      left: 0;
      top: 0;
    }
  }

  .txtContent_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.weirdImg_content_item {
  &.warning {
      border-radius: 0px;
      border: 2px solid red;
  }
}


.newContent_object {
  background-color: #DEE0E4;

  .controlBtn {

    &:active {
      background-color: $revfluence-blue;
    }
  }

  .contentImg_wrap {

    .content_img {
      max-width: 100%;
    }
  }

  .hoverContent {
    opacity: 0;
    position: absolute;
    transition: all .15s ease-in-out;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .hoverHeader {
      position: absolute;
      top: 0;
      width: 100%;
      left: 0;
      right: 0;
      padding: 1rem 1rem 0;

      .leftWrap {
        float: left;
        width: calc(100% - 75px);
      }

      .nameWrap {
        font-size: .7rem;

        .createdBy {
          margin-right: .75rem;
          display: inline-block;
          vertical-align: 6px;
        }

        .creatorAvatar {
          border-radius: 100px;
          border: 2px solid white;
          width: 30px;
          display: inline-block;
          margin-right: .25rem;
          vertical-align: -2px;
        }

        .username {
          display: inline-block;
          max-width: calc(100% - 50px);
        }
      }

      .rightWrap {
        float: right;
      }

      .contentControls {}

      .controls_list {}

      .controls_list {}

      .controls_item {
        display: inline-block;
        margin-right: .5rem;

        &:last-child {
          margin-right: 0;
        }
      }

      .controlBtn {
        border: none;
        border-radius: 3px;
      }

      .likeIcon {}

      .likeContent_btn, .searchContent_btn {
        position: relative;
        right: initial;
        top: initial;
      }

      .searchContent_btn {}

      .icon_like_btn {}

      .icon_search_white {}

      .likeContent_btn, .icon_like_btn {}

      .createdBy, .username {
        color: white;
        font-weight: 400;
      }

      .ecvAmount {
        font-size: 2rem;
        font-weight: 600;
      }
    }

    .hoverFooter {
      bottom: 0;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 1rem 1rem;

      .leftWrap {
        float: left;
        width: 115px;
        padding-right: .75rem;

        .ecvAmount, .ecvLabel {
          color: white;
          display: block;
        }

        .ecvAmount {
          font-weight: 600;
          font-size: 1.6rem;
        }

        .ecvLabel {}
      }

      .rightWrap {
        float: right;
        width: calc(100% - 115px);

        .sampleHover_chart {
          max-width: 100%;
          padding-top: 10px;
        }
      }
    }

    .hoverHeader, .hoverFooter {
      z-index: 3;
    }
  }

  .shadowsWrap {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2;
    bottom: 0;
    opacity: .6;

    .topShadow, .botShadow {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
    }

    .topShadow {
      top: 0;
    }

    .botShadow {
      bottom: 0;
    }
  }
}
