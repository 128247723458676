.new_manage {

  .content_approval_tool {

    .content_cell {
      display: inline-block;
      margin: 0 0 1rem 1rem;
      width: 215px;
      vertical-align: top;
      position: relative;
    }

    .approval_tooltip {
      margin: .5rem 0 1rem;

      .campaign_code_tip {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
      }

      .bullet_list {
        list-style: disc;
        list-style-position: outside;
        padding-left: 20px;
      }
  
      .bullet_item {
        font-weight: 300;
        color: $dark-text;
        margin-bottom: 1em;
      }

      .content_upload_tooltip {
        max-width: 100px;

      }
    }

    .content_preview_module {
      border: 1px solid $lightgray-border;
      margin: .5rem 0 1rem;
      padding: .9rem 1.5rem 1.5rem;
      width: 99%;
      border-radius: 5px;
      display: inline-block;
      background-color: white;

      .preview_label {
        display: block;
        margin-bottom: .5rem;
        font-weight: 600;
      }

      .video_link {
        display: block;
        margin-bottom: .9rem;
      }

      .thumbnail_container {
        margin-right: 1.5rem;
        display: inline-block;

        @include media($break1135) {
          width: 100%;
          float: none;
          margin-right: 0;
          margin-bottom: 1rem;
        }

        .thumbnail_link {
          cursor: pointer;
        }
      }

      .content_thumbnail {
        max-width: 215px;
        border-radius: 8px;
        position: relative;
      }

      .thumbnail_link {
        display: block;
        position: relative;
      }

      .network_indicator_container {
        position: absolute;
        top: .5rem;
        left: .5rem;
        padding: 6px;
        background-color: white;
        border-radius: 100px;
        z-index: 2;
        width: 30px;
        height: 30px;

        .network_indicator_icon {}
      }

      .instagram_icon, .youtube_icon {
        vertical-align: initial;
      }

      .instagram_icon {
        width: 16px;
        height: 15px;
        margin: 1px auto 0;
        display: block;
      }

      .youtube_icon {
        width: 17px;
        height: 13px;
        margin-top: 3px;
      }

      .likeContent_btn {
        
      }

      .caption_container {
        float: right;
        width: calc(100% - 239px);

        .full_caption_text {
          white-space: pre-wrap;
        }

        @include media($break1135) {
          float: none;
          width: 100%;
        }
      }

      .caption_txt {
        overflow: hidden;
        text-overflow: ellipsis;
        height: 81px;
        white-space: pre-wrap;
      }

      .full_caption_txt{
        text-overflow: ellipsis;
        white-space: pre-wrap;
      }

      .show_caption_btn {
        background: none;
        color: $medium_gray;
        padding: 0;
        margin-top: .6rem;

        &:hover {
          color: $revfluence-blue;
        }

        &:active, &:focus {
          box-shadow: none;
        }
      }
    }
  }
}
