.campaign_marketplace {
	height: 100%;
	overflow-y: scroll;
	position: relative;

	.searchInput_container {
		position: absolute;
		right: 0;
		top: 50%;
		margin-top: -20px;

		@include media($break1000) {
			right: .5rem;
		}

		.clear_txt_btn {
			position: absolute;
			top: 7px;
			right: .35rem;
			background: none;
			border: none;
			z-index: 2;
			padding: .25rem;

			&:focus {
				outline: none;
			}

			.remove_icon {
				width: 17px;
			}
		}
	}

	.searchInputContainerWithFilters {
		display: inline-block;
		position: relative;

		@include media($break1000) {
			width: 100%;
			padding: 0 1em;
		}

		.clear_txt_btn {
			position: absolute;
			top: 7px;
			right: .35rem;
			background: none;
			border: none;
			z-index: 2;
			padding: .25rem;

			&:focus {
				outline: none;
			}

			.remove_icon {
				width: 17px;
			}

			@include media($break1000) {
				right: 1.1rem;
			}
		}

		.searchCampaigns_input {
			@include media($break1000) {
				width: 100%;
			}
		}
	}

	.small_searchInput_container {
		display: none;
	}

	.searchCampaigns_input {
		width: 257px;
		background-image: url('/images/style_guide/icon_search_black.svg');
		background-position: .75rem center;
		background-repeat: no-repeat;
		padding: .65rem 2rem .65rem 2rem;
		border-radius: 100px;
		background-size: 16px;
		font-size: .85rem;
	}

	.main_header {
		margin-top: 50px;
		position: relative;
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		background-color: white;
		background-color: rgba(255,255,255,.96);
		z-index: 6200;
		box-shadow: 0 1px 10px rgba(0,0,0,.1);

		@include media($break765) {
			display: none;
		}

		.more_filters_popup {
			display: none;
			position: absolute;
			top: 71px;
			right: 137px;
			width: 215px;
			background: white;
			border-width: 1px;
			border-color: rgb(242, 241, 241);
			border-style: solid;
			box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.11);
			padding: 2em;
			z-index: $zindexTutorial;

			&:after {
				content: '';
				position: absolute;
				border-style: solid;
				border-width: 0 10px 10px;
				border-color: #FFFFFF transparent;
				display: block;
				width: 0;
				z-index: 1;
				top: -10px;
				left: 176px;
			}

			.close_button_container {
				border-top: 1px solid #E7E7E7;
				display: none;
				margin-top: 2em;
				padding-top: 1.25em;

				@include media($break450) {
					display: block;
				}

				.close_button {
					color: #7E7E7E;
					font-weight: 200;
					line-height: 1.75em;
					display: block;
					text-decoration: none;
					font-size: 1.35em;
					text-align: center;

					&:hover {
						color: $dark-text;
					}
				}
			}
		}

		.filter_categories_list {
			margin-bottom: 2em;
			border-bottom: 1px solid #e7e7e7;
			padding-bottom: 3em;

			li {
				marign-bottom: 2em;
			}

			a {
				color: #7e7e7e;
				font-weight: 200;
				line-height: 1.75em;
				display: block;
				text-decoration: none;
				font-size: 1.35em;

				&:hover {
					color: $dark-text;
				}
			}

			.active {
				font-weight: 600;
				color: $dark-text;
			}
		}

		.filter_offerType_list {

			li {
				marign-bottom: 2em;
			}

			a {
				color: #7e7e7e;
				font-weight: 200;
				line-height: 1.75em;
				display: block;
				text-decoration: none;
				font-size: 1.35em;

				&:hover {
					color: $dark-text;
				}
			}

			.active {
				font-weight: 600;
				color: $dark-text;
			}
		}

		.inner_container {
			max-width: 1200px;
			margin: 0 auto;
			overflow: hidden;
			padding: 1.25rem 0 1.5rem 0;
			position: relative;

			@include media($break1000) {
				padding: 2em .5rem 1rem .5rem;
			}

			@include media($break765) {
				padding: 2rem 1rem 2rem 1rem;
			}
		}

		.main_title {
			font-size: 2rem;
		}

		.main_subtitle {
			font-size: 1.4em;
			font-weight: 300;
			margin-top: 1.0em;
			margin-left: 0.2em;
			color: $dark-text;
		}

		.filters_container {
			display: inline-block;
			@include media($break500) {
				width: 120px;
				margin-right: 1.85%;
			}

			@include media($break1000) {
				padding-top: 1em;
			}

			.filter_dropdown {
				padding-left: 0.5rem;
				width: 193px;
				display: inline-block;

				&.smaller_dropdown {
					width: 130px;
				}

				&.medium_dropdown {
					width: 160px;
				}
			}

			.filtersBtn {
				padding-left: 1rem;
				font-size: 1.2em;
			}
		}

		.filters_list {

			.youtube_container, .instagram_container, .more_filters_container {
				display: inline-block;
				margin-right: 4em;
				@include media($break500) {
					margin-right: 3em;
				}

				&:last-child {
					margin-right: 0;
				}

				a {
					display: block;
				}
			}

			.youtube_container {
				vertical-align: -.1em;
				@include media($break500) {
					vertical-align: 0;
				}
			}
		}

		.youtube_toggle {
			display: block;
			width: 25px;
			height: 25px;
			fill: #c8c8c8;
			@include media($break500) {
				width: 17px;
				height: 17px;
			}
		}

		.instagram_toggle {
			width: 22px;
			height: 22px;
			fill: #c8c8c8;
			@include media($break500) {
				width: 15px;
				height: 15px;
			}
		}

		.instagram_active {
			fill: #3f729b;
		}

		.youtube_active {
			fill: #f32732;
		}

		.youtube_campaign {}

		.instagram_container {}

		.more_filters_container {
			vertical-align: -.6em;
			@include media($break500) {
				vertical-align: -.4em;
			}
		}

		.more_filters_toggle {
			width: 35px;
			height: 35px;
			@include media($break500) {
				display: none;
			}

			rect {
				fill: #c8c8c8;
			}

			polygon {
				fill: #c8c8c8;
			}
		}

		.more_filters_toggle_active {

			rect {
				fill: $dark-text;
			}

			polygon {
				fill: $dark-text;
			}
		}

		.small_filters_toggle {
			display: none;
			width: 22px;
			height: 22px;

			circle {
				fill: #c8c8c8;
			}

			@include media($break500) {
				display: block;
			}

		}

		.small_filters_toggle_active {
			circle {
				fill: $dark-text;
			}
		}
	}
}
