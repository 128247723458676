.best_practices {
	background-color: white;

	strong {
		font-weight: 600;
	}

	.main_header {
		margin-top: 7em;

		.inner_container {
			max-width: 1200px;
			margin: 0 auto 3em;
			overflow: hidden;
		}

		.main_title {
			text-align: center;
			font-weight: 300;
			font-size: 5em;
			line-height: 1.5em;
			color: $dark-text;

			@include media($break492) {
				font-size: 3.7em;
			}

			@include media($break320) {
				font-size: 3em;
			}
		}

		.network_type_container {
			overflow: hidden;
			width: 132px;
			margin: 0 auto;
		}

		.for_text {
			float: left;
			margin-right: .5em;
			font-size: 2.5em;
			font-style: italic;
			font-weight: 300;
			color: $dark-text;
			margin-top: .25em;
			@include media($break320) {
				font-size: 2em;
			}
		}

		.youtube_logo {
			float: left;
			width: 85px;

			@include media($break320) {
				width: 66px;
			}
		}

		.instagram_logo {
			float: left;
			width: 45px;

			@include media($break320) {
				width: 35px;
				margin-top: 4px;
			}
		}
	}

	.best_practices_nav {

		.inner_container {
			max-width: 1000px;
			border-bottom: 1px solid #e7e7e7;
			overflow: hidden;
			margin: 0 auto;
			padding-bottom: 1.25em;

			@include media($break492) {
				padding-bottom: 2em;
			}
		}

		.largeNav_inner_container {
			padding-bottom: 0;
			max-width: 1000px;
			overflow: hidden;
			margin: 0 auto;

			@include media($break937) {
				display: none;
			}

			.faq_link {
				float: right;
				text-decoration: none;
				color: $revfluence-blue;
				line-height: 1.5em;
				font-size: 1.5em;
				font-weight: 300;
				padding: .25em 1em;
				border: 1px solid $revfluence-blue;
				border-radius: 45px;
				text-align: center;
				margin-left: 2em;

				@include media($break937) {
					display: none;
				}

				@include media($break936) {
					margin: 0 auto;
					float: none;
					display: block;
					width: 95px;
				}

				&:hover {
					color: $revfluence-blue-hover;
					border: 1px solid $revfluence-blue-hover;
				}
			}
		}

		.small_pageNav_container {
			display: none;
			width: 212px;
			margin: 0 auto;
			overflow: hidden;

			&:focus {
				color: white;
			}

			@include media($break937) {
				display: block;
			}

			.faq_link {
				float: none;
				display: none;
				width: 65px;
				text-decoration: none;
				color: $revfluence-blue;
				line-height: 1.5em;
				font-size: 1.5em;
				font-weight: 300;
				border: 1px solid $revfluence-blue;
				border-radius: 45px;
				text-align: center;
				padding: .25em 1em;
				margin: 1em auto;

				&:hover {
					color: $revfluence-blue-hover;
					border: 1px solid $revfluence-blue-hover;
				}

				@include media($break937) {
					display: block;
				}
			}

			.select {
				width: 190px;
				marign: 0 auto;
				display: block;
			}
		}

		.navigation_list {
			list-style-type: decimal;
			margin: auto;
			text-align: center;
			@include media($break936) {
				float: none;
				text-align: center;
				margin-bottom: 1em;
			}
		}

		.navigation_item {
			display: inline-block;
			margin-right: 4em;

			@include media($break936) {
				float: none;
			}

			@include media($break492) {
				display: block;
				margin-right: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}

		.navigation_link {
			text-decoration: none;
			color: #a0a0a0;
			line-height: 2.2em;
			font-size: 1.5em;
			font-weight: 300;

			&:hover {
				color: $revfluence-blue;
			}
		}

		.active_link {
			font-weight: 600;
			color: $dark-text;
		}
	}

	.is_fixed {
		position: fixed;
		top: 5em;
		width: 100% !important;
		max-width: 100% !important;
		left: 0;
		padding: 1em 1em;
		background-color: white;
		z-index: 7000;
		border-bottom: 1px solid #e7e7e7;

		.inner_container {
			border-bottom: none;
			overflow: hidden;
		}

		.faq_link {
			@include media($break937) {
				display: block;
				margin: .4em 0 0 2.4em !important;
				float: left !important;
			}


			@include media($break315) {
				margin: 0.09em 0px 0px 1.4em !important;
			}
		}

		.select {
			@include media($break937) {
				float: left;
			}

			@include media($break315) {
				width: 121px !important;
			}

			select {

				@include media($break315) {
					font-size: 1em;
					padding: 0.5rem .15rem 0.5rem .5rem !important;
				}
			}
		}
	}

	.best_practice_section {

		.inner_container {
			max-width: 1000px;
			margin: 0 auto;
			overflow: hidden;
			padding: 6em 0;

			@include media($break1215) {
				padding: 6em 2em;
			}

			@include media($break740) {
				padding: 6em 2em;
			}
		}

		.main_title {
			font-size: 3.35em;
			font-weight: 300;
			color: $dark-text;
			line-height: 1.2em;
			margin-bottom: .5em;

			@include media($break740) {
				font-size: 3em;
			}
		}

		.main_paragraph {
			font-size: 2em;
			font-weight: 300;
			color: $dark-text;
			margin-bottom: 1.75em;
			line-height: 1.5em;

			strong {
				font-weight: 600;
			}
		}

		.bullet_list {
			list-style: disc;
			list-style-position: inside;
		}

		.bullet_item {
			font-size: 1.5em;
			line-height: 1.5em;
			font-weight: 300;
			color: $dark-text;
			margin-bottom: 1em;
		}

		.text_container {

			a {
				color: $revfluence-blue;
				text-decoration: none;

				&:hover {
					color: $revfluence-blue-hover;
				}
			}
		}

		.visual_container {}

		.visual_item {
			max-width: 100%;
		}

		.bottom_border_container {
			border-top: 1px solid #dadada;
			position: relative;
			max-width: 1000px;
			margin: 0 auto;

			.revfluence_logomark {
				position: absolute;
				left: 50%;
				top: -21px;
				margin-left: -30px;
				width: 60px;
			}
		}

		.sub_points_list {
			margin-top: 1em;
			list-style: decimal;
			list-style-position: inside;
			padding-left: 2em;
			margin-bottom: 2em;
		}

		.sub_point_item {
			margin-bottom: 1em;
		}

	}

	.budget_section {

		.text_container {
			width: calc(100% - 480px);
			float: left;
			margin-right: 3%;
			margin-top: 4em;

			@include media($break857) {
				width: calc(100% - 401px);
				margin-top: 0;
			}

			@include media($break740) {
				width: 100%;
				float: none;
				margin-right: 0;
			}
		}

		.visual_container {
			width: 420px;
			float: right;

			@include media($break857) {
				width: 360px;
			}

			@include media($break740) {
				width: 100%;
				float: none;
			}
		}
	}

	.inviting_creators_section {

		.text_container {
			width: calc(100% - 480px);
			float: right;
			margin-top: 3em;

			@include media($break857) {
				width: calc(100% - 401px);
				margin-top: 0;
			}

			@include media($break740) {
				width: 100%;
				float: none;
				margin-right: 0;
			}
		}

		.visual_container {
			width: 420px;
			float: left;
			margin-right: 3%;

			@include media($break857) {
				width: 360px;
			}

			@include media($break740) {
				width: 100%;
				float: none;
				display: none;
			}
		}

		.small_screen_visual_container {
			display: none;

			@include media($break740) {
				display: block;
				width: 100%;
				float: none;

				.visual_container {
					display: block;
				}
			}
		}
	}

	.evaluating_proposals_section {

		.text_container {
			width: calc(100% - 480px);
			float: left;
			margin-right: 3%;

			@include media($break857) {
				width: calc(100% - 401px);
				margin-top: 0;
			}

			@include media($break740) {
				width: 100%;
				float: none;
				margin-right: 0;
			}

			.embeds_container {
				overflow: hidden;
			}

			.embed_link {
				position: relative;
				display: block;
				float: left;
				width: 125px;
				margin-right: 2em;

				&:last-child {
					margin-right: 0;
				}
			}

			.play_icon {
				background: url('/images/best_practices/play_icon.svg') center no-repeat;
				position: absolute;
				left: 50%;
				top: 50%;
				width: 30px;
				height: 30px;
				margin: -15px 0 0 -15px;
			}

			.embed_thumbnail {
				max-width: 100%;
				border-radius: 4px;
			}
		}

		.visual_container {
			width: 380px;
			float: right;
			margin-top: 3em;

			@include media($break857) {
				width: 320px;
			}

			@include media($break740) {
				width: 100%;
				float: none;
			}
		}
	}

	.video_success_section {

		.text_container {
			width: calc(100% - 480px);
			float: right;
			margin-top: 3em;

			@include media($break857) {
				width: calc(100% - 401px);
				margin-top: 0;
			}

			@include media($break740) {
				width: 100%;
				float: none;
				margin-right: 0;
			}
		}

		.visual_container {
			width: 420px;
			float: left;
			margin-right: 3%;

			@include media($break857) {
				width: 360px;
			}

			@include media($break740) {
				width: 100%;
				float: none;
			}
		}

		.small_screen_visual_container {
			display: none;

			@include media($break740) {
				display: block;
				width: 100%;
				float: none;

				.visual_container {
					display: block;
				}
			}
		}
	}

	.reinvest_success_section {

		.text_container {
			width: calc(100% - 480px);
			float: left;
			margin-right: 3%;
			margin-top: 4em;

			@include media($break857) {
				width: calc(100% - 401px);
				margin-top: 0;
			}

			@include media($break740) {
				width: 100%;
				float: none;
				margin-right: 0;
			}
		}

		.visual_container {
			width: 420px;
			float: right;

			@include media($break857) {
				width: 360px;
			}

			@include media($break740) {
				width: 100%;
				float: none;
			}
		}
	}

	.examples_section {

		.main_title {
			text-align: center;
			margin-bottom: 1em;
		}

		.first_example_container {
			float: left;
			margin-right: 5%;
		}

		.second_example_container {
			float: left;
		}

		.example_container {
			width: 47.5%;
			transition: all .15s ease-in-out;

			&:hover {
				opacity: .95;
			}

		}

		.thumbnail_container {
			position: relative;
			margin-bottom: 2em;
		}

		.play_icon {
			width: 75px;
			height: 75px;
			position: absolute;
			left: 50%;
			top: 50%;
			margin-left: -37.5px;
			margin-top: -37.5px;
			display: block;
			text-indent: -9999px;
			background: url('/images/best_practices/play_icon.svg') center no-repeat;

			@include media($break600) {
				width: 45px;
				height: 45px;
				margin-left: -22.5px;
				margin-top: -22.5px;
			}
		}

		.thumbnail_image {
			max-width: 100%;
		}

		.example_title {
			margin: 0 auto .5em;
			max-width: 385px;
			text-align: center;

			@include media($break1017) {
				max-width: 100%;
				width: 100%;
			}

			font-size: 2.85em;
			font-weight: 300;
			color: $dark-text;
			line-height: 1.5em;
			display: block;
			text-decoration: none;

			@include media($break1017) {
				font-size: 2em;
			}

			@include media($break600) {
				font-size: 1.5em;
			}
		}
	}

	.youtube_container {}

	.instagram_container {

		.network_type_container {
			width: 89px;
		}
	}
}