.emailCollect {

  .inner_container {
    max-width: 1000px;
    margin: 0 auto;
    padding-bottom: 3rem;

    @include media($break425) {
      padding-bottom: 2rem;
    }
  }

  .emailCollect_header {
    padding: 2rem 0;

    @include media($break425) {
      padding: 1rem 0;
    }

    .inner_container {
      padding-bottom: 0;
    }

    .logoLInk {}

    .revLogo {
      width: 200px;
      display: block;
      margin: 0 auto;

      @include media($break425) {
        width: 165px;
      }
    }
  }

  .emailCollect_body {
    padding: 0 2rem;

    @include media($break425) {
      padding: 0 1rem;
    }

    .info_container {
      float: left;
      width: 41%;
      margin-right: 3%;

      @include media($break680) {
        float: none;
        width: 100%;
        margin-right: 0;
        padding-bottom: 2rem;
        display: none;
      }

      .infoTitle, .infoDescrip, .infoList {
        margin-bottom: 1rem;
      }

      .infoTitle {

        @include media($break425) {
          font-size: 1.4rem;
        }
      }

      .infoList {
        list-style: disc;
        list-style-position: inside;

        > li {
          margin-bottom: .5rem;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .infoVisual {
        display: block;
        max-width: 100%;

        @include media($break680) {
          max-width: 300px;
          margin: 0 auto;
        }

        @include media($break425) {
          max-width: 100%;
        }
      }
    }

    .mobile_info_container {
      display: none;

      @include media($break680) {
        display: block;
        padding-bottom: 0;
        padding-top: 2rem;
      }
    }

    .form_container {
      float: left;
      width: 56%;
      border: 1px solid $lightgray-border;
      border-radius: 8px;
      padding: 1.75rem 2.75rem;

      @include media($break680) {
        float: none;
        width: 100%;
      }

      @include media($break425) {
        padding: 1.75rem 1.25rem;
      }

      .searchIcon {}

      .formIcon {
        width: 65px;
        margin-bottom: .5rem;
      }

      .formTitle {
        margin-bottom: .5rem;
        font-weight: 600;

        @include media($break425) {
          font-size: 1.85rem;
        }
      }

      .formDescrip {
        margin-bottom: 1rem;
      }

      .createAccount_form {}

      .row, .email_fieldset, .website_fieldset {
        margin-bottom: .65rem;
      }

      .firstName_fieldset, .lastName_fieldset {
        float: left;
      }

      .firstName_fieldset {
        width: 48%;
        margin-right: 2%;
      }

      .lastName_fieldset {
        width: 50%;
      }

      .email_fieldset {}

      .email_input {
        max-width: 191px;
      }

      .website_fieldset {}

      .standard_label {}

      .form_control {}

      .seeDash_btn {
        margin-top: .5rem;
      }

      .error_container {
        margin-top: .25rem;
        color: $error_red;
        font-weight: 600;
      }
    }
  }

  .emailCollect_footer {
    text-align: center;
    border-top: 1px solid $lightgray-border;
    padding: 3rem 2rem;

    @include media($break425) {
      padding: 3rem 2rem;
    }

    .inner_container {
      padding-bottom: 0;
    }

    .quoteAuthor_avatar {
      border: 2px solid white;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, .1);
      width: 50px;
      display: block;
      margin: 0 auto 1rem;
      border-radius: 100px;
    }

    .quoteTxt {
      margin-bottom: 1rem;
      font-size: 1.15rem;
    }

    .quoteAuthor_txt {
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: .25rem;
      display: block;
      font-size: .75rem;
    }
  }
}
