.card-added {
	border: 1px solid $lightgray-border;
	padding: 1em;
	@include standard-radius;
	overflow: hidden;
	margin-bottom: 2em;

	.card-added-title {
		text-align: center;
		color: $dark-text;
		@include fontsize15;
		font-weight: 600;
		margin-bottom: .5em;
	}

	.addNew_card_btn {
		display: block;
		width: 171px;
		margin: 0 auto;

		.icon_addItem {
			vertical-align: -9px;
			margin-right: .15rem;
		}
	}

	.card-icon {
		display: inline-block;
		width: 46px;
		height: 32px;
		margin-right: .5em;
		vertical-align: -7px;
	}

	.card-number {
		color: $lightgray-text;
		@include fontsize16;
		font-weight: 300;

		.revealed-numbers {
			color: $dark-text;
		}
	}

	.change-link {
		@include blue-link;
	}
}
