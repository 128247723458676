.collaboration {

	.modal-content {
		overflow: hidden;
	}

	.modal-dialog {
        width: 90%;
		max-width: 800px;
		margin: 4em auto;
		@include media($break675) {
			width: 95%;
			padding: 4em;
		}
		@include media($break450) {
			padding: 3em;
		}

		.close-modal-link {
			position: absolute;
			top: 1em;
			right: 1em;
			width: 20px;
			height: 20px;
			background: url('../images/agreements/close_icon.svg') center no-repeat;
		}
	}

	.collaboration_header {
		text-align: center;
		margin: 4em;
		border-bottom: 1px solid $lightgray-border;
		padding-bottom: 3em;
		@include media($break675) {
			margin: 0;
			margin-bottom: 3em;
		}
	}

	.collaboration_icon {
		display: inline-block;
		margin-right: 1em;
		border-radius: 6px;
		vertical-align: -13px;
	}

	.collaboration_title {
		@include title35;
		color: $dark-text;
		display: inline-block;
		@include media($break675) {
			@include title30;
		}
		@include media($break450) {
			@include title22;
			font-weight: 600;
		}
	}

	.proposed_by {
		@include fontsize15;
		color: $dark-text;
		display: block;
		margin-top: .25em;

		a {
			@include blue-link;
		}
	}

	.collaboration_body {
		margin: 0 auto 4em;
		width: 85%;
        max-width: 600px;
        @include media($break675) {
        	width: 100%;
        }
	}

	.main_details, .product_details, .guideline_details {
		@include fontsize15;
		color: $dark-text;
		margin-bottom: 2em;

        strong {
            font-weight: 800;
        }
	}

	.creator_reqs {
		margin-bottom: 3em;
	}

	.reqs_title {
		@include fontsize15;
		color: $dark-text;
		font-weight: 600;
        margin-bottom: 0.5em;
	}

	.reqs_list {
		@include fontsize15;
		color: $dark-text;
		list-style-type: initial;
		list-style-position: inside;

		li {
			margin-bottom: .5em;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

    .status_section {
        width: 85%;
        margin: auto;
        p {
            text-align: center;
            @include fontsize15;
            color: $dark-text;
            span {
                font-weight: 800;
            }

            &.invalid_iteration {
                color: $label-red;
            }
        }
        .see_latest_link {
            @include blue-link;
            margin-top: 0.5em;
        }
    }

	.collaboration_actions {
		text-align: center;
		padding-bottom: 4em;
		@include media($break675) {
			padding-bottom: 0;
		}

		li {
			display: inline-block;
			margin-right: 1em;
			&:last-child {
				margin-right: 0;
			}
		}

		.edit_button {
			display: inline-block;
		}

		.accept_button {
			display: inline-block;
		}
	}

	del {
		color: $lightgray-text;
	}

	.updated_content {
		background-color: $highlight_color;
		color: $dark-text;
		@include standard-radius;
		padding: .04em .3em;
		font-weight: 600;
	}
}
