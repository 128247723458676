
.signatured_pad_popup {

    .modal-dialog {
      position: relative;

      @include media($break630) {
        width: 95%;
        margin: 25px auto;
      }
    }

    .sigModal_header {
      text-align: center;
      padding: .5rem 1rem 0;
    }

    .sign_terms_form {
      height: 345px;
    }

    .m-signature-pad {
        position: relative;
        font-size: 10px;
        width: 100%;
        height: 300px;
        background-color: #fff;
    }

    .m-signature-pad:before, .m-signature-pad:after {
        position: absolute;
        z-index: -1;
        content: "";
        width: 40%;
        height: 10px;
        left: 20px;
        bottom: 10px;
        background: transparent;
        -webkit-transform: skew(-3deg) rotate(-3deg);
        -moz-transform: skew(-3deg) rotate(-3deg);
        -ms-transform: skew(-3deg) rotate(-3deg);
        -o-transform: skew(-3deg) rotate(-3deg);
        transform: skew(-3deg) rotate(-3deg);
        /* box-shadow: 0 8px 12px rgba(0, 0, 0, 0.4); */
    }

    .m-signature-pad:after {
        left: auto;
        right: 20px;
        -webkit-transform: skew(3deg) rotate(3deg);
        -moz-transform: skew(3deg) rotate(3deg);
        -ms-transform: skew(3deg) rotate(3deg);
        -o-transform: skew(3deg) rotate(3deg);
        transform: skew(3deg) rotate(3deg);
    }

    .m-signature-pad--body {
        position: absolute;
        left: 20px;
        right: 20px;
        top: 20px;
        bottom: 60px;
    }

    .m-signature-pad--body
    canvas {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        border: 1px solid #CBCBCB;
    }

    .m-signature-pad--footer {
        position: absolute;
        left: 20px;
        right: 20px;
        bottom: 20px;
        height: 40px;
    }

    .m-signature-pad--footer
    .description {
        color: $dark-text;
        text-align: center;
        font-size: 1rem;
        margin-top: .75rem;
        font-weight: 300;
    }

    .m-signature-pad--footer
    .button {
        position: absolute;
        bottom: 0;
    }

    .m-signature-pad--footer
    .button.clear {
        left: 0;
    }

    .m-signature-pad--footer
    .button.save {
        right: 0;
    }

    @media screen and (max-width: 1024px) {

        #github {
            display: none;
        }
    }

    @media screen and (max-height: 320px) {
        .m-signature-pad--body {
            left: 0;
            right: 0;
            top: 0;
            bottom: 32px;
        }
        .m-signature-pad--footer {
            left: 20px;
            right: 20px;
            bottom: 4px;
            height: 28px;
        }
        .m-signature-pad--footer
        .description {
            font-size: 1em;
            margin-top: 1em;
        }
    }

    .sigButtons_container {
      text-align: center;
      padding-top: 1rem;
    }

    .submit_btn {
      margin-right: .5rem;
    }
}
