.auth_gdrive {

  .close_modal_btn {
    position: absolute;
    right: .5rem;
    top: .6rem;

    .icon_close_modal {
      width: 15px;
    }
  }

  .modal-dialog {
    padding: 2rem;
    max-width: 700px;
  }

  .auth_gdrive_header {
    text-align: center;

    .gdrive_complete_icon {
      display: block;
      margin: 0 auto 1rem;
      width: 65px;
    }

    .main_title {
      padding-bottom: .5rem;
    }

    .main_descrip {
      margin-bottom: 2rem;
      font-size: 1.1rem;
    }
  }

  strong {
    font-weight: 600;
  }

  .screenshot_wrap {
    width: 250px;
    margin: 0 auto 2rem;
  }

  .screenshot_img {
    max-width: 100%;
  }

  .actions_container {
    text-align: center;
  }

  .btn_primary {
    margin-right: .5rem;
  }

  .icon_chevron {
    margin-left: .3rem;
    vertical-align: -2px;
    width: 9px;
  }

}
