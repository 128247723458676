.delete_collab_popup {

  .modal-dialog {
    padding: 1rem 1rem 1.25rem;
    text-align: center;

    @include media($break600) {
      margin: 35px auto;
    }
  }

  .close-modal-link {
    position: absolute;
    right: .75rem;
    top: .75rem;
  }

  .main_title {
    padding-bottom: .25rem;
  }

  .warning_color_icon {
    width: 25px;
    margin-right: .5rem;
    vertical-align: -1px;
  }

  .main_descrip {
    padding-bottom: 1rem;
  }

  .actions_container {}

  .btn_primary, .btn_default {
    display: inline-block;
  }

  .btn_primary {
    margin-right: .25rem;
  }

  .btn_default {}

  .delete_collab_btn {
    background-color: red;
  }
}
