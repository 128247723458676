.filter_items_modal {

  .close-modal-link {
    right: 0;
    position: absolute;
    top: .75rem;
  }

  .modal-dialog {
    padding: 1rem 2rem 2.5rem;
    max-width: 800px;
    overflow: initial;
  }

  .main_title {
    padding: 1rem 0 1.7rem;
  }

  .row {
    padding-bottom: 1rem;

    @include media($break560) {
      border-bottom: 1px solid $lightgray-border;
      padding-bottom: .5rem;
      margin-bottom: 1rem;
    }
  }

  .doubleArrow_select_container {
    padding-right: .5rem;
  }

  .dropdown_btn2 {
    margin-bottom: 0;
  }

  .column_select {
    width: 39% !important;

    .doubleArrow_select_btn {
      width: 100%;
    }
  }

  .select_btn_txt {
    width: calc(100% - -3px);
  }

  .column_select_dropdown {
    top: 38px;
    right: -12px;
  }

  .filter_select {
    width: 33% !important;

    .doubleArrow_select_btn {
      width: 100%;
    }
  }

  .filter_dropdown {
    width: 215px;
    top: 38px;
    right: -12px;

    &:before, &:after {
      left: 175px;
    }
  }

  .dropdown_container, .amount_input, .remove_btn {
    float: left;

    @include media($break560) {
      float: none;
      margin-bottom: .5rem;
      width: 100%;
      display: block;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .column_select_dropdown {
    width: 265px;

    &:before, &:after {
      left: 225px;
    }

    .col_dropdown_list_list {
      overflow: auto;
      max-height: 200px;
    }
  }

  .amount_input {
    width: 19.5%;
  }

  .remove_btn {
    padding: .7rem;
    margin-left: .5rem;

    @include media($break560) {
      width: auto;
    }
  }

  .remove_icon {
    width: 13px;
  }

  .new_row {

    .remove_btn {
      display: none;
    }
  }

  .apply_btn {
    margin-right: .5rem;
  }

  .modal_actions_container {
    padding-top: .5rem;
    text-align: center;
  }
}
