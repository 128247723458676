.direct_offer {

  .aspirex_embedded {
    .direct_offer_header {
      margin-top: 0;
    }
  }

  .direct_offer_header {
    background-color: white;
    margin-top: 3.1rem;
    border-bottom: 1px solid $lightgray-border;
    padding: 1.1rem 0 1.5rem;

    @include media($break695) {
      padding: 1.1rem 0 1.25rem;
    }

    .inner_container {
      max-width: 1400px;
      margin: 0 auto;
      position: relative;
    }

    .back_btn {
      display: inline-block;
      float: left;
      width: 45px;
      height: 45px;
      background: url('/images/direct_offer/back_icon.svg') center no-repeat;
      border: none;
      background-size: 55%;
      padding: 0;
      margin: .5rem .25rem 0 0;
      cursor: pointer;
      outline: none;

      @include media($break695) {
        position: absolute;
        left: .5rem;
        top: 0;
      }
    }

    .main_info_container {
      float: left;
      width: calc(100% - 273px);

      @include media($break695) {
        float: none;
        width: 100%;
        border-bottom: 1px solid $lightgray-border;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
      }
    }

    .avatar_container {
      position: relative;
      float: left;
      margin-right: 1rem;
      width: 65px;

      @include media($break695) {
        float: none;
        margin: 0 auto;
      }
    }

    .message_icon {
      position: absolute;
      top: -6px;
      right: -10px;
      width: 33px;
      height: 40px;
    }

    .user_avatar {
      display: block;
      border-radius: 100px;
      width: 60px;
      height: 60px;
      box-shadow: 0 1px 5px rgba(0,0,0,.15);
      border: 2px solid white;
    }

    .text_container {
      float: left;
      width: calc(100% - 81px);
      padding: .8rem 0 0 .5rem;

      @include media($break695) {
        width: 100%;
        text-align: center;
      }

      .offers_left {
        display: block;

         strong {
           font-weight: 600;
         }
      }
    }

    .send_offer_title {
      font-size: 1.5rem;
    }

    .steps_list {
      width: 209px;
      float: right;
      background: url('/images/direct_offer/step_lines.svg') center 14px no-repeat;
      margin: .55rem .9rem 0 0;

      @include media($break695) {
        float: none;
        margin: .55rem auto 0;
      }
    }

    .step_item {
      float: left;
      width: 59px;
      margin-right: 1rem;
      text-align: center;

      &:last-child {
        margin-right: 0;
      }
    }

    .step_number {
      border: 1px solid $lightgray-border;
      display: block;
      width: 28px;
      height: 28px;
      margin: 0 auto .5rem;
      border-radius: 100px;
      padding-top: 1px;
    }

    .step_label {
      font-size: 1.25em;
      font-weight: 300;
    }

    .current_step_indication {

      .step_number {
        background-color: $accent_gold;
        color: white;
        font-weight: 600;
        border: 1px solid $accent_gold;
      }

      .step_label {
        font-weight: 600;
      }
    }

    .completed_step_indication {

      .step_number {
        background-color: $medium_gray;
        color: white;
        font-weight: 300;
        border: 1px solid $medium_gray;
      }

      .step_label {
        font-weight: 300;
        color: $medium_gray;
      }
    }
  }
}
