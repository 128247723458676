.shareLibrary_modal {
  scrollbar-face-color: #AFAFAF;
  scrollbar-track-color: none;

  @include media($break630) {
    background-color: #F8F8F8;
    padding: 0;
  }

  ::-webkit-scrollbar {
    width:  6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #D8D8D8;
    border-radius: 100px;
  }

  ::-webkit-scrollbar-track {
    background: none;
  }

  .modal-dialog {
    padding: 1.25rem 3rem 2rem;
  }

  .shareLibrary_title {
    text-align: center;
    padding-bottom: .5rem;
  }

  .close_modal_btn {
    padding: .5rem;
    top: .5rem;
    right: .5rem;
  }

  .remove_modal_icon {
    width: 15px;
  }

  .shareLink {
    white-space: nowrap;
    font-size: 1rem;
    width: 100%;
    border: none;
    background: none;
  }

  .shareLink_txt_container {
    overflow-x: auto;
    width: calc(100% - 98px);
    float: left;
    padding: 5px 10px;
    border-radius: 6px 0 0 6px;
    font-size: 1rem;
  }

  .share_descrip {
    padding-bottom: .75rem;
  }

  .copy_url_btn {
    border-radius: 0 6px 6px 0;
    border-left: none;
    padding: 8px 20px;
  }

  .share_note {
    padding-top: .75rem;
  }

  .success_text {
    color: #4EB468;
  }
}
