.aspireiq_theme {

  .success_descrip {
    background-color: $aiq_nude;
    color: $aiq_black;
    font-weight: 600 !important;
  }

  .success_alert {
    background-color: #F6FFED;
    border: 1px solid #B7EB8F;
    padding: 10px;
    border-radius: 6px;
  }

  .hint_msg {
    background-color: $aiq_nude;

    .btn_default {
      background-color: white;
    }
  }

  .status_msg_container {
    background-color: $aiq_nude;

    .btn_default {
      background-color: white;
    }
  }

  .tooltip_container {

    .tooltip {
      border: none;
      background-color: $aiq_nude;
    }

    .tooltip_black {
      background: rgba(0,0,0,.9);
      background-color: rgba(0,0,0,.9);
      box-shadow: 0 1px 3px rgba(0,0,0,.05);
      color: white;

      &:before, &:after {
        border-color: rgba(0,0,0,.9) transparent;
      }

      &:before {
        border-color: rgba(0,0,0,.9) transparent !important;
      }

      .tooltip_txt {
        color: white !important;
      }
    }

    .black_tooltip {
      background-color: $dark-text;
    }
  }

  .tooltip_topLeft {

    &:after {
      border-color: $aiq_nude transparent;
    }

    &:before {
      border-color: $aiq_nude transparent;
    }
  }

  .tooltip_topRight {

    &:after {
      border-color: $aiq_nude transparent;
    }

    &:before {
      border-color: $aiq_nude transparent;
    }
  }

  .tooltip_topCenter {

    &:after {
      border-color: $aiq_nude transparent;
    }

    &:before {
      border-color: $aiq_nude transparent;
    }
  }

  .tooltip_bottomLeft {

    &:after {
      border-color: $aiq_nude transparent;
    }

    &:before {
      border-color: $aiq_nude transparent;
    }
  }

  .tooltip_bottomCenter {

    &:after {
      border-color: $aiq_nude transparent;
    }

    &:before {
      border-color: $aiq_nude transparent;
    }
  }

  .tooltip_bottomRight {

    &:after {
      border-color: $aiq_nude transparent;
    }

    &:before {
      border-color: $aiq_nude transparent;
    }
  }
}
