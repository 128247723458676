.campaign_article {
	float: left;

	.campaign_title {
		font-size: 3.15em;
		line-height: 1.15em;
		padding-bottom: .35em;


		@include media($break585) {
			font-size: 2.5em;
			line-height: 1.4em;
		}

		@include media($break525) {
			float: left;
			width: calc(100% - 116px);
		}
	}

	.campaign_blurb, .networks_available_container, .desired_content {
		padding-bottom: 1rem;
	}

	.small_campaign_image_container {
		display: none;

		@include media($break525) {
			display: block;
			margin-bottom: 1rem;
			float: left;
			width: 100px;
			margin-right: 1rem;
		}

		.campaign_blurb {
			display: inline-block;
		}

		.campaign_image {
			max-width: 100%;
		}
	}


	.new_campaign_title_wrap {
		padding-bottom: .7em;

		.campaign_title {
			font-size: 3.15em;
			font-weight: 300;
			line-height: 1.15em;

		}

		.apply_btn {
			display: none;
		}

		@include media($break585) {
			.campaign_title {
				font-size: 2.5em;
				line-height: 1.4em;
			}
		}

		@include media($break525) {
			float: left;
			width: calc(100% - 116px);

			.campaign_title {
				float: none;
				font-size: 1.5rem;
				width: auto;
			}

			.apply_btn {
				display: inline-block;
				font-size: 1rem;
			}
		}
	}

	.contentGuidelines {

		.desired_content_title {}

		> p {}

		.exampleContent_container {}

		.exampleContent_list {
			padding: 12px 0 16px;

			> li {
				margin-right: 2%;
				float: left;
				width: 31.33%;

				&:last-child {
					margin-right: 0;
				}
			}
		}

		.content_link {
			display: block;
		}

		.ig_content {}

		.yt_content {}

		.contentImg {
			display: block;
			max-width: 100%;
			border-radius: 4px;

			&:hover {
				opacity: .95;
			}
		}

		.seeExamples_btn {}

		.disclosure_icon {
			margin-left: 8px;
		}
	}

	.networks_available_label {
		font-size: 1.4em;
		color: $dark-text;
		line-height: 1.85em;
		display: inline;
		margin-right: 1em;
		font-weight: 600;
	}

	.networks_available_list {
		display: inline;

		@include media($break405) {
			display: block;
			margin-top: .5rem;
		}

		li {
			display: inline-block;
			margin-right: 1rem;

			&:last-child {
				margin-right: 0;
			}

			.network_icon {
				width: 15px;
				margin-right: 2px;
			}

			.instagram_icon {}

			.youtube_icon {
				vertical-align: 1px;
			}

			.vine_icon {
				width: 14px;
				vertical-align: -1px;
			}

			.facebook_icon {}

			.twitter_icon {
				width: 16px;
			}

			.blog_icon {
				width: 16px;
			}

			.pinterest_icon {
				width: 13px;
			}

			.googleplus_icon {
				width: 21px;
			}

			.networkName_txt {
				vertical-align: 2px;
			}
		}
	}

	.network_name {
		display: inline-block;
		font-size: 1.4em;
		color: $dark-text;
		line-height: 1.85em;
		font-weight: 300;
	}

	.preapproved_notice {
		background-color: #f5f8ff;
		border: 1px solid #507df5;
	}

	.pending_approval_notice {
		background-color: white;
		border: 1px solid #ececec;
	}

	.upfront_offer_notice {
		border-radius: 4px;
		padding: 1em 1.5em;
		margin-bottom: 3em;
	}

	.upfront_offer_title {
		font-size: 1.4em;
		color: $dark-text;
		line-height: 1.85em;
		font-weight: 600;
	}

	.upfront_offer_descrip {
		font-size: 1.4em;
		color: $dark-text;
		line-height: 1.85em;
		font-weight: 300;
	}

	.desired_content_title {
		font-size: 1.4em;
		color: $dark-text;
		line-height: 1.85em;
		font-weight: 600;
	}

	.sectionLabel_txt {
		font-size: 1.4em;
		color: $dark-text;
		line-height: 1.85em;
		font-weight: 600;
	}

	.desired_content_descrip {
		font-size: 1.4em;
		color: $dark-text;
		line-height: 1.85em;
		font-weight: 200;
		white-space: pre-line;
	}

	.compensation_section {

		.compensation_title {
			font-size: 1.4em;
			color: $dark-text;
			line-height: 1.85em;
			font-weight: 600;
			margin-bottom: .8em;
		}

		.compensation_amount {
			font-size: 3em;
			color: $dark-text;
			font-weight: 200;
			margin-bottom: .35em;
			display: block;
		}

		.compensation_options_list {

			li {
				display: inline-block;
				width: 250px;
				margin: 0 2em 2em 0;

				@include media($break972) {
					display: block;
					margin: 0 0 2em 0;
					width: 100%;

					&:last-child {
						margin: 0;
					}
				}
			}

			&.payment_container {
				width: 500px;
			}
		}

			.checkmark_icon {
				width: 20px;
				height: 20px;
				display: inline-block;
				text-indent: -9999px;
				margin-right: 1em;
				vertical-align: 5px;
			}

			.available {
				background: url('/images/style_guide/icon_checkmark_circle_color.svg') center no-repeat;
			}

			.unavailable {
				background: url('/images/brand_profile/checkmark_icon_gray.svg') center no-repeat;
			}

		.compensation_text_container {
			display: inline-block;
			width: calc(100% - 33px);
			vertical-align: top;

			.network_icon {
				width: 15px;
			}

			.option_title {
				font-size: 1.4em;
				font-weight: 300;
				color: $dark-text;
				display: block;
				margin-bottom: .5em;
				margin-top: 0.2em;
			}

			.option_descrip {
				font-size: 1.2em;
				font-weight: 300;
				color: $dark-text;
				line-height: 1.55em;
				white-space: pre-line;
			}

			.recommended_payment_txt {
				font-weight: 300;
				font-size: .9rem;
			}
		}

		.payment_description {
			font-size: 1.4em;
			padding-top: .15rem;
			font-weight: 300;
			color: $dark-text;
		}

		.recommendedPayment_list {
			margin-top: .25rem;

			.recommendedPayment_item {
				margin-bottom: .75rem;
			}

			.payment_network_icon {
				display: inline-block;
				width: 17px;
				margin-right: .25rem;
				vertical-align: -3px;
			}

			.instagram_icon {}

			.youtube_icon {}

			.paymentAmount_container {
				display: inline-block;
				width: calc(100% - 29px);
			}

			.username_txt {}

			.paymentAmount_txt {}
		}
	}

	.account_to_post {
		margin-bottom: 2em;
	}

	.account_to_post_label {
		font-size: 1.4em;
		color: $dark-text;
		line-height: 1.85em;
		display: inline-block;
		font-weight: 300;
		margin-right: .5em;
	}

	.campaign_actions {
		margin-top: 1.0em;

		.accept_offer_button {
			background-color: $revfluence-blue;
			padding: 1.3em 3em;
			display: inline-block;
			text-align: center;
			text-decoration: none;
			border-radius: 50px;
			margin-right: 1em;
			border: 1px solid #1c75b5;
			&:hover {
				background-color: $revfluence-blue-hover;
			}
		}

		.accept_offer_icon {
			background: url('/images/brand_profile/accept_offer_icon.svg') center no-repeat;
			width: 18px;
			height: 16px;
			display: inline-block;
			margin-right: 1em;
			vertical-align: -2px;
		}

		.accept_offer_text {
			display: inline-block;
			color: white;
			font-size: 1.6em;
			font-weight: 400;
			letter-spacing: .01em;
		}


		.negotiate_container {
			display: inline-block;
			@include media($break440) {
				display: block;
				margin-top: 1em;
			}
		}

		.negotiate_text {
			font-size: 1.4em;
			color: $dark-text;
			line-height: 1.85em;
			font-weight: 300;

			a {
				color: $revfluence-blue;
				text-decoration: none;
				&:hover {
					color: $revfluence-blue-hover;
				}
			}
		}

		.apply_btn {
			display: inline-block;
			margin: 0 1em 2em 0;

			@include media($break445) {
				display: block;
			}
		}

		.apply_icon {
			background: url('/images/brand_profile/apply_icon.svg') center no-repeat;
			width: 20px;
			height: 20px;
			display: inline-block;
			margin-right: .3rem;
			vertical-align: -5px;
		}

		.reject_btn {
			display: inline-block;
			margin: 0 1em 2em 0;

			@include media($break445) {
				display: block;
			}
		}

		.apply_text {
			display: inline-block;
		}

		.like_button {
			padding: 1.3em 5em;
			display: inline-block;
			text-align: center;
			text-decoration: none;
			border-radius: 50px;
			margin-right: 2em;
			border: 1px solid #dcdcdc;
			&:hover {
				border: 1px solid $revfluence-blue;
			}
			@include media($break445) {
				display: block;
				margin-right: 0;
			}
		}

		.like_icon {
			background: url('/images/brand_profile/like_icon.svg') center no-repeat;
			width: 20px;
			height: 20px;
			display: inline-block;
			margin-right: 1em;
			vertical-align: -5px;
		}

		.like_text {
			display: inline-block;
			color: $revfluence_blue;
			font-size: 1.6em;
			font-weight: 400;
			letter-spacing: .01em;
		}
	}
}

.campaign_headers_container {}

.campaigns_container {

	.campaign_article {
		width: 61%;

		@include media($break525) {
			width: 100%;
			float: none;
		}
	}

	.campaign_container {
		background-color: white;
		box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.01);
		border: 1px solid rgb(242, 241, 241);
		margin: 0 auto 2em;
		max-width: 1000px;
		border-radius: 7px;

		@include media($break1015) {
			width: 100%;
			border: none;
			box-shadow: none;
			margin: 0;
			border-radius: 0;
		}
	}

	.bestPerforming_label {
		font-weight: 600;
		display: block;
		margin-bottom: .8rem;
	}

	.otherContent_label {
		font-weight: 600;
		display: block;
	}

	.otherContent_descrip {
		padding-bottom: 1rem;
	}

	.exampleContent_view {
		padding: .5rem 2rem 3rem;

		@include media($break400) {
			padding: .5rem 1rem 3rem;
		}

		.exampleContent_header {
			padding: 1rem;
			text-align: center;
		}

		.main_title {
			font-size: 2.75em;
		}

		.cta_container {
			margin-bottom: 1rem;
		}

		.avatar_img {
			width: 40px;
			border-radius: 100px;
			display: block;
			margin: 0 auto .75rem;

			&:hover {
				opacity: .95;
			}
		}

		.cta_descrip {
			text-align: center;
			margin-bottom: 1rem;
		}

		.proposeTerms_btn {
			width: 200px;
			margin: 0 auto;
			display: block;
		}

		.bestPerforming_content {
			padding-bottom: 1rem;
			border-bottom: 1px solid $lightgray-border;
			margin-bottom: 1.4rem;
		}

		.bestPerforming_list {

			> li {
				float: left;
				width: 33%;
				margin-right: .35%;

				@include media($break425) {
					width: 100%;
					margin-right: 0;
					float: none;
					padding-bottom: 1rem;
					margin-bottom: .5rem;
				}

				&:last-child {
					margin-right: 0;
				}
			}

			.contentImg {
				padding-bottom: .5rem;
			}
		}

	.contentImg {
		display: block;
		max-width: 100%;
		width: 100%;
		padding-bottom: .75rem;

		&:hover {
			opacity: .95;
		}
	}

	.qualityScore_container {

		@include media($break725) {
			padding: 0 .5rem;
		}
	}

	.qualityScore_label {
		display: block;
	}

	.qualityScore_number {
		display: block;
	}

	.otherContent_container {}

	.intro_container {

		> p {}
	}

	.content_link {
		display: block;
	}

	.standard_label {}

	.content_list {

		> li {
			width: 19.75%;
			float: left;
			margin: 0 .25% .25% 0;
			overflow: hidden;

			@include media($break750) {
				width: 24.75%;
			}

			@include media($break350) {
				width: 33%;
			}
		}
	}

	.contentImg {
		display: block;
		max-width: 100%;
		width: 100%;
		padding-bottom: 0;
	}
}

	.campaign_controls_container {
		padding: 0 1.85em;
		border-bottom: 1px solid $lightgray-border;
		border-radius: 7px 7px 0 0;

		@include media($break1000) {
			background-color: $ultra_light_blue;
			border-radius: 0;
		}

		@include media($break550) {
			padding: 0 .75rem;
		}

		.campaign_status_container, .status_label, .campaign_status, .status_icon, .status_txt {
			float: left;
			display: block;
		}

		.campaign_status_container {
			padding: 10px 15px 10px 0;

			@include media($break550) {
				float: none;
				padding: 0;
			}
		}

		.status_label {
			margin: 1px .5rem 0 0;
			color: $dark-text;

			@include media($break404) {
				margin: 1px .5rem .2rem 0;
			}
		}

		.campaign_status {
			color: $dark-text;
		}

		.status_icon {
    	width: 14px;
			display: inline-block;
			margin: 4px .4rem 0 0;
		}

		.pending_icon {}

		.live_icon {}

		.status_txt {}

		.negative_status_txt {
			color: red;
			margin-top: 2px;
			font-weight: 600;
		}

		.edit_campaign_container {
			float: left;

			@include media($break550) {
				float: none;
			}
		}

		.campaignControl_link {
			display: block;
			border-left: 1px solid $lightgray-border;
			padding: 10px;
			text-decoration: none;
	    cursor: pointer;

			@include media($break550) {
				float: none;
				padding: 0;
				border: none;
			}

			.campaignControl_icon {
				width: 22px;
				margin-right: .4rem;
				float: left;
			}

			.deactivate_icon {
				padding-top: 2px;
			}

			.campaignControl_txt {
				color: $dark-text;
				float: left;
				font-weight: 400;
			}
		}

		.campaignControl_container {
			float: left;

			@include media($break550) {
				padding: 1rem 0 0 !important;
				float: none;
			}
		}

		.reactivateLink {

			.campaignControl_txt {
				color: $revfluence_blue;
			}
		}
	}

	.status_msg_container {
		background-color: $aspireiq_desert_sand_tone1;
		border-radius: 7px;
		padding: 1rem;
		margin: 1rem 2rem 0;

		@include media($break1000) {
			margin: 1rem 1rem 0;
		}

		@include media($break785) {
			padding: 1.5rem 2rem;
		}

		.robot_msg_container {
			padding-bottom: .5rem;
			text-align: center;
			color: $dark-text;
		}

		.robot_msg_title {
			font-weight: 700;
			font-size: 1.75em;
			padding-bottom: .25rem;
		}

		.robot_msg_descrip {}

		.team_msg_container {
			border: 1px solid $lightgray-border;
			padding: 1.25rem 1rem;
			border-radius: 7px;
			width: 550px;
			margin: 0 auto 1.5rem;
			background-color: #f7fffb;

			@include media($break785) {
				width: 96%;
			}
		}

		.avatar_container {
			float: left;
			margin-right: 1.6rem;
			position: relative;

			@include media($break350) {
				float: none;
				margin: 0 auto 1rem;
				width: 40px;
			}
		}

		.revfluence_logomark {
			position: absolute;
			bottom: -4px;
			padding: 5px;
			width: 22px;
			z-index: 2;
			background-color: white;
			border-radius: 100px;
			height: 22px;
			left: -5px;
		}

		.avatar {
			display: block;
			width: 40px;
			border-radius: 100px;
		}

		.msg_txt_container {
			float: left;
			width: calc(100% - 66px);
			color: $dark-text;

			@include media($break350) {
				float: none;
				width: 100%;
			}
		}

		.username {
			font-weight: 600;
		}

		.actions_container {
			text-align: center;
		}

		.btn {

			@include media($break560) {
				display: block;
				margin: 0 auto;
			}
		}

		.btn_default {
			margin-left: .25rem;

			@include media($break560) {
				margin: 1rem auto 0;
			}
		}
	}

	.campaign_wrapper {
		padding: 1.5rem 2rem;

		@include media($break1000) {
			padding: 1rem;
		}
	}

	.campaign_aside {
		float: left;
		width: 35%;
		margin-right: 4%;

		@include media($break525) {
			display: none;
		}

		.campaign_image_container {
			margin-bottom: 2em;
		}

		.campaign_image {
			max-width: 100%;
		}
	}

	.brand_invited_creator {


		.like_icon {
			display: inline-block;
			width: 22px;
			height: 22px;
			margin-right: .25rem;
			background: url('/images/brand_profile/brand_likes_you.svg') center no-repeat;
			vertical-align: 4px;
		}

		.brand_invited_creator_title {
			display: inline;
			font-size: 1.4em;
			color: $dark-text;
			font-weight: 700;
			line-height: 1.4em;
			vertical-align: 11px;
		}

		.brand_invited_creator_descrip {
			margin-top: .5em;
			font-size: 1.4em;
			color: $dark-text;
			font-weight: 300;
			line-height: 1.4em;
		}
	}

	&.approved_invite {
		.brand_invited_creator {
			display: block;

			&.small_brand_invited {
				display: none;
				margin-bottom: 3em;
				padding: .5rem 0 1rem;

				@include media($break585) {
					display: block;
				}
			}
		}
	}
}

.compensation_options_list {

	li {
		display: inline-block;
		width: 250px;
		margin: 0 2em 2em 0;

		&.payment_container {
			width: 500px;
		}

		@include media($break972) {
			display: block;
			margin: 0 0 2em 0;
			width: 100%;
		}
	}

	.checkmark_icon {
		width: 20px;
		height: 20px;
		display: inline-block;
		text-indent: -9999px;
		margin-right: 1em;
		vertical-align: 5px;
	}

	.available {
		background: url('/images/brand_profile/checkmark_icon_color.svg') center no-repeat;
	}

	.unavailable {
		background: url('/images/brand_profile/checkmark_icon_gray.svg') center no-repeat;
	}

	.compensation_text_container {
		display: inline-block;
		width: calc(100% - 33px);
		vertical-align: top;
	}

	.option_title {
		font-size: 1.4em;
		font-weight: 300;
		color: $dark-text;
		display: block;
		margin-bottom: .25rem;
	}

	.option_descrip {
		font-size: 1.2em;
		font-weight: 300;
		color: $dark-text;
		line-height: 1.55em;
	}
}
