.edit_permissions_modal {

  .modal-dialog {
    padding: 4rem;
    overflow: visible;
    max-width: 900px;

    @include media($break600) {
      padding: 3rem 1rem;
    }

    @include media($break535) {
      width: 95%;
    }
  }

  .close_modal_btn {
    padding: 10px;
    position: absolute;
    right: .5rem;
    top: .5rem;
    cursor: pointer;

    &:hover {
      background-color: $hoverBtn_bg
    }
  }

  .error {
    
    a {
      color: white;
    }
  }

  .remove_modal_icon {
    width: 15px;
  }

  .permissions_title, .permissions_descrip {
    text-align: center;
  }

  .standard_label {
    font-weight: 700;
  }

  .permissions_title {
    padding-bottom: .75rem;

    @include media($break600) {
      font-size: 1.8rem;
    }

    @include media($break375) {
      font-size: 2rem;
    }
  }

  .permissions_descrip {
    font-size: 1.15rem;
    padding-bottom: 2rem;
  }

  .permissions_main_content {
    max-width: 440px;
    margin: 0 auto;

    @include media($break425) {
      max-width: 100%;
    }
  }

  .permissionsType_section {

    .permissionsType_dropdown_list {
      display: block;
      top: 64px;
      left: 0;
      width: 100%;

      &:before, &:after {
        display: none;
      }
    }

    .permissionsType_dropdown {
      width: 100%;
      display: block;
      max-width: 100%;
    }
  }

  .reuse_checkbox_control {
    padding-bottom: .25rem;
    display: inline-block;
  }

  .addNotes_section {
    padding-bottom: .85rem;

    .addNotes_textarea {
      margin-bottom: .25rem;
    }

    .addNotes_details {
      font-size: .85rem;
    }
  }

  .savePermissions_btn {
    display: block;
    margin: 1rem auto 0;
    width: 125px;

    @include media($break425) {
      max-width: 100%;
      width: 100%;
    }
  }
}
