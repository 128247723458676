.creatorRejected_modal {

  .modal-dialog {
    padding: 1.5rem 2rem 2rem;
  }

  .close_modal_btn {
    padding: .35rem;
  }

  .icon_close {}

  .startSub_main_title {
    padding-bottom: 1rem;
  }

  .main_descrip {
    padding-bottom: 1rem;
  }

  .startSub_main_title, .main_descrip {
    text-align: center;
  }

  .standard_label {
    display: block;
    font-weight: 600;
    padding-bottom: .25rem;
  }

  .reason_input {
    margin-bottom: 1rem;
  }

  .submit_btn {
    margin-right: .5rem;
  }

  .skip_btn {}
}
