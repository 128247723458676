.hint_msg {
	background-color: $aspireiq_desert_sand_tone1;
	border-radius: 6px;
	padding: .6rem .9rem .9rem;

	strong {
		font-weight: 700;
	}

	p {
		margin-bottom: .5rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	ol {
		list-style: decimal;
		list-style-position: inside;
	}
}

.hint_indicator {
	background: none;
	padding: 0;
	border: none;
	position: relative;
	display: inline;

	.hint_icon {
    cursor: pointer;
  }
}

.hint_btn {
		background: none;
		padding: 0;
		border: none;
}

.hint_title {
	display: block;
	font-weight: 600;
}

.hint_descrip {
	padding-bottom: 0;
}
