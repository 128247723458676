.send-payments {

	.modal-dialog {
		padding: 4em 0;
	}

	.modal-header {
		background: white;
		border-bottom: none;
	}

	.close-modal-link {
		position: absolute;
		top: 1em;
		right: 1em;
		background: url('../images/creator-payments/close_icon.svg') center no-repeat;
		width: 20px;
		height: 20px;
		text-indent: -9999px;

		&:hover {}
	}

	.visual-container {
		width: 203px;
		margin: 0 auto 2em;

		.payment-icon {
			display: inline-block;
			margin-right: 1em;
			width: 75px;
		}

		.arrow-icon {
			display: inline-block;
			margin-right: 1em;
			vertical-align: 3em;
			width: 20px;
		}

		.creator-avatar {
			display: inline-block;
			border-radius: 45px;
			width: 75px;
		}
	}

	.modal-title {
		@include title20;
		margin-bottom: 1rem;
		font-weight: 200;
		color: $dark-text;
		text-align: center;
	}

	.current-balance {
		@include fontsize15;
		font-weight: 200;
		display: block;
		text-align: center;
		margin-bottom: 3em;
		color: $dark-text;
	}

	.payment-amount-container {
		width: 327px;
		margin: 0 auto 1em;
		@include media($break385) {
			width: 90%;
		}

		.payment-amount-input {
			display: inline-block;
			width: 200px;
			@include standard-input;
			margin-right: .5em;
			@include media($break385) {
				width: 100%;
				margin: 0 0 1em;
				display: block;
			}
		}

		.continue-button {
			display: inline-block;
			border: 1px solid $revfluence-blue;
			&:hover {
				border: 1px solid $revfluence-blue-hover;
			}
			@include media($break385) {
				width: 100%;
				display: block;
			}
		}
	}

	.revfluence-gaurantee {
		display: block;
		text-align: center;
		@include fontsize15;
		font-weight: 200;
		color: $dark-text;
	}

	.error-container {
		@include errors;
	}
}
