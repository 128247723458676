.setPassword_popup {
  width: 101%;

  .modal-dialog {
    padding: 2rem;

    @include media($break600) {
      border-radius: 0;
      margin: auto !important;
    }
  }

  .main_title, .main_descrip {
    text-align: center;
  }

  .main_title {
    margin-bottom: .25rem;
  }

  .main_descrip {
    margin-bottom: 1rem;
    font-size: 1.15rem !important;
  }

  .set_password_form {}

  fieldset {
    margin-bottom: 1rem;
  }

  .setPassword_btn {
    display: block;
    width: 150px;
    margin: 0 auto;
  }
}
