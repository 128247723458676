.contact_container {
	position: fixed;
	bottom: 2.5em;
	right: 3em;
	z-index: 7500;
	@include media($break475) {
		bottom: .85em;
		right: 1em;
	}

	.contact_button {
		background-color: $dark-text;
		padding: 1em 1.45em 1em 1.45em;
		-webkit-appearance: none;
		border: 2px solid white;
		cursor: pointer;
		display: block;
		transition: all .15s ease-in-out;
		z-index: 7500;

		&:hover {
			transform: translate(0,-5px);
		}

		&:focus {
			outline: none;
			transform: translate(0,-5px);
		}
		@include media($break475) {
			border-radius: 100px;
			padding: 0;
			width: 60px;
			height: 60px;
		}
	}

	.contact_icon {
		display: inline-block;
		width: 23px;
		height: 22px;
		margin-right: .5em;
		vertical-align: -5px;
		background: url('/images/support/contact_icon.svg') center no-repeat;
		@include media($break475) {
			margin-right: 0;
			vertical-align: initial;
		}
	}

	.contact_text {
		color: white;
		font-size: 1.5em;
		line-height: 1.5em;
		font-weight: 500;
		@include media($break475) {
			display: none;
		}
	}
}