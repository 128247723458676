.social_account_browse {
	background-color: #f9f9f9;

	.onboarding_header {
		display: block;
		background-color: white;
		box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.01);
		border: 1px solid rgb(242, 241, 241);
		margin-top: 4.94em;
		text-align: center;

        .fade-enter {
            opacity: 0.01;
            transition: opacity .5s ease-in;
        }

        .fade-enter.fade-enter-active {
            opacity: 1;
        }

		.inner_container {
			max-width: 1200px;
			margin: 3em auto 4em;
			@include media($break754) {
				margin: 3em auto 1em;
			}
			@include media($break415) {
				margin: 2em auto 1em;
			}
		}

		.main_title_container {
			margin-bottom: 3em;
			position: relative;
		}

		.back_button {
			width: 10px;
			height: 31px;
			background: url('/images/social_account_browse/back_arrow.svg');
			display: block;
			position: absolute;
			left: 0;
			top: 9px;
			text-indent: -9999px;
			@include media($break1220) {
				left: 20px;
			}
		}

		.main_title {
			font-size: 3.35em;
			color: $dark-text;
			font-weight: 300;
			display: inline-block;
			margin-right: .5em;
			vertical-align: -0.15em;
			@include media($break590) {
				font-size: 2em;
				font-weight: 500;
			}
			@include media($break425) {
				text-align: center;
				display: block;
				margin-bottom: .5em;
			}
		}

		.next_button {
			padding: 1.15em 1.35em;
			cursor: pointer;
			border-radius: 4px;
			display: inline-block;
			text-decoration: none;
			background-color: #2582f4;

            &.disabled {
                opacity: 0.5;
                cursor: default;
            }

			@include media($break415) {

			}

			&:hover {
				background: #3b90f9;
			}
		}

		.next_button_text {
			color: white;
			font-size: 1.3em;
			line-height: 1.5em;
			margin-right: .45em;
			display: inline-block;
		}

		.next_button_icon {
			width: 15px;
			height: 15px;
			display: inline-block;
			background: url('/images/social_account_browse/white_right_arrow.svg') center no-repeat;
			vertical-align: -2px;
		}

		.categories_container {}

		.categories_list {
			text-align: center;
		}

		.category_item {
			display: inline-block;
			text-align: center;
			margin-right: 6em;

			&:last-child {
				margin-right: 0;
			}

			@include media($break754) {
				margin: 0 4em 2em 0;
			}

			@include media($break370) {
				margin: 0 2.25em 2em 0;
			}

			a {
				text-decoration: none;
			}
		}

		.category_item_active {

			.category_icon {
				opacity: 1;
			}
			.category_text {
				font-weight: 600;
				color: $dark-text;
			}
		}

		.category_icon {
			display: block;
			width: 25px;
			height: 25px;
			margin: 0 auto 1.5em;
			opacity: .3;
			@include media($break754) {
				display: none;
			}
		}

		.womensfashion_icon {
			background: url('/images/social_account_browse/womens_fashion_icon.svg') center no-repeat;
		}

		.fitness_icon {
			background: url('/images/social_account_browse/fitness_icon.svg') center no-repeat;
		}

		.beauty_icon {
			background: url('/images/social_account_browse/beauty_icon.svg') center no-repeat;
		}

		.food_icon {
			background: url('/images/social_account_browse/food_icon.svg') center no-repeat;
		}

		.lifestyle_icon {
			background: url('/images/social_account_browse/lifestyle_icon.svg') center no-repeat;
		}

		.gaming_icon {
			background: url('/images/social_account_browse/gaming_icon.svg') center no-repeat;
		}

		.kidsfamily_icon {
			background: url('/images/social_account_browse/moms_icon.svg') center no-repeat;
		}

		.mensfashion_icon {
			background: url('/images/social_account_browse/mens_fashion.svg') center no-repeat;
		}

		.category_text {
			display: block;
			font-size: 1.4em;
			color: #bdbcbc;
			font-weight: 300;
		}

		.budget_input_container {
			display: none;
		}

		.slider_container {
            padding: 0em 2.5em;
			margin-bottom: 2em;
			@include ui-slider;
		}

	}
}