.confirm_deleteAccount_modal {

  .close_modal_btn {
    padding: .25rem;
    border: none;
  }

  .modal-dialog {
    padding: 1rem 2rem 2rem;

    @include media($break375) {
      padding: 1rem;
    }
  }

  .main_title {
    padding-bottom: 2rem;
  }

  .mainDescrip {
    padding-bottom: 1rem;
  }

  .strong {
    font-weight: 600;
  }

  .actionsList {
    padding: .6rem 0 2rem;
    width: 305px;
    margin: 0 auto;

    @include media($break375) {
      width: auto;
    }
  }

  .actionItem {
    display: inline-block;
    margin-right: .5rem;

    @include media($break375) {
      display: block;
      margin-right: 0;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .btn_confirm {}

  .btn_cancel {}

  .manageNotifications_hint {
    padding: 1rem;
    border-radius: 3px;
    background: #F3F3F3;
  }

  .icon_alert {
    margin-right: .5rem;
    vertical-align: top;
  }

  .txtWrap {
    display: inline-block;
    width: calc(100% - 50px);

    .default_body_copy {
      font-size: .8rem;
    }
  }
}
