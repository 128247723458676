.campaign_marketplace {
	height: 100%;

	.marketplaceWithFilters {
		.marketplaceContainer {
			@include media($break1000) {
				margin-top: 3rem;
			}
			@include media($break765) {
				margin-top: 0;
			}
		}
	}

	.noBrands {
		max-width: 680px;
		margin: 25em auto 0;
		text-align: center;

		@include media($break650) {
			margin: 30em auto;
			width: 97%;
			padding: 3em 2em;
		}

		.noBrands_title {
			@include title20();
			font-weight: 700;
			color: $dark-text;
			margin-bottom: 1em;

			@include media($break650) {
				font-size: 1.4em;
			}
		}

		.noBrands_suggestion {
			@include fontsize16();
			color: $dark-text;
			margin-bottom: 1.75em;
			text-align: left;

			.minimumRequirements {
				margin-top: 1em;

				.network_icon {
					width: 18px;
					margin-right: 4px;
				}

				> * {
					display: table;

					> * {
						display: table-cell;
						vertical-align: middle;

						&:last-child {
							width: 100%;
						}
					}
				}
			}
		}

		.existingCollabs {
			color: $dark-text;
			padding-bottom: 80px;

			.existingCollabs_title {
				@include title20();
				font-weight: 700;
				margin-bottom: 1em;
			}

			.existingCollabs_subtitle {
				@include fontsize16();
				margin-bottom: 1em;
			}

			ul {
				li {
					margin-bottom: 16px;
				}
			}

			.existingCollab_link {
				width: 100%;
				text-align: left;
				border-radius: 6px;
				border: 1px solid $lightgray-border;
				padding: 12px;
				display: table;
				cursor: pointer;
				text-decoration: none;
				color: unset;

				&:hover {
					background-color: $lightgray-bg-hover;
				}

				.existingCollab_logo {
					width: 40px;
					border-radius: 6px;
					display: table-cell;
					vertical-align: middle;
				}

				.existingCollab_name {
					@include fontsize14();
					font-weight: 700;
					line-height: unset;
					display: table-cell;
					vertical-align: middle;
					padding-left: 16px;
					width: 100%;
				}

				.existingCollab_indicator {
					display: table-cell;
					vertical-align: middle;
					padding-right: 12px;

					img {
						width: 14px;
					}
				}
			}
		}

		.actionsList {}

		.add_accounts_button {
			display: inline-block;
			padding: .75em 2.65em .75em 1.25em;
			background: #2582f4 url('/images/style_guide/icon_arrowright_circle_white.svg') 254px center no-repeat;
			line-height: 1.55em;
			background-size: 16px;

			&:hover {
				background: #4294f9 url('/images/style_guide/icon_arrowright_circle_white.svg') 254px center no-repeat;
				background-size: 16px;
			}

			@include media($break345) {
				background: #2582f4;
				padding: .75em 1.25em .75em;
				font-size: .75rem;

				&:hover {
					background: #4294f9;
				}
			}
		}

	}

	.errorMessage {
		width: 610px;
		margin: 5em auto 0;
		text-align: center;
		border: 1px solid #f2f1f1;
		background-color: white;
		box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.01);
		padding: 3em 6em;
        display: none;
		@include media($break650) {
			margin: 0 auto;
			width: 97%;
			padding: 3em 2em;
		}

		.error_title {
			font-size: 1.75em;
			color: $dark-text;
			font-weight: 600;
			line-height: 1.5em;
			margin-bottom: 1em;
			@include media($break650) {
				font-size: 1.4em;
			}
		}

		.error_suggestion {
			font-size: 1.4em;
			color: $dark-text;
			font-weight: 300;
			line-height: 1.5em;
			margin-bottom: 1.75em;
		}
	}

	/* CONTENT BLOCKER */
	.contentBlocker_container {
		border: 1px solid $lightgray-border;
		border-radius: 6px;
		padding: 1rem 1.75rem;
		max-width: 550px;
		margin: 13rem auto 0;

		@include media($break1200) {
			margin: 12rem auto 0;
		}

		@include media($break1100) {
			margin: 11rem auto 0;
		}

		@include media($break580) {
			margin: 9rem auto 0;
		}

		@include media($break550) {
			width: 95%;
		}

		.contentBlocker_header {
			padding-bottom: .35rem;
		}

		.icon_alert, .contentBlocker_title {
			float: left;
		}

		.icon_alert {
			margin-right: .35rem;
			width: 25px;
			margin-top: 7px;
		}

		.contentBlocker_title {
			display: inline-block;
			width: calc(100% - 32px);
			font-size: 1.5rem;
			font-weight: 500;
		}

		.contentNeeded_list {
			padding: .5rem 0 .5rem 1rem;
			list-style: disc;
			list-style-position: inherit;
		}

		.contentNeeded_item {}

		.incorrect_disclaimer {
			font-style: italic;
		}
	}

	.main_body {
		max-width: 1200px;
		margin: 0 auto;
		overflow: visible;
		padding-top: 10.5rem;

		@include media($break1225) {
			padding: 10.5rem 1rem 2rem;
		}

		@include media($break1000) {
			padding: 9.75rem 1rem 2rem;
		}

		@include media($break700) {
			padding: 9rem 1rem 2rem;
		}

		.noResults_container {
			padding: 1.5rem 0;

			.noResults_txt {}
		}

		/* PROPOSAL CD NOTICE */
		.proposalCD_notice {
			margin-bottom: 1rem;
		}

		.brandModuleWrap {

			.brand_collection_name {
				margin-bottom: 1rem;
				font-size: 1.5rem;

				@include media($break700) {
					text-transform: uppercase;
					padding-left: 0;
					letter-spacing: .25rem;
					font-size: .9rem;
					font-weight: 600;
				}
			}
		}

		.campaign_item {
			transition: all .25s ease-in-out;
			width: 23.33%;
			margin-right: 1.67%;
			margin-bottom: 2%;
			float: left;
			border: 1px solid $lightgray-border;
			border-radius: 6px;
			overflow: hidden;

			&.closed {
				cursor: default;

				.campaign_image_link {
					cursor: default;
				}
				.campaign_image_container_wrapper {
					background-color: black;
				}

				.campaign_image_container {
					opacity: 0.7;

				}

			}

			&:hover {
				transform: translate(0, -8px);
			}

			@include media($break900) {
				width: 31.66%;
			}
			@include media($break700) {
				width: 48.33%;
			}
			@include media($break465) {
				float: none;
				width: 100%;
				margin: 0 auto 2em;
			}

			.self_serve_experiment_ribbon {
				color: white;
				background-color: #ef5682;
				width: 200px;
				position: absolute;
				top: 35px;
				right: -51px;
				height: 28px;
				text-align: center;
				line-height: 24px;
				letter-spacing: -0.4px;
				font-size: 12px;
				font-weight: 800;
				transform: rotate(45deg);
				-webkit-transform: rotate(45deg);
				overflow: hidden;
				box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
			}
		}

		.campaign_image_container {
			position: relative;
		}

		.likesYou_indicator_container {
			background: white;
			border-radius: 2em;
			padding: .6em 1.25em .75em;
			position: absolute;
			top: 1.5em;
			left: 2em;
			box-shadow: 0px 0.5px 1.5px rgba(0, 0, 0, 0.1);
			z-index: 1;

			.likesYou_icon {
				background: url('/images/campaign_marketplace/like_icon_solid.svg') center no-repeat;
				width: 15px;
				height: 15px;
				display: inline-block;
				margin-right: .5em;
				vertical-align: -2px;
				background-size: 100%;
			}

			.likesYou_text {
				font-size: 1.25em;
				color: $dark-text;
				line-height: 1em;
				font-weight: 700;
				display: inline-block;
			}
		}

		.hideCampaign {
			top: 0.7rem;
			right: 0.5rem;
			position: absolute;
			z-index: 1;

			.closeImg {
				width: 40px;
				height: 40px;
			}

			&:hover {

			}
		}

		.campaign_image_link {
			cursor: pointer;
			display: block;
  			text-decoration: none;
			height: 100%;
			transition: all .1s ease-in-out;
		}

		.upfront_offer_container {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			padding: 2em;
			background: url('/images/campaign_marketplace/upfront_offer_shadow.png') center no-repeat;

			.upfront_offer_amount {
				font-size: 2.5em;
				font-style: italic;
				text-align: right;
				font-family: "adobe-caslon-pro";
				color: white;
				font-weight: 300;
				display: block;
				text-shadow: 1px 1px 0px rgba(0,0,0,.35);
			}
		}

		.campaign_image_container_wrapper {
			overflow: hidden;
		}

		.campaign_image_spacer {
			padding-bottom: 100%;
		}

		.campaign_image {
			width: 100%;
			height: 100%;
			overflow: hidden;
			display: flex;
			align-items: center;
		}

		.campaign_image_img {
			position: absolute;
		}

		.campaign_details_container {
			padding: 2em;
			margin-top: -4.6em;
			position: relative;
			z-index: $zindexHeader;
			height: 12.5rem;
		}

		.brand_avatar {
			width: 52px;
			height: 52px;
			margin-bottom: .85rem;
			border-radius: 10px;
			border: 2px solid white;
			box-shadow: 0px .5px 1.5px rgba(0,0,0,.1);
			background: white;
		}

		.brand_name_container {
			margin-bottom: 1em;
		}

		.brand_name {
	    color: $dark-text;
	    font-size: 1.25rem;
	    display: inline-block;
	    text-decoration: none;
	    font-weight: 200;
	    line-height: 2rem;
	    width: 100%;
			margin-bottom: .15rem;
	    white-space: nowrap;
	    overflow: hidden;
	    text-overflow: ellipsis;
		}

		.instagram_indicator {
			background: url('/images/campaign_marketplace/instagram_available_indicator.svg') center no-repeat;
		}

		.youtube_indicator {
			background: url('/images/campaign_marketplace/youtube_available_indicator.svg') center no-repeat;
		}

		.campaign_title {
			font-size: .87rem;
			color: $dark-text;
			line-height: 1.25rem;
			display: block;
			font-weight: 300;
			width: 100%;
			white-space: nowrap;
			margin-bottom: 1rem;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.networks_available_container {
			margin-bottom: .8rem;

			.networks_available_list {
				float: left;
				width: calc(100% - 16px);
				height: 18px;
				overflow: hidden;

				> li {
					float: left;
					margin-right: .7rem;
					padding-bottom: .25rem;

					&:last-child {
						margin-right: 0;
					}
				}
			}

			.network_icon {
				width: 15px;
			}

			.demo_mode {
				width: 28px;
				height: 28px;
			}

			.ellipses_icon {
				float: left;
				width: 16px;
				margin: 14px 0 0;
			}

			.network_type_indicator {
				width: 26px;
				height: 26px;
				float: left;
				margin-right: .5rem;
				display: block;
				background-size: 97%;

				&:last-child {
					margin-right: 0;
				}
			}

			.instagram_icon {}

			.youtube_icon {
				margin-top: 2px;
			}

			.twitter_icon {
				width: 17px;
			}

			.facebook_icon {}

			.blog_icon {}

			.vine_icon {}

			.snapchat_icon {}

			.pinterest_icon {
				width: 14px;
			}

			.googleplus_icon {
				width: 23px;
				margin-top: 1px;
			}
		}

		.review_icon {
			width: 17px;
		}

		.moreBrandsContainer {
			text-align: center;
			padding-bottom: 2em;
		}

		.moreBrandsSuggestion {
			padding-bottom: 1em;
		}
	}
}
