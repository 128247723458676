.aspireiq_theme {

  .more_details_container {

    .get_started_button {
      background: $aiq_darkblue;
      border: none;
      color: white;

      &:hover, &:focus {
        background: $aiq_darkblue_hover;
        border: none;
      }

      &:active {
        background: $aiq_darkblue;
        border: none;
      }
    }
  }

  .closing_cta_container {

    .get_started_button {
      background: $aiq_darkblue;
      border: none;
      color: white;

      &:hover, &:focus {
        background: $aiq_darkblue_hover;
        border: none;
      }

      &:active {
        background: $aiq_darkblue;
        border: none;
      }
    }

    .see_case_study {

      a {
        color: $aiq_darkblue;

        &:hover {
          color: $aiq_darkblue_hover;
        }
      }
    }
  }
}
