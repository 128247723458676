.collab_terms_tool,
.guidelines_container {
	margin-right: 0;
	width: 100%;
	height: 100%;

	.diff_added_copy,
	.diff_removed_copy {
		white-space: pre-wrap;
	}

	@include media($break630) {
		margin-right: 0;
	}


	// Add line height and consistent padding to list items
	.termsEditable_item,
	.postType_dueDate_item,
	.content_agreement_container li {
		padding: 4px 8px 4px 8px !important;
		line-height: 1.25rem !important;
		list-style-position: outside;
		margin-left: 1.5rem;
	}

	.tools_header {
		padding-bottom: .5rem;
	}

	.tool_main_container {

		@include media($break630) {
			background-color: white;
			padding: 0 0 1rem 1rem;
		}
	}

	.character_limit_color {
		color: #878787;
	}

	.terms_tool_section {
		position: relative;
		padding: 1rem 0 1rem;
		border-bottom: 1px solid $lightgray-border;
	}

	.new_price_container {
		display: inline-block;
	}

	.balance_information_container {
		display: inline-block;

		.balance_information {
			display: inline-block;
			margin-left: 10px;
		}

		.balances {
			vertical-align: middle;
		}
	}

	.special_terms_section {
		margin-left: 4px;

		.large_section_header {
			margin-bottom: .5rem;
		}

		.text_wrap {
			padding-bottom: .7rem;
		}

		li {
			padding-bottom: .7rem;
		}

		a {
			font-size: .85rem;
			font-weight: 600;
			color: #3996E0;
			text-decoration: none;
		}
	}

	.terms_container {
		width: 99%;
		margin-right: 0;
		position: relative;
		padding-bottom: 1.5rem;

		@include media($break875) {
			width: 100%;
		}
	}

	.guideline_set_header {
		padding-bottom: .25rem;
	}

	.justProposal_hint {
		font-weight: 500;
		margin-top: .5rem;

		@include media($break425) {
			margin-top: 1rem;
			margin-bottom: .25rem;
		}

		.icon_alert {
			margin-right: .5rem;
			display: inline-block;
			vertical-align: -3px;
		}
	}

	.usage_rights_container {
		padding-left: 3px;

		.selectWrap {
			padding-bottom: 1rem;

			.selectDescrip {
				display: inline-block;
				margin-right: .5rem;
			}

			.dropdown_container {
				display: inline-block;
			}

			.dropdown_btn2 {
				width: 184px;
				margin-bottom: 0;
				background-position: 92% center;
			}

			.dropdown_list {
				top: 36px;
				width: 184px;
				right: 0;

				&:before,
				&:after {
					display: none;
				}
			}
		}

		textarea {
			border-radius: 8px;
			padding-bottom: 2rem;
		}

		pre {
			white-space: pre-wrap;
		}
	}

	.content_agreement_container {

		ul {
			list-style-type: disc;
			padding-top: 1rem;
			list-style-position: inside;
		}
	}

	.igInsightsContainer {
		.insightsNotice {
			margin-top: 1rem;
		}

		.authInsightsBtn {
			margin-top: 1rem;
			width: 100%;
		}

		.insightsCheckbox {
			margin-top: 0.5rem;
			margin-left: 0.2rem;
		}

		.igInsightsContainerDisabled {
			opacity: 0.7;
			user-select: none;
			pointer-events: none;
		}

		.uce_influencer_whitelist_insights_notice {
			background: $bg_default;
			border: 1px solid #91d5ff;
			box-sizing: border-box;
			border-radius: 6px;
			padding: 9px 16px;
			font-weight: 600;
			font-size: 14px;
			line-height: 22px;
			color: #1f1f21;
			margin-top: 32px;
			word-wrap: break-word;
		}
	}

	.systemMessageNotice {
		.nextParagraph {
			padding-top: 1rem;
		}

		.title {
			font-weight: 700;
			font-size: 1.1rem;
			margin-bottom: 0.5rem;
		}
	}

	.disableCreatorsCheckbox {
		margin-top: 1.5rem;

		label {
			font-weight: bold;
			font-size: 14px;
			line-height: 18px;
			display: flex;
			align-items: center;
			letter-spacing: -0.32px;

			color: #1F1F21;

		}

		input[type=checkbox] {
			flex: none;
			margin-right: 10px;
		}

		p {
			margin-top: 0.5rem;
			font-size: 14px;
			line-height: 22px;
			color: #505256;
		}
	}

	.tool_icon_container {
		float: left;
		width: 50px;
		height: 50px;
		margin-right: 15px;

		@include media($break365) {
			width: 35px;
			height: 35px;
			margin-right: 13px;
		}
	}

	.section_title {
		margin-bottom: .5rem;
		font-weight: 600;
		font-size: 1rem;
	}

	.explanation_text {
		font-size: 1.4em;
		padding-top: .5rem;
		display: block;
	}

	.terms_accepted_container {
		margin-bottom: 26px;

		.complete_check {
			display: inline-block;
			width: 15px;
			height: 15px;
			position: relative;
			vertical-align: -1px;
			background-image: url("/images/new_manage/checkmark_icon_color.svg");
			background-repeat: no-repeat;
			margin-right: .25em;
		}
	}

	.accounts_section {
		border-bottom: 1px solid $lightgray-border;
		padding: 0 0 1rem;

		.addContent_btn {

			.edit_icon {
				margin-right: 5px;
				vertical-align: -5px;
			}
		}
	}

	.editContent_btn {}

	.addContent_btn {
		display: block;
		font-weight: 600;
		width: 99%;
		margin: 0 auto !important;
		font-weight: 500;

		.icon_addContent {
			margin-right: 4px;
			vertical-align: -5px;
		}
	}

	.edit_actions_container {
		padding: 1rem 3px;

		.error_text {
			line-height: 1rem;
			padding-top: 1em;
			clear: both;
		}

		.accept_terms_btn,
		.edit_terms_btn,
		.cancel_edit_btn,
		.update_button {
			float: left;
			width: 49%;
		}

		.accept_terms_btn {

			.acceptTerms_icon {
				vertical-align: -4px;
				margin-right: .35rem;
			}
		}

		.edit_terms_btn,
		.cancel_edit_btn {
			margin-left: 2%;
		}

		.cancel-edit_btn {}
	}

	.acceptedTerms_indication_container {
		margin-top: 1rem;
		padding-top: .75rem;

		.acceptedTerms_header {
			margin-bottom: .4rem;
		}

		.checkmark_icon {
			float: left;
			margin-right: .4rem;
			padding-top: .1rem;
		}

		.acceptedTerms_title {
			font-weight: 600;
			float: left;
			font-size: 1rem;
			width: calc(100% - 27px);
		}

		.acceptedTerms_descrip {
			margin-bottom: 1rem;
		}

		.acceptedTerms_actions_container {
			float: left;
			margin-top: 1rem;
		}

		.backtoRelationship_btn,
		.selectProduct_btn_txt {
			margin-bottom: .25rem;
		}

		.backtoRelationship_btn {
			margin-right: .25rem;
		}

		.back_icon {
			width: 22px;
			vertical-align: -5px;
		}
	}

	.agreement_status_text {
		color: #417505;
		font-weight: 600;
	}

	.accounts_list {}

	.terms_invalid_container {
		margin-bottom: 26px;
	}

	.invalid_text {
		color: red;
		font-weight: 600;
		display: block;
		padding-left: 27px;
		background: url('/images/new_manage/alert_icon_red.svg') left center no-repeat;
		background-size: 20px;
		padding-top: 1rem;
	}

	.waiting_to_accept_container {
		margin-bottom: .5rem;
		padding: .4rem;
		margin-top: .5rem;

		.icon_waiting {
			margin-right: .25rem;
			vertical-align: -6px;
		}

		.waiting_text {
			font-weight: 600;
		}

		.sendReminderBtn {
			margin: 0.5rem 0.25rem 0.25rem 0;
		}
	}

	.canceled_container {
		display: flex;
		gap: .5rem;
		margin-bottom: .5rem;
		padding: .4rem;
		margin-top: .5rem;
		border: 1px solid #FFA39E;
		background-color: #FFF1F0;
		text-align: left;
		color: #1F1F21;
	}
	.error_msg_container {
		margin-bottom: .5rem;
		padding: .4rem;
		margin-top: .5rem;

		background-color: $error;
		color: white;

		.unable_to_find_account_title {
			margin-bottom: 0.5rem;

			.title {
				font-weight: 600;
			}
		}
	}

	.accounts_list {}

	.post_type_item {
		position: relative;
		padding: 4px 0;
		border-bottom: 1px solid $lightgray-border;
		margin-bottom: 1rem;

		&:last-child {
			border-bottom: none;
			margin-bottom: 0;
		}

		/*&:last-child {
			border-bottom: none;
			margin-bottom: 0;
			padding: 4px 0 0;
		}*/

		.post_type_text {}

		.item_header {
			position: relative;

			.remove_btn {
				background: none;
				padding: 0;
				border: none;
				width: 19px;
				height: 19px;
				position: absolute;
				top: .74rem;
				right: .8rem;

				.remove_icon {
					width: 19px;
				}

				&:focus,
				&:active {
					box-shadow: none;
				}
			}
		}

		.postType_dueDate_container {
			padding-bottom: .75rem;

			.dueDate_label {
				font-weight: 600;
				display: block;
				padding: 0 0 .5rem 3px;
			}

			.editing_postType_dueDate_list {

				.postType_dueDate_item {
					padding-bottom: 1.5rem;
					border-bottom: 1px solid $lightgray-border;
					margin-bottom: 1.25rem;

					&:last-child {
						margin-bottom: 0;
						border-bottom: none;
						padding-bottom: .75rem;
					}
				}

				.dropdown_btn {
					width: 100%;
					background-position: 88% center;
					margin-bottom: 0;
				}

				.dropdown_list {
					left: 0;
					top: 37px;
					width: 125px;

					&:after,
					&:before {
						display: none;
					}
				}

				.date_description {
					padding-bottom: .5rem;
				}

				.postType_order_dropdown,
				.date_input,
				.andTxt {
					display: inline-block;
				}

				.postType_order_dropdown {
					max-width: 120px;
					width: 100%;
				}

				.andTxt {
					vertical-align: -1px;
				}

				.submit_date_set {
					padding-bottom: 1rem;
				}

				.complete_set_date {}

				.date_input {
					max-width: 110px;
					width: 100%;
					vertical-align: -3px;
					margin-left: .25rem;
					padding: .5rem 1rem;
				}

				.first_date_input {
					margin-right: .25rem;
				}
			}
		}

		.wrapper_entered_postType_dueDate_list {
			list-style-type: none;
			list-style-position: inside;

			.wrapper_postType_dueDate_item {
				padding: .25rem 0;

				&:first-child {
					padding: 0 0 .25rem 0;
				}

				.entered_postType_dueDate_list {
					list-style-type: disc;
					list-style-position: inside;
					padding-left: 0;

					.entered_postType_txt {
						font-size: .9rem;
						line-height: 1.7em;
						font-weight: 300;
						display: inline;
					}
				}
			}
		}
	}

	.large_section_header {
		font-size: 1.1rem;
		padding-bottom: .5rem;
		font-weight: 700;
		position: relative;
		padding-right: 35px;

		.remove_btn {
			position: absolute;
			right: .25rem;
			top: -4px;
			padding: 0;
			border: none;
		}

		.remove_icon {
			width: 26px;
		}
	}

	.add_product_content {
		margin-left: .15rem;
	}

	.textarea_container {
		position: relative;

		.character_limit_txt {
			display: block;
			position: absolute;
			bottom: -23px;
			right: 0;
		}
	}

	.new_guideline_input_container {
		padding: 1rem;
		border: 1px solid $lightgray-border;
		border-radius: 6px;
		margin-bottom: .7rem;

		.textarea_container {
			padding-bottom: 1.5rem;
		}

		.hint_msg {
			margin-bottom: .9em;
		}

		.character_limit_txt {
			left: 0;
			bottom: 0;
		}
	}

	.editing_guideline_input_container {
		margin-bottom: .7rem;
		display: inline-block;
		width: 96%;
		margin-top: -1rem;
		padding: 1rem;
		border: 1px solid $lightgray-border;
		border-radius: 6px;
		margin: -1rem 0 .7rem 1rem;

		.character_limit_txt {
			display: block;
			float: right;
		}

		.guideline_input {
			margin-bottom: .3rem;
			float: left;
		}

		.guideline_input,
		.add_guideline_btn {}

		.save_guideline_btn {
			margin-top: .5rem;
			border-top: none;
		}
	}

	.input_instructions {
		font-size: .75rem;
		padding-top: 3px;
		padding-left: 10px;
		display: block;
	}

	.add_guideline_btn {
		border-top: 1px solid $lightgray-border;
	}

	.first_guideline_container {

		.main_label {
			margin-bottom: .5rem;
			display: block;
		}

		.guideline_specs_list {
			list-style-type: decimal;
			list-style-position: inside;
		}

		.hint_msg {
			margin-top: 1em;
			margin-bottom: .9rem;
			font-size: 1.4em;
		}
	}

	.adding_state {

		.main_label {
			margin-bottom: .5rem;
			display: block;
		}

		.added_guidelines_list {
			list-style-position: inside;
			list-style-type: disc;

			.contentUploader {
				position: relative;
				left: -20px;
			}
		}

		.added_guideline_item {}

		.added_guideline_txt {
			vertical-align: -2px;
			display: inline;
			width: 100%;
		}

		.placeholder_guideline_txt {
			color: #a2a2a2;
			vertical-align: -2px;
			display: inline;
			width: 100%;
		}

		.revfluence_item {
			/* list-style-image: url('/images/manage/revfluence_logomark_smaller.svg'); */

			.added_guideline_txt {
				vertical-align: -2px;
			}

			.input_instructions {
				padding-left: 21px;
			}
		}

		.guideline_input_container {
			margin-bottom: 0;
		}
	}

	.guideline_set {
		padding-bottom: 1rem;

		.addGuideline_btn {
			display: none;
			margin-left: .45rem;
			background: none;
			padding: 0;
			margin-top: -2px;
			position: absolute;
			right: 0;
			top: 0;
			font-size: 1rem;

			@include media($break630) {
				display: block;
			}
		}

		.addItem_icon {
			margin-right: 0;
			vertical-align: -5px;
		}

		.addGuideline_btn_txt {
			font-weight: 600;
			font-size: .9rem;
		}

		.guideline_set_header {
			padding-right: 8.4rem;
			position: relative;
			display: inline-block;
			padding-bottom: 3px;
		}

		.guideline_setHeader_txt {
			display: inline-block;
			font-weight: 700;
		}

		.guideline_list {
			list-style-position: inside;
			list-style-type: disc;
			padding-left: 0px;
		}

		.checkbox_inline {
			padding-left: 32px;
		}

		.guideline_input {
			font-size: .9rem;
			font-weight: 300;
			display: inline-block;
			width: calc(100% - 21px);
			border: none;
			border-radius: 3px;
			padding: 4px 6px;
			border: 1px solid $lightgray-border;

			&:active,
			&:focus {
				outline: none;
			}
		}

		.checkboxGuideline_item {
			list-style-type: none;
			display: block;
			margin-bottom: 9px;
			margin-top: 5px;

			&:first-child {
				margin-bottom: .25rem;
			}

			.guideline_txt {
				vertical-align: 4px;
				font-size: .9rem;
			}

			.actions_list {
				width: 30px;
			}

			.guidelineAlert_checkbox {
				margin-bottom: 2px;
			}

			.text_container {
				width: auto;
				float: none;
			}

			.control-indicator {
				left: 3px;
			}
		}

		.icon_submissionAlert {
			margin-right: 3px;
			vertical-align: -5px;
			width: 20px;
		}
	}

	.actions_list {
		position: absolute;
		right: 0;
		top: 2px;
		width: 47px;
		display: none;

		@include media($break630) {
			display: block;
		}

		.action_item {
			display: inline-block;
		}

		.edit_icon,
		.remove_icon {
			width: 19px;
		}

		.btn {
			background: none;
			padding: 0;
			border: none;
		}

		.edit_btn,
		.remove_btn {

			&:focus,
			&:active {
				box-shadow: none;
			}
		}

		.edit_btn {
			margin-right: .4rem;
		}
	}

	.noRemove_btn {
		width: 30px;
	}

	.termsEditable_item {
		position: relative;
		border-radius: 2px;
		padding: 3px 0;
		overflow-wrap: break-word;
		line-height: 1.25rem;

		.guideline_container {
			display: inline;
		}
	}

	.remove_button {
		width: 12px;
		height: 12px;
		position: absolute;
		right: 15px;
		top: 13px;
		border: none;
		background: url('/images/new_manage/remove_icon.svg') center no-repeat;
		display: block;
		cursor: pointer;
	}

	.add_account_button {
		padding: 10px;
		border: 1px dashed $revfluence-blue;
		border-radius: 6px;
		color: $revfluence-blue;
		font-weight: 300;
		background: white;
		cursor: pointer;
		width: 100%;
		display: block;
		margin-top: 1em;
		outline: none;

		&:hover {
			background-color: #FBFBFB;
		}
	}

	.content_guidelines_section {
		position: relative;
	}

	.edit_button {
		width: 22px;
		height: 22px;
		background: url('/images/collab_terms_tool/edit_button_icon.svg') center no-repeat;
		text-indent: -9999px;
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		border: none;
		cursor: pointer;

		&:active,
		&:focus {
			box-shadow: none;
			outline: none;
		}
	}

	.brief_section {

		.edit_button {
			top: 1.25rem;
			right: 0;
		}
	}

	.guidelines_text {

		span {
			display: block;
		}

		a {
			color: $revfluence-blue;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.content_guidelines_section {

		.edit_guidelines_label {
			font-weight: 300;
			display: block;
			margin-bottom: 10px;
			font-size: 1.85em;
		}

		.editContent_guidelines_list {
			list-style-position: inside;
			margin-bottom: .85em;
		}

		.editContent_guidelines_item {
			list-style-type: disc;
			padding: .75em 5em 0 0;
		}

		.editing_content_guidelines_container {

			.entered_item {
				list-style-type: disc;
				position: relative;
			}

			.content_guidelines_textarea {
				display: block;
				margin-bottom: 15px;
				font-size: 1.35em;
				height: 100px;
			}

			.save_content_guidelines_btn {
				display: inline-block;
			}

			.edit_guidelines_label {}

			.entered_item {
				position: relative;
			}

			.entered_text {
				width: calc(100% - 65px);
				display: inline-block;
			}

			.actions_container {
				width: 56px;
				position: absolute;
				right: 0;
				top: 7px;
			}

			.edit_btn {
				background: url('/images/new_manage/edit_icon.svg') center no-repeat;
			}

			.remove_btn {
				background: url('/images/new_manage/remove_icon.svg') center no-repeat;
			}

			.guideline_input {
				width: calc(100% - 97px);
				display: inline-block;
				margin-right: 5px;
				padding: 5px;
			}

			.enter_btn {
				display: inline-block;
				vertical-align: -1px;
			}

			.entered_btn {
				padding: 0;
				width: 26px;
				height: 26px;
				background-size: 60%;
			}

			.entered_btn {
				padding: 0;
				width: 26px;
				height: 26px;
				background-size: 60%;
			}
		}
	}

	.content_review_section {
		position: relative;

		.edit_btn {
			position: absolute;
			top: 0;
			right: 0;
			background: none;
			padding: 0;
		}

		.section_title {
			margin-bottom: 1rem;
		}

		.first_checkbox {
			margin-bottom: .8rem;
		}

		.checkbox_control {
			margin-left: .2rem;
			padding-right: 0;
		}

		.first_row {
			margin-bottom: .8rem;
		}

		.txt_container {
			font-size: 1em;
		}

		.checkmark_icon,
		.process_item_txt {
			float: left;
		}

		.checkmark_icon {
			margin-right: .5rem;
		}

		.process_item_txt {
			width: calc(100% - 28px);
		}
	}

	.payment_amount_container {

		.edit_btn {
			padding: 0;
			display: inline;
		}
	}

	.payment_amount {
		display: inline-block;
		margin-right: 5px;
	}

	.payment_clarification {
		font-style: italic;
		margin-top: 0.5em;
	}

	.custom_payment_terms_edit_container {
		margin-top: 1.0rem;
		margin-bottom: 0.5rem;
	}

	.ads_reports {

		tbody {
			height: auto;
		}

		tr,
		body,
		thead {
			display: table;
		}

		th {
			font-size: .8rem;
		}

		td {
			font-size: .9rem;

			@include media($break730) {
				font-size: .75rem !important;
			}
		}

		.smWidth {
			width: 95px;
		}

		.medWidth {
			width: 155px;
		}

		.truncate {
			max-width: 217px;

			@include media($break730) {
				max-width: 85px;
			}
		}

		.larWidth {
			width: 245px;
			font-weight: 600;
		}

		.form_control {
			font-size: .9rem;
			background: url('/images/style_guide/icon_payment_black.svg') 7px center no-repeat;
			padding: .4em .7em .4em 1.25em;
			background-size: 6px;
		}

		.creator_avatar {
			vertical-align: -13px;
			margin-right: .35rem;
		}

		.standard_table2 {
			height: auto;
		}
	}

	.change_brief_button,
	.brief_link {
		color: $revfluence-blue;
		border: none;
		background: none;
		cursor: pointer;
		display: inline-block;
		text-decoration: none;
	}

	.negotiate_button {
		color: $revfluence-blue;
		border: none;
		background: none;
		cursor: pointer;
		display: inline-block;
	}

	.negotiate_payment_container {}

	.timezone_notice {
		margin-top: .6rem;
		padding: .3rem .9rem .4rem;
	}

	.negotiate_button,
	.edit_requirements_button {
		color: $revfluence-blue;
		border: none;
		background: none;
		cursor: pointer;
		display: inline-block;
	}

	.negotiate_title {
		font-weight: 600;
	}

	.negotiate_disclaimer {
		font-style: italic;
		margin-bottom: 15px;
	}

	.old_price_container {
		margin-bottom: 15px;
	}

	.new_price_label,
	.explanation_label {
		display: block;
		margin-bottom: 7px;
	}

	.new_price_input {
		display: inline-block;
		width: 125px;
	}

	.explanation_input {
		width: 95%;
		height: 100px;
	}

	.custom_payment_terms_textarea {
		width: 95%;
		height: 60px;
	}

	.submit_negotiation_btn {
		display: inline-block;
		margin-right: 10px;
	}

	.cancel_btn {
		display: inline-block;
		padding: 6px 17px;
	}

	.new_price_explanation_container {
		margin-top: 1.0em;
		margin-bottom: 15px;
	}

	.date_to_post {
		max-width: 100px;
	}

	.date_to_post,
	.brief_url {
		display: inline-block;
		margin-right: 5px;
	}

	.brief_input_container {
		width: calc(100% - 84px);
		margin-right: .4rem;
		display: inline-block;
	}

	.change_date_button {
		color: $revfluence-blue;
		display: inline-block;
		border: none;
		background: none;
		cursor: pointer;
		vertical-align: -6px;
	}

	.content_type_item {}

	.active_type_item {
		border: 1px solid #90C2FF !important;
		background-color: #EEF6FF;
	}

	.type_package_container {
		float: left;
		width: calc(100% - 45px);
	}

	.post_price {}

	.percent_average {}

	.change_comment_container {
		padding: 21px 17px;
		border-radius: 6px;
		overflow: hidden;
		background-color: $user_msg_blue;
		margin-top: 20px;
		border: 1px solid #ECECEC;
	}

	.payment_terms_comment_container {
		padding: 21px 17px;
		border-radius: 6px;
		overflow: hidden;
		margin-top: 20px;

		.change_comment_text_container {
			font-weight: 600;
		}
	}

	.comment_avatar_container {
		float: left;
		margin-right: 19px;
		width: 50px;

		@include media($break425) {
			width: 35px;
		}
	}

	.comment_user_avatar {
		display: block;
		max-width: 100%;
		border-radius: 100px
	}

	.change_comment_text_container {
		float: left;
		width: calc(100% - 69px);

		@include media($break425) {
			width: calc(100% - 54px);
		}
	}

	.change_text {
		font-style: italic;
	}

	.creatorAddress_container {
		position: relative;

		.address_title {
			margin-bottom: 15px;
		}

		.editing_name_wrapper,
		.city_state_wrapper,
		.postal_country_wrapper {
			margin-bottom: 1em;
		}

		.addressEntered_mode {}

		.name_wrapper {}

		.first_name {}

		.last_name {}

		.street {}

		.city_state_zip_wrapper {}

		.non_edit_mode_container {}

		form {}

		.firstName_fieldset {
			width: 50%;
			float: left;
			margin-right: 3%;
		}

		.firstName_label {}

		.firstName_input {}

		.lastName_fieldset {
			float: left;
			width: 47%;
		}

		.lastName_label {}

		.lastName_input {}

		.street_fieldset {
			margin-bottom: 1em;
		}

		.street_label {}

		.street_input {}

		.city_state_wrapper {}

		.city_fieldset {
			width: 50%;
			float: left;
			margin-right: 3%;
		}

		.city_label {}

		.city_input {}

		.state_fieldset {
			float: left;
			width: 47%;
		}

		.state_label {}

		.state_input {}

		.postal_country_wrapper {}

		.postal_fieldsets {
			width: 50%;
			float: left;
			margin-right: 3%;
		}

		.postal_label {}

		.postal_input {
			width: 150px;
		}

		.country_fieldset {
			float: left;
			width: 47%;
		}

		.country_label {}

		.country_input {}

		.save_btn {}
	}

	.calendar {
		border: 1px solid $lightgray-border;
		border-radius: 6px;
		padding: 1.25em 1.25em .4em 1.25em;
		width: 259px;
		margin-bottom: 2em;


		.calendarHead {
			padding-bottom: 1em;
			border-bottom: 1px solid $lightgray-border;
			overflow: hidden;
			position: relative;
			margin-bottom: .35em;
		}

		.navigateMonth_btn {
			width: 20px;
			height: 20px;
			display: block;
			position: absolute;
			padding: 0;

			&:focus,
			&:active {
				box-shadow: none;
			}
		}

		.previousMonth_btn {
			background: url('/images/new_manage/prev_month_icon.svg') center no-repeat;
			left: 0;
			top: 1px;
		}

		.nextMonth_btn {
			background: url('/images/new_manage/next_month_icon.svg') center no-repeat;
			right: 0;
			top: 1px;
		}

		.calendar_title {
			margin: 0 auto;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 180px;
			display: block;
			text-align: center;
		}

		.month {}

		.year {}

		.calendarBody {}

		.weekdayNames_list {}

		.weekdayNumbers_list {}

		.weekday_item {
			display: inline-block;
			margin: 0;
			width: 30px;
			height: 30px;
			padding-top: 5px;
			text-align: center;
		}

		.day_btn {
			cursor: pointer;
			border-radius: 4px;
			overflow: hidden;

			&:hover {
				background-color: #E1EEFF;
				color: $revfluence-blue;
			}
		}

		.activeDay_btn {
			background-color: $revfluence-blue;
			color: white;
			border-radius: 4px;

			&:hover {
				color: white;
				background-color: $revfluence-blue;
			}
		}

		.empty_btn {
			cursor: default;

			&:hover,
			.day_btn {
				background-color: white;
			}
		}
	}

	.paymentTable_wrapper {}

	.signature_section {

		.default_body_copy {
			padding-bottom: .75rem;
		}
	}

	.postType_social_acct {
		color: $dark-text;
		text-decoration: none;
		margin-left: 4px;
		display: inline-block;
		vertical-align: -2px;

		&:hover {
			color: $revfluence-blue-hover;
		}

		.network_icon {
			display: inline-block;
			height: 25px;
			width: 18px;
			vertical-align: -4px;
		}

		.icon_instagram {
			margin-top: 0;
		}

		.icon_youtube {
			margin-top: 2px;
		}

		.icon_snapchat {}

		.icon_vine {}

		.icon_twitter {}

		.icon_facebook {}

		.icon_blog {}

		.icon_pinterest {}

		.icon_googleplus {}

		.socialAcct_txt {
			display: inline-block;
		}
	}

	.update_button {
		max-width: 256px;
		margin: 0 auto;
		display: block;
	}

	.advertiser_access {

		.edit_mode {

			.standard_label {
				font-weight: 600;
			}

			.requires_advertiser {
				margin: .5rem 0 1rem .2rem;
				height: 26px;

				.text_container {
					margin-top: 2px;
				}
			}

			.required_options {
				padding-left: 1rem;
			}

			.account_selection {
				padding-bottom: 1.5rem;
			}

			.standard_label {}

			.account_list {}

			li {
				padding: .35rem 0;
				display: block;
				height: 37px;
			}

			.network_icon {
				margin-right: .35rem;
				vertical-align: -7px;
			}

			.error_caption {
				padding-top: .5rem;
			}

			.date_item {
				display: inline-block;
				width: 215px;
				padding-right: 1.5rem;

				@include media($break475) {
					display: block;
					padding-right: 0;
					padding-bottom: .25rem;
				}
			}
		}

		.entered_mode {

			.intro_statement {
				padding-top: .25rem;
			}

			.account_list {
				font-weight: 600;
				padding: .5rem 0;
				margin-bottom: .5rem;

				.icon_network {
					vertical-align: -2px;
				}
			}

			.date_list {
				padding: .5rem 0;
				margin-bottom: .5rem;

				.icon_calendar {
					vertical-align: -1px;
				}
			}

			.icon_network,
			.icon_calendar {
				width: 14px;
				margin-right: .2rem;
			}
		}

		&.uce_influencer_whitelist {
			.edit_mode {
				.required_options {
					padding-left: 0.2rem;
				}

				.requires_advertiser {
					margin-top: 0;
					margin-bottom: 24px;
					height: initial;
					min-height: 26px;

					.text_container {
						font-weight: bold;
						font-size: 14px;
					}
				}

				.authInsightsBtn {
					margin-top: 1rem;
					width: 100%;
				}
			}

			.date_selection {
				padding-bottom: 1.5rem;
			}
		}
	}

	.grant_and_signature_section {
		&_inner {
			display: flex;
			flex-direction: row;
		}

		.icon-and-line {
			margin-right: 16px;
			position: relative;

			.blue-circle {
				width: 40px;
				height: 40px;
				background: #3D73D7;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				overflow: hidden;
				z-index: 2;

				.icon-sign {
					width: 25px;
					height: 25px;
				}
			}

			&::before {
				content: "";
				background: linear-gradient(180deg, #BDBDBD 0%, rgba(189, 189, 189, 0) 100%);
				position: absolute;
				width: 1px;
				height: 100%;
				left: 50%;
				top: 40px;
				bottom: 0;
				display: block;
				z-index: 1;
			}
		}

		.section-content {
			.section_title {
				margin-top: 0.5rem;
			}
		}

		.step-container {
			display: flex;
			flex-direction: column;
			margin-top: 42px;

			.step-item {
				display: flex;
				flex-direction: column;
				margin-bottom: 32px;

				&-title {
					font-weight: bold;
					font-size: 14px;
					line-height: 18px;
					display: inline-flex;
					align-items: center;

					.icon-check {
						width: 23px;
						height: 23px;
						margin-right: 15px;
					}
				}

				&-body {
					margin-top: 6px;
					padding-left: 38px;

					.grant-faqs {
						margin-top: 6px;
						list-style-type: disc;
						color: #3996E0;
						padding-left: 24px;
					}

					.signature_section {
						border-bottom: none;
					}
				}

				.step-actions {
					margin-top: 16px;
					margin-bottom: 24px;
				}

				&--disabled {
					opacity: 0.7;
					pointer-events: none;
					user-select: none;
				}

				&--success {
					.step-item-title {
						text-decoration: line-through;
						color: #BDBDBD;
					}
				}
			}
		}
	}
}

.old_terms_v7 {

	.remove_btn,
	.edit_btn {
		padding: 6px 3px;

		&:hover,
		&:active,
		&:focus {
			box-shadow: none;
		}
	}

	.actions_container {
		position: absolute;
		right: 0;
		top: -7px;
		display: none;

		@include media($break630) {
			display: block;
		}
	}

	.added_guidelines_list {
		padding-bottom: .5rem;
	}

	.add_added_guideline_txtguideline_btn {
		margin: 0 0 .7rem 3px;
	}

	.added_guideline_item,
	.revfluence_item {
		position: relative;
		padding-right: 60px;
		line-height: 1.5rem;

		&:hover {

			.actions_container {
				display: block;
			}
		}
	}

	.editing_guideline_input_container {
		width: 100%;
	}
}




.collab_terms_tool,
.contentTemplates_tab {

	.terms_edit_mode {

		.termsEditable_item {

			&:hover {

				.actions_list {
					display: block;
				}
			}
		}

		.guideline_set {

			&:hover {

				.addGuideline_btn {
					display: inline-block !important;
				}
			}
		}

		.contentConcept_set {
			padding-bottom: .5rem;

			&:hover {
				.addGuideline_btn {
					display: inline-block !important;
				}
			}
		}
	}
}

.contentTemplate_tab {

	.termsEditable_item {

		div {
			display: inline;
		}
	}
}

.collab_terms_tool {

	.termsEditable_item {

		div {
			display: block;
		}
	}
}

.direct_offer {

	.collab_terms_tool {

		.tool_main_container {
			padding: 0 .25rem;

			@include media($break500) {
				padding: 0;
			}
		}

		.terms_container {

			@include media($break350) {
				padding: 0 .3rem;
			}
		}
	}
}

.alert_notice {
	background: #fff1f0;
	border: 1px solid #ffa39e;
	box-sizing: border-box;
	border-radius: 6px;
	margin-bottom: 20px;
}

.default_notice {
	background: #e6f7ff;
	border: 1px solid #91d5ff;
	box-sizing: border-box;
	border-radius: 6px;
	margin-bottom: 20px;
}

.notice {
	padding: 16px;
	font-size: 0.9rem;

	.info_icon {
		display: inline-block;
		margin-right: 0.5em;
		height: 16px;
		vertical-align: -3px;
	}

	.header {
		font-weight: bold;
		margin-bottom: 8px;
	}

	.notice_body {
		.notice_p {
			margin-top: 4px;
			margin-left: 1.8em;
		}

		.notice_link {
			color: $aiq_darkblue;
			text-decoration: none;

			&:hover,
			&:focus {
				color: $aiq_darkblue_hover;
				text-decoration: underline;
			}
		}
	}
}

.negativeInsightsNotice {
	margin-top: 1rem;
}

.strong {
	font-weight: 600;
}