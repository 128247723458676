@mixin existing-labels-list {
	@include fontsize12;

	li {
		display: inline-block;
		margin: 0 .5em .5em 0;
  		line-height: 1em;
	}

	.label {
        border-radius: 25px;
        padding: .35em 1em;
        text-decoration: none;
        color: white;
        display: inline-block;

        .label-text {
            display: inline-block;
            color: white;
            font-weight: 300;
            @include fontsize12;
        }

        .close-icon {
            padding: 0.2em;
            display: inline-block;
        }
    }

	.blue-label { background-color: $revfluence-blue; }
	.green-label { background-color: $label-green; }
	.yellow-label { background-color: $label-yellow; }
	.purple-label { background-color: $label-purple; }
	.brown-label { background-color: $label-brown; }
	.red-label { background-color: $label-red; }
	.pink-label { background-color: $label-pink; }
}

@mixin add-label {
	border: 1px solid $cornflower;
	border-radius: 25px;
	color: $revfluence-blue;
	padding: .35em 1em;
	text-decoration: none;
	@include fontsize12;
	cursor: pointer;

	&:hover {
		color: $revfluence-blue-hover;
		border: 1px solid $revfluence-blue-hover;
	}
}

@mixin edit-label {
	border: 1px solid $revfluence-blue;
	width: 100%;
	border-radius: 25px;
	color: $revfluence-blue;
	padding: .35em 1em;
	background: none;
	margin: 0;
	@include fontsize12;

	&:focus {
		outline: none;
	}
}


@mixin non_colored_labels {

	.label {
        border-radius: 25px;
        padding: .35em 1em;
        text-decoration: none;
        color: white;
        display: inline-block;
        background-color: #a9a9a9;
        border: 1px solid #999999;
        margin: 0 .5em .5em 0;

        .label-text {
            display: inline-block;
            color: white;
            font-weight: 300;
            @include fontsize12;
            margin-right: .5em;
        }

        .close-icon {
            padding: 0.2em;
            display: inline-block;
            background: url('../images/misc/remove_label_icon.svg') center no-repeat;
            width: 10px;
            height: 10px;
            vertical-align: -.1em;
        }
    }
}