.affiliate_list {

	.main_container {
		width: 1200px;
		margin: 10em auto 0;
	}

	.intro_container {
		margin-bottom: 2em;
		padding-top: 3em;
		float: left;

		.intro_title {
			@include title25;
		}
	}

	.table_header {
		overflow: hidden;
	}

	.table_tools_container {
		position: relative;
		float: right;
		width: 634px;
		height: 50px;
		margin-top: 3em;

		.filter_container {
			display: inline-block;
			vertical-align: 1.2em;
			margin-right: 2em;

			.filter_title {
				@include fontsize14;
				color: $dark-text;
				margin-right: 1em;
				@include standard-bold;
			}

			.filter_label {
				margin-right: 1em;
			}

			.filter_text {
				display: inline-block;
				@include fontsize15;
				color: $dark-text;
			}

			.filter_checkbox {
				display: inline-block;
				margin-right: .5em;
			}
		}

		.affiliate_search_container {
			display: inline-block;

			.affiliate_search_input {
				width: 250px;
				border: none;
				border: 1px solid $lightgray-border;
				background-color: $lightgray-bg;
				padding: .25em 2em .25em .8em;
				@include fontsize15;
				border-radius: 45px;
				display: block;
				color: $dark-text;
				background: url('../images/affiliates/search_icon.svg') 95% center no-repeat;
				background-size: 6%;

				&:focus {
					background-color: white;
					border: 1px solid $revfluence-blue;
				}
			}
		}
	}

	.affiliate_table {
		margin-bottom: 2em;
		width: 100%;

		table, th, td {
		    border: 1px solid $lightgray-border;
		    border-collapse: collapse;
		    border-spacing: 0;
		}

		th, td {
			padding: .75em;
        }

        td {
            @include fontsize15;
        }

		th {
			@include fontsize15;
			font-weight: 600;
		}

		.affiliate_program {

			a {
				@include blue-link;
			}
		}

		.approve_affiliate_button {
			display: inline-block;
			margin-right: .5em;
		}

		.reject_affiliate_button {
			display: inline-block;
		}
	}

	.add_affiliate_container {

		.add_affiliate_button {
			display: inline-block;
		}
	}
}