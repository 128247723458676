.campaign_shared {
  scrollbar-face-color: none;
  scrollbar-track-color: none;
  background-color: none;
  padding: 0 1%;

  ::-webkit-scrollbar {
    width:  0px;
  }

  ::-webkit-scrollbar-thumb {
    background: none;
    border-radius: 100px;
  }

  ::-webkit-scrollbar-track {
    background: none;
  }

  .new_campaign_edit_view, .main_content {
		height: 100%;
	}

  .campaignHeader {
    margin-bottom: 2rem;
    text-align: center;
    position: relative;
    padding-top: 1.5rem;
		margin-top: 3rem;

    @include media($break550) {
      padding-top: .85rem;
    }

    .back_btn {
      background: none;
      position: absolute;
      top: 2.7rem;
      left: 2rem;
      border: none;
      padding: 10px;

      @include media($break550) {
        left: 1rem;
        top: 1.5rem;
      }

      .back_icon {
        width: 29px;

        @include media($break550) {
          width: 26px;
        }
      }
    }

    .main_title {
      font-size: 3.75em;

			strong {}

      @include media($break550) {
        font-size: 1.75rem;
        padding: 0 3rem;
      }
    }

    .main_descrip {
      font-size: 1.15rem;

      @include media($break550) {
        font-size: 1rem;
        padding: 0 1rem;
      }
    }
  }

  .tab_content_container {
    padding: 2rem 2.75rem 1.5rem;
    position: relative;

    @include media($break1024) {
      padding: 2rem 1.5rem 1.5rem;
    }
  }

  .active_tab_content {
    border-radius: 0;
    border-bottom: none;
    border-top: none;
    margin-top: 1px;
  }

  .main_content {
    max-width: 1200px;
    margin: 0 auto;

    .active_tab_header {
      text-align: center;
      margin-bottom: 2rem;

      a {
        color: $revfluence-blue;
        font-weight: 500;
        text-decoration: none;

        &:hover {
          color: $revfluence-blue-hover;
        }
      }

      .active_tab_title {
        margin-bottom: .25rem;
        font-size: 3em;

        @include media($break550) {
          font-size: 2.5em;
        }
      }

      .active_tab_descrip {}
    }

    .settings_wrapper {
      position: relative;

      .disabled_screen {
        position: absolute;
        background-color: rgba(255,255,255,.5);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 3;
      }
    }

    .profileDetails_form {
      max-width: 1200px;
      margin: 0 auto;
      border-bottom: 1px solid $lightgray-border;
      border-radius: 4px;
    }

    .campaignForm_wrapper {

      .standard_label {
        font-weight: 600;
      }
    }

    .section_label {
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .subSection_label {
      margin-bottom: 1rem;
    }

    .subSection_container {}

    .profile_details_tab {

      .error {
        display: block;
        text-align: center;
        font-size: .9rem;
        margin-bottom: 1rem;
      }

      .productImg_container {
        float: left;
        width: 310px;
        margin-right: 2rem;

        @include media($break700) {
          float: none;
          width: auto;
          margin-right: 0;
          margin-bottom: 2rem;
        }
      }

      .dragDrop_wrapper {
        margin-bottom: .8rem;

        .drag_drop_container {
          display: block;
        }

        .drop_slot_container {
          width: 276px;
        }

        .drop_slot_item {
          width: 276px;
          height: 276px;
          background-size: 45%;
        }

        .loading_icon {
          width: 35px;
          margin: 8rem auto 0;
        }

        .dropped_slot_item {
          width: 276px;
          height: 276px;
        }

        .dropped_img_wrapper {
          width: 276px;
          height: 276px;
        }

        @include media($break700) {
          width: 310px;
        }

        @include media($break365) {
          width: 270px;

          .drop_slot_container, .dropped_slot_item, .drop_slot_item, .dropped_lost_item, .dropped_img_wrapper {
            width: 233px;
          }

          .drop_slot_item, .dropped_slot_item, .dropped_img_wrapper {
            height: 233px;
          }

          .drop_slot_item {
            background-size: 42%;
          }

          .loading_icon {
            width: 28px;
            margin: 5rem auto 0;
          }
        }
      }

      .productImg_descrip {}

      .otherFields_container {
        float: left;
        width: calc(100% - 342px);

        @include media($break700) {
          float: none;
          width: auto;
        }
      }

      .exampleContent_container {
        padding-top: 20px;
      }

      .exampleContent_fieldset {

        .added_fieldset {
          margin-bottom: .5rem;

          .added_input {
            float: left;
            width: 75%;
            margin-right: .5rem;
          }

          .remove_btn {
            background: none;
            float: left;
            padding: .5rem 0;
          }
        }
      }

      .hint_msg {
        margin-top: .75rem;

        p {
          font-weight: 600;
        }

        ul {
          list-style: disc;
          list-style-position: inside;
        }
      }

      .socialNetworks_fieldset {
        padding: 1rem 0 1.4rem;
      }

      .checkbox_container {
        margin-top: .5rem;

        .network_name {
          vertical-align: 5px;
        }

      }

      .youtube_icon {
        margin-bottom: 3px;
      }

      .network_icon {
        padding: 2px 0 0 3px;
        width: 25px;
      }

      .first_checkbox {
        margin-right: 1rem;
      }

      .compensationInformation {}

      .compensation_section {
        padding: .5rem 0 0;
      }

      .compensation_title {
        margin-bottom: .75rem;
        font-weight: 600;
      }
    }
  }

  .hint_indicator {}

  .fieldIndent {
    padding-left: 1rem;
  }

  .basedOn_expectedLikes_container {
    margin: 1.25rem 0 1rem;
  }

  .payment_instructions {
    margin-bottom: .85rem;

    .paymentInstructions_label {
      padding: 0 0 0 30px
    }

    .hint_indicator {
      vertical-align: -7px;
      margin-left: .25rem;
    }
  }

  .currency_input {
    display: inline-block;

    .dropdown_btn {
      min-width: 100px;
      margin-bottom: 0;
    }

    .dropdown_list {
      display: block;

      &:before, &:after {
        left: unset;
        right: .5rem;
      }
    }
  }

  .fixed_price_instructions {
    margin-top: .65rem;
    border-bottom: 1px solid $lightgray-border;
    padding-bottom: 1rem;

    .payment_details_label {
      display: inline;
      margin-right: .5rem;
    }

    .payment_details_input {
      max-width: 100px;
      display: inline;
    }

    .fixedComp_perEngage_txt {
      margin-top: .35rem;
      display: block;
    }
  }

  .maxBudget_perIg_container {
    padding-bottom: 1rem;

    .maxBudget_label {
      margin-right: .5rem;
    }

    .hint-indicator {
      vertical-align: -5px;
    }

    .max_budget_select {
      margin-left: .75rem;

      @include media($break550) {
        margin-left: 0;
      }
    }
  }

  .eligib_tab {

    .sectionHeader {
      border-radius: 5px;
      border: none;
      background-color: white;
      width: 100%;
      display: block;
      text-align: left;
      padding: .75rem 1rem;
    }

    .invite_only_section {
      margin-bottom: 2.0rem;
    }

    .close_application_section {
      max-width: 550px;
      margin-bottom: 2.0rem;

      .application_close_input {
        width: 150px;
        margin-bottom: 1.0rem;
      }
    }

    .target_collabs_section {
      margin-bottom: 2.0rem;

      .target_collabs_input {
        width: 150px;
      }
    }

    .accordionBtn {
      position: relative;
      padding: .5rem 5.25rem .5 1rem;
    }

    .standardAccordion {
      max-width: 550px;
      margin-bottom: .7rem;

      .disclosure_icon {
        position: absolute;
        right: .4rem;
        top: 1.29rem;
        width: 15px;
      }

      .open_disclosureIcon {
        transform: rotate(180deg);
      }

      .control-indicator {
        top: .4rem;
      }

      .hint_icon {
        vertical-align: -4px;
      }

      .text_container {
        font-size: 1rem;
      }

      .checkbox_control {
        margin-bottom: 0;
      }

      .vine_icon {
        width: 15px;
        margin-right: 3px;
      }

      .pin_icon {
        width: 15px;
        vertical-align: -4px;
      }

      .gplus_icon {
        width: 22px;
        vertical-align: -1px;
      }
    }

    .network_container {}

    .network_header {
      margin-bottom: 1rem;
    }

    .slider_range {
      margin-bottom: .75rem;
    }

    .network_label {
      font-weight: 600;
    }

    .ig_label, .yt_label {}

    .estimated_price {}

    .subSection_container {
      margin-bottom: 1rem;
      padding-bottom: .15rem;
    }

    .igFollower_label {
      margin-bottom: 1rem;
    }

    .network_icon {
      width: 17px;
      margin-right: 3px;
      vertical-align: -2px;
    }

    .slider_container {
      max-width: 365px;
    }

    .slider_descrip_container {}

    .slider_descrip {
      font-size: .9rem;
    }

    .countries_container, .language_container {
      margin-bottom: 1rem;

      .section_label {
        margin-bottom: .5rem;
      }

      .field_hint {
        margin-top: .35rem;
        display: block;
      }
    }

    .text_input_container {
      max-width: 550px;
    }
  }

  .recommend_tab {

    .recommend_form {}

    .creatorType_fieldset, .campaign_goal_fieldset, .campaign_goal_descrip {
      margin-bottom: 1rem;

      .typesOf_creatorsLabel {
        margin-bottom: 1rem;
        font-weight: 600;
      }

      .campaign_goal_label {
        font-weight: 600;
      }

      .checkbox_control {
        margin-bottom: 1rem;

        @include media($break375) {
          display: block;
        }
      }
    }

    .accordionBtn_txt {
      font-weight: 600;
    }

    .ageRange_fieldset, .gender_fieldset, .idealInstagrammers_fieldset {

      .standard_label {
        font-weight: 600;
      }
    }

    .ageRange_fieldset, .gender_fieldset {
      margin-bottom: 1rem;
    }

    .ageRange_fieldset {

      .ageRange_label {
        margin-bottom: .75rem;
        display: block;
      }
    }

    .group_label {
      display: block;
      margin-bottom: .75rem;
    }

    .checkbox_control, .radio {
      margin-bottom: .5rem;
    }

    .gender_fieldset {
      margin-bottom: 1rem;
    }

    .idealInstagrammers_fieldset {
      margin-bottom: 1.5rem;

      .idealInsta_label {}

      .idealInsta_descrip {
        margin-bottom: .55rem;
      }

      .instaUser_input {
        max-width: 350px;
        margin-bottom: .5rem;
      }

      .addField_btn {

        .add_icon {
          vertical-align: -10px;
        }
      }

      .added_fieldset {

        .added_instaUser_input {
          float: left;
          width: 75%;
          margin-right: .5rem;
        }

        .remove_btn {
          background: none;
          float: left;
          padding: .5rem 0;
        }
      }
    }

    .radio {}

    .radio_inline {}
  }

  .creatorManage_tab {

    .maxWidth_fieldset {
      max-width: 450px;
    }

    .creatorManage_form {
      margin-top: 2.5rem;
    }

    .productOptions_container {
      margin-bottom: 1.25rem;

      .productOptions_label {
        margin-bottom: 0;
      }

      .prerequisite_indicator {
        display: block;
        max-width: 550px;
        font-weight: 600;
        margin: .6rem 0 .75rem;
      }

      .productOptions_input {
        margin-bottom: .75rem;
      }

      .productOptions_descrip {
        margin-bottom: 9px;
      }
    }

    .autoResponder_descrip {
      margin-bottom: 9px;
    }

    .autoResponse_input {}

    .autoResponder_label {
      margin-bottom: 0;
    }
  }

  /* CONTENT GUIDELINES TEMPLATES */
  .contentTemplates_tab {

    .newTemplate_btn {
      width: 173px;
      margin: 0 auto;
      display: block;
    }

    .contentTemplates_actions_list {
      text-align: center;

      .contentTemplates_action_item {
        display: inline-block;

        @include media($break405) {
          display: block;
        }
      }

      .btn_primary {
        margin-right: .25rem;

        @include media($break405) {
          margin-right: 0;
          margin-bottom: .5rem;
        }
      }
    }

    .contentTemplates_container {
      .accordionMain_content {
        background: white;
      }
    }

    /* FIRST TEMPLATE NOTICE */
    .firstTemp_wrap {
      text-align: center;
      padding: 1rem 1rem 1.5rem;

      .firstTemp_title {
        font-weight: 600;
      }

      .firstTemp_descrip {
        padding-bottom: .5rem;
      }

      .firstTemp_actions_list {
        width: 100%;
      }

      .firstTemp_actions_item {
        display: inline-block;
      }

      .btn_selectPremade {
        margin-right: .25rem;

        @include media($break435) {
          margin-right: 0;
          margin-bottom: 1rem;
        }
      }

      .btn_newTemplate {}
    }

    .icon_newTemplate {
      vertical-align: -5px;
      margin-right: .25rem;
    }

    .accordionBtn {
      position: relative;
      vertical-align: -3px;
      padding: .5rem 4.75rem .5rem 1rem
    }

    .accordionBtn_txt {
      display: inline-block;
      width: calc(100% - 135px);
      font-size: .8rem;
    }

    .closed_accordionBtn_txt {
      margin-top: -9px;
    }

    .title_txt_wrap {
      vertical-align: -10px;
      width: calc(100% - 53px);
      padding: .4rem 0;
      display: inline-block;
      position: relative;
    }

    .template_title_txt {
      font-weight: 700;

      &:hover {

        .editTemplate_name_btn {
          display: block;
        }
      }
    }

    .product_type {
      font-weight: 400;
    }

    .product_type_txt {
      display: block;
    }

    .template_title_input {
      display: inline-block;
      width: calc(100% - 50px);
      font-size: .9rem;
      padding: .5rem 1rem;
      vertical-align: 2px;
    }

    .templateBtn_controls_list {
      position: absolute;
      right: 8px;
      top: 0;
      z-index: 2;
    }

    .templateBtn_controls_item {
      display: inline-block;
    }

    .templateBtn_remove_item {
      position: relative;
    }

    .deleteTemplate_confirmation_tooltip {
      position: absolute;
      right: -6px;
      top: 28px;
      width: 200px;
      padding: .65rem 1rem .85rem 1rem;
      position: absolute;
      background: #FFFFFF;
      border-radius: 6px;
      box-shadow: 0 1px 3px rgba(0,0,0,.05);
      z-index: 8000;
      white-space: normal;
      border: 1px solid $lightgray-border;

      &:after {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 0 10px 10px;
        border-color: $hint_yellow transparent;
        display: block;
        width: 0;
        z-index: 1;
        top: -10px;
        right: 8px;
      }

      &:before {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 0 10px 10px;
        border-color: $hint_border_yellow transparent;
        display: block;
        width: 0;
        z-index: 0;
        top: -11px;
        right: 8px;
      }

      .btn_danger {
        margin-right: 1px;
      }

      .btn {
        font-size: .85rem;
        padding: 6px 14px;
      }

      .btn_default {
        color: black;
      }

      .tooltip_txt {
        display: block;
        padding-bottom: .5rem;
        font-weight: 600;
        font-size: .85rem;
      }

      .tooltip_parenth {
        font-weight: 300;
      }
    }

    .icon_delete {
      width: 15px;
    }

    .icon_duplicate {
      width: 20px;
    }

    .editTemplate_name_btn, .deleteTemplate_btn {
      padding: 0;
      border: none;
    }

    .deleteTemplate_btn {
      padding: 15px 10px;
      cursor: pointer;
    }

    .collabHeader_container, .tools_header, .signature_section, .accept_terms_btn {
      display: none !important;
    }

    .added_templatesWrapper {}

    .chooseContent_type {

      .chooseContent_type_title {
        font-size: 1.15rem;
        font-weight: 600;
        padding-bottom: .75rem;

        @include media($break450) {
          font-size: 1.05rem;
        }
      }

      .chooseContent_type_list {}

      .chooseContent_type_item {
        margin-bottom: .5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .chooseContent_type_btn {
        background-color: white;
        border: 1px solid $lightgray-border;
        padding: .7rem 2.6rem .8rem 1rem;
        text-align: left;
        border-radius: 6px;
        display: block;
        background: white url('/images/style_guide/icon_chevronRight_black.svg') 98% center no-repeat;
        background-size: 10px;
        width: 100%;
        white-space: initial;

        &:hover {
          background: #fcfcfc url('/images/style_guide/icon_chevronRight_black.svg') 98% center no-repeat;
          background-size: 10px;
        }

        &:focus, &:active {
          background-size: 12px;
        }

        @include media($break630) {
          background: white url('/images/style_guide/icon_chevronRight_black.svg') 97% center no-repeat;
          background-size: 10px;

          &:hover {
            background: #FAFAFA url('/images/style_guide/icon_chevronRight_black.svg') 97% center no-repeat;
            background-size: 10px;
          }

          &:focus, &:active {
            background-size: 10px;
          }
        }

        @include media($break450) {
          background: white url('/images/style_guide/icon_chevronRight_black.svg') 96% center no-repeat;
          background-size: 10px;
          padding: .7rem 2rem .8rem 1rem;

          &:hover {
            background: #FAFAFA url('/images/style_guide/icon_chevronRight_black.svg') 96% center no-repeat;
            background-size: 10px;
          }

          &:focus, &:active {
            background-size: 10px;
          }
        }
      }

      .chooseContent_icon_network {
        width: 20px;
        float: left;
        margin: 12px 10px 0 0;
      }

      .icon_instagram {}

      .icon_youtube {}

      .icon_vine {}

      .icon_twitter {}

      .icon_facebook {}

      .icon_blog {}

      .icon_pinterest {}

      .icon_googleplus {}

      .chooseContent_txt_container {
        float: left;
        width: calc(100% - 34px);
      }

      .contentType_title {
        font-weight: 600;
      }

      .contentType_descrip {
        font-weight: 300;
      }
    }

    .added_templates_list {
      padding-bottom: 1.5rem;

      .disclosure_icon {
        position: absolute;
        top: 50%;
        margin-top: -8px;
        left: 16px;
      }

      .accordionBtn {
        padding: .5rem 4.75rem .5rem 2.5rem;
        height: 53px;
      }
    }

    .open_standardAccordion {}

    .added_templates_item {
      padding-bottom: 1rem;

      &:last-child {
        padding-bottom: 0;
      }
    }

    .icon_network {
      vertical-align: -2px;
      margin-right: 7px;
    }

    .icon_editTemplate_name {}
  }
}

.axContentTemplates_tab {
  .actions {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid #ECECEC;
    text-align: center;
  }
}