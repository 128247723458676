/*

<div class="single_discuss contentDiscuss_wrap" style="display:block;">
  <div class="innerWrap">
    <div class="inputWrap">
      <textarea rows="5" class="form_control"></textarea>
      <div class="actionWrap">
        <button type="button" class="sendComment_btn btn_primary btn">Send Comment</button>
      </div>
    </div>
    <ol class="messageList">
      <li class="unreadItem secondPerson_item msgItem clearfix">
        <div class="firstCol"><img src="/images/style_guide/sample_avatar.jpg" class="avatar"></div>
        <div class="secCol default_body_copy">
          <div class="msgHeader clearfix">
            <span class="username">Emily Sand</span>
            <div class="metadataWrap">
              <img src="/images/style_guide/icon_unread_indicator.svg" class="icon_unread">
              <span class="timestamp">12/07/2017 (12w ago)</span>
            </div>
          </div>
          <div class="msgBody">
            <p class="msg_txt">Lorem Ipsum is simply dummy text of the printing and <a href="#" title="" class="">typesetting</a> industry. Lorem Ipsum has been Lorem Ipsum has been the industry's standard dummy text.</p>
            <p class="msg_txt">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been Lorem Ipsum has been the industry's standard dummy text.</p>
            <p class="msg_txt">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been Lorem Ipsum has been the industry's standard dummy text.</p>
          </div>
        </div>
      </li>
      <li class="msgItem clearfix">
        <div class="firstCol"><img src="/images/style_guide/sample_avatar1.jpg" class="avatar"></div>
        <div class="secCol default_body_copy">
          <div class="msgHeader clearfix">
            <span class="username">Emily Sand</span>
            <div class="metadataWrap">
              <span class="timestamp">12/07/2017 (12w ago)</span>
            </div>
          </div>
          <div class="msgBody">
            <p class="msg_txt">Lorem Ipsum is simply <strong>dummy</strong> text of the printing and typesetting industry. Lorem Ipsum has been Lorem Ipsum has been the industry's standard dummy text.</p>
          </div>
        </div>
      </li>
      <li class="secondPerson_item msgItem clearfix">
        <div class="firstCol"><img src="/images/style_guide/sample_avatar.jpg" class="avatar"></div>
        <div class="secCol default_body_copy">
          <div class="msgHeader clearfix">
            <span class="username">Emily Sand</span>
            <div class="metadataWrap">
              <span class="timestamp">12/07/2017 (12w ago)</span>
            </div>
          </div>
          <div class="msgBody">
            <p class="msg_txt">Lorem Ipsum is simply dummy text of the <em>printing and typesetting</em> industry. Lorem Ipsum has been Lorem Ipsum has been the industry's standard dummy text.</p>
          </div>
        </div>
      </li>
    </ol>
  </div>
</div>

*/

.analyze_view {

  .single_discuss {
    padding-bottom: 1rem;
    min-width: 1200px;

    .innerWrap {
      max-width: 915px;
      margin: 0 auto;
    }
  }

  /*
  <div class="contentWrap">
    <div class="contentControls">
      <ul class="controls_list">
        <li class="controls_item">
          <button type="button" class="likeContent_btn no_shadow_focus btn controlBtn btn_icon"><img src="/images/style_guide/icon_like_btn.svg" class="likeIcon"></button>
        </li>
        <li class="controls_item">
          <button type="button" class="searchContent_btn no_shadow_focus btn controlBtn btn_icon"><img src="/images/style_guide/icon_search_white.svg" class="searchIcon"></button>
        </li>
      </ul>
    </div>
    <div class="collectionIndicator default_body_copy">
      <img src="/images/style_guide/icon_collection_white.svg" class="icon_collection"> <span class="collectionTxt">10</span>
    </div>
    <img src="/images/style_guide/sample_ig_content.jpg" class="content_img">
  </div>
  */

  .contentWrap {
    position: relative;
    cursor: pointer;

    &:hover {

      .content_img {
        opacity: .95;
      }
    }

    &:active {

      .content_img {
        opacity: 1;
      }
    }

    .contentControls {
      position: absolute;
      top: 0;
      right: 0;
    }

    .controls_list {}

    .controls_item {}

    .likeContent_btn {}

    .searchContent_btn {}

    .controlBtn {
      border: none;

      &:hover {
        background-color: rgba(255,255,255,.25);
      }

      &:focus {
        background: none;
      }

      &:active {
        background-color: $revfluence-blue;
      }
    }

    .likeIcon {}

    .searchIcon {}

    .collectionIndicator {
      position: absolute;
      right: .5rem;
      bottom: .5rem;
      background-color: #1A2430;
      background-color: rgba(26,36,48,.9);
      border-radius: 2px;
      padding: .15rem .5rem;
      z-index: 2;
    }

    .icon_collection {
      width: 12px;
    }

    .collectionTxt {
      font-weight: 500;
      color: white;
      font-size: .8rem;
      vertical-align: 1px;
    }

    .content_img {
      max-width: 100%;
      border-radius: 3px;
    }
  }

  .bestPerform_lessVisual_list {

    .bestPerform_item {
      background: #FFFFFF;
      box-shadow: 1px 1px 8px 0 #E1E4EA;
      border-radius: 3px;
      padding: 1.5rem 1.5rem 1rem;
      width: 48%;
      margin-right: 2%;
      margin-bottom: 1.5rem;
      float: left;
    }

    .leftCol, .rightCol {
      float: left;
    }

    .leftCol {
      width: 163px;
      margin-right: 2rem;
      cursor: pointer;

      &:hover {

        .contentImg {
          opacity: .95;
        }
      }

      .contentType {
        border-radius: 4px 4px 0 0;
        color: white;
        display: block;
        padding: .25rem 1rem;
        text-align: center;
        font-size: .7rem;
        font-weight: 400;
      }

      .type_fb_ads {
        background-image: linear-gradient(-90deg, #4CCF8B 0%, #4BB771 100%);
        border-radius: 3px 3px 0 0;
      }

      .type_website {
        background-image: linear-gradient(-90deg, #EF5891 0%, #DD0054 100%);
        border-radius: 3px 3px 0 0;
      }

      .type_influencer {
        background-image: linear-gradient(-90deg, #B18965 0%, #8E6846 100%);
        border-radius: 3px 3px 0 0;
      }

      .type_ownedSocial {
        background-image: linear-gradient(-90deg, #5AA7F9 0%, #0078F3 100%);
        border-radius: 3px 3px 0 0;
      }

      .contentImg {
        max-width: 100%;
        border-radius: 0 0 4px 4px;

        &:hover {
          opacity: .95;
        }

        &:active {
          opacity: 1;
        }
      }
    }

    .rightCol {
      width: calc(100% - 195px);

      .titleWrap {
        padding-bottom: .5rem;
        cursor: pointer;

        &:hover {
          color: $revfluence-blue-hover;
        }
      }

      .icon_fb {}

      .icon_ig {}

      .icon_yt {}

      .icon_blog {}

      .icon_network {
        margin-right: .25rem;
        width: 18px;
      }

      .contentTitle {
        font-weight: 600;
        display: inline-block;
        vertical-align: 1px;
        width: calc(100% - 26px);
        vertical-align: -1px;
      }

      .bodyWrap {}

      .detail_txt {
        font-size: .8rem;
      }

      .mainLabel, .mainDescrip {
        display: inline-block;
        font-size: .8rem;
      }

      .mainDescrip {
        vertical-align: -6px;
      }

      .contentGraph_wrap {
        padding-top: .65rem;
      }

      .breakdownMetric {
        padding-bottom: .4rem;
      }

      .breakdown_label {
        line-height: 1.05rem;
        font-weight: 300;
      }

      .mainStat_wrap {}

      .mainStat_number {
        color: #67A3EE;
        display: inline-block;
        font-weight: 600;
        font-size: 1.05rem;
        margin-right: .25rem;
      }

      .mainStat_label {
        color: #737C87;
      }

      .sample_emv_graph {
        max-width: 100%;
        display: block;
        width: 100%;
        padding-bottom: 2rem;
      }

      .breakdownStat_list {
        padding-bottom: .5rem;
      }

      .moreStats_btn {
        border-radius: 0;
        display: block;
        width: 100%;
        position: relative;
        padding: 2px 0;

        &:hover {
          background-color: #F9FAFC;
        }

        &:active {
          background: none;
        }

        .moreStats_btn_txt {
          text-transform: uppercase;
          letter-spacing: .25rem;
          color: #737C87;
          font-weight: 600;
          font-size: .65rem;
          display: block;
          padding: .15rem .75rem;
          background: white;
          width: 124px;
          margin: 0 auto;
          position: relative;
          z-index: 2;
        }

        .line {
          background: $lightgray-border;
          height: 1px;
          width: 100%;
          position: absolute;
          top: 16px;
        }
      }

      .moreStats_wrap {
        border-top: 1px solid $lightgray-border;
        padding-top: 1rem;

        .detail_txt {}

        .detail_label {
          font-weight: 600;
          display: block;
          padding-bottom: 1rem;
        }

        .barChart_wrap, .sample_pieChart_wrap, .sample_ageRange_chart, .sample_pieChart_wrap {
          max-width: 100%;
        }

        .barChart_wrap {
          padding-bottom: 1rem;
        }

        .sample_ageRange_chart {}

        .pieChart_wrap {}

        .sample_pieChart_wrap {}
      }
    }
  }

  .contentDiscuss_wrap {

    .default_body_copy {
      font-size: .9rem;
    }

    .inputWrap {
      margin-bottom: 2rem;
      border: 1px solid #E2E2E2;
      border-radius: 7px;

      .form_control {
        border-radius: 7px 7px 0 0;
      }

      .actionWrap {
        background: #F4F6FA;
        padding: .6rem 1rem .65rem;
        text-align: right;
        border-radius: 0 0 7px 7px;
      }

      .sendComment_btn {
        border-radius: 3px;
        font-size: .8rem;
      }
    }

    .messageList {

      .msgItem {
        padding: 1rem 1rem 1.2rem;
        background-color: #F6F6F6;
      }

      .firstCol, .secCol {
        float: left;
      }

      .firstCol {
        padding-right: 1rem;
        width: 51px;
      }

      .avatar {
        border-radius: 100px;
        width: 35px;
      }

      .secCol {
        width: calc(100% - 51px);
      }

      .msgHeader {
        padding-bottom: .25rem;

        .username {
          font-weight: 600;
          float: left;
        }

        .metadataWrap {
          float: right;
        }

        .icon_unread {
          margin-right: .5rem;
        }

        .timestamp {
          color: #969696;
          font-size: .75rem;
        }
      }

      .msgBody {

        .msg_txt {
          padding-bottom: 1rem;

          &:last-child {
            padding-bottom: 0;
          }
        }

        a {
          text-decoration: none;
          color: $revfluence-blue;

          &:hover {
            color: $revfluence-blue-hover;
          }
        }

        strong {
          font-weight: 600;
        }

        em {
          font-style: italic;
        }
      }

      .unreadItem {}

      .secondPerson_item {
        background: white;
      }
    }
  }

  /*
  CONTENT METADATA

  <div class="middleCol contentMetadata_wrap">
    <div class="headerWrap">
      <h1 class="headerTitle h3 truncate">Falling Hair Falling Hair Falling Hair Falling Hair Falling Hair Falling Hair Falling Hair Falling Hair Falling Hair</h1><button type="button" class="editName_btn btn no_shadow_focus btn_icon"><img src="/images/style_guide/icon_edit_newcolor.svg" class="icon_edit"></button>
    </div>
    <ul class="contentMetadata_list default_body_copy">
      <li class="contentMetadata_item">
        <span class="mainLabel">Owner:</span> <span class="mainDescrip">Beatrics</span>
      </li>
      <li class="contentMetadata_item">
        <span class="mainLabel">Artist:</span> <span class="mainDescrip">Hapatime</span>
      </li>
      <li class="contentMetadata_item">
        <span class="mainLabel">Created:</span> <span class="mainDescrip">June 7, 2016</span>
      </li>
      <li class="contentMetadata_item">
        <span class="mainLabel">Campaign:</span> <span class="mainDescrip"><a href="#" title class="standard_link">Love Your Melon Summer</a></span>
      </li>
      <li class="contentMetadata_item">
        <span class="mainLabel">Permissions:</span> <span class="mainDescrip">Full re-use rights <a href title class="standard_link">Edit</a></span>
      </li>
      <li class="contentMetadata_item">
        <span class="mainLabel">Resolution:</span> <span class="mainDescrip">512x512</span>
      </li>
    </ul>
    <div class="standardTags_container">
      <ul class="standardTags_list">
        <li class="addTag_item standardTags_item">
          <a class="icon_addTag_link">
            <img src="/images/style_guide/icon_plus_black.svg" class="icon_addTag img-loaded">
          </a>
        </li>

        <!--FIRST TAG ITEM-->
        <li class="firstContent_item standardTags_item">Add tags</li>
        <li class="standardTags_item automaticTag">
          hair
          <button type="button" class="remove_tag_btn btn no_shadow_focus">
            <img src="/images/style_guide/icon_close_gray.svg" class="icon_remove_tags img-loaded">
          </button>
        </li>
        <li class="standardTags_item automaticTag">
          red
          <button type="button" class="remove_tag_btn btn no_shadow_focus">
            <img src="/images/style_guide/icon_close_gray.svg" class="icon_remove_tags img-loaded">
          </button>
        </li>
        <li class="standardTags_item automaticTag">
          beauty
          <button type="button" class="remove_tag_btn btn no_shadow_focus">
            <img src="/images/style_guide/icon_close_gray.svg" class="icon_remove_tags img-loaded">
          </button>
        </li>
        <li class="standardTags_item automaticTag">
          hairstyle
          <button type="button" class="remove_tag_btn btn no_shadow_focus">
            <img src="/images/style_guide/icon_close_gray.svg" class="icon_remove_tags img-loaded">
          </button>
        </li>
        <li class="standardTags_item automaticTag">
          photography
          <button type="button" class="remove_tag_btn btn no_shadow_focus">
            <img src="/images/style_guide/icon_close_gray.svg" class="icon_remove_tags img-loaded">
          </button>
        </li>
        <li class="moreTags_item standardTags_item overflowTags_item">
          <button type="button" class="overflowTags_btn btn">
            <img src="/images/style_guide/icon_ellipses_black.svg" class="ellipses_icon img-loaded">
          </button>

          <!-- Overflow Tags Dropdown -->
          <ul class="overflowTags_dropdown dropdown_list_top dropdown_list">
            <li class="standardTags_item automaticTag">
              photography
              <button type="button" class="remove_tag_btn btn no_shadow_focus btn_icon">
                <img src="/images/style_guide/icon_close_gray.svg" class="icon_remove_tags img-loaded">
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  */
  .contentMetadata_wrap {
    width: calc(100% - 513px);
    padding: 0 1rem .5rem 0;

    .headerWrap {}

    .headerTitle {
      width: auto;
      max-width: calc(100% - 40px);
      font-size: 2rem;
      display: inline-block;
    }

    .editName_btn {
      vertical-align: 11px;
    }

    .icon_edit {}

    .contentMetadata_list {
      font-size: .8rem;
      line-height: 1.25rem;
      padding-top: .5rem;
    }

    .mainLabel {}

    .mainDescrip {}

    .standardTags_container {
      padding-top: .5rem;
      display: inline-block;

      .overflowTags_btn {
        border: none;
      }
    }

    .moreTags_item {
      padding: 0;
    }
  }

  .newMenu_item {
    padding: .6rem .5rem;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      background-color: #F4F6FA;
    }

    &:active {
      background-color: white;
    }
  }
}
