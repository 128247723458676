.managerRegistration_popup {

  .modal-dialog {
    padding: 2rem;

    @include media($break600) {
      margin: 0 auto;
      border: none;
      border-radius: 0;
      margin-top:4rem;
    }
  }

  .close_modal_btn {}

  .close_icon {}

  .managerRegistration_form {}
}
