.new_manage {

  #intercom-container {
    @include media($break500) {
      display: none;
    }
  }

  #intercom-frame {
    @include media($break500) {
      display: none;
    }
  }

  .intercom-lightweight-app {
    @include media($break500) {
      display: none;
    }
  }

  .intercom-app-container, .intercom-app, .intercom-lightweight-app {

    .intercom-launcher-discovery-frame {
      left: 19px !important;
    }

    .intercom-notifications-frame {
      left: 20px !important;
      right: auto !important;;
    }

    .intercom-notifications {
      left: 20px !important;
      right: auto !important;;
    }

    .intercom-launcher-badge-frame {
      left: 16px !important;
      right: auto !important;
    }

    .intercom-launcher-frame {
      left: 20px !important;
      right: auto !important;
    }

    .intercom-launcher {
      left: 5px !important;
      bottom: 5px !important;
    }

    .intercom-borderless-frame {
      left: 5px !important;
    }

    .intercom-messenger-frame {
      left: 20px !important;
      right: auto !important;
    }

    .intercom-note {
      left: 20px !important;
      right: auto !important;
    }

    .intercom-launcher-preview {
      bottom: 18px !important;
      left: 83px !important;
    }

    .intercom-launcher-button {
      bottom: 15px !important;
      left: 15px !important;
      border: 3px solid white !important;
    }

    .intercom-launcher-badge {
      top: -22px !important;
      left: 15px !important;
    }

    .intercom-launcher-preview-caret {

      &:after {
        border-right-color: white !important;
        border-left-color: transparent !important;
        left: -5% !important;
      }
    }

    .intercom-launcher-preview-caret {

      &:before {
        border-right-color: #ddd !important;
        border-left-color: transparent !important;
        right: 100% !important;
        left: -6.5% !important;
      }
    }

    .intercom-launcher-preview-close {
      right: -9px !important;
      left: initial !important;
    }
  }
}

.quickmatch, .browse_creators {

  .intercom-launcher-discovery-frame {
    left: auto !important;
    right: 19px !important;
  }
}
