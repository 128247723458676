.request-payment {

	.error_descrip {
		text-align: center;
		margin: 2em 2em 1em;
		display: block;
	}

	.request_payment_title {
		text-align: center;
	}

	.modal-dialog {

		@include media($break650) {
			width: 95%;
		}
	}

	.request_payment_input {
		width: 85px;
		margin: 0 auto 25px;
		display: block;
	}

	.request_amount_label {
		font-weight: 600;
		display: block;
		text-align: center;
		margin-bottom: .45em;
	}

	.pending_payment_notice {
		margin-bottom: 1.0em;
		margin-top: 1.0em;
		color: $label-red;
		text-align: center;
	}

	.pending_payment_link {
		cursor: pointer;
		color: $revfluence-blue;
		text-align: center;
	}

	.completed_content_label {
		display: block;
		font-weight: 600;
		text-align: center;
		margin-bottom: .45em;
	}

	.completed_content_container {
		margin-bottom: 35px;
	}

	.content_link_input {
		display: block;
		width: 290px;
		margin: 0 auto;

		@include media($break350) {
			width: 100%;
		}
	}

	.request_button {
		display: block;
		width: 150px;
		margin: 35px auto 0;
	}

	.request_payment_disclaimer {
		display: block;
		text-align: center;
		font-size: 1.25em;
		line-height: 1.7em;
		font-weight: 300;
		font-weight: 200;
		color: #1c1c1c;
		width: 315px;
		margin: 0 auto;

		@include media($break350) {
			width: 100%;
			margin: 0;
			padding: 0 1em;
		}
	}

	.modal-dialog {
		padding: 5em 0;
		@include media($break620) {
			width: 95%;
			margin: 2em auto;
		}
	}

	.modal-header {
		background: white;
		border-bottom: none;
	}

	.close-modal-link {
		position: absolute;
		top: 1em;
		right: 1em;
		background: url('../images/creator-payments/close_icon.svg') center no-repeat;
		width: 20px;
		height: 20px;
		text-indent: -9999px;

		&:hover {}
	}

	.visual-container {
		width: 203px;
		margin: 0 auto 2em;

		.payment-icon {
			display: inline-block;
			width: 75px;
		}

		.arrow-icon {
			display: inline-block;
			margin-right: 1em;
			margin-left: 1em;
			vertical-align: 3em;
			width: 27px;
		}

		.brand-avatar {
			display: inline-block;
			border-radius: 37.5px;
		}
	}

	.modal-title {
		@include title20;
		margin-bottom: .25em;
		font-weight: 200;
		color: $dark-text;
	}

	.current-balance {
		@include fontsize15;
		font-weight: 200;
		display: block;
		text-align: center;
		margin-bottom: 3em;
		color: $dark-text;
	}

	.payment-amount-container {
		width: 327px;
		margin: 0 auto 1em;
		@include media($break385) {
			width: 90%;
		}

		.payment-amount-input {
			display: inline-block;
			width: 200px;
			@include standard-input;
			margin-right: .5em;
			@include media($break385) {
				width: 100%;
				margin: 0 0 1em;
				display: block;
			}
		}
	}

	.revfluence-gaurantee {
		display: block;
		text-align: center;
		@include fontsize15;
		font-weight: 200;
		color: $dark-text;
	}

	.error-container {
		@include errors;
	}
}