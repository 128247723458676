.onboarding {

	.predemo_navbar {
		display: block;
	}
}

.predemo_navbar {
	background-color: white;
	display: none;
	padding-top: .5rem;

	.inner_container {
		max-width: 1400px;
		margin: 0 auto;
		overflow: hidden;
	}

	.back_container {
		float: left;
		margin-top: 1.3em;
		display: none;
		margin-right: 2em;
		@include media($break770) {
			display: block;
		}
		@include media($break300) {
			margin-right: .5em;
		}
	}

	.back_link {
		display: block;
		width: 35px;
		height: 26px;
		background: url('/images/demo_product/back_arrow.svg') center no-repeat;
		text-indent: -9999px;
	}

	.logo_container {
		width: 132px;
		margin-top: 1.5em;
		margin: 0 auto;
	}

	.revfluence_logo {
		width: 132px;
		height: 29px;
		display: block;
		background: url('/images/themes/aspireiq/aiq_logo.png') center no-repeat;
		background-size: cover;
		text-indent: -9999px;
	}

	.nav_links_container {
		width: 165px;
		float: right;
		margin-top: 1.5em;
	}

	.nav_links_list {
		text-align: right;
	}

	.nav_links_item {
		display: inline-block;
		color: #9f9f9f;
		font-size: 1.5em;
		line-height: 1.5em;
	}

	.nav_text_link {
		color: $dark-text;
		text-decoration: none;
		font-weight: 500;
		&:hover {
			color: #3b92fb;
		}
	}

	.text_link_active {
		font-weight: 600;
		color: $dark-text;
		&:hover {
			color: $dark-text;
		}
	}
}
