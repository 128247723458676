.creator_mediaKit {
  margin-bottom: 1.25rem;
  border-radius: 6px;

  .uploadKit {
    background-color: $ultra_light_blue;
    padding: 1rem 1.5rem 1.25rem;
    border-radius: 6px;
  }

  .icon_container {
    float: left;
    margin-right: 1rem;
    width: 50px;
  }

  .icon_uploadKit {
    max-width: 100%;
  }

  .uploadTxt_descrip {
    margin-bottom: .75rem;
  }

  .uploadTxt_container {
    float: left;
    width: calc(100% - 66px);
  }

  /* UPLOAD */
  .uploadKit_container {
    margin-top: 1rem;

    .uploadKit_btn {
      font-size: 1rem;
    }

    .icon_upload_cta {
      vertical-align: -4px;
      margin-right: .2rem;
    }
  }

  /* UPLOADING */
  .uploadingKit_container {
    margin-top: 1rem;

    .icon_uploadKit {
      width: 30px;
      display: block;
      margin: 9px auto 0;
    }

    .uploadTxt_descrip {
      margin-bottom: 0;
    }
  }


  /* UPLOADED */
  .uploadedKit_container {
    padding: 1rem 1.5rem 1.25rem;
    border: 1px solid $lightgray-border;
    border-radius: 6px;

    .uploadedKit {
      border: 1px solid $lightgray-border;
      border-radius: 6px;
      position: relative;
      padding: .5rem;
    }

    .btn_preview_mediaKit {
      margin-right: .25rem;
    }

    .icon_edit, .icon_remove {
      width: 15px;
    }

    .media_kit_link {
      text-decoration: none;
    }

    .btn_edit_mediaKit {}

    .actions_container {
      position: absolute;
      right: 5px;
      top: 7px;
    }

    .icon_edit, .icon_remove {
      width: 16px;
    }

    .btn_remove_mediaKit {
      padding: 8px;
    }

    .btn_preview_mediaKit {
      padding: 0;
      border: none;
    }
  }
}
