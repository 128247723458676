@import './common';

.ax_project {
  .ax_project_page_title {
    @include aspirex_title;
  }
  .ax_project_page_subtitle {
    @include aspirex_subtitle;
  }
  .ax_project_content {
    padding-top: 16px;
    max-width: 700px;
    margin: 0 auto;
  }
}
