.brand_profile {
	background-color: $ultraLight_gray;

	.welcome_nav {
		z-index: 9000;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 50px;
		background-color: white;
		border-bottom: 1px solid $lightgray-border;
	}
}

.brand_profile_header {
	margin: 50px 0 1rem;
	border-bottom: 1px solid $lightgray-border;
	background-color: white;

	@include media($break1000) {
		margin-bottom: 0;
	}

	.inner_container {
		position: relative;
		max-width: 1350px;
		margin: 0 auto;
	}

	.back_btn {
		position: absolute;
		left: .5rem;
		top: 1.45rem;
		padding: .5rem;

		@include media($break580) {
			left: .25rem;
			top: 1.15rem;
		}

		@include media($break400) {
			top: .9rem;
		}

		.back_icon {
			width: 30px;

			@include media($break580) {
				width: 25px;
			}
		}
	}

	.edit_link {
		position: absolute;
		right: 1.5rem;
		top: 23px;
		text-align: center;
		text-decoration: none;
		display: none;

		@include media($break875) {
			right: 1rem;
			top: 23px;
		}

		@include media($break515) {
			right: 15px;
		}

		.edit_icon, .edit_link_txt {
			display: block;
		}

		.edit_icon {
			width: 25px;
			margin: 0 auto .25rem;
		}

		.edit_link_txt {
			color: #A1A1A1;
			display: block;

			@include media($break875) {
				display: none;
			}
		}
	}

	.brand_name_container {

		.brandName_inner_container {
			margin: 0 auto;
			max-width: 1400px;
			padding: 1rem 4rem;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;

			@include media($break580) {
				padding: 1rem 3rem;
			}
		}

		.brand_logo {
			margin-right: .6rem;
			width: 60px;
			height: 60px;
			display: inline-block;

			@include media($break875) {
				width: 50px;
				height: 50px;
				margin-right: .5rem;
			}

			@include media($break580) {
				width: 40px;
				height: 40px;
			}

			@include media($break400) {
				width: 35px;
				height: 35px;
			}
		}

		.brand_name {
			font-size: 2.6rem;
			display: inline-block;

			@include media($break875) {
				font-size: 2.25rem;
			}

			@include media($break585) {
				font-size: 1.9rem;
				width: calc(100% - 61px);
				text-align: left;
			}

			@include media($break400) {
				font-size: 1.5rem;
			}
		}
	}

	.brand_reviews_container {
		text-align: center;
		padding: .5rem 0;

		.star_reviews_list {
			width: 111px;
			margin: 0 auto;
		}

		.based_on_indicator {
			font-size: .85rem;
		}
	}

	.externalLinks_container {
		border-top: 1px solid $lightgray-border;;
		padding: .75em;
		text-align: center;

		.externalLink_txt {
			text-decoration: none;
			font-size: 1.4em;
			color: $revfluence_blue;
			font-weight: 300;
			line-height: 1.5rem;

			&:first-child {
				margin-right: .5rem;
			}

			&:last-child {
				margin-left: .5rem;
			}

			&:hover {
				color: $revfluence-blue-hover;
			}
		}

		.divider_thing {
			color: #888d91;
			padding: 0 .15rem;

			@include media($break325) {
				display: none;
			}
		}
	}

	.self_serve_experiment_banner {
		color: white;
		background-color: #ef5682;
		font-weight: bold;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		height: 48px;
		font-size: 14px;
	}
}

.invitedYou_wrapper {
	max-width: 1000px;
	margin: 1.5rem auto;
	text-align: center;

	.bold_text {
		font-weight: 600;
	}

	.invitedAvatar_wrapper {
		width: 65px;
		position: relative;
		margin: 0 auto .5rem;
		height: 50px;
	}

	.inviterAvatar {
		margin-right: .75rem;
		border-radius: 100px;
		width: 50px;
	}

	.liked_icon {
		position: absolute;
		top: 0;
		right: 0;
		width: 25px;
	}

	.invitedYou_title {
		font-weight: 600;
		margin-bottom: 0;
	}

	.invitedYou_txt {
		margin-bottom: 0;
	}
}
