
@mixin standard-textarea {
	background-color: $lightgray-bg;
	color: $dark-text;
	border: 1px solid $lightgray-border;;
	padding: .5em;
	@include standard-radius;
	margin: 0;
	@include fontsize15;
	-webkit-appearance: none;
	@include standard-radius;

	&:focus {
    outline: none;
    background-color: $lightgray-bg-hover;
    border: 1px solid $revfluence-blue;
    &.standard-input-error {
      @include standard-input-error;
    }
  }
}

@mixin standard-input {
	@include standard-radius;
	padding: .6em;
	border: 1px solid $lightgray-border;
	background-color: $lightgray-bg;
	@include fontsize15;
	font-weight: 300;
	color: $dark-text;
	float: none;
	@include standard-radius;
	-webkit-appearance: none;

    &.standard-input-error {
        @include standard-input-error;
    }

    &:focus {
        outline: none;
        border: 1px solid $revfluence-blue;
        background-color: $lightgray-bg-hover;
        &.standard-input-error {
          @include standard-input-error;
        }
    }
}

@mixin dark-label {
	@include fontsize15;
	margin-bottom: .5em;
	display: block;
	color: white;
}

@mixin dark-input {
	padding: .6em;
	border: none;
	background-color: white;
	@include fontsize15;
	font-weight: 500;
	color: $dark-text;
	float: none;
	-webkit-appearance: none;
  @include standard-radius;

  &.standard-input-error {
      @include standard-input-error;
  }

  &:focus {
    &.standard-input-error {
      @include standard-input-error;
    }
  }
}

@mixin light-textInput-medium {
	@include small-radius;
	padding: .53em .75em;
	border: 1px solid $lightgray-border;
	color: $dark-text;
	@include fontsize15;
	font-weight: 300;
	-webkit-appearance: none;

	&:focus {
		border: 1px solid $revfluence-blue;
		background-color: $system-lightBlue;
		outline: none;
	}
}

@mixin errors {
	background-color: $error;
	padding: 1em;
	@include small-radius;
	width: 90%;
	margin: 0 auto 3em;
  	display: none;

	.error-description {
		@include fontsize15;
		color: white;
		text-align: center;
	}

	.error-button {
		display: block;
		width: 150px;
		margin: 0 auto;
	}

	.error-list {
		text-align: center;
		margin-top: 1em;
		border-top: 1px solid $error-border;
		padding-top: 1em;

		li {
			color: white;
			@include fontsize15;
		}
	}
}

@mixin standard-label {
	@include fontsize16;
	margin-bottom: .35em;
	display: block;
	color: $dark-text;
}

@mixin standard-hint {
	@include fontsize13;
	color: $lightgray-text;
	margin-top: .5em;
}

@mixin standard-input-error {
	border: 1px solid $label-red;
}

@mixin standard-error-message {
	background-color: $label-red;
	color: white;
	@include standard-radius;
	padding: .25em 1em;
	@include fontsize15;
}

@mixin form-error-message {
  color: $label-red;
  @include standard-radius;
  padding: 1em 1em;
  @include fontsize15;
  text-align: center;
  width: 100%;
}

@mixin standard-dropdown {
	padding: .75em;
	border: 1px solid $lightgray-border;
	background-color: $lightgray-bg;
	@include fontsize15;
	font-weight: 300;
	color: $dark-text;
	float: none;
	@include standard-radius;
	-webkit-appearance: none;

	&:focus {
		outline: none;
		border: 1px solid $revfluence-blue;
	}
}

/* SELECT DROPDOWNS */

.select_dropdown {
  position: relative;
  display: block;
  margin-top: 0.5em;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  cursor: pointer;
}

select {
  /* Make sure the select is wider than the container so we can clip the arrow */
  width: 110%;
  max-width: 110%;
  min-width: 110%;
  /* Remove select styling */
  appearance: none;
  -webkit-appearance: none;
  /* Ugly Firefox way of doing it */
  -moz-appearance: window;
  text-indent: 0.01px;
  text-overflow: "";
  /* Magic font size number to prevent iOS text zoom */
  background:none;
  border: none;
  color: $dark-text;
  @include fontsize13;
  font-weight: 300;
  outline: none;
  /* Padding works surpringly well */
  padding: .5em 19% .4em .5em;
  margin:.2em;
  cursor: pointer;
}

/* This hides native dropdown button arrow in IE */
select::-ms-expand {
  display: none;
}

 /* Custom arrow - could be an image, SVG, icon font, etc. */
.select_dropdown:after {
	width: 15px;
	height: 15px;
	content: url(../images/form_elements/dropdown_arrow_icon.svg);
	background:  center no-repeat;
	background-size: 100%;
	font-size: .7em;
	padding:0;
	position: absolute;
	right: 2em;
	top: 2.1em;
	bottom: .3em;
	z-index: 1;
	/* This hack makes the select behind the arrow clickable in some browsers */
pointer-events:none;
}

/* Hover style - tricky because we're clipping the overflow */
.select_dropdown:hover {
}

/* Focus style */
select:focus {
}

/* This hides focus around selected option in FF */
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

 /* These are just demo button-y styles, style as you like */
.select_button {
  border: 1px solid $lightgray-border;
  @include standard-radius;
  background: white; /* Old browsers */
  cursor: pointer;
}

@mixin small_input {
	width: 150px;
}

@mixin ui-slider {

	.ui-slider-horizontal {
		height: .5em;
	}

	.ui-widget-content {
		border: none;
		background: #e5e5e5;
	}

	.ui-widget-header {
		background: $revfluence-blue;
		border: none;
	}

	.ui-slider-handle {
		width: 2em;
		height: 2em;
		cursor: grab;
		outline: none;
		top: -.7em
	}

	.ui-state-default {
		background: white;
		border: 2px solid $revfluence-blue;
	}

	.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
		border-top-left-radius: 45px;
	}

	.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
		border-bottom-right-radius: 45px;
	}

	.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
		border-bottom-left-radius: 45px;
	}

	.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
		border-top-right-radius: 45px;
	}
}

.slider_container {
	@include ui-slider;
}

/* Select */

.select {
  position: relative;
  display: inline-block;
  color: #555;
}
.select select {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: .5rem 1.25rem .5rem 1rem;
  line-height: 1.5;
  color: $dark-text;
  font-weight: 600;
  font-size: 1.6em;
  background-color: white;
  border: 1px solid #e4e4e4;
  border-radius: .25rem;
  cursor: pointer;
  outline: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
/* Undo the Firefox inner focus ring */
.select select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
/* Dropdown arrow */
.select:after {
  position: absolute;
  top: 50%;
  right: 0;
  display: inline-block;
  content: "";
  width: 0;
  height: 0;
  margin-top: -.15rem;
  pointer-events: none;
  border-top: .35rem solid;
  border-right: .35rem solid transparent;
  border-bottom: .35rem solid transparent;
  border-left: .35rem solid transparent;
}

/* Hover state */
/* Uncomment if you need it, but be aware of the sticky iOS states.
.select select:hover {
  background-color: #ddd;
}
*/

/* Focus */
.select select:focus {
  box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #0074d9;
}

/* Active/open */
.select select:active {
  color: #fff;
  background-color: #0074d9;
}

/* Hide the arrow in IE10 and up */
.select select::-ms-expand {
  display: none;
}

/* Media query to target Firefox only */
@-moz-document url-prefix() {
  /* Firefox hack to hide the arrow */
  .select select {
    text-indent: 0.01px;
    text-overflow: '';
    padding-right: 1rem;
  }

  /* <option> elements inherit styles from <select>, so reset them. */
  .select option {
    background-color: white;
  }
}

/* IE9 hack to hide the arrow */
@media screen and (min-width:0\0) {
  .select select {
    z-index: 1;
    padding: .5rem 1.5rem .5rem 1rem;
  }
  .select:after {
    z-index: 5;
  }
  .select:before {
    position: absolute;
    top: 0;
    right: 1rem;
    bottom: 0;
    z-index: 2;
    content: "";
    display: block;
    width: 1.5rem;
    background-color: #eee;
  }
  .select select:hover,
  .select select:focus,
  .select select:active {
    color: #555;
    background-color: #eee;
  }
}
