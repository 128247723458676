.add_posts_mobile {
	display: none;

	@include media($break875) {
		display: block;
	}
}

.add_posts_container {
	float: left;
	width: 41.2%;
	margin-top: 15px;

	@include media($break875) {
		display: none;
	}

	.youtube_icon {
		margin-top: 0 !important;
		vertical-align: 1px !important;
	}

	.add_types_title {
		margin-bottom: 15px;
	}

	.choose_qty_container {
		float: right;
		width: 81px;

		.btn {
			background: none;
			padding: 0;
		}

		.btn, .current_qty_number {
			float: left;
		}

		.subtract_qty_btn {}

		.subtract_qty_icon {}

		.current_qty_number {
			padding: .14rem .5rem 0;
		}

		.add_qty_btn {}

		.add_qty_icon {}

		.qty_btn {

			&:active, &:focus {
				box-shadow: none;
			}
		}
	}

	.account_item {
		padding: 12px 15px;
		border: 1px solid $lightgray-border;
		border-radius: 4px;
		margin-bottom: 20px;
	}

	.manageTemplate_btn {
		font-size: .9rem;
		margin: .5rem 0 0;
		font-weight: 400;
		padding: .25rem 0;
	}

	.additional_imgs_container {
		padding: 12px 15px;
		border: 1px solid $lightgray-border;
		border-radius: 4px;

		.label_container {
			float: left;
			width: calc(100% - 89px);
		}

		.additional_imgs_icon {
			margin-right: 8px;
			vertical-align: -4px;
		}

		.additional_imgs_txt {
			font-weight: 600;
		}

		.choose_qty_container {}

		.added_guidelines_list {
			padding: .5rem 1rem;
			border: 1px solid $lightgray-border;
			list-style-type: disc;
			list-style-position: inside;
			border-radius: 6px;
			font-size: .8rem;
			margin-top: .5rem;
		}
	}

	.account_header {
		margin-bottom: .8rem;
		font-size: 1rem;
	}

	.content_type_wrapper {
		border: 1px solid $lightgray-border;
		border-radius: 4px;
		padding: 10px;
		margin-bottom: 1rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.titleAmount_wrapper {
		font-size: 1rem;
		padding-bottom: .4rem;
	}

	.totalAmount {
		float: right;
	}

	.label_wrapper, .content_type_list {
		font-size: .85rem;
	}

	.label_wrapper {
		padding-bottom: .7rem;
	}

	.typeHeader_label {
		font-weight: 600;
		margin-right: .25rem;
	}

	.addPost_accountName_container {
		float: left;
		margin-right: 15px;
		max-width: calc(100% - 246px);

		@include media($break365) {
			float: none;
			margin-right: 0;
			max-width: 100%;
		}
	}

	.network_icon {
		float: left;
		margin-right: .5rem;
		width: 20px;
	}

	.account_username {
		font-weight: 600;
		float: left;
		margin-right: .75rem;
		max-width: calc(100% - 271px);
		white-space: nowrap;
	}

	.addPost_accountStats_container {
		float: left;

		@include media($break365) {
			float: none;
			max-width: 100%;
			margin-top: 5px;
		}
	}

	.subscriber_amount {
		display: inline-block;
		margin-right: 10px;
	}

	.views_amount {
		display: inline-block;
	}

	.content_type_list {}

	.content_type_item {
		padding: 5px 10px;
		border-radius: 4px;
		overflow: hidden;

		&:last-child {
			margin-bottom: 0;
		}

		&:nth-child(odd) {
			background-color: $ultraLight_gray;
		}

		.added_guidelines_list {
			padding: .5rem 1rem;
			border: 1px solid $lightgray-border;
			list-style-type: disc;
			list-style-position: inside;
			border-radius: 6px;
			font-size: .8rem;
			margin-top: .5rem;
		}

		.opt_out_text {
			color: red;
		}
	}

	.content_type_name {
		width: calc(100% - 81px);
		display: inline-block;
	}

	.additional_images_type_item {
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.manageTemplates_link {

		@include media($break415) {
			display: block;
		}
	}

	.type_package_container {
		float: left;
		width: calc(100% - 81px);
	}

	.post_price {
		display: block;
	}

	.percent_average {
		display: block;
		font-size: .85em;
	}

	.below_average {
		color: green;
	}

	.above_average {
		color: red;
	}

	.just_above_average {
		color: #AFAF0D;
	}

	.add_package_button {
		color: $revfluence-blue;
		display: block;
		float: right;
		width: 45px;
		text-align: right;
		border: none;
		background: none;
		font-size: 1em;
		cursor: pointer;
	}

	.added_indicator {
		color: $dark-text;
		display: block;
		float: right;
		width: 45px;
		text-align: right;
		font-size: 1em !important;
		font-style: italic;
	}

	.close_btn {
		display: none;
	}
}

.add_posts_popup {
	display: block !important;

	.add_posts_container {
		float: none;
		width: 100%;
		margin-top: 0;
		padding: 20px 14px;
		display: block !important;

		@include media($break875) {
			display: block;
		}
	}

	.close_btn {
		background: url('/images/collab_terms_tool/close_btn_icon.svg') center no-repeat;
		width: 19px;
		height: 19px;
		position: absolute;
		padding: 0;
		margin: 0;
		border: none;
		top: 14px;
		right: 14px;
		display: block;

		&:active {
			box-shadow: none;
		}
	}

	.add_types_title {
		text-align: center;
		margin-bottom: 18px;
		font-size: 2.6em;

		strong {
			font-weight: 600;
		}

		@include media($break335) {
			font-size: 2.25em;
		}
	}

	.mobile_added_post {
		border: 1px solid $revfluence-blue;
		background-color: #F8FBFF;
	}

	.cancel_addPosts_btn, .save_btn {
		display: block;
		width: 150px;
		margin: 0 auto;
		font-size: 1rem;
	}

	.add_package_button {
		font-size: 1em !important;
		padding: 0;
	}
}
