/*** APPLICATIONS CLOSED

  <!--APPLICATIONS CLOSED-->
  <section class="applicationClosed_container">
    <img src="/images/style_guide/icon_cancel_dark.svg" class="icon_cancel_dark">
    <span class="applicationClosed_txt default_body_copy">Applications for this campaign are now closed</span>
  </section>
***/
.applicationClosed_container {
  background-color: #F7F7F7;
  border-radius: 6px;
  padding: .75rem;

  .icon_cancel_dark {
    margin-right: .25rem;
    vertical-align: -6px;
  }

  .applicationClosed_txt {
    font-weight: 600;
  }
}
