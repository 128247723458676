.connect_switcherHeader {
  position: absolute;
  left: 18px;
  top: -5px;
  width: calc(100% - 740px);
  z-index: 2;

  @include media($break1000) {
    position: relative;
    left: auto;
    width: auto;
  }

  @include media($break845) {
    padding: 0 1rem 1rem;
  }

  @include media($break375) {
    padding: 0 .15rem 1rem;
  }

  .select_wrapper {
    max-width: 700px;
    float: left;

    @include media($break1300) {
      width: 330px;
    }

    .admin_btn {
      padding: .6rem .75rem;
      vertical-align: -2px;
    }

    .sampleTitle {
      font-size: 1.75rem;
      padding: 0 .5rem 0 .5rem;
      vertical-align: -5px;
    }

    .favSelect_dropdown_btn {
      border: none;
      max-width: 100%;
      padding: .65rem 2.3rem .65rem 1rem;
      position: relative;
      margin-bottom: 0;
      margin-right: .25rem;
      background-image: none;

      .btn_txt {
        font-size: 1.4rem;
        font-weight: 600;

        @include media($break1130) {
          font-size: 1.5rem;
        }
      }
    }

    .dropdown_list {
      width: 350px;
      left: 0;
      font-size: .75rem;
      top: 44px;
      z-index: 5999;

      @include media($break1000) {
        width: auto;
      }

      &:before, &:after {
        left: 11px;
      }
    }

    .dropdown_list_list {
      max-height: 300px;
      overflow: auto;
      scrollbar-face-color: #AFAFAF;
      scrollbar-track-color: none;

      @include media($break630) {
        background-color: #F8F8F8;
        padding: 0;
      }

      ::-webkit-scrollbar {
        width:  6px;
      }

      ::-webkit-scrollbar-thumb {
        background: #D8D8D8;
        border-radius: 100px;
      }

      ::-webkit-scrollbar-track {
        background: none;
      }
    }

    .icon_dropdownIndicator {
      position: absolute;
      right: 16px;
      top: 18px;
      width: 12px;
    }

    .memberAmount {}

    .dropdown_list {}

    .editList_btn {
      vertical-align: -6px;
      padding: .7rem .75rem;
    }

    .icon_editFav_list {
      width: 16px;
    }
  }

  .favSelect_editMode {
    max-width: 700px;
    float: left;
    width: 100%;
    padding-top: .96rem;

    .btn {
      border-radius: 4px;
      padding: 8px 14px;
    }

    .editName_input, .saveEdit_name_btn, .cancelEdit_name_btn, .deleteList_wrapper {
      float: left;
      margin-right: .5rem;
      font-size: .8rem;
    }

    .editName_input {
      width: 100%;
      max-width: 290px;
    }

    .saveEdit_Name_btn {}

    .cancelEdit_Name_btn {}

    .deleteList_wrapper {
      margin-right: 0;

      .dropdown_list {
        width: 230px;
        left: 0;

        &:before, &:after {
          left: 7px;
        }
      }

      .confirmQ {
        font-weight: 600;
        padding: .25em 1em;
        display: block;
      }
    }
  }

  .deleteList_btn {
    font-weight: 400;
    border: none;
    background: none;
    padding: 8px 14px;
    color: $error_red;
    margin-bottom: 0;

    &:hover {
      color: $error_red_hover;
    }
  }

  .createCV_form {
    display: inline-block;
    vertical-align: -5px;

    .btn_link {
      padding: .5rem;

      @include media($break1300) {
        padding-top: 0;
      }
    }
  }
}
