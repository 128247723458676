/* Starter HTMl

<ol class="stageBar fourWide default_body_copy">
  <li class="active_stageBar_item stageBar_item">
    <span class="stageNumber">1</span>
    <span class="stageLabel">Invite Creators</span>
  </li>
  <li class="stageBar_item">
    <span class="stageNumber">1</span>
    <span class="stageLabel">Invite Creators</span>
  </li>
  <li class="stageBar_item">
    <span class="stageNumber">1</span>
    <span class="stageLabel">Invite Creators</span>
  </li>
  <li class="stageBar_item">
    <span class="stageNumber">1</span>
    <span class="stageLabel">Invite Creators</span>
  </li>
</ol>

*/

.stageBar {
  height: 43px;
  padding-right: 20px;
  position: relative;
  z-index: 0;

  .stageBar_item {
    float: left;
		position: relative;
		z-index: 3;

    &:first-child {
      border-radius: 5px 0 0 5px;

      .bg_wrapper {
        padding-left: 12px;
        border-radius: 5px 0 0 5px;
      }
    }

    &:nth-child(2) {
      z-index: 1;
    }

    &:nth-child(3) {
      z-index: 0;
    }

    &:nth-child(4) {
      z-index: -1;
    }

    &:before {
      content: "";
  		width: 29px;
  		height: 29px;
  		border-right: 1px solid #dddddd;
  		border-bottom: 1px solid #cbcbcb;
  		background-color: #F3F3F3;
  		display: block;
  		position: absolute;
  		top: 6px;
  		right: -16px;
  		z-index: -1;
	    transform: rotate(-45deg);
    }
  }

  .bg_wrapper {
    width: 100%;
		height: 43px;
		padding: 0 0 0 25px;
		color: #6B6B6B;
		text-align: center;
		line-height: 43px;
		white-space: nowrap;
		border: 1px solid #E2E2E2;
		border-top-color: #E2E2E2;
		border-right: 0;
		background-color: #F3F3F3;
		float: left;
		position: relative;
    box-sizing: border-box;

    &:before {
      content: "";
  		width: 29px;
  		height: 29px;
  		border-right: 1px solid #dddddd;
  		border-bottom: 1px solid #cbcbcb;
  		background-color: #F3F3F3;
  		display: block;
  		position: absolute;
  		top: 6px;
  		right: -16px;
  		z-index: -1;
	    transform: rotate(-45deg);
    }
  }

  .stageNumber {
    color: white;
    background-color: #D4D4D4;
    width: 20px;
    height: 20px;
    font-size: .85rem;
    border-radius: 100px;
    display: inline-block;
    text-align: center;
    line-height: 1.31rem;
    margin-right: .09rem;
  }

  .stageLabel {
    color: #6B6B6B;
  }

  .active_stageBar_item {
    .bg_wrapper {
      background-color: white;

      &:before {
        background-color: white;
      }
    }

    .stageNumber {
      background-color: $revfluence-blue;
    }

    .stageLabel {
      font-weight: 600;
      color: $dark-text;
    }
  }
}

.stageBar_twoWide {

  li {
    width: 50%;
  }
}

.stageBar_threeWide {

  li {
    width: 33.33%;
  }
}

.stageBar_fourWide {

  li {
    width: 25%;
  }
}
