.uploadContent_modal {

  .modal-dialog {
    padding: .65rem 1rem .75rem;

    @include media($break630) {
      width: 96%;
    }
  }

  ::-webkit-scrollbar {
  	width:  6px;

  	&:horizontal {
  		height: 6px;
  	}
  }

  ::-webkit-scrollbar-thumb {
  	background: #D8D8D8;
  	border-radius: 100px;
  }

  ::-webkit-scrollbar-track {
  	background: none;
  }

  .close_modal_btn {
    padding: 10px;
    position: absolute;
    right: .25rem;
    top: .25rem;
    cursor: pointer;

    &:hover {
      background-color: $hoverBtn_bg
    }
  }

  .remove_modal_icon {
    width: 15px;
  }

  .uploadContent_title {
    padding-bottom: 1rem;
    text-align: center;
    padding-bottom: .75rem;
    font-size: 2rem;
    padding-top: 12px;

    @include media($break320) {
      font-size: 1.5rem;
    }
  }

  .uploadContent_dragDrop {
    width: 188px;
    margin: .6rem auto 2rem;
  }

  .upload_content_list {
    padding-bottom: 1rem;
    position: relative;
    max-height: 268px;
    overflow-y: auto;
  }

  .upload_content_item {
    position: relative;
    padding: 5px 36px 5px 5px;
    height: 59px;

    &:hover {
      background-color: $ultraLight_gray;
    }
  }

  .thumbnailContainer {
    width: 50px;
    margin-right: .75rem;
    float: left;
    height: 50px;
    border: 1px solid $lightgray-border;
    border-radius: 4px;
    overflow: hidden;
  }

  .video_thumbnail {
    margin-top: 10px;
  }

  .uploadContent_thumbnail {
    max-width: 100%;
  }

  .uploadContent_name {
    float: left;
    width: calc(100% - 62px);
    padding-top: 14px;
  }

  .uploadContent_error {
    display: inline-block;
    margin-top: .25rem;
  }

  .remove_uploadContent_btn {
    background: none;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 12px;
  }

  .uploadContent_icon {
    width: 14px;
  }

  .uploadContent_actions_container {
    text-align: center;
    padding-bottom: 1rem;
    padding-top: 1rem;

    .okay_btn {
      margin-right: .25rem;
    }

    .cancel_btn {}
  }
}
