.brand_profile_footer {
	background-color: white;
	box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.01);
	border: 1px solid rgb(242, 241, 241);
	@include media($break715) {
		margin-bottom: -1px;
	}

	.inner_container {
		max-width: 480px;
		margin: 0 auto;
		text-align: center;
		padding: 4em 0;
		@include media($break500) {
			padding: 4em 2em;
		}
	}

	.brand_logo {
		display: block;
		width: 35px;
		height: 35px;
		margin: 0 auto 2em;
	}

	.brand_name {
		font-size: 1.4em;
		color: $dark-text;
		font-weight: 600;
		display: block;
		line-height: 1.85em;
	}

	.brand_descrip {
		font-size: 1.4em;
		color: #8f8484;
		font-weight: 300;
		display: block;
		line-height: 1.85em;
		white-space: pre-wrap;
	}


}