.ax_project_navigation {
  display: grid;
  grid-template-columns: 2fr minmax(554px, 1fr) 2fr;
  margin: 16px 120px 0px;

  .ax_company_logo {
    display: flex;
    align-items: center;
    margin-right: 16px;

    .company_logo {
      width: 42px;
      height: 42px;
      object-fit: cover;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 16px;
    }
    .company_info {
      .company {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        white-space: nowrap;
      }
      .campaign {
        font-size: 14px;
        line-height: 22px;
        white-space: nowrap;
      }
    }
  }

  .navigation {
    flex: 1;
    display: flex;
    align-items: center;

    .nav_item {
      height: 48px;
      display: flex;
      align-items: center;
      margin-right: 40px;
      color: #1890FF;
      font-size: 14px;
      position: relative;
      cursor: pointer;
      opacity: 0.5;

      &.selected {
        opacity: 1;

        &::after {
          content: ' ';
          display: block;
          width: 100%;
          height: 2px;
          background: #1890FF;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 10px;

    &.terms {
      background-image: url(/images/aspirex/asset_content.svg);
    }
    &.upload {
      background-image: url(/images/aspirex/asset_upload.svg);
    }
  }

  @media screen and (max-width: 768px) {
    margin: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .ax_company_logo {
      margin-right: 0;
    }
  }
}

.hide_project_navigation .ax_project_navigation {
  display: none;
}
