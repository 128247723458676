.creator_list_posts {

  .username {
    display: block;
    padding-bottom: .25rem;
  }

  .socialNetwork_icon {
    margin-right: .25rem !important;
    vertical-align: -3px !important;
  }

  .offer_icon {
    width: 13px !important;
  }
}
