.widget_height_stretch {
	height: calc(100vh - 150px);
}

.height_percentage {
	height: 100%;
}

.new_manage {
	scrollbar-face-color: #AFAFAF;
	scrollbar-track-color: none;
	background-color: white;

	::-webkit-scrollbar {
		width:  6px;

		&:horizontal {
			height: 6px;
		}
	}

	::-webkit-scrollbar-thumb {
		background: #D8D8D8;
		border-radius: 100px;
	}

	::-webkit-scrollbar-track {
		background: none;
	}

	.subnav {
		padding: 0 13px;
		z-index: 6000;
		position: fixed;
		top: 50px;
		left: 0;
		display: flex;
		flex-direction: row;
		align-items: center;
    	width: 100%;
		height: 40px;
		font-size: 15px;
		color: $aiq_black;
		background-color: $aiq_lightblue_bg;

		.navItem {
			margin-right: 18px;

			a {
				display: flex;
				flex-direction: row;
				align-items: center;
				text-decoration: none;
				color: #1a1818;
				will-change: color;
				transition: color	.1s ease-out;

				&:visited {
					color: #1a1818;
				}

				&.active, &:hover {
					color: #3996E0
				}

				&.active {
					text-shadow: .5px 0 0 #3996E0;
				}

				&:active {
					color: #4FA7EE;
				}

				.content_review_count {
					margin-left: 6px;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
					width: 26px;
					height: 19px;
					user-select: none;
					border-radius: 10px;
					background-color: #3996e0;
					color: #eff5f9;
					font-size: 12px;
					font-weight: bold;
				}
			}
		}
	}

	.campaign_navbar {
		top: 50px;
		border-bottom: 1px solid $aiq_border !important;
	}

	.standalone {
		.relationship_main_content {
			height: 100%;

			&.hasBanner {
				height: calc(100% - 80px);
			}
		}
		.inbox_view {
			padding-top: 0px;
		}
	}

	.mainNav_inner {

		.revfluence_logo {
			margin-left: 0;
			margin-right: 0;
		}

		.large_nav_links {}
	}

	.inbox_view, .relationship_view {
		margin: 0 auto;
		padding: 50px .5rem 0;
	}

	.hasSubNav, .hasCampaign_navbar {
		.subnav {
			top: 50px;
		}

		.inbox_view, .relationship_view {
			padding-top: 90px;
		}
	}

	.hasSubNav.hasCampaign_navbar {
		.subnav {
			top: 90px;
		}

		.campaign_navbar {
			top: 50px;
		}

		.inbox_view, .relationship_view {
			padding-top: 130px;
		}
	}

	.relationship_view {
		height: 100%;
		overflow: hidden;
		padding: 0 2%;

		@include media($break630) {
			background-color: #F8F8F8;
			padding: 0;
			height: auto;
			margin: 67px auto 0;
		}

		@include media($break400) {
			margin: 75px auto 0;
		}

		.inbox_filters {

			@include media($break845) {
				display: none;
			}
		}
	}

	.inbox_view {
		height: 100%;

		.default_body_copy {
			font-size: 1.45em;
		}
	}

  .new_creator_portal_hint {
    margin-top: .5rem;
  }

	.height_percentage {}

	.h3 {
		font-size: 3.15em;
	}

	.h4 {
		font-size: 2.45em;
	}

	.labels_list {

		.label_item {
			border: 1px solid $lightgray-border;
			border-radius: 100px;
			padding: 5px;
			display: inline-block;
			margin-right: .5em;

			&:last-child {
				margin-right: 0;
			}
		}

		.label_text {
			font-size: 1.2em;
			padding: 4px 3px 4px 3px;
			font-weight: 300;
		}

		.remove_button {
			background: url('/images/new_manage/remove_label_icon.svg') center no-repeat;
			width: 10px;
			height: 10px;
			padding: 0;
			display: inline-block;

			&:active, &:focus {
				box-shadow: none;
			}
		}
	}

	.likedIndicator {
		border-radius: 100px;
		border: 1px solid $lightgray-border;
		padding: 5px;
		background-color: white;
		width: 155px;
		text-align: center;
		display: inline-block;
	}

	.likedIndicator_icon {
		display: inline-block;
		margin-right: 5px;
		width: 13px;
		height: 13px;
	}

	.likedIndicator_text {
		font-size: 1.2em;
		display: inline-block;
		vertical-align: 1px;
		font-weight: 400;
	}

	.creatorStats_container {
		padding: 0;
		height: 18px;
		overflow: hidden;

		@include media($break845) {
			height: 34px;
		}

		.igStats_container {}

		.ytStats_container {
			padding-top: 2px;
		}

		.networkStats_container {
			float: left;
			margin-right: 1rem;

			@include media($break845) {
				margin-bottom: .35rem;
			}
		}

		.instagram_icon {}

		.youtube_icon {}

		.network_icon {
			width: 13px;
		}

		.networkStats_container {}

		.networkStats_txt {
			font-size: .8rem;
			font-weight: 600;
		}
	}

	.collabHeader_container {
		display: none;
		position: relative;
		padding: 0 3rem .65rem;
		z-index: 2;
		box-shadow: 0 1px 0 rgba(0,0,0,.05);

		@include media($break630) {
			display: block;
			box-shadow: 0 1px 1px rgba(0,0,0,.1);
			z-index: 4;
			padding: 4px 3rem;
			height: 45px;
		}

		.backBtn_charles {
			position: absolute;
			left: 0;
			top: -5px;

			@include media($break630) {
				top: 0;
				left: 4px;
			}
		}

		.back_icon {
			width: 20px;
		}

		.tool_icon {
			margin-right: .5rem;
			width: 35px;
			vertical-align: -9px;

			@include media($break630) {
				vertical-align: -6px;
				width: 26px;
			}
		}

		.collabTitle {
			text-align: center;

			@include media($break630) {
				/* header 6*/
				font-size: 20px;
				font-weight: bold;
				font-style: normal;
				font-stretch: normal;
				line-height: normal;
				letter-spacing: 0.3px;
				padding-top: 6px;
			}
		}
	}
}
