.social_account_browse {

	.real_header {
		background-color: white;
		box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.01);
		border: 1px solid rgb(242, 241, 241);
		margin-top: 4.94em;
		position: relative;

		.extra_filters_popup {
			position: absolute;
			top: 82px;
			right: 324px;
			width: 400px;
			background-color: white;
			box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.1);
			z-index: $zindexTutorial;
			display: none;
			@include media($break800) {
				top: 135px;
				right: 24px;
			}

			@include media($break445) {
				width: 90%;
			}

			&:after {
				content: '';
				position: absolute;
				border-style: solid;
				border-width: 0 13px 15px;
				border-color: #FFFFFF transparent;
				display: block;
				width: 0;
				z-index: 1;
				top: -15px;
				right: 20px;
			}
		}

		.categories_container {
			overflow: hidden;
			padding: 2em;
		}

		.categories_list {
			width: 150px;
			float: left;
			margin-right: 3em;
		}

		.second_list {
			@include media($break445) {
				margin-top: 1.75em;
			}
		}

		.category_item {
			margin-bottom: 2em;
			&:last-child {
				margin-bottom: 0;
			}

			a {
				text-decoration: none;
				display: block;
			}
		}

		.category_icon {
			display: inline-block;
			margin-right: 1em;
			width: 20px;
			height: 20px;
			vertical-align: -4px;
			opacity: .3;
		}

		.category_item_active {

			.category_icon {
				opacity: 1;
			}

			.category_text {
				font-weight: 600;
				color: $dark-text;
			}
		}

		.womens_fashion_icon {
			background: url('/images/social_account_browse/womens_fashion_icon.svg') center no-repeat;
		}

		.beauty_icon {
			background: url('/images/social_account_browse/beauty_icon.svg') center no-repeat;
		}

		.fitness_icon {
			background: url('/images/social_account_browse/fitness_icon.svg') center no-repeat;
		}

		.food_icon {
			background: url('/images/social_account_browse/food_icon.svg') center no-repeat;
		}

		.lifestyle_icon {
			background: url('/images/social_account_browse/lifestyle_icon.svg') center no-repeat;
		}

		.gaming_icon {
			background: url('/images/social_account_browse/gaming_icon.svg') center no-repeat;
		}

		.moms_icon {
			background: url('/images/social_account_browse/moms_icon.svg') center no-repeat;
		}

		.mens_fashion_icon {
			background: url('/images/social_account_browse/mens_fashion.svg') center no-repeat;
		}

		.category_text {
			display: inline-block;
			font-size: 1.3em;
			line-height: 1.5em;
			color: #bdbcbc;
			font-weight: 300;
		}

		.followers_filter_container {
			border-top: 1px solid #f1f1f1;
			padding: 1.5em 2em;
		}

		.slider_container {
			@include ui-slider;
		}

		.followers_title {
			font-size: 	1.3em;
			color: $dark-text;
			line-height: 1.5em;
			font-weight: 500;
			margin-bottom: 1em;
		}

		.engagements_filter_container {
			border-top: 1px solid #f1f1f1;
			padding: 2em;
		}

		.engagements_title {
			font-size: 	1.3em;
			color: $dark-text;
			line-height: 1.5em;
			font-weight: 500;
			margin-bottom: 1em;
		}

		.slider_description_container {
			padding: 0em 2.5em;

			.title {
				@include fontsize14;
				@include standard-bold;
			}

			.slider_description {
				margin-bottom: .5em;
				@include fontsize14;
				color: $dark-text;
			}
		}

		.countries_container, .language_container {
			border-bottom: none;

			.country_title_container {
				margin-bottom: 1em;
				overflow: hidden;

				.section_title {
					display: inline-block;
					margin-right: .25em;
					margin-bottom: 0;
				}

				.remove_countries_container {
					display: inline-block;
					float: right;

					.remove_countries_link {
						@include blue-link;
						display: inline-block;
						@include fontsize12;
					}
				}
			}

			.selected_countries_container {
				margin-bottom: 1em;

				.selected_countries_list {
					@include non_colored_labels;
					display: inline-block;
				}

				.showing_all_indication {
					color: $lightgray-text;
					@include fontsize12;
					display: none;
				}
			}

			.country_input_container {
				position: relative;
			}

			.country_input {
				@include standard-input;
				width: 100%;
			}

			.suggestions_container {
				display: none;
				position: absolute;
				top: 47px;
				left: 0;
				border: 1px solid $lightgray-border;
				z-index: $zindexTutorial;
				background-color: white;
				width: 100%;
				height: 175px;
				overflow-y: auto;
				@include standard-radius-bottom;
			}

			.suggestions_list {
				padding: 1em 0;

				li {
					@include fontsize14;
					color: $dark-text;
					padding: .5em 1.5em;
					cursor: pointer;

					&:hover {
						background-color: $revfluence_blue;
						color: white;
					}
				}
			}
		}

		.filter_button {
			width: 100%;
			display: block;
			text-align: center;
		}


		.inner_container {
			max-width: 1300px;
			margin: 4em auto 3em;
			overflow: hidden;

			@include media($break1315) {
				max-width: 1200px;
			}

			@include media($break810) {
				margin: 4em auto 0;
			}
		}

		.main_title_container {
			float: left;
			width: 255px;

			@include media($break1200) {
				padding-left: 2em;
			}

			@include media($break810) {
				width: 100%;
				float: none;
				text-align: center;
				margin-bottom: 3em;
			}
		}

		.main_title {
			font-size: 3em;
			color: $dark-text;
			font-weight: 300;
			display: inline-block;
			margin-right: .5em;
			vertical-align: -0.15em;
		}

		.sort_tools_container {
			float: right;
			width: 538px;

			@include media($break1200) {
				padding-right: 2em;
			}

			@include media($break810) {
				width: 100%;
				float: none;
				border-top: 1px solid #f1f1f1;
				padding-right: 0;
			}
		}

		.network_selector {
			display: inline-block;
			margin-right: 3em;
			vertical-align: -5px;
			@include media($break810) {
				padding: 11px;
				border-right: 1px solid #F1F1F1;
				margin-right: 0;
				vertical-align: 0;
			}
		}

		.network_text {
			font-size: 1.5em;
			line-height: 1.5em;
			font-weight: 300;
			margin-right: 1em;
			vertical-align: 5px;
			@include media($break550) {
				font-size: 1.3em;
			}
		}

		.networks_list {
			display: inline-block;
			vertical-align: -2px;
		}

		.networks_item {
			display: inline-block;
			margin-right: 2em;

			&:last-child {
				margin-right: 0;
			}
		}

		.network_button {
			display: inline-block;
			width: 22px;
			height: 22px;
			cursor: pointer;
			@include media($break550) {
				width: 15px;
				height: 15px;
			}
		}

		.instagram_button {
			vertical-align: 1px;
		}

		.youtube_icon {
			fill: #cfcfcf;
			width: 25px;
			height: 25px;
			@include media($break550) {
				width: 15px;
				height: 18px;
			}
		}

		.youtube_icon_active {

			path {
				fill: #e52d00;
			}
		}

		.instagram_icon {
			fill: #cfcfcf;
		}

		.instagram_icon_active {

			path {
				fill: #3f729b;
			}
		}

		.extra_filters {
			display: inline-block;
			margin-right: 3em;
			@include media($break810) {
				padding: 0;
			}
			@include media($break550) {
				margin-right: 0;
			}
		}

		.extra_filters_button {
			color: $dark-text;
			font-size: 1.5em;
			line-height: 1.5em;
			text-decoration: none;
			font-weight: 300;
			@include media($break810) {
				border-right: 1px solid #F1F1F1;
				padding: 14px;
				vertical-align: 5px;
			}
			@include media($break550) {
				font-size: 1.3em;
				vertical-align: 3px;
				padding: 14px 4px 14px 7px;
			}
		}

		.extra-filters_text {}

		.extra_filters_icon {
			background: url('/images/social_account_browse/down_arrow.svg') center no-repeat;
			width: 24px;
			height: 7px;
			display: inline-block;
		}

		.ui-slider-handle {
			cursor: move;
			border: 1px solid #c1c1c1 !important;
		}

		.ui-slider-tip {
			border: 1px solid #d8d8d8;
		}

		.search_container {
			display: inline-block;
			@include media($break810) {
				float: right;
				padding: .6em 0 0 0;
				margin-right: 1em;
			}
			@include media($break550) {
				padding: .5em 0 0 0;
			}
			@include media($break400) {
				float: none;
				display: block;
				padding: 1em;
				margin-right: 0;
				border-top: 1px solid #F1F1F1;
			}
		}

		.search_input {
			border: 1px solid #eae5e6;
			border-radius: 45px;
			font-size: 1.4em;
			color: $dark-text;
			padding: .5em 2.75em .5em 1em;
			background: url('/images/social_account_browse/search_icon.svg') 93% center no-repeat;
			background-size: 16px 16px;
			margin-bottom: 1em;
			&:focus {
				border: 1px solid #2582f4;
				outline: none;
			}

			@include media($break810) {
				margin-bottom: 0;
			}

			@include media($break550) {
				width: 160px;
				font-size: 1.3em;
			}
			@include media($break400) {
				width: 100%;
			}
		}

	}

	.create_account_container {
		position: relative;
		text-align: center;
		float: left;
		width: 100%;
		margin-top: -29em;
		z-index: 10;
		height: 300px;
		background: url('/images/demo_product/create_account_gradient.png') repeat-x;
		background-position: left center;

		.inner_container {
			padding: 3em 0;
			margin-top: 15em;
		}

		.main_title {
			font-size: 2em;
			color: $dark-text;
			font-weight: 200;
			line-height: 1.5em;
			margin-bottom: .5em;

			strong {
				font-weight: 600;
			}
		}

		.create_account_link {
			color: $revfluence-blue;
			display: block;
			font-size: 2em;
			font-weight: 500;
			line-height: 1.5em;
			text-decoration: none;

			&:hover {
				color: $revfluence-blue-hover;
			}
		}
	}
}
