.addSnap_modal {

  .modal-dialog {
    padding: 0 2rem 2rem;

    @include media($break415) {
      padding: 0 1.5rem 1.5rem;
    }
  }

  .close_modal_btn {}

  .add_snap_graphic {
    width: 70px;
    display: block;
    margin: 14px auto 0;
  }

  .howto_addSnap_title {
    text-align: center;
    padding: .25rem 0 1.25rem;

    @include media($break650) {
      font-size: 1.8rem;
    }

    @include media($break375) {
      font-size: 1.45rem;
      padding: .25rem 0 .75rem;
    }
  }

  .loading_icon_container {
    padding-bottom: 1.5rem;
  }

  .loading_icon {
    display: block;
    margin: 0 auto;
    width: 75px;
  }

  /* Upload Container */
  .addSnap_uploadMain_container {}

  /* Mobile - Verify correct image Container */
  .mobile_verifySnap_main_container {

    .dragDrop_wrapper {
      margin-bottom: 2rem;
    }

    .continue_btn {
      display: block;
      width: 130px;
      margin: 0 auto;
    }

    .icon_chevronRight_white {
      margin-left: 4px;
      vertical-align: -1px;
    }
  }

  strong {
    font-weight: 600;
  }

  .addSnap_dragDrop {
    float: left;
    width: 200px;
    margin-right: .5rem;
  }

  .drag_drop_container {
    margin: 0 auto;
    display: block;
    width: 190px;

    .dropped_slot_item {
      height: 100%;
    }

    .dropped_img_wrapper {
      height: 100%;
    }
  }

  .contentImg_form {}

  .active_dragdrop_indicator {}

  .active_dragdrop_icon {}

  .addSnap_txt_container {
    float: left;
    width: calc(100% - 208px);

    @include media($break515) {
      width: 100%;
    }

    p {
      margin-bottom: 1rem;
    }
  }

  .continue_btn {

    @include media($break515) {
      display: none;
    }
  }

  .mobileUpload_media_btn {
    display: none;
    color: $dark-text;
    width: 100%;

    @include media($break515) {
      display: block;
    }

    .uploadSnap_media_graphic {
      width: 32px;
    }

    .mobileUpload_media_txt {
      vertical-align: 8px;
      font-size: 1rem;
    }
  }

  .chevron_icon {
    vertical-align: -2px;
    margin-left: 4px;
  }

  .addSnap_dragDrop {

    @include media($break515) {
      display: none;
    }
  }
}
