.view_content_modal {

  .close_btn_container {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 9999;
  }

  .close_btn {
    background: none;
    padding: 7px;
    border-radius: 6px;

    &:hover {
      background-color: rgba(255,255,255,.1);
    }

    &:active {
      background-color: $revfluence-blue;
    }
  }

  .close_icon {
    display: block;
    width: 20px;
  }

  .nav_controls_container {
    position: absolute;
    top: 50%;
    max-width: 720px;
    margin-top: -21px;
    margin-left: -360px;
    left: 50%;
    width: 100%;
    z-index: 9999;

    @include media($break800) {
      top: 1rem;
      left: 1rem;
      max-width: 130px;
      margin-top: 0;
      margin-left: 0;
    }

    .amount_indicator {
      display: none;

      @include media($break414) {
        display: block;
        text-align: center;
        width: 150px;
        font-style: italic;
        position: absolute;
        bottom: 25px;
        left: 50%;
        margin-left: -75px;
        font-size: 1.15rem;
      }
    }

    .mobile_close_btn {
      display: none;

      @include media($break414) {
        display: block;
        position: absolute;
        width: 45px;
        left: 50%;
        margin-left: -22.5px;
        background: none;
        padding: 0;
        bottom: -25px;
      }
    }

    .nav_btn {
      background: none;
      padding: 7px;
      position: absolute;
      border: none;
      border-radius: 6px;

      &:hover {
        background-color: rgba(255,255,255,.1);
      }

      &:active {
        background-color: $revfluence-blue;
      }
    }

    .nav_left {
      left: 0;
    }

    .nav_right {
      right: 0;
    }

    .nav_icon {
      width: 30px;

      @include media($break545) {
        width: 30px;
      }
    }

    .mobile_nav_icon {
      display: none;
    }
  }

    .modal-dialog {
      background: none;
      border: none;
      box-shadow: none;
      margin: 15px auto 15px;
      max-width: 450px;

      @include media($break830) {
        width: 100%;
      }
    }

    .instagram_dialog {
      max-width: 650px;

      .engagements_container {
        display: none;
      }
    }

    .youtube_dialog {
      max-width: 750px;

      .video_container {
        margin-bottom: -2rem;

        @include media($break745) {
          margin-bottom: -1.5rem;
        }

        @include media($break350) {
          margin-bottom: -1rem;
        }
      }

      .nav_controls_container {

        @include media($break350) {
          top: 31%;
        }
      }
    }

    .video_content_container {

      video {
        max-width: 100%;
      }
    }

    .content_container {
      margin: 0 auto;
      position: relative;
      border: none;

      .content_img {
        display: block;
        max-width: 100%;
        width: 100%;
      }

      .mobile_close_icon {
        display: none;
      }
    }

    .instagram_content_container {
      max-width: 475px;

      @include media($break630) {
        max-width: 75%;
      }

      @include media($break414) {
        width: 100%;
        max-width: 100%;
      }
    }

    .youtube_content_container {
      max-width: 560px;

      @include media($break705) {
        width: 100%;
        max-width: 73%;
      }

      @include media($break414) {
        max-width: 100%;
        width: 100%;
      }
    }

    .snapchat_content_container {
      max-width: 300px;

      @include media($break500) {
        width: auto;
        width: 72%;
      }

      .snapchat_vid, .snapchat_img {
        max-width: 100%;
      }
    }

    .engagements_container {
      padding: 1rem 0;

      @include media($break414) {
        padding: 1rem;
        border-bottom: 1px solid $lightgray-border;
      }

      .engagements_list {}

      .likes_item {}

      .comments_item {}

      .views_item {}

      .engagements_item {
        float: left;
        margin-right: 2rem;
      }

      .engagements_icon {
        width: 30px;
      }

      .likes_icon {
        vertical-align: -6px;
        margin-right: .35rem;
        width: 28px;
      }

      .comments_icon {
        width: 37px;
        vertical-align: -11px;
        margin-right: .2rem;
      }

      .views_icon {
        width: 30px;
        vertical-align: -2px;
        margin-right: .4rem;
      }

      .engagements_txt {
        color: white;
      }
    }

    .amount_of_content_indicator {
      position: absolute;
      bottom: 0;
      width: 100%;

      .content_indicator_list {
        text-align: center;
        padding: 1rem 0;
      }

      .content_indicator_item {
        display: inline-block;
        margin-right: .5rem;

        &:last-child {
          margin-right: 0;
        }
      }

      .content_indicator_icon {
        opacity: .5;
      }

      .active_indicator_icon {
        opacity: 1;
      }
    }

    .media_img {
      max-width: 100%;
    }
  }
