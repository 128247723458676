.creator-sign-up {
	background: #1a1a1a url('../images/creator_signup/background-image.jpg') center center no-repeat fixed;
	padding-top: 5em;
	background-size: cover;
	@include media($break530) {
		padding-top: 4em;
	}
	@include media($break400) {
		padding-top: 2em;
	}

	.revfluence-logo {
		a {
			display: block;
			width: 199px;
			height: 43px;
			background: url('../images/themes/aspireiq/aiq_logo.png');
			margin: 0 auto 4em;
			text-indent: -9999px;
		}
	}

	.header {
		margin-bottom: 7em;
		@include media($break730) {
			padding: 0 2em;
		}
		@include media($break400) {
			padding: 0 1em;
			margin-bottom: 5em;
		}

		.flashNotice {
			margin-top: 1.25rem;
		}
	}

	.verifySignup {
		width: 95%;
		margin: 0 auto;

		.verify_title {
			@include title30;
			text-align: center;
			color: white;
			margin-bottom: .5em;
			font-weight: 300;

			@include media($break735) {
				@include title22;
				font-weight: 600;
			}

			@include media($break590) {
				margin-top: 2em;
			}
		}

		.verify_description {
			color: white;
			@include fontsize16;
			text-align: center;

            a {
                @include blue-link;
            }
		}
	}

	.intro_title {
		text-align: center;
		margin-bottom: .25em;
	}

	.main {
		overflow: hidden;
		width: 450px;
		margin: 0 auto 3em;
		@include media($break825) {
			width: 425px;
		}
		@include media($break460) {
			width: 95%;
		}
	}

	.goLogin_container {
		border-top: 1px solid #4C4C4C;
		padding-top: 1em;
		padding-bottom: 3em;
		@include media($break825) {
			width: 425px;
		}
		@include media($break460) {
			width: 95%;
		}

		.goLogin {
			@include fontsize16;
			color: white;
			display: block;
			text-align: center;
		}

		a {
			@include blue-link;
		}
	}

	  .error_container {
	    @include errors;
	    width: 100%;

	    .error_descrip {
	    	color: white;
	    	@include fontsize16;
	    	text-align: center;
	    }

	    a {
	    	color: white;
	    	text-decoration: underline;

	    	&:hover {
	    		text-decoration: none;
			}
		}
	  }


	.notice_container {
		background-color: $aspireiq_blue_light;
		width: 100%;
		padding: 1em;
		@include small-radius;
		width: 90%;
		margin: 0 auto 3em;

		.descrip {
			color: $dark-text;
			@include fontsize16;
			text-align: center;
		}

		a {
			color: white;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	  .form-error-span {
	  	background-color: $error;
	  	color: white;
	  	padding: .5em;
	  	@include standard-radius;
	  	display: inline-block;
	  	margin-bottom: 1em;
	  }

		.email_signup {

			.signUp_form {

				label {
					font-weight: 700;
					padding-bottom: .75rem;
					display: block;
				}

				fieldset {
					margin-bottom: 2em;
				}
			}

			.name_input {
				@include fontsize18;
				display: block;
				width: 100%;
				padding: .85em;

				&:focus {
					outline: none;
				}
			}

			.email_input {
				@include fontsize18;
				display: block;
				width: 100%;
				padding: .85em;

				&:focus {
					outline: none;
				}
			}

			.password_input {
				@include fontsize18;
				display: block;
				width: 100%;
				padding: .85em;

				&:focus {
					outline: none;
				}
			}

			.email_login_button {
				display: block;
				width: 100%;
				margin-bottom: 2em;
				padding: 1.13em;
				border-radius: 4px;
				@include uppercase14;
			}

			.forgot_password_link {
				@include blue-link;
				@include fontsize15;
				display: block;
				text-align: center;
				width: 173px;
				margin: 0 auto;
			}
		}

		.instagram_signup_button {
			@include standard-radius;
			background-color: $instagram-color;
			width: 325px;
	    margin-bottom: 1em;
	    padding: 1.5em 2em;
	    border: none;
	    -webkit-appearance: none;
	    cursor: pointer;
	    display: block;
	    margin: 0 auto 2em;
	    text-align: center;
	    @include standard-transition;
	    @include media($break355) {
	    	width: 95%;
	    }

	    &:hover {
	    	background-color: $instagram-color-hover;
	    }

	    &:focus {
	    	background-color: $instagram-color-hover;
	    	outline: none;
	    }

	    .instagram_icon {
	    	display: inline-block;
	    	margin-right: 1em;

	    	path {
	    		fill: white;
	    	}
	    }

	    .button_text {
	    	color: white;
	    	@include uppercase14;
	    	display: inline-block;
	    	vertical-align: .4em;
	    }
		}

		.youtube_signup_button {
			width: 325px;
			background-color: $youtube-color;
			@include standard-radius;
	    padding: 1.5em 2em;
	    border: none;
	    -webkit-appearance: none;
	    cursor: pointer;
	    display: block;
	    margin: 0 auto;
	    text-align: center;
	    @include standard-transition;
	    @include media($break355) {
	    	width: 95%;
	    }

	    &:hover {
	    	background-color: $youtube-color-hover;
	    }

	    &:focus {
	    	background-color: $youtube-color-hover;
	    	outline: none;
	    }

	    .youtube_icon {
	    	display: inline-block;
	    	margin-right: 1em;

	    	path {
	    		fill: white;
	    	}
	    }

	    .youtube_text {
	    	color: white;
	    	@include uppercase14;
	    	display: inline-block;
	    	vertical-align: .4em;
	    }
		}
	}

  .include-email {
    text-align: inherit;
    width: 75%;
    background: #fff url('../images/creator_signup/middle-divider.png') 49.57% top repeat-y;
    @include media($break960) {
      background: #fff;
    }
  }

	.email-signup {
		display: inline-block;
		width: 39.5%;
		margin: 0 5%;
		@include media($break960) {
			display: block;
			margin: 0 auto 1em;
			width: 50%;
		}
		@include media($break845) {
			width: 90%;
		}

		.username-input {
			@include standard-input;
			display: block;
			width: 100%;
			margin-bottom: .5em;
			@include media($break960) {
				display: block;
			}
		}

		.email-input {
			@include standard-input;
			display: block;
			width: 100%;
			margin-bottom: .5em;
			@include media($break960) {
				display: block;
			}
		}

		.password-input {
			@include standard-input;
			display: block;
			width: 100%;
			margin-bottom: .5em;
		}

		.email-signup-button {
			display: block;
			width: 100%;
			margin-bottom: 1em;
			border-radius: 4px;
		}

	  .forgot-password {
      @include fontsize15;
      text-align: center;
      @include blue-link;
      display: block;
	  }
	}

  .form-error-span {
    line-height: 2.0em;
    font-size: 1.2em;
    color: red;
  }

  .forgot-success {
    width: 60%;
    padding: 4em;

    p {
      @include fontsize15;
      display: none;
      width: 100%;
    }
  }
