.aspireiq_theme {

  .share_profile_banner {
    background-color: $aiq_nude;
    display: none !important;
  }

  .creator_response {

    .attachment_field {

      .edit-label {
        color: $aiq_darkblue;

        &:hover, &:focus {
          color: $aiq_darkblue_hover;
        }
      }
    }
  }

  .socialAccounts_callout {

    .addAccounts_btn_list {

      .newNetwork_indicator {}
    }

    .manageAccounts_btn {
      color: $aiq_darkblue;
    }
  }

  .campaign_article {

    .compensation_section {

      .available {
        background: url('/images/themes/aspireiq/icon_checkmark_circle_pink_solid.svg') center no-repeat;
        background-size: 100%;
      }
    }

    .campaign_actions {

      .apply_icon {
        background: url('/images/themes/aspireiq/message_icon_white.svg') center no-repeat;
        background-size: 100%;
      }
    }
  }
}
