.newAnalyze_body {

  .standard_table2_wrapper {
    padding: 0 2.25rem .35rem;
    min-width: 1200px;
    overflow: visible;

    .exampleContent_popup {
      display: none;
    }
  }

  .breakdownWrap {

    .noData_wrap {
      background-color: $ultraLight_gray;
      padding: 1rem;
      border-radius: 6px;
      text-align: center;
      margin: 0 2rem 1rem;

      .noData_txt {
        color: #929292;
        font-weight: 400;
      }
    }

    .pagination_wrapper {
      text-align: center;
      padding: 1rem 0 3rem;
    }
  }

  .breakdownToolbar {
    padding: 0 2.25rem 0;

    .dropdown_btn2 {
      margin-bottom: 0;
    }

    .btn_txt {
      font-size: .8rem;
    }

    .btn_default {
      border-radius: 6px;
    }

    .leftGrp {}

    .breakdownDecrip, .displayType_dropdown {
      display: inline-block;
    }

    .breakdownDescrip {
      margin-right: .5rem;
      display: inline;
    }

    .displayType_dropdown {

      .dropdown_btn2 {
        padding: .32rem 1.75rem .3rem .5rem;
      }

      .icon_dropdown {
        top: 13px;
      }

      .dropdown_list {
        top: 32px;
        right: -9px;
      }
    }

    .rightGrp {
      float: right;
    }

    .exportBtn, .colSettings_dropdown {
      display: inline-block;
    }

    .exportBtn, .editCols_btn {
      font-size: .8rem;
    }

    .exportBtn, .editCols_btn {
      padding: 6px 12px;
    }

    .exportBtn {
      margin-right: .5rem;
    }

    .colSettings_dropdown {

      .settings_btn {
        margin-bottom: 0;
      }
    }
  }

  .colSettings_dropdown {

    .dropdown_accord {
      width: 190px;
      top: 32px;

      &:before, &:after {
        left: 152px;
      }
    }
  }

  .breakdownTable {
    width: 100%;
    margin: 1rem 0;

    thead {

      th {
        font-weight: 600;
        color: #7C858C;
        cursor: pointer;
        background: none;
        font-size: .7rem;
        text-transform: uppercase;
        letter-spacing: .1rem;

        &:hover {
          color: $revfluence-blue;
          background-color: #F6F8FB;
        }

        &:active {
          background: none;
        }
      }
    }

    tbody {

      tr {

        &:nth-child(even) {
          background-color: #F6F8FB;
        }

        &:hover {
          background-color: #EAECF4;
        }
      }
    }

    td, th {
      text-align: left;
      padding: .5rem;
      max-width: 205px;
    }

    th {
      padding: .5rem 1.65rem .5rem .5rem;
    }

    td {
      font-size: .85rem !important;
      white-space: nowrap;
      text-overflow: ellipsis;
      vertical-align: middle;
    }

    .img_thumbnail {
      width: 32px;
      border-radius: 3px;
      vertical-align: -12px;
    }

    .img_content {
      vertical-align: middle;
    }

    .icon_sortDirection {
      width: 13px;
      vertical-align: 0;
    }

    a {
      color: $revfluence-blue;
      text-decoration: none;
      font-size: .85rem !important;
      font-weight: 300 !important;

      &:hover {
        color: $revfluence-blue-hover;
        text-decoration: underline;
      }
    }

    .sponsoredChannel_item {
      display: inline-block;
      margin-right: .5rem;

      &:last-child {
        margin-right: 0;
      }
    }

    button, a {
      cursor: pointer;
    }

    .accountName_txt {
      font-weight: 600;
    }

    .lastComm_dropdown_wrap {

      .lastComm_drop_btn {
        border: none;
        padding: 0;
        color: $revfluence-blue;
        margin: 0;
        background: none;

        &:hover {
          color: $revfluence-blue-hover;
          text-decoration: underline;
          background: none;
        }
      }

      .btn_txt {
        font-size: .85rem !important;
      }

      .dropdown_list {
        width: 305px;
        left: 0;
        top: 35px;
        padding: .75rem;
        font-size: .75rem;
        line-height: 1.15rem;

        &:before, &:after {
          left: 8px;
        }
      }

      .contextMenu_dropdown {
        width: 192px;
        padding: .5rem;

        .contextMenu_item {

          a {
            border-radius: 4px;
            color: $dark-text;

            &:hover {
              background-color: #F0F4F8;
              color: $dark-text;
              text-decoration: none;
            }

            &:focus {
              background: none;
            }

            &:active {
              background :none
            }
          }

          .icon_contextMenu {
            margin-right: .5rem;
            width: 15px;
          }
        }
      }

      .lastComm_msg {
        white-space: normal;
        word-wrap: break-word;
      }

      .lastComment_dropdown {
        max-height: 355px;
      }

      .lastComm_drop_header {
        font-weight: 600;
        vertical-align: -5px;
        margin-right: .15rem;
        padding-bottom: .65rem;
      }

      .icon_chats {
        vertical-align: -5px;
        margin-right: .15rem;
        width: 19px;
      }

      .commentInput_wrap {}

      .form_control {
        margin-bottom: .75rem;
      }

      .commentInput_btn {
        border-radius: 3px;
        margin: 0 0 .25rem .25rem;
      }

      .commentInput_btn, .form_control {
        font-size: .75rem;
      }

      .lastCommentWrap {
        padding-top: .85rem;
      }

      .lastCommWrap {}

      .lastComm_header {
        padding-bottom: .35rem;
      }

      .lastComm_avatar {
        width: 26px;
        border-radius: 100px;
        display: inline-block;
        margin-right: .25rem;
        vertical-align: -7px;
      }

      .lastComm_name_txt {
        font-weight: 600;
      }

      .lastComm_msg {}
    }
  }
}
