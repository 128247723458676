.creator_profile {

  .intro_video_container {
    margin-bottom: -15px;

    @include media($break875) {
      display: none !important;
    }

    .video_container {

      iframe {
        height: 92%;
      }
    }

    .add_intro_video_container {
      background-color: $light_action_blue;
      border-radius: 6px;
      padding: .78rem 1rem .6rem;
      margin-bottom: 2rem;
    }

    .add_intro_video {
      display: block;
      border: 1px solid $lightgray-border;
      border-radius: 6px;
      padding: .6rem 1rem;
      background-color: white;
    }

    .add_video_label {
      font-weight: 600;
      display: block;
      margin-bottom: .65rem;
    }

    .remove_intro_vid_btn {
      display: inline-block;
      margin-left: .75rem;
      font-size: 1em;
      padding: 0;
      background: none;
    }

    .purpose_descrip {
      margin-bottom: .83rem;
    }

    .embed_form {}

    .embed_label {
      width: 98px;
      float: left;
      padding-top: .3rem;
    }

    .embed_input {
      width: calc(100% - 98px);
      float: left;
    }
  }
}
