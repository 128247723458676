@mixin standardTooltip {

    .standardTooltip {
        position: relative;
        width: 250px;
        height: 100px;
        padding: 0px;
        background: #4a4a4a;
        border-radius: 4px;
        padding: 10px 12px;
        position: absolute;
        color: white;
    }

    .standard_Tooltip:after {
        content: "";
        position: absolute;
        bottom: -12px;
        left: 117px;
        border-style: solid;
        border-width: 12px 8px 0;
        border-color: #ADB400 transparent;
        display: block;
        width: 0;
        z-index: 1;
    }
}

@mixin standard_tooltip {
    position: absolute;
    padding: 1em;
    @include fontsize12;
    color: $dark-text;
    text-align: center;
    background: #FFFFFF;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    border: #e5e5e5 solid 1px;
    z-index: $zindexTutorial;

    &:after {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 13px 11px 0;
        border-color: #FFFFFF transparent;
        display: block;
        width: 0;
        z-index: 1;
        bottom: -13px;
        left: 79px;
    }

    &:before {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 13px 11px 0;
        border-color: #e5e5e5 transparent;
        display: block;
        width: 0;
        z-index: 0;
        bottom: -14px;
        left: 79px;
    }
}