.standard_table2_container {

  .standard_table2 {

    .th_btn {

      &:hover {
        color: $aiq_darkblue;
      }
    }

    .icon_sort {
      vertical-align: -1px;
    }

    .interactable {

      a {
        color: $aiq_darkblue;

        &:hover, &:focus {
          color: $aiq_darkblue_hover;
        }
      }
    }
  }
}
