.aspireiq_theme {

  .overallStats_item {

    .tooltip_container {
      margin-left: .25rem;
    }
  }

  .brand_summary_report {

    .campaignBreakdown_section {

      .first_col_link {
        color: $aiq_darkblue;
      }
    }
  }

  .active_filter_item {

    .revfluence_logomark {
      background: url('/images/themes/aspireiq/aiq_logomark_white.svg') center no-repeat;
      background-size: 100%;
    }
  }

  .analyze_main_header {

    .intro_title {
      font-size: 2rem;
      color: $aiq_black;
    }

    .intro_descrip {
      color: $aiq_black;
    }
  }

  .content_searchFilters_container {

    .postDate_list {}

    .order_list {}

    .filters_dropdown_list {
      width: 170px;

      &:before, &:after {
        left: 134px;
      }
    }

    .search_input {
      padding: .56rem .5rem .5rem 1.85rem;
      font-size: .85rem;
      font-weight: 400;
    }
  }

  .creator_username_link {
    color: $aiq_darkblue;

    &:hover {
      color: $aiq_darkblue_hover;
    }
  }

  .creators_main_container {

    .creators_table {

      .icon_network {
        margin-right: .35rem;
      }

      .sort_up_icon {
        background: url('/images/themes/aspireiq/icon_sortUp_blue.svg') center no-repeat;
        background-size: 100%;
      }

      .sort_down_icon {
        background: url('/images/themes/aspireiq/icon_sortDown_blue.svg') center no-repeat;
        background-size: 100%;
      }
    }

    .action_link {

      &:hover {

        .action_text {
          color: $aiq_darkblue_hover;
        }
      }
    }

    .action_text {
      color: $aiq_darkblue;
    }
  }

  .core_subnav {

    .brandSummary_report_link.active_core_link {
      color: $aspireiq_bondi_blue;
    }
  }

  .no_content {

    .main_cta {
      background-color: $aiq_darkblue;

      &:hover, &:focus {
        background-color: $aiq_darkblue_hover;
      }

      &:active {
        background-color: $aiq_darkblue;
      }
    }
  }

  .doubleArrow_select_container {

    .doubleArrow_select_btn {
      padding: 8px 28px 7px 13px;

      .select_btn_txt {
        font-weight: 400;
      }
    }
  }

  .network_type_container {

    .doubleArrow_select_btn {
      padding: 8px 28px 7px 13px;

      .youtube_icon {
        vertical-align: -4px;
      }

      .all_icon {
        vertical-align: -2px;
      }

      .select_btn_txt {
        vertical-align: -5px;
      }

      .instagram_icon {
        vertical-align: -5px;
      }
    }

    .doubleArrow_dropdown {
      top: 37px;
      right: -5px;
    }
  }

  .btn_outline {
    background-color: $aiq_lightblue_bg;
    color: $aiq_darkblue;
    border: none;

    &:hover, &:focus {
      color: $aiq_darkblue_hover;
      background-color: $aiq_lightblue_bg_hover;
      border: none;
    }

    &:active {
      background-color: $aiq_lightblue_bg;
    }
  }
}
