/* CREATOR CONTENT PREVIEW MODULE */
.creatorContent_preview {
  position: relative;

  .caption_container {
    position: absolute;
    top: .5rem;
    left: .5rem;
    right: .5rem;
    z-index: 1;
    opacity: 1;
    transition: all .15s ease-in-out;
    background: rgba(0,0,0,.3);
    padding: 5px;
    color: white;
    border-radius: 4px;
    font-size: .8rem;
    font-weight: 500;
  }

  .topControls_container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: all .15s ease-in-out;

    .btn {
      position: relative;
      z-index: 2;
      margin-bottom: .35rem;
    }
  }

  .integrated_demographics {}

  .imgContainer {}

  .title_txt {
    position: absolute;
    top: .5rem;
    left: .5rem;
    right: .5rem;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    color: white;
    padding: 1px 7px;
    font-size: .87rem;
    font-weight: 500;
    display: none;
  }

  .content_img {
    max-width: 100%;
    border-radius: 6px;
    width: 100%;
    cursor: pointer;
  }

  .top_shadow, .bottom_shadow {
    position: absolute;
    width: 100%;
    transition: all .15s ease-in-out;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
  }

  .top_shadow {
    top: 0;
    opacity: .5;
  }

  .bottom_shadow {
    bottom: 0;
    display: none;
  }

  .secondaryActions_container {
    float: left;
    position: relative;
    padding-left: .25rem;

    &:before, &:after {
      left: 7px;
    }

    .dropdown_container {
      display: inline-block;
    }

    .moreOptions_btn {
      padding: 12px 10px 8px;
      border: none;

      &:focus, &:active {
        background: none;
      }
    }
  }

  .secondaryActions_dropdown {
    left: 5px;
    top: 42px;
    display: block;

    &:after, &:before {
      left: 5px;
    }
  }

  .moreOptions_btn {
    padding: 14px;
    border-radius: 0;
    background: none;
  }

  .moreOptions_icon {}

  .secondaryActions_item {}

  .secondaryActions_btn {}

  .primaryActions_container {
    float: right;
    padding-right: 9px;
  }

  .primaryActions_list {}

  .primaryActions_item {
    display: inline-block;
    margin-right: .25rem;

    .icon_invite {
      margin: 0 5px 0 0 !important;
    }
  }

  .offerBtn, .inviteBtn {
    padding: 8px 16px;
  }

  .primaryAction_btn {}

  .offer_icon {
    width: 18px;
    vertical-align: -2px;
  }

  .invitedBtn, .offeredBtn {
    color: #A2A2A2;
  }

  .invited_icon, .offered_icon {
    display: none;
  }

  .invitedBtn, .offeredBtn {

    &:hover {
      background: #F6F6F6;
    }

    .invite_icon, .offer_icon {
      display: none;
    }

    .invited_icon, .offered_icon {
      display: inline-block;
      margin-right: .25rem;
    }

    .invited_icon {
      width: 23px;
    }
  }

  .invitedBtn {
    background-color: white;
  }

  .offeredBtn {}

  .invite_icon {}

  .previewContent_container {
    position: absolute;
    bottom: 0;
    width: 100%;
    opacity: 0;
    transition: all .15s ease-in-out;
    overflow: visible;

    .slick-list {
      overflow: visible
    }
  }

  .navLeft_btn {
    left: 0;
  }

  .navRight_btn {
    right: 0;
  }

  .contentNav_btn {
    border-radius: 0;
    padding: 0 3%;
    background: none;
    cursor: default;
    position: absolute;
    top: 50%;
    margin-top: -8px;
  }

  .detailed_view_version {
    display: none;
  }

  /* USE THIS CLASS WHEN THE USER REACHES THE END OF THE SCROLL ON EITHER SIDE */
  .reached_end {
    opacity: .5;
  }

  .arrow_icon {
    width: 11px;
  }

  .previewContent_list {
    overflow-x: auto;
    white-space: nowrap;
    margin: 0 auto;
    padding-top: 1px;
    width: calc(100% - 15px);
    overflow: visible;
    text-align: center;
  }

  .previewContent_item, .previewContent_thumb {
    border-radius: 4px;
    width: 100px;
    overflow: visible;
    cursor: pointer;

    &:hover {
      .previewPopover_container {
        display: block;
      }
    }
  }

  .previewContent_item {
    display: inline-block;
    width: 19.06%;
    border: 2px solid white;
    border-radius: 4px;
    margin-right: 3px;
    position: relative;
    z-index: 5899;
  }

  .previewPopover_container {
    position: absolute;
    width: 300px;
    padding: 0px;
    background: #FFFFFF;
    display: none;
    border-radius: 3px;
    border: 6px solid white;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.25);
    bottom: 60px;
    left: -130px;
    z-index: 9999;

    &:after {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 9px 9px 0;
      border-color: #FFFFFF transparent;
      display: block;
      width: 0;
      z-index: 1;
      bottom: -15px;
      left: 141px;
    }

    .previewPopover_img {
      max-width: 100%;
    }
  }

  .previewContent_thumb {
    max-width: 100%;
  }

  .aud_breakdown {
    padding: 1rem 0;
    float: left;
    width: 100%;

    .audBreakdown_header {
      border-bottom: 1px solid $lightgray-border;
      text-align: center;
    }

    .audBreakdown_title {
      padding: .5rem;
      background-color: white;
      margin-top: -5px;
      color: #AEAEAE;
    }

    .audBreakdown_footer {}

  }
}

.youtube_creator_item {

  .previewContent_item {
    width: 24.26%;
  }
}

.instagram_creator_item {

  .previewContent_container {
    bottom: -6%;
  }

  .previewContent_item {
    width: 18.4%;
  }
}
