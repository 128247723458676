.quickmatch {

	.core_subnav {

		@include media($break843) {
			top: 3.1rem;
		}
	}

	.hasCampaign_navbar {

		@include media($break843) {
			margin-top: 152px;
		}
	}

	.start_campaign_banner {

		@include media($break830) {
			display: none;
		}
	}

	.creator-info {

		.inner_container {
			max-width:  1600px !important;
		}
	}

	.new_connect_nav {

		.inner_container {
			max-width: 100% !important;
		}
	}

	.main_nav {}

	.modal-backdrop {
		background-color: $dark-text;
		background-color: rgba(0,0,0,.9);
	}

	.moreInfo_txt {
		font-size: 1.4em;
	}

	.smallScreen_moreinfo_link {
		display: none;
		margin-top: .25rem;
		font-weight: 300;

		@include media($break830) {
			display: inline;
		}
	}

	.start_campaign_banner {
		margin-top: 93px;
	}

	.hasCampaign_navbar {
		margin-top: 130px;

		@include media($break843) {
			margin-top: 90px;
		}
	}

	.main_header {
		background-color: white;
		width: 100%;
		box-shadow: 1px 1px 1px rgba(0,0,0,.3);
		position: relative;
		z-index: 2;

		@include media($break1400) {
			padding: 0 1em;
		}

		@include media($break830) {
			z-index: 1000;
			height: 130px;
			margin-top: 5.8rem;
		}

		@include media($break690) {
			height: 137px
		}

		@include media($break400) {
			height: 116px;
		}

		.inner_container {
			max-width: 100%;
			margin: 0 auto;
			position: relative;
			background: url('/images/quickmatch/more_bio_indicator.svg') center 94% no-repeat;
			background-size: 1.5%;

			@include media($break830) {
				background: none;
			}
		}

		.creator-info {
			position: relative;
			width: 100%;

			.inner_container {
				max-width: 1250px;
				margin: 0 auto;
				padding: 1.5em 0 1em;

				@include media($break690) {
					padding: .5em 0 1em;
				}
			}

			.creator_text_container {
				float: left;
				width: calc(100% - 145px);

				@include media($break830) {
					width: calc(100% - 127px);
				}

				@include media($break690) {
					width: calc(100% - 80px);
					height: 69px;
					padding-right: 1em;
				}

				@include media($break450) {
					width: calc(100% - 80px);
					padding-right: 4rem;
				}

				&:hover {

	      		.bio {
	          			max-height: 25em;
	                    -webkit-mask-image: none;
	      		}
	  		}
			}
		}

		.creator-info-large-screen {

			.creator_details_container {
				width: calc(100% - 255px);
				padding-right: 2rem;
				float: left;

				@include media($break690) {
					width: calc(100% - 176px);
					padding-right: 0;
				}

				@include media($break450) {
					width: 100%;
					padding-right: 0;
					position: relative;
				}
			}
		}
	}

	.main_actions_container {
		float: right;
		width: 255px;

		@include media($break690) {
			width: 176px;
		}

		@include media($break450) {
			display: none;
		}

		@include media($break400) {
			width: 93px;
		}

		.remaining_creators {
			display: block;
			text-align: center;
			color: $lightgray-text;
			@include fontsize12;
			margin-bottom: .5em;

			@include media($break690) {
				display: none;
			}
		}

		.main_actions_list {
			text-align: center;
		}

		.main_action_item {
			display: inline-block;

			a {
				display: block;

			}
		}

		.action_icon {
			max-width: 100%;
			display: block;
		}

		.skip_item, .like_item {
			margin-right: 1em;
		}

		.recommended_btn {
			width: 71px;
			margin: 0 0 .5rem 0;
			padding: 0;

			@include media($break690) {
				width: 50px;
				height: 50px;
			}

			@include media($break400) {
				width: 40px;
				height: 40px;
			}
		}

		.skip_btn {}

		.like_btn {}

		.offer_btn {}

		.btn_text {
			display: block;
			text-align: center;
			color: $dark-text;
			font-weight: 600;
			text-transform: uppercase;
			letter-spacing: .2em;
		}

		.skip_txt {
			color: #757575;
		}

		.like_txt, .offer_txt {
			color: $revfluence-blue;
		}

		.remaining_creators_container {
			display: inline-block;

			@include media($break830) {
				display: none;
			}
		}

		.remaining_creators_number {
			font-size: 1.1em;
			color: #ADADAD;
			font-weight: 300;
		}
	}

	.creator-info-large-screen {

		.remaining_creators_container {
			text-align: center;
			width: 100%;
			margin: 1rem auto 0;

			.remaining_creators_number {
				font-size: 1.1em;
				display: block;
				color: #ADADAD;
				font-weight: 300;
				border-top: 1px solid #f5f5f5;
				padding-top: .7em;
			}
		}
	}

	.creator-bio {

		@include media($break830) {
			display: none;
		}
	}

	.bio {
		@include fontsize14;
		color: $dark-text;
		margin-bottom: .5em;
		font-weight: 200;
		max-height: 4.7em;
		margin-top: 0.25em;
		text-overflow: ellipsis;
		width: 100%;
		-webkit-transition: all .5s ease;
		-moz-transition: all .5s ease;
		-o-transition: all .5s ease;
		transition: all .5s ease;
		-webkit-mask-image: -webkit-gradient(linear, left 60%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));

		@include media($break956) {
			margin-bottom: .25em;
		}

		@include media($break830) {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		@include media($break403) {
			display: none;
		}
	}

	.account_stats {

		.account_stats_item {
			display: inline-block;
			margin-right: 2em;
			font-weight: 300;
		}

		.report_creator {
			vertical-align: 1px;
			display: inline-block;

			.report_btn {
				background: none;
				padding: 0;
				max-width: auto;
				font-size: .85rem;
				border: none;
				margin-bottom: 0;
				cursor: pointer;
				vertical-align: -1.6px;
				padding-left: 1px;
			}

			.prompt_msg {
				padding: .25em 1em;
				font-size: .85rem;
				font-weight: 600;
			}

			.divider_line {
				font-size: .9rem;
				font-weight: 300;
				vertical-align: -1px;
			}

			.report_dropdown {
				width: 200px;
				top: 29px;
				left: 4px;

				&:before, &:after {
					left: 40px;
				}
			}
		}

		a {
			text-decoration: none;
		}

		.social-icon {
			display: inline-block;
			margin-right: .5em;
			fill: $lightgray-text;
			vertical-align: -1.5px;
		}

		.vertical_bar {
			font-weight: 300;
		}

		.social-text {
			display: inline-block;
			color: $dark-text;
			@include fontsize14;
		}

		.filters_link_container {
			@include fontsize14;
			color: $dark-text;

			@include media($break450) {
				display: none;
			}

			.filters_link {
				@include blue-link;
			}
		}
	}

	.smallScreen_filters_btn {
		display: none;
		width: 50px;
		position: absolute;
		right: 0;
		top: 0;
		background: none;

		&:active, &:focus {
			box-shadow: none;
		}

		@include media($break450) {
			display: block;
			width: 50px;
		}
	}

	.total-reach {
		display: none;
		@include fontsize16;
		font-weight: 100;
		color: $dark-text;
		margin-bottom: .5em;
		@include media($break775) {
			display: block;
		}

		.total-reach-text {
			font-weight: 400;
		}

		.total-reach-number {}
	}

	.creator_avatar_container {
		float: left;
		margin-right: 2em;
		width: 125px;

		@include media($break830) {
			width: 107px;
		}

		@include media($break690) {
			width: 60px;
		}

		@include media($break400) {
			width: 45px;
			margin-right: 1em;
		}
	}

	.account_profile_link {
		display: block;
	}

	.creator_avatar {
		border-radius: 100px;
		width: 100%;
		box-shadow: 1px 0 4px rgba(0,0,0,.2);
		display: block;
		max-width: 100%;
		height: 125px;
		overflow-x: hidden;

		@include media($break830) {
			height: 107px;
		}

		@include media($break690) {
			height: 60px;
		}

		@include media($break400) {
			height: 45px;
		}
	}

	.main_title {
		margin-bottom: .2em;
		max-width: calc(100% - 44px);
		display: inline-block;

		@include media($break1100) {
			font-size: 2.6em;
			margin-bottom: 0;
		}

		@include media($break900) {
			font-size: 2em;
			margin-bottom: .075em;
		}

		@include media($break690) {
			display: none;
		}
	}

	.favBtn_dropdown_container {
		display:inline-block;
		vertical-align: 23px;

		@include media($break700) {
			display: none;
		}

		.favBtn {
			padding: 8px;
		}
	}

	.favDropdown_list {
		top: 34px;
		left: -3px;
		width: 275px;
	}

	.account_link {
		color: $aiq_darkblue;
		text-decoration: none;

		&:hover {
			color: $aiq_darkblue_hover;
		}

		@include media($break700) {
			display: inline;
		 	max-width: auto;
			vertical-align: 0;
			font-weight: 600;
		}
	}

	.number-remaining {
		@include fontsize12;
		color: $lightgray-text;
		text-align: center;
		display: block;
	  	margin-top: 6px;
	}

	.small_screen_questionContainer {
		display: none;

		@include media($break690) {
			display: block;
		}

		.main_title {
			display: block;
			font-weight: 600;
			margin-bottom: .45em;

			@include media($break400) {
				font-size: 1.5em;
			}
		}
	}
}

.onboarding_tinder {

    &.demo {
        .start_campaign_banner {
            margin-top: 99px;
        }
    }

    .start_campaign_banner {
        margin-top: 50px;
    }

	.connect_nav {
		display: none;
	}

	.tinder-header {
		top: 5em;
	}
}

.onboarding_tinder {

	.tinder-actions-container {
		width: 157px;
	}
}
