.confirm-payment {

	.modal-body {
		padding: 0 2em 3em;
	}

	.modal-header {
		background: none;
		border-bottom: none;
	}

	.back-link {
		position: absolute;
		top: 2em;
		left: 2em;
		width: 20px;
		height: 20px;
		text-indent: -9999px;
		background: url('../images/creator-payments/back_icon.svg') center no-repeat;
	}

	.close-modal-link {
		position: absolute;
		top: 2em;
		right: 2em;
		background: url('../images/creator-payments/close_icon.svg') center no-repeat;
		width: 20px;
		height: 20px;
		text-indent: -9999px;

		&:hover {}
	}

	.modal-title {
		@include title20;
		margin-bottom: 1em;
		color: $dark-text;
		padding-top: 1em;
		text-align: center;
	}

	.payment-details {
		margin-bottom: 1em;
		border: 1px solid $lightgray-border;
		@include standard-radius;
		margin-bottom: 2em;

		.primary-info {

			li {
				padding: 1em ;
				@include fontsize15;
				overflow: hidden;
				color: $dark-text;
				font-weight: 300;
				background-color: $system-lightBlue;
				font-weight: 600;
				border-bottom: 2px solid white;
			}

			.amount {
				float: right;
			}

            .waived-amount {
                text-decoration: line-through;
            }

            .waived-text {
                float: right;
                display: none;
                color: $label-green;
                padding-right: .75em;
            }
		}

		.secondary-info {
			li {
				padding: 1em ;
				@include fontsize15;
				overflow: hidden;
				color: $dark-text;
				font-weight: 300;
			}

			.amount {
				float: right;
			}
		}

		.charges-list {

			li {
				padding: 1em ;
				@include fontsize15;
				overflow: hidden;
				color: $dark-text;
				font-weight: 300;
				border-bottom: 1px solid $lightgray-border;

				&:last-child {
					border-bottom: none;
				}
			}

			.amount {
				float: right;
			}
		}

		.positive {
			color: $label-green;
		}

		.negative {
			color: $label-red;
		}
	}

	.revfluence-gaurantee {
		display: block;
		text-align: center;
		@include fontsize15;
		font-weight: 200;
		color: $dark-text;
		margin-bottom: 1em;
	}

	.complete-payment {
		width: 200px;
		display: block;
		margin: 0 auto;
		text-align: center;
	}

	.error-container {
		background-color: $label-red;
		padding: 1em;
		@include small-radius;
		margin-bottom: 2em;

		.error-description {
			@include fontsize15;
			color: white;
			text-align: center;
		}

		.error-button {
			display: block;
			width: 150px;
			margin: 0 auto;
		}
	}

	.card-added {

		.card-added-title {
			margin-bottom: .8rem;
		}

		.card_select_container {
			display: block;
			width: 245px;
			margin: 0 auto .6rem;

			@include media($break375) {
				width: 185px;
			}
		}

		.card_select {
			display: block;
			padding: .3rem 2.5rem .3rem .5rem;
		}

		.add_card_link {
			display: block;
			width: 220px;
			margin: 0 auto;

			&:focus, &:active {
				box-shadow: none;
			}
		}
	}

	.card-added-notice {
		background-color: $label-green;
		text-align: center;
		z-index: $zindexFlashNotification;
		padding: 1em;
		@include standard-radius;
		margin-bottom: 1em;

		p {
			color: white;
			@include fontsize15;
			display: block;
			text-align: center;
		}

		a {
			@include blue-link;
		}
	}

	.add-new-card {
		background-color: $lightgray-bg;
		padding: 2em;
		margin-bottom: 2em;

		.styled-selected {
			width: 120px;
		}
	}

	.add-card-form {
		width: 100%;
	}
}
