.aspireiq_theme {

  .btn_preview_mediaKit {
    border-radius: 5px;
    padding: .5rem .75rem .5rem .5rem;

    .mediaKit_preview {
      margin-right: .5rem;
    }
  }

  .btn_link {
    text-decoration: none;

    &:hover {
      color: $aiq_darkblue_hover !important;
    }
  }

  .btn {

    &:active, &:focus {
      box-shadow: none;
    }
  }

  .btn_primary {
    font-weight: 600;
  }

  .btn_primary, .offerSignup_btn {
    background: $aiq_darkblue;
    background-size: 400% 400%;
    /* transition: all .15s ease-in-out; */
    border: none;
    color: white;

    &:hover, &:focus {
      /* animation: Gradient 15s ease infinite; */
      background: $aiq_darkblue_hover;
    }

    &:active {
      /* animation: inherit; */
      background: $aiq_darkblue;
    }
  }

  @keyframes Gradient {
    0% {
      background-position: 0% 50%
    }
    50% {
      background-position: 100% 50%
    }
    100% {
      background-position: 0% 50%
    }
  }

  .deleteBtn, .btn_danger {
    background-color: $aiq_dangerRed;
    border: none !important;
    color: white !important;

    &:hover, &:focus {
      background-color: $aiq_dangerRed_hover;
    }

    &:active {
      background-color: $aiq_dangerRed;
    }
  }

  .btn_outline {
    background-color: white;
    border: 1px solid $aspireiq_border;
    color: $aspireiq_persian_blue;

    &:hover {
      border: 1px solid $aspireiq_bondi_blue;
    }

    .btn_txt {
      color: $aspireiq_persian_blue;
    }
  }

  .admin_link {
    color: $aspireiq_persian_blue;
  }

  .standard_link {
    color: $aiq_darkblue;
  }

  .no_content {

    .main_cta {
      background-color: $aiq_darkblue;

      &:hover {
        background-color: $aiq_darkblue_hover;
      }
    }
  }
}
