@keyframes spin {

  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-label {
  display: none;
}

.spinner-image {
  width: 87px;
  height: 64px;
}

.loading_spinner {
  animation: spin 0.7s linear infinite;
  width: 20px;
  height: 20px;
  background: url('/images/style_guide/loading_spinner_white.svg') center no-repeat;
  background-size: 100%;
  display: inline-block;
}

.loading_spinner_white {}

.loading_spinner_blue {
  animation: spin 0.7s linear infinite;
  width: 20px;
  height: 20px;
  background: url('/images/style_guide/loading_spinner_blue.svg') center no-repeat;
  background-size: 100%;
  display: inline-block;
}

.open_circle {}
