.defaultFiltersContainer {
  padding: 18% 0;

  .welcomeHeader {
    margin-bottom: 16px;
  }

  .headerBreak {
    width: 100%;
    height: 1px;
    background: #D9D9D9;
    margin-bottom: 21px;
  }

  .innerContainer {
    margin: auto;
    text-align: center;
    max-width: 488px;
    padding: 0 32px;
  }

  .filterDropdown {
    width: 100%;
    display: block;
    margin: 24px 0 0;
  }

  .saveFiltersButton {
    width: 100%;
    margin: 24px 0 0;
  }

  .helpText {
    margin: 8px 0 0;
  }
}