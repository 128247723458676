@keyframes scale_in {

  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 100%;
    transform: scale(100%);
  }
}

@keyframes fade_in {

  0% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

@keyframes fade_out {

  0% {
    opacity: 100%;
  }

  100% {
    opacity: 0;
  }
}
