.aspireiq_theme {

  .newAnalyze_header {
    margin: 92px 2.25rem 0;

    .headerControls {
      padding-top: 1.5rem !important;

      .headerBtn_txt {
        font-weight: 600;
      }
    }

    .leftCol {

      .mainTitle {
        font-size: 1.85rem;
        padding-bottom: 1rem;
      }

      .mainDescrip {
        font-size: 1.05rem;
        color: $aiq_black;
        padding-bottom: 1.4rem;
      }

      .addFilter_btn {
        font-weight: 600;
        color: $aiq_darkblue;
        border-radius: 100px;
        padding: 7px 10px 6px 10px;

        &:hover, &:focus {
          color: $aiq_darkblue_hover;
          background-color: $aiq_lightblue_bg;
        }

        &:active {
          background-color: white;
        }

        .icon_addFilter {
          vertical-align: -4px;
        }
      }

      .linkNav {

        .standard_link {
          padding: 1rem .75rem .9rem;
        }
      }
    }
  }

  .newAnalyze_body {

    .editCols_btn {
      background-color: white;
      border: 1px solid #E9E8EA;
      border-radius: 100px;

      &:hover {
        background-color: #EFF5F9;
      }
    }

    .breakdownTable {

      thead {

        th {

          &:hover {
            color: $aiq_darkblue;
            background-color: $aiq_lightblue_bg;
          }
        }
      }
    }
  }

  .reports_table {

    thead {

      th {

        &:hover {
          color: $aspireiq_bondi_blue;
        }
      }
    }

    tbody {

      tr {

        &:hover {
          background-color: $aspireiq_blue_lightest;
        }
      }
    }
  }

  .reports_toolbar {

    .btn_txt {
      font-weight: 400;
    }

    .leftGrp {

      .dropdown_btn2 {

        &:hover {
          background: $aiq_lightblue_bg;
        }
      }
    }
  }
}
