.new_manage {

  .content_approval_tool {

    .confirm_content_unfinished {
      text-align: center;

      .modal-dialog {
        padding: 2rem 4rem;

        @include media($break600) {
          width: 95%;
        }

        @include media($break440) {
          padding: 2rem 1.25rem;
        }
      }

      .main_title {
        margin-bottom: 2rem;
      }

      .not_doing_list {
        margin: 0 auto 2rem;
        width: 30rem;

        @include media($break600) {
          width: 100%;
        }
      }

      .not_doing_item {
        border: 1px solid $lightgray-border;
        border-radius: 5px;
        padding: .5rem;
        margin-bottom: .5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .back_btn {
        margin-right: .65rem;

        @include media($break340) {
          margin: 0 0 1rem;
          display: block;
          width: 100%;
        }
      }

      .confirm_btn {

        @include media($break340) {
          display: block;
          width: 100%;
        }
      }
    }
  }
}
