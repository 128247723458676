/* Sample HTML

<div class="standardAccordion">
  <button class="accordionBtn btn no_shadow_focus">
    <img src="/images/icons/filled/triangle_arrow_right.svg" alt class="disclosure_icon">
    <span class="accordionBtn_txt">Show Accordion</span>
  </button>

  <div class="accordionMain_content"></div>
</div>
*/

.standardAccordion {
  border: 1px solid $lightgray-border;
  border-radius: 5px;
  padding: 0;

  .accordionBtn {
  	border-radius: 5px;
    border: none;
  	background-color: white;
  	width: 100%;
  	display: block;
  	text-align: left;
  	padding: .75rem 1rem;

  	&:hover {
  		background-color: #FAFAFA;
  	}

  	.disclosure_icon {
  		width: 16px;
  		display: inline-block;
  		margin-right: .5rem;
  		vertical-align: -2px;
  	}

  	.open {

  		.dropdown_icon {
  			transform: rotate(90deg);
  		}
  	}

  	.accordionBtn_txt {
  		font-weight: 400;
  		display: inline-block;
  	}
  }

  .accordionMain_content {
    padding: .75rem 1rem 1rem;
    border-radius: 0 0 6px 6px;
    background-color: #FAFAFA;
    border-top: 1px solid $lightgray-border;
  }

  .disclosure_icon {
    transition: all .15s ease-in-out;
  }
}

.open_standardAccordion {

  .disclosure_icon {
    transform: rotate(90deg);
  }
}

/* DROPDOWN ACCORDION -> STARTER HTML
<div class="dropdown_container">
  <button type="button" class="dropdown_btn2 btn">
    <img src="/images/style_guide/icon_settings_fill_block.svg" class="icon_settings dropdownLeft_icon">
  </button>
  <div class="dropdown_accord dropdown_list" style="display:block;">
    <div class="overflow_wrapper">
      <div class="accordSet">
        <button type="button" class="toggle_btn btn no_shadow_focus">
            <img src="/images/style_guide/icon_triangleRight_gray.svg" alt class="icon_arrow">
            <img src="/images/style_guide/icon_triangleDown_color.svg" alt class="open_icon_arrow icon_arrow">
            <span class="toggle_btn_txt">Structure</span>
        </button>
        <div>
          <ul class="accordList">
            <li class="accordItem">
              <label class="checkbox_control">
               <input type="checkbox">
                 <span class="control-indicator"></span>
                 <div class="text_container">
                     Ad
                 </div>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
*/
.dropdown_accord {

  .accordSet {}

  .overflow_wrapper {
    max-height: 500px;
    overflow-y: auto;
  }

  .toggle_btn {
    position: relative;
    font-weight: 600;
    display:block;
    width: 100%;
    text-align: left;
    border-radius: 0;
    padding: .5rem .5rem .5rem  1.5rem;
    background-color: white;
    border-top: 1px solid $lightgray_border;

    &:hover {
      background-color: #F9F9F9;
    }

    &:active {
      background-color: #e9e9e9;
    }
  }

  .open_icon_arrow {
    display: none;
    top: .5rem;
    left: .84rem;
  }

  /* ENGINEERING: APPLY THIS DYNAMICALLY ACCORDINGLY */
  .active_toggle_btn {
    background-color: #F9F9F9;
    color: $revfluence-blue;
    border: none;

    &:active {
      background-color: #e9e9e9;
    }

    .icon_arrow {
      display: none;
    }

    .open_icon_arrow {
      display: block;
    }
  }

  .icon_arrow {
    position: absolute;
    left: .5rem;
    top: .8rem;
  }

  .toggle_btn_txt {}

  .accordList {
    padding: .25rem 1rem .4rem;
  }

  .checkbox_control {
    margin-bottom: 0;
  }

  .accordItem {
    padding: 0;

    &:hover {
      color: $revfluence-blue;
    }

    label {

      &:hover {
        color: $revfluence-blue;
      }
    }

    .checkbox_control, .radio {
      padding: .5rem 0;
      display: block;
    }

    .text_container {
      padding-left: 1.9rem;
      margin-top: -5px;
    }
  }

  .selected_accordItem {

    label {
      font-weight: 600;
      color: $revfluence-blue;
    }
  }

  .accordIcon {
    width: 15px;
    margin: 0 .25rem 0 .25rem;
  }

  .icon_user {}

  .icon_flag {}

  .icon_adset_grp {}

  .icon_content {}
}

.accordion_box {
  border-radius: 6px;
  border: 1px solid #E6EDF2;

  .toggle_button {
    padding: .75rem 2.5rem .75rem 1rem;
    position: relative;
    background-color: #FDFDFD;
    
    display: block;
    width: 100%;
    text-align: left;
    color: #3996E0;
    border-radius: 6px;

    &:hover {
      background-color: #F6F5F6;
    }

    &:active {
      background-color: #FDFDFD;
    }

    .accordion_button_text {
      font-size: .9rem;
      font-weight: 600;
    }

    .indicator {
      width: 14px;
      right: 1rem;
      top: 50%;
      margin-top: -7px;
      position: absolute;
    }

    .indicator_open {
      transform: rotate(90deg);
    }
  }

  .accordion_body {
    background-color: #EFF5F9;
    padding: 1rem;
    border-top: 1px solid #E6EDF2;
  }
}