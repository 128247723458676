.aiq_theme_icons {
  display: none;
}

.sr_only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.disabled_element {
  opacity: .5;
}

.noData_placeholder {
  text-align: center;
  color: #737c87;
  font-size: 16px;
  background: #FAFAFA;
  padding: 1rem;
  border-radius: 6px;
  font-weight: 400;
}

/* Starter HTML for .signature_input_module
<div class="signature_input_module">
  <input type="text" class="signature_input">
  <span class="signHere_label">Sign here</span>
</div>
*/
.signature_input_module {
  background-color: #FAFAFA;
  border: 1px solid #E2E2E2;
  border-radius: 7px;
  padding: .5rem 1rem;
  width: 98%;
  position: relative;

  .resetSignature_btn {
    position: absolute;
    top: .25rem;
    right: .25rem;
    font-weight: 300;
    border-radius: 0;
    padding: .25rem .5rem;
    background: none;
    color: #BBBBBB;
    font-size: .85rem;
    margin-top: 10px;

    &:hover {
      color: $revfluence-blue-hover;
    }
  }



  .signHere_label {
    text-align: center;
    display: block;
    padding: .55rem 0 .25rem;
    color: #BBBBBB;
    font-size: .85rem;
  }
}

.signature_full_name_label {
  font-size: 14px;
  font-weight: 600;
}

.signature_full_name {
  flex: 1;
  margin-left: 12px;
}

.signature_full_name_wrapper {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 16px;
  align-items: center;
}

.signature_input {
  height: 115px;
  width: 100%;
  border: none;
  border-radius: 7px 7px 0 0;
  border-bottom: 1px solid #e2e2e2;
  background-size: 13px;
  font-size: 2rem;
  background: url('/images/style_guide/icon_signHere_gray.svg') left 90% no-repeat;

  &:active, &:focus {
    outline: none;
  }

    canvas {
        width: 100%;
        height: 100%;
    }
}

/* BADGE IMG STARTER HTML

<div class="tooltip_img_wrap tooltip_container">
  <div class="tooltip_bottomCenter tooltip">
    <span class="tooltip_txt">The creator was responsive, communicated clearly, and delivered quality content.</span>
  </div>
  <img src="/images/style_guide/badge_professional.png" class="badge_img">
</div>
*/

.tooltip_img_wrap {
  position: relative;

  .tooltip {
    left: -88px;
    bottom: 92px;

    &:before, &:after {
      left: 109px;
    }
  }

  .badge_img {

    &:hover {
      opacity: .9;
    }
  }
}
