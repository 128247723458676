/*

<div class="folder_grp">
  <button type="button" class="folder_btn_open folder_btn btn no_shadow_focus">
    <!-- Apply .open_arrow_icon accordingly -->
    <img src="/images/style_guide/icon_chevronRight_black.svg" class="open_arrow_icon arrow_icon">
    <span class="toggle_btn_txt">All Accounts (20)</span>
  </button>
  <ul><li></li</ul>
</div>
*/

.folder_grp {

  .folder_btn {
    background: none;
    padding: 6px 10px;
    border-radius: 4px;

    &:hover {
      background-color: #F7F7F7;
    }
  }

  .arrow_icon {
    margin-right: .25rem;
    width: 8px;
  }

  .folder_btn_open {

    .arrow_icon {
      transform: rotate(90deg);
    }
  }

  .toggle_btn_txt {
    font-weight: 600;
  }
}
