.creator_profile {
  .main_header {
    padding: 1rem 0;
    border-bottom: 1px solid $lightgray-border;

    @include media($break1250) {
      padding: 1rem;
    }

    .inner_container {}

    .intro_container {
      width: 48%;
      float: left;
      margin-right: 4%;

      @include media($break875) {
        width: 100%;
        margin-right: 0;
        float: none;
      }
    }

    .contentImg_form {
      cursor: pointer;
    }

    .ambassador_icon_container {
      display: inline-block;
      height: 41px;
      vertical-align: bottom;
      .ambassador_icon {
        height: 31px;
      }
    }

    .avatar_container {
      float: left;
      width: 100px;
      margin-right: 1rem;
      position: relative;
      height:100px;

      @include media($break375) {
        width: 75px;
      }

      .edit_icon {
        position: absolute;
        top: 5px;
        left: 1px;
        cursor: pointer;
        display: none;

        @include media($break875) {
          display: block;
        }
      }

      &:hover {

        .edit_icon {
          display: block;
        }
      }
    }

    .edit_avatar_link {
      display: block;
    }

    .hover_edit_indicator {
      display: none;
    }

    .avatar_img {
      border-radius: 100px;
      display: block;
      max-width: 100%;
      max-height: 100px;
    }

    .txt_container {
      width: calc(100% - 116px);
      float: left;

      @include media($break875) {

      }
    }

    .name_txt_container {
      margin-bottom: .25rem;

      .name_txt {
        display:inline-block;
        max-width: calc(100% - 40px);

        @include media($break550) {
          font-size: 1.8rem;
        }
      }

      .edit_btn {
        display: inline-block;
        vertical-align: 8px;
        margin-left: .25rem;
          display: inline-block;

        @include media($break875) {
          display: block;
        }
      }
    }

    .reviewsContainer, .headerActions_container {
      float: left;
    }

    .headerActions_container {

      @include media($break495) {
        margin-top: .65rem;
      }
    }

    .reviewsContainer {
      width: 155px;
    }

    .reviewsDisclaimer {
      font-size: .85rem;
      font-style: italic;
    }

    .stats_container {
      float: right;
      width: 32%;
      padding-top: 1.25rem;

      @include media($break875) {
        display: none;
      }
    }

    .total_reach {}

    .social_account_stats {
      display:block;
      float: right;
      width: 48%;
      padding-top: .8rem;

      @include media($break875) {
        display: none !important;
      }

      .network_icon {
        float: left;
        width: 25px;
        margin: 1.25rem 1rem 0 0;
      }

      .instagram_icon {}

      .youtube_icon {}

      .twitter_icon {}

      .facebook_icon {}

      .vine_icon {}

      .blog_icon {}

      .snapchat_icon {}

      .pinterest_icon {}

      .googleplus_icon {}

      .social_stats_list {
        float: left;
        width: calc(100% - 41px);
      }

      .social_stats_item {
        float: left;
        width: 25%;
        text-align: center;
      }

      .subscribers {}

      .social_stats_item {}

      .stat_number {

        @include media($break1015) {
          font-size: 1.5rem !important;
        }
      }

      .stat_label {}
    }
  }

  .one_account_header {

    .intro_container {
      width: 54%;
    }

    .social_account_stats {
      width: 38%;
    }
  }
}
