.autoMsg_tab {

  .autoMsg_main {

    /* HOME HEADER */
    .autoMsg_home_header {
      padding-bottom: .25rem;

      .autoMsg_label {
        font-weight: 600;
        float: left;
        padding-top: 7px;
      }

      .globalActions_list {
        float: right;
      }

      .globalActions_item {
        float: right;
        margin-right: .5rem;

        &:first-child {
          margin-right: 0;
        }
      }

      .globalAction_btn {
        padding: .5rem;
      }

      .newAuto_msg_btn {}

      .importMsg_btn {
        padding: .25rem .5rem .25rem .25rem;
      }

      .newMsg_icon {
        vertical-align: -4px;
        margin-right: 4px;
        width: 20px;
        height: 20px;
      }

      .import_icon {
        vertical-align: -9px;
      }
    }

    /* HOME LIST */
    .autoMsg_home_list {

      /* FIRST ITEM PALCEHOLDER—ONLY SHOW IF THERE ARE NO MESSAGES */
      .emptyPlaceholder_item {
        text-align: center;
        background-color: #FCFCFC;
        border-radius: 6px;
        border: 1px dashed #E2E2E2;
        font-weight: 600;
        padding: 1.5rem 0;
        color: #A8A8A8;
        cursor: pointer;

        &:hover {
          background-color: #f9f9f9;
        }
      }

      .autoMsg_home_item {
        border: 1px solid $lightgray-border;
        border-radius: 6px;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      /* AUTO MESSAGE HEADER */
      .autoMsg_header {
        border-radius: 6px;
        padding: .75rem 1rem 1.05rem;

        &:hover {
          background-color: $ultraLight_gray;
        }

        &:active, &:focus {
          background-color: white;
        }
      }

      .mechIndicator, .basicInfo_container, .rightInfo_container {
        float: left;
      }

      .mechIndicator {
        margin-right: .87rem;
        padding-top: 16px;
      }

      .mechIndicator_icon {
        width: 12px;
      }

      .open_indicator {
        transform: rotate(90deg);
      }

      .basicInfo_container {
        float: left;
      }

      .autoMsg_name {
        font-weight: 600;
        display: block;
        padding-bottom: .15rem;
      }

      .singleActions_list {}

      .singleActions_item {
        display: inline-block;
        margin-right: .5rem;

        &:last-child {
          margin-right: 0;
        }
      }

      .status_indicator {
        position: relative;
      	line-height: 19px;
        border-radius: 100px;
        padding: 0 7px 0 7px;
      }

      .disabled_indicator {
        background-color: #C1C1C1;
      	padding: 0 5px 0 5px;
      }

      .enabled_indicator {
        background-color: $revfluence-blue;
      	padding: 0 5px 0 5px;
      }

      .indicatorTxt {
        font-size: .6rem;
      	color: white;
      	text-transform: uppercase;
      	letter-spacing: .08rem;
      	font-weight: 600;
        vertical-align: 2px;
      }

      .singleAction_link {
        color: $dark-text;
        text-decoration: none;
        font-size: .85rem;

        &:hover {
          color: $revfluence-blue;
        }
      }

      .rightInfo_container {
        float: right;
      }

      .autoMsg_stats_list {
        padding-top: .55rem;
      }

      .autoMsg_stats_item {
        text-align: left;
        display: inline-block;
        margin-right: 2rem;

        &:last-child {
          margin-right: 1rem;
        }
      }

      .statNumber {
        font-size: 1.1rem;
        font-weight: 300;
        display: block;
        line-height: 1.25rem;
      }

      .statLabel {
        font-size: .7rem;
        font-weight: 300;
        display: block;
        line-height: 1.25rem;
        text-transform: uppercase;
        letter-spacing: .08rem;
      }

      /* AUTO MESSAGE BODY */
      .autoMsg_body {
        background-color: #F9F9F9;
        border-top: 1px solid $lightgray-border;
        padding: .75rem 1.5rem;
        border-radius: 0 0 6px 6px;
      }

      .small_txt {
        padding-top: .2rem;
        display: block;
      }

      .edit_mode {}

      .preview_mode {

        .section_title {
          font-size: .95rem;
        }

        .targetAudience_section, .msgtoCreators_section {
          padding-bottom: .45rem;
        }

        .msgtoCreators_section {

          .message_to_creators {
            white-space: pre-wrap;
          }

          p {
            padding: .25rem 0;
          }

          a {
            color: $revfluence-blue;
            text-decoration: none;

            &:hover {
              color: $revfluence-blue-hover;
            }
          }
        }

        .preview_actions_list {
          text-align: center;
          padding: .25rem 0 .65rem;
        }

        .preview_actions_item {
          display: inline-block;
        }

        .btn_primary {
          margin-right: .5rem;
        }

        .btn_default {}
      }

      .autoMsg_nameControl {
        max-width: 390px;
      }

      .standardFieldset {
        padding: .5rem 0;
      }

      .formActions_list {
        text-align: center;
        padding: 1rem 0;
      }

      .formActions_item {
        display: inline-block;

        .btn_primary {
          margin-right: .25rem;
        }
      }

      .row {
        padding-bottom: .25rem;
      }

      .filter_row {
        padding-bottom: .5rem;
      }

      .category_header {
        font-weight: 600;
        border-bottom: 1px solid $lightgray-border;
        padding-bottom: .25rem;
        font-size: .85rem;
        padding: .65em 0;
        margin: 0 .5rem .3rem;
      }

      .filterIcon {
        width: 18px;
        margin-right: 3px;
        vertical-align: -1px;
      }

      .filterDropdown_container {
        width: 200px;
        left: 6px;
        background-color: white;

        &:before, &:after {
          left: 10px;
        }

        scrollbar-face-color: #AFAFAF;
        scrollbar-track-color: none;

        @include media($break630) {
          background-color: #F8F8F8;
          padding: 0;
        }

        ::-webkit-scrollbar {
          width:  6px;
        }

        ::-webkit-scrollbar-thumb {
          background: #D8D8D8;
          border-radius: 100px;
        }

        ::-webkit-scrollbar-track {
          background: none;
        }
      }

      .filterDropdown_list {
        font-size: .58rem;
        max-height: 250px;
        overflow-y: auto;
      }

      .standard_label {
        font-weight: 600;
      }

      .dropdown_btn {
        background: white url('/images/style_guide/dropdown_indicator_icon.svg') 94% center no-repeat;
        background-size: 14px;
        margin-bottom: 0;

        &:hover {
          background: $ultraLight_gray url('/images/style_guide/dropdown_indicator_icon.svg') 94% center no-repeat;
          background-size: 14px;
        }
      }

      .first_input, .second_input, .third_input, .fourth_input, .type_input {
        float: left;
        position: relative;

        .dropdown_btn {
          max-width: 300px;
        }
      }

      .second_input_txt, .fourth_input_txt {
        float: left;
        padding: .4rem .5rem;
      }

      .first_input {
        width: 200px;
      }

      .enteredStage_btn {
        background: white url('/images/style_guide/icon_edit_gray.svg') 95% center no-repeat;
        background-size: 15px;
        border-radius: 6px;
        border: 1px solid $lightgray-border;
        text-align: left;
        box-shadow: none;
        font-weight: 300;
        padding: 6px 30px 6px 10px;

        &:hover {
          background-color: #FBFBFB;
        }
      }

      .enteredFilter {
        box-shadow: none;
        text-align: left;
        position: relative;
        background: white url('/images/style_guide/icon_filter_black.svg') 3% center no-repeat;
        max-width: 390px;
        background-size: 14px;
        border: 1px solid $lightgray-border;
        border-radius: 4px;
        padding: .4em 4rem .4em 2rem;

        &:hover {
          background-color: #FBFBFB;
        }
      }

      .enteredFilter_actions_container {
        position: absolute;
        right: 0;
        top: 2px;
      }

      .enteredFilter_btn {
        background: none;
        padding: 10px;

        .icon_edit, .icon_remove {
          width: 13px;
        }
      }

      .second_input, .third_input, .fourth_input {
        margin-right: .25rem;
        width: 112px;

        .dropdown_btn {
          width: 100%;
        }
      }

      .saveFilter_btn {
        float: left;
        border-radius: 4px;
        padding: 5px 15px 4px;
        margin-top: 1px;
      }

      .is_txt {
        float: left;
        display: block;
        padding: 0 .5rem;
        margin-top: 5px;
      }

      .second_input {

        .dropdown_btn {
          background: white url('/images/style_guide/dropdown_indicator_icon.svg') 94% center no-repeat;
          background-size: 14px;

          &:hover {
            background: $ultraLight_gray url('/images/style_guide/dropdown_indicator_icon.svg') 94% center no-repeat;
            background-size: 14px;
          }
        }
      }

      .third_input {
        width: 112px;
      }

      .dropdown_list {
        display: block;
      }

      .first_dropdown_list, .second_dropdown_list, .third_dropdown_list {
        top: 34px;
        right: -12px;
      }

      .second_dropdown_list {
        width: 185px;

        &:before, &:after {
          right: 13px;
          left: auto;
        }
      }

      .third_dropdown_list {
        width: 185px;

        &:before, &:after {
          right: 13px;
          left: auto;
        }
      }

      .target_audience_helpLink {
        vertical-align: -7px;
        margin-left: 2px;
      }

      .addFilter_btn {
        padding: 6px 11px;
        margin-bottom: .5rem;
        display: block;

        .icon_addItem {
          vertical-align: -4px;
        }
      }

      .updateExamples_btn {
        display: inline-block;
        padding: 0;
        border: none;
        margin-bottom: 5px;
        margin-left: 5px;
      }

      .removeFilter_btn {
        float: left;
        padding: 11px 8px;
        background-color: #F9F9F9;

        &:hover {
          background-color: $lightgray-border;
        }
      }

      .icon_remove {
        width: 13px;
      }

      .icon_addItem {}

      .exampleRecipients_container {}

      .exampleRecipients_label {
        margin-bottom: 5px;
        display: inline-block;
      }

      .exampleRecipients_list {
        background-color: white;
        border: 1px solid $lightgray-border;
        border-radius: 6px;
        padding: .5rem;
        max-height: 150px;
        overflow-y: auto;
        max-width: 390px;
        position: relative;
        min-height: 100px;

        .spinner-image {
          width: 58px;
          height: 43px;
        }
      }

      .noRecipients_item {
        font-style: italic;
        color: $lightgray-text;
      }

      .exampleRecipient_item {
        padding: .15rem 0;
      }

      .exampleRecipient_avatar {
        width: 27px;
        border-radius: 100px;
        margin-right: .5rem;
        display: inline-block;
        vertical-align: -7px;
      }

      .exampleRecipient_txt {
        display: inline-block;
      }

      .msgComposer {
        max-width: 575px;
      }

      .composeToolbar {
        background-color: white;
        border-radius: 6px 6px 0 0;
        border: 1px solid $lightgray-border;
      }

      .composeToolbar_btn {
        background-color: white;
        border-radius: 0;
        border: none;

        &:hover {
          background-color: $ultraLight_gray;
        }
      }

      .composeToolbar_list {}

      .composeToolbar_item {
        display: inline-block;
      }

      .composeToolbar_txt {
        font-weight: 300;
        font-size: .9rem;
      }

      .insertLink_btn {
        padding: 7px 11px 6px;
        cursor: default;
        margin-top: 0;
      }

      .icon_link {
        vertical-align: -2px;
        width: 15px;
      }

      .insertName_container {}

      .insertName_btn {
        border-radius: 6px 0 0 0;
        padding: .4rem 1.75rem .4rem .65rem;
      }

      .icon_insertName {
        width: 18px;
        vertical-align: -2px;
        margin-right: .15rem;
      }

      .has_borderBottom {
        border-bottom: 1px solid $lightgray-border;
        padding-bottom: 1rem;

        &:last-child {
          border-bottom: none;
        }
      }

      .compose_textarea {
        border-radius: 0 0 6px 6px;
        margin-top: -1px;
        background-color: white;
        min-height: 200px;
        font-size: .9rem;

        &:focus, &:active {
          border: 1px solid $lightgray-border;
        }
      }

      .insertName_dropdown_list {
        width: 212px;
        top: 36px;
        left: -55px;

        &:before, &:after {
          left: 181px;
        }
      }
    }
  }

  .type_dropdown_list {
    width: 182px;

    &:before, &:after {
      left: 149px;
    }

    .dropdown_li {

      a {
        line-height: 1.25rem;
      }
    }
  }

  /* Auto Message Form - Edit/New */
  .autoMsg_edit {

    .autoMsg_edit_header {}

    .back_btn {}

    .autoMsg_edit_title {}

    .edit_autoMsg_form {}

    .fieldset {}
  }
}

.autoMsg_form {

  .main_actions {
    display: none;
  }

  .active_tab_content {
    border-bottom: 1px solid $lightgray-border;
    border-radius: 0 0 5px 5px;
  }
}
