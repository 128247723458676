.creator_login, .creator-sign-up {
	background: #1a1a1a url('../images/creator_signup/background-image.jpg') center center no-repeat fixed;
	padding-top: 5em;
	background-size: cover;

	@include media($break530) {
		padding-top: 2em;
	}

	.header {
		margin-bottom: 7em;
		@include media($break730) {
			padding: 0 2em;
		}
		@include media($break400) {
			padding: 0 1em;
			margin-bottom: 5em;
		}

		.revfluence-logo {
			a {
				display: block;
				width: 199px;
				height: 43px;
				background: url('../images/themes/aspireiq/aiq_logo.png') center no-repeat;
				margin: 0 auto 4em;
				text-indent: -9999px;
				@include media($break730) {
					margin: 0 auto 4em;
				}
				@include media($break400) {
					margin: 0 auto 2em;
				}
			}
		}

		.intro_title {
			text-align: center;
			margin-bottom: .25em;
		}

		.intro_descrip {
			@include fontsize18;
			font-weight: 200;
			text-align: center;
		}
	}

	.main {
		overflow: hidden;
		width: 784px;
		margin: 0 auto 3em;
		@include media($break825) {
			width: 425px;
		}
		@include media($break460) {
			width: 95%;
		}

		.error_container {
	    @include errors;
	    width: 100%;

	    .error_descrip {
	    	color: white;
	    	@include fontsize16;
	    	text-align: center;
	    }

	    a {
	    	color: white;
	    	text-decoration: underline;

	    	&:hover {
	    		text-decoration: none;
	    	}
	    }
	  }

	  .form-error-span {
	  	background-color: $aiq_dangerRed;
	  	color: white;
	  	padding: .5em;
	  	@include standard-radius;
	  	display: inline-block;
	  	margin-bottom: 1em;
	  }

		.social_login {
			width: 361px;
			float: left;
			margin-right: 70px;
			@include media($break825) {
				width: 100%;
				float: none;
				margin-right: 0;
				margin-bottom: 4em;
			}

			.option_title {
				@include fontsize18;
				color: white;
				display: block;
				text-align: center;
				margin-bottom: 1em;
				padding-bottom: 1em;
				border-bottom: 1px solid #4c4c4c;
			}

			.facebook_login_button {
				@include standard-radius;
				background-color: $facebook-color;
				width: 100%;
				margin-bottom: 1em;
				padding: 1.5em 2em;
				border: none;
				-webkit-appearance: none;
				cursor: pointer;
				display: block;
				margin: 0 auto 2em;
				text-align: center;
				@include standard-transition;
				outline: none;

				&:hover {
					background-color: $facebook-color-hover;
				}

				&:focus {
					background-color: $facebook-color-hover;
					outline: none;
				}

				.facebook_icon {
					display: inline-block;
					vertical-align: 2px;
					margin-right: .5rem;

					path {
						fill: white;
					}
				}

				.button_text {
					color: white;
					@include uppercase14;
					display: inline-block;
					vertical-align: .4em;
				}
			}

			.instagram_login_button {
				@include standard-radius;
				background-color: $instagram-color;
				width: 100%;
				margin-bottom: 1em;
				padding: 1.5em 2em;
				border: none;
				-webkit-appearance: none;
				cursor: pointer;
				display: block;
				margin: 0 auto 2em;
				text-align: center;
				@include standard-transition;
				outline: none;

				&:hover {
					background-color: $instagram-color-hover;
				}

				&:focus {
					background-color: $instagram-color-hover;
					outline: none;
				}

				.instagram_icon {
					display: inline-block;
					vertical-align: 2px;
					margin-right: .5rem;

					path {
						fill: white;
					}
				}

				.button_text {
					color: white;
					@include uppercase14;
					display: inline-block;
					vertical-align: .4em;
				}
			}

			.youtube_login_button {
				width: 100%;
				background-color: $youtube-color;
				@include standard-radius;
				padding: 1.5em 2em;
				border: none;
				-webkit-appearance: none;
				cursor: pointer;
				display: block;
				margin: 0 auto;
				text-align: center;
				@include standard-transition;

				&:hover {
					background-color: $youtube-color-hover;
				}

				&:focus {
					background-color: $youtube-color-hover;
					outline: none;
				}

				.youtube_icon {
					display: inline-block;
					margin-right: 1em;

					path {
						fill: white;
					}
				}

				.youtube_text {
					color: white;
					@include uppercase14;
					display: inline-block;
					vertical-align: .4em;
				}
			}
		}

		.email_login {
			width: 353px;
			float: left;
			@include media($break825) {
				width: 100%;
				float: none;
				margin-bottom: 3em;
			}

			.option_title {
				@include fontsize18;
				color: white;
				display: block;
				text-align: center;
				margin-bottom: 1em;
				padding-bottom: 1em;
				border-bottom: 1px solid #4c4c4c;
			}

			.signUp_form {

				label {
					@include standard-label;
					color: white;
				}

				fieldset {
					margin-bottom: 2em;
				}
			}

			.email_login_button {
				display: block;
				width: 100%;
				margin-bottom: 2em;
				padding: 1.13em;
				border-radius: 4px;
			}

			.forgot_password_link {
				color: $aiq_darkblue;
				text-decoration: none;
				@include fontsize15;
				display: block;
				text-align: center;
				width: 173px;
				margin: 0 auto;
			}

      .forgot_success {
		    @include fontsize16;
		    display: none;
		    text-align: center;
      }
		}

		.forgotPassword_form {

			label {
				@include standard-label;
			}

			fieldset {
				margin-bottom: 2em;
			}

			.email-signup-button {
				display: block;
				width: 100%;
				margin-bottom: 2em;
				padding: 1.13em;
				border-radius: 4px;
				@include uppercase14;
			}

			.back_link {
				@include blue-link;
				@include fontsize15;
				text-align: center;
				display: block;
				width: 173px;
				margin: 0 auto;
			}
		}
	}

	.goSignup_container {
		border-top: 1px solid $lightgray-border;
		overflow: hidden;
		width: 784px;
		margin: 0 auto 8em;
		padding-top: 1em;
		padding-bottom: 3em;

		@include media($break825) {
			width: 425px;
		}

		@include media($break460) {
			width: 95%;
		}

		.goSignup {
			@include fontsize16;
			display: block;
			text-align: center;
		}

		.instagramLoginNotice {
			@include fontsize14;
			display: block;
			text-align: center;
		}

		a {
			color: $aiq_darkblue;
			text-decoration: none;

			&:hover, &:focus {
				color: $aiq_darkblue_hover;
			}

			&:active {
				color: $aiq_darkblue;
			}
		}
	}
}

.light_creator_login {
	background: #fbfbfb;
	padding-top: 3em !important;

	.revfluence-logo {

		a {
			background: url('/images/style_guide/logo_rev_black.svg') center no-repeat !important;
			transition: all .15s ease-in-out !important;
			margin: 0 auto !important;

			&:hover {
				opacity: .95 !important;
			}
		}
	}

	.colWrap {
		margin-bottom: 2rem;
	}

	.main {
		background-color: white;
		padding: 3rem 4rem;
		width: 100%;
		max-width: 912px !important;

		@include media($break600) {
			padding: 2rem 1rem;
		}
	}

	.intro_title, .option_title, .goSignup, .signUp_form {
		color: $dark-text !important;

		label {
			color: $dark-text !important;
		}
	}

	.option_title, .goSignup, .signUp_form, .form_control, .forgot_password_link {
		font-size: 1rem !important;

		label {
			font-size: 1rem !important;
		}
	}

	.form-error-span {
		padding: .25rem .5rem !important;
		font-size: .85rem !important;
	}

	fieldset {
		margin-bottom: .65rem !important;
	}

	.intro_title {
		letter-spacing: .01em !important;
		text-align: center !important;
		padding-bottom: 1.5rem;
		font-size: 2.25rem !important;
		margin-bottom: 0 !important;
	}

	.standard_label {
		display: block !important;
	}

	.header {
		margin-bottom: 2.5rem !important;
	}

	.intro_hint {
		text-align: center;
		margin-bottom: 1.5rem;
		font-weight: 600;
	}

	.option_title {
		border-bottom: 1px solid $lightgray-border !important;
		font-weight: 600 !important;
	}

	.social_login {
		margin-right: 9%;
	}

	.social_login, .email_login {
		width: 45%;
	}

	.email_input {
		font-size: 1rem !important;
	}

	.intro_descrip {
		color: $dark-text !important;
	}

	.reset_password {
		color: $dark-text !important;
		font-weight: 600 !important;
	}

	.button_text, .youtube_text, .email-signup-button, .email_login_button {
		text-transform: capitalize !important;
		letter-spacing: .01em !important;
		font-size: 1rem !important;
	}

	.email_login_button, .facebook_login_button, .instagram_login_button, .youtube_login_button, .email-signup-button {
		border-radius: 100px !important;
		padding: .55rem 1rem !important;
		font-size: 1rem !important;
		text-decoration: none !important;
	}

	.instagram_icon, .youtube_icon {
		margin-right: .25rem !important;
	}

	.facebook_login_button {
		margin-bottom: 1.25rem !important;
	}

	.instagram_login_button {
		margin-bottom: 1.25rem !important;
	}

	.email_login_button {
		margin-top: 1.25rem !important;
		margin-bottom: 1.5rem !important;
	}

	.goLogin_container {
		border-top: none !important;
		padding-bottom: 0 !important;
	}

	.goLogin {
		color: $dark-text !important;
		border-top: 1px solid $lightgray-border !important;
		padding-top: 1rem !important;
	}

	.goSignup_container {
		border-top: 1px solid $lightgray-border !important;
		margin: 0 auto !important;
		padding-bottom: 0 !important;
		padding-top: 1rem !important;
	}
}

.signup_post_oauth {

	.main {
	  max-width: 575px !important;
	}
  }
