.tos_privacy {
  padding-top: 50px;

  .tos_header {
    background: url('/images/tos_privacy/header_bg.png') center no-repeat;
    background-size: 100%;
    margin-bottom: 2rem;

    @include media($break1120) {
      background-size: cover;
    }

    .rev_logo {
      display: block;
      margin: 0 auto;
      padding: 2rem 0;
      width: 404px;

      @include media($break425) {
        width: 234px;
      }
    }
  }

  .tos_body {
    max-width: 850px;
    padding: 0 2rem 2rem;
    margin: 0 auto;

    @include media($break425) {
      padding: 0 .5rem 1rem;
    }

    .innerHeader {
      text-align: center;
      padding-bottom: 2rem;
      border-bottom: 1px solid $lightgray-border;
      margin-bottom: 2rem;

      .mainTitle {
        font-size: 2.2rem;
        padding-bottom: 1.4rem;

        @include media($break425) {
          font-size: 1.5rem;
        }
      }

      .mainDescrip {
        font-size: 1.25rem;
      }
    }

    .innerBody {

      .termsSection {}

      .innerSection {
        border-bottom: 1px solid $lightgray-border;
        padding-bottom: 1.75rem;
        margin-bottom: 2rem;
      }

      .innerTitle {
        font-weight: 400;
        font-size: 1.5rem;
        padding-bottom: 1.25rem;
      }

      .innerPara {
        padding-bottom: 2rem;
      }

      .agreeCheckbox {
        background: #F5F7F9;
        padding: 1rem 1rem 1rem 3rem;
        font-weight: 600;
        display: inline-block;

        &:hover {
          background: #EFF1F3;
        }

        &:active {
          background: #F5F7F9;
        }

        .control-indicator {
          top: 16px;
          left: 17px;
        }

        .text_container {
          font-size: .86rem;
        }
      }

      .privacySection {}

      .innerTitle {}

      .innerPara {}

      .innerList {}

      .checkList_item {
        margin-bottom: 0.5rem;
      }

      .strong {
        font-weight: 600;

        .icon_alert {
          margin-right: .25rem;
          vertical-align: -5px;
        }
      }

      .cta_section {}

      .btn_continue {
        width: 150px;
        display: block;
        margin: 0 auto;
      }
    }
  }
}
