.ui-menu {
  background-color: white;
	background-color: rgba(255,255,255,.97);
	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.07);
	border-radius: 6px;
	border: #E2E2E2 solid 1px;
}

.ui-menu-item {
  padding: 1rem;
  font-weight: 300;
  font-size: .85rem;
}

.ui-menu-item a {
  padding: 1rem;
}

.ui-button-text-only .ui-button-text {
	padding: .8em 2em;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  background: #2582f4;
  color: white;
  border: none;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  background: #2582f4;
  color: white;
  border: none;
}

.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited,
.ui-state-focus a,
.ui-state-focus a:hover,
.ui-state-focus a:link,
.ui-state-focus a:visited {
  background: #2582f4;
  color: white;
  border: none;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  background: #2582f4;
  color: white;
  border: none;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  background: #2582f4;
  color: white;
  border: none;
}
