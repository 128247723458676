.contract_review_tool {

  .tool_body {
    padding: 1rem 0 3rem;

    @include media($break630) {
      padding: 1rem .5rem;
      background-color: white;
    }
  }

  .tool_name {
    width: calc(100% - 229px) !important;
  }

  /* AGREED TERMS INTRO */
  .agreedTerms_intro {
      margin-bottom: .5rem;
      width: 97%;
      margin-top: .5rem;

      @include media($break800) {
          width: 100%;
      }

      .agreedTerms_title {
          font-weight: 500;
          padding-bottom: 1px;
      }

      p {
          margin-bottom: 0;
      }

      .icon_checkmark {
          vertical-align: -2px;
          margin-right: 2px;
      }

      .agreedTerms_descrip {
        padding: 0;
        margin: 0;
      }
  }

  .uploadContract_btn {
    margin-top: 3px;
    margin-right: 3px;

    @include media($break630) {
      float:none;
      margin: .5rem auto;
      display: block;
      width: 200px;
    }
  }

  .icon_tool {
    margin-top: 3px;
  }

  .tool_icon {
    vertical-align: -2px;
    width: 30px;
  }

  .signature_status {
    padding-bottom: 1rem;
  }

  p {
    padding-bottom: 1rem;
  }

  .status_icon {
    margin-right: .35rem;
    vertical-align: -2px;
  }

  .status_txt {}

  .quote_txt {}

  .status_txt {
    font-weight: 700;
    font-size: 1.05rem;
    padding-bottom: .5rem;
    line-height: 1.5rem;
  }

  .viewContract_btn {}

  .spec_concerns {
    padding-top: 1rem;
    border-top: 1px solid $lightgray-border;
    margin-top: 1.5rem;
  }

  .pending_signature {

    .status_icon {
      vertical-align: -4px;
    }

    .email {
      font-weight: 600;
    }
  }

  .contractList_container {

    .contractUpload_list {
      margin-bottom: 1rem;
      padding: 0 3px;
    }

    .contractUpload_item {
      margin-bottom: .75rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .contract_btn {
      border: 1px solid $lightgray-border;
      border-radius: 5px;
      background-color: white;
      padding: .75rem 1rem .75rem;
      position: relative;
      display: block;
      width: 100%;
      text-align: left;
      font-size: .85rem;

      &:hover {
        background-color: $ultraLight_gray;
      }
    }

    .inactiveBtn {
      background-color: #F7F6F6;
      border: none;

      &:hover {
        background-color: #F3F3F3;
      }
    }

    .icon_disclosure {
      position: absolute;
      right: 1rem;
      top: 50%;
      width: 10px;
      margin-top: -8px;
    }

    .contract_btn_title {
      font-weight: 600;
    }

    .contract_details {
      font-weight: 300;

      @include media($break1375) {
        font-size: .75rem;
      }
    }

    .contractStatus_wrapper {
      margin-top: 0.5rem;

      .contract_action_btn {
        font-size: 1.0rem;
      }
    }

    .contractStatus_wrapper, .cancelRequest_btn, .contractDate {
      display: inline-block;
      padding: .25rem .5rem;

      @include media($break1375) {
        padding: .25rem;
      }

      @include media($break425) {
        display: block;
      }
    }

    .contractStatus_wrapper {
      padding: .25rem .5rem .25rem .25rem;

      @include media($break1375) {
        padding: .25rem;
      }
    }

    .contractStatus_wrapper {}

    .contractDate {}

    .waiting_icon {}

    .cancelledStatus {
      font-weight: 600;
    }

    .cancelRequest_btn {
      font-size: .85rem;
      color: $revfluence-blue;
      font-weight: 300;
      line-height: 24px;
      vertical-align: .5px;
      text-align: left;

      &:hover {
        color: $revfluence-blue-hover;
      }

      .icon_close {
        width: 12px;
        margin-right: .25rem;
        vertical-align: -1.5px;
      }

      @include media($break1375) {
        font-size: .75rem;
      }
    }

    .campaign_status_icon {
      margin-right: .15rem;
      width: 15px;
      vertical-align: -2px;
    }

    .alert_icon {}

    .alertContract_btn {

      .contract_btn_txt {
        font-weight: 600;
        display: inline;
      }
    }

    .uploadContract_btn {
      margin-left: 3px;
    }
  }

  .contractUpload_title {
    display: block;
    font-weight: 600;
    font-size: 1rem;
    padding-bottom: 1rem;
  }

  .cancelledContract_upload {
    padding-top: 1rem;
  }

  .noContracts_wrapper {
    background-color: $ultraLight_gray;
    padding: .5rem;
    border-radius: 6px;
    margin-bottom: 1rem;
    display: flex;
  }

  .contractItem_wrapper {
    padding: .75rem 1.5rem .75rem .75rem;
    border: 1px solid $lightgray-border;
    border-radius: 6px;
    margin-bottom: 1rem;

    .loading_icon {
      margin: 0 auto;
      width: 25px;
      display: flex;
    }

    .file_name {
      font-size: 1.35em;
      font-weight: 600;
      vertical-align: 4px;
      padding-left: .25rem;
      display: inline-block;
      line-height: 1rem;
      width: calc(100% - 24px);
    }
  }

  .sendContract_btn {
    margin-right: .5rem;
  }

  .contrtactUpload_confirm_wrapper {}

  .contractReview_form {

    .standard_label {
      font-weight: 600;
      margin-bottom: 0;
    }

    .standard_fieldset {
      padding-bottom: .5rem;
    }

    .form_control {
      max-width: 260px;
    }

    .btn_primary {
      margin-top: .5rem;
    }

    .field_descrip {
      padding-bottom: .5rem;
    }

    .introField_descrip {
      padding-bottom: .5rem;
      font-weight: 600;
      margin-bottom: .5rem;
    }
  }

  .cancelledContract_uploadList {

    .contract_btn {
      background-color: $ultraLight_gray;
      border: none;

      &:hover {
        background-color: #F3F2F2;
      }
    }
  }
}
