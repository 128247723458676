.campaign_hidden_blocker_manager {

  .actions_container {
    text-align: center;

    @include media($break400) {
      padding: 0 1rem;
    }
  }

  .reactivate_btn {
    margin-right: .25rem;

    @include media($break400) {
      margin-right: 0;
    }
  }

  .btn {

    @include media($break400) {
      display: block;
      margin-bottom: .75rem;
    }
  }
}
