.complete_profile {
  background: white;

  .revfluence_brandBar {
  	background-color: white;
  	border-bottom: 1px solid $lightgray-border;
  	padding: 1em 0;

	  .revfluence_logo {
	  	display: block;
	  	width: 146px;
	  	height: 33px;
	  	background: url('/images/themes/aspireiq/aiq_logo.png') center no-repeat;
	    background-size: 100%;
	  	margin: 0 auto;
	  }
  }

	.primaryContainer {
		background: none;
		background-color: white;
	}

	.broadcastBg {
		background: none;
	}

	.successbar {
  	display: none;
  }

  .stepsContainer {
    margin-top: 5em;
  	border-bottom: 1px solid $lightgray-border;
  	background-color: $lightgray-bg;
  	@include media($break1010) {
  		background-color: white;
  	}
  	@include media($break900) {
  		display: none;
  	}

  	.stepsList {
  		overflow: hidden;
  		max-width: 900px;
  		width: 100%;
  		margin: 0 auto;
  		border-left: 1px solid $lightgray-border;
  		list-style-type: none;
  		height: 41px;

      .steps_label {
        font-weight: 500;
      }

  		li {
  			padding: .5em 2em;
  			float: left;
  			text-align: center;
  			@include fontsize15;

  			&:last-child {
  				border-right: none;
  			}

        &.three_tabs {
          width: 34.55%;
        }
        &.four_tabs {
          width: 26.6625%;
        }
  		}
  	}

		.step_text {
			display: inline-block;
		}

 		.viewDescription, .step_marker {
      background-position: -40px;
 			background: url('../images/brand_onboarding/steps_background_sprite.png');
      @include hidpi(1.5) {
        background: url('../images/brand_onboarding/steps_background_sprite@2x.png');
        background-size: 138%;
        &.three_tabs {
          background-size: 107%;
        }
      }
    }

 		.viewDescription {
 			position: relative;
 			z-index: 4;
 		}

    .step1, .step2, .step3 {
      cursor: pointer;
    }

 		.step1 {
 			position: relative;
 			margin-left: -2em;
 			z-index: 3;
 		}

 		.step2 {
 			position: relative;
 			margin-left: -2em;
 			z-index: 2;
 		}

    .step3 {
      position: relative;
      margin-left: -2em;
      z-index: 1;
    }

		.step_complete_icon {
			display: none;
			margin-right: .5em;
			vertical-align: -4px;
		}

		.viewDescription {
			background-position: right -40px;
		}

 		.active {
 			background-position: right 0;
 			color: white;
 		}

		.completed {
			background-position: right -40px;

			.step_complete_icon {
				display: inline-block;
			}
		}

 		.upcoming {
 			color: $lightgray-text;
 			background: none;
            cursor: default;
 		}
  }

  .main {
  	width: 875px;
  	margin: 0 auto;
    padding-bottom: 3em;
  	@include media($break1010) {
  		width: 100%;
  		padding: 0 6em;
  	}
  	@include media($break835) {
  		padding: 0 3em;
  	}
  	@include media($break520) {
  		padding: 0 2em;
  	}
  }

  .broadcastEditBtn {
    float: left;
    width: 100%;
    text-align: center;
    letter-spacing: .1em;
    float: none;

    &:hover {
    	border: none;
    }
  }

  .contact_revfluence {
    @include fontsize16;
    color: $dark-text;
    text-align: center;
    margin-top: 2em;

    span {
      @include blue-link;
    }
  }

  .splashUpload_btn {
  	display: none;
  }

  .broadcastCheckbox {
      float: left;
      width: 100%;
      margin: 1em auto;
  }

  .broadcastInformation {
    overflow: hidden;
  }



  .hideCreatorContents {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
  }

  .hidePrompt {
      margin: 20px 40px;
  }

  .hideReasonInput {
      margin: 20px 40px;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 10px;
  }

  .hideBtnContainer {
      width: 350px;
      text-align: center;
      clear: none;
      margin: 40px auto;
      padding: 0;
  }

  .broadcastInformationSection {
      border-top: 1px solid #ddd;
      color: #666;
  }
}
