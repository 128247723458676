.creator_welcome {

	.creator_welcome_nav {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		background-color: white;
		background-color: rgba(255,255,255,.97);
		border-bottom: 1px solid #efefef;
		z-index: $zindexHeader;

		.inner_container {
			max-width: 1200px;
			margin: 0 auto;
			overflow: hidden;
			padding: 1em 2%;

			@include media($break289) {
				padding: 1.5em 5%;
			}
		}

		h1 {
			float: left;
		}

		.revfluence_logo {
			display: inline-block;
			width: 150px;
			height: 34px;
			background: url('/images/themes/aspireiq/aiq_logo.png') center no-repeat;
			background-size: 100%;
			margin-right: 3em;
			text-indent: -9999px;
			margin-top: .25em;

			@include media($break450) {
				background: url('/images/themes/aspireiq/logo_a_mark.png') center no-repeat;
				background-size: cover;
				width: 38px;
				height: 30px;
				margin-right: 0;
				margin: 0 auto;
			}

			@include media($break289) {
				margin-bottom: 1em;
			}
		}

		.revfluence_logo {}

		.creator_signin_link {
			float: right;
			color: $dark-text;
			font-weight: 300;
			text-decoration: none;
			font-size: 1.5em;
			line-height: 1.5em;
			display: inline-block;
			padding: .5em 0;

			@include media($break350) {
				font-size: 1.3em;
			}

			&:hover {
				color: $revfluence-blue;
			}
		}
	}

	.scroll_indicator {
		display: block;
		text-indent: -9999px;
		width: 100%;
		height: 75px;
		background: url('/images/main_welcome/scroll_indicator.svg') center no-repeat;
		position: absolute;
		bottom: 0;
		left: 0;
		background-size: 37px;
		transition: all .15s ease-in-out;

		&:hover {
			background: rgba(255,255,255,.75) url('/images/main_welcome/scroll_indicator.svg') center no-repeat;
			background-size: 37px;
		}
	}
}
